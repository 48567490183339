import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask, enqueueTask } from 'ember-concurrency';
import type EntryModel from 'garaje/models/entry';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { all } from 'rsvp';

interface SignOutEntryComponentSignature {
  Args: {
    entries: EntryModel[];
    confirm: (options: { entries: EntryModel[]; signOutGroup: boolean }) => void;
    abort: () => void;
  };
}

export default class SignOutEntry extends Component<SignOutEntryComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  get hasGroupParent(): boolean {
    return Boolean(this.args.entries.find((entry) => entry.hasAdditionalGuests));
  }

  signOutEntriesTask = enqueueTask(
    {
      maxConcurrency: 10,
    },
    async (entry: EntryModel, date: Date) => {
      if (!entry.signOutTime) {
        // _signOutTime is set in entry/controller
        const signOutTime = entry._signOutTime ?? date;
        entry.signOutTime = signOutTime;
      }

      await entry.save();
    },
  );

  signOutTask = dropTask(async (entries: EntryModel[], signOutGroup: boolean) => {
    const signOutDate = new Date();

    const promise = all(
      entries.map((entry) => {
        let promise: Promise<void | void[]>;
        if (entry.hasAdditionalGuests && signOutGroup) {
          promise = entry.additionalGuestEntries.then((children) => {
            const promises = [this.signOutEntriesTask.perform(entry, signOutDate)];

            children.forEach((childEntry) => {
              promises.push(this.signOutEntriesTask.perform(childEntry, signOutDate));
            });

            return all(promises);
          });
        } else {
          promise = this.signOutEntriesTask.perform(entry, signOutDate);
        }

        return promise;
      }),
    );

    try {
      await promise;

      if (entries.length > 1) {
        const entry_count = entries.reduce((count, entry) => count + entry.additionalGuests, entries.length);
        this.metrics.trackEvent('Bulk Entries Signed Out', { entry_count });
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.confirm({ entries, signOutGroup });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  closeModal(): void {
    this.args.abort();
  }
}
