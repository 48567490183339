import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Model<Config>}             config
 * @param {Boolean}                   doesNotHaveEmployees
 * @param {Boolean}                   doesNotHaveNotification
 * @param {Boolean}                   hasUnmetPreconditions
 * @param {Boolean}                   isDirty
 * @param {Function}                  didSave
 * @param {Function}                  doRollback
 */
export default class DeliveriesSettingsPanel extends Component {
  @service flashMessages;

  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;

  @(employeesSearcherTask().restartable())
  searchTask;

  @dropTask
  *enableTask() {
    set(this.args.config, 'acceptDeliveries', true);

    try {
      yield this.args.config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      if (!this.args.config.deliveryPerson) {
        this.isOpen = true;
      }
    } catch (e) {
      this.args.config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *disableTask() {
    set(this.args.config, 'acceptDeliveries', false);

    try {
      yield this.args.config.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.didSave();
    } catch (e) {
      this.args.config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *saveTask() {
    try {
      yield this.args.config.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.didSave();
    } catch (e) {
      this.args.config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  onContinue() {
    if (this.args.isDirty) {
      this.args.doRollback();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  selectPerson(employee) {
    if (employee) {
      set(this.args.config, 'deliveryPerson', employee);
      set(this.args.config, 'acceptDeliveries', true);
    } else {
      set(this.args.config, 'deliveryPerson', null);
      set(this.args.config, 'acceptDeliveries', false);
    }
  }

  @action
  cancel() {
    if (this.args.isDirty) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.onContinue();
    }
  }

  @action
  edit() {
    this.isOpen = true;
  }

  @action
  async enable() {
    await this.enableTask.perform();
    this.isOpen = true;
  }

  @action
  async disable() {
    await this.disableTask.perform();
    this.isOpen = false;
  }

  @action
  async save() {
    await this.saveTask.perform();
    this.isOpen = false;
  }
}
