import type { AsyncBelongsTo } from '@ember-data/model';
import { belongsTo } from '@ember-data/model';
import AbstractPhotoPageModel from 'garaje/models/abstract/abstract-photo-page';
import type GlobalFlowModel from 'garaje/models/global-flow';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('photo-page')
class GlobalPhotoPageModel extends AbstractPhotoPageModel {
  @belongsTo('global-flow', { async: true }) declare flow: AsyncBelongsTo<GlobalFlowModel>;
}

export default GlobalPhotoPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-photo-page': GlobalPhotoPageModel;
  }
}
