import Model, { belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type ZoneModel from 'garaje/models/zone';

export default class DeviceConfigStaticPageModel extends Model {
  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'device-config-static-page': DeviceConfigStaticPageModel;
  }
}
