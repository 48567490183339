import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';

/**
 * @param {Model<Integration}           googleApp
 * @param {Object}                      filter
 * @param {Model<Company>}              company
 * @param {Boolean}                     canEdit
 * @param {Function}                    showFlash
 * @param {Function}                    hideFlash
 * @param {Function}                    handleFailedQuery
 */
export default class GoogleAppsCompanyFilter extends Component {
  @service flashMessages;
  @service googleSyncManager;
  @service skinnyLocations;

  elementId = guidFor(this);

  @tracked filterQuery = '';
  @tracked search;

  constructor() {
    super(...arguments);
    this.filterQuery = this.args.filter.query ?? '';
  }

  get isActive() {
    return this.args.filter.type === 'company';
  }

  get neverSynced() {
    return !get(this.args.company, 'employeesLastSyncAt');
  }

  get companyHasOneLocation() {
    return get(this.skinnyLocations, 'currentCompanyLocations.length') === 1;
  }

  @action
  _resetResults() {
    if (!this.isActive) {
      this.search = null;
    }
  }

  @action
  setFilter() {
    const filters = { query: this.filterQuery || '' };
    return this.googleSyncManager.setFilter(this.args.company, 'company', filters);
  }

  @dropTask
  *saveTask() {
    return yield this.setFilter().then(() => {
      this.search = null;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    });
  }

  @dropTask
  *testTask() {
    this.args.hideFlash();

    const query = this.filterQuery;

    const promise = yield this.googleSyncManager.companyFilter(this.args.googleApp, query).then(
      (data) => {
        this.search = { results: data };
      },
      (response) => {
        this.args.handleFailedQuery(response);
      },
    );

    return promise;
  }

  @action
  queryChanged() {
    this.search = null;
  }

  @action
  activate() {
    if (!this.args.canEdit) {
      return false;
    }

    if (!this.isActive) {
      this.search = null;
      set(this.args.filter, 'type', 'company');
    }
  }

  @action
  cancel() {
    this.args.filter.rollbackAttributes();
    this.filterQuery = this.args.filter.query;
    this.search = null;
  }
}
