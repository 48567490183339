import Route from '@ember/routing/route';

class WorkplaceSetupGuideRoute extends Route {
  title = 'Workplace · Setup Guide · Envoy';

  queryParams = {
    postTrial: {
      refreshModel: true,
    },
  };
}
export default WorkplaceSetupGuideRoute;
