import Controller from '@ember/controller';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';

export type RedirectCode = keyof typeof REDIRECT_CODE_TO_MESSAGE_MAP;

const REDIRECT_CODE_TO_MESSAGE_MAP = {
  100: 'Invitation is already confirmed. Please log in.',
  101: 'Confirmation code is invalid.',
  102: 'SAML account not found.',
  103: 'SAML is not available on your plan.',
  104: 'SAML certificate is not valid.',
  105: 'SAML HTTP POST URL is not configured.',
  106: 'Email confirmed',
  107: 'Email is not yet confirmed',
  108: 'SAML access denied',
  109: 'SAML company not found',
  110: 'SAML employee not found',
  111: 'SAML invalid fingerprint',
  112: 'SAML invalid response',
  113: 'SAML error. Contact support.',
  114: 'Please check your email to confirm your account.',
};

export default class LoginController extends Controller {
  @service declare ajax: AjaxService;
  @service declare flashMessages: FlashMessagesService;

  queryParams = ['companyId', 'rd', 'rc', 'em', 'state'];

  @tracked companyId = null;
  @tracked rc: string | null = null;
  @tracked em = null; // prefilled email
  @tracked rd = null; // redirect from
  @tracked state = null;

  showRedirectCodeError(redirectCode: RedirectCode): void {
    const reason = REDIRECT_CODE_TO_MESSAGE_MAP[redirectCode] || 'There was an error';

    this.flashMessages.showAndHideFlash('error', reason);

    next(() => (this.rc = ''));
  }

  @action
  _redirectToEnvoy(path: string): void {
    this.send('redirectToEnvoy', path);
  }
}
