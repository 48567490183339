import { dasherize } from '@ember/string';
import { isBlank } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import { type AsyncBelongsTo } from '@ember-data/model';
import type AbstractPrinter from 'garaje/models/abstract/printer';
import type LocationModel from 'garaje/models/location';
import type VisitorEntranceModel from 'garaje/models/visitor-entrance';
import { reads } from 'macro-decorators';

class PaperDeviceModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('abstract/printer', { async: true, polymorphic: true }) declare printer: AsyncBelongsTo<AbstractPrinter>;
  @belongsTo('visitor-entrance', { async: true }) declare visitorEntrance: AsyncBelongsTo<VisitorEntranceModel>;

  @attr('string') declare name: string;
  @attr('string') declare pngSrc: string;
  @attr('string') declare printerStatus: string;
  @attr('string') declare signInUrl: string;
  @attr('string') declare svgSrc: string;
  @attr('date') declare lastHeartbeatAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  // Try to build a file name that is safe for Windows + Mac
  get safeFileName(): string {
    const { name } = this;

    if (isBlank(name)) return 'qr-code';

    return dasherize(name.replace(/[\\/:*?"<>|]+/g, '-'));
  }

  // Can build download link with base64 content directly.
  // Using computed property for symmetry with SVG
  @reads('pngSrc', '') pngDownloadUrl!: string;

  get svgDownloadUrl(): string {
    if (typeof window?.URL?.createObjectURL !== 'function') return '';

    const blob = new Blob([this.svgSrc], { type: 'image/svg+xml' });

    return window.URL.createObjectURL(blob);
  }

  async duplicate(): Promise<PaperDeviceModel> {
    const location = await this.location;
    const printer = await this.printer;
    const name = this.name;
    const copy = this.store.createRecord('paper-device', { location, printer, name });

    try {
      await copy.save();
    } catch (e) {
      copy.rollbackAttributes();
      throw e;
    }

    return copy;
  }

  async regenerate(): Promise<PaperDeviceModel> {
    const copy = await this.duplicate();

    try {
      await this.destroyRecord();
    } catch (e) {
      this.rollbackAttributes();
      throw e;
    }

    return copy;
  }
}

export default PaperDeviceModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'paper-device': PaperDeviceModel;
  }
}
