import Component from '@glimmer/component';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action, set, get } from '@ember/object';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Object}              location
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class GoogleHangoutsConfigBox extends Component {
  @service currentAdmin;
  @service flashMessages;
  @service integrationsManager;

  get company() {
    return get(this.args.location, 'company.content');
  }

  get connected() {
    return get(this.company, 'gtalkEnabled');
  }

  get isOpen() {
    return this.args.integration === 'google-hangouts';
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    set(this.company, 'gtalkEnabled', false);

    try {
      yield this.company.save();
      this.args.integrationDisconnected();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.company.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @action
  open() {
    this.args.openAction('google-hangouts');
  }

  @action
  close() {
    this.args.closeAction();
  }
}
