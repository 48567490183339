import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class DowngradeWarning extends Component {
  @service('location-billing') billingService;

  @alias('billingCart.visitors.onTrial') cartVisitorsTrial;
  @alias('billingCart.rooms.onTrial') cartRoomsTrial;
  @alias('billingCart.deliveries.onTrial') cartDeliveriesTrial;
  @alias('billingService.downgradeSubscriptionPlan') downgradeSubscriptionPlan;
  @alias('billingService.downgradeFeatures') downgradeFeatures;
  @alias('billingService.downgradeRestrictions') downgradeRestrictions;

  get title() {
    let titleText = 'Are you sure you want to downgrade?';
    const app = this.downgradeSubscriptionPlan.app;
    const currentTrial = this[`cart${app}Trial`];
    if (currentTrial) {
      titleText = 'Some features available in your trial will be disabled';
    }
    return titleText;
  }

  @action
  cancel() {
    this.billingService.cancelDowngrade();
  }

  @action
  startDowngrade() {
    this.billingService.startDowngrade();
  }
}
