import Controller from '@ember/controller';
import { action } from '@ember/object';
import { capitalize } from '@ember/string';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class DowngradeController extends Controller {
  @service router;
  @service metrics;

  queryParams = ['downgradeViaLabel', 'downgradeViaObject'];

  @tracked showDowngradeFeatures = false;
  downgradeViaLabel = null;
  downgradeViaObject = null;

  get title() {
    return this.showDowngradeFeatures
      ? 'You’ll lose these features'
      : `Downgrade to ${capitalize(this.model.planName)}`;
  }

  @action
  transitionToPlans() {
    this.router.transitionTo('billing.product-plans.index', this.model.subscription.appName);
  }

  @action
  transitionToBilling() {
    this.router.transitionTo('billing.index');
  }

  @dropTask
  *trackModalExited(object, label) {
    yield this.metrics.trackPage('Modal exited', {
      action_id: this.model.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'downgrade', // purpose of modal
      via_object: object, // object interacted with that triggered the modal exiting
      via_action: 'click', // type of interaction
      via_label: label, // Label of the button or element being interacted with
    });
  }
}
