import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { all, task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { APP, IMPRESSION_NAMES } from 'garaje/utils/enums';

export default class SignupQuestionsModal extends Component {
  @service state;
  @service flashMessages;
  @service growth;
  @service impressions;

  @tracked questions = [];
  @tracked submitClicked = false;
  @tracked showFormError = false;
  @tracked isDesktop = window.innerWidth > 1100;

  requests = {};

  constructor() {
    super(...arguments);
    this.getQuestionsTask.perform();
  }

  get questionsAnswered() {
    let questionsAnswered = 0;
    this.questions.forEach((question) => {
      let questionAnswered = false;
      question['relationships']['signup-question-choices']['data'].forEach((choice) => {
        if (choice.relationships && choice.relationships.length > 0) {
          questionAnswered = true;
        }
      });
      if (questionAnswered) {
        questionsAnswered++;
      }
    });
    return questionsAnswered;
  }

  get showCloseButton() {
    if (this.args.showCloseX !== null) {
      return this.args.showCloseX;
    }
    if (
      (this.state.tenantConnectionRequests || this.state.tenantConnections) &&
      (this.state.tenantConnectionRequests.length !== 0 || this.state.tenantConnections.length !== 0)
    ) {
      return false;
    }
    return true;
  }

  get showLargeModal() {
    return this.isDesktop && this.args.app === APP.WORKPLACE;
  }

  getQuestionsTask = task({ drop: true }, async () => {
    const params = {
      filter: { company_id: this.state.currentCompany.id, app: this.args.app },
    };
    try {
      const questionsData = await this.growth.get.perform('signup-questions', params);
      this.questions = this.questionsSerializer(questionsData);
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  submitAnswerTask = task({ drop: true }, async (choice_id) => {
    const params = {
      attributes: {
        'signup-question-choice-id': choice_id,
      },
    };
    try {
      await this.growth.post.perform(null, 'signup-answers', params);
      this.getQuestionsTask.perform();
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  deleteAnswerTask = task({ drop: true }, async (answer_id, fetchQuestions) => {
    const url = `signup-answers/${answer_id}`;
    try {
      await this.growth.delete.perform(url);
      if (fetchQuestions) {
        this.getQuestionsTask.perform();
      }
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });

  @action
  questionsSerializer(questionsData) {
    questionsData?.data.forEach((question) => {
      if (!question.attributes.metadata.min_answers) {
        question.attributes.metadata.min_answers = 1;
      }
      if (!question.attributes.metadata.max_answers) {
        question.attributes.metadata.max_answers = question['relationships']['signup-question-choices']['data'].length;
      }
      question['relationships']['signup-question-choices']['data'].forEach((choice) => {
        choice.relationships = [];
        choice.selected = false;

        questionsData.included.forEach((answer) => {
          if (answer.type == 'signup-question-choices' && choice.id == answer.id) {
            choice.text = answer.attributes.text;
            choice.metadata = answer.attributes.metadata;
            choice.text_input_enabled = answer.attributes['text-input-enabled'];
          } else if (answer.type == 'signup-answers' && choice.id == answer.attributes['signup-question-choice-id']) {
            choice.relationships.push(answer);
            choice.selected = true;
          }
        });
      });
    });
    questionsData?.data.sort((a, b) =>
      a.attributes.rank > b.attributes.rank ? 1 : b.attributes.rank > a.attributes.rank ? -1 : 0,
    );
    return questionsData?.data || [];
  }

  @action
  getNumChoicesSelected(question) {
    return question['relationships']['signup-question-choices']['data'].filter((choice) => choice.selected).length;
  }

  @action
  async closeModal() {
    if (this.questionsAnswered == this.questions.length) {
      this.submitClicked = true;
      await this.performPendingRequestsTask.perform();
      this.args.close();
    } else {
      this.showFormError = true;
    }
  }

  @action
  closeModalSecondary() {
    this.args.closeSecondary();
  }

  @action
  async toggleAnswer(max_answers, choices, choice) {
    // Invalidate any pending request on the toggled choice
    delete this.requests[choice.id];

    // Because toggleAnswer refreshes the state of the survey from the backend,
    // we flush the buffer of pending requests to ensure those are changes persisted
    await this.performPendingRequestsTask.perform();

    if (choice.relationships.length > 0) {
      try {
        this.deleteAnswerTask.perform(choice.relationships[0].id, true);
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        throw e;
      }
    } else {
      const answerIds = [];
      choices.forEach((answer) => {
        if (answer.relationships.length > 0) {
          answerIds.push(answer.relationships[0].id);
        }
      });
      if (answerIds.length >= max_answers) {
        answerIds.forEach((id) => {
          try {
            this.deleteAnswerTask.perform(id, false);
          } catch (e) {
            this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
            throw e;
          }
        });
      }
      try {
        this.submitAnswerTask.perform(choice.id);
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
        throw e;
      }
    }
  }

  performPendingRequestTask = task(async (request) => {
    switch (request.type) {
      // POST and DELETE are currently unused. We should however eventually leverage
      // them to defer the network calls currently made in toggleAnswer to whenever closeModal is invoked
      case 'POST':
        await this.growth.post.perform(null, request.uri, request.body);
        return;
      case 'DELETE':
        await this.growth.delete.perform(request.uri);
        return;
      case 'PATCH':
        // /signup-answers does not support upsertion, so we must explicitly delete and recreate the answer
        await this.growth.delete.perform(request.uri_delete);
        await this.growth.post.perform(null, request.uri_post, request.body);
        return;
    }
  });

  performPendingRequestsTask = task({ drop: true }, async () => {
    const requests = Object.entries(this.requests);

    try {
      await all(requests.map(([_, request]) => this.performPendingRequestTask.perform(request)));
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }

    this.requests = {};
  });

  @action
  async updateTextValue(choice, value) {
    this.requests[choice.id] = {
      type: 'PATCH',
      uri_delete: `/signup-answers/${choice.relationships[0].id}`,
      uri_post: '/signup-answers',
      body: {
        attributes: {
          'signup-question-choice-id': choice.id,
          'text-input': value,
        },
      },
    };
  }

  @action
  async trackSignupSurveyViewed() {
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_VIEWED);
  }
}
