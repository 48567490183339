import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type EmployeeModel from 'garaje/models/employee';
import type MapFloorModel from 'garaje/models/map-floor';
import type MapVersionModel from 'garaje/models/map-version';
import type StateService from 'garaje/services/state';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import type { MapVersionGroup } from '../versions-sidebar/component';

interface VersionsSidebarGroupArgs {
  mapVersionGroup: MapVersionGroup;
  currentMapVersion: MapVersionModel;
  currentMapFloor?: MapFloorModel;
}

export default class VersionsSidebarGroup extends Component<VersionsSidebarGroupArgs> {
  @service declare store: StoreService;
  @service declare state: StateService;

  @tracked open = false;

  get isActive(): boolean {
    return !this.open && !!this.args.mapVersionGroup.mapVersions.find((mv) => mv.id === this.args.currentMapVersion.id);
  }

  get employees(): EmployeeModel[] {
    const mapVersions = this.args.mapVersionGroup.mapVersions;
    const employeeIds = uniq(flatten(mapVersions.map((mv) => mv.hasMany('employees').ids())));

    return this.store.peekAll('employee').filter((e) => employeeIds.includes(e.id));
  }

  @action
  onToggle(event: Event): void {
    this.open = (event.target as HTMLDetailsElement).open;
  }
}
