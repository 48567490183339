// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type EmployeeModel from 'garaje/models/employee';
import type InviteModel from 'garaje/models/invite';

class AttendeeModel extends Model {
  // Relationships
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('invite', { async: true }) declare invite: AsyncBelongsTo<InviteModel>;

  // Attributes
  @attr('string') declare name: string;
  @attr('string') declare email: string;

  /**
   * Used to determine if we
   * need to create an invite for this attendee
   */
  @tracked sendInvite = (<AttendeeModel>this).belongsTo('employee').id() && !this.hasInvite ? false : true;

  /**
   * avoid server call for checking invite existence
   */
  @computed('invite')
  get hasInvite(): boolean {
    return (<AttendeeModel>this).belongsTo('invite').id() ? true : false;
  }
}

export default AttendeeModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    attendee: AttendeeModel;
  }
}
