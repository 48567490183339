import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Function}            placeResource
 * @param {Object}              placingResource
 * @param {Function}            drawArea
 * @param {Boolean}             isDrawing
 * @param {Object}              resourceOptions
 */
export default class AvailableResources extends Component {
  @tracked pressedKeys = {};

  get categoriesMap() {
    const {
      resourceOptions: { resources, deliveryArea, visitorArea },
    } = this.args;

    return [
      {
        title: 'Resources',
        sections: [
          {
            title: '',
            resources: resources.map((resource) => ({
              title: resource.title,
              type: resource.type,
              geometryType: resource.geometryType,
              icon: resource.type,
              disabled: resource.disabled,
              tooltip: resource.tooltip,
              beta: resource.beta,
              onClick: resource.geometryType === 'Point' ? this.place : this.draw,
            })),
          },
        ],
      },
      {
        title: 'Points of interest',
        sections: [
          {
            title: 'Safety',
            resources: [
              {
                title: 'AED',
                type: 'aed',
                geometryType: 'Point',
                icon: 'aed',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Emergency exit',
                type: 'emergency-exit',
                geometryType: 'Point',
                icon: 'emergency-exit',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Fire extinguisher',
                type: 'fire-extinguisher',
                geometryType: 'Point',
                icon: 'fire-extinguisher',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'First aid',
                type: 'first-aid-kit',
                geometryType: 'Point',
                icon: 'first-aid-kit',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
            ],
          },
          {
            title: 'Workplace amenities',
            resources: [
              {
                title: 'Break room',
                type: 'break-room',
                geometryType: 'Point',
                icon: 'break-room',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Cafe',
                type: 'cafe',
                geometryType: 'Point',
                icon: 'cafe',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Delivery area',
                type: 'delivery-area',
                geometryType: 'Point',
                icon: 'delivery-area',
                disabled: deliveryArea.disabled,
                tooltip: deliveryArea.tooltip,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Kitchen',
                type: 'kitchen',
                geometryType: 'Point',
                icon: 'kitchen',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: `Mother's room`,
                type: 'mothers-room',
                geometryType: 'Point',
                icon: 'mothers-room',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Printer',
                type: 'printer',
                geometryType: 'Point',
                icon: 'printer',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Visitor area',
                type: 'visitor-area',
                geometryType: 'Point',
                icon: 'visitor-area',
                disabled: visitorArea.disabled,
                tooltip: visitorArea.tooltip,
                active: false,
                onClick: this.place,
              },
            ],
          },
          {
            title: 'Facilities',
            resources: [
              {
                title: 'Accessible',
                type: 'accessible',
                geometryType: 'Point',
                icon: 'accessible',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Elevator',
                type: 'elevator',
                geometryType: 'Point',
                icon: 'elevator',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Restroom',
                type: 'restroom',
                geometryType: 'Point',
                icon: 'restroom',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
              {
                title: 'Stairs',
                type: 'stairs',
                geometryType: 'Point',
                icon: 'stairs',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
            ],
          },
          {
            title: 'Custom POI',
            resources: [
              {
                title: 'Custom',
                type: 'custom',
                geometryType: 'Point',
                icon: 'custom',
                disabled: false,
                tooltip: null,
                active: false,
                onClick: this.place,
              },
            ],
          },
        ],
      },
    ];
  }

  get bookableResources() {
    const {
      isDrawing,
      resourceOptions: { room, desk },
    } = this.args;

    return [
      {
        title: 'Desk',
        type: 'desk',
        geometryType: 'Point',
        icon: 'desk',
        disabled: desk.disabled,
        tooltip: desk.tooltip,
        active: false,
        onClick: this.place,
      },
      {
        title: 'Room',
        type: 'room',
        geometryType: 'Polygon',
        icon: 'room',
        disabled: room.disabled,
        tooltip: room.tooltip,
        active: isDrawing,
        onClick: this.draw,
      },
    ];
  }

  get additionalResources() {
    const {
      resourceOptions: { deliveryArea, visitorArea },
    } = this.args;

    return [
      {
        title: 'Visitor area',
        type: 'visitor-area',
        geometryType: 'Point',
        icon: 'visitor-area',
        disabled: visitorArea.disabled,
        tooltip: visitorArea.tooltip,
        active: false,
        onClick: this.place,
      },
      {
        title: 'Delivery area',
        type: 'delivery-area',
        geometryType: 'Point',
        icon: 'delivery-area',
        disabled: deliveryArea.disabled,
        tooltip: deliveryArea.tooltip,
        active: false,
        onClick: this.place,
      },
    ];
  }

  @action
  place(resource) {
    if (!resource.disabled) {
      this.args.placeResource(resource);
    }
  }

  @action
  draw(resource) {
    if (!resource.disabled) {
      this.args.drawArea(resource);
    }
  }
}
