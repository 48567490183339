import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type EmployeeGroupRoleModel from './employee-group';

@adapter('v3-coalesce')
class EmployeeGroupModel extends Model {
  @belongsTo('employee-group-role', { async: true }) declare employeeGroupRole: AsyncBelongsTo<EmployeeGroupRoleModel>;

  @attr('string') declare displayName: string;
}

export default EmployeeGroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-group': EmployeeGroupModel;
  }
}
