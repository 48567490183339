import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import _isEmpty from 'lodash/isEmpty'; // Use lodash version as isBlank from utils doesn't check object emptiness
import { service } from '@ember/service';
import { A } from '@ember/array';
import EmberObject from '@ember/object';
import { v4 as uuid } from 'uuid';
import move from 'ember-animated/motions/move';
import { fadeOut, fadeIn } from 'ember-animated/motions/opacity';
import zft from 'garaje/utils/zero-for-tests';

/**
 * @param {String|Number|Boolean|Object}            value
 * @param {Object}                                  field
 * @param {Object}                                  output
 * @param {Object}                                  plugin
 * @param {Service<CurrentLocation>}                currentLocation
 * @param {Function}                                updateValue
 */
export default class Mapping extends Component {
  @service ajax;
  @service flashMessages;

  durationAnimation = zft(200);
  primaryComponent = 'plugin-config-field/select';
  @tracked matchingRows = A(this._generateMatchingRows());

  get addingNew() {
    // If there is any row without an `id` it means we're adding a new row
    return this.matchingRows.some((row) => !row.id);
  }

  _generateMatchingRows() {
    const outputValue = get(this.args.output, this.args.field.key);
    if (_isEmpty(outputValue)) {
      return [EmberObject.create({ id: null, source: '', matching: '' })];
    } else {
      return Object.keys(outputValue).map((key) => {
        return EmberObject.create({ id: uuid(), source: key, matching: outputValue[key] });
      });
    }
  }

  /* eslint-disable require-yield */
  *transition({ keptSprites, insertedSprites, removedSprites }) {
    keptSprites.forEach(move);
    insertedSprites.forEach(fadeIn);
    removedSprites.forEach(fadeOut);
  }
  /* eslint-enable require-yield */

  @action
  updateConfigValue() {
    const outputValue = {};
    this.matchingRows.forEach((row) => {
      if (row.id) {
        outputValue[row.source] = row.matching;
      }
    });
    this.args.updateValue(outputValue);
  }

  @action
  deletePair(row) {
    this.matchingRows.removeObject(row);
    this.updateConfigValue();
  }

  @action
  addEmptyMatch() {
    this.matchingRows.addObject(EmberObject.create({ id: null, source: '', matching: '' }));
  }
}
