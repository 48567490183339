import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class LoseFreeWorkplaceModal extends Component {
  @service statsig;

  constructor() {
    super(...arguments);
    this.statsig.logEvent('lose-free-workplace-modal-viewed');
  }

  @action
  keepPlanClicked() {
    this.statsig.logEvent('lose-free-workplace-modal-button-clicked', 'keep_plan');
    this.args.onClose();
  }

  @action
  continueClicked() {
    this.statsig.logEvent('lose-free-workplace-modal-button-clicked', 'continue_to_downgrade');
    this.args.onClose();
    // the request type is either 'cancel' or 'downgrade'
    if (this.args.prosperStackRequestType) {
      this.args.continueToProsperStack(this.args.prosperStackRequestType);
    } else {
      this.args.continueToDowngrade();
    }
  }
}
