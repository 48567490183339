import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { MessageType } from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

export interface QueryData {
  id?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // For other properties that might be in data
}

export type ScheduledReportEvent = 'showCreateModal' | 'closeCreateModal' | 'showToaster';

export interface ScheduledReportMessage {
  event: ScheduledReportEvent;
  query?: {
    data?: QueryData;
  };
  type?: MessageType;
  msg?: string;
}

export default class AnalyticsLocationSigmaReportsIndexController extends Controller {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare messageBus: MessageBusService;

  @tracked loginRequired = false;
  @tracked showModal = false;
  @tracked modalIframeUrl = '';
  @tracked modalData = {};

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  get iframeUrl(): string {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    return urlBuilder.scheduledReportsV2LocationUrl(this.loginRequired, companyId, locationId);
  }

  @action
  toggleShowModal(): void {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message: ScheduledReportMessage): void {
    const data = message?.query?.data || {};
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
    } = this.state;
    if (message.event === 'showCreateModal') {
      this.modalData = data;
      this.modalIframeUrl = urlBuilder.sigmaScheduleReportsModalUrlLocation(companyId, locationId, this.loginRequired);
      this.toggleShowModal();
    } else if (message.event === 'closeCreateModal') {
      this.showModal = false;
    } else if (message.event === 'showToaster') {
      const type = message.type as MessageType;
      const msg = message.msg || '';
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
