import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { buildWaiter, waitFor } from '@ember/test-waiters';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import {
  addDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  formatISO,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask, enqueueTask, restartableTask, timeout } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type Invite from 'garaje/models/invite';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import { or } from 'macro-decorators';

import { type VisitorsApprovalsIndexModel } from './route';

// The date presets that should appear in the date range dropdown, in the order they should appear.
const DATE_RANGE_FILTER_OPTIONS = [
  'Today',
  'The next 2 days',
  'This week',
  'Next 7 days',
  'Next 30 days',
  'This month',
  'Past 7 days',
  'Past 30 days',
];

// Calculate ranges for each date preset.
const DATE_OPTIONS = {
  'Next 30 days': (today: Date): [Date, Date] => [today, addDays(today, 30)],
  'Next 7 days': (today: Date): [Date, Date] => [today, addDays(today, 7)],
  'Past 30 days': (today: Date): [Date, Date] => [subDays(today, 30), today],
  'Past 7 days': (today: Date): [Date, Date] => [subDays(today, 7), today],
  'The next 2 days': (today: Date): [Date, Date] => [today, addDays(today, 2)],
  'This month': (today: Date): [Date, Date] => [startOfMonth(today), endOfMonth(today)],
  'This week': (today: Date): [Date, Date] => [startOfWeek(today), endOfWeek(today)],
  Today: (today: Date): [Date, Date] => [today, today],
};

const DEBOUNCE_TIMEOUT = 300; // time in ms for debouncing 'query' field

const QUERY_PARAM_DATE_FORMAT = 'yyyy-MM-dd';

type DateRangeFilter = { from: string; to: string };
type ApprovalsFilters = { [key: string]: string | DateRangeFilter };

const bulkApproveInvitesWaiter = buildWaiter('visitors-approvals-index-controller:bulkApproveInvites');
const bulkDenyInvitesWaiter = buildWaiter('visitors-approvals-index-controller:bulkDenyInvites');

export default class VisitorsApprovalsIndexController extends Controller {
  @service declare abilities: AbilitiesService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare store: Store;
  @service declare featureFlags: FeatureFlagsService;
  @service declare currentAdmin: CurrentAdminService;
  declare model: VisitorsApprovalsIndexModel;

  dateRangePresets = DATE_OPTIONS;
  dateRangePresetsOrder = DATE_RANGE_FILTER_OPTIONS;

  @tracked query = '';
  @tracked start: string | null = null;
  @tracked end: string | null = null;
  @tracked sortBy = 'dueAt';
  @tracked sortDirection = 'asc';

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  queryParams = ['end', 'query', 'sortBy', 'sortDirection', 'start'];

  get columns(): string[] {
    return this.model.columnOptions.filter((column) => column.show).map((column) => column.value);
  }

  get filters(): ApprovalsFilters {
    const filters: ApprovalsFilters = {};
    if (this.state.currentLocation?.id) {
      filters['location'] = this.state.currentLocation.id;
    }
    if (this.query) {
      filters['query'] = this.query;
    }
    filters['arrival-time'] = {
      from: formatISO(this.startDate),
      to: formatISO(this.endDate),
    };

    return filters;
  }

  get endDate(): Date {
    // return Date object based on the string from the query param
    const date = this.end ? parse(this.end, QUERY_PARAM_DATE_FORMAT, new Date()) : new Date();
    return endOfDay(date);
  }

  get startDate(): Date {
    // return Date object based on the string from the query param
    const date = this.start ? parse(this.start, QUERY_PARAM_DATE_FORMAT, new Date()) : new Date();
    return startOfDay(date);
  }

  approveInviteTask = enqueueTask(
    waitFor(async (removeInvitesFromView: (invites: Invite[]) => void, invite: Invite): Promise<void> => {
      try {
        await invite.approveInvite();
        await invite.reload();

        let additionalFlashMessageText;
        if (!invite.preregistrationComplete) {
          if (invite.approved) {
            additionalFlashMessageText = 'Invitation sent!';
          } else if (invite.needsPropertyApprovalReview) {
            // only flash this message  if a report with a property based source is present
            additionalFlashMessageText =
              'Additional approval from the property manager is required before the invitation is sent.';
          }
        }

        this.flashMessages.showAndHideFlash('success', 'Visitor approved', additionalFlashMessageText);
        const sourcesForApprovalReviewMetrics = (invite.approvalStatus?.failedReport || []).reduce(
          (sources, report) => ({ ...sources, [report.source]: true }),
          {},
        );
        this.metrics.trackEvent('Dashboard Invite - Reviewed', {
          action: 'approve',
          invite_id: invite.id,
          source: 'Approvals page',
          ...sourcesForApprovalReviewMetrics,
        });
        removeInvitesFromView([invite]);
      } catch (error) {
        this.flashMessages.showAndHideFlash('error', 'Error approving invite', parseErrorForDisplay(error));
      } finally {
        const currentLocation = this.currentLocation.location;
        if (currentLocation.capacityLimitEnabled) {
          await fetchCapacity(this.store, currentLocation, invite.expectedArrivalTime);
        }
      }
    }),
  );

  bulkApproveInvitesTask = dropTask(
    async (removeInvitesFromView: (invites: Invite[]) => void, invites: Invite[]): Promise<Invite[]> => {
      const token = bulkApproveInvitesWaiter.beginAsync();
      const approvedInvites: Invite[] = [];
      const failedInvites: Invite[] = [];
      let failedInviteCount = 0;

      for (const invite of invites) {
        try {
          await invite.approveInvite();
          await invite.reload();

          approvedInvites.push(invite);
        } catch (_error) {
          failedInvites.push(invite);
          failedInviteCount++;
        }
      }

      removeInvitesFromView(approvedInvites);

      if (approvedInvites.length === invites.length) {
        // all invites approved successfully
        this.flashMessages.showAndHideFlash('success', `${pluralize(approvedInvites.length, 'visitor')} approved`);
      } else if (failedInviteCount > 0 && approvedInvites.length > 0) {
        // some (but not all) invites failed
        this.flashMessages.showAndHideFlash(
          'error',
          `Approving ${pluralize(failedInviteCount, 'visitor')} failed`,
          `${pluralize(approvedInvites.length, 'visitors')} were approved`,
        );
      } else {
        // all invites failed
        this.flashMessages.showAndHideFlash(
          'error',
          `Approving ${pluralize(failedInviteCount, 'visitor')} failed`,
          'Please try again later.',
        );
      }

      bulkApproveInvitesWaiter.endAsync(token);

      return approvedInvites;
    },
  );

  bulkDenyInvitesTask = dropTask(
    async (removeInvitesFromView: (invites: Invite[]) => void, invites: Invite[]): Promise<Invite[]> => {
      const deniedInvites: Invite[] = [];
      const failedInvites: Invite[] = [];
      let failedInviteCount = 0;

      const token = bulkDenyInvitesWaiter.beginAsync();

      for (const invite of invites) {
        try {
          await invite.denyInvite();
          await invite.reload();

          deniedInvites.push(invite);
        } catch (_error) {
          failedInvites.push(invite);
          failedInviteCount++;
        }
      }

      removeInvitesFromView(deniedInvites);

      if (deniedInvites.length === invites.length) {
        // all invites denied successfully
        this.flashMessages.showAndHideFlash('success', `${pluralize(deniedInvites.length, 'visitor')} denied`);
      } else if (failedInviteCount > 0 && deniedInvites.length > 0) {
        // some (but not all) invites failed
        this.flashMessages.showAndHideFlash(
          'error',
          `Denying ${pluralize(failedInviteCount, 'visitor')} failed`,
          `${pluralize(deniedInvites.length, 'visitors')} were denied`,
        );
      } else {
        // all invites failed
        this.flashMessages.showAndHideFlash(
          'error',
          `Denying ${pluralize(failedInviteCount, 'visitor')} failed`,
          'Please try again later.',
        );
      }

      bulkDenyInvitesWaiter.endAsync(token);

      return deniedInvites;
    },
  );

  denyInviteTask = enqueueTask(
    waitFor(async (removeInvitesFromView: (invites: Invite[]) => void, invite: Invite): Promise<void> => {
      try {
        await invite.denyInvite();
        await invite.reload();

        this.flashMessages.showAndHideFlash('success', 'Visitor denied');

        removeInvitesFromView([invite]);
      } catch (error) {
        this.flashMessages.showAndHideFlash('error', 'Error denying invite', parseErrorForDisplay(error));
      }
    }),
  );

  @action
  selectDateRange(startDate: Date, endDate: Date): void {
    this.start = format(startDate, QUERY_PARAM_DATE_FORMAT);
    this.end = format(endDate, QUERY_PARAM_DATE_FORMAT);
  }

  @action
  setSort(field: string, direction: string): void {
    this.sortBy = field;
    this.sortDirection = direction;
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  setQueryTask = restartableTask(async (reloadData: () => void, value: string) => {
    await timeout(DEBOUNCE_TIMEOUT);
    this.query = value;
    reloadData();
  });
}
