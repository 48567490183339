import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type CarrierModel from './carrier';
import type DeliveryAreaModel from './delivery-area';
import type DeliveryDeviceModel from './delivery-device';
import type EmployeeModel from './employee';
import type UserModel from './user';

@adapter('deliveries')
class DeliveryModel extends Model {
  @belongsTo('carrier', { async: true }) declare carrier: AsyncBelongsTo<CarrierModel>;
  @belongsTo('user', { async: true }) declare creator: AsyncBelongsTo<UserModel>;
  @belongsTo('delivery-area', { async: true }) declare deliveryArea: AsyncBelongsTo<DeliveryAreaModel>;
  @belongsTo('employee', { async: true }) declare pickedUpBy: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('delivery-device', { async: true }) declare pickedUpByKiosk: AsyncBelongsTo<DeliveryDeviceModel>;
  @belongsTo('employee', { async: true }) declare recipientEmployee: AsyncBelongsTo<EmployeeModel>;

  @attr('date') declare acknowledgedAt: Date;
  @attr('date') declare createdAt: Date;
  @attr('string') declare imageUrl: string;
  @attr('string') declare notes: string;
  @attr('string') declare recipientName: string;
  @attr('string') declare signOutPhotoThumbnailUrl: string;
  @attr('string') declare signOutPhotoUrl: string;
  @attr('string') declare signOutSignatureUrl: string;
  @attr('string') declare thumbnailUrl: string;
  @attr('string') declare trackingNumber: string;

  get hasProofOfPickUp(): boolean {
    return !!(this.signOutPhotoUrl || this.signOutSignatureUrl);
  }
}

export default DeliveryModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    delivery: DeliveryModel;
  }
}
