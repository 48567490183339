import type StoreService from '@ember-data/store';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';

type Point = [number, number];
type PolygonArray = Array<Point>;
type NeighborhoodPolygons = {
  [neighborhoodId: string]: PolygonArray[];
};

interface NeighborhoodToDesksMap {
  [neighborhoodId: string]: DeskModel[];
}

function getMargins(
  mapWidth: number,
  mapHeight: number,
): { XMARGIN: number; YMARGIN: number; normalizingFactor: number } {
  const normalizingFactor = mapWidth > mapHeight ? mapWidth / mapHeight : mapHeight / mapWidth;
  const MARGIN_LENGTH = 0.025;
  const XMARGIN = mapWidth > mapHeight ? MARGIN_LENGTH / normalizingFactor : MARGIN_LENGTH;
  const YMARGIN = mapHeight > mapWidth ? MARGIN_LENGTH / normalizingFactor : MARGIN_LENGTH;
  return { XMARGIN, YMARGIN, normalizingFactor };
}

export function getNeighborhoodPolygons(
  deskFeatures: MapFeatureModel[],
  mapDimensions: number[],
  store: StoreService,
): NeighborhoodPolygons {
  const neighborhoodToDesksMap: NeighborhoodToDesksMap = {};
  const desksOnFloor = deskFeatures.map((deskFeature: MapFeatureModel) => {
    if (deskFeature.externalId) {
      return store.peekRecord('desk', String(deskFeature.externalId));
    } else {
      return deskFeature.desk;
    }
  });
  desksOnFloor.forEach((desk) => {
    if (desk) {
      const neighborhoodId: string = desk.belongsTo('neighborhood').id();
      if (neighborhoodId) {
        if (neighborhoodToDesksMap[neighborhoodId]) {
          neighborhoodToDesksMap[neighborhoodId].push(desk);
        } else {
          neighborhoodToDesksMap[neighborhoodId] = [desk];
        }
      }
    }
  });
  const [mapWidth, mapHeight] = mapDimensions;
  const { XMARGIN, YMARGIN, normalizingFactor } = getMargins(mapWidth!, mapHeight!);
  const isWideFloor = mapWidth! > mapHeight!;
  const delta = 0.005 / normalizingFactor;

  const polygonCoords: NeighborhoodPolygons = {};
  Object.keys(neighborhoodToDesksMap).map((neighborhoodId) => {
    const desks: DeskModel[] = neighborhoodToDesksMap[neighborhoodId]!;
    const deskClusters = getDeskClusters(desks);

    deskClusters.forEach((cluster) => {
      const exteriorDesks = findExteriorDesks(cluster);
      const exteriorIntersections = findExteriorIntersections(exteriorDesks);
      const [topLeft, topRight, bottomRight, bottomLeft] = exteriorIntersections;

      const topRightFoldedPoints = foldTopRightCorner(exteriorIntersections, cluster);
      const topRightPoints = topRightFoldedPoints.length ? topRightFoldedPoints : [topRight];

      const bottomRightFoldedPoints = foldBottomRightCorner(exteriorIntersections, cluster);
      const bottomRightPoints = bottomRightFoldedPoints.length ? bottomRightFoldedPoints : [bottomRight];

      const topLeftFoldedPoints = foldTopLeftCorner(exteriorIntersections, cluster);
      const topLeftPoints = topLeftFoldedPoints.length ? topLeftFoldedPoints : [topLeft];

      const bottomLeftFoldedPoints = foldBottomLeftCorner(exteriorIntersections, cluster);
      const bottomLeftPoints = bottomLeftFoldedPoints.length ? bottomLeftFoldedPoints : [bottomLeft];

      const tightPolygon = [
        ...topLeftPoints,
        ...topRightPoints,
        ...bottomRightPoints,
        ...bottomLeftPoints,
      ] as PolygonArray;
      if (polygonCoords[neighborhoodId]) {
        polygonCoords[neighborhoodId].push(tightPolygon);
      } else {
        polygonCoords[neighborhoodId] = [tightPolygon];
      }
    });
  });
  return polygonCoords;

  function foldTopRightCorner(bounds: PolygonArray, cluster: DeskModel[]): PolygonArray {
    const [_topLeft, topRight, _bottomRight, _bottomLeft] = bounds;

    if (!topRight) {
      return [];
    }

    let isDeskInHorizontalBound = false;
    let isDeskInVerticalBound = false;
    let checkingWidth = topRight[0];
    let checkingHeight = topRight[1];
    let foldPoints: PolygonArray = [
      [checkingWidth, topRight[1]],
      [checkingWidth, checkingHeight],
      [topRight[0], checkingHeight],
    ];

    for (let i = 1; ; i++) {
      if (!isDeskInHorizontalBound) {
        checkingWidth = topRight[0] - delta * i;
      }

      if (!isDeskInVerticalBound) {
        checkingHeight = topRight[1] + delta * i;
      }

      if (checkingWidth < 0 || checkingHeight > 1) {
        return [];
      }

      // Check if desk is in bounds
      if (!isDeskInHorizontalBound) {
        isDeskInHorizontalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const horizontalCheckingBounds: PolygonArray = [topRight, [checkingWidth, checkingHeight - delta]];
          return isPointInBoxHorizontal(point, horizontalCheckingBounds);
        });

        if (isDeskInHorizontalBound) {
          checkingWidth = checkingWidth + delta;
        }
      }

      if (!isDeskInVerticalBound) {
        isDeskInVerticalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const verticalCheckingBounds: PolygonArray = [topRight, [checkingWidth + delta, checkingHeight]];
          return isPointInBoxVertical(point, verticalCheckingBounds);
        });

        if (isDeskInVerticalBound) {
          checkingHeight = checkingHeight - delta;
        }
      }

      if (isDeskInHorizontalBound && isDeskInVerticalBound) {
        return foldPoints;
      } else {
        foldPoints = [
          [checkingWidth, topRight[1]],
          [checkingWidth, checkingHeight],
          [topRight[0], checkingHeight],
        ];
      }
    }
  }

  function foldBottomRightCorner(bounds: PolygonArray, cluster: DeskModel[]): PolygonArray {
    const [_topLeft, _topRight, bottomRight, _bottomLeft] = bounds;

    if (!bottomRight) {
      return [];
    }

    let isDeskInHorizontalBound = false;
    let isDeskInVerticalBound = false;
    let checkingWidth = bottomRight[0];
    let checkingHeight = bottomRight[1];
    let foldPoints: PolygonArray = [
      [bottomRight[0], checkingHeight],
      [checkingWidth, checkingHeight],
      [checkingWidth, bottomRight[1]],
    ];

    for (let i = 1; ; i++) {
      if (!isDeskInHorizontalBound) {
        checkingWidth = bottomRight[0] - delta * i;
      }

      if (!isDeskInVerticalBound) {
        checkingHeight = bottomRight[1] - delta * i;
      }

      if (checkingWidth < 0 || checkingHeight < 0) {
        return [];
      }

      // Check if desk is in bounds
      if (!isDeskInHorizontalBound) {
        isDeskInHorizontalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const horizontalCheckingBounds: PolygonArray = [
            [bottomRight[0], checkingHeight + delta],
            [checkingWidth, bottomRight[1]],
          ];
          return isPointInBoxHorizontal(point, horizontalCheckingBounds);
        });

        if (isDeskInHorizontalBound) {
          checkingWidth = checkingWidth + delta;
        }
      }

      if (!isDeskInVerticalBound) {
        isDeskInVerticalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const verticalCheckingBounds: PolygonArray = [
            [bottomRight[0], checkingHeight],
            [checkingWidth + delta, bottomRight[1]],
          ];
          return isPointInBoxVertical(point, verticalCheckingBounds);
        });

        if (isDeskInVerticalBound) {
          checkingHeight = checkingHeight + delta;
        }
      }

      if (isDeskInHorizontalBound && isDeskInVerticalBound) {
        return foldPoints;
      } else {
        foldPoints = [
          [bottomRight[0], checkingHeight],
          [checkingWidth, checkingHeight],
          [checkingWidth, bottomRight[1]],
        ];
      }
    }
  }

  function foldTopLeftCorner(bounds: PolygonArray, cluster: DeskModel[]): PolygonArray {
    const [topLeft, _topRight, _bottomRight, _bottomLeft] = bounds;

    if (!topLeft) {
      return [];
    }

    let isDeskInHorizontalBound = false;
    let isDeskInVerticalBound = false;
    let checkingWidth = topLeft[0];
    let checkingHeight = topLeft[1];
    let foldPoints: PolygonArray = [
      [topLeft[0], checkingHeight],
      [checkingWidth, checkingHeight],
      [checkingWidth, topLeft[1]],
    ];

    for (let i = 1; ; i++) {
      if (!isDeskInHorizontalBound) {
        checkingWidth = topLeft[0] + delta * i;
      }

      if (!isDeskInVerticalBound) {
        checkingHeight = topLeft[1] + delta * i;
      }

      if (checkingWidth > 1 || checkingHeight > 1) {
        return [];
      }

      // Check if desk is in bounds
      if (!isDeskInHorizontalBound) {
        isDeskInHorizontalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const horizontalCheckingBounds: PolygonArray = [
            [checkingWidth, topLeft[1]],
            [topLeft[0], checkingHeight - delta],
          ];
          return isPointInBoxHorizontal(point, horizontalCheckingBounds);
        });

        if (isDeskInHorizontalBound) {
          checkingWidth = checkingWidth - delta;
        }
      }

      if (!isDeskInVerticalBound) {
        isDeskInVerticalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const verticalCheckingBounds: PolygonArray = [
            [checkingWidth - delta, topLeft[1]],
            [topLeft[0], checkingHeight],
          ];
          return isPointInBoxVertical(point, verticalCheckingBounds);
        });

        if (isDeskInVerticalBound) {
          checkingHeight = checkingHeight - delta;
        }
      }

      if (isDeskInHorizontalBound && isDeskInVerticalBound) {
        return foldPoints;
      } else {
        foldPoints = [
          [topLeft[0], checkingHeight],
          [checkingWidth, checkingHeight],
          [checkingWidth, topLeft[1]],
        ];
      }
    }
  }

  function foldBottomLeftCorner(bounds: PolygonArray, cluster: DeskModel[]): PolygonArray {
    const [_topLeft, _topRight, _bottomRight, bottomLeft] = bounds;

    if (!bottomLeft) {
      return [];
    }

    let isDeskInHorizontalBound = false;
    let isDeskInVerticalBound = false;
    let checkingWidth = bottomLeft[0];
    let checkingHeight = bottomLeft[1];
    let foldPoints: PolygonArray = [
      [checkingWidth, bottomLeft[1]],
      [checkingWidth, checkingHeight],
      [bottomLeft[0], checkingHeight],
    ];

    for (let i = 1; ; i++) {
      if (!isDeskInHorizontalBound) {
        checkingWidth = bottomLeft[0] + delta * i;
      }

      if (!isDeskInVerticalBound) {
        checkingHeight = bottomLeft[1] - delta * i;
      }

      if (checkingWidth > 1 || checkingHeight < 0) {
        return [];
      }

      // Check if desk is in bounds
      if (!isDeskInHorizontalBound) {
        isDeskInHorizontalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const horizontalCheckingBounds: PolygonArray = [
            [checkingWidth, checkingHeight + delta],
            [bottomLeft[0], bottomLeft[1]],
          ];
          return isPointInBoxHorizontal(point, horizontalCheckingBounds);
        });

        if (isDeskInHorizontalBound) {
          checkingWidth = checkingWidth - delta;
        }
      }

      if (!isDeskInVerticalBound) {
        isDeskInVerticalBound = cluster.some((desk) => {
          const point: Point = [desk.xPos, desk.yPos];
          const verticalCheckingBounds: PolygonArray = [
            [checkingWidth - delta, checkingHeight],
            [bottomLeft[0], bottomLeft[1]],
          ];
          return isPointInBoxVertical(point, verticalCheckingBounds);
        });

        if (isDeskInVerticalBound) {
          checkingHeight = checkingHeight + delta;
        }
      }

      if (isDeskInHorizontalBound && isDeskInVerticalBound) {
        return foldPoints;
      } else {
        foldPoints = [
          [checkingWidth, bottomLeft[1]],
          [checkingWidth, checkingHeight],
          [bottomLeft[0], checkingHeight],
        ];
      }
    }
  }

  function isPointInBoxHorizontal(point: Point, box: PolygonArray): boolean {
    // Extract the bounding box corners (assuming it is axis-aligned)
    const [topRight, bottomLeft] = box;

    const pointIsInWidth = point[0] + XMARGIN >= bottomLeft![0] && point[0] - XMARGIN <= topRight![0];
    const pointIsInHeight = point[1] - YMARGIN <= bottomLeft![1] && point[1] + YMARGIN >= topRight![1];
    // Check if the point lies within the bounds
    const pointIsInBox = pointIsInWidth && pointIsInHeight;

    if (pointIsInBox && pointIsInWidth) {
      return true;
    }
    return false;
  }

  function isPointInBoxVertical(point: Point, box: PolygonArray): boolean {
    // Extract the bounding box corners (assuming it is axis-aligned)
    const [topRight, bottomLeft] = box;
    // Check if the point lies within the bounds
    const pointIsInWidth = point[0] + XMARGIN >= bottomLeft![0] && point[0] - XMARGIN <= topRight![0];
    const pointIsInHeight = point[1] - YMARGIN <= bottomLeft![1] && point[1] + YMARGIN >= topRight![1];
    // Check if the point lies within the bounds
    const pointIsInBox = pointIsInWidth && pointIsInHeight;
    if (pointIsInBox && pointIsInHeight) {
      return true;
    }
    return false;
  }

  function findExteriorDesks(desks: DeskModel[]): DeskModel[] {
    let leftMost = desks[0]!;
    let rightMost = desks[0]!;
    let topMost = desks[0]!;
    let bottomMost = desks[0]!;

    desks.forEach((desk) => {
      if (desk.xPos < leftMost.xPos) {
        leftMost = desk;
      }
      if (desk.xPos > rightMost.xPos) {
        rightMost = desk;
      }
      if (desk.yPos < topMost.yPos) {
        topMost = desk;
      }
      if (desk.yPos > bottomMost.yPos) {
        bottomMost = desk;
      }
    });
    return [leftMost, topMost, rightMost, bottomMost];
  }

  function findExteriorIntersections(exteriorDesks: DeskModel[]): PolygonArray {
    // Find the intersection of the lines that connect the exterior desks
    const [leftMost, topMost, rightMost, bottomMost] = exteriorDesks;
    const topLeft: Point = [leftMost!.xPos - XMARGIN, topMost!.yPos - YMARGIN];
    const topRight: Point = [rightMost!.xPos + XMARGIN, topMost!.yPos - YMARGIN];
    const bottomLeft: Point = [leftMost!.xPos - XMARGIN, bottomMost!.yPos + YMARGIN];
    const bottomRight: Point = [rightMost!.xPos + XMARGIN, bottomMost!.yPos + YMARGIN];

    return [topLeft, topRight, bottomRight, bottomLeft];
  }

  function getDeskClusters(desks: DeskModel[]) {
    const deskStack: DeskModel[] = [];
    const visited: { [deskId: string]: boolean } = {};
    const clusters: DeskModel[][] = [];

    desks.forEach((desk) => {
      if (visited[desk.id]) return;
      deskStack.push(desk);
      let cluster: DeskModel[] = [];
      while (deskStack.length) {
        const currentDesk = deskStack.pop();
        if (visited[currentDesk!.id]) continue;
        visited[currentDesk!.id] = true;
        const neighbors = getNeighbors(currentDesk!, desks);
        cluster.push(currentDesk!);
        deskStack.push(...neighbors);
      }
      clusters.push(cluster);
      cluster = [];
    });
    return clusters;
  }

  function getNeighbors(desk: DeskModel, desks: DeskModel[]) {
    const neighbors: DeskModel[] = [];
    desks.forEach((neighbor) => {
      if (neighbor.id === desk.id) return;
      const distance = getDistance(desk, neighbor);
      if (distance < 0.085) {
        neighbors.push(neighbor);
      }
    });
    return neighbors;
  }

  function getDistance(desk1: DeskModel, desk2: DeskModel): number {
    let width = Math.abs(desk1.xPos - desk2.xPos);
    let height = Math.abs(desk1.yPos - desk2.yPos);
    width = isWideFloor ? width * normalizingFactor : width;
    height = isWideFloor ? height : height * normalizingFactor;
    return Math.hypot(width, height);
  }
}
