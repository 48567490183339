import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set, get } from '@ember/object';
import { service } from '@ember/service';
import { A } from '@ember/array';
import move from 'ember-animated/motions/move';
import { fadeIn, fadeOut } from 'ember-animated/motions/opacity';
import { dropTask } from 'ember-concurrency';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import LOCALE_OPTIONS, { disabledLocalesInfo, localeUpdateWarning } from 'garaje/utils/locale-options';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const MINIMUM_IPAD_VERSION_NEEDED = '3.2.0';

export default class SettingsWelcomeScreenMultipleLanguagesController extends Controller {
  @service flashMessages;
  @service localStorage;
  @service metrics;
  @service impressions;
  @service state;
  @service router;

  @tracked isEditing = false;
  @tracked isShowingAddedMessage = false;
  localeOptions = LOCALE_OPTIONS;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED) isAppUpdateRequired;
  @disabledLocalesInfo('model.ipads') disabledLocalesInfo;
  @localeUpdateWarning() localeUpdateWarning;

  get availableLocales() {
    const enabledLocales = [...this.model.location.enabledLocales, this.model.location.locale];
    const disabledLocales = this.disabledLocalesInfo ? this.disabledLocalesInfo.disabledLocales : [];
    const disabledLocaleCodes = new Set(disabledLocales.map((locale) => locale.localeCode));
    return this.localeOptions
      .filter((option) => !enabledLocales.includes(option.value))
      .map(({ value, label }) => {
        const disabled = disabledLocaleCodes.has(value);
        return { value, label, disabled };
      });
  }

  get isVirtualFrontDesk() {
    const currentRoute = this.router.currentRouteName;
    return currentRoute.includes('virtual-front-desk');
  }

  get noticeAlreadyShown() {
    return !!this.localStorage.getItem('language_added_message');
  }

  /* eslint-disable require-yield */
  *transition({ insertedSprites, keptSprites, removedSprites }) {
    keptSprites.forEach(move);
    insertedSprites.forEach(fadeIn);
    removedSprites.forEach(fadeOut);
  }
  /* eslint-enable require-yield */

  @action
  rollback() {
    const { location } = this.model;
    location.rollbackAttributes();
  }

  trackMultipleLanguage(eventName) {
    const { currentLocation, currentUser } = this.state;
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const trackingEventProperties = {
      company_id: get(currentLocation, 'company.id'),
      location_id: get(currentLocation, 'id'),
      user_id: get(currentUser, 'id'),
      enable_languages: this.model.location.enabledLocales,
      timestamp,
    };
    this.metrics.trackEvent(eventName, trackingEventProperties);
  }

  @dropTask
  *toggleMultipleLanguages(value) {
    const { location } = this.model;

    set(location, 'multipleLanguagesEnabled', value);

    try {
      yield location.save();
      this.isEditing = value;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      yield this.impressions.postImpression.perform(
        IMPRESSION_NAMES.VR_WELCOME_SCREEN_MULTIPLE_LANGUAGES[value ? 'ENABLED' : 'DISABLED'],
      );
      const eventName = value ? 'Multiple Languages Enabled' : 'Multiple Languages Disabled';
      this.trackMultipleLanguage(eventName);
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *saveTask(redirectTo = '') {
    const { location } = this.model;

    try {
      yield location.save();
      this.isEditing = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.trackMultipleLanguage('Multiple Languages Edited');
      if (redirectTo) {
        this.isShowingAddedMessage = false;
        this.router.transitionTo(redirectTo);
      }
    } catch (e) {
      location.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  messageDisplayed() {
    this.localStorage.setItem('language_added_message', true);
  }

  @action
  addLocale(option) {
    const { location } = this.model;
    const locales = A([...location.enabledLocales]);
    locales.addObject(option.value);
    set(location, 'enabledLocales', locales); // Needed to make it dirty
    if (!this.noticeAlreadyShown) {
      this.isShowingAddedMessage = true;
    }
  }

  @action
  removeLocale(option) {
    const { location } = this.model;
    const locales = A([...location.enabledLocales]);
    locales.removeObject(option.value);
    set(location, 'enabledLocales', locales); // Needed to make it dirty
  }
}
