import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

function decorateClass(Class, options) {
  const DecoratedClass = class extends Class {
    @service visitorsOnboarding;

    async save() {
      const onboardingAttributesChanged = this._onboardingChanges(Object.keys(this.changedAttributes()));
      const result = await super.save(...arguments);

      const onboardingChecksOptions = options ?? this.constructor.ONBOARDING_CHECKS;
      if (
        this.visitorsOnboarding.showVideoWalkthrough &&
        onboardingChecksOptions &&
        isPresent(onboardingAttributesChanged)
      ) {
        this.visitorsOnboarding[onboardingChecksOptions.taskName].perform();
      }
      return result;
    }

    async destroyRecord() {
      const result = super.destroyRecord(...arguments);

      const onboardingChecksOptions = options ?? this.constructor.ONBOARDING_CHECKS;
      if (this.visitorsOnboarding.showVideoWalkthrough && onboardingChecksOptions) {
        this.visitorsOnboarding[onboardingChecksOptions.taskName].perform();
      }
      return result;
    }

    _onboardingChanges(changes) {
      const onboardingChecksOptions = options ?? this.constructor.ONBOARDING_CHECKS;
      const attributes = onboardingChecksOptions?.attributes || [];
      return changes.filter((attr) => attributes.includes(attr));
    }
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}

/**
 * @returns {*}
 */
export default function (classOrOptions) {
  if (typeof classOrOptions === 'function') {
    // bare decorator
    return decorateClass(classOrOptions);
  } else {
    // decorator with options, e.g., @OnboardingChangesTracker({ ... })
    return function (Class) {
      return decorateClass(Class, classOrOptions);
    };
  }
}
