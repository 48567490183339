import { A } from '@ember/array';
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { isPresent } from '@ember/utils';
import type { AsyncBelongsTo } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import type { TaskInstance } from 'ember-concurrency';
import { task, dropTask } from 'ember-concurrency';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import type EntryModel from 'garaje/models/entry';
import type LocationsCapacityModel from 'garaje/models/locations-capacity';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type PerformanceLoadTime from 'garaje/services/performance-load-time';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import _intersection from 'lodash/intersection';
import { reads } from 'macro-decorators';

import type VisitorsEntriesController from '../controller';

import type { VisitorsEntriesIndexRouteModel } from './route';

export default class VisitorsEntriesIndexController extends Controller {
  declare model: VisitorsEntriesIndexRouteModel;

  @service declare currentLocation: CurrentLocationService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare performanceLoadTime: PerformanceLoadTime;
  @service declare store: StoreService;

  @controller('visitors.entries') entries!: VisitorsEntriesController;

  @reads('entries.eventReports') eventReports!: VisitorsEntriesController['eventReports'];
  @reads('entries.isToday') isToday!: VisitorsEntriesController['isToday'];
  @reads('entries.isAfterToday') isAfterToday!: VisitorsEntriesController['isAfterToday'];
  @reads('entries.isSingleDay') isSingleDay!: VisitorsEntriesController['isSingleDay'];
  @reads('entries.startDateWithDefault') startDateWithDefault!: VisitorsEntriesController['startDateWithDefault'];
  @reads('entries.endDateWithDefault') endDateWithDefault!: VisitorsEntriesController['endDateWithDefault'];
  @reads('entries.timezone') timezone!: VisitorsEntriesController['timezone'];

  @tracked locationsCapacity?: LocationsCapacityModel;

  get selectedDate(): Date | undefined {
    // a "day" is not selected if the range is not a single day
    if (!this.entries.isSingleDay) return;
    return toZonedTime(this.entries.startDateWithDefault, this.timezone);
  }

  get signInFieldPages(): AsyncBelongsTo<SignInFieldPageModel>[] {
    return this.currentLocation.location.flows.mapBy('signInFieldPage');
  }

  get allSignInFieldsFulfilled(): boolean {
    return A(this.signInFieldPages).isEvery('isFulfilled');
  }

  get showVideo(): boolean {
    const canSeeVideo = isPresent(_intersection(['Global Admin', 'Location Admin'], this.currentAdmin.roleNames));
    return this.entries.noHistoricVisitors && canSeeVideo;
  }

  get sortField(): string {
    return this.entries.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.entries.sort.startsWith('-') ? 'desc' : 'asc';
  }

  get menuHeight(): string {
    // Keep a minimum of 13px space from the table
    return `${document.querySelector('.visitorsTable-header')!.clientHeight + 13}px`;
  }

  get isSelectAllIndeterminate(): boolean {
    const selectedCount = this.entries.selectedEntries.length;
    const totalEntries = this.entries.relevantEntries.length;
    return selectedCount > 0 && selectedCount !== totalEntries;
  }

  get allEntriesSelected(): boolean {
    const selectedCount = this.entries.selectedEntries.length;
    const totalEntries = this.entries.relevantEntries.length;
    return selectedCount === totalEntries;
  }

  @action
  clearAll(): void {
    return this.entries.clearAll();
  }

  @action
  selectedDidChange(selected: EntryModel[]): void {
    return this.entries.selectedDidChange(selected);
  }

  signOutTask = dropTask(async (entry: EntryModel, actionOrigin: string) => {
    this.metrics.trackEvent('Dashboard Entry - Signed Out', { action_origin: actionOrigin });
    await this.entries.signOutEntry.perform(entry);
    await this.refreshLocationsCapacity();
  });

  @action
  checkID(entry: EntryModel): Promise<void> {
    return this.entries.checkID(entry);
  }

  @action
  reprintBadge(entry: EntryModel, printer: PrinterModel | null = null): Promise<void> {
    // if a printer is specified then try to use the first/only available printer, otherwise it'll fall back to v2 api entry.printBadge
    printer = printer || this.model.printers?.firstObject || null;
    return this.entries.reprintBadge(entry, printer);
  }

  @action
  deleteEntry(entry: EntryModel): void {
    return this.entries.deleteEntry(entry);
  }

  @action
  deleteEntries(entries: EntryModel[]): void {
    return this.entries.deleteEntries(entries.slice());
  }

  @action
  loadMore(): void | Promise<void> | TaskInstance<void> {
    return this.entries.loadMore();
  }

  @action
  sortEntries(field: string, direction: string): void {
    const entriesController = this.entries;
    return entriesController.sortEntries(field, direction);
  }

  @action
  selectAllEntries(): void {
    return this.entries.selectAllEntries();
  }

  @action logLoadTime(): void {
    this.performanceLoadTime.logPageUsableMetric();
  }

  async refreshLocationsCapacity(): Promise<void> {
    const { location } = this.currentLocation;
    if (location?.capacityLimitEnabled) {
      const start = formatInTimeZone(this.startDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD);
      let end: string | undefined;

      // pass in range if not single day
      if (!this.isSingleDay) {
        end = formatInTimeZone(this.endDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD);
      }

      this.locationsCapacity = await (<Promise<LocationsCapacityModel>>fetchCapacity(this.store, location, start, end));
    }
  }

  approveEntryTask = task(
    waitFor(async (entry: EntryModel) => {
      try {
        await entry.approveEntry();
        const flow = await entry.flow;
        await flow.badge; // load async relationship for entryApprovalMessage helper dependency
        this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));

        this.metrics.trackEvent('Dashboard Entry - Reviewed', {
          action: 'approve',
          entry_id: entry.id,
          source: 'Visitor Log',
          ...this.#sourcesForReviewMetrics(entry),
        });
        await entry.reload();
      } catch (error) {
        this.flashMessages.showAndHideFlash('error', 'Error approving entry', parseErrorForDisplay(error));
      } finally {
        const currentLocation = this.currentLocation.content;
        if (currentLocation.capacityLimitEnabled) {
          await fetchCapacity(this.store, currentLocation, entry.signedInAt);
        }
      }
    }),
  );

  denyEntryTask = task(
    waitFor(async (entry: EntryModel) => {
      try {
        await entry.denyEntry();
        this.flashMessages.showAndHideFlash('warning', 'Entry denied');
        await entry.reload();

        this.metrics.trackEvent('Dashboard Entry - Reviewed', {
          action: 'deny',
          entry_id: entry.id,
          source: 'Visitor Log',
          ...this.#sourcesForReviewMetrics(entry),
        });
      } catch {
        this.flashMessages.showAndHideFlash('error', 'Error denying entry');
      }
    }),
  );

  #sourcesForReviewMetrics(entry: EntryModel): Record<string, boolean> {
    return (entry.approvalStatus?.failedReport || []).reduce(
      (sources: Record<string, boolean>, report: { source: string }) => ({ ...sources, [report.source]: true }),
      {},
    );
  }
}
