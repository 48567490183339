import Component from '@glimmer/component';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';
import { action, get } from '@ember/object';
import { format } from 'date-fns';

/**
 * @param {Object}              company
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 */
export default class GoogleAppsConfigBox extends Component {
  @service currentAdmin;
  @service flashMessages;
  @service integrationsManager;

  get integrationData() {
    return get(this.args.company, 'googleApp');
  }

  get connected() {
    return get(this.integrationData, 'connected');
  }

  get enabled() {
    return get(this.integrationData, 'enabled');
  }

  get domain() {
    return get(this.integrationData, 'domain');
  }

  get connectedBy() {
    return get(this.integrationData, 'connectedBy');
  }

  get isOpen() {
    return this.args.integration === 'googleApp';
  }

  get createdAt() {
    const createdAt = get(this.integrationData, 'createdAt')
      ? new Date(get(this.integrationData, 'createdAt'))
      : new Date();
    return format(createdAt, 'MMMM d, yyyy');
  }

  @action
  close() {
    this.args.closeAction();
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  @action
  open() {
    this.args.openAction('googleApp');
  }

  @dropTask
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    try {
      yield this.integrationsManager.disconnectGoogleApps(this.args.company);
      this.close();
      this.args.integrationDisconnected(true);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *toggleEnabledTask({ target }) {
    try {
      if (target.checked) {
        yield this.integrationsManager.enableGoogleApps(this.args.company);
      } else {
        yield this.integrationsManager.disableGoogleApps(this.args.company);
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
