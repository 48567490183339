import Component from '@glimmer/component';
import { set } from '@ember/object';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Object}             model
 * @param {Boolean}            isACH
 */
export default class InactivelocationsComponent extends Component {
  @service currentAdmin;
  @service state;
  @service store;
  @service router;

  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;

  /**
   * Activates Desks at location if licenses available or on trial
   */
  @dropTask()
  *activateDesksTask() {
    const { currentCompany, currentLocation } = this.state;
    const {
      args: { model },
    } = this;

    let deskLocation = model.currentLocationEnabledDesks;

    if (!deskLocation) {
      deskLocation = this.store.createRecord('desk-location', {
        active: true,
        timeZone: currentLocation.timezone,
        company: currentCompany,
        location: currentLocation,
      });
    } else {
      deskLocation = this.store.peekRecord('desk-location', deskLocation.id);
      set(deskLocation, 'active', true);
    }

    yield deskLocation.save();

    // Reload state service with the new subscription added
    yield this.state.setDeskLocationsTask.perform();

    this.router.transitionTo('desks');
  }
}
