import { action, set } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { getUnixTime } from 'date-fns';
import type TakeoverModel from 'garaje/models/takeover';
import type FlashMessagesService from 'garaje/services/flash-messages';

interface BannerTakeoverArgs {
  takeovers: TakeoverModel[];
}

export default class Takeover extends Component<BannerTakeoverArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;

  @action
  async endTakeover(takeover: TakeoverModel): Promise<void> {
    try {
      const timestamp = getUnixTime(new Date());
      set(takeover, 'endAt', timestamp);
      await takeover.save();
      this.flashMessages.showAndHideFlash('success', 'Takeover ended');
    } catch (_error) {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again. error');
    }
  }
}
