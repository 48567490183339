import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, task } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type SignInFieldActionRuleGroupModel from 'garaje/models/sign-in-field-action-rule-group';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { GROUP_OPERATION_OPTIONS } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { bool } from 'macro-decorators';

type AnyFlowModel = FlowModel | GlobalFlowModel;

interface SignInFieldsRulesModalComponentSignature {
  Args: {
    flow: AnyFlowModel;
    signInFieldActionRuleGroups: SignInFieldActionRuleGroupModel[];
    isLocationAdmin?: boolean;
    isProtect?: boolean;
    isGlobalChild?: boolean;
    onClose: () => void;
  };
}

export default class SignInFieldsRulesModalComponent extends Component<SignInFieldsRulesModalComponentSignature> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;

  // the in-flight rule (creating/editing)
  @tracked currentRule: SignInFieldActionRuleGroupModel | null = null;

  // New rules created since the route loaded
  @tracked newRules: SignInFieldActionRuleGroupModel[] = [];

  // isEditing flag is a template helper to prevent flickering
  @tracked isEditing = false;

  // the selected rule to confirm deletion
  @tracked ruleToDelete: SignInFieldActionRuleGroupModel | null = null;

  @bool('ruleToDelete') declare isDeleting: boolean;

  get rules(): SignInFieldActionRuleGroupModel[] {
    const argRules = this.args.signInFieldActionRuleGroups || [];

    return [...new Set([...argRules, ...this.newRules].filter((r) => !r.isDeleted))];
  }

  @action
  handleAddRule(): void {
    const { flow } = this.args;
    const groupOperator = GROUP_OPERATION_OPTIONS.AND;

    const ruleGroupModelType = flow.isGlobal
      ? 'global-sign-in-field-action-rule-group'
      : 'sign-in-field-action-rule-group';

    this.currentRule = <SignInFieldActionRuleGroupModel>(
      this.store.createRecord(ruleGroupModelType, { flow, groupOperator })
    );
  }

  @action
  handleDeleteRule(rule: SignInFieldActionRuleGroupModel): void {
    this.ruleToDelete = rule;
  }

  @action
  handleEditRule(rule: SignInFieldActionRuleGroupModel): void {
    this.isEditing = true;
    this.currentRule = rule;
  }

  @action
  resetRule(fromCancel?: boolean): void {
    const { currentRule } = this;

    if (currentRule && fromCancel) {
      const { signInFieldActions, signInFieldActionsContacts } = currentRule;

      signInFieldActionsContacts.forEach((contact) => contact.rollbackAttributes());
      signInFieldActions.forEach((action) => action.rollbackAttributes());
      currentRule.rollbackAttributes();
    }

    this.ruleToDelete = null;
    this.currentRule = null;
    this.isEditing = false;
  }

  saveRuleTask = task({ drop: true }, async (evt?: Event) => {
    evt?.preventDefault();

    const {
      currentRule,
      featureFlags,
      flashMessages,
      args: { isLocationAdmin, isProtect },
    } = this;

    if (!currentRule) return;

    const isNew = !currentRule.id;

    try {
      if (featureFlags.isEnabled('ignore-rules')) {
        // whenever saving rules, they always start as enabled. aka not ignored
        currentRule.ignore = false;
      }

      const signInFieldActionsContacts = await currentRule.signInFieldActionsContacts;
      const signInFieldActions = await currentRule.signInFieldActions;

      if (isLocationAdmin && isProtect) {
        await all(
          signInFieldActionsContacts
            .filter((contact) => contact.hasDirtyAttributes || contact.isDeleted)
            .map((contact) => contact.save()),
        );

        return;
      }

      await currentRule.save();

      try {
        await all([
          ...signInFieldActions
            .filter((action) => action.hasDirtyAttributes || action.isDeleted)
            .map((action) => {
              return action.save();
            }),
          ...(currentRule.hasAlertAction
            ? signInFieldActionsContacts
                .filter((contact) => contact.hasDirtyAttributes || contact.isDeleted)
                .map((contact) => {
                  return contact.save();
                })
            : []),
        ]);
      } catch (error: unknown) {
        let message = 'Unknown Error';

        if (error instanceof Error) message = error.message;

        if (
          (message.indexOf('Attempted to handle event') > 0 && message.indexOf('while in state root.empty') > 0) ||
          message.indexOf('as there is no such record in the cache') > 0
        ) {
          // eslint-disable-next-line no-console
          console.debug('Error saving signInFieldActions for rules: ', error);
        } else {
          throw error;
        }
      }

      if (!currentRule.hasAlertAction) {
        // `envoy-web` unwinds and deletes the relationship but we need to update the local store to match
        signInFieldActionsContacts.forEach((contact) => {
          signInFieldActionsContacts.removeObject(contact);
          contact.unloadRecord();
        });
      }

      flashMessages.showAndHideFlash('success', 'Rule saved', { showAboveModalOverlay: true });
      if (isNew) this.newRules = [...this.newRules, currentRule];
      this.resetRule();
    } catch (e) {
      flashMessages.showFlash('error', parseErrorForDisplay(e), { showAboveModalOverlay: true });
    }
  });

  deleteRuleTask = task({ drop: true }, async () => {
    const { ruleToDelete, flashMessages } = this;

    if (!ruleToDelete) return;

    try {
      await ruleToDelete.destroyRecord();

      flashMessages.showAndHideFlash('success', 'Rule deleted', { showAboveModalOverlay: true });

      this.resetRule();
    } catch (_) {
      flashMessages.showAndHideFlash('error', 'Rule could not be deleted. Please try again.', {
        showAboveModalOverlay: true,
      });
    }
  });
}
