import Service, { service } from '@ember/service';
import type { Analytics } from '@envoy/react-rich-components-core';
import type MetricsService from 'garaje/services/metrics';

export default class ReactAnalytics extends Service implements Analytics {
  @service declare metrics: MetricsService;

  track(event: string, data: AnalyticsTraits = {}): void {
    this.trackAnalytics(event, data);
    this.trackMonitoring(event, data);
  }

  trackAnalytics(event: string, data?: AnalyticsTraits): void {
    this.metrics.trackEvent(event, data);
  }

  trackMonitoring(event: string, data?: Record<string, unknown>): void {
    this.metrics.logMonitorEvent(event, data);
  }
}
