import Model, { attr, belongsTo, hasMany, type AsyncHasMany, type AsyncBelongsTo } from '@ember-data/model';
import type AreaMapModel from 'garaje/models/area-map';
import type MapFeatureModel from 'garaje/models/map-feature';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('maps')
class MapFloorModel extends Model {
  @attr('number') declare ordinality: number;
  @attr('string') declare rasterImageUrl: string;
  @attr('string') declare rasterImageName: string;
  @attr('string') declare name: string;

  @belongsTo('area-map', { async: true }) declare areaMap: AsyncBelongsTo<AreaMapModel>;
  @hasMany('map-feature', { async: true }) declare mapFeatures: AsyncHasMany<MapFeatureModel>;
}

export default MapFloorModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'map-floor': MapFloorModel;
  }
}
