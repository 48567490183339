import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type PluginInstallModel from 'garaje/models/plugin';
import type VfdConfiguration from 'garaje/models/vfd-configuration';
import type { RingDuration } from 'garaje/models/vfd-configuration';
import type VfdContactMethod from 'garaje/models/vfd-contact-method';
import { VfdContactMethodKind } from 'garaje/models/vfd-contact-method';
import type { NotificationContactDropdownOption } from 'garaje/pods/components/virtual-front-desk/notification-contact-dropdown/component';
import { TrackedArray } from 'tracked-built-ins';
import { localCopy } from 'tracked-toolbox';

interface VirtualFrontDeskManageBackupContactsSignature {
  Args: {
    configuration: VfdConfiguration;
    primaryContact: VfdContactMethod;
    slackPluginInstall: PluginInstallModel | null;
    teamsPluginInstall: PluginInstallModel | null;

    save: (ringDuration: RingDuration, backupContacts: VfdContactMethod[]) => Promise<void>;
  };
}

type RingDurationDropdownOption = {
  label: string;
  value: RingDuration;
};

export default class VirtualFrontDeskManageBackupContacts extends Component<VirtualFrontDeskManageBackupContactsSignature> {
  @service declare store: Store;

  @tracked contactMethods: Array<VfdContactMethod | null>;
  @tracked hasChanges = false;

  @localCopy('args.configuration.ringDuration') declare ringDuration: RingDuration;

  #newContactMethods = new Set<VfdContactMethod>();

  ringDurationOptions: RingDurationDropdownOption[] = [
    { value: 30, label: '30 seconds' },
    { value: 45, label: '45 seconds' },
    { value: 60, label: '60 seconds (Default)' },
    { value: 75, label: '75 seconds' },
    { value: 90, label: '90 seconds' },
  ];

  constructor(owner: unknown, args: VirtualFrontDeskManageBackupContactsSignature['Args']) {
    super(owner, args);

    // create a local copy of the existing contacts so we can modify that here without affecting the "real" list until we're ready
    this.contactMethods = new TrackedArray(
      this.args.configuration.contactMethods.slice().sort((a, b) => a.contactPriority - b.contactPriority),
    );

    // if there are no backup contacts initially, create a blank one so the user sees a dropdown to select a contact
    // (We don't want to read `this.backupContacts.length` or `this.contactMethods.length` here because we immediately modify
    // `this.contactMethods` and the tracked stuff doesn't like being modified-after-read.)
    if (this.args.configuration.contactMethods.length === 1) {
      this.addEmptyBackupContact();
    }

    registerDestructor(this, () => this.#cleanUpUnsavedModels());
  }

  get backupContacts(): Array<VfdContactMethod | null> {
    return this.contactMethods.filter((contactMethod) => contactMethod !== this.args.primaryContact);
  }

  // Create a list of contact methods that should be disabled in dropdowns (because they're already selected
  // as either a primary or backup contact). This is the same as the internally-tracked list of contacts,
  // with empty ones removed.
  get contactMethodsToDisableInDropdown(): VfdContactMethod[] {
    return this.nonEmptyBackupContacts;
  }

  get hasUnselectedBackupContact(): boolean {
    return this.backupContacts.some((contact) => !contact);
  }

  get isRingDurationDropdownDisabled(): boolean {
    // "Ring duration" dropdown is disabled if there are no selected backup contacts
    return this.nonEmptyBackupContacts.length === 0;
  }

  get isSaveButtonDisabled(): boolean {
    // "Save" button is disabled if:
    // * a save operation is in progress, or
    // * there have been no changes made to the backup contacts or ring duration setting, or
    // * there is a blank backup contact (i.e., the user clicked "Add another back up" and has not yet selected a contact)
    return this.saveBackupContacts.isRunning || !this.hasChanges || this.hasUnselectedBackupContact;
  }

  get nonEmptyBackupContacts(): VfdContactMethod[] {
    return this.backupContacts.filter((contactMethod) => contactMethod !== null);
  }

  get selectedRingDuration(): RingDurationDropdownOption {
    return this.ringDurationOptions.find((option) => option.value === this.ringDuration)!;
  }

  get showSortHandles(): boolean {
    return this.backupContacts.length > 1;
  }

  @action
  addEmptyBackupContact(): void {
    this.contactMethods.push(null);
  }

  @action
  changeRingDuration(option: RingDurationDropdownOption): void {
    this.ringDuration = option.value;
    this.hasChanges = true;
  }

  @action
  deleteBackupContact(backupContactIndex: number): void {
    this.contactMethods.splice(backupContactIndex + 1, 1);
    this.hasChanges = true;
  }

  @action
  reorderBackupContacts(backupContactsInNewOrder: VfdContactMethod[]): void {
    this.contactMethods = new TrackedArray([this.args.primaryContact, ...backupContactsInNewOrder]);
    this.hasChanges = true;
  }

  @action
  updateBackupContact(backupContactIndex: number, selectedContact: NotificationContactDropdownOption): void {
    const newContactMethod = this.#createContactMethodFromOption(selectedContact);

    this.contactMethods.splice(backupContactIndex + 1, 1, newContactMethod);
    this.hasChanges = true;
  }

  // This wraps the function passed as `@save` as an ember-concurrency task so we can access the `.isRunning` state
  // to disable the "Save" button while the save operation is in progress.
  saveBackupContacts = dropTask(async () => {
    // It _should_ never be the case that the save action gets triggered while there is an
    // empty contact in the list, but if it happens, skip empty ones.
    await this.args.save(this.ringDuration, this.nonEmptyBackupContacts);
  });

  // When this component is torn down, remove any vfd-contact-method models that we created but didn't save.
  #cleanUpUnsavedModels(): void {
    for (const model of this.#newContactMethods.values()) {
      if (<boolean>(model.isNew as unknown)) {
        model.unloadRecord();
      }
    }
  }

  #createContactMethodFromOption(option: NotificationContactDropdownOption): VfdContactMethod {
    const contactMethod = this.store.createRecord('vfd-contact-method', {
      configuration: this.args.configuration,
    });
    this.#newContactMethods.add(contactMethod);

    contactMethod.kind = option.kind;
    if (option.kind === VfdContactMethodKind.EnvoyUser) {
      contactMethod.metadata = {};
      contactMethod.user = option.user!;
    } else {
      contactMethod.metadata = option.metadata!;
      contactMethod.user = null;
    }

    return contactMethod;
  }
}
