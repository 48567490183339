import Service from '@ember/service';

interface ActivepiecesToken {
  instanceUrl: string;
  externalAccessToken: string;
}

interface ActivepiecesResponse {
  connection?: {
    id: string;
    name: string;
  };
  error?: string;
}

export default class WorkflowsService extends Service {
  /**
   * Opens the Activepieces connection dialog
   * @param pieceName The name of the piece to connect
   * @param connectionName The name for the connection
   * @returns The response from the connection attempt
   */
  async connect({
    pieceName,
    connectionName,
    token,
  }: {
    pieceName: string;
    connectionName: string;
    token: ActivepiecesToken;
  }): Promise<ActivepiecesResponse> {
    if (typeof window !== 'undefined' && window.activepieces) {
      await window.activepieces.configure({
        instanceUrl: token.instanceUrl,
        jwtToken: token.externalAccessToken,
      });
      return await window.activepieces.connect({
        pieceName,
        connectionName,
      });
    }

    throw new Error('Activepieces is not available');
  }
}

// Extend window interface to include activepieces
declare global {
  interface Window {
    activepieces: {
      configure: (config: { instanceUrl: string; jwtToken: string }) => Promise<void>;
      connect: (options: { pieceName: string; connectionName: string }) => Promise<ActivepiecesResponse>;
    };
  }
}
