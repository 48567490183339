import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, get, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { or, reads } from '@ember/object/computed';
import { all, dropTask, task } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Model<Location>}           location
 * @param {Boolean}                   unchanged
 * @param {Function}                  onChange
 */
export default class VisitorEmailsVisitorSurvey extends Component {
  @service flashMessages;
  @service metrics;
  @service impressions;
  @service state;
  @service store;

  @tracked isOpen = false;
  @tracked isEditing = false;
  @tracked isPreviewing = false;
  @tracked showUnsavedEditsWarning = false;
  @tracked visitorSurveyConfiguration = null;

  @reads('args.location.visitorSurveyEnabled') isEnabled;
  @or('loadVisitorSurveyConfiguration.isRunning', 'enableVisitorSurvey.isRunning') isLoading;

  constructor() {
    super(...arguments);
    this.loadVisitorSurveyConfiguration.perform();
  }

  get hasAccessToVisitorSurvey() {
    return !!this.state.features?.canAccessVisitorSurvey;
  }

  @dropTask
  *enableVisitorSurvey() {
    try {
      if (!this.visitorSurveyConfiguration) {
        yield this.createVisitorSurveyConfiguration();
        yield this.loadVisitorTypes();
        this.enableAllVisitorTypes();
      }
      // Mark as dirty, admin needs to Save to actually enable (on the location)
      this.args.onChange({ hasChanges: true });
      this.isOpen = true;
      this.toEditing();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @dropTask
  *disableVisitorSurvey() {
    if (this.args.location.visitorSurveyEnabled === false) {
      this.onContinue();
      return;
    }
    set(this.args.location, 'visitorSurveyEnabled', false);
    try {
      yield this.args.location.save();
      this.metrics.trackEvent('Survey Disabled - Location', {
        location_id: this.args.location.id,
        location_name: this.args.location.name,
      });
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_EMAILS_SURVEY_EMAIL['DISABLED']);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @dropTask
  *saveVisitorSurveyConfiguration() {
    try {
      const { hasDirtyContacts } = this.visitorSurveyConfiguration;
      if (hasDirtyContacts) {
        yield this.visitorSurveyConfiguration.save();
      }

      const dirtyVisitorTypes = this.visitorSurveyConfiguration.visitorTypes.filter((vt) => vt.hasDirtyAttributes);
      yield all(dirtyVisitorTypes.map((vt) => vt.save()));

      if (this.args.location.visitorSurveyEnabled === false) {
        set(this.args.location, 'visitorSurveyEnabled', true);
        yield this.args.location.save();
        this.metrics.trackEvent('Survey Enabled - Location', {
          location_id: this.args.location.id,
          location_name: this.args.location.name,
        });
        yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_EMAILS_SURVEY_EMAIL['ENABLED']);
      } else {
        this.metrics.trackEvent('Survey Edited - Location', {
          location_id: this.args.location.id,
          location_name: this.args.location.name,
          recipients_edited: dirtyVisitorTypes.length > 0,
          results_email_contact_edited: hasDirtyContacts,
        });
      }

      this.args.onChange({ hasChanges: false });
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.args.onChange({ hasChanges: true });
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @task
  *loadVisitorSurveyConfiguration() {
    try {
      const surveyConfig = yield get(this.args.location, 'visitorSurveyConfiguration');
      if (surveyConfig) {
        this.visitorSurveyConfiguration = surveyConfig;
        yield this.loadVisitorTypes();
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  async loadVisitorTypes() {
    const { id } = this.args.location;
    await this.visitorSurveyConfiguration.loadVisitorTypes(id);
  }

  async createVisitorSurveyConfiguration() {
    const surveyConfig = this.store.createRecord('visitor-survey-configuration', {
      location: this.args.location,
    });
    await surveyConfig.save();
    this.visitorSurveyConfiguration = surveyConfig;
  }

  enableAllVisitorTypes() {
    this.visitorSurveyConfiguration.visitorTypes.forEach((visitorType) => {
      set(visitorType, 'visitorSurveyEnabled', true);
    });
  }

  @action
  toEditing() {
    this.isEditing = true;
    this.isPreviewing = false;
    this.showUnsavedEditsWarning = false;
  }

  @action
  toPreviewing() {
    this.isEditing = false;
    this.isPreviewing = true;
    this.showUnsavedEditsWarning = false;
  }

  @action
  trackPreview(kind) {
    const props = { location_id: this.args.location.id };
    props[`previewed_${kind}`] = true;
    this.metrics.trackEvent('Survey Previewed - Location', props);
  }

  @action
  onContinue() {
    if (this.visitorSurveyConfiguration.hasDirtyAttributes) {
      this.visitorSurveyConfiguration.rollbackAttributes();
      this.args.onChange({ hasChanges: false });
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  edit() {
    this.isOpen = true;
    this.toEditing();
  }

  @action
  cancel() {
    if (this.visitorSurveyConfiguration.hasDirtyAttributes) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.onContinue();
    }
  }

  @action
  updateSurveyContacts(contacts) {
    this.args.onChange({ hasChanges: true });
    const contactIds = contacts.map((c) => parseInt(c.userId || c.id, 10));
    set(this.visitorSurveyConfiguration, 'contactIds', contactIds);
    const _contacts = contacts.map((contact) => {
      return { userId: contact.userId || contact.id, fullName: contact.fullName };
    });
    set(this.visitorSurveyConfiguration, 'contacts', _contacts);
  }
}
