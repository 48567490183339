import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class PropertySettingsTenantsEditController extends Controller {
  @service declare router: RouterService;

  @action
  onClose(): void {
    this.router.transitionTo('property.settings.tenants');
  }
}
