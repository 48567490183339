import Component from '@glimmer/component';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { set, get } from '@ember/object';

/**
 * @param {Boolean}     isInactive
 * @param {Function}    onToggle
 * @param {Model}       notificationPreference
 */

export default class NotificationPreferenceToggleComponent extends Component {
  @service flashMessages;

  get enabled() {
    const { notificationPreference } = this.args;
    return get(notificationPreference, 'preference.preferenceValue');
  }

  toggleEnabledTask = task({ drop: true }, async () => {
    const { notificationPreference } = this.args;
    set(notificationPreference.preference, 'preferenceValue', !this.enabled);
    try {
      await notificationPreference.preference.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      notificationPreference.preference.rollbackAttributes();
      this.flashMessages.showFlash('error', e);
    }
  });
}
