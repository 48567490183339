import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { equal } from 'macro-decorators';

export default class DeliveryAreaSettingsController extends Controller {
  @service router;
  @equal('model.deliveryAreas.length', 1) singleDeliveryArea;

  @action
  transitionToDeliveryArea(deliveryArea) {
    this.router.transitionTo('deliveries.settings.delivery-areas.delivery-area.index', deliveryArea.id);
  }
}
