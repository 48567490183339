// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import AbstractFlowModel from 'garaje/models/abstract/abstract-flow';
import type CompanyModel from 'garaje/models/company';
import type EmployeeAgreementPageModel from 'garaje/models/employee-agreement-page';
import type EmployeeSignInFieldActionRuleGroupModel from 'garaje/models/employee-sign-in-field-action-rule-group';
import type EmployeeSignInFieldPageModel from 'garaje/models/employee-sign-in-field-page';
import type EmployeeSummaryPageModel from 'garaje/models/employee-summary-page';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import { STORE_RESPONSE_CONFIG_OPTIONS } from 'garaje/utils/enums';

export const EMPLOYEE_SCREENING_FLOW = Object.freeze({
  headerText:
    'Your answers to the following questions will be used to determine if you’re ready to work in the workplace. Your responses are private to you and will not be stored.\n\nYou can decline to answer these questions; however doing so will require that you continue working remotely.',
});

class EmployeeScreeningFlowModel extends AbstractFlowModel {
  @service declare store: StoreService;

  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>; // This is future proofing for location exclusive flows
  @belongsTo('skinny-location', { async: true, inverse: null }) declare location: AsyncBelongsTo<LocationModel>; // This is future proofing for location exclusive flows
  @hasMany('skinny-location', { async: true, inverse: 'employeeScreeningFlow' })
  declare locations: AsyncHasMany<SkinnyLocationModel>; // These locations use this if enabled
  @belongsTo('employee-summary-page', { async: true }) declare summaryPage: AsyncBelongsTo<EmployeeSummaryPageModel>;
  @belongsTo('employee-agreement-page', { async: true })
  declare agreementPage: AsyncBelongsTo<EmployeeAgreementPageModel>;
  @belongsTo('employee-sign-in-field-page', { async: true })
  declare signInFieldPage: AsyncBelongsTo<EmployeeSignInFieldPageModel>;
  @hasMany('employee-sign-in-field-action-rule-group', { async: true })
  declare signInFieldActionRuleGroups: AsyncHasMany<EmployeeSignInFieldActionRuleGroupModel>;

  isProtect = true;
  isGlobal = false;

  @attr('boolean', { defaultValue: true }) declare useDefaultHeaderText: boolean;
  @attr('string', { defaultValue: EMPLOYEE_SCREENING_FLOW.headerText }) declare headerText: string;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
  @attr('string', { defaultValue: STORE_RESPONSE_CONFIG_OPTIONS.DISCARDED }) declare storeResponseConfig: string;
  @attr('boolean', { defaultValue: true }) declare employeeCentric: boolean;
  @attr('boolean', { defaultValue: false }) declare employeeScreeningRequired: boolean;
  @attr('boolean', { defaultValue: false }) declare skipEmployeeScreeningVaccinated: boolean;
  @attr('boolean', { defaultValue: false }) declare skipEmployeeScreeningNegativeTestResult: boolean;

  @computed('locations.@each.id')
  get locationIds(): string[] {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (this.locations && this.locations.length) {
      return this.locations.map((loc) => loc.id);
    }
    return [];
  }

  get associatedFlow(): FlowModel | null {
    return this.store.peekRecord('flow', this.id);
  }
}

export default EmployeeScreeningFlowModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-screening-flow': EmployeeScreeningFlowModel;
  }
}
