import { tracked } from '@glimmer/tracking';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { alias } from '@ember/object/computed';

function withDowngradeFunctionality(Class) {
  return class withDowngradeFunctionality extends Class {
    @service() state;

    @tracked downgradeWarningOpened = false;
    @tracked title = 'You will lose these features';
    @tracked downgradeSubscriptionPlan = null;
    @tracked downgradeStarted = false;
    @tracked downgradeRestrictions = null;

    @alias('state.currentCompany.id') companyId;

    @computed(
      'downgradeStarted',
      'downgradeSubscriptionPlan.app',
      'state.{visitorsSubscription,deliveriesSubscription,roomsSubscription}',
    )
    get currentSubscriptionToBeDowngraded() {
      if (!this.downgradeSubscriptionPlan) {
        return null;
      }
      switch (this.downgradeSubscriptionPlan.app) {
        case 'visitors':
          return this.state.visitorsSubscription;
        case 'deliveries':
          return this.state.deliveriesSubscription;
        case 'rooms':
          return this.state.roomsSubscription;
        default:
          return null;
      }
    }

    @computed('downgradeSubscriptionPlan.downgradeRestrictions')
    get _downgradeRestrictions() {
      return this.downgradeSubscriptionPlan ? this.downgradeSubscriptionPlan.downgradeRestrictions : [];
    }

    @tracked downgradeEnabled = true;

    @computed('downgradeStarted', 'currentSubscriptionToBeDowngraded.downgradeFeatures')
    get downgradeFeatures() {
      return this.currentSubscriptionToBeDowngraded
        ? this.currentSubscriptionToBeDowngraded.subscriptionPlan.downgradeFeatures
        : [];
    }

    @task
    *generateDowngradeRestrictions() {
      const { _downgradeRestrictions, state } = this;
      const downgradeRestrictionsToShow = [];
      for (const restriction of _downgradeRestrictions) {
        let show = false;
        let count = 0;
        switch (restriction.id) {
          case 'visitors-basic-multiple-locations':
            count = yield state.activeLocationsCount.perform();
            show = count > restriction.maximum;
            break;
          case 'visitors-standard-multiple-visitor-types':
          case 'visitors-basic-multiple-visitor-types':
            count = yield state.locationsWithMultipleVisitorTypesCount.perform();
            show = count > restriction.maximum;
            break;
          case 'rooms-basic-maximum-rooms':
            count = yield state.activeRoomsCount.perform();
            show = count > restriction.maximum;
            break;
        }
        if (show) {
          downgradeRestrictionsToShow.push(restriction);
        }
      }
      return downgradeRestrictionsToShow;
    }

    @task
    *startDowngradeToSubscriptionPlan(plan) {
      this.downgradeSubscriptionPlan = plan;
      this.downgradeRestrictions = yield this.generateDowngradeRestrictions.perform();
      this.downgradeStarted = true;
      this.downgradeWarningOpened = true;
    }

    cancelDowngrade() {
      this.downgradeSubscriptionPlan = null;
      this.downgradeStarted = false;
      this.downgradeWarningOpened = false;
    }

    startDowngrade() {
      this.downgradeWarningOpened = false;
      this.setSubscriptionPlan(this.downgradeSubscriptionPlan);
      if (this.subscribed) {
        this.flashMessages.showAndHideFlash('info', 'Your downgrade has been initiated');
      }
    }
  };
}

export default withDowngradeFunctionality;
