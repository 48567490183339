import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';
import { htmlSafe } from '@ember/template';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { dropTask, enqueueTask } from 'ember-concurrency';

/**
 * @param {String}                  menuHeight
 * @param {Boolean}                 canPrintBadges
 * @param {Boolean}                 isSelectAllIndeterminate
 * @param {Boolean}                 allSelected
 * @param {Function}                deleteEntries
 * @param {Function}                signOutEntriesTask
 * @param {Function}                clearEntries
 * @param {Array<Object>}           entries
 * @param {Function}                reprintBadge
 * @param {Array<Object>}           printers
 */
export default class BulkActionsMenu extends Component {
  @service abilities;
  @service currentAdmin;
  @service metrics;

  @tracked displayPrinterSelectorModal = false;

  @alias('currentAdmin.canDeleteVisitor') canDeleteVisitor;

  @enqueueTask({
    maxConcurrency: 10,
  })
  *saveEntryTask(entry) {
    yield entry.save();
    return entry;
  }

  get canSignOut() {
    // check whether some selected entries are sign-out-able (i.e., not all of them are already signed out)
    const areSomeEntriesSignOutable = this.args.entries.any((entry) => !entry.signOutTime);

    // can the current user edit all entries, regardless of whether they're their own?
    const userCanEditAllEntries = this.abilities.can('edit entries');

    // can the current user edit all selected entries? (i.e., they're the host for all, or an assistant of the host)
    const userCanEditAllSelectedEntries = this.args.entries.every((entry) => this.abilities.can('edit entry', entry));

    // sign-out button is available if some entries can be signed out and the user can edit all of the selected ones
    return areSomeEntriesSignOutable && (userCanEditAllEntries || userCanEditAllSelectedEntries);
  }

  get heightStyle() {
    return htmlSafe(`top: -13px;height:${this.args.menuHeight}`);
  }

  @dropTask
  *reprintBadgeTask(entries) {
    yield this.args.reprintBadge(entries);
    const entry_ids = entries.map((e) => parseInt(e.id, 10));
    this.metrics.trackEvent('Bulk Reprint Entry Badge Requested', {
      action_origin: 'visitor_log',
      entry_ids,
      entry_count: entries.length,
    });
    this.args.clearEntries();
  }

  @action
  signOut() {
    const toSave = this.args.entries.filter((entry) => !get(entry, 'signOutTime'));
    this.args.signOutEntriesTask.perform(toSave);
  }
}
