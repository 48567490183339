import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import type EntryModel from 'garaje/models/entry';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type MetricsService from 'garaje/services/metrics';
import { reads } from 'macro-decorators';
import moment from 'moment-timezone';

import type { VisitorsNewRouteModel } from './route';

export default class VisitorsNewController extends Controller {
  declare model: VisitorsNewRouteModel;

  @service declare metrics: MetricsService;
  @service declare router: RouterService;

  queryParams = ['signedInAt'];

  @tracked signedInAt?: string;
  @tracked changeset!: DetailedChangeset<EntryModel>;

  @reads('changeset.flow.signInFieldPage.signInFields')
  signInFields!: SignInFieldPageModel['signInFields'];

  get hostSignInField(): SignInFieldModel | undefined {
    return this.signInFields.findBy('identifier', 'host');
  }

  get locationIsConnectedToProperty(): boolean {
    return <number>this.model.connectedTenants.length > 0;
  }

  @action
  modelDidSave(options: { redirectToIndex?: boolean } = {}): void {
    if (options.redirectToIndex) {
      this.router.transitionTo('/visitors/entries');
    } else {
      this.metrics.trackEvent('Dashboard Entries - Add Another');
      this.send('refreshRoute');
    }
  }

  @action
  dateChanged(date: Date): void {
    const signedInAt = moment(date).format('YYYY-MM-DDTHH:mm');
    // update query-param
    this.signedInAt = signedInAt;
  }

  @action
  modelDidPartialSave({ id: entry_id }: EntryModel): void {
    void this.router.transitionTo('visitors.entry', entry_id);
  }
}
