import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class ProductPlansRoute extends Route {
  @service ajax;
  @service locations;
  @service state;
  @service store;
  @service router;

  async model({ product }) {
    const { paymentSource } = this.modelFor('billing');
    if (paymentSource.isACH) {
      return this.router.transitionTo('billing');
    }

    let activeUnits, subscription;
    switch (product) {
      case 'deliveries': {
        subscription = this.state.realDeliveriesSubscription;
        const deliveryAreas = await this.store.findAll('delivery-area');
        activeUnits = deliveryAreas.filterBy('enabled').mapBy('nameWithLocationName');

        break;
      }
      case 'rooms': {
        subscription = this.state.realRoomsSubscription;
        activeUnits = this.modelFor('billing').activeRoomsQuantity;

        break;
      }
      case 'visitors':
        subscription = this.state.vrSubscription;
        activeUnits = this.locations.active.mapBy('nameWithCompanyName');
        break;
      case 'desks':
        subscription = this.state.realDesksSubscription;
        break;
      case 'workplace':
        subscription = this.state.workplaceSubscription;
        break;
    }

    if (!subscription) {
      return this.router.transitionTo('billing');
    }

    return hash({ activeUnits, paymentSource, subscription });
  }
}
