import Component from '@glimmer/component';
import { service } from '@ember/service';
import { buildChangeset, FakeChangeset } from 'garaje/utils/legal-document';

export default class extends Component {
  @service state;

  get vrSubscription() {
    return this.state.vrSubscription;
  }

  get bccChangeset() {
    return buildChangeset(this.args.agreement);
  }

  get ndaChangeset() {
    return buildChangeset(this.args.agreement);
  }

  get isSaved() {
    return !this.args.agreement.isNew && !this.args.agreement.hasDirtyAttributes;
  }

  get changesets() {
    return [this.bccChangeset, this.ndaChangeset];
  }

  get changeset() {
    const changesets = this.changesets ?? [];
    return new FakeChangeset(changesets);
  }
}
