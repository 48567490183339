import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class SignInFieldsRoute extends Route {
  @service transitionConfirm;
  @service store;

  queryParams = {
    showFieldOptions: {
      replace: true,
    },
  };

  async model() {
    const { ipads } = this.modelFor('visitors.settings');
    const { currentLocation, flow } = this.modelFor('visitors.settings.visitor-types.flow');
    const signInFieldPageId = flow.belongsTo('signInFieldPage').id();
    const includes = ['sign-in-field-actions', 'actionable-sign-in-field-actions', 'actionable-sign-in-fields'];
    const signInFieldPage = await this.store.findRecord('sign-in-field-page', signInFieldPageId, {
      reload: true,
      include: includes.map((include) => `sign-in-fields.${include}`).join(','),
    });

    const ruleGroupModelType = flow.isGlobalChild
      ? 'global-sign-in-field-action-rule-group'
      : 'sign-in-field-action-rule-group';

    // The rule records are returned without an explicit association to their flows.
    // Since the flow can be location-specific, global, or a "global child," side-loading
    // the flow here is problematic. You've been warned! :(
    const signInFieldActionRuleGroups = this.store.query(ruleGroupModelType, {
      filter: { flow: flow.id },
      page: { limit: 200 },
      include: 'sign-in-field-actions,sign-in-field-actions-contacts,sign-in-field-actions-contacts.employee',
    });

    return hash({
      signInFields: get(signInFieldPage, 'signInFields'),
      signInFieldActionRuleGroups,
      currentLocation,
      flow,
      visualComplianceConfiguration: get(flow, 'visualComplianceConfiguration'),
      ipads,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const changeset = get(this.controller, 'changeset');
    const confirmTask = get(this, 'transitionConfirm.displayConfirmTask');

    if (changeset) {
      if (get(changeset, 'isDirty')) {
        confirmTask.perform(transition, {
          continue() {
            changeset.rollbackAttributes();
          },
        });
      }
    }
  }
}
