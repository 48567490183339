import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import fade from 'ember-animated/transitions/fade';
import { task } from 'ember-concurrency';
import type HomepageItemGroupMapping from 'garaje/models/homepage-item-group-mapping';
import type SetupGuideItemModel from 'garaje/models/setup-guide-item';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import { alias, filterBy } from 'macro-decorators';

interface SetupGuideArgs {
  mapping: HomepageItemGroupMapping;
  logEvent: (event_name: string, event_value?: string, metadata?: object) => void;
  reload: () => void;
}

export default class SetupGuide extends Component<SetupGuideArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare flashMessages: FlashMessagesService;

  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];
  @alias('state.workplaceSubscription') declare workplaceSubscription: StateService['workplaceSubscription'];

  @filterBy('args.mapping.homepageItem.setupGuideItems', 'showOnHomepage', true)
  declare setupGuideItems: SetupGuideItemModel[];
  @filterBy('setupGuideItems', 'app', 'visitors') declare visitorsSetupGuideItems: SetupGuideItemModel[];
  @filterBy('setupGuideItems', 'app', 'empxp') declare workplaceSetupGuideItems: SetupGuideItemModel[];

  @tracked currentSetupGuideItems: SetupGuideItemModel[] = [];

  @tracked activeProductTab: string = 'visitors';
  @tracked basicOrAdvancedTab: string = 'basic';
  @tracked hasAdvancedItems: boolean = false;

  @tracked processedSetupGuideItems: SetupGuideItemModel[] = [];
  transition = fade;
  duration = zft(300);

  constructor(owner: unknown, args: SetupGuideArgs) {
    super(owner, args);
    this.checkVisitorsOrWorkplaceTab();
    this.checkHasAdvancedItems();
    void this.updateCurrentSetupGuideItems();
    void this.processSetupGuideItems();
  }

  checkVisitorsOrWorkplaceTab(): void {
    if (
      this.setupGuideItems.some((item) => item.app === 'empxp') &&
      !this.setupGuideItems.some((item) => item.app === 'visitors')
    ) {
      this.activeProductTab = 'empxp';
    }
  }

  checkHasAdvancedItems(): void {
    if (this.activeProductTab === 'visitors') {
      this.hasAdvancedItems = this.visitorsSetupGuideItems.some((item) => item.metadata.dashboard_group === 'advanced');
    } else {
      this.hasAdvancedItems = this.workplaceSetupGuideItems.some(
        (item) => item.metadata.dashboard_group === 'advanced',
      );
    }
  }

  get showTabs(): boolean {
    const showTabs =
      !!this.setupGuideItems.some((item) => item.app === 'visitors') &&
      !!this.setupGuideItems.some((item) => item.app === 'empxp');
    return showTabs;
  }

  async processSetupGuideItems(): Promise<void> {
    this.processedSetupGuideItems = await Promise.all(
      this.setupGuideItems.map(async (item): Promise<SetupGuideItemModel> => {
        if (item.metadata?.route_dynamic_field) {
          const dynamicFieldModel = await this.processDynamicField(item.metadata.route_dynamic_field);
          const newItem = this.store.createRecord('setup-guide-item', {
            title: item.title,
            description: item.description,
            route: item.route,
            rank: item.rank,
            identifier: item.identifier,
            app: item.app,
            icon: item.icon,
            resources: item.resources,
            enabled: item.enabled,
            homepageItem: item.homepageItem,
            metadata: {
              ...item.metadata,
              route_dynamic_field_model: dynamicFieldModel,
            },
          });
          return newItem;
        } else {
          return item;
        }
      }),
    );
    this.checkVisitorsOrWorkplaceTab();
    this.checkHasAdvancedItems();
    void this.updateCurrentSetupGuideItems();
  }

  @action updateCurrentSetupGuideItems(): void {
    this.currentSetupGuideItems = this.processedSetupGuideItems.filter((item) => {
      return item.app === this.activeProductTab && this.matchesBasicOrAdvancedTab(item);
    });

    this.checkHasAdvancedItems();
  }

  async processDynamicField(dynamicField: string): Promise<string> {
    if (dynamicField === '<flow-id>') {
      const flows = await this.store.query('flow', { filter: { location: this.state.currentLocation.id } });
      const firstFlow = flows.firstObject;

      return firstFlow?.id ?? '';
    }
    return '';
  }
  matchesBasicOrAdvancedTab(item: SetupGuideItemModel): boolean {
    if (!item.metadata.dashboard_group) {
      return this.basicOrAdvancedTab === 'basic';
    }
    return item.metadata.dashboard_group === this.basicOrAdvancedTab;
  }

  @action toggleBasicOrAdvancedTab(): void {
    this.basicOrAdvancedTab = this.basicOrAdvancedTab === 'basic' ? 'advanced' : 'basic';
    void this.updateCurrentSetupGuideItems();
  }

  @action setVisitorsOrWorkplaceTab(tab: string): void {
    this.activeProductTab = tab;
    this.basicOrAdvancedTab = 'basic';
    void this.updateCurrentSetupGuideItems();
  }

  @action
  logViewed(): void {
    this.args.logEvent('homepage_setup_guide_viewed');
  }

  hideGuidesTask = task({ drop: true }, async () => {
    const homepageItem = this.args.mapping.homepageItem;
    homepageItem.userClosed = true;

    try {
      await homepageItem.save();
      this.args.reload();
    } catch (e) {
      homepageItem.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
