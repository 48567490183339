import Service, { service } from '@ember/service';
import config from 'garaje/config/environment';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
import $ from 'jquery';

export default class CsvAjax extends Service {
  @service cookieAuth;
  @service session;

  trustedHosts = config.trustedHosts;

  @computed('session.isAuthenticated')
  get headers() {
    const headers = { Accept: 'application/vnd.api+json' };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
    }

    return headers;
  }

  request(url, settings) {
    const headers = get(this, 'headers');

    const defaultSettings = {
      url,
      type: 'GET',
      contentType: 'text/csv',
      headers,
    };

    settings = Object.assign({}, settings, defaultSettings);
    settings = this.cookieAuth.jQueryDecorator(url, settings.type, settings);

    return $.ajax(settings);
  }
}
