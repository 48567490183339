import JSONAPISerializer from '@ember-data/serializer/json-api';
import type Store from '@ember-data/store';
import type GenericResourceModel from 'garaje/models/generic-resource';
import { getIdFromURN } from 'garaje/models/generic-resource';
import type { Relationship } from 'jsonapi/types';

interface GeometryApi {
  type: string;
  coordinates: number[];
}

interface AmenityApi {
  id: number;
  name: string;
}

interface AssignmentApi {
  employeeId: number;
}

interface GenericResourceResponse {
  id: string | number;
  name: string;
  resourceTypeId: number;
  floorId: number;
  relationId: string;
  geometry?: GeometryApi;
  amenities?: Array<AmenityApi>;
  assignments?: Array<AssignmentApi>;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}

interface ApiPayload {
  data: GenericResourceResponse;
}

interface Relationships {
  floor?: {
    data: Relationship;
  };
  amenities?: {
    data: Relationship[];
  };
  assignments?: {
    data: Relationship[];
  };
}

interface NormalizedData {
  id: string;
  type: string;
  attributes: Record<string, unknown>;
  relationships: Relationships;
}

type NormalizedResponse = { data: NormalizedData } | { data: NormalizedData[] };

export default class GenericsSerializer extends JSONAPISerializer {
  primaryKey = 'id';

  // keep the attributes keys in camelCase
  keyForAttribute(key: string): string {
    return key;
  }

  // kept the relationships keys in camelCase
  keyForRelationship(key: string): string {
    return key;
  }

  formatObject(resourceHash: GenericResourceResponse): NormalizedData {
    // convert response to JSON:API format
    const JSONAPIDocument = {
      id: String(resourceHash.id),
      type: 'generic-resource',
      attributes: {
        name: resourceHash.name,
        resourceTypeId: resourceHash.resourceTypeId,
        relationId: getIdFromURN(resourceHash.relationId),
        amenities: resourceHash.amenities || [],
        assignments: resourceHash.assignments || [],
        floorId: resourceHash.floorId,
        geometry: resourceHash.geometry,
        createdAt: resourceHash.createdAt,
        updatedAt: resourceHash.updatedAt,
        deletedAt: resourceHash.deletedAt,
      },
      relationships: {} as Relationships,
    };

    return JSONAPIDocument;
  }

  normalizeResponse(_store: Store, _primaryModelClass: GenericResourceModel, payload: ApiPayload): NormalizedResponse {
    if (!Array.isArray(payload.data)) {
      return {
        data: this.formatObject(payload.data),
      };
    }

    return {
      data: payload.data.map((resource: GenericResourceResponse) => this.formatObject(resource)),
    };
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    generics: GenericsSerializer;
  }
}
