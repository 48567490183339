import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { filter, sort } from 'macro-decorators';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import globalFlowChangeset from 'garaje/changesets/global-flow';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class extends Component {
  @service router;
  @service store;
  @service state;
  @service flashMessages;

  @tracked didAttemptToSubmit = false;
  @tracked copyFlows = [];
  @tracked copyFlowChecked = false;
  @tracked copyFlowTypes = null;
  @tracked changeset;

  copyFlowsSortProperties = ['companyName', 'locationName', 'name'];
  copyGlobalFlowsSortProperties = ['name'];

  @filter('copyFlows', (value) => value.constructor.modelName === 'copy-global-flow') globalFlows;
  @filter('copyFlows', (value) => value.constructor.modelName === 'copy-flow') localFlows;
  @sort('localFlows', 'copyFlowsSortProperties') sortedFlows;
  @sort('globalFlows', 'copyGlobalFlowsSortProperties') sortedGlobalFlows;

  constructor() {
    super(...arguments);
    const { currentCompany: company } = this.state;

    this.globalFlow = this.store.createRecord('global-flow', {
      company,
    });
    this.changeset = globalFlowChangeset(this.globalFlow);
  }

  @action
  onDestroy() {
    if (this.globalFlow.isNew) {
      this.globalFlow.unloadRecord();
    }
  }

  get groupedFlows() {
    return [
      {
        groupName: 'Global sign-in flows',
        options: this.globalFlows,
      },
      {
        groupName: 'Location sign-in flows',
        options: this.localFlows,
      },
    ];
  }

  locationAndFlowNameMatcher(copyFlow, term) {
    const companyName = get(copyFlow, 'companyName');
    const locationName = get(copyFlow, 'locationName');
    const flowName = get(copyFlow, 'name');
    const string = `${companyName} ${locationName}: ${flowName}`.toLowerCase();

    return string.indexOf(term.toLowerCase());
  }

  @dropTask
  *loadCopyFlowsTask() {
    this.copyFlows = yield this.store.query('copy-flow', {});
  }

  @dropTask
  *saveGlobalFlowTask() {
    const { flashMessages, changeset, args, router, globalFlow } = this;

    yield changeset.validate();

    if (changeset.isInvalid) {
      this.didAttemptToSubmit = true;
      return;
    }

    try {
      yield changeset.save();
      if (typeof args.onSuccess === 'function') {
        args.onSuccess();
      }

      router.transitionTo('location-overview.sign-in-flows.show', globalFlow.id);
    } catch (err) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(err));
    }
  }

  @action
  updateBlueprint(flow) {
    const changeset = this.changeset;

    if (flow) {
      set(changeset, 'blueprint', get(flow, 'id'));
    } else {
      set(changeset, 'blueprint', null);
    }
  }

  @action
  handleChangeCopyFlowChecked(event) {
    this.copyFlowChecked = event.target.checked;
  }
}
