import { set } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type CompanyModel from 'garaje/models/company';
import type ConfigModel from 'garaje/models/config';
import type ConnectLocationConfigurationModel from 'garaje/models/connect-location-configuration';
import type DeviceModel from 'garaje/models/device';
import type LocationModel from 'garaje/models/location';
import type LocationSubscriptionModel from 'garaje/models/location-subscription';
import type PluginModel from 'garaje/models/plugin';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type SubscriptionModel from 'garaje/models/subscription';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';
import { routeEvent } from 'garaje/utils/decorators/route';
import { GLOBAL_ADMIN, LOCATION_ADMIN } from 'garaje/utils/roles';
import type { RecordArray } from 'garaje/utils/type-utils';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';
import { hash } from 'rsvp';

import type SecurityIndexController from './controller';

const companyHasSecurityFeatures = ({
  vrSubscription,
  plugins,
  company,
  state,
}: {
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  plugins: RecordArray<PluginModel>;
  company: CompanyModel | null;
  state: StateService;
}) => {
  const securityPluginIds = plugins
    .filter((plugin) => ['security', 'wi-fi'].includes(plugin.category))
    .map((plugin) => plugin.id);
  return (
    vrSubscription?.isEnterprisePlan ||
    vrSubscription?.canAccessBlocklist ||
    state.features?.canAccessVisualCompliance ||
    state.features?.canAccessIdScanning ||
    isPresent(_intersection(company?.adhocPlugins, securityPluginIds))
  );
};

const CAN_VISIT_SECURITY = [GLOBAL_ADMIN, LOCATION_ADMIN];
const CAN_VISIT_AUTHZ_PERMISSIONS = [
  Permission.VISITORS_BLOCKLIST_READ,
  Permission.VISITORS_WATCHLIST_READ,
  Permission.VISITORS_FULL_LEGAL_NAME_SETTING_READ,
];

export interface SecurityIndexRouteModel {
  company: CompanyModel | null;
  vrSubscription: LocationSubscriptionModel | SubscriptionModel | null;
  isConnectedToProperty: boolean;
  connectLocationConfiguration: ConnectLocationConfigurationModel | undefined;
  currentLocation: LocationModel;
  ipads: RecordArray<DeviceModel>;
  plugins: RecordArray<PluginModel>;
  pluginInstalls: RecordArray<PluginInstallModel>;
  visualComplianceConfiguration: VisualComplianceConfigurationModel;
  config: ConfigModel;
}

export default class SecurityIndexRoute extends Route {
  declare controller: SecurityIndexController;

  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;
  @service declare transitionConfirm: TransitionConfirmService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;
  @service declare store: Store;
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare router: RouterService;

  titleToken = 'Security';

  beforeModel(): void {
    const { roleNames } = this.currentAdmin;
    const hasPermission = this.authz.hasAnyPermissionAtCurrentLocation(CAN_VISIT_AUTHZ_PERMISSIONS);
    const isAuthorized = isPresent(_intersection(CAN_VISIT_SECURITY, roleNames)) || hasPermission;
    if (!isAuthorized) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<SecurityIndexRouteModel> {
    const currentLocation = this.state.currentLocation;
    const ipads = this.store.query('device', { filter: { location: currentLocation.id } });
    const vrSubscription = this.state.vrSubscription;
    const company = this.state.currentCompany;
    const visualComplianceConfiguration = currentLocation.visualComplianceConfiguration;
    const plugins = this.store.query('plugin', {
      filter: { location: currentLocation.id },
    });
    const pluginInstalls = this.store.query('plugin-install', {
      filter: { location: currentLocation.id },
    });

    const isConnectedToProperty = await currentLocation.isConnectedToProperty();
    let connectLocationConfiguration: ConnectLocationConfigurationModel | undefined;

    if (this.featureFlags.isEnabled('connect-pre-reg-acs-qr-code') && isConnectedToProperty) {
      connectLocationConfiguration = await currentLocation.getConnectConfiguration();
    }

    await this.visitorsOnboarding.loadSteps();

    return hash({
      company,
      isConnectedToProperty,
      // reload location in case blocklist contacts have changed
      currentLocation: currentLocation.reload(),
      connectLocationConfiguration,
      ipads,
      plugins,
      pluginInstalls,
      visualComplianceConfiguration,
      vrSubscription,
      config: currentLocation.config,
    });
  }

  redirect(_model: SecurityIndexRouteModel, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }

  setupController(controller: SecurityIndexController, model: SecurityIndexRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);
    const { vrSubscription, plugins, company } = model;

    const isUpgradeModalInitiallyVisible =
      !companyHasSecurityFeatures({ vrSubscription, plugins, company, state: this.state }) &&
      !controller.createSalesforceLead.lastSuccessful;
    set(controller, 'isUpgradeModalVisible', isUpgradeModalInitiallyVisible);
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = this.controller;
    const { idScanningHasChanges } = controller;
    // no need to display confirmation modal when nothing is changed
    if (!idScanningHasChanges) {
      return;
    }
    // display confirmation modal if settings are dirty
    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (idScanningHasChanges) {
          controller.send('rollbackIdScanning');
        }
      },
    });
  }
}
