import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class DeliveriesLogDeliveryRoute extends Route {
  @service store;

  model({ id }) {
    const delivery = this.store.findRecord('delivery', id, {
      include: ['carrier', 'delivery-area'].join(','),
    });

    const creator = delivery.then((d) => get(d, 'creator'));

    return hash({ creator, delivery });
  }

  setupController(controller, { delivery }) {
    super.setupController(...arguments);
    controller.fetchDataTask.perform();
    controller._buildChangeset(delivery);
  }

  @routeEvent
  routeWillChange(transition) {
    const changeset = get(this.controller, 'changeset');
    if (
      get(changeset, 'isDirty') &&
      !confirm(`You have unsaved changes. Would you like to discard them and leave the page?`)
    ) {
      transition.abort();
    } else {
      changeset.rollback();
      return true;
    }
  }
}
