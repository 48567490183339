import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class SettingsWelcomeScreenDeliveriesRoute extends Route {
  @service state;
  @service store;
  @service transitionConfirm;

  titleToken = 'Deliveries';

  model() {
    const currentLocation = get(this, 'state.currentLocation');
    const config = get(currentLocation, 'config');
    const currentDeliveryPerson = config.then((c) => get(c, 'deliveryPerson'));

    const employees = this.store.query('employee', {
      page: { offset: 0, limit: 1 },
      filter: { deleted: false, locations: get(currentLocation, 'id') },
    });

    return hash({
      config,
      currentDeliveryPerson,
      currentLocation,
      employees,
    });
  }

  afterModel({ config }) {
    return this.store.findRecord('config', get(config, 'id'), { reload: true });
  }

  @routeEvent
  routeWillChange(transition) {
    const { controller } = this;
    // no need to display confirmation modal when nothing is changed
    if (!controller.isDirty) {
      return;
    }
    // display confirmation modal if settings are dirty
    this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue() {
        if (controller.isDirty) {
          controller.rollback();
        }
      },
    });
  }
}
