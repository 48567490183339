import Component from '@glimmer/component';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {Boolean}             isOpen
 * @param {String}              position // left || right
 * @param {Function}            onToggle // called when opening/closing sidebar with open state.
 */
export default class Sidebar extends Component {
  @localCopy('args.isOpen', false) isOpen;
  @localCopy('args.position', 'right') position;
}
