import JSONAPIAdapter from '@ember-data/adapter/json-api';
import { service } from '@ember/service';

import config from 'garaje/config/environment';

export default class HubAdapter extends JSONAPIAdapter {
  namespace = 'a/hub/v1';
  host = config.umbrellaHost;
  useFetch = false;

  @service cookieAuth;

  ajaxOptions(url, type, options = {}) {
    let hash = super.ajaxOptions(url, type, options);
    hash = this.cookieAuth.decorate(type, hash);

    return hash;
  }

  ajax(url, method, hash = {}) {
    hash.xhrFields = { withCredentials: true };
    return super.ajax(url, method, hash);
  }

  ensureResponseAuthorized(status /* headers, payload, requestData*/) {
    // Do not log out when receiving 401 from hub
    if (status === 401) {
      return;
    }
  }
}
