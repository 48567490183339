import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class WorkplaceStartTrialPageRoute extends Route {
  @service state;
  @service router;

  titleToken = 'Workspace';

  redirect() {
    if (this.state.features?.canAccessWorkplaceApplication) {
      this.router.transitionTo('workplace.settings.employees.registration');
    }
  }
}
