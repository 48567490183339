import Component from '@glimmer/component';
import { action, get, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import { localCopy } from 'tracked-toolbox';
import { MINUTES_IN_A_DAY } from 'garaje/utils/enums';

/**
 *
 * @param {Location}              location
 */

export default class DailyWindowComponent extends Component {
  @service flashMessages;
  @service transitionConfirm;
  @service workplaceMetrics;

  @localCopy('args.location') location;

  dayOptions = ['day before', 'day of'];

  @tracked expandableSettingsPanelExpanded = false;

  constructor() {
    super(...arguments);

    // Generate the list of all hrs with `15 min`intervals
    const mmtMidnight = moment().startOf('day');
    this.hourOptions = [];
    for (let hour = 0; hour < 24; hour++) {
      const time = moment(mmtMidnight).add('hours', hour);
      for (let times = 0; times < 4; times++) {
        this.hourOptions.push({ minutes: time.diff(mmtMidnight, 'minutes'), label: time.format('h:mm a') });
        time.add(15, 'minutes');
      }
    }
  }

  get endTimeOptions() {
    if (this.startDayOpt != this.endDayOpt) {
      return this.hourOptions;
    }
    return this.hourOptions.filter(({ minutes }) => minutes > this.startTime);
  }

  get minEndTime() {
    const { startTime } = this;
    if (this.endDayOpt !== this.startDayOpt) {
      return null;
    }
    return startTime + 15;
  }

  /**
   * @returns {string}
   */
  get startDayOpt() {
    const { registrationEligibilityStartOffset: startOffset } = this.location;
    return startOffset >= 0 ? 'day of' : 'day before';
  }

  /**
   * @returns {string}
   */
  get endDayOpt() {
    const { registrationEligibilityEndOffset: endOffset } = this.location;
    return endOffset >= 0 ? 'day of' : 'day before';
  }

  /**
   * @returns {number}
   */
  get startTime() {
    let { registrationEligibilityStartOffset: startOffset } = this.location;
    if (startOffset < 0) {
      startOffset += MINUTES_IN_A_DAY;
    }
    return startOffset;
  }

  /**
   * @returns {number}
   */
  get endTime() {
    let { registrationEligibilityEndOffset: endOffset } = this.location;
    if (endOffset < 0) {
      endOffset += MINUTES_IN_A_DAY;
    }
    const minutesPast15 = endOffset % 15;
    return endOffset - minutesPast15;
  }

  /**
   * @returns {boolean}
   */
  get isLocationDirty() {
    if (!get(this.location, 'hasDirtyAttributes')) {
      return false;
    }
    const changed = Object.keys(this.location.changedAttributes());
    return (
      changed.includes('registrationEligibilityStartOffset') || changed.includes('registrationEligibilityEndOffset')
    );
  }

  /**
   * @task `saveTask`
   */
  @dropTask
  *saveTask() {
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_HEALTH_CHECK_WINDOW_SAVE_CLICKED');
    try {
      yield this.location.save();
      this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_HEALTH_CHECK_WINDOW_SAVE_SUCCESSFUL');
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_HEALTH_CHECK_WINDOW_SAVE_FAILED');
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  }

  @action
  setTime(type, { minutes: selectedMinutes }) {
    if (type === 'start') {
      const { startDayOpt } = this;
      if (startDayOpt === 'day before') {
        set(this, 'location.registrationEligibilityStartOffset', selectedMinutes - MINUTES_IN_A_DAY);
      } else {
        set(this, 'location.registrationEligibilityStartOffset', selectedMinutes);
      }
    } else {
      const { endDayOpt } = this;
      if (endDayOpt === 'day before') {
        set(this, 'location.registrationEligibilityEndOffset', selectedMinutes - MINUTES_IN_A_DAY);
      } else {
        set(this, 'location.registrationEligibilityEndOffset', selectedMinutes);
      }
    }
    this._checkOverlap();
  }

  @action
  setDay(type, opt) {
    if (type === 'start') {
      const { registrationEligibilityStartOffset: startOffset } = this.location;
      if (opt === 'day of') {
        set(this, 'location.registrationEligibilityStartOffset', startOffset + MINUTES_IN_A_DAY);
      } else {
        set(this, 'location.registrationEligibilityStartOffset', startOffset - MINUTES_IN_A_DAY);
      }
    } else {
      const { registrationEligibilityEndOffset: endOffset } = this.location;
      if (opt === 'day of') {
        set(this, 'location.registrationEligibilityEndOffset', endOffset + MINUTES_IN_A_DAY);
      } else {
        set(this, 'location.registrationEligibilityEndOffset', endOffset - MINUTES_IN_A_DAY);
      }
    }
    this._checkOverlap();
  }

  @action
  rollback() {
    const { registrationEligibilityStartOffset: startOffset, registrationEligibilityEndOffset: endOffset } =
      this.location.changedAttributes();

    startOffset && (this.location.registrationEligibilityStartOffset = startOffset[0]);
    endOffset && (this.location.registrationEligibilityEndOffset = endOffset[0]);
  }

  @action
  expandPanel() {
    this.expandableSettingsPanelExpanded = true;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_HEALTH_CHECK_WINDOW_BUTTON_CLICKED');
  }

  @action
  close() {
    this.expandableSettingsPanelExpanded = false;
  }

  _checkOverlap() {
    const { registrationEligibilityStartOffset: startOffset, registrationEligibilityEndOffset: endOffset } =
      this.location;
    if (startOffset >= endOffset) {
      set(this, 'location.registrationEligibilityEndOffset', startOffset + 15);
    }
  }
}
