import { service } from '@ember/service';
import Model, {
  attr,
  belongsTo,
  hasMany,
  type AsyncBelongsTo,
  type AsyncHasMany,
  type SyncHasMany,
} from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type UserModel from 'garaje/models/user';
import type AjaxService from 'garaje/services/ajax';
import type PlainObject from 'garaje/utils/plain-object';
import urlBuilder from 'garaje/utils/url-builder';
import { v4 as uuidv4 } from 'uuid';

export default class GroupInviteModel extends Model {
  @service declare ajax: AjaxService;

  @attr('boolean', { defaultValue: true }) declare includeGroupNameInEmail: boolean;
  @attr('boolean', { defaultValue: true }) declare notifyVisitor: boolean;
  @attr('date') declare expectedArrivalTime: Date;
  @attr('string') declare csv: string;
  @attr('string') declare groupName: string;
  @attr('string') declare privateNotes: string;
  @attr('string', { defaultValue: () => uuidv4() }) declare jobId: string;

  @belongsTo('employee', { async: false }) declare employee: EmployeeModel | null;
  @belongsTo('flow', { async: false }) declare flow: FlowModel;
  @belongsTo('location', { async: false }) declare location: LocationModel;
  @belongsTo('user', { async: true }) declare creator: AsyncBelongsTo<UserModel>;
  @hasMany('invite', { async: true }) declare invites: AsyncHasMany<InviteModel>;
  @hasMany('employee', { async: false, inverse: null }) declare additionalHosts: SyncHasMany<EmployeeModel>;

  // future feature, declared for compatibility with multiple host input field
  @hasMany('location', { async: false, inverse: null }) declare childInviteLocations: SyncHasMany<LocationModel>;

  notifyGroupInvite(data: PlainObject<unknown> | undefined): Promise<unknown> {
    return this.ajax.request(urlBuilder.v3.groupInvites.sendNotification(this.id), {
      contentType: 'application/json',
      data,
      type: 'POST',
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'group-invite': GroupInviteModel;
  }
}
