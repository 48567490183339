// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';

/**
 * @param {Boolean} isInactive
 */

export default class NotificationIntegrationToggleComponent extends Component {
  @service flashMessages;

  tagName = '';

  @alias('notificationPreference.preference.preferenceValue') enabled;

  @dropTask
  *toggleEnabled() {
    set(this.notificationPreference.preference, 'preferenceValue', !this.enabled);
    try {
      yield this.notificationPreference.preference.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.onToggle();
    } catch (e) {
      this.notificationPreference.preference.rollbackAttributes();
      this.flashMessages.showFlash('error', e);
    }
  }
}
