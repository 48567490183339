import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import type Model from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { endOfDay, format, isToday, parse, parseISO, startOfDay } from 'date-fns';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask, restartableTask, timeout } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type FlowModel from 'garaje/models/flow';
import type GroupModel from 'garaje/models/group';
import type InviteModel from 'garaje/models/invite';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type { InvitesDashboardField } from 'garaje/pods/visitors/invites/index/controller';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MessageBusService from 'garaje/services/message-bus';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import { DATE_FNS_YYYY_MM_DD } from 'garaje/utils/date-fns-tz-utilities';
import isIsoString from 'garaje/utils/is-iso-string';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import _intersection from 'lodash/intersection';
import { notEmpty } from 'macro-decorators';

interface InviteFilter {
  location: string;
  flow: string;
  start_time: string;
  end_time: string;
  status?: string;
  query?: string;
}

interface StatusOption {
  name: string;
  value: string;
}

const HEADERS = [
  { name: 'Name', sort: 'full_name' },
  { name: 'Locations', sort: 'locations.name' },
  { name: 'Invited By', componentName: 'custom-column' },
  { name: 'Purpose Of Visit', sort: 'flows.name', componentName: 'custom-column' },
  { name: 'Due At', sort: 'expected_arrival_time', componentName: 'due-at-column' },
  { name: 'Entry status', componentName: 'custom-column' },
  { name: 'Signed In', componentName: 'signed-in-column', sort: 'entries.finalized_at' },
];

// The date presets that should appear in the date range dropdown, in the order they should appear.
const DATE_RANGE_FILTER_OPTIONS = [
  'today',
  'next2Days',
  'thisWeek',
  'next7Days',
  'next30Days',
  'thisMonth',
  'past7Days',
  'past30Days',
  'past90Days',
  'monthToDate',
  'yearToDate',
  'pastYear',
  'allTime',
];

const STATUS_OPTIONS: StatusOption[] = [
  { name: 'All Invites', value: '' },
  { name: 'Signed In', value: 'signed-in' },
  { name: 'Signed Out', value: 'signed-out' },
  { name: 'No Show', value: 'no-show' },
];

const MIN_SEARCH_LENGTH = 3;

export default class LocationOverviewInviteLogController extends Controller {
  queryParams = ['startDate', 'endDate', 'sort', 'status', 'query', 'locations', 'visitorType'];

  dateRangePresetsOrder = DATE_RANGE_FILTER_OPTIONS;
  statusOptions = STATUS_OPTIONS;

  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare infinity: InfinityService;
  @service declare flashMessages: FlashMessagesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare abilities: AbilitiesService;
  @service declare store: StoreService;
  @service declare messageBus: MessageBusService;

  @tracked showExportModal = false;

  @tracked invites?: PaginatedRecordArray<InviteModel>;
  @tracked flows?: PaginatedRecordArray<FlowModel>;

  @tracked sort = 'expected_arrival_time';
  @tracked startDate?: string;
  @tracked endDate?: string;
  @tracked query = '';
  @tracked locations = '';
  @tracked visitorType = '';

  @tracked status = '';

  /**
   * internal state to hold entered search query before updating this.query (which updates query param & performs search)
   */
  @tracked _searchQuery = '';

  get showEnterToSearch(): boolean {
    return !isEmpty(this.searchQuery) && this.searchQuery.length < MIN_SEARCH_LENGTH;
  }

  get searchQuery(): string {
    return this._searchQuery || this.query;
  }

  set searchQuery(query: string) {
    this._searchQuery = query;
  }

  @notEmpty('searchQuery') showClearButton!: boolean;

  get showFullDate(): boolean {
    return !this.isToday || !isEmpty(this.query);
  }

  get exportIframeUrl(): string {
    const availableLocations = this.skinnyLocations.readableByCurrentAdmin;
    const availableLocationIds = availableLocations.map((loc) => loc.id);

    let modalLabel: string;
    let locationIds = this.locations === '' ? availableLocationIds : this.locations.split(',');

    if (this.locations !== '') {
      // remove any location ids coming in from query paras that are not in the available locations
      locationIds = locationIds.filter((locId) => availableLocationIds.includes(locId));
    }

    const companyId = this.state.currentCompany.id;

    const allSelected = _intersection(locationIds, availableLocationIds).length === availableLocations.length;

    if (allSelected) {
      modalLabel = 'All locations';
    } else if (locationIds.length > 1) {
      modalLabel = locationIds.length + ` locations`;
    } else {
      modalLabel = availableLocations.find((loc) => loc.id === locationIds[0])?.name || '';
    }
    return urlBuilder.embeddedInviteExportModalUrl(
      companyId,
      locationIds.join(','),
      null,
      format(this.start, DATE_FNS_YYYY_MM_DD),
      format(this.end, DATE_FNS_YYYY_MM_DD),
      modalLabel,
    );
  }

  get isDefaultStatus(): boolean {
    return this.status === this.statusOptions[0]!.value;
  }

  get selectedStatus(): StatusOption | undefined {
    return this.statusOptions.find((option) => option.value === this.status);
  }
  set selectedStatus(option: StatusOption | undefined) {
    this.status = option?.value || '';
  }

  get isToday(): boolean {
    return isToday(this.start) && isToday(this.end);
  }

  get isIso(): boolean {
    if (!this.startDate || !this.endDate) return false;
    return isIsoString(this.startDate) && isIsoString(this.endDate);
  }

  get start(): Date {
    let start: Date;

    if (this.startDate) {
      start = this.isIso ? parseISO(this.startDate) : parse(this.startDate, DATE_FNS_YYYY_MM_DD, new Date());
    } else {
      start = new Date();
    }

    return startOfDay(start);
  }

  get end(): Date {
    let end: Date;

    if (this.endDate) {
      end = this.isIso ? parseISO(this.endDate) : parse(this.endDate, DATE_FNS_YYYY_MM_DD, new Date());
    } else {
      end = new Date();
    }

    return endOfDay(end);
  }

  get sortField(): string {
    return this.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.sort.startsWith('-') ? 'desc' : 'asc';
  }

  get fieldOptions(): InvitesDashboardField[] {
    return HEADERS.map(({ name, sort, componentName }) => ({
      name,
      componentName,
      show: true,
      disabled: name === 'Name',
      sort,
    }));
  }

  get emptyMessage(): string {
    let message = 'No invites';

    if (this.query) {
      message = `${message} matching query`;
    } else if (this.isToday) {
      message = `${message} yet today`;
    } else {
      const formatStart = format(this.start, 'MMMM d, yyyy');
      const formatEnd = format(this.end, 'MMMM d, yyyy');
      const dateString = formatStart === formatEnd ? formatStart : `${formatStart} - ${formatEnd}`;

      message = `${message} on ${dateString}`;
    }

    return message;
  }

  get formattedCount(): string | undefined {
    return this.invites?.meta.total.toLocaleString();
  }

  get groups(): GroupModel[] {
    if (!this.abilities.can('view locations-grouping')) return [];
    return this.store.peekAll('group').toArray();
  }

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.#handleMessage);
  }

  @action
  sortInvites(field: string, direction: string): void {
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;

    this.metrics.trackEvent('Global Invites Log Sorted', { sort_field_name: field });
  }

  @action
  selectDateRange(startDate: Date, endDate: Date): void {
    this.startDate = format(startDate, DATE_FNS_YYYY_MM_DD);
    this.endDate = format(endDate, DATE_FNS_YYYY_MM_DD);

    this.metrics.trackEvent('Global Invites Log Date Range Filtered', {
      start_date: this.startDate,
      end_date: this.endDate,
    });
  }

  @action
  updateSelectedLocations(locations: SkinnyLocationModel[]): void {
    const locationIds = this.locations ? this.locations.split(',') : [];

    locations.forEach((location) => {
      if (!locationIds.includes(location.id)) {
        locationIds.push(location.id);
      } else {
        locationIds.splice(locationIds.indexOf(location.id), 1);
      }
    });

    this.locations = locationIds.join();

    this.metrics.trackEvent('Global Invites Log Locations Filtered', {
      location_count: locationIds.length,
      location_ids: this.locations,
    });
  }

  @action
  updateSelectedFlows(flows: Model[]): void {
    const flowIds = this.visitorType ? this.visitorType.split(',') : [];

    flows.forEach((flow) => {
      if (!flowIds.includes(flow.id)) {
        flowIds.push(flow.id);
      } else {
        flowIds.splice(flowIds.indexOf(flow.id), 1);
      }
    });

    this.visitorType = flowIds.join();

    this.metrics.trackEvent('Global Invites Log Visitor Type Filtered', {
      flow_count: flowIds.length,
      flow_ids: this.visitorType,
    });
  }

  @action
  updateSelectedStatus(option: StatusOption): void {
    this.selectedStatus = option;

    this.metrics.trackEvent('Global Invites Log Status Filtered', {
      status_value: option.value,
      state_name: option.name,
    });
  }

  loadInvitesTask = dropTask(async () => {
    try {
      const filter: InviteFilter = {
        location: this.locations,
        flow: this.visitorType,
        start_time: this.start.toJSON(),
        end_time: this.end.toJSON(),
      };

      const inviteParams = {
        countParam: 'meta.total',
        pageParam: 'page[offset]',
        perPageParam: 'page[limit]',
        startingPage: 0,
        filter,
        sort: this.sort,
        include: ['platform-jobs'].join(),
      };

      if (this.status) inviteParams.filter.status = this.status;

      if (!isEmpty(this.query)) inviteParams.filter.query = this.query;

      this.invites = await this.infinity.model('invite', inviteParams, ExtendedInfinityModel);
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading invites');
    }
  });

  loadFlowsTask = dropTask(async () => {
    try {
      this.flows = await this.infinity.model(
        'flow',
        {
          countParam: 'meta.total',
          pageParam: 'page[offset]',
          perPageParam: 'page[limit]',
          startingPage: 0,
          include: 'location,global-flow.locations',
          fields: {
            flows: 'name,type,enabled,location,global-flow,position',
            locations: 'name,disabled',
            'global-flows': 'name,enabled,locations',
          },
          sort: 'location.name,position,id',
        },
        ExtendedInfinityModel,
      );
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading flows');
    }
  });
  @action
  searchInvites(): void {
    void this.mutateQueryTask.perform(this.searchQuery, true);
  }

  @action
  updateInputQuery(query: string): void {
    this.searchQuery = query;

    if (query) {
      void this.mutateQueryTask.perform(this.searchQuery);
    } else {
      this.clearSearch();
    }
  }

  @action
  clearSearch(): void {
    this.query = '';
    this.searchQuery = '';
  }

  @action
  enableExportModal(): void {
    this.showExportModal = !this.showExportModal;
    this.metrics.trackEvent('Global Export Invites Modal Opened');
  }

  mutateQueryTask = restartableTask(async (query: string, skipLengthCheck = false) => {
    // If the current query is the same as the new one, do not proceed!
    if (this.query === query) return;

    // Stop if the query is "too short" for this ride
    if (!skipLengthCheck && query.length < MIN_SEARCH_LENGTH) return;

    await timeout(zft(500));

    void this.loadInvitesTask.cancelAll();
    this.query = query;

    this.metrics.trackEvent('Global Invites Searched', { search_value: this.query });
  });

  #handleMessage(message: { event: string }) {
    if (message.event === 'showInviteExportModal') {
      this.showExportModal = true;
    } else if (message.event === 'closeExportModal') {
      this.showExportModal = false;
    }
  }
}
