import Route from '@ember/routing/route';
import { service } from '@ember/service';
export default class LocationOverviewReportsDashboardRoute extends Route {
  @service abilities;
  @service router;
  @service featureFlags;
  @service state;
  @service userFeatureFlags;

  get hasScheduledReports() {
    return this.state.vrSubscription.canAccessScheduledReports;
  }

  beforeModel() {
    if (
      this.abilities.cannot('visit-visitors global-analytics') ||
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      this.router.transitionTo('unauthorized');
    }
    if (!this.hasScheduledReports) {
      this.router.transitionTo('location-overview.analytics');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.showModal = false;
  }
}
