import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type { MessageInput, SentMessage } from '@envoy/components-communication';
import { SendMessage } from '@envoy/components-communication';
import Component from '@glimmer/component';
import {
  type AnnouncementAudienceAggregateSizeQuery,
  type AnnouncementAudienceAggregateSizeVariables,
  type CreateAnnouncementMutation,
  type CreateAnnouncementVariables,
} from 'garaje/graphql/generated/announcement-types';
import createAnnouncementMutation from 'garaje/graphql/mutations/CreateAnnouncementMutation';
import announcementAudienceAggregateSizeQuery from 'garaje/graphql/queries/AnnouncementAudienceAggregateSizeQuery';
import type ApolloService from 'garaje/services/apollo-extension';
import type CommunicationsService from 'garaje/services/communications-service';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type ReactAnalytics from 'garaje/services/react-analytics';
import type ReactRendererService from 'garaje/services/react-renderer';
import type StatsigService from 'garaje/services/statsig';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import compact from 'lodash/compact';
import type { Root } from 'react-dom/client';

interface MessageLogNewArgs {
  locationId?: string;
  locationName?: string;
  redirectRoute: string;
}

interface MessageAudience {
  locationId: string;
  type: string;
  employeeGroupsSelectedByCreator?: string[];
  employeeIdsSelectedByCreator?: string[];
}

export default class CommunicationsMessageLogNewComponent extends Component<MessageLogNewArgs> {
  @service declare reactRenderer: ReactRendererService;
  @service declare reactAnalytics: ReactAnalytics;
  @service declare router: RouterService;
  @service declare apolloExtension: ApolloService;
  @service declare flashMessages: FlashMessagesService;
  @service declare communicationsService: CommunicationsService;
  @service declare metrics: MetricsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare statsig: StatsigService;

  private root?: Root;

  getAudiencesFromMessageInput(input: Partial<MessageInput>, locationId: string): MessageAudience[] {
    // To support both string and array values for employeegroups
    // Can be removed when we want to delete the split flag making it single select
    const employeeGroup = compact(
      Array.isArray(input.employeeGroup) ? input.employeeGroup : input.employeeGroup ? [input.employeeGroup] : [],
    );

    const visitorGroup = compact(
      Array.isArray(input.visitorGroup) ? input.visitorGroup : input.visitorGroup ? [input.visitorGroup] : [],
    );

    const audiences: MessageAudience[] = [
      ...employeeGroup
        .filter((g) => !['CUSTOM_EMPLOYEE_AUDIENCE', 'EMPLOYEE_GROUP_AUDIENCE'].includes(g))
        .map((type) => ({ locationId, type })),
      ...visitorGroup.map((type) => ({ locationId, type })),
    ];

    if (input.selectedEmployees?.length) {
      audiences.push({
        employeeIdsSelectedByCreator: input.selectedEmployees,
        locationId,
        type: 'CUSTOM_EMPLOYEE_AUDIENCE',
      });
    }

    if (input.selectedGroups?.length) {
      audiences.push({
        employeeGroupsSelectedByCreator: input.selectedGroups,
        locationId,
        type: 'EMPLOYEE_GROUP_AUDIENCE',
      });
    }

    return audiences;
  }

  async getTotalRecipientCount(input: Partial<MessageInput>, locationId: string): Promise<number> {
    const audiences = this.getAudiencesFromMessageInput(input, locationId);

    if (!audiences.length) {
      return 0;
    }

    const announcementAudienceAggregateSize = await this.apolloExtension.query<
      AnnouncementAudienceAggregateSizeQuery,
      AnnouncementAudienceAggregateSizeVariables,
      'announcementAudienceAggregateSize'
    >(
      {
        query: announcementAudienceAggregateSizeQuery,
        variables: {
          audiences,
          locationId,
        },
      },
      'announcementAudienceAggregateSize',
    );

    return announcementAudienceAggregateSize?.aggregateSize || 0;
  }

  async sendMessage(input: MessageInput): Promise<SentMessage> {
    const canSendEmergencyNotifications = await this.communicationsService.canSendEmergencyNotifications();
    const audiences = this.getAudiencesFromMessageInput(input, input.locationId);

    const announcementInput = {
      title: input.title,
      message: input.message,
      channels: input.deliveryMethods,
      audiences,
      actions: canSendEmergencyNotifications && input.markAsSafe ? ['MARK_AS_SAFE'] : [],
      type: canSendEmergencyNotifications && input.critical ? 'EMERGENCY' : 'ANNOUNCEMENT',
      takeoverActive: input.takeoverActive,
    };

    const announcement = await this.apolloExtension.mutate<
      CreateAnnouncementMutation,
      CreateAnnouncementVariables,
      'createAnnouncement'
    >(
      {
        mutation: createAnnouncementMutation,
        variables: {
          announcementInput,
        },
      },
      'createAnnouncement',
    );

    this.metrics.trackEvent('COMMUNICATIONS_SEND_ANNOUNCEMENT', {
      announcement: announcementInput,
    });

    const {
      statistics: {
        sentCount: { employeesCount, visitorsCount },
      },
    } = announcement;

    return {
      id: announcement.id,
      sentCount: employeesCount + visitorsCount,
    };
  }

  @action
  async injectComponent(): Promise<void> {
    const canSendEmergencyNotifications = await this.communicationsService.canSendEmergencyNotifications();
    const canSendTakeovers = this.communicationsService.canSendTakeovers;

    this.root = this.reactRenderer.render(SendMessage, 'react__communications__send-message', {
      service: {
        analytics: this.communicationsService.analytics,
        canSelectScimGroups: this.communicationsService.canSelectScimGroups,
        canSelectIndividualEmployees: this.communicationsService.canSelectIndividualEmployees,
        showCustomEmployeeSelect: this.communicationsService.showCustomEmployeeSelect,
        showEmployeeSelect: this.communicationsService.showEmployeeSelect,
        showVisitorSelect: this.communicationsService.showVisitorSelect,
        canSendEmergencyNotifications,
        canSendTakeovers,
        showTotalRecipientCount: this.communicationsService.showTotalRecipientCount,
        getTotalRecipientCount: (input: MessageInput, locationId: string) =>
          this.getTotalRecipientCount(input, locationId),
        getAudiences: (locationId: string) => this.communicationsService.getAudiences(locationId),
        getAvailableTemplates: (locationId: string) =>
          Promise.resolve(this.communicationsService.getAvailableTemplates(locationId)),
        getEmployeeGroupOptions: (query: string, page: number) =>
          this.communicationsService.getEmployeeGroupOptions(query, page),
        getEmployeeOptions: (query: string, page: number) => this.communicationsService.getEmployeeOptions(query, page),
        getLocations: () => this.communicationsService.getLocations(),
        getTemplate: (locationId: string, templateId: string) =>
          this.communicationsService.getTemplate(locationId, templateId),
        getAvailableDeliveryMethods: (locationId) =>
          this.communicationsService.getAvailableDeliveryMethods(locationId, !!this.args.locationId),
        getActiveTakeoverMessage: (locationId) => this.communicationsService.getActiveTakeoverMessage(locationId),
      },
      locationId: this.args.locationId,
      locationName: this.args.locationName,
      sendMessage: (input: MessageInput) => this.sendMessage(input),
      onSent: () => this.onSent(),
      onError: (errorMessage, error) => this.onError(errorMessage, error),
      createTemplateUrl: '/communications/templates',
    });
  }

  @action
  destroyComponent(): void {
    if (this.root) {
      this.reactRenderer.unmount(this.root);
      this.root = undefined;
    }
  }

  @action
  onSent(): void {
    void this.router.transitionTo(this.args.redirectRoute);
    this.flashMessages.showAndHideFlash('success', 'Sent successfully');
  }

  @action
  onError(errorMessage: string, error: unknown): void {
    this.flashMessages.showAndHideFlash('error', errorMessage);

    this.workplaceMetrics.logMonitorError({
      event: 'COMMUNICATIONS_SEND_MESSAGE_COMPONENT_ERROR',
      debugExtras: { errorMessage },
      error,
    });
  }
}
