import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class DeliveryAreaSettingsRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view-analytics delivery-areas')) {
      this.router.transitionTo('unauthorized');
    } else {
      const { deliveryArea } = this.modelFor('deliveries.settings.delivery-areas.delivery-area');
      this.router.transitionTo('deliveries.analytics', { queryParams: { deliveryAreaId: deliveryArea.id } });
    }
  }
}
