import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get, action } from '@ember/object';
import { alias } from 'macro-decorators';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { task, dropTask } from 'ember-concurrency';

/**
 * @param {Class<Employee>}            recipientEmployee
 */
export default class ShowNickname extends Component {
  @service flashMessages;
  @service state;
  @service store;

  @tracked isEditing;
  @alias('state.currentLocation.id') locationId;

  get nicknameString() {
    return get(this.args.recipientEmployee, 'recipientAliases').mapBy('name').sort().join(', ');
  }

  @task
  *newAlias(name, recipientEmployee) {
    const { locationId, store } = this;
    const newAlias = store.createRecord('recipient-alias', { name, recipientEmployee, locationId });

    try {
      yield newAlias.save();
      this.flashMessages.showAndHideFlash('success', 'Nicknames updated');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
      newAlias.deleteRecord();
    }
  }

  @task
  *deleteAlias(alias) {
    try {
      yield alias.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Nicknames updated');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  }

  @dropTask
  *updateAliases(aliases) {
    const recipientEmployee = this.args.recipientEmployee;

    // Find and delete any aliases that are missing
    const originalAliases = yield get(recipientEmployee, 'recipientAliases');
    // aliases that were not new and were not deleted
    const remainingAliases = aliases.filter((alias) => typeof alias !== 'string');
    const aliasesToBeDeleted = originalAliases.filter((alias) => {
      return !remainingAliases.mapBy('name').includes(get(alias, 'name'));
    });

    aliasesToBeDeleted.forEach((alias) => {
      this.deleteAlias.perform(alias);
    });

    // Create the new aliases
    const newAliases = aliases.filter((alias) => typeof alias === 'string');
    newAliases.forEach((alias) => {
      this.newAlias.perform(alias, recipientEmployee);
    });
  }

  @action
  search(term) {
    return [term];
  }
}
