// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get, set, action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import moment from 'moment-timezone';

/**
 * Form for purchasing additional subscription quantity
 * @param {String}              billingPeriodCTAText Text for change billing period CTA
 * @param {Function}            cancelDowngradeTask cancel downgrade task
 * @param {Function}            onUpgrade function called when upgrade occurs
 * @param {Array}               plans array of plans
 * @param {Class<Subscription>} subscription A subscription model
 */

export default class PlansVisitorsComponent extends Component {
  @service featureFlags;
  @service metrics;
  @service impressions;
  @service abilities;
  @service router;
  @service statsig;

  @tracked showTrialEndDowngradeToBasicModal = false;
  @tracked showLoseFreeWorkplaceModal = false;

  // this is either 'cancel' or 'downgrade'
  @tracked prosperStackRequestType = null;

  get isBillingAdmin() {
    return this.abilities.can('visit billing');
  }

  get canShowLoseFreeWorkplaceModal() {
    return this.featureFlags.isEnabled('growth_workplace_free_bundle');
  }

  @action
  setShowTrialEndDowngradeToBasicModal(val) {
    this.showTrialEndDowngradeToBasicModal = val;
  }

  @action
  setShowLoseFreeWorkplaceModal(val) {
    this.showLoseFreeWorkplaceModal = val;
  }

  @action
  setProsperStackRequestType(val) {
    this.prosperStackRequestType = val;
  }

  @action
  openTrialExtendedModal() {
    this.showTrialExtendedModal = true;
  }

  @action
  closeTrialExtendedModal() {
    this.showTrialExtendedModal = false;
    this.router.transitionTo('visitors.entries.index');
  }

  get ctaEventProperties() {
    return {
      cta_id: this.ctaId || 'growth-visitors-trial-end-interstitial',
      cta_type: 'card',
      growth_team_project: true,
    };
  }

  get ctaId() {
    if (!this.isBillingAdmin) {
      return 'growth-visitors-trial-end-interstitial_non-billing-admin';
    }

    return `growth-visitors-trial-end-interstitial`;
  }

  get eventMetadata() {
    const trialExpiredDuration = this.subscription.trialEndDate
      ? moment.duration(moment().diff(this.subscription.trialEndDate)).days()
      : null;
    const onExpiredTrial = this.subscription.onExpiredTrial;
    return {
      onExpiredTrial,
      trialExpiredDuration: onExpiredTrial ? trialExpiredDuration : null,
    };
  }

  @action
  logCTAClicked(event) {
    const buttonText = event.target.textContent.trim();
    this.metrics.trackEvent('CTA Clicked', {
      ...this.ctaEventProperties,
      cta_clickable_type: 'button',
      cta_clickable_text: buttonText,
    });

    this.statsig.logEvent('visitors-plans-page-button-clicked', buttonText, this.eventMetadata);
    return event;
  }

  logToGrowthServiceTask = task({ drop: true }, async (action) => {
    if (this.subscription.onExpiredTrial) {
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.VISITORS_TRIAL_END_MODAL[action.toUpperCase()]);
    }
  });

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);
    // this is a one-time set so that if we cancel the downgrade plan the plans don't get in a weird button state as soon as the downgrade plan is nulled out
    set(this, 'downgradePlanName', get(this, 'subscription.downgradePlan.normalizedPlanLevel'));
  }
}
