import { set } from '@ember/object';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

import type DeliveriesAnalyticsController from './controller';

export default class DeliveriesAnalyticsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  declare showDeliveriesOnboarding: boolean;

  beforeModel(): void {
    if (this.abilities.cannot('visit-deliveries analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }

  setupController(controller: DeliveriesAnalyticsController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);
    set(this.state, 'showDeliveriesOnboarding', false);

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }
}
