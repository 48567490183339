import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type Employee from 'garaje/models/employee';
import type SkinnyLocationModel from 'garaje/models/skinny-location';

export default class EmployeeLocationModel extends Model {
  @belongsTo('skinny-location', { async: true }) declare location: AsyncBelongsTo<SkinnyLocationModel>;
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<Employee>;
  @attr('boolean') declare manuallyAdded: boolean;
  // Need for checking if an employee belongs to a specific company
  // without having to load the entire location record
  @attr('number') declare locationId: number;
  @attr('boolean') declare hasRequiredDocumentApproval: boolean;
  @attr('date') declare requiredDocumentApprovalExpiresAt: Date;
  @attr('string') declare requiredDocumentMessage: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-location': EmployeeLocationModel;
  }
}
