import Route from '@ember/routing/route';
import { later } from '@ember/runloop';
import { hash } from 'rsvp';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

class IntegrationsRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service flashMessages;
  @service integrationsManager;
  @service state;
  @service locations;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('visit advance-settings')) {
      this.router.transitionTo('billing');
    }
    if (this.featureFlags.isEnabled('appStore')) {
      this.router.replaceWith('apps');
    }
  }

  model() {
    const { vrSubscription, deliveriesSubscription, workplaceSubscription, currentCompany, currentLocation } =
      this.state;
    return hash({
      integrations: this.integrationsManager.loadIntegration(currentCompany.id),
      plugins: this.store.query('plugin', {
        filter: { location: currentLocation.id },
      }),
      pluginInstalls: this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
      }),
      vrSubscription,
      deliveriesSubscription,
      workplaceSubscription,
    });
  }

  afterModel() {
    return this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  scrollToIntegration(selector) {
    later(function () {
      document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 200);
  }

  @action
  installSalesforce() {
    const { currentCompany } = this.state;
    return this.integrationsManager
      .connectSalesforce(currentCompany)
      .then(() => {
        this.router
          .transitionTo('integrations.enabled', {
            queryParams: {
              integration: 'salesforce',
            },
          })
          .then(() => {
            this.scrollToIntegration('#salesforce-config');
          });
      })
      .catch(() => {
        this.flashMessages.showAndHideFlash('error', 'Failed to install Salesforce integration');
      });
  }

  @action
  enableGoogleHangouts() {
    const { currentCompany } = this.state;
    set(currentCompany, 'gtalkEnabled', true);

    currentCompany.save().then(() => {
      this.router.transitionTo('integrations.enabled', { queryParams: { integration: 'google-hangouts' } }).then(() => {
        this.scrollToIntegration('#google-hangouts-config');
      });
    });
  }
}

export default IntegrationsRoute;
