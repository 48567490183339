import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

import type AnalyticsDesksController from './controller';

export default class AnalyticsDesksRoute extends Route {
  @service declare featureConfig: FeatureConfigService;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  title = 'Desks · Analytics · Envoy';

  setupController(controller: AnalyticsDesksController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  beforeModel(): void {
    if (this.abilities.cannot('visit-desks analytic')) {
      this.router.transitionTo('unauthorized');
    } else if (!this.featureConfig.isEnabled('desks.analytics')) {
      this.router.transitionTo('desks');
    }
  }
}
