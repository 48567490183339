import Component from '@glimmer/component';
import { service } from '@ember/service';
import { set } from '@ember/object';
import { all, restartableTask } from 'ember-concurrency';
import { action } from '@ember/object';

export default class extends Component {
  @service trial;
  @service state;
  @service metrics;

  get legalDocumentsEnabled() {
    return this.args.agreementPage.enabled && !!this.state.features?.includesNda;
  }

  get hasAccessToIncludesNdaFeature() {
    return !!this.state.features?.includesNda;
  }

  @restartableTask
  *updateAgreementPositions(agreements) {
    yield all(
      agreements.map((agreement, index) => {
        set(agreement, 'position', index + 1);
        return agreement.save({ propagable: false });
      }),
    );
  }

  @action
  toggleAgreementEnabled(agreement, value) {
    this.args.updateAndSaveTask.perform(agreement, 'enabled', value, false);
  }

  @action
  toggleAgreementPageEnabled(agreementPage, value) {
    this.args.updateAndSaveTask.perform(agreementPage, 'enabled', value, false);
    this.args.trackFeatureToggled(value);
  }
}
