import { service } from '@ember/service';
import Model, { attr, hasMany } from '@ember-data/model';
import type { AsyncHasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type FlowModel from 'garaje/models/flow';
import type MailerBlockModel from 'garaje/models/mailer-block';
import type MailerSectionModel from 'garaje/models/mailer-section';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type StateService from 'garaje/services/state';
import type { SinglePayload } from 'jsonapi/response';

export const DEFAULT_FONT_FACE = "'Cera-Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif";

export default class MailerTemplateModel extends Model {
  // Used for mapping UIHooks triggerName filter to a given mailer-template identifier.
  static TRIGGER_NAME_MAPPING = {
    ['welcome']: 'welcome_email',
    ['registration']: 'registration_email',
  };

  // These are the types of email notification that should *always* use
  // Company Name in the "From" headers.
  static IDENTIFIERS_ALWAYS_FROM_COMPANY = Object.freeze(['registration']);

  @service declare state: StateService;

  // Relationships
  @hasMany('company', { async: true }) declare companies: AsyncHasMany<CompanyModel>;
  @hasMany('flow', { async: true }) declare flows: AsyncHasMany<FlowModel>;

  @hasMany('mailer-section', { async: true }) declare mailerSections: AsyncHasMany<MailerSectionModel> | null;
  @hasMany('skinny-location', { async: true }) declare locations: AsyncHasMany<SkinnyLocationModel>;

  // Attributes
  @attr('boolean') declare active: boolean;
  @attr('boolean') declare envoyDefault: boolean;
  @attr('string') declare description: string;
  @attr('string') declare envoyDefaultId: string;
  @attr('string') declare identifier: string;
  @attr('string') declare subject: string;
  @attr('string') declare subtitle: string;
  @attr('string') declare templateFontFace: string;
  @attr('string') declare templateTextAlignment: string;
  @attr('string') declare title: string;

  get mailerBlocks(): MailerBlockModel[] | null {
    const { mailerSections } = this;

    if (!mailerSections?.length) return null;

    return mailerSections.reduce<MailerBlockModel[]>(
      (acc: MailerBlockModel[], section: MailerSectionModel): MailerBlockModel[] => {
        return [...acc, ...(<MailerSectionModel>(<unknown>section)).mailerBlocks.toArray()];
      },
      [],
    );
  }

  get childTemplateConfig(): Partial<MailerTemplateModel> {
    const { id, templateFontFace = DEFAULT_FONT_FACE, templateTextAlignment = 'left' } = this;

    return {
      envoyDefault: false,
      envoyDefaultId: id,
      templateFontFace,
      templateTextAlignment,
    };
  }

  serializeWithBlockOverrides(): object {
    const { id } = this;
    const { currentCompany, currentLocation } = this.state;
    const serializedOverrides =
      this.mailerBlocks?.map((override): object => {
        const json = (<SinglePayload<MailerBlockModel>>override.serialize()).data;

        json['type'] = 'mailer-block-overrides';
        json['relationships'] = {
          'mailer-block': {
            data: {
              type: 'mailer-blocks',
              id: override.id,
            },
          },
          'mailer-template': {
            data: {
              type: 'mailer-templates',
              id,
            },
          },
        };

        return json;
      }) ?? null;
    const payload = <SinglePayload<MailerTemplateModel> & { meta: unknown }>this.serialize();

    payload.meta = {
      ...(payload.meta || {}),
      location_id: currentLocation.id,
      company_id: currentCompany.id,
    };
    payload.data.relationships = {
      ...(payload.data.relationships || {}),
      'mailer-block-overrides': {
        data: serializedOverrides,
      },
    };

    return payload;
  }

  async getEmailPreview(): Promise<unknown> {
    const data = this.serializeWithBlockOverrides();

    return await apiAction(this, { method: 'PATCH', path: 'preview', data });
  }

  async sendTestEmail(): Promise<unknown> {
    const data = this.serializeWithBlockOverrides();

    return await apiAction(this, { method: 'PATCH', path: 'send-test', data });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'mailer-template': MailerTemplateModel;
  }
}
