import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';

export default class FlowsScreeningNameEditor extends Component {
  @service flashMessages;

  @tracked value = '';
  @tracked cachedValue = '';

  constructor() {
    super(...arguments);
    this.value = this.args.value;
    this.cachedValue = this.args.value;
    this.model = this.args.model;
    this.updateState = this.args.updateState;
  }

  get isDirty() {
    return this.model && this.model.id && this.value != this.cachedValue;
  }

  @action
  contentChanged(text) {
    this.cachedValue = text;
    this.updateState(this.isDirty);
  }

  @dropTask
  *save() {
    this.value = this.cachedValue;
    this.model.name = this.cachedValue;
    yield this.model.save();
    this.updateState(this.isDirty);

    try {
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  }

  @action
  reset() {
    this.cachedValue = this.value;
  }
}
