import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

import type { ProfileDocumentUploadRouteModel } from '../route';

export default class ProfileDocumentUploadConfirmRoute extends Route {
  @service declare router: RouterService;

  afterModel({ userDocument, userDocumentTemplate }: ProfileDocumentUploadRouteModel): void {
    const requiredTemplateAttachment = userDocument?.checkRequiredTemplateAttachments();
    if (requiredTemplateAttachment) {
      void this.router.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        requiredTemplateAttachment.identifier,
      );
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    // @ts-ignore
    if (transition.targetName === 'profile.document.index') {
      this.send('documentListAltered');
    }
  }
}
