/* eslint-disable ember/no-computed-properties-in-native-classes */
import $ from 'jquery';
import Ember from 'ember';
import EmberObject, { computed, get, set } from '@ember/object';
import Service, { service } from '@ember/service';
import config from 'garaje/config/environment';
import moment from 'moment-timezone';
import zft from 'garaje/utils/zero-for-tests';
import { alias } from '@ember/object/computed';
import { loadHeadway, loadChameleon } from 'garaje/utils/script-loader';
import { resolve } from 'rsvp';
import { dropTask, restartableTask, timeout, didCancel } from 'ember-concurrency';
import deviceStatus from 'garaje/utils/decorators/device-status';
import { getActiveEmployeeCategory, getActiveLocationsCategory } from 'garaje/utils/log-rocket-helpers';
import { tracked } from '@glimmer/tracking';

@deviceStatus
class DeviceStatus extends EmberObject {}

const currentLocation = {};
[
  'save',
  'rollbackAttributes',
  'changedAttributes',
  'generateApiKey',
  'generatePreRegistrationLink',
  'generateSecurityDeskLink',
  'belongsTo',
].map(function (method) {
  currentLocation[method] = function () {
    const content = get(this, 'content');
    return content[method](...arguments);
  };
});

// eslint-disable-next-line ember/no-classic-classes
export default class CurrentLocationService extends Service.extend(Ember._ProxyMixin, currentLocation) {
  @service abilities;
  @service cookies;
  @service currentAdmin;
  @service devicesManager;
  @service integrationsManager;
  @service locations;
  @service moment;
  @service pubnub;
  @service store;
  @service state;
  @service splitio;
  @service featureFlags;
  @service metrics;
  @service wootric;

  @alias('content') location;

  pubnubInstance = null;
  @tracked iPadVersions = [];

  reset() {
    this.teardownPubnub();
    this.loadIntegrations.cancelAll();
    this.pubnub.fetchChannels.cancelAll();
    set(this, 'content', null);
  }

  setupPubnub(masterRoute) {
    if (!this.content) {
      return false;
    }

    const devicesManager = get(this, 'devicesManager');
    const integrationsManager = get(this, 'integrationsManager');

    this.pubnub.fetchChannels
      .perform({
        locationId: get(this, 'id'),
        userId: get(this, 'currentAdmin.id'),
      })
      .then((pubnubConfig) => {
        set(this, 'pubnubConfig', pubnubConfig);
        const pubnub = get(this, 'pubnub').initialize(get(pubnubConfig, 'cipherKey'), 'current-location');
        this.pubnubInstance = pubnub;

        pubnub.subscribe({
          channel: get(pubnubConfig, 'channelIntegrations'),
          message: (data) => {
            const payload = $.parseJSON(data);

            integrationsManager.handleEvent(payload, this);
          },
        });

        pubnub.subscribe({
          channel: [get(pubnubConfig, 'channelEntry'), get(pubnubConfig, 'channelInvite')],
          message: (m) => {
            const payload = $.parseJSON(m);
            const eventType = get(payload, 'event');
            const isHubEvent = eventType === 'hub_event_report';

            if (isHubEvent) {
              const loadedEventReport = this.store.peekRecord('eventReport', get(payload, 'event_report.id'));
              if (loadedEventReport) {
                this.store.pushPayload('eventReport', {
                  data: {
                    type: 'event-reports',
                    id: payload.event_report.id,
                    attributes: payload.event_report,
                  },
                });
              }
              return;
            }

            if (payload.event && !this.ignorePubnubEntryEvent()) {
              masterRoute.pushEntry(payload);
            }

            if (payload.type && payload.type === 'delete_invite') {
              masterRoute.deleteInvite(payload);
            } else if (payload.type && !this.ignorePubnubInviteEvent()) {
              masterRoute.newInvite(payload);
            }
          },
        });

        pubnub.subscribe({
          channel: get(pubnubConfig, 'channelDevices'),
          message(data) {
            const payload = $.parseJSON(data);
            devicesManager.handleEvent(payload);
          },
        });
      })
      .catch((e) => {
        if (!didCancel(e)) {
          throw e;
        }
      });
  }

  teardownPubnub() {
    if (!this.content) {
      return false;
    }

    const pubnubConfig = get(this, 'pubnubConfig');

    if (pubnubConfig) {
      this.pubnubInstance?.unsubscribe({
        channel: [
          get(pubnubConfig, 'channelEntry'),
          get(pubnubConfig, 'channelInvite'),
          get(pubnubConfig, 'channelDevices'),
          get(pubnubConfig, 'channelIntegrations'),
        ],
      });
    }
  }

  loadIntegrations = dropTask(async () => {
    const { currentCompany } = this.state;
    await this.integrationsManager.loadIntegration(currentCompany.id);
  });

  setupHeadway() {
    if (!window.HW_config) {
      window.HW_config = {
        selector: '.updates-trigger',
        account: '9Jl95y',
        translations: {
          title: 'Latest Updates',
          readMore: 'Read more',
          labels: { new: 'New', improvements: 'Improvements', fix: 'Fixes' },
        },
        callbacks: {
          onWidgetReady: function (widget) {
            if (widget.getUnseenCount() === 0) {
              $('#HW_badge').hide();
            }
          },
          onShowWidget: () => $('#HW_badge').hide(),
        },
      };
      loadHeadway();
    }
  }

  teardownHeadway() {
    const node = document.getElementById('envoyheadwayapp');
    if (node) {
      if (node.remove) {
        node.remove();
      } else if (node.parentNode) {
        node.parentNode.removeChild(node);
      }

      if (window.HW_config) {
        delete window.HW_config;
      }
    }
  }

  setupChameleon() {
    loadChameleon();
  }

  setTrackingData(isBoss) {
    if (this.content && !isBoss && config.environment !== 'development') {
      this.setupSegment();
    }
  }

  // Setup for Intercom (via Segment)
  setupSegment() {
    const company = get(this, 'state.currentCompany');
    const vrSubscription = get(this, 'state.vrSubscription') || {};
    const currentAdmin = get(this, 'currentAdmin');
    const metrics = get(this, 'metrics');
    const wootric = get(this, 'wootric');

    const userId = get(currentAdmin, 'id');

    const intercomIdentityHash = get(this, 'state.currentUser.intercomIdentityHash');
    const { cookieDomain: domain } = config;

    const userCreatedAtUnix = get(currentAdmin, 'createdAt')
      ? moment(get(currentAdmin, 'createdAt')).format('x')
      : null;

    const companyMetaState = get(this, 'state._companyMeta') || {};
    const activeEmployeeCount = get(companyMetaState, 'employees-count');
    const activeLocationsCount = get(companyMetaState, 'active-locations-count');

    const activeEmployeesCategory = getActiveEmployeeCategory(activeEmployeeCount);
    const activeLocationsCategory = getActiveLocationsCategory(activeLocationsCount);

    const traits = {
      createdAt: userCreatedAtUnix,
      email: get(currentAdmin, 'email') || null,
      employeeId: get(currentAdmin, 'employee.id') || null,
      name: get(currentAdmin, 'fullName') || null,
      phone: get(currentAdmin, 'phoneNumber') || null,
      url: get(window, 'location.href') || null,
      last_dashboard_location_name: get(this, 'name') || null,
      last_dashboard_location_id: get(this, 'id') || null,
      last_dashboard_location_boss_link: `https://boss.envoy.com/locations/${get(this, 'id')}` || null,
      has_role_last_dashboard_location_receptionist: get(currentAdmin, 'isReceptionist'),
      has_role_last_dashboard_location_security: get(currentAdmin, 'isSecurity'),
      has_role_last_dashboard_location_location_admin: get(currentAdmin, 'isLocationAdmin'),
      has_role_last_dashboard_location_employee: get(currentAdmin, 'isEmployee'),
      has_role_last_dashboard_company_billing: get(currentAdmin, 'isBillingAdmin'),
      has_role_last_dashboard_company_global_admin: get(currentAdmin, 'isGlobalAdmin'),
      has_role_any_location_security: get(currentAdmin, 'allLocationRoleNames').includes('Security'),
      has_role_any_location_location_admin: get(currentAdmin, 'allLocationRoleNames').includes('Location Admin'),
      has_role_any_location_receptionist: get(currentAdmin, 'allLocationRoleNames').includes('Receptionist'),
      has_role_any_location_employee: get(currentAdmin, 'allLocationRoleNames').includes('Employee'),
      company: {
        id: get(company, 'id') || null,
        name: get(company, 'name') || null,
        last_employee_directory_sync_at: get(company, 'syncedSince') || null,
        plan: get(vrSubscription, 'plan') || null,
        vr_plan_name: get(vrSubscription, 'plan') || null,
        company_retool_link: `https://retool.envoy.christmas/presentation/Account%20%26%20User%20Explorer/Company#companyID=${get(
          company,
          'id',
        )}`,
      },
      'company.id': get(company, 'id') || null,
      'company.name': get(company, 'name') || null,
      'company.employee_count': activeEmployeeCount || null,
      'company.employee_count_category': activeEmployeesCategory || null,
      'company.active_locations_count': activeLocationsCount || null,
      'company.active_locations_count_category': activeLocationsCategory || null,
      'company.last_employee_directory_sync_at': get(company, 'syncedSince') || null,
      'company.plan': get(vrSubscription, 'plan') || null,
      'company.vr_plan_name': get(vrSubscription, 'plan') || null,
      'company.company_retool_link': `https://retool.envoy.christmas/presentation/Account%20%26%20User%20Explorer/Company#companyID=${get(
        company,
        'id',
      )}`,
    };

    if (get(vrSubscription, 'hasTrialDaysLeft')) {
      traits.company.vr_trial_end_at = get(vrSubscription, 'trialEndDate') || null;
    }

    metrics.identify(userId, traits);
    if (window.Intercom && window.intercomSettings?.user_id !== userId) {
      window.Intercom('shutdown');
      window.intercomSettings = Object.assign({}, window.intercomSettings, {
        user_id: userId,
        user_hash: intercomIdentityHash,
      });
      window.Intercom('boot');
    }
    // Write a cookie for envoy.com and help.envoy.com to use the
    // Intercom messenger with identity verification on.
    get(this, 'cookies').write('intercomIdentityHash', intercomIdentityHash, { domain });

    if (!get(this, 'currentAdmin.isEmployee')) {
      wootric.run(userId, traits);
    }
  }

  checkDevicesStatus = restartableTask(async () => {
    if (this.abilities.cannot('update devices') || !get(this, 'location.id')) {
      return resolve();
    }

    const devices = await this.store.query('device', { filter: { location: get(this, 'location.id') } });

    await this.setDeviceStatus(devices);

    this.checkiPadVersions(devices);
  });

  checkiPadVersions(devices) {
    const iPadVersions = devices
      .filter((device) => device.isIpad && device.isConnected)
      .map((device) => device.appVersion);
    set(this, 'iPadVersions', iPadVersions || []);
  }

  setDeviceStatus(devices) {
    return get(this, 'printer').then((printerModel) => {
      const wrappedDevices = devices
        .filterBy('isConnected')
        .filter((device) => {
          // if deviceType is not defined, it is probably a stale
          // device. We want to ignore since it won't even appear on
          // the user list.
          return get(device, 'hasType') && get(device, 'isConnected');
        })
        .map((device) => {
          return DeviceStatus.create({
            // FF:device-status-pinging
            // need to pass in featureFlags becasue we are not looking up DeviceStatus class from the container
            // this will go away once we move device status onto models
            featureFlags: get(this, 'featureFlags'),
            model: device,
            currentAppVersion: get(this, 'currentVersion'),
            isPrinterEnabled: printerModel && get(printerModel, 'enabled'),
          });
        });

      if (wrappedDevices.isAny('isStatusBad')) {
        set(this, 'deviceStatus', 'bad');
      } else if (wrappedDevices.isAny('isStatusWarn')) {
        set(this, 'deviceStatus', 'warn');
      } else {
        set(this, 'deviceStatus', null);
      }
    });
  }

  searchUsers = restartableTask(
    async (term, roles = ['Global Admin', 'Location Admin', 'Receptionist'], confirmedUsers = false) => {
      await timeout(zft(250));

      const result = await this.store.query('user-role', {
        filter: { query: term, location: get(this, 'id'), roles: roles.join(','), confirmedUsers },
      });

      return result;
    },
  );

  getUniqueUserRoleCount = restartableTask(
    async (term, roles = ['Global Admin', 'Location Admin', 'Receptionist'], confirmedUsers = true) => {
      await timeout(zft(250));

      const result = await this.store.query('user-role', {
        filter: { query: term, roles: roles.join(','), confirmedUsers },
      });

      const uniqueIds = new Set();
      result.map((role) => {
        const id = get(role.user, 'id');
        if (id !== undefined) {
          uniqueIds.add(id);
        }
      });

      return uniqueIds.size || 0;
    },
  );

  owns(model) {
    return get(this, 'id') === model.belongsTo('location').id();
  }

  getCorrectPrinter() {
    return this.store.queryRecord('printer', {
      filter: { device_config: get(this, 'config.id') },
    });
  }

  pollPrinter() {
    return this.getCorrectPrinter();
  }

  // These two ignore Pubnub functions are a temporary workaround to disable Pubnub updating the Entry or Invite log
  // when a custom role does not have the ability to view all Entries or Invites.
  // The correct fix will need to overhaul the v2/me/pubnub-channels API to return the correct channels based
  // on a user's permissions, along with the a cipherKey per channel rather than the same cipherKey for all
  // channels.
  ignorePubnubEntryEvent() {
    return !get(this, 'currentAdmin.isEmployee') && this.abilities.cannot('view all entries');
  }

  ignorePubnubInviteEvent() {
    return !get(this, 'currentAdmin.isEmployee') && this.abilities.cannot('view all invites');
  }

  @computed('location.printer', 'currentLocation.config')
  get printer() {
    return this.getCorrectPrinter();
  }
}
