import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class FlashMessageExamples extends Component {
  @service flashMessages;

  @action
  hideFlash() {
    this.flashMessages.hideFlash();
  }

  @action
  showError() {
    this.flashMessages.showFlash('error', 'Oops something went wrong', 'Please try again later');
  }

  @action
  showInfo() {
    this.flashMessages.showFlash('info', '411, you know what', 'Well it depends on what you know');
  }

  @action
  showSuccess() {
    this.flashMessages.showFlash('success', 'Sweet, that works', 'I can do this all day long');
  }

  @action
  showWarning() {
    this.flashMessages.showFlash('warning', 'You have been warned', 'Here be dragons');
  }

  @action
  showAndHide() {
    this.flashMessages.showAndHideFlash('info', 'A message from IMF', 'This message will self destruct in 3 seconds');
  }

  @action
  showComponent(componentName) {
    this.flashMessages.showFlashComponent('info', 'Render a component as message details', componentName);
  }
}
