import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class EmployeesController extends Controller {
  @service currentAdmin;
  @service featureFlags;
  @service visitorsOnboarding;
  @service emptyStatePage;

  get showSetupGuide() {
    return (
      (this.currentAdmin.isGlobalAdmin || this.currentAdmin.isLocationAdmin) &&
      !this.emptyStatePage.shouldShowEmployeeDirectoryEmptyPage &&
      this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')
    );
  }
}
