import type ModelRegistry from 'ember-data/types/registries/model';
import ApplicationAdapter from 'garaje/adapters/application';
import config from 'garaje/config/environment';

// adapter for generic resources
export default class GenericsAdapter extends ApplicationAdapter {
  host = config.rmsHost;
  namespace = 'a/rms/v2/generics';

  pathForType<K extends keyof ModelRegistry>(modelName: K): string {
    if (modelName === 'generic-resource') {
      return 'resources';
    }
    return super.pathForType(modelName);
  }
}
