import Component from '@glimmer/component';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { task, dropTask } from 'ember-concurrency';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {Function}    onClick
 * @param {Object}      response
 */
export default class FlashMessageRoomsDiscountComponent extends Component {
  @service ajax;
  @service currentAdmin;
  @service flashMessages;
  @service metrics;

  get message() {
    if (this.args.response.hasSufficientRoomsForDiscount) {
      return 'Your workplace qualifies for up to 35% off.';
    } else {
      return 'Customers who buy more than 10 rooms could qualify for up to 35% off.';
    }
  }

  constructor() {
    super(...arguments);
    this.trackView.perform();
  }

  @task
  *trackView() {
    yield this.metrics.trackEvent('Viewed flash message', {
      product: 'rooms',
      type: 'info',
      message_title: `${this.args.response.title} ${this.message}`,
      message_code: 'rooms_discount_message_viewed',
      message_code_type: 'rooms_discount_message',
    });
  }

  @dropTask
  *createSalesforceLead() {
    try {
      const salesforceLeadData = {
        data: {
          type: 'salesforce-leads',
          attributes: {
            email: this.currentAdmin.email,
            'lead-source': 'Billing Page - Rooms Custom Pricing Alert',
            name: this.currentAdmin.fullName,
          },
        },
      };

      yield this.ajax.post(urlBuilder.v3.salesforceLeads.createUrl(), { data: salesforceLeadData });

      this.flashMessages.showAndHideFlash('success', 'Request Sent', 'We’ll be reaching out soon!');
    } catch (error) {
      this.flashMessages.showFlash('error', 'Try again', parseErrorForDisplay(error));
      throw error;
    }
  }
}
