import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { isEmpty } from '@ember/utils';

import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validateFormat, validatePresence } from 'ember-changeset-validations/validators';
import { service } from '@ember/service';
import { task, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const Validations = {
  token: validatePresence({
    presence: true,
    message: "can't be blank",
  }),
  domain: [
    validatePresence({
      presence: true,
      message: "can't be blank",
    }),
    validateFormat({
      type: 'url',
      message: 'must be a valid URL',
    }),
  ],
};

/**
 * @param {Object}              okta                        model for the okta integration
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            integrationDisconnected
 * @param {Function}            save
 */
export default class OktaConfigBox extends Component {
  @service currentAdmin;
  @service flashMessages;

  @tracked changeset;

  constructor() {
    super(...arguments);

    const validator = lookupValidator(Validations);
    this.changeset = new Changeset(this.args.okta ?? {}, validator, Validations);
  }

  get isDirty() {
    return this.args.okta?.isNew || this.changeset.isDirty;
  }

  get connected() {
    return !isEmpty(this.args.okta);
  }

  get isOpen() {
    return this.args.integration === 'okta';
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  @action
  close() {
    if (this.isDirty) {
      if (this.confirmAction()) {
        if (get(this.args.okta, 'isNew')) {
          this.args.okta.destroyRecord().then(() => {
            this.args.integrationDisconnected();
          });
        } else {
          this.changeset.rollback();
        }
      } else {
        return;
      }
    }
    this.args.closeAction();
  }

  @dropTask
  *saveTask(callSave = true) {
    const { changeset } = this;

    yield changeset.validate();

    if (!get(changeset, 'isValid')) {
      return;
    }

    // This component can receive an action for saving the changeset
    // (save=(action saveSomething), but we might not want to call
    // such actions all the time.
    //
    // This allow us to use the ember-concurrency task in other places
    // where we need to call save on the model like toggling on/off
    // the enabled state.
    //
    if (callSave) {
      yield this.args.save(changeset);
    } else {
      yield changeset.save();
    }
  }

  @dropTask
  *disableTask() {
    try {
      set(this.changeset, 'enabled', false);
      yield this.saveTask.perform(false);
      this.close();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this, 'changeset.enabled', true);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *enableTask() {
    try {
      set(this.changeset, 'enabled', true);
      yield this.saveTask.perform(false);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this.changeset, 'enabled', false);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @action
  toggleEnable(isEnable) {
    if (isEnable) {
      this.enableTask.perform();
    } else {
      this.disableTask.perform();
    }
  }

  @task
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }

    yield this.args.okta.destroyRecord();
    this.close();
    this.args.integrationDisconnected(true);
  }
}
