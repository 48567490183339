import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, ZONE_SECURITY, ZONE_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_MANAGE_DEVICES = [GLOBAL_ADMIN, ZONE_ADMIN];
const CAN_MANAGE_APPS = [GLOBAL_ADMIN, ZONE_ADMIN];
const CAN_MANAGE_PROPERTIES = [GLOBAL_ADMIN, ZONE_ADMIN];
const CAN_MANAGE_SECURITY = [GLOBAL_ADMIN, ZONE_ADMIN];
const CAN_DELETE_PROPERTIES = [GLOBAL_ADMIN];
const CAN_CREATE_PROPERTIES = [GLOBAL_ADMIN];
const CAN_VIEW_ALL_PROPERTIES = [GLOBAL_ADMIN, ZONE_SECURITY, ZONE_ADMIN];
const CAN_VISIT_PROPERTIES = [GLOBAL_ADMIN, ZONE_SECURITY, ZONE_ADMIN];
const CAN_VISIT_DIRECTORY = [GLOBAL_ADMIN, ZONE_SECURITY, ZONE_ADMIN];

export default class PropertyAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_PROPERTIES, roleNames));
  }

  get canManage(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_PROPERTIES, roleNames));
  }

  get canManageApps(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_APPS, roleNames));
  }

  get canManageDevices(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_DEVICES, roleNames));
  }

  get canManageSecuritySettings(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_MANAGE_SECURITY, roleNames));
  }

  get canDelete(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_DELETE_PROPERTIES, roleNames));
  }

  get canCreate(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_CREATE_PROPERTIES, roleNames));
  }

  get canViewAll(): boolean {
    const { roleNames } = this.currentAdmin;

    // Permission to access connect overview page does not care about whether a user can access it from a specific property.
    const unfilteredZoneRoles = this.state.currentUser?.zoneRoles.mapBy('roleName');
    return (
      isPresent(_intersection(CAN_VIEW_ALL_PROPERTIES, roleNames)) ||
      isPresent(_intersection(CAN_VIEW_ALL_PROPERTIES, unfilteredZoneRoles))
    );
  }

  get canVisitDirectory(): boolean {
    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(CAN_VISIT_DIRECTORY, roleNames));
  }
}
