import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureConfigService from 'garaje/services/feature-config';

import type AnalyticsStrategicInsightsController from './controller';

export default class AnalyticsStrategicInsightsRoute extends Route {
  @service declare featureConfig: FeatureConfigService;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  title = 'Strategic Insights · Analytics · Envoy';

  setupController(controller: AnalyticsStrategicInsightsController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (this.abilities.can('visit strategic insights in analytic')) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  beforeModel(): void {
    if (this.abilities.cannot('visit strategic insights in analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }
}
