import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import { equal, not } from 'macro-decorators';

export default class SignedAgreementsJobModel extends Model {
  @belongsTo('entry', { async: true }) declare entry: AsyncBelongsTo<EntryModel>;
  @belongsTo('invite', { async: true }) declare invite: AsyncBelongsTo<InviteModel>;

  @attr('string') declare status: string;
  @attr('string') declare url: string;

  @equal('status', 'completed') isDone!: boolean;
  @not('isDone') isNotDone!: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'signed-agreements-job': SignedAgreementsJobModel;
  }
}
