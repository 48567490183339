import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('hub')
class NotificationPreferenceModel extends Model {
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @attr('date') declare insertedAt: Date;
  @attr('string') declare notificationPlatformKey: string;
  @attr('string') declare notificationType: string;
  @attr('boolean') declare preferenceValue: boolean;
  @attr('string') declare relationId: string;
  @attr('string') declare relationType: string;
  @attr('date') declare updatedAt: Date;
}

export default NotificationPreferenceModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'notification-preference': NotificationPreferenceModel;
  }
}
