import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StatsigService from 'garaje/services/statsig';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

export default class EmployeesNavBarComponent extends Component {
  @service declare router: RouterService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare statsig: StatsigService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  get isGlobalOverview(): boolean {
    return this.router.currentRouteName?.includes('location-overview');
  }

  @action
  logEvent(event_name: string): void {
    this.statsig.logEvent(event_name);
    this.workplaceMetrics.trackEvent(`WORKPLACE_SETUP_GUIDE_${event_name.toLocaleUpperCase()}`);
  }
}
