import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { or, reads } from 'macro-decorators';
import moment from 'moment-timezone';

const DEFAULT_PAGE_SIZE = 30;
const DEFAULT_DATE_RANGE = 'Today';

export default class MyLocationsVisitorLogController extends Controller {
  @service declare skinnyLocations: SkinnyLocationsService;

  queryParams = [
    'endDate',
    'locationIds',
    'pageNumber',
    'pageSize',
    'query',
    'signedOut',
    'sortBy',
    'sortDirection',
    'startDate',
    'selectedDateRange',
  ];

  @reads('model.entries.meta.total') metaRecordCount!: number;
  @or('metaRecordCount', 'defaultRecordCount') recordCount!: number;

  @tracked pageNumber = 1;
  @tracked pageSize = DEFAULT_PAGE_SIZE;
  @tracked selectedDateRange = DEFAULT_DATE_RANGE;
  @tracked defaultRecordCount = 0;
  @tracked startDate = moment().startOf('day').format();
  @tracked endDate = moment().endOf('day').format();
  @tracked locationIds = '';
  @tracked signedOut?: string;
  @tracked query?: string;

  @action
  setDateRange(option: string, start: string, end: string): void {
    this.startDate = start;
    this.endDate = end;
    this.selectedDateRange = option;
  }

  @action
  setLocationIds(locationIds: string): void {
    this.locationIds = locationIds;
    this.pageNumber = 1;
  }

  @action
  setSignedOut(signedOut: string): void {
    this.signedOut = signedOut;
    this.pageNumber = 1;
  }

  @action
  setQuery(query: string): void {
    this.query = query;
    this.pageNumber = 1;
  }
}
