import Controller, { inject as controller } from '@ember/controller';
import { get, action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import _intersection from 'lodash/intersection';
import { task, dropTask } from 'ember-concurrency';
import { entryApprovalMessage } from 'garaje/helpers/entry-approval-message';
import { fetchCapacity } from 'garaje/utils/locations-capacity';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { reads } from 'macro-decorators';

export default class WorkplaceEntriesIndexController extends Controller {
  @service currentLocation;
  @service currentAdmin;
  @service metrics;
  @service store;
  @service workplaceMetrics;

  @controller('workplace.entries') entries;

  @reads('entries.eventReports') eventReports;
  @reads('entries.isToday') isToday;
  @reads('entries.isAfterToday') isAfterToday;

  get signInFieldPages() {
    return get(this.currentLocation, 'flows').mapBy('signInFieldPage');
  }

  get allSignInFieldsFulfilled() {
    return this.signInFieldPages.isEvery('isFulfilled');
  }

  get showVideo() {
    const canSeeVideo = isPresent(
      _intersection(['Global Admin', 'Location Admin'], get(this.currentAdmin, 'roleNames')),
    );
    return get(this.entries, 'noHistoricVisitors') && canSeeVideo;
  }

  get sortField() {
    return get(this.entries, 'sort').replace(/^[-|+]/g, '');
  }

  get sortDirection() {
    return get(this.entries, 'sort').startsWith('-') ? 'desc' : 'asc';
  }

  get menuHeight() {
    // Keep a minimum of 13px space from the table
    return `${document.querySelector('.employeesTable-header').clientHeight + 13}px`;
  }

  get isSelectAllIndeterminate() {
    const selectedCount = get(this.entries, 'selectedEntries.length');
    const totalEntries = get(this.entries, 'relevantEntries.length');
    return selectedCount > 0 && selectedCount !== totalEntries;
  }

  get allEntriesSelected() {
    const selectedCount = get(this.entries, 'selectedEntries.length');
    const totalEntries = get(this.entries, 'relevantEntries.length');
    return selectedCount === totalEntries;
  }

  @action
  clearAll() {
    return this.entries.clearAll();
  }

  @action
  selectedDidChange(selected) {
    return this.entries.selectedDidChange(selected);
  }

  @dropTask
  *signOutTask(entry, actionOrigin) {
    this.metrics.trackEvent('Dashboard Entry - Signed Out', { action_origin: actionOrigin });
    this.workplaceMetrics.trackEvent('EMPLOYEE_LOG_ENTRY_SIGN_OUT_BUTTON_CLICKED', {
      entry: entry.id,
      action_origin: actionOrigin,
    });
    yield this.entries.signOutEntry.perform(entry);
    yield this.refreshLocationsCapacity(entry.signedInAt);
  }

  @action
  checkID(entry) {
    return this.entries.checkID(entry);
  }

  @action
  reprintBadge(entry, printer = null) {
    // if a printer is specified then try to use the first/only available printer, otherwise it'll fall back to v2 api entry.printBadge
    printer = printer || get(this.model.printers, 'firstObject') || null;
    return this.entries.reprintBadge(entry, printer);
  }

  @action
  deleteEntry(entry) {
    return this.entries.deleteEntry(entry);
  }

  @action
  deleteEntries(entries) {
    return this.entries.deleteEntries(entries);
  }

  @action
  loadMore() {
    return this.entries.loadMore();
  }

  @action
  sortEntries(field, direction) {
    const entriesController = this.entries;
    return entriesController.sortEntries(field, direction);
  }

  @action
  selectAllEntries() {
    return this.entries.selectAllEntries();
  }

  refreshLocationsCapacity(date) {
    const currentLocation = get(this.currentLocation, 'content');
    if (currentLocation.capacityLimitEnabled) {
      return fetchCapacity(this.store, currentLocation, date);
    }
  }

  linkToRoute(entry) {
    if (entry.signedInAt == null) {
      return 'workplace.scheduled';
    }
    return 'workplace.entry';
  }

  approveEntryTask = task(async (entry) => {
    try {
      await entry.approveEntry();
      const flow = await entry.flow;
      await flow.badge; // load async relationship for entryApprovalMessage helper dependency
      this.flashMessages.showAndHideFlash('success', 'Access approved', entryApprovalMessage(entry));

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'approve',
        entry_id: entry.id,
        source: 'Visitor Log',
        ...this.#sourcesForReviewMetrics(entry),
      });
      await entry.reload();
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', 'Error approving entry', parseErrorForDisplay(error));
    } finally {
      const currentLocation = this.currentLocation.content;
      if (currentLocation.capacityLimitEnabled) {
        await fetchCapacity(this.store, currentLocation, entry.signedInAt);
      }
    }
  });

  denyEntryTask = task(async (entry) => {
    try {
      await entry.denyEntry();
      this.flashMessages.showAndHideFlash('warning', 'Entry denied');
      await entry.reload();

      this.metrics.trackEvent('Dashboard Entry - Reviewed', {
        action: 'deny',
        entry_id: entry.id,
        source: 'Visitor Log',
        ...this.#sourcesForReviewMetrics(entry),
      });
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error denying entry');
    }
  });

  #sourcesForReviewMetrics(entry) {
    return (entry.approvalStatus?.failedReport || []).reduce(
      (sources, report) => ({ ...sources, [report.source]: true }),
      {},
    );
  }
}
