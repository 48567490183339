// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { dropTask, waitForProperty } from 'ember-concurrency';
import { get, set } from '@ember/object';
import { service } from '@ember/service';

export default class DashboardProductBoxSetupStepsStepComponent extends Component {
  @service router;
  @service flashMessages;
  @service metrics;

  transitionDone = false;

  /**
   * reviewStepAndTransitionTask
   * @param {LocationSetupGuideStep} locationSetupGuideStep
   * @param {Event} evt
   */
  @dropTask
  *reviewStepAndTransitionTask(locationSetupGuideStep, evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }

    if (this.onTransition) {
      yield this.onTransition(locationSetupGuideStep);
    } else {
      set(locationSetupGuideStep, 'reviewed', true);
    }

    try {
      yield locationSetupGuideStep.save();

      const { routeName, pageResourceId } = locationSetupGuideStep;
      let transitionObj = null;

      yield this._trackStep(evt.target);

      if (pageResourceId) {
        transitionObj = this.router.transitionTo(routeName, pageResourceId);
      } else {
        transitionObj = this.router.transitionTo(routeName);
      }
      // Keep the loading spinner until we destroy the component or we finish the transition
      transitionObj.then(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          set(this, 'transitionDone', true);
        }
      });
      yield waitForProperty(this, 'transitionDone');
    } catch (error) {
      /* eslint-disable no-console */
      console.log({ error });
      /* eslint-enable no-console */
      this.flashMessages.showFlash('error', "We're sorry, something went wrong.");
    }
  }

  _trackStep(target) {
    const product = target.closest('[data-product]').dataset.product;
    const step = get(this.locationSetupGuideStep, 'setupGuideStep.title');
    const total_steps = product === 'Deliveries' ? 2 : 3;
    const step_number = target.closest('[data-step-number]').dataset.stepNumber;
    return this.metrics.trackEvent('Setup Step Button Clicked', {
      product,
      step,
      button_text: 'Start →',
      total_steps,
      step_number,
    });
  }
}
