import type { AsyncHasMany } from '@ember-data/model';
import Component from '@glimmer/component';
import type AnnouncementChannelsModel from 'garaje/models/announcement-channel';
import type { AnnouncementChannelValue } from 'garaje/models/announcement-channel';
import type AnnouncementRecipientModel from 'garaje/models/announcement-recipient';
import type AnnouncementRecipientChannelModel from 'garaje/models/announcement-recipient-channel';

type AnnouncementRecipientListRowType = {
  channels: AsyncHasMany<AnnouncementChannelsModel>;
  recipient: AnnouncementRecipientModel;
};

export default class AnnouncementRecipientListRow extends Component<AnnouncementRecipientListRowType> {
  CHANNELS = [
    { key: 'email', label: 'Email', showKey: 'showEmail', sentKey: 'emailSent' },
    { key: 'sms', label: 'SMS', showKey: 'showSms', sentKey: 'smsSent' },
    { key: 'push', label: 'Push', showKey: 'showPush', sentKey: 'pushSent' },
    { key: 'slack', label: 'Slack', showKey: 'showSlack', sentKey: 'slackSent' },
    { key: 'ms_teams', label: 'Microsoft Teams', showKey: 'showMsTeams', sentKey: 'msTeamsSent' },
  ];

  get showEmail(): boolean {
    return this.showChannel('email');
  }

  get showSms(): boolean {
    return this.showChannel('sms');
  }

  get showPush(): boolean {
    return this.showChannel('push');
  }

  get showSlack(): boolean {
    return this.showChannel('slack');
  }

  get showMsTeams(): boolean {
    return this.showChannel('ms_teams');
  }

  private showChannel(channelName: AnnouncementChannelValue) {
    return this.args.channels.any((ac) => ac.channel === channelName);
  }

  getChannel = (key: string): AnnouncementRecipientChannelModel | undefined => {
    return this.args.recipient.announcementRecipientChannels.find((channel) => channel.channel === key);
  };
}
