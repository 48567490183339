import { belongsTo } from 'ember-data/relationships';
import AbstractSignInFieldActionsContactModel from 'garaje/models/abstract/abstract-sign-in-field-actions-contact';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-actions-contact')
class GlobalSignInFieldActionsContact extends AbstractSignInFieldActionsContactModel {
  @belongsTo('global-sign-in-field-action-rule-group', { async: true }) signInFieldActionRuleGroup;
}

export default GlobalSignInFieldActionsContact;
