import Component from '@glimmer/component';

/**
 * @class HeaderComponent
 * @param {string} backLink       optional - Internal link to navigate back to. If not present, no navBack icon shown
 * @param {string} title          required - The header title
 * @param {string} subtitle       optional - The header subtitle
 * @param {string} label          optional - The aria-label to set on the back link
 */
export default class HeaderComponent extends Component {
  get label() {
    return this.args.label || 'Back';
  }
}
