import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';

import type GlobalAnalyticsBenchmarkingIndexController from './controller';

export default class GlobalAnalyticsBenchmarkingIndexRoute extends Route {
  title = 'Benchmarking · Global Analytics · Envoy';

  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;

  setupController(controller: GlobalAnalyticsBenchmarkingIndexController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (this.globalOverviewAccess.canVisitBenchmarkingAnalytics) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  redirect(): void {
    if (!this.globalOverviewAccess.canVisitBenchmarkingAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }
}
