/* eslint-disable ember/no-get */
import type ArrayProxy from '@ember/array/proxy';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import type BlacklistContactUser from 'garaje/models/blacklist-contact-user';
import type BlacklistFilterModel from 'garaje/models/blacklist-filter';
import type User from 'garaje/models/user';
import type ZoneModel from 'garaje/models/zone';
import type BlocklistFilterService from 'garaje/services/blocklist-filter';
import { GLOBAL_ADMIN, ZONE_ADMIN } from 'garaje/utils/roles';
import { all } from 'rsvp';

interface SecurityComponentsConfigureBlocklistPropertyArgs {
  /**
   * function triggered when list of contacts is changed or saved. Received a single Boolean argument, indicating whether the list is changed & unsaved (true) or not (false).
   */
  onContactsChange?: (didChange: boolean) => unknown;
  /**
   * The property for which the blocklist is being configured
   */
  property: ZoneModel;
}

export default class SecurityComponentsConfigureBlocklistProperty extends Component<SecurityComponentsConfigureBlocklistPropertyArgs> {
  @service('blocklistFilter') declare blocklistFilterService: BlocklistFilterService;
  @service declare store: Store;

  blocklistContactUsers: BlacklistContactUser[] = [];

  @action
  async loadBlocklistContacts(): Promise<User[]> {
    const blocklistContactUsers = await this.store.query('blacklist-contact-user', {
      filter: {
        zone: this.args.property.id,
      },
      include: 'user',
    });

    // store local copy of fetched admins
    this.blocklistContactUsers = blocklistContactUsers.toArray();

    return all(blocklistContactUsers.mapBy('user'));
  }

  @action
  loadBlocklistFilters(): Promise<ArrayProxy<BlacklistFilterModel>> {
    return this.blocklistFilterService.queryByPropertyTask.perform(this.args.property.id);
  }

  /**
   * @param {User[]} newBlocklistUsers
   */
  @action
  async saveBlocklistContacts(newBlocklistUsers: ArrayProxy<User>): Promise<BlacklistContactUser[]> {
    const blocklistContactUsersToDelete = [];
    const newBlocklistContactUsersList = [];
    const existingUserIds: string[] = [];

    // first, go through the blocklist contacts we know about.
    // save the ones that still have a corresponding User in `newBlocklistUsers`
    // and remove the ones that don't (they should be deleted)
    const newBlocklistUserIds = newBlocklistUsers.mapBy('id');
    for (const blocklistContactUser of this.blocklistContactUsers) {
      const userId = <string>get(blocklistContactUser, 'user.id');
      existingUserIds.push(userId);
      if (newBlocklistUserIds.includes(userId)) {
        newBlocklistContactUsersList.push(blocklistContactUser);
      } else {
        blocklistContactUsersToDelete.push(blocklistContactUser);
      }
    }

    // now, create records for users listed in `newBlocklistUsers` that don't correspond
    // to any User in our saved list of BlacklistContactUsers
    const newUsers = newBlocklistUsers.filter((user) => !existingUserIds.includes(user.id));
    const newBlocklistContactUsers = newUsers.map((user) => {
      const blocklistContactUser = this.store.createRecord('blacklist-contact-user');
      // assign these relationships separately since ember-data doesn't reliably set them
      // if they're included in the `createRecord` call
      blocklistContactUser.contactConfigurable = this.args.property;
      blocklistContactUser.user = user;
      return blocklistContactUser;
    });
    newBlocklistContactUsersList.push(...newBlocklistContactUsers);
    this.blocklistContactUsers = newBlocklistContactUsersList;

    const addPromises = newBlocklistContactUsers.map((bcu) => bcu.save());
    const deletePromises = blocklistContactUsersToDelete.map((bcu) => bcu.destroyRecord());
    return all([...addPromises, ...deletePromises]);
  }

  @action
  async searchUsers(term: string): Promise<User[]> {
    const companyId = this.args.property.belongsTo('company').id();

    const userRoles = await this.store.query('user-role', {
      filter: {
        query: term,
        company: companyId,
        roles: [GLOBAL_ADMIN, ZONE_ADMIN].join(),
        zone: this.args.property.id,
        confirmedUsers: true,
      },
      include: 'user',
    });
    return all(userRoles.map((role) => role.user));
  }
}
