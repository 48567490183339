export enum Workbook {
  LOCATION_ATTENDANCE = 'workbook_XoUQVxANRBiXGB7XYKEjbA',
  LOCATION_DELIVERIES = 'workbook_Z0wdndldQImUSAwvw24XTw',
  LOCATION_DESKS = 'workbook_naAaJarUSJyoJPys2TPfAg',
  LOCATION_OCCUPANCY = 'workbook_6S1Rnq1kS3GY9t0GUHeXfg',
  LOCATION_ROOMS = 'workbook_ThtswdIgQDqgED3zjfslAA',
  LOCATION_STRATEGIC_INSIGHTS = 'workbook_IQCLbbbJTVG8BHerRowryg',
  LOCATION_VISITORS = 'workbook_beGmsjMATzaeLQhwBSxzMg',
  GLOBAL_ATTENDANCE = 'workbook_rkrNWgUlRym8pFWK7SOAqw',
  GLOBAL_DELIVERIES = 'workbook_hIVpuOrrTFWjNahwabcOog',
  GLOBAL_DESKS = 'workbook_9LVaHpk4Sca7k9dtFRHDFg',
  GLOBAL_OCCUPANCY = 'workbook_RY1QoLOTTTqWfS2sT4pLvQ',
  GLOBAL_ROOMS = 'workbook_XTwVShkzT6CBpLy6gy28kg',
  GLOBAL_VISITORS = 'workbook_Ejo9zQvFToGooajJwZxy6Q',
}
