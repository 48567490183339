import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { WorkbookScopeType } from 'garaje/utils/workbooks';

export default class LocationOverviewAnalyticsCustomDashboardsController extends Controller {
  queryParams = ['page'];
  scope = WorkbookScopeType.COMPANY;

  @tracked page = 1;
}
