import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { singularize } from 'ember-inflector';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import { type ScimCategory } from 'garaje/models/neighborhood';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import toOxfordComma from 'garaje/utils/to-oxford-comma';

interface NeighborhoodFormArgs {
  neighborhoodId: number;
  onClose: () => void;
}

type ScimCategoryWithSelectedLabel = ScimCategory & { selectedLabel: string };

type DirectorySyncProvider = {
  name: string | undefined;
  provider: string | undefined;
};

export default class NeighborhoodForm extends Component<NeighborhoodFormArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare metrics: MetricsService;

  @tracked showColorPicker = false;
  @tracked selectedColor = this.neighborhood.displayColor;

  get neighborhood(): NeighborhoodModel {
    return this.store.peekRecord('neighborhood', this.args.neighborhoodId) as NeighborhoodModel;
  }

  get backgroundColor(): string {
    return 'background-color: ' + this.neighborhood.displayColor;
  }

  get sittingHereText(): string {
    const { employees } = this.neighborhood;
    if (isEmpty(employees) && isEmpty(this.selectedCategories)) {
      return 'Anyone can sit here';
    }
    const sitting = [];
    if (isPresent(this.selectedCategories)) {
      sitting.push(...this.selectedCategories.map(({ selectedLabel }) => selectedLabel));
    }
    if (isPresent(employees)) {
      sitting.push(...employees.map(({ name }) => name));
    }
    return 'Only ' + toOxfordComma(sitting) + ' can sit here';
  }

  get providerName(): string | undefined {
    const { currentCompany } = this.state;

    const provider = currentCompany.directorySyncProvider as unknown as DirectorySyncProvider;
    if (!provider) {
      return provider;
    } else {
      return provider.name || provider.provider;
    }
  }

  get selectedCategories(): ScimCategoryWithSelectedLabel[] {
    const { scimCategories } = this.neighborhood;
    const categories = scimCategories.map((category) => {
      const selectedLabel = `${category.name} (${this.providerName} ${singularize(
        category.categoryName,
      ).toLowerCase()})`;
      return { ...category, selectedLabel };
    });
    return categories;
  }

  @action
  hideOnClickOutside(selector: string): void {
    const outsideClickListener = (event: MouseEvent & { target: Element }) => {
      const target = event.target;
      if (!target) {
        removeClickListener();
      } else if (target.closest(selector) === null) {
        this.showColorPicker = false;
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener as (event: MouseEvent) => void);
    };

    if (this.showColorPicker) {
      this.showColorPicker = false;
      removeClickListener();
    } else {
      this.metrics.trackEvent('Neighborhood color picker opened');
      this.showColorPicker = true;
      // Delay adding the outside click listener to avoid triggering it on the initial toggle click
      setTimeout(() => {
        document.addEventListener('click', outsideClickListener as (event: MouseEvent) => void);
      }, 10);
    }
  }

  @action
  onColorSelect(color: string): void {
    set(this.neighborhood, 'displayColor', color);
  }

  @action
  getFloorName(floorId: number): string | undefined {
    if (!floorId) {
      return '';
    }
    const floor = this.store.peekRecord('map-floor', floorId);
    return floor?.name;
  }
}
