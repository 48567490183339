import { action, set } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type { Desk as GqlDesk } from 'garaje/graphql/generated/assigned-and-unassigned-employees-types';
import type AmenityModel from 'garaje/models/amenity';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { DESK_ATTRIBUTES_TO_UPDATE_RESOURCE_OVERVIEW } from 'garaje/utils/enums';
import { defer } from 'rsvp';

interface BulkEditFormArgs {
  neighborhoods: NeighborhoodModel[];
  amenities: AmenityModel[];
  selectedFeatures: MapFeatureModel[];
  mapFloor: MapFloorModel;
  setSelectedFeatures: (features: MapFeatureModel[]) => void;
  setNeighborhoodToView: (neighborhoodId: string | undefined) => void;
  onClose: () => void;
  updateResourceOverview: (action: string, feature: MapFeatureModel) => void;
  activeDesksCount: number;
  maximumDesksForCurrentPlan: number;
  updateActiveDesksCount: (count: number) => void;
  removeFeature: (feature: MapFeatureModel) => void;
  getAssignedDesksForEmployee: (employeeId: string) => GqlDesk[];
  updateEmployeeOverview: (employeeId: string, assignedDesks: GqlDesk[]) => void;
  deleteSelectedDesks: boolean;
}

export default class BulkEditForm extends Component<BulkEditFormArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  @tracked selectedNeighborhood: NeighborhoodModel[] = [];
  @tracked showEnableOptions = false;

  get cannotEditMap(): boolean {
    return this.abilities.cannot('edit maps');
  }

  get cannotManageDesks(): boolean {
    return this.abilities.cannot('manage-desk desks');
  }

  get selectedAssignedDesks(): DeskModel[] {
    return this.selectedDesks.filter((desk) => {
      return desk?.assignedTo;
    }) as DeskModel[];
  }

  get selectedAssignedEmails(): string[] {
    return this.selectedDesks.reduce((acc: string[], desk) => {
      if (desk?.assignedTo && !acc.includes(desk.assignedTo)) {
        acc.push(desk.assignedTo);
      } else if (!desk?.assignedTo) {
        acc.push('');
      }
      return acc;
    }, []);
  }

  get assignedEmployeePlaceholder(): string {
    if (this.selectedAssignedEmails.length === 1 && this.selectedAssignedDesks.length) {
      const employee = this.store
        .peekAll('employee')
        .find((employee) => employee.email === this.selectedAssignedDesks[0]!.assignedTo);
      if (employee) {
        return employee.name;
      }
    }
    return this.selectedAssignedDesks.length ? 'Multiple' : 'No employees assigned';
  }

  get selectedDesks(): Array<DeskModel | null> {
    return this.args.selectedFeatures.map((feature) => {
      return feature.desk || this.store.peekRecord('desk', feature.externalId);
    });
  }

  get selectedNeighborhoods(): Array<NeighborhoodModel | null> {
    const allSelectedNeighborhoods = this.selectedDesks.reduce((acc: Array<NeighborhoodModel | null>, desk) => {
      const neighborhoodId = desk?.belongsTo('neighborhood').id();
      if (neighborhoodId) {
        const neighborhood = this.store.peekRecord('neighborhood', neighborhoodId);
        if (neighborhood && !acc.includes(neighborhood)) {
          acc.push(neighborhood);
        }
      } else if (!acc.includes(null)) {
        acc.push(null);
      }
      return acc;
    }, []);
    return allSelectedNeighborhoods;
  }

  // get createNeighborhoodValue(): string {
  //   const isValidNeighborhood = this.findValidNeighborhood(this.neighborhoodSearchValue);
  //   return isValidNeighborhood ? '' : this.neighborhoodSearchValue;
  // }

  @action
  unselectFeature(feature: MapFeatureModel): void {
    const { selectedFeatures, setSelectedFeatures } = this.args;
    this.metrics.trackEvent('Unselected desk from bulk edit form');
    const newSelectedFeatures = selectedFeatures.filter((selectedFeature) => selectedFeature !== feature);
    setSelectedFeatures(newSelectedFeatures);
  }

  @action
  unassignDesks(): void {
    this.metrics.trackEvent('Unassigned desks from bulk edit form', { numberOfDesks: this.selectedDesks.length });
    this.selectedDesks.forEach((desk) => {
      const feature = this.args.selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);
      this.editDesk(desk!, 'assignedTo', null, feature!);
    });
  }

  @action
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  editDesk(desk: DeskModel, attr: keyof DeskModel, value: any, feature: MapFeatureModel | null): void {
    const { updateResourceOverview } = this.args;
    if (value === '') {
      set(desk, attr, null);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      set(desk, attr, value);
    }
    if (updateResourceOverview) {
      if (DESK_ATTRIBUTES_TO_UPDATE_RESOURCE_OVERVIEW.includes(attr) && feature) {
        updateResourceOverview('update', feature);
      }
    }
  }

  updateNeighborhoodDeskCount(neighborhood: NeighborhoodModel, count: number): void {
    set(neighborhood, 'numberOfDesks', neighborhood.numberOfDesks + count);
    const currentFloorId = this.args.mapFloor.id;
    if (neighborhood?.deskCountByFloor) {
      set(neighborhood, 'deskCount', neighborhood.deskCount + count);
      let floor = neighborhood.deskCountByFloor.find((floor) => floor['floor-id'] == currentFloorId);
      if (!floor) {
        floor = {
          'floor-id': currentFloorId,
          'desk-count': 0,
        };
      }
      const newDeskCountByFloor = neighborhood.deskCountByFloor.filter((floor) => floor['floor-id'] != currentFloorId);
      floor['desk-count'] += count;
      set(neighborhood, 'deskCountByFloor', [...newDeskCountByFloor, floor]);
    }
  }

  @action
  setSelectedNeighborhood(neighborhood: NeighborhoodModel[]): void {
    if (neighborhood.length === 0) {
      this.metrics.trackEvent('Unassigned neighborhood from bulk edit form');
    } else {
      this.metrics.trackEvent('Assigned neighborhood from bulk edit form', { neighborhood: neighborhood[0]!.name });
    }

    this.selectedNeighborhood = neighborhood;
    this.selectedDesks.forEach((desk) => {
      const feature = this.args.selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);
      const prevNeighborhoodId = desk?.belongsTo('neighborhood').id();
      if (prevNeighborhoodId) {
        const prevNeighborhood = this.store.peekRecord('neighborhood', prevNeighborhoodId);
        this.updateNeighborhoodDeskCount(prevNeighborhood!, -1);
      }
      this.editDesk(desk!, 'neighborhood', neighborhood[0], feature!);
      if (neighborhood[0]) {
        this.updateNeighborhoodDeskCount(neighborhood[0], 1);
      }
    });
  }

  @action
  searchNeighborhoods(searchTerm: string): NeighborhoodModel[] {
    return this.args.neighborhoods.filter((neighborhood) =>
      neighborhood.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  get selectedAmenities(): AmenityModel[] {
    return Object.keys(this.desksWithAmenity).reduce((acc: AmenityModel[], id: string) => {
      if (this.desksWithAmenity[id] === this.selectedDesks.length) {
        const amenity = this.store.peekRecord('amenity', id);
        acc.push(amenity!);
      }
      return acc;
    }, []);
  }

  get desksWithAmenity(): { [key: string]: number } {
    return this.selectedDesks.reduce((acc: { [key: string]: number }, desk) => {
      const deskAmenityIds = desk?.hasMany('amenities').ids();
      deskAmenityIds?.forEach((id: string) => {
        acc[id] = (acc[id] || 0) + 1;
      });
      return acc;
    }, {});
  }

  get hasIndeterminateAmenity(): boolean {
    return Object.values(this.desksWithAmenity).some((count) => count > 0 && count < this.selectedDesks.length);
  }

  @action
  removeAmenity(amenity: AmenityModel): void {
    this.metrics.trackEvent('Removed amenity from bulk edit form trigger', { amenity: amenity.name });
    const newSelectedAmenities = this.selectedAmenities.filter((selectedAmenity) => selectedAmenity !== amenity);
    this.setSelectedAmenities(newSelectedAmenities);
  }

  @action
  setSelectedAmenities(amenities: AmenityModel[]): void {
    const amenitiesToRemove = this.selectedAmenities.filter((amenity) => !amenities.includes(amenity));

    if (amenitiesToRemove.length) {
      this.metrics.trackEvent('Removed amenities from bulk edit form dropdown', {
        numberOfAmenities: amenitiesToRemove.length,
      });
    }

    let updatedDesks = 0;
    // Update all selected desks
    this.selectedDesks.forEach((desk) => {
      const feature = this.args.selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);

      const deskAmenityIds = desk?.hasMany('amenities').ids();
      let deskAmenities = deskAmenityIds?.map((id: string) => {
        return this.store.peekRecord('amenity', id);
      });

      let didUpdateDesk = false;
      amenities.forEach((amenity) => {
        if (!deskAmenityIds?.includes(amenity.id)) {
          deskAmenities?.push(amenity);
          if (!didUpdateDesk) {
            updatedDesks += 1;
            didUpdateDesk = true;
          }
        }
      });

      deskAmenities = deskAmenities?.filter((amenity) => !amenitiesToRemove.includes(amenity!));
      this.editDesk(desk!, 'amenities', deskAmenities, feature!);
    });

    this.metrics.trackEvent('Added amenities from bulk edit form', { numberOfDesks: updatedDesks });
  }

  @action
  searchAmenities(searchTerm: string): AmenityModel[] {
    return this.args.amenities.filter((amenity) => amenity.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  @action
  onNeighborhoodViewClick(): void {
    const neighborhoodId = this.selectedNeighborhoods[0]?.id;
    this.metrics.trackEvent('Viewed neighborhood from bulk edit form', { neighborhoodId });
    this.args.setNeighborhoodToView(neighborhoodId);
  }

  @action
  hideOnClickOutside(selector: string): void {
    const outsideClickListener = (
      event: MouseEvent & { target: { id?: string; closest: (selector: string) => HTMLElement } },
    ) => {
      const target = event.target;
      if (!target) {
        removeClickListener();
      } else if (target.closest(selector) === null) {
        this.showEnableOptions = false;
        removeClickListener();
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener as (event: MouseEvent) => void);
    };
    document.addEventListener('click', outsideClickListener as (event: MouseEvent) => void);
  }

  @action
  openEnableMenu(): void {
    this.showEnableOptions = !this.showEnableOptions;
    this.hideOnClickOutside('#menu-container');
  }

  @action
  deactivateDesks(): void {
    this.metrics.trackEvent('Deactivated desks from bulk edit form', { numberOfDesks: this.selectedDesks.length });
    this.showEnableOptions = false;
    const { selectedFeatures, updateActiveDesksCount } = this.args;
    this.selectedDesks.forEach((desk) => {
      const feature = selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);
      updateActiveDesksCount(-1);
      this.editDesk(desk!, 'enabled', false, feature!);
    });
  }

  @action
  activateDesks(): void {
    this.metrics.trackEvent('Activated desks from bulk edit form', { numberOfDesks: this.selectedDesks.length });
    const { activeDesksCount, selectedFeatures, updateActiveDesksCount, maximumDesksForCurrentPlan } = this.args;
    this.showEnableOptions = false;
    if (activeDesksCount + selectedFeatures.length > maximumDesksForCurrentPlan) {
      this.showActiveDesksLimitModalTask.perform();
      return;
    }
    this.selectedDesks.forEach((desk) => {
      const feature = this.args.selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);
      updateActiveDesksCount(1);
      this.editDesk(desk!, 'enabled', true, feature!);
    });
  }

  @dropTask
  showActiveDesksLimitModalTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: BulkEditForm; abort: () => void; continue: () => void }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.router.transitionTo('billing.product-plans.upgrade', ['desks', 'standard']);
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @action
  onDeleteDesk(desk: DeskModel, feature: MapFeatureModel): void {
    const { removeFeature, updateActiveDesksCount, updateEmployeeOverview, getAssignedDesksForEmployee } = this.args;
    if (desk.enabled) {
      updateActiveDesksCount(-1);
    }
    const employee = this.store.peekAll('employee').find((employee) => employee.email === desk.assignedTo);

    if (employee) {
      const remainingAssignedDesksForEmployee = getAssignedDesksForEmployee(employee.id)?.filter(
        (it) => parseInt(it.id) !== parseInt(desk.id),
      );
      updateEmployeeOverview(employee.id, remainingAssignedDesksForEmployee);
    }
    desk.deleteRecord();
    removeFeature(feature);
  }

  @action
  deleteSelectedDesks(): void {
    if (this.args.deleteSelectedDesks) {
      this.showDeleteDeskModalTask.perform();
    }
  }

  @action
  deleteDesks(): void {
    this.metrics.trackEvent('Deleted desks from bulk edit form', { numberOfDesks: this.selectedDesks.length });
    this.selectedDesks.forEach((desk) => {
      const feature = this.args.selectedFeatures.find((f) => f.externalId === desk?.id || f.desk === desk);
      this.onDeleteDesk(desk!, feature!);
    });
  }

  @dropTask
  showDeleteDeskModalTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: BulkEditForm; abort: () => void; continue: () => void }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        this.context.deleteDesks();
        this.context.args.onClose();
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  // @action
  // onCreateNeighborhood(neighborhood): void {
  //   const { neighborhoods } = this.args;
  // update this to handle bulk changes
  // const prevNeighborhoodId = this.selectedDeskResource.belongsTo('neighborhood').id();
  // if (prevNeighborhoodId) {
  //   const prevNeighborhood = this.store.peekRecord('neighborhood', prevNeighborhoodId);
  //   this.updateNeighborhoodDeskCount(prevNeighborhood, -1);
  // }
  // this.editDesk(this.selectedDeskResource, 'neighborhood', neighborhood);
  // set(neighborhood, 'numberOfDesks', 1);
  // set(neighborhood, 'deskCount', 1);
  // set(neighborhood, 'deskCountByFloor', [
  //   { 'floor-id': this.selectedDeskResource.belongsTo('floor').id(), 'desk-count': 1 },
  // ]);
  // neighborhoods.pushObject(neighborhood);
  // this.clearNeighborhoodErrors();
  // }

  @dropTask
  showCreateNeighborhoodModalTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: BulkEditForm; abort: () => void; continue: () => void }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}
