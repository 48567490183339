import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type ZoneModel from 'garaje/models/zone';

export default class ConnectPropertyConfigurationModel extends Model {
  @attr('boolean') declare inviteApprovalsEnabled: boolean;
  @attr('boolean') declare autoCheckInOnApprovalEnabled: boolean;
  @attr('boolean') declare tenantInviteAcsCredentialingEnabled: boolean;

  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-property-configuration': ConnectPropertyConfigurationModel;
  }
}
