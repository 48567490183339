import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FlowModel from 'garaje/models/flow';
import type { TransientRecord } from 'jsonapi/response';

export default class extends Component {
  @tracked isMultiLocationSelectModalOpen = false;
  @tracked currentGlobalFlowObj?: TransientRecord<FlowModel> = undefined;

  @action
  onClickEditLocations(globalFlowObj: TransientRecord<FlowModel>): void {
    this.isMultiLocationSelectModalOpen = !this.isMultiLocationSelectModalOpen;
    this.currentGlobalFlowObj = globalFlowObj;
  }

  @action
  closeModal(): void {
    this.isMultiLocationSelectModalOpen = false;
    this.currentGlobalFlowObj = undefined;
  }
}
