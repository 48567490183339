import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type { SinglePayload } from 'jsonapi/response';

export type UploadStatusPayload = SinglePayload<{ uploadStatus: string; importedCount: number; message: string }>;

class CsvUploadModel extends Model {
  @belongsTo('csv-uploadable', { async: true, polymorphic: true }) declare csvUploadable: AsyncBelongsTo<Model> | Model;

  @attr('string') declare destination: string;

  async uploadStatus(): Promise<UploadStatusPayload> {
    return await (<Promise<UploadStatusPayload>>apiAction(this, { method: 'GET', path: 'upload-status' }));
  }
}

export default CsvUploadModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'csv-upload': CsvUploadModel;
  }
}
