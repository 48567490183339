import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import { localCopy } from 'tracked-toolbox';

interface LocalityFieldComponentSignature {
  Args: {
    changeset: DetailedChangeset<SignInFieldModel>;
    update: (changeset: DetailedChangeset<SignInFieldModel>) => void;
    locality: { placeId: string };
    updateLocality: (locality: { placeId: string }) => void;
    disabled: boolean;
  };
}

export default class LocalityFieldComponent extends Component<LocalityFieldComponentSignature> {
  @localCopy('args.disabled', false) disabled!: boolean;

  @action
  didChangeName(name: string): void {
    this.args.changeset.value = name;
    this.args.update(this.args.changeset);
  }

  @action
  didChangePlaceId(placeId: string): void {
    this.args.updateLocality({ placeId });
  }
}
