import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

import type Room from './room';

@adapter('rms-rooms-v2')
@serializer('rooms')
class RoomDevice extends Model {
  @belongsTo('room', { async: true })
  declare room: AsyncBelongsTo<Room>;

  @attr('boolean')
  declare isOnline?: boolean;

  @attr('string')
  declare status?: string;

  @attr('number')
  declare batteryLevel?: number;

  @attr('string')
  declare chargingStatus?: string;

  @attr('number')
  declare deviceVolume?: number;

  @attr('string')
  declare deviceJackStatus?: string;

  @attr('boolean')
  declare lightbarDetected?: boolean;

  @attr('string', { defaultValue: '' })
  declare ledAudioType: string;
}

export default RoomDevice;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'room-device': RoomDevice;
  }
}
