import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Promise } from 'rsvp';
import { tracked } from '@glimmer/tracking';

export default class InlineInputEditorComponent extends Component {
  @tracked isEditing = false;

  @action
  onChange(e) {
    // blur on success
    Promise.resolve(this.args.onTextChanged(e.target.value)).then(() => {
      return e.target.blur();
    });
  }
}
