import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import { task, type Task } from 'ember-concurrency';
import type { Report } from 'garaje/models/approval-status';
import type Invite from 'garaje/models/invite';
import type User from 'garaje/models/user';
import type MetricsService from 'garaje/services/metrics';
import { BlocklistFilterSource } from 'garaje/utils/enums';

const APPROVAL_REQUIRED_COPY = {
  location: 'This invite requires approval by a property admin.',
  property: 'This invite requires approval by a tenant admin.',
};

type Context = 'location' | 'property';

interface InviteFeedItemFlaggedSignature {
  Args: {
    /**
     * ember-concurrency task to be performed when approving an invite. Receives the invite as its argument.
     */
    approveInviteTask: Task<void, [Invite]>;
    /**
     * List of users who are set as contacts for blocklist feature, so their names can be displayed.
     */
    blocklistContacts: User[];
    /**
     * The context in which the component is being used ("location" or "property"). Defaults to "location".
     */
    context?: Context;
    /**
     * ember-concurrency task to be performed when denying an invite. Receives the invite as its argument.
     */
    denyInviteTask: Task<void, [Invite]>;
    /**
     * List of fields which are being shown. This is used to calculate the correct `colspan` to match the
     * width of non-flagged invite rows in a table.
     */
    fields: Array<{ show: boolean }>;
    invite: Invite;
  };
}

export default class InviteFeedItemFlagged extends Component<InviteFeedItemFlaggedSignature> {
  @service declare abilities: AbilitiesService;
  @service declare metrics: MetricsService;

  get canReview(): boolean {
    return this.abilities.can('review entry-approval', {
      context: this.context,
      report: this.report,
    });
  }

  get canReviewOrReadBlocklistMatch(): boolean {
    return this.abilities.can('review or read blocklist match in security', {
      context: this.context,
      didFailBlocklistCheck: this.args.invite.approvalStatus?.didFailBlocklistCheck,
      report: this.report,
    });
  }

  get context(): Context {
    return this.args.context ?? 'location';
  }

  get report(): Report[] {
    return this.args.invite.approvalStatus?.failedReport ?? [];
  }

  get hasPropertyReport(): boolean {
    return this.report.some((report) => report.blacklistFilterSource === BlocklistFilterSource.PROPERTY);
  }

  get showDetails(): boolean {
    return this.context === 'location';
  }

  // Show an "Edited" pill when the invite was edited in such a way as to trigger rescreening.
  // This can be done by, for example, changing the Visitor Type. This status is communicated from
  // the backend by the `employeeTriggeredRescreen` property of the invite's `approvalStatus`.
  get showEditedIndication(): boolean {
    return Boolean(this.args.invite.approvalStatus?.employeeTriggeredRescreen);
  }

  get additionalApprovalRequiredCopy(): string {
    // Default copy
    let copy = this.args.invite.preregistrationComplete
      ? 'This invite requires approval.'
      : 'This invite requires approval to send.';

    // If there is a failed property specific report then we need to specify whose approval is required.
    if (this.hasPropertyReport) {
      copy = APPROVAL_REQUIRED_COPY[this.context];
    }

    return copy;
  }

  get colspan(): number {
    // colspan is equal the the number of shown fields
    // only because we have to add 1 for the kebab menu cell,
    // but subtract 1 for the name field, which is already in the row and is not included in the colspan
    return (this.args.fields ?? []).filter((field) => field.show).length;
  }

  get contactedNames(): string[] {
    if (this.args.invite.approvalStatus?.didFailBlocklistCheck && this.args.blocklistContacts) {
      return this.args.blocklistContacts.map((contact) => contact.fullName);
    }
    return [];
  }

  get sourcesForApprovalReviewMetrics(): Record<string, boolean> {
    return this.report.reduce((sources, report) => ({ ...sources, [report.source]: true }), {});
  }

  approveInviteTask = task(
    waitFor(async (invite: Invite) => {
      // This component-local version of the task exists so we can get instance-specific state.
      // The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
      // executes; otherwise, once the approved invite was reloaded (either with an `invite.reload()`
      // call or by the pubnub subscription that gets notified of changes to invites and pushes them
      // into the store out-of-band), this component gets torn down and the task is aborted before,
      // e.g., the flash message is shown. By keeping that logic out of this component, we don't
      // have to worry about that.
      // The instance-local state means that we can disable this invite's Approve/Deny buttons when
      // approveInviteTask or denyInviteTask is running without having to track state ourselves at
      // the higher level, or ended up disabling the buttons for _all_ invites instead of this one.
      await this.args.approveInviteTask.unlinked().perform(invite);
    }),
  );

  denyInviteTask = task(
    waitFor(async (invite: Invite) => {
      // This component-local version of the task exists so we can get instance-specific state.
      // The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
      // executes; otherwise, once the denied invite was reloaded (either with an `invite.reload()`
      // call or by the pubnub subscription that gets notified of changes to invites and pushes them
      // into the store out-of-band), this component gets torn down and the task is aborted before,
      // e.g., the flash message is shown. By keeping that logic out of this component, we don't
      // have to worry about that.
      // The instance-local state means that we can disable this invite's Approve/Deny buttons when
      // approveInviteTask or denyInviteTask is running without having to track state ourselves at
      // the higher level, or ended up disabling the buttons for _all_ invites instead of this one.
      await this.args.denyInviteTask.unlinked().perform(invite);
    }),
  );

  @action
  trackClickInviteDetail(invite_id: string): void {
    this.metrics.trackEvent('Invite Details Clicked', { invite_id });
  }
}
