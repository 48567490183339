/* eslint-disable ember/no-computed-properties-in-native-classes */
import type NativeArray from '@ember/array/-private/native-array';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AgreementPageModel from 'garaje/models/agreement-page';
import type BadgeModel from 'garaje/models/badge';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type IdScanPageModel from 'garaje/models/id-scan-page';
import type LocationModel from 'garaje/models/location';
import type PhotoPageModel from 'garaje/models/photo-page';
import PropagableModel from 'garaje/models/propagable';
import type SignInFieldActionRuleGroupModel from 'garaje/models/sign-in-field-action-rule-group';
import type SignInFieldPageModel from 'garaje/models/sign-in-field-page';
import type SummaryPageModel from 'garaje/models/summary-page';
import type UserModel from 'garaje/models/user';
import type UserDocumentTemplateConfiguration from 'garaje/models/user-document-template-configuration';
import type VisualComplianceConfigurationModel from 'garaje/models/visual-compliance-configuration';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { FlowType, STORE_RESPONSE_CONFIG_OPTIONS } from 'garaje/utils/enums';
import { equal } from 'macro-decorators';

import type SkinnyLocationModel from './skinny-location';

const FLOW = Object.freeze({
  customTranslations: () => ({ name: {} }),
  OVERWRITABLE_SETTINGS: {
    name: 'Name',
    enabled: 'Enable visitor type',
    additionalGuests: 'Plus one sign-in',
    useExistingPhoto: 'use existing photos',
  },
  PROPAGATED_RELOADABLE_RELATIONS: {
    belongsTo: ['agreementPage', 'summaryPage', 'photoPage'],
    hasMany: [],
  },
});

const ONBOARDING_CHECKS = {
  taskName: 'loadFlowsTask',
  attributes: ['name'],
};

class FlowModel extends PropagableModel {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: Store;

  @attr('string', { defaultValue: '' }) declare headerText: string;
  @attr('boolean', { defaultValue: false }) declare enabled: boolean;
  @attr('boolean', { defaultValue: true }) declare useDefaultHeaderText: boolean;
  @attr('string', { defaultValue: STORE_RESPONSE_CONFIG_OPTIONS.STORED }) declare storeResponseConfig: string;

  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  /**
   * used to link flows to included locations as they are loaded in as skinny-locations
   */
  @belongsTo('skinny-location', { async: false, inverse: 'flows' }) declare skinnyLocation: SkinnyLocationModel;

  @hasMany('skinny-location', { async: true, inverse: null }) declare locations: AsyncHasMany<LocationModel>; // For Global Flows?
  @belongsTo('summary-page', { async: true }) declare summaryPage: AsyncBelongsTo<SummaryPageModel>;
  @belongsTo('agreement-page', { async: true }) declare agreementPage: AsyncBelongsTo<AgreementPageModel>;
  @belongsTo('sign-in-field-page', { async: true }) declare signInFieldPage: AsyncBelongsTo<SignInFieldPageModel>;
  @hasMany('sign-in-field-action-rule-group', { async: true })
  declare signInFieldActionRuleGroups: AsyncHasMany<SignInFieldActionRuleGroupModel>;
  @belongsTo('badge', { async: true }) declare badge: AsyncBelongsTo<BadgeModel>;
  @belongsTo('photo-page', { async: true }) declare photoPage: AsyncBelongsTo<PhotoPageModel>;
  @belongsTo('id-scan-page', { async: true }) declare idScanPage: AsyncBelongsTo<IdScanPageModel>;
  @belongsTo('visual-compliance-configuration', { async: true })
  declare visualComplianceConfiguration: AsyncBelongsTo<VisualComplianceConfigurationModel>;
  @belongsTo('global-flow', { async: true }) declare globalFlow: AsyncBelongsTo<GlobalFlowModel>;

  @hasMany('user-document-template-configuration', { async: true, inverse: null })
  declare userDocumentTemplateConfigurations: AsyncHasMany<UserDocumentTemplateConfiguration>;
  @hasMany('user-document-template-configuration', { async: true, inverse: null })
  declare activeUserDocumentTemplateConfigurations: NativeArray<UserDocumentTemplateConfiguration>;
  @hasMany('user', { async: true }) declare visitorDocumentContacts: AsyncHasMany<UserModel>;

  @hasMany('user', { async: true, inverse: null }) declare inviteApprovalContacts: AsyncHasMany<UserModel>;

  // START - removed from base class
  @attr('string') declare type: FlowType;
  @attr('number') declare position: number;
  @attr('number') declare blueprint: number | null;
  @attr('string', { defaultValue: '' }) declare description: string;
  @attr('boolean', { defaultValue: false }) declare idCheck: boolean;
  @attr('boolean', { defaultValue: false }) declare employeeCentric: boolean;
  @attr('immutable', { defaultValue: FLOW.customTranslations }) declare customTranslations: Record<string, unknown>;
  // END - removed from base class

  @attr('boolean', { defaultValue: false }) declare additionalGuests: boolean;
  @attr('boolean', { defaultValue: false }) declare inviteApprovalsEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare useExistingPhoto: boolean;
  @attr('boolean', { defaultValue: false }) declare visitorSurveyEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare visualComplianceEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare propertyInviteApprovalsEnabled: boolean;

  @attr('string', { defaultValue: 'all_visitors' }) declare welcomeEmailPreference: string;

  @equal('type', FlowType.EMPLOYEE_SCREENING) isProtect!: boolean;

  isGlobal = false;

  @computed('type')
  get isGlobalChild(): boolean {
    const globalTypes: string[] = [FlowType.GLOBAL_CHILD];
    return globalTypes.includes(this.type);
  }

  get isWalkup(): boolean {
    return this.type === FlowType.PROPERTY_WALKUP;
  }

  get canEdit(): boolean {
    if (!this.type) {
      return this.abilities.can('edit visitor-types');
    } else if (this.type === FlowType.GLOBAL_CHILD) {
      return false;
    } else {
      return this.abilities.can('edit global-flows');
    }
  }

  invitesTemplateUrl(): string {
    const path = 'csv-template';
    const adapter = this.store.adapterFor('flow');
    // Temporary workaround to switch the api version from v3 to v2 until the csv template
    // api catches up
    const url = adapter.buildURL('flow', this.id).replace('v3', 'v2');
    return `${url}/${path}`;
  }

  hasDirtyWelcomeEmailPreference(): boolean {
    const changedAttrs = this.changedAttributes();
    return Object.keys(changedAttrs).includes('welcomeEmailPreference');
  }

  syncActiveUserDocumentTemplateConfigurations(): void {
    this.activeUserDocumentTemplateConfigurations = this.userDocumentTemplateConfigurations.filterBy('active');
  }

  static PROPAGATED_RELOADABLE_RELATIONS = FLOW.PROPAGATED_RELOADABLE_RELATIONS;
  static OVERWRITABLE_SETTINGS = FLOW.OVERWRITABLE_SETTINGS;
  static ONBOARDING_CHECKS = ONBOARDING_CHECKS;
}

export default FlowModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    flow: FlowModel;
  }
}
