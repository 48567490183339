import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { task } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';

import ImporterService from './importer';
import type StateService from './state';

interface Data {
  id: string;
  physicalDescription: string;
  fullName: string;
  reasonForBlocking: string;
  externalId: string;
  imageUrl: string;
  aliases: string;
  emailAddresses: string;
  keywords: string;
  phoneNumbers: string;
}

interface Props {
  id: string;
  fullName: string;
  aliases: string | string[];
  emails: string | string[];
  phoneNumbers: string | string[];
  description: string;
  location: LocationModel;
  otherKeywords: string | string[];
  reason: string;
  imageUrl: string;
  externalId: string;
}

export default class BlocklistFilterImporterService extends ImporterService {
  @service declare state: StateService;

  handleImport = task(async (data: Data) => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const props = await this._parseProps(data);
    if (
      isPresent(props.reason) &&
      (isPresent(props.fullName) ||
        isPresent(props.aliases) ||
        isPresent(props.emails) ||
        isPresent(props.phoneNumbers) ||
        isPresent(props.otherKeywords))
    ) {
      return props;
    }
    return null;
  });

  _parseProps(data: Data): Props {
    const location = this.state.currentLocation;
    const { id, physicalDescription, fullName, reasonForBlocking, externalId, imageUrl } = data;

    const aliases = this.convertDelimitedStringToArray(data.aliases);
    const emails = this.convertDelimitedStringToArray(data.emailAddresses);
    const otherKeywords = this.convertDelimitedStringToArray(data.keywords);
    const phoneNumbers = this.convertDelimitedStringToArray(data.phoneNumbers);

    return {
      id,
      fullName,
      aliases,
      emails,
      phoneNumbers,
      description: physicalDescription,
      location,
      otherKeywords,
      reason: reasonForBlocking,
      imageUrl,
      externalId,
    };
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    blocklistFilterImporter: BlocklistFilterImporterService;
  }
}
