import Model, { attr, belongsTo } from '@ember-data/model';
import config from 'garaje/config/environment';

class ProtectAccessCode extends Model {
  @attr('boolean') allowNonEmployees;
  @belongsTo('location', { async: true }) location;

  get registrationUrl() {
    return `${config.inviteSignHost}/protect/${this.id}`;
  }
}

export default ProtectAccessCode;
