import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SecurityDeskRoute extends Route {
  @service abilities;
  @service currentLocation;
  @service router;

  title = 'Security Desk';

  beforeModel() {
    if (this.abilities.cannot('visit security-desk')) {
      this.router.transitionTo('unauthorized');
    }
  }
}
