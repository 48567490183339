import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';
import {
  DELIVERIES_PAUSED_ALERT_TITLE,
  DELIVERIES_PAUSED_ALERT_MESSAGE,
  DELIVERIES_PAUSED_ALERT_MESSAGE_FOR_NON_ADMIN,
} from 'garaje/models/delivery-limit';

export default class DeliveriesController extends Controller {
  @service metrics;
  @service state;

  @alias('state.realDeliveriesSubscription') realDeliveriesSubscription;

  deliveriesPausedAlertTitle = DELIVERIES_PAUSED_ALERT_TITLE;
  deliveriesPausedAlertMessage = DELIVERIES_PAUSED_ALERT_MESSAGE;
  deliveriesPausedAlertMessageForNonAdmin = DELIVERIES_PAUSED_ALERT_MESSAGE_FOR_NON_ADMIN;

  get showTrialExpired() {
    const { deliveriesSubscription } = this.model;

    if (!deliveriesSubscription) {
      return false;
    }

    return deliveriesSubscription.onExpiredTrial;
  }

  get ctaTrackingEventOptions() {
    return {
      product: this.model.deliveriesSubscription.app,
      cta_id: 'deliveries_paused_banner',
      cta_type: 'banner',
      cta_clickable_type: 'button',
      cta_clickable_text: 'Upgrade',
      cta_title: this.deliveriesPausedAlertTitle,
      cta_body: this.deliveriesPausedAlertMessage,
      cta_intent: 'upgrade',
    };
  }

  @action
  trackDeliveriesUpgradeBannerClicked() {
    this.metrics.trackEvent('CTA Clicked', this.ctaTrackingEventOptions);
  }
}
