export enum WorkbookType {
  CUSTOM = 'CUSTOM',
  CORE = 'CORE',
}

export enum WorkbookScopeType {
  LOCATION = 'LOCATION',
  COMPANY = 'COMPANY',
}

export enum UserActionsType {
  VIEW_EMBED = 'view_embed',
  CUSTOMIZE_EMBED = 'customize_embed',
  UPDATE_ACTION = 'update',
  DELETE_ACTION = 'delete',
}

export interface WorkbooksResponse {
  content: Array<Workbook>;
  pageable: Pageable;
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  sort: WorkbookSort;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
}

export interface Workbook {
  id: string;
  name: string;
  description: string;
  type: WorkbookType;
  parent: ParentWorkbook | null;
  scope: WorkbookScopeType;
  createdByUserId: string | null;
  createdByUserName: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  userActions: Array<UserActionsType>;
}

export interface ParentWorkbook {
  id: string;
  name: string;
  description: string;
}

export interface Pageable {
  pageNumber: number;
  pageSize: number;
  size: WorkbookSort;
  offset: number;
  unpaged: boolean;
  paged: boolean;
}

export interface WorkbookSort {
  empty: boolean;
  unsorted: boolean;
  sorted: boolean;
}
