import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type DraftModel from 'garaje/models/draft';
import type EmployeeModel from 'garaje/models/employee';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { DRAFT_SHARE_OPTIONS } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

type SelectOptionType = {
  label: string;
  value: string;
};

interface ShareDraftModalArgs {
  draft: DraftModel;
}

export default class ShareDraftModal extends Component<ShareDraftModalArgs> {
  @service declare store: Store;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;

  @(employeesSearcherTask({
    filter: { deleted: false },
    prefix: true,
  }).restartable())
  searchEmployeesTask!: EmployeeSearcherTask;

  @tracked selectedEmployees: EmployeeModel[] = [];
  @tracked sendEmailNotification: boolean = true;
  @tracked emailMessage: string = '';

  get shareStrategyOptions(): SelectOptionType[] {
    return DRAFT_SHARE_OPTIONS;
  }

  get shareStrategy(): SelectOptionType {
    if (!this.args.draft.sharingStrategy) {
      return this.shareStrategyOptions[0]!;
    }

    return this.shareStrategyOptions.find((o) => o.value === this.args.draft.sharingStrategy)!;
  }

  get draftName(): string {
    if (!this.args?.draft?.name?.trim()) {
      return 'Untitled draft';
    }

    return this.args.draft.name;
  }

  get draftUrl(): string {
    const draftAreaMapId = this.args.draft.belongsTo('draftAreaMap').id();
    return `${window.location.origin}/spaces/maps/drafts/${draftAreaMapId}`;
  }

  @action
  trackCopyLink(): void {
    this.flashMessages.showAndHideFlash('success', 'Copied share URL');
    this.metrics.trackEvent('Copied draft share link', {
      draftId: this.args.draft.id,
      draftName: this.args.draft.name,
    });
  }

  @action
  async removeEmployeeAccess(employee: EmployeeModel): Promise<void> {
    try {
      await this.store.adapterFor('draft').updateRelationship(
        'draft',
        this.args.draft.id,
        'employees',
        this.args.draft.sharedEmployees.filter((e) => e.id !== employee.id),
      );

      this.args.draft.sharedEmployees.removeObjects(A([employee]));

      this.metrics.trackEvent('Removed employee draft access', {
        draftId: this.args.draft.id,
        draftName: this.args.draft.name,
        employeeId: employee.id,
        employeeName: employee.name,
      });

      this.flashMessages.showFlash('success', 'Access updated');
    } catch (err) {
      const error = parseErrorForDisplay(err) || 'Unknown error. Saving failed.';
      this.flashMessages.showFlash('error', error);
    }
  }

  @action
  async shareDraft(): Promise<void> {
    try {
      const employeeIds = this.selectedEmployees.map((e) => e.id);
      this.metrics.trackEvent('Shared draft with employee', {
        draftId: this.args.draft.id,
        draftName: this.args.draft.name,
        employeeIds: employeeIds,
      });

      if (!this.selectedEmployees.length) {
        return;
      }

      const allSharedEmployees = [...this.selectedEmployees, ...this.args.draft.sharedEmployees.toArray()];

      await this.store
        .adapterFor('draft')
        .updateRelationship('draft', this.args.draft.id, 'employees', allSharedEmployees, {
          message: this.emailMessage,
          'send-email-notification': this.sendEmailNotification,
        });

      this.args.draft.sharedEmployees.pushObjects(A(this.selectedEmployees));

      this.selectedEmployees = [];
      this.emailMessage = '';
      this.sendEmailNotification = true;

      this.flashMessages.showFlash('success', 'Invite sent');
    } catch (err) {
      const error = parseErrorForDisplay(err) || 'Unknown error. Saving failed.';
      this.flashMessages.showFlash('error', error);
    }
  }

  @action
  exitShareMode(): void {
    this.selectedEmployees = [];
    this.sendEmailNotification = true;
    this.emailMessage = '';
  }

  @action
  onEmployeeChange(employees: EmployeeModel[]): void {
    this.selectedEmployees = employees;
  }

  @action
  async onShareStrategyChange(option: SelectOptionType): Promise<void> {
    try {
      this.args.draft.sharingStrategy = option.value;
      await this.args.draft.save();

      this.flashMessages.showFlash('success', 'Access updated');
    } catch (err) {
      const error = parseErrorForDisplay(err) || 'Unknown error. Saving failed.';
      this.flashMessages.showFlash('error', error);
    }
  }
}
