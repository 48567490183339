import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type ScimPrimaryLocationConfigurationModel from 'garaje/models/scim-primary-location-configuration';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('scim-primary-location-mapping')
class ScimPrimaryLocationMappingModel extends Model {
  @attr('string') declare scimAttributeValue: string;

  @belongsTo('scim-primary-location-configuration', { async: true })
  declare scimPrimaryLocationConfiguration:
    | AsyncBelongsTo<ScimPrimaryLocationConfigurationModel>
    | ScimPrimaryLocationConfigurationModel;
  @belongsTo('location', { async: false }) declare location: LocationModel | null;
}

export default ScimPrimaryLocationMappingModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-primary-location-mapping': ScimPrimaryLocationMappingModel;
  }
}
