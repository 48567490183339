import { action } from '@ember/object';
import { service } from '@ember/service';
import { isBlank, isPresent } from '@ember/utils';
import type Model from '@ember-data/model';
import type { AsyncHasMany } from '@ember-data/model';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout, task } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import { pluralize } from 'ember-inflector';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import { isPaginated } from 'garaje/utils/is-paginated';
import zft from 'garaje/utils/zero-for-tests';
import unionBy from 'lodash/unionBy';
import { reads } from 'macro-decorators';
import type HTMLElementEvent from 'utils/html-element-event';

export type NameKey = 'name';
export type ItemsKey = 'locations';
export type GroupKey = 'group';
export type TotalKey = 'total';

export type SupportedModel<N extends string = NameKey, G extends string = GroupKey> = Model & { [key in N]: string } & {
  [key in G]: SupportedGroupModel;
};
export type SupportedGroupModel<
  N extends string = NameKey,
  I extends string = ItemsKey,
  T extends string = TotalKey,
> = Model & {
  [key in N]: string;
} & {
  [key in I]: SupportedModel[] | AsyncHasMany<SupportedModel>;
} & {
  [key in T]: number;
};

export interface GroupOption {
  trackingKey: string;
  isParent: true;
  name: string;
  data: {
    id: string;
    name: string;
    total: number;
    items: SupportedModel[];
  };
}

export interface ChildOption {
  trackingKey: string;
  isParent: false;
  name: string;
  data: {
    id: string;
    name: string;
    group: unknown;
  };
}

export type MultiModelOption = GroupOption | ChildOption;

export type VirtualItem = Partial<SupportedModel> & {
  id: string;
};

/**
 * Multiple select on top of `BasicDropdown` for models that can be grouped and their groups.
 */
export interface MultiModelSelectorComponentSignature {
  Args: {
    selectedIds?: string;
    items?: SupportedModel[] | PaginatedRecordArray<SupportedModel>;
    groups?: SupportedGroupModel[];
    loading?: boolean;
    onSelected?: (items: VirtualItem[]) => void;
    modelDisplayName?: string;
    groupModelDisplayName?: string;
    nameKey?: NameKey;
    itemsKey?: ItemsKey;
    totalKey?: TotalKey;
    groupKey?: GroupKey;
    searchEnabled?: boolean;
  };

  Element: HTMLElement;
}

export default class MultiModelSelectorComponent extends Component<MultiModelSelectorComponentSignature> {
  @service declare infinity: InfinityService;

  @tracked options: MultiModelOption[] = [];
  @tracked searchQuery = '';

  @reads('args.searchEnabled', true) declare searchEnabled: boolean;
  @reads('args.nameKey', 'name') declare nameKey: NameKey;
  @reads('args.itemsKey', 'locations') declare itemsKey: ItemsKey;
  @reads('args.totalKey', 'total') declare totalKey: TotalKey;
  @reads('args.groupKey', 'group') declare groupKey: GroupKey;
  @reads('args.modelDisplayName', 'location') declare modelDisplayName: string;
  @reads('args.groupModelDisplayName', 'group') declare groupModelDisplayName: string;
  @reads('args.selectedIds', '') declare selectedIds: string;

  get pluralModelDisplayName(): string {
    return pluralize(this.modelDisplayName);
  }

  get isPaginated(): boolean {
    return isPaginated(this.items);
  }

  get selectedText(): string {
    const { selectedItems, items } = this;
    const totalSelected = selectedItems.length;

    if (isBlank(selectedItems) || totalSelected === items.length) {
      return `All ${this.pluralModelDisplayName}`;
    }

    if (totalSelected === 1) {
      return selectedItems[0]![this.nameKey] || `1 ${this.modelDisplayName}`;
    }

    return `${totalSelected} ${this.pluralModelDisplayName}`;
  }

  get groups(): SupportedGroupModel[] {
    return this.args.groups || [];
  }

  get items(): SupportedModel[] | PaginatedRecordArray<SupportedModel> {
    return this.args.items || [];
  }

  /**
   * a union of the currently available items and the selected items used to capture items possibly
   *
   */
  get virtualItems(): VirtualItem[] {
    let items: SupportedModel[] = [];

    if (this.isPaginated) {
      items = (<PaginatedRecordArray<SupportedModel>>this.items).toArray();
    } else {
      items = <SupportedModel[]>this.items;
    }

    return unionBy(items, this.selectedItems, 'id');
  }

  get selectedItems(): VirtualItem[] {
    const ids = (this.selectedIds || '').split(',').filter(Boolean);

    return ids.map((id) => {
      return this.items.find((item) => item.id === id) ?? { id };
    });
  }

  get isSelectAllDisabled(): boolean {
    return this.selectedItems.length === this.items.length;
  }

  get isDeselectAllDisabled(): boolean {
    return this.selectedItems.length === 0;
  }

  get filteredOptions(): MultiModelOption[] {
    if (isBlank(this.searchQuery)) {
      return this.options;
    }

    return this.options.reduce((filteredOpts: MultiModelOption[], option: MultiModelOption): MultiModelOption[] => {
      // If the top level option matches the search query, render the option
      // along with all its nested items.
      if (this.#matchesSearchQuery(option[this.nameKey])) {
        filteredOpts.push(option);

        return filteredOpts;
      }

      // If the option doesn't directly match the search and it's a group,
      // check its nested item for a match.
      if (option.isParent) {
        const { id, name, total, items } = option.data;
        const filteredItems = items.filter((item) => this.#matchesSearchQuery(item[this.nameKey]));

        if (filteredItems.length) {
          const data = { id, name, total, items: this.#sortOptions(filteredItems) };

          filteredOpts.push({
            ...option,
            data,
          });
        }
      }

      return filteredOpts;
    }, []);
  }

  @action
  handleSearch(event: HTMLElementEvent<HTMLInputElement>): void {
    void this.setSearchQueryTask.perform(event.target.value);
  }

  /**
   * Handles the `group` selected action. If the `group` is in an `indeterminate` state, we only send the already
   * selected items as we want to clear the group. Otherwise we send all items for that group.
   *
   * @param optionGroup
   */
  @action
  onGroupSelected(optionGroup: GroupOption): void {
    const { items } = optionGroup.data;
    const { selectedItems } = this;
    const isSelected = items.some((item) => selectedItems.includes(item));

    this.args.onSelected?.(this.#itemsToUpdateSelectionState([optionGroup], isSelected));
  }

  @action
  selectAll(): void {
    this.args.onSelected?.(this.#itemsToUpdateSelectionState(this.options));
  }

  @action
  deselectAll(): void {
    const itemsToDeselect = this.#itemsToUpdateSelectionState(this.options, true);
    // create a union from currently selected items to capture items that might be outside
    // the current list of items
    const unionList = unionBy(itemsToDeselect, this.selectedItems, 'id');

    this.args.onSelected?.(unionList);
  }

  infinityLoadTask = task(async (infinityModel: PaginatedRecordArray<SupportedModel>) => {
    await this.infinity.infinityLoad(infinityModel);
    await this.computeOptionsTask.perform();
  });

  computeOptionsTask = task({ restartable: true }, async (): Promise<void> => {
    await timeout(zft(500));

    const options: MultiModelOption[] = [];

    // All the items that do not belong to a group
    const itemsWithoutGroup = this.items.filter((item) => !item[this.groupKey] || isBlank(this.groups));

    itemsWithoutGroup.forEach((item) =>
      options.push({ isParent: false, name: item[this.nameKey], data: item, trackingKey: `item-${item.id}` }),
    );

    if (isPresent(this.groups)) {
      // All the available groups with their states
      this.groups.forEach((group) => {
        const { id, name } = group;

        const items = group[this.itemsKey] ?? [];
        const resolvableItems = 'toArray' in items ? items.toArray() : items;
        const total = group[this.totalKey];

        if (total === 0) return;

        const data = { id, name, total, items: this.#sortOptions(resolvableItems) };

        options.push({ isParent: true, name, data, trackingKey: `group-${id}` });
      });
    }

    this.options = this.#sortOptions(options);
  });

  setSearchQueryTask = task({ restartable: true }, async (value: string): Promise<void> => {
    await timeout(zft(200));
    this.searchQuery = value;
  });

  /**
   * Helper that matches a string against this.searchQuery. Returns true if
   * there's a string overlap, false if not.
   *
   * @param str the string to match against this.searchQuery
   * @return whether there is a match or not
   */
  #matchesSearchQuery(str: string): boolean {
    return str.toLowerCase().includes(this.searchQuery.toLowerCase());
  }

  #sortOptions<T extends MultiModelOption[] | SupportedModel[]>(options: T): T {
    // do not sort paginated lists
    if (this.isPaginated) return options;
    const { nameKey } = this;
    return options.sort((option1, option2) => {
      const n1 = option1[nameKey].toLowerCase();
      const n2 = option2[nameKey].toLowerCase();

      if (n1 === n2) return 0;

      return n1 < n2 ? -1 : 1;
    }) as typeof options;
  }

  /**
   * Among the options provided, find either:
   * - items included in the current selection
   * - items excluded from the current selection
   * This allows select/deselect all to indicate a list of
   * items to toggle selection state for.
   */
  #itemsToUpdateSelectionState(options: MultiModelOption[], currentlySelected: boolean = false): SupportedModel[] {
    const { selectedIds, items } = this;
    const ids = (selectedIds || '').split(',');
    const filterFn = (data: { id: string }): boolean => {
      const isSelected = ids.includes(data.id);

      return currentlySelected ? isSelected : !isSelected;
    };

    return options.reduce((opts: SupportedModel[], opt: MultiModelOption): SupportedModel[] => {
      // Check each item in a group
      if (opt.isParent) {
        return [...opts, ...opt.data.items.filter(filterFn)];
      }

      if (filterFn(opt.data)) {
        const it = items.find((l) => l.id === opt.data.id);

        if (it) opts.push(it);
      }

      return opts;
    }, []);
  }
}
