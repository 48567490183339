import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { alias } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @service state;

  @alias('state.currentLocation') currentLocation;

  @tracked showModalTranslations = false;
  @tracked showModalDelete = false;

  constructor() {
    super(...arguments);

    this.changeset = this.args.changeset;
  }

  @action
  handleChangeName(name) {
    set(this.changeset, 'name', name);
  }

  @action
  handleChangeDescription(description) {
    set(this.changeset, 'description', description);
  }
}
