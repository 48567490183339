import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { equal } from 'macro-decorators';
import { service } from '@ember/service';
import { restartableTask, timeout } from 'ember-concurrency';
import { IFRAME_STATE } from 'garaje/utils/enums';
import config from 'garaje/config/environment';
import { registerDestructor } from '@ember/destroyable';

/**
 * @param {object}  [context]                          - optional context passed to iframe
 * @param {string}  [eventName="embedded-app-message"] - name used for events emitted via messageBus service
 * @param {string}  [id]                               - value to use as `id` attribute of iframe element
 * @param {string}  [iframeClass]                      - CSS class names to apply to iframe element
 * @param {number}  [loadingTimeout=30000]             - time (in ms) to wait before considering that loading iframe content has timed out
 * @param {object}  [options]                          - iframe resizer options
 * @param {number}  [resizeTimeout=1000]               - time (in ms) to wait before considering that iframe resizing has timed out
 * @param {boolean} [scrolling=false]                  - whether scrolling should be enabled on the iframe
 * @param {string}  url                                - URL for iframe's `src` attribute
 */
export default class EmbeddedApp extends Component {
  @service embeddedApp;
  @service messageBus;
  @service router;
  @service windowLocation;

  // states: loading, timeout, loaded
  @tracked state = IFRAME_STATE.LOADING;
  @equal('state', IFRAME_STATE.LOADING) isLoading;
  @equal('state', IFRAME_STATE.TIMEOUT) isTimedOut;
  @equal('state', IFRAME_STATE.LOADED) isLoaded;

  // Used to display a warning that the iframe is not set up correctly
  @tracked showIframeWarning = false;

  // Used to cache bust the iframe URL
  @tracked timestamp = Date.now();

  // ec on('init') doesn't seem to fire in a glimmer component. manually performing the
  // task on component init
  constructor() {
    super(...arguments);
    this.loadingIframeTask.perform();
  }

  /**
   * Add a timestamp query param to the URL passed in so that we are able to reload the
   * iframe by updating the timestamp when the retry button is pressed in the timeout
   * screen.
   */
  get url() {
    try {
      const cacheBustingURL = new URL(this.args.url, this.windowLocation.href);
      cacheBustingURL.searchParams.set('_envoyts', this.timestamp); // eslint-disable-line ember/use-ember-get-and-set
      return cacheBustingURL.toString();
    } catch (e) {
      return '';
    }
  }

  get loadingTimeout() {
    return this.args.loadingTimeout ?? 30000;
  }

  /**
   * Displays a loading spinner while the iframe has not yet emitted the onLoad event.
   * After the timeout, it will display an error. If the task is cancelled before the
   * timeout, the error will not display.
   *
   * NOTE: there doesn't seem to be a way to stop an iframe from loading, so even if the
   * state has transitioned to timeout, the iframe could very well emit the onLoad some
   * time after, which will move the state to loaded. The impact to the user is that they
   * could be staring at the error screen for a few seconds when suddenly the actual
   * the error screen goes away to be replaced by the iframe content.
   */
  @restartableTask
  *loadingIframeTask() {
    this.state = IFRAME_STATE.LOADING;
    yield timeout(this.loadingTimeout);
    this.state = IFRAME_STATE.TIMEOUT;
  }

  get resizeTimeout() {
    return this.args.resizeTimeout ?? 1000;
  }

  /**
   * Displays a helpful debugging message above the iframe when it detects that the iframe
   * is not set up correctly for autoresizing.
   */
  @restartableTask
  *showIframeWarningTask() {
    this.showIframeWarning = false;
    yield timeout(this.resizeTimeout);
    this.showIframeWarning = true;
  }

  /**
   * iframe onLoad handler to signal that the iframe URL has successfully loaded.
   *
   * We use it to cancel out the spinner-then-error-screen task and start a new task that
   * will show a warning that the iframe was not correctly set up for iframeResizer if it
   * doesn't receive a proper resize event within a timeout.
   */
  @action
  onLoad() {
    this.loadingIframeTask.cancelAll();
    this.state = IFRAME_STATE.LOADED;
    if (!config.isProduction) {
      this.showIframeWarningTask.perform();
    }
  }

  /**
   * Callback passed to iframeResizer in the {{iframe-resize}} element modifier. Whenever
   * Garaje receives a message from a managed iframe, this handler will be invoked.
   */
  @action
  onMessage({ iframe, message }) {
    switch (message.event) {
      // sent by iframe when initialization is complete. we use this event to send the
      // initial context.
      case 'ready': {
        this.sendContext(iframe, [this.args.context]);
        break;
      }
      // sent by iframe when it is navigating to a garaje-owned URL. instead of following
      // the link inside the iframe, it delegates the navigation to garaje to perform a
      // native transition.
      case 'navigate': {
        const { pathname, search } = new URL(message.url);
        this.router.transitionTo(`${pathname}${search}`);
        break;
      }

      default:
        break;
    }
    this.messageBus.trigger(this.args.eventName ?? 'embedded-app-message', message);
  }

  /**
   * Callback fired by iframeResizer whenever the iframe resizes.
   *
   * We really only use this to tell if iframeResizer is set up correctly.
   */
  @action
  onResized({ height }) {
    if (parseInt(height, 10)) {
      // if resize is successful, data will eventually contain a message with height > 0
      this.showIframeWarningTask.cancelAll();
      // in the case it already showed the warning but took a while to call the onResized
      // callback
      this.showIframeWarning = false;
    }
  }

  /**
   * Create a cache-busting iframe src URL and restart the loading process.
   *
   * This action is attached the to button in the error screen that gets shown if the
   * iframe fails to load after `this.loadingTimeout`.
   */
  @action
  onRetry() {
    this.timestamp = Date.now();
    this.loadingIframeTask.perform();
  }

  /**
   * Send context to the hosted iframe
   */
  @action
  sendContext(element, [context]) {
    if (context !== undefined) {
      const message = { event: 'context', context };
      this.messageSender(message);
    }
  }

  @action
  registerMessageSender(element) {
    this.messageSender = (message) => {
      element.iFrameResizer?.sendMessage(message);
    };
    this.embeddedApp.registerMessageSender(this.args.id, this.messageSender);
    registerDestructor(this, () => {
      // eslint-disable-next-line no-console
      console.log('cleanup message sender');
      this.embeddedApp.unregisterMessageSender(this.args.id);
    });
  }
}
