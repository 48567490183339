import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class WorkplaceSettingsEmployeesIndexRoute extends Route {
  @service router;

  async beforeModel() {
    this.router.transitionTo('workplace.settings.employees.registration');
  }
}
