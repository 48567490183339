import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type SubscriptionModel from 'garaje/models/subscription';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('billing-v1')
class CouponRedemptionModel extends Model {
  @belongsTo('subscription', { async: true }) declare subscription: AsyncBelongsTo<SubscriptionModel>;

  @attr('string') declare code: string;
}

export default CouponRedemptionModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'coupon-redemption': CouponRedemptionModel;
  }
}
