import Model, { belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type User from 'garaje/models/user';

import type ZoneModel from './zone';

export default class BlacklistContactUser extends Model {
  @belongsTo('contact-configurable', { async: true, polymorphic: true })
  declare contactConfigurable: AsyncBelongsTo<ZoneModel> | ZoneModel;
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<User> | User;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blacklist-contact-user': BlacklistContactUser;
  }
}
