import { isEmpty } from '@ember/utils';
import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

import type ZoneModel from './zone';

interface Photo {
  original: string | null;
  thumb: string | null;
  dataUrl: string | null;
}

class BlacklistFilter extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;

  // Strings (not sure on createdBy)
  @attr('string') declare createdBy: string;
  @attr('string') declare description: string;
  @attr('string') declare fullName: string;
  @attr('string') declare reason: string;
  @attr('string') declare keyword: string; // deprecated, still used on blacklist-filter

  // Arrays of Strings
  @attr('array', { defaultValue: () => [] }) declare aliases: string[];
  @attr('array', { defaultValue: () => [] }) declare emails: string[];
  @attr('array', { defaultValue: () => [] }) declare otherKeywords: string[];
  @attr('array', { defaultValue: () => [] }) declare phoneNumbers: string[];

  @attr('immutable', {
    defaultValue: () => ({ original: null, thumb: null, dataUrl: null }),
  })
  declare photo: Photo;

  get searchable(): string {
    return `${this.description}~${this.fullName}~${this.reason}~${this.searchableAliases}~${this.searchableEmails}~${this.searchableKeywords}~${this.searchablePhoneNumbers}`;
  }

  get searchableAliases(): string {
    const aliases = this.aliases || [];
    if (isEmpty(aliases)) {
      return '';
    }
    return aliases.reduce((all, one) => `${all}${one}`);
  }

  get searchableEmails(): string {
    const emails = this.emails || [];
    if (isEmpty(emails)) {
      return '';
    }
    return emails.reduce((all, one) => `${all}${one}`);
  }

  get searchableKeywords(): string {
    const otherKeywords = this.otherKeywords || [];
    if (isEmpty(otherKeywords)) {
      return '';
    }
    return otherKeywords.reduce((all, one) => `${all}${one}`);
  }

  get searchablePhoneNumbers(): string {
    const phoneNumbers = this.phoneNumbers || [];
    if (isEmpty(phoneNumbers)) {
      return '';
    }
    return phoneNumbers.reduce((all, one) => `${all}~${one}`);
  }

  toDataForCsvExport(): Record<string, string> {
    const { id, fullName, description, reason } = this;
    const aliases = this.aliases && this.aliases.length ? this.aliases.join(',') : '';
    const emails = this.emails && this.emails.length ? this.emails.join(',') : '';
    const phoneNumbers = this.phoneNumbers && this.phoneNumbers.length ? this.phoneNumbers.join(',') : '';
    const otherKeywords = this.otherKeywords && this.otherKeywords.length ? this.otherKeywords.join(',') : '';

    return {
      ID: id,
      'Full Name': fullName,
      Aliases: aliases,
      'Email addresses': emails,
      'Phone Numbers': phoneNumbers,
      Keywords: otherKeywords,
      'Physical description': description,
      'Reason for blocking': reason,
    };
  }
}

export default BlacklistFilter;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'blacklist-filter': BlacklistFilter;
  }
}
