import ApplicationSerializer from './application';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
import { service } from '@ember/service';

export default class FlowSerializer extends ApplicationSerializer {
  @service featureFlags;
  @service state;

  // This is used for the `hasMany` relationship between flows and skinny-locations
  // envoy-web doesn't know anything about the skinny-locations model.
  modelNameFromPayloadKey(key) {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }

  normalize() {
    const normalized = super.normalize(...arguments);
    const {
      data: {
        relationships: { location },
      },
    } = normalized;
    if (location && location.data) {
      location.data.type = 'location';

      // locations are loaded in as skinny locations, so that needs to be also identified
      // as a relationship in order to use the includes scattered across the application
      normalized.data.relationships.skinnyLocation = {
        data: {
          type: 'skinny-location',
          id: location.data.id,
        },
        links: location.links,
        type: 'skinny-location',
      };
    }
    return normalized;
  }

  payloadKeyFromModelName(key) {
    if (key === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }

  @computed('state.vrSubscription.canAccessGroupSignIn')
  get attrs() {
    const attrs = {
      propertyInviteApprovalsEnabled: {
        serialize: false,
      },
      skinnyLocation: { serialize: false },
    };

    if (!get(this, 'state.vrSubscription.canAccessGroupSignIn')) {
      attrs.additionalGuests = { serialize: false };
    }

    return attrs;
  }

  serializeIntoHash(hash, type, snapshot, options) {
    const payload = this.serialize(snapshot, options);
    const { attributes, relationships } = payload.data;
    // workaround while ED supports per record meta
    if (!payload.data.id && payload.data.attributes.blueprint) {
      payload.meta = payload.meta || {};

      payload.meta.blueprint = {
        type: 'flows',
        id: payload.data.attributes.blueprint.toString(),
      };
    }
    delete payload.data.attributes.blueprint;

    if (attributes.type === 'Flows::EmployeeScreening' && relationships.location) {
      delete payload.data.relationships.location;
    }
    delete payload.data.attributes.type;
    Object.assign(hash, payload);
  }
}
