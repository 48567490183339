import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type AreaMapModel from 'garaje/models/area-map';
import type EmployeeModel from 'garaje/models/employee';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('maps')
class MapVersionModel extends Model {
  // Attributes
  @attr('string') declare source: string;
  @attr('number') declare createdAt: number;

  // Relationships
  @belongsTo('area-map', { async: true }) declare areaMap: AsyncBelongsTo<AreaMapModel> | AreaMapModel;
  @hasMany('employee', { async: true }) declare employees: AsyncHasMany<EmployeeModel>;
}

export default MapVersionModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'map-version': MapVersionModel;
  }
}
