import Route from '@ember/routing/route';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

class MeetingRoute extends Route {
  @service abilities;
  @service locations;
  @service router;
  @service store;

  beforeModel() {
    if (this.abilities.cannot('visit meetings')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model(params) {
    return this.store.findRecord('meeting', params.id, {
      include: 'attendees,location,employee',
    });
  }

  afterModel() {
    return this.locations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupLocation();
  }
}

export default MeetingRoute;
