import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class MapsIndexRoute extends Route {
  @service abilities;
  @service router;

  async beforeModel() {
    const { areaMap } = this.modelFor('spaces.maps');
    const mapFloors = await areaMap.mapFloors;
    const hasFloorWithRasterImage = mapFloors.any((floor) => floor.rasterImageUrl);
    let transitionTo = '';
    if ((isEmpty(mapFloors) || !hasFloorWithRasterImage) && this.abilities.can('edit maps')) {
      transitionTo = 'spaces.maps.edit';
    } else if (hasFloorWithRasterImage && this.abilities.can('visit-live-maps maps')) {
      transitionTo = 'spaces.maps.live';
    } else if (this.abilities.can('visit-drafts maps')) {
      transitionTo = 'spaces.maps.drafts';
    } else if (this.abilities.can('visit-neighborhoods maps')) {
      transitionTo = 'spaces.maps.neighborhoods';
    }

    this.router.transitionTo(transitionTo);
  }
}
