import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';

const DATE_FORMAT = 'MMM d, yyyy - h:mm a';
const TIME_FORMAT = 'h:mm a';

interface ArrivedAtColumnComponentSignature {
  Args: {
    timezone?: string;
    value: Date;
    showFullDate: boolean;
  };
}

export default class ArrivedAtColumnComponent extends Component<ArrivedAtColumnComponentSignature> {
  get timezone(): string {
    return this.args.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  get value(): string | null {
    return this.args.value
      ? formatInTimeZone(this.args.value, this.timezone, this.args.showFullDate ? DATE_FORMAT : TIME_FORMAT)
      : null;
  }
}
