import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StoreService from 'garaje/services/store';

export default class MapsVersionsIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (!this.featureFlags.isEnabled('map-version-history') || this.abilities.cannot('edit maps')) {
      return void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<void> {
    const { areaMap } = this.modelFor('spaces.maps') as { areaMap: AreaMapModel };

    const mapVersions = await this.store.query('map-version', {
      filter: { 'area-map': areaMap.id, 'exclude-source': 'internal-update' },
      page: {
        size: 1,
      },
    });

    if (mapVersions.firstObject) {
      return void this.router.transitionTo('spaces.maps.versions.show', mapVersions.firstObject.id);
    }
  }
}
