import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { type Task, task } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import type PropertySettingsTenantsListController from './controller';

export interface TenantRouteListModel {
  property: ZoneModel;
  loadTenantsTask: Task<PaginatedRecordArray<TenantModel>, [property: ZoneModel, search?: string | undefined]>;
}

export default class PropertySettingsTenantsListRoute extends Route {
  @service declare store: Store;
  @service declare featureFlags: FeatureFlagsService;
  @service declare infinity: InfinityService;

  search?: string;

  queryParams = {
    search: {
      refreshModel: true,
      replace: true,
    },
  };

  async model({ search }: { search: string }): Promise<TenantRouteListModel> {
    this.search = search;

    const property = <ZoneModel>this.modelFor('property');
    await this.loadTenantsTask.perform(property, search).catch(throwUnlessTaskDidCancel);

    return {
      property,
      loadTenantsTask: this.loadTenantsTask,
    };
  }

  setupController(
    controller: PropertySettingsTenantsListController,
    model: TenantRouteListModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.total = controller.tenants.meta.total;
  }

  resetController(controller: PropertySettingsTenantsListController, isExiting: boolean): void {
    if (isExiting) {
      Object.assign(controller, {
        search: '',
        tenantToDelete: null,
        tenantToDisconnect: null,
      });
    }
  }

  @action
  loading(transition: Transition): boolean {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <PropertySettingsTenantsListController>this.controllerFor('property/settings/tenants/list');
    controller.loadingData = true;
    void transition.promise.finally(function () {
      controller.loadingData = false;
    });
    return transition.from?.name !== transition.to.name;
  }

  loadTenantsTask = task(async (property: ZoneModel, search: string | undefined = this.search) => {
    const include = ['suites', 'tenant-connection-request', 'tenant-connection-request.connection-request-invites'];

    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <PropertySettingsTenantsListController>this.controllerFor('property/settings/tenants/list');

    const tenants = await this.infinity.model(
      'tenant',
      {
        filter: { property: property.id, name: search },
        include: include.join(','),
        perPage: 20,
        perPageParam: 'page[limit]',
        pageParam: 'page[offset]',
        startingPage: 0,
        countParam: 'meta.total',
      },
      ExtendedInfinityModel,
    );

    // update count in controller if re-running task outside of model hook
    if (controller) controller.total = tenants.meta.total;

    return tenants;
  });
}
