import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
export default class ContactsModal extends Component {
  @service statsig;

  constructor() {
    super(...arguments);
    this.statsig.logEvent('billing-contact-us-modal-viewed');
  }

  @action
  logContactClicked(contactRole) {
    this.statsig.logEvent('billing-contact-modal-contact-clicked', null, { role: contactRole });
  }
}
