import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { format, toDate } from 'date-fns-tz';
import { task } from 'ember-concurrency';
import saveAs from 'file-saver';
import type { AnnouncementChannelValue } from 'garaje/models/announcement-channel';
import type AnnouncementRecipientModel from 'garaje/models/announcement-recipient';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import {
  ANNOUNCEMENT_RECIPIENT_FILTERS,
  ANNOUNCEMENT_RECIPIENT_OPTIONS,
  ANNOUNCEMENT_STATUS_OPTIONS,
  EMERGENCY_NOTIFICATION_STATUS_OPTIONS,
} from 'garaje/utils/enums';

import type { CommunicationsMessageModel } from './route-base';

type SelectOptionType = {
  label: string;
  value: string;
};

export default class CommunicationsMessageControllerBase extends Controller {
  declare model: CommunicationsMessageModel;

  @service store!: Store;
  @service featureFlags!: FeatureFlagsService;

  @tracked recipientOptions = ANNOUNCEMENT_RECIPIENT_OPTIONS;
  @tracked selectedRecipient = 'all';
  @tracked selectedStatus = 'all';
  @tracked sortBy = 'name';
  @tracked sortDirection: string = 'desc';
  @tracked page = 1;
  @tracked limit = 100;

  get markAsSafeRequired(): boolean {
    return !!this.model?.announcement?.emergencyNotification?.content?.markAsSafeRequired;
  }

  get statusOptions(): typeof EMERGENCY_NOTIFICATION_STATUS_OPTIONS {
    let options;

    if (this.model?.announcement?.emergencyNotification?.content?.markAsSafeRequired) {
      options = [...EMERGENCY_NOTIFICATION_STATUS_OPTIONS];
    } else {
      options = [...ANNOUNCEMENT_STATUS_OPTIONS];
    }

    const channelStatusMap: Record<AnnouncementChannelValue, SelectOptionType> = {
      email: { value: 'email-not-sent', label: 'Email not sent' },
      push: { value: 'push-not-sent', label: 'Push not sent' },
      sms: { value: 'sms-not-sent', label: 'SMS not sent' },
      slack: { value: 'slack-not-sent', label: 'Slack not sent' },
      ms_teams: { value: 'ms-teams-not-sent', label: 'Microsoft Teams not sent' },
    };

    Object.keys(channelStatusMap).forEach((channel) => {
      if (this.hasAnnouncementChannel(channel)) {
        options.push({
          ...channelStatusMap[channel as AnnouncementChannelValue],
          icon: 'icon-cross-small-red !h-[12px]',
        });
      }
    });

    return options;
  }

  get sentDate(): string {
    const createdAt = this.model.announcement.createdAt;
    const date = toDate(createdAt);

    return format(date, "MMM dd, yyyy 'at' h:mm a zzz");
  }

  get filters(): { [key: string]: string } {
    const filters: { [key: string]: string } = {};

    if (this.selectedRecipient !== 'all') {
      filters[ANNOUNCEMENT_RECIPIENT_FILTERS.recipient_type] = this.selectedRecipient;
    }

    switch (this.selectedStatus) {
      case 'email-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.email_sent] = 'false';
        break;
      case 'push-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.push_sent] = 'false';
        break;
      case 'sms-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.sms_sent] = 'false';
        break;
      case 'slack-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.slack_sent] = 'false';
        break;
      case 'ms-teams-not-sent':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.ms_teams_sent] = 'false';
        break;
      case 'sent':
      case 'failed':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.notification_status_equal_to] = this.selectedStatus;
        break;
      case 'not-marked-as-safe':
      case 'marked-as-safe':
        filters[ANNOUNCEMENT_RECIPIENT_FILTERS.marked_as_safe] =
          this.selectedStatus === 'marked-as-safe' ? 'true' : 'false';
    }

    return filters;
  }

  get recipientFilter(): SelectOptionType {
    return this.recipientOptions.find((o) => o.value === this.selectedRecipient)!;
  }

  get statusFilter(): SelectOptionType {
    return this.statusOptions.find((o) => o.value === this.selectedStatus)!;
  }

  get showExport(): boolean {
    return this.featureFlags.isEnabled('emno-csv-export-web');
  }

  @action
  setSort(field: string, direction: string): void {
    this.sortBy = field;
    this.sortDirection = direction;
  }

  @action
  updateRecipientFilter(option: SelectOptionType): void {
    this.selectedRecipient = option.value;
  }

  @action
  updateStatusFilter(option: SelectOptionType): void {
    this.selectedStatus = option.value;
  }

  exportToCsv = task({ drop: true }, async () => {
    const recipients = await this.getAllRecipients();

    const employees = await Promise.all(
      recipients.filter((recipient) => recipient.employeeId).map((recipient) => recipient.employee),
    );

    const employeeEmails = new Map(employees.map((employee) => [employee.id, employee.email] as [string, string]));

    const columnConfiguration = [
      {
        label: 'Recipient type',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.employeeId ? 'Employee' : 'Visitor'),
      },
      {
        label: 'Full name',
        getValue: (recipient: AnnouncementRecipientModel) => recipient.name,
      },
      {
        label: 'Email',
        getValue: (recipient: AnnouncementRecipientModel) => {
          if (recipient.employeeId) {
            // This isn't ember
            // eslint-disable-next-line ember/use-ember-get-and-set
            return employeeEmails.get(recipient.employeeId) || '';
          } else {
            return recipient.visitorEmail || '';
          }
        },
      },
      {
        label: 'Phone Number',
        getValue: (recipient: AnnouncementRecipientModel) => recipient.phoneNumber || '',
      },
      this.hasAnnouncementChannel('email') && {
        label: 'Email sent',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.emailSent ? 'TRUE' : 'FALSE'),
      },
      this.hasAnnouncementChannel('push') && {
        label: 'Push sent',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.pushSent ? 'TRUE' : 'FALSE'),
      },
      this.hasAnnouncementChannel('sms') && {
        label: 'SMS sent',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.smsSent ? 'TRUE' : 'FALSE'),
      },
      this.hasAnnouncementChannel('slack') && {
        label: 'Slack sent',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.slackSent ? 'TRUE' : 'FALSE'),
      },
      this.hasAnnouncementChannel('ms_teams') && {
        label: 'Microsoft Teams sent',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.msTeamsSent ? 'TRUE' : 'FALSE'),
      },
      this.markAsSafeRequired && {
        label: 'Marked safe',
        getValue: (recipient: AnnouncementRecipientModel) => (recipient.markedAsSafe ? 'TRUE' : 'FALSE'),
      },
      this.markAsSafeRequired && {
        label: 'Marked safe at',
        getValue: (recipient: AnnouncementRecipientModel) =>
          recipient.markedAsSafeAt ? format(recipient.markedAsSafeAt, 'E LLL d yyyy HH:mm:ss OOOO') : '',
      },
    ].filter((column) => !!column);

    const csvContent = [
      columnConfiguration.map((column) => column.label).join(','),
      ...recipients.map((recipient) => columnConfiguration.map((column) => column.getValue(recipient)).join(',')),
    ];
    const filename = `envoy-message-${this.model.announcementId}-${format(new Date(), 'yyyy-MM-dd-HH:mm:ssOOOO')}.csv`;
    const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv;charset=utf-8' });
    saveAs(csvBlob, filename);
  });

  private async getAllRecipients() {
    const limit = 200;
    // First iteration offset will be 0
    let offset = -limit;
    const recipients = [];

    do {
      offset += limit;

      const nextRecipients = await this.store.query('announcement-recipient', {
        filter: {
          announcement: this.model.announcementId,
        },
        page: {
          limit,
        },
        include: 'employee',
      });

      recipients.push(...nextRecipients.toArray());
    } while (recipients.length === limit + offset);

    return recipients;
  }

  private hasAnnouncementChannel(channel: string) {
    return this.model?.announcement?.announcementChannels?.any((ac) => ac.channel === channel);
  }
}
