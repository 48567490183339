import { action, set } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, dropTask, type Task } from 'ember-concurrency';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import LOCALE_OPTIONS from 'garaje/utils/locale-options';
import type { Locale, LocaleKey, Translatable } from 'garaje/utils/locale-options';
import { filter } from 'macro-decorators';

interface ManageTranslationsComponentSignature {
  Args: {
    enabledLocales: LocaleKey[];
    locale: LocaleKey;
    saveTask: Task<void, []>;
    onClose?: () => void;
    translatables: Translatable[];
  };
}

export default class ManageTranslationsComponent extends Component<ManageTranslationsComponentSignature> {
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  @tracked localeOptions: Locale[];
  @tracked selectedLocale: Locale | null;

  constructor(owner: unknown, args: ManageTranslationsComponentSignature['Args']) {
    super(owner, args);

    const firstLocale = this.args.enabledLocales[0];

    this.localeOptions = LOCALE_OPTIONS;
    this.selectedLocale = this.localeOptions.find((locale) => locale.value === firstLocale) || null;
    void this.reloadTranslatablesTask.perform();
  }

  @filter('args.translatables', (translatable: Translatable): boolean => !translatable.isDeleted && !translatable.isNew)
  filteredTranslatables!: Translatable[];

  get defaultLocale(): string {
    return this.localeOptions.find((locale) => locale.value === this.args.locale)?.label || 'English';
  }

  get availableLocales(): Locale[] {
    return this.localeOptions.filter((option) => this.args.enabledLocales.includes(option.value));
  }

  trackTranslationEvent(): void {
    const translatable = this.filteredTranslatables[0];

    if (translatable?.isGlobal === false) {
      const { currentLocation, currentUser } = this.state;
      const timestamp = Math.floor(new Date().getTime() / 1000);
      const trackingEventProperties: AnalyticsTraits = {
        company_id: currentLocation.belongsTo('company').id(),
        location_id: currentLocation.id,
        user_id: currentUser.id,
        flow_id: translatable.id,
        translations: translatable.customTranslations,
        timestamp,
      };

      this.metrics.trackEvent('Visitor Type Name Translated', trackingEventProperties);
    }
  }

  @action
  changeLocale(locale: Locale): void {
    this.selectedLocale = locale;
  }

  @action
  updateTranslatable(translatable: Translatable, field: string, value: unknown): void {
    set(translatable, field, Object.assign({}, value));
  }

  reloadTranslatablesTask = dropTask(async (): Promise<void> => {
    await all(this.filteredTranslatables.map((field: Translatable): Promise<void> => field.reload()));
  });

  saveAndCloseTask = dropTask(async (): Promise<void> => {
    const { saveTask, onClose } = this.args;

    await saveTask?.perform();

    this.trackTranslationEvent();

    onClose?.();
  });
}
