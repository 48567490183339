// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { readOnly } from '@ember/object/computed';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';

import type DeliveryAreaModel from './delivery-area';

export default class DeliveryDeviceModel extends Model {
  // Pairing code for iPad

  @belongsTo('delivery-area', { async: true }) declare deliveryArea: AsyncBelongsTo<DeliveryAreaModel>;

  @attr('string', {
    defaultValue: 'Device name',
  })
  declare name: string;

  @attr('string') declare osVersion: string;
  @attr('string') declare maximumOsVersion: string;
  @attr('string') declare deviceType: string;
  @attr('string') declare appVersion: string;
  @attr('string') declare currentAppVersion: string;
  @attr('string') declare activationCode: string;
  @readOnly('activationCode') declare userToken: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'delivery-device': DeliveryDeviceModel;
  }
}
