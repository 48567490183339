import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import Changeset from 'ember-changeset';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { all, dropTask, restartableTask } from 'ember-concurrency';
import { action, get, setProperties } from '@ember/object';
import { confirmTransferTask, findAllDeliveryAreasForCompany } from 'garaje/utils/delivery-area';
import { service } from '@ember/service';
import { alias, notEmpty, filterBy, bool } from 'macro-decorators';

export default class DeliveriesLogDeliveryController extends Controller {
  @service currentAdmin;
  @service flashMessages;
  @service metrics;
  @service store;
  @service abilities;

  @tracked carriers = [];
  @tracked deliveryAreas = [];
  @tracked changeset;

  @(employeesSearcherTask().restartable())
  searchEmployeesTask;

  @(confirmTransferTask().drop())
  confirmTransferTask;

  @alias('model.delivery') delivery;
  @notEmpty('delivery.notes') hasNotes;
  @filterBy('deliveryAreas', 'enabled') activeDeliveryAreas;
  @bool('delivery.acknowledgedAt') isPickedUp;

  get readOnly() {
    const delivery = this.delivery;

    return this.abilities.cannot('edit delivery', delivery);
  }

  get carrier() {
    return this.changeset.change.carrier ?? this.delivery.carrier;
  }

  get deliveryArea() {
    return this.changeset.change.deliveryArea ?? this.delivery.deliveryArea;
  }

  get recipientEmployee() {
    return this.changeset.change.recipientEmployee ?? this.delivery.recipientEmployee;
  }

  _buildChangeset(delivery) {
    this.changeset = new Changeset(delivery);
  }

  get showNotesField() {
    return (this.hasNotes && this.readOnly) || !this.readOnly;
  }

  get sortedCarriers() {
    return this.carriers.sortBy('name');
  }

  get sortedDeliveryAreas() {
    return this.activeDeliveryAreas.sortBy('locationName', 'name');
  }

  @restartableTask
  *fetchDataTask() {
    yield all([this.fetchDeliveryAreas.perform(), this.fetchCarriers.perform()]);
  }

  @restartableTask
  *fetchCarriers() {
    const carriers = yield this.store.findAll('carrier');
    this.carriers = carriers;
  }

  @restartableTask
  *fetchDeliveryAreas() {
    const deliveryAreas = yield findAllDeliveryAreasForCompany(this.store);
    this.deliveryAreas = deliveryAreas;
  }

  updateChangeset(changes) {
    setProperties(this.changeset, changes);
  }

  @dropTask
  *updateDelivery(changes) {
    const delivery = this.delivery;
    setProperties(delivery, changes);

    try {
      yield delivery.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      delivery.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }

  @dropTask
  *saveChangeset() {
    const changeset = this.changeset;
    const origin = yield get(this.delivery, 'deliveryArea');
    const destination = get(changeset, 'change.deliveryArea');

    if (destination) {
      const confirmed = yield this.confirmTransferTask.perform({ destination, origins: [origin] });

      if (!confirmed) {
        return;
      }
    }

    try {
      yield changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this._trackUpdateDetailsSaved();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }

  _trackUpdateDetailsSaved() {
    const { delivery } = this;
    const props = this._getPropertiesForTrackedEvent(delivery);
    props.delivery_note = delivery.notes;
    this.metrics.trackEvent('Delivery Item - Update Details Saved', props);
  }

  _trackMarkAsNotCollected() {
    const { delivery } = this;
    const props = this._getPropertiesForTrackedEvent(delivery);
    this.metrics.trackEvent('Delivery Item - Mark as Not Collected Clicked', props);
  }

  _getPropertiesForTrackedEvent(delivery) {
    const deliveryArea = get(delivery, 'deliveryArea');
    const location = get(deliveryArea, 'location');
    const company = get(location, 'company');
    const recipientEmployee = get(delivery, 'recipientEmployee');
    return {
      company_id: get(company, 'id'),
      location_id: get(location, 'id'),
      delivery_area_id: get(deliveryArea, 'id'),
      delivery_id: delivery.id,
      recipient_name: get(recipientEmployee, 'name'),
      recipient_id: get(recipientEmployee, 'id'),
    };
  }

  @action
  async markAsNotCollected() {
    const result = await this.updateDelivery.perform({ status: 'pending', acknowledgedAt: null });
    this._trackMarkAsNotCollected();
    return result;
  }

  @action
  markAsPickedUp() {
    return this.updateDelivery.perform({ status: 'collected', acknowledgedAt: new Date() });
  }

  @action
  setRecipientEmployee(recipientEmployee) {
    this.updateChangeset({ recipientEmployee });
  }

  @action
  setDeliveryArea(deliveryArea) {
    this.updateChangeset({ deliveryArea });
  }

  @action
  setTrackingNumber(trackingNumber) {
    this.updateChangeset({ trackingNumber });
  }

  @action
  setCarrier(carrier) {
    this.updateChangeset({ carrier });
  }

  @action
  setAcknowledgedAt(acknowledgedAt) {
    this.updateChangeset({ acknowledgedAt });
  }

  @action
  setNotes(notes) {
    this.updateChangeset({ notes });
  }
}
