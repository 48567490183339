// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';

import type CompanyModel from './company';
import type LocationModel from './location';

class LocationsCapacityModel extends Model {
  @attr('date') declare capacityDate: Date;
  @attr('number') declare capacityLimit: number;
  @attr('number') declare onSiteCount: number;
  @attr('number') declare expectedCount: number;
  @attr('number') declare inCount: number;
  @attr('number') declare outCount: number;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;

  @computed('onSiteCount', 'expectedCount')
  get capacityUsed(): number {
    return this.onSiteCount + this.expectedCount;
  }
  @computed('capacityUsed', 'capacityLimit')
  get capacityRatio(): number {
    return this.capacityLimit >= 0 ? this.capacityUsed / this.capacityLimit : 0;
  }
  @computed('capacityRatio')
  get hasCapacity(): boolean {
    return this.capacityRatio < 1;
  }
}

export default LocationsCapacityModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'locations-capacity': LocationsCapacityModel;
  }
}
