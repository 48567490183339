import { Checkbox } from '@envoy/polarwind-react';
import { ReactNode } from 'react';
import { FieldValues, Path, useController } from 'react-hook-form';

export interface FormCheckboxProps<T extends FieldValues> {
  name: Path<T>;
  label: string;
  required?: boolean;
  disabled?: boolean;
  testId?: string;
  tooltip?: string | ReactNode;
}

export function FormCheckbox<T extends FieldValues>(props: FormCheckboxProps<T>) {
  const { field } = useController({ name: props.name, rules: { required: props.required } });

  return (
    <Checkbox
      label={props.label}
      required={props.required}
      disabled={props.disabled}
      checked={field.value}
      onChange={(value: boolean) => field.onChange(value)}
      testId={props.testId}
      tooltip={props.tooltip}
    />
  );
}
