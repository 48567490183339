import Route from '@ember/routing/route';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class WorkplaceSettingsEmployeesRegistrationRoute extends Route {
  @service flow;
  @service abilities;
  @service skinnyLocations;
  @service featureFlags;
  @service router;

  async beforeModel() {
    if (this.abilities.cannot('visit settings for workplace')) {
      this.router.transitionTo('workplace.entries');
    }
    await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model() {
    await this.flow.initService();
  }
}
