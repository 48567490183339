import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type EmployeeModel from './employee';
import type EntryModel from './entry';
import type InviteModel from './invite';
import type LocationModel from './location';
import type ReservationModel from './reservation';
import type ZoneModel from './zone';

@adapter('badge-log')
class AccessEventModel extends Model {
  // attributes
  @attr('string') declare eventId: string;
  @attr('string') declare integrationName: string;
  @attr('string') declare integrationType: string;
  @attr('date') declare messageTimestamp: Date;
  @attr('date') declare receivedAtTimestamp: Date;
  @attr('string') declare personEmail: string;
  @attr('string') declare personType: string;
  @attr('string') declare personName?: string;
  @attr('number') declare deliveryAttempt: number;
  @attr('string') declare failureReason?: string;
  @attr('string') declare portalName?: string;
  @attr('string') declare portalType?: string;
  @attr('string') declare eventType?: string;

  // relationships
  @belongsTo('entry', { async: true }) declare entry?: AsyncBelongsTo<EntryModel>;
  @belongsTo('invite', { async: true }) declare invite?: AsyncBelongsTo<InviteModel>;
  @belongsTo('reservation', { async: true }) declare reservation?: AsyncBelongsTo<ReservationModel>;
  @belongsTo('zone', { async: true }) declare zone?: AsyncBelongsTo<ZoneModel>;
  @belongsTo('location', { async: true }) declare location?: AsyncBelongsTo<LocationModel>;
  @belongsTo('employee', { async: true }) declare employee?: AsyncBelongsTo<EmployeeModel>;
}

export default AccessEventModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'access-event': AccessEventModel;
  }
}
