import { action } from '@ember/object';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import type { TaskInstance } from 'ember-concurrency';
import type EmployeeModel from 'garaje/models/employee';
import { type LocationType } from 'garaje/models/employee';
import type EmployeeLocationModel from 'garaje/models/employee-location';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import { LOCATION_TYPE_MAPPING } from 'garaje/pods/components/employees/directory/location-type-input/component';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { alias } from 'macro-decorators';

interface EmployeeWorkBoxSignature {
  Args: {
    changeset: EmployeeModel;
    employee: EmployeeModel;
    employeeLocations: Array<EmployeeLocationModel>;
    isEditModeEnabled: boolean;
    isCreate: boolean;
    disabled?: boolean;
  };
}

interface BlankLocationOption {
  id: string | null;
  nameWithCompanyName: string;
}

export default class EmployeeWorkBox extends Component<EmployeeWorkBoxSignature> {
  @service declare skinnyLocations: SkinnyLocationsService;

  @alias('skinnyLocations.currentCompanyLocations') locations!: SkinnyLocationsService['currentCompanyLocations'];

  @(employeesSearcherTask().restartable()) searchEmployeesTask!: EmployeeSearcherTask;

  blankLocationOption: BlankLocationOption = { id: null, nameWithCompanyName: '' };

  get employeeLocationOptions(): Array<SkinnyLocationModel | Partial<SkinnyLocationModel> | BlankLocationOption> {
    const employeeLocationIds = new Set(
      this.args.employeeLocations.map((employeeLocation) => {
        if (employeeLocation.belongsTo) {
          return employeeLocation.belongsTo('location').id();
        } else {
          return employeeLocation.locationId;
        }
      }),
    );

    const employeeLocations: Array<Partial<SkinnyLocationModel>> = [];
    this.locations.forEach((location: SkinnyLocationModel) => {
      if (employeeLocationIds.has(location.id)) {
        const disabled = !isEmpty(location.disabledToEmployeesAt);
        let nameWithCompanyName = location.nameWithCompanyName;
        if (disabled) {
          nameWithCompanyName += ' (Disabled)';
        }
        employeeLocations.push({
          ...location,
          id: location.id,
          disabled,
          nameWithCompanyName,
        });
      }
    });

    return [this.blankLocationOption, ...employeeLocations];
  }

  get defaultLocation(): SkinnyLocationModel | BlankLocationOption {
    const defaultLocationId = this.args.changeset.defaultLocationId;
    return (
      this.locations.find((location: SkinnyLocationModel) => location.id == defaultLocationId?.toString()) ||
      this.blankLocationOption
    );
  }

  get displayAssistants(): string {
    const assistants = this.args.employee.assistants.slice();
    return assistants ? assistants.map((assistant) => assistant.fullName).join(', ') : '';
  }

  @action
  updateDefaultLocation(selectedLocation: SkinnyLocationModel): void {
    this.args.changeset.defaultLocationId = Number(selectedLocation.id);
  }

  @action
  doSearch(term: string): TaskInstance<EmployeeModel[]> {
    const extraFilters = {
      except: this.args.employee.id,
    };

    return this.searchEmployeesTask.perform(term, extraFilters);
  }

  @action
  searchAllEmployees(term: string): TaskInstance<EmployeeModel[]> {
    const except = [];

    if (this.args.employee.id) {
      except.push(this.args.employee.id);
    }

    if (this.args.changeset.manager) {
      const manager =
        'content' in this.args.changeset.manager ? this.args.changeset.manager.content : this.args.changeset.manager;

      if (manager?.id) {
        except.push(manager.id);
      }
    }

    return this.searchEmployeesTask.perform(term, { except }, { withoutLocation: true });
  }

  displayLocationType(option: LocationType | ''): string {
    return option === '' ? '' : LOCATION_TYPE_MAPPING[option];
  }
}
