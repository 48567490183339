import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AsyncExportManagerStatus extends Component {
  @service asyncExportManager;

  @action
  cancelEntriesExport() {
    this.asyncExportManager.exportEntriesTask.last.cancel();
  }

  @action
  cancelLocationsExport() {
    this.asyncExportManager.exportLocationsTask.last.cancel();
  }

  @action
  cancelDeliveriesExport() {
    this.asyncExportManager.exportDeliveriesTask.last.cancel();
  }
}
