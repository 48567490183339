import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Task} amenityModalTask
 * @param {Function} addAmenity
 * @param {Function} removeAmenity
 * @param {Amenity} amenity
 * @param {Boolean} isEditing
 */

export default class CreateAmenityModalComponent extends Component {
  @service store;
  @service state;
  @service flashMessages;
  @service metrics;

  @localCopy('args.amenity.name') amenityName;

  @tracked amenityErrors = [];

  get disableSaveButton() {
    return !this.amenityName?.trim().length;
  }

  @action
  onAmenityNameChange(value) {
    const { amenity, isDuplicateAmenity } = this.args;
    this.amenityName = value;
    const isDuplicateName = isDuplicateAmenity(amenity, value);
    if (value.length > 25) {
      this.amenityErrors = ['Amenity names cannot be more than 25 characters'];
    } else if (isDuplicateName) {
      this.amenityErrors = ['An amenity with this name already exists'];
    } else {
      this.amenityErrors = [];
    }
  }

  setAmenityInputOnClose() {
    const { amenity, isEditing } = this.args;
    if (isEditing) {
      this.amenityName = amenity.name;
    } else {
      this.amenityName = '';
    }
  }

  @action
  exitModal() {
    const { amenityModalTask } = this.args;
    this.setAmenityInputOnClose();
    amenityModalTask.last.abort();
  }

  @dropTask
  *createAmenityTask() {
    const { addAmenity, amenitiesCount } = this.args;
    const amenity = this.store.createRecord('amenity', {
      name: this.amenityName.trim(),
      location: this.state.currentLocation,
      position: amenitiesCount + 1,
    });
    yield amenity.save();
    addAmenity(amenity);
    this.metrics.trackEvent('Created amenity', { amenityId: amenity.id });
    yield this.flashMessages.showAndHideFlash('success', 'Success!', `"${amenity.name}" amenity was created`);
  }

  @dropTask
  *deleteAmenityTask() {
    const { amenity, amenityModalTask, removeAmenity } = this.args;
    yield amenity.destroyRecord();
    yield this.flashMessages.showAndHideFlash('success', 'Success!', `"${amenity.name}" amenity was deleted`);
    this.metrics.trackEvent('Deleted amenity', { amenityId: amenity.id });
    removeAmenity(amenity);
    amenityModalTask.last.continue();
  }

  @dropTask
  *saveAmenityTask() {
    const { amenity, amenityModalTask, isEditing } = this.args;
    if (isEditing) {
      set(amenity, 'name', this.amenityName);
      yield amenity.save();
      this.metrics.trackEvent('Edited amenity', { amenityId: amenity.id });
      yield this.flashMessages.showAndHideFlash('success', 'Success!', `"${amenity.name}" amenity was saved`);
    } else {
      yield this.createAmenityTask.perform();
    }
    this.setAmenityInputOnClose();
    amenityModalTask.last.continue();
  }

  @dropTask
  *saveAndAddAnotherTask() {
    yield this.createAmenityTask.perform();
    this.setAmenityInputOnClose();
  }
}
