import Controller from '@ember/controller';
import { service } from '@ember/service';
import { reads } from 'macro-decorators';
import { action } from '@ember/object';

export default class ForbiddenController extends Controller {
  @service state;
  @reads('state.currentUser.email') userEmail;

  @action
  _logout() {
    this.send('logout');
  }
}
