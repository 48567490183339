import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask, enqueueTask } from 'ember-concurrency';
import moment from 'moment-timezone';
import { alias } from 'macro-decorators';

export default class EmployeeRegistrationIndexController extends Controller {
  @service router;
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service flow;
  @service localStorage;
  @service skinnyLocations;
  @service state;
  @service store;

  @tracked isOpen = false;
  @tracked showWelcomeBanner = false;

  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin;
  @alias('state.currentLocation') currentLocation;
  @alias('flow.globalEmployeeScreeningFlows') globalEmployeeScreeningFlows;
  @alias('flow.openedModal') openedModal;
  @alias('skinnyLocations.currentCompanyLocations') currentCompanyLocations;
  @alias('skinnyLocations.manageableByCurrentAdmin') locations;
  @alias('flow.currentSkinnyLocation') currentSkinnyLocation;
  @alias('flow.canDisableEmployeeScreeningFlow') canDisable;
  @alias('flow.canSaveEmployeeScreeningFlow') canSave;
  @alias('flow.selectedEmployeeScreeningFlow') selectedEmployeeScreeningFlow;
  @alias('flow.activeEmployeeScreeningFlow') activeEmployeeScreeningFlow;
  @alias('flow.activeOrSelectedEmployeeScreeningFlow') activeOrSelectedEmployeeScreeningFlow;
  @alias('flow.isEmployeeScreeningFlowConfigured') isEmployeeScreeningFlowConfigured;
  @alias('state.vrSubscription') subscription;

  get canHaveMultipleFlows() {
    return !!this.state.features?.canAccessMultipleFlows;
  }

  get currentLocationEnabledDesks() {
    return this.state.currentLocationEnabledDesks;
  }

  @action
  openModal(modalToOpen) {
    this.openedModal = modalToOpen;
  }

  @action
  async disableFlowForThisLocation() {
    const { flashMessages, currentSkinnyLocation, canDisable } = this;
    if (canDisable) {
      currentSkinnyLocation.employeeScreeningFlow = null;
      await currentSkinnyLocation.save();
      this.isOpen = !this.isOpen;
      flashMessages.showAndHideFlash('success', 'Saved!');
    }
  }

  @action
  async selectFlowForThisLocation() {
    const { flashMessages, currentSkinnyLocation, selectedEmployeeScreeningFlow, activeEmployeeScreeningFlow } = this;
    currentSkinnyLocation.employeeScreeningFlow = selectedEmployeeScreeningFlow;
    await currentSkinnyLocation.save();
    this.isOpen = !this.isOpen;
    flashMessages.showAndHideFlash('success', 'Saved!');
    if (activeEmployeeScreeningFlow) {
      this.openedModal = 'instructions';
    }
  }

  @action
  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  @dropTask
  *newEmployeeScreeningFlow() {
    const { currentCompany } = this.state;
    const employeeScreeningFlow = this.store.createRecord('employee-screening-flow', {
      company: currentCompany,
    });
    yield employeeScreeningFlow.save();
    this.router.transitionTo('protect.settings.employees.registration.edit', employeeScreeningFlow.id);
  }

  @enqueueTask
  *saveUserDocumentRequirementsTask(requirements) {
    const { flashMessages, currentSkinnyLocation } = this;

    currentSkinnyLocation.userDocumentRequirements = requirements;

    yield currentSkinnyLocation.save();

    flashMessages.showAndHideFlash('success', 'Saved!');
  }

  shouldShowWelcomeBanner() {
    let shouldShowBanner = false;
    try {
      let meta = this.localStorage.getItem('protect_settings_employees_meta');
      meta = meta ? JSON.parse(meta) : [];
      const { visitedDate } = meta.find((s) => s.locationId === this.state.currentLocation.id) || {};
      if (visitedDate) {
        const isRecent = moment.utc().subtract(5, 'days').isBefore(moment.utc(visitedDate));
        shouldShowBanner = isRecent;
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return shouldShowBanner;
  }

  setMetaInStorage() {
    try {
      let meta = this.localStorage.getItem('protect_settings_employees_meta');
      meta = meta ? JSON.parse(meta) : [];
      const { visitedDate } = meta.find((data) => data.locationId === this.state.currentLocation.id) || {};
      if (!visitedDate) {
        this.localStorage.setItem(
          'protect_settings_employees_meta',
          JSON.stringify([{ locationId: this.state.currentLocation.id, visitedDate: moment.utc().format() }]),
        );
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  @action
  rollbackAll() {
    const { activeEmployeeScreeningFlow } = this;
    this.selectedEmployeeScreeningFlow = activeEmployeeScreeningFlow;
  }
}
