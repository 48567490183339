import { A } from '@ember/array';
import { setProperties } from '@ember/object';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { RoomsOrderBy, SortDirection } from 'garaje/graphql/generated/roomba-types';
import type {
  GetRoomsPaginatedQuery,
  PaginationInput,
  RoomsPageFilterInput,
  RoomsSortOrder,
} from 'garaje/graphql/generated/roomba-types';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type StateService from 'garaje/services/state';
import uniqBy from 'lodash/uniqBy';

import type RoombaSettingsCalendarsController from './controller';

interface RoombaSettingsCalendarsRouteModel {
  totalCount: GetRoomsPaginatedQuery['paginatedRooms']['totalCount'];
  rooms: GetRoomsPaginatedQuery['paginatedRooms']['items'];
  unassignedRoomsCount: number;
  assignedRoomsCount: number;
}

export const ROOMS_UNASSIGNED_AT_CURRENT_LOCATION_PER_PAGE = 200;

export default class RoombaSettingsCalendarsRoute extends Route {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare state: StateService;

  setupController(
    controller: RoombaSettingsCalendarsController,
    model: RoombaSettingsCalendarsRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    setProperties(controller, {
      selectedUnassignedRooms: A(),
      selectedAssignedRooms: A(),
      rooms: model.rooms,
      totalRoomsCount: model.totalCount,
      unassignedRoomsTotalCount: model.unassignedRoomsCount,
      assignedRoomsTotalCount: model.assignedRoomsCount,
    });
  }

  async model(): Promise<RoombaSettingsCalendarsRouteModel> {
    const { currentLocation } = this.state;
    if (!currentLocation) {
      throw new Error('no current location found while loading roomba settings');
    }
    const sort: RoomsSortOrder = { by: RoomsOrderBy.DisplayName, direction: SortDirection.Asc };

    const filter: RoomsPageFilterInput = {
      locationId: { _neq: currentLocation.id, _eq: null, _in: null, _is_null: null, _nin: null },
      buildingId: null,
      buildingName: null,
      displayName: null,
      floorName: null,
    };

    const pagination: PaginationInput = {
      limit: ROOMS_UNASSIGNED_AT_CURRENT_LOCATION_PER_PAGE,
      offset: 0,
    };

    const { items: rooms, totalCount: unassignedAtCurrentLocationTotalRoomsCount } =
      await this.roombaGraphql.getRoomsPaginated(filter, sort, pagination);

    const filterAssignedRooms: RoomsPageFilterInput = {
      locationId: { _eq: currentLocation.id, _neq: null, _in: null, _is_null: null, _nin: null },
      buildingId: null,
      buildingName: null,
      displayName: null,
      floorName: null,
    };
    const { items: roomsAssignedToCurrentLocation, totalCount: assignedAtCurrentLocationTotalRoomsCount } =
      await this.roombaGraphql.getRoomsPaginated(filterAssignedRooms, sort);

    return {
      rooms: uniqBy([...rooms, ...roomsAssignedToCurrentLocation], 'id'),
      totalCount: unassignedAtCurrentLocationTotalRoomsCount + roomsAssignedToCurrentLocation.length,
      unassignedRoomsCount: unassignedAtCurrentLocationTotalRoomsCount,
      assignedRoomsCount: assignedAtCurrentLocationTotalRoomsCount,
    };
  }
}
