import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type AuthzService from 'garaje/services/authz';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type { Role } from 'garaje/utils/custom-roles';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { bool, gt } from 'macro-decorators';

interface DeleteRoleModalArgs {
  role: Role;
  closeModal: () => boolean;
  afterRoleDeleted: () => void;
  routeToAdminUsers: string;
}

export default class DeleteRoleModal extends Component<DeleteRoleModalArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare authz: AuthzService;
  @service declare router: RouterService;

  @gt('args.role.assignmentCounts.user_count', 0) roleIsAssignedToUser!: boolean;
  @bool('args.role.assignmentCounts.employee_group_role_exists') roleIsAssignedToGroup!: boolean;

  deleteRoleTask = dropTask(async () => {
    try {
      await this.authz.deactivateRole(this.args.role.id);
      this.args.closeModal();
      this.flashMessages.showAndHideFlash('success', 'Role successfully deleted!');
      this.args.afterRoleDeleted();
    } catch (error) {
      this.args.closeModal();
      let errorText = parseErrorForDisplay(error);
      if (errorText.startsWith('Request was formatted incorrectly')) {
        errorText = 'Role is still assigned to a group or user';
      }
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  close(): void {
    this.args.closeModal();
  }

  @action
  closeAndViewAssignedUsers(): void {
    this.args.closeModal();
    const queryParams = {
      rolesFilter: [
        {
          id: this.args.role.id,
          name: this.args.role.name,
          type: this.args.role.type,
        },
      ],
    };
    void this.router.transitionTo(this.args.routeToAdminUsers, { queryParams });
  }
}
