import Route from '@ember/routing/route';
import Changeset from 'ember-changeset';
import FlowValidations from 'garaje/validations/flow';
import lookupValidator from 'ember-changeset-validations';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';
import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';

export default class VisitorsSettingsVisitorTypesFlowIndexRoute extends Route {
  @service transitionConfirm;

  model() {
    const { allFlowsForCurrentLocation, currentLocation, flow } = this.modelFor('visitors.settings.visitorTypes.flow');
    const changeset = this._buildChangeset(flow);
    const visitorFlowsForCurrentLocation = allFlowsForCurrentLocation.filter(
      ({ employeeCentric, type }) => !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric,
    );

    return {
      allFlowsForCurrentLocation,
      changeset,
      flow,
      currentLocation,
      visitorFlowsForCurrentLocation,
    };
  }

  _buildChangeset(flow) {
    const validator = lookupValidator(FlowValidations);
    return new Changeset(flow, validator, FlowValidations);
  }

  @routeEvent
  routeWillChange(transition) {
    const { changeset } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (get(changeset, 'isDirty')) {
      confirmTask.perform(transition, {
        continue() {
          changeset.rollback();
        },
      });
    }
  }
}
