import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class DropdownMenuComponent extends Component {
  get style() {
    const { left, right, top, width } = this.args;
    let style = `left: ${left}px; right: ${right}px; margin-top: ${top}px;`;
    if (width) {
      style += ` width: ${width};`;
    }
    return style;
  }

  @action
  close(close) {
    close();
  }
}
