import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type FlowModel from 'garaje/models/flow';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { TransientRecord } from 'jsonapi/response';

interface SignInFlowsFlowsByLocationCardFlowRowComponentSignature {
  Args: {
    flowObj: TransientRecord<FlowModel>;
  };
}

export default class SignInFlowsFlowsByLocationCardFlowRowComponent extends Component<SignInFlowsFlowsByLocationCardFlowRowComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  toggleEnabled = task(async () => {
    const newState = !this.args.flowObj.data.enabled;
    try {
      const flow = await this.store.findRecord('flow', this.args.flowObj.id);
      flow.enabled = newState;
      await flow.save();
      this.args.flowObj.data.enabled = newState;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.args.flowObj.data.enabled = !newState;
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
