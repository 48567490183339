import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';

class Integration extends Model {
  @belongsTo('config', { async: true })
  declare config: AsyncBelongsTo<Model>;

  @attr('string')
  declare type: string;

  @attr('string')
  declare folderId: string;

  @attr('string')
  declare folderName: string;
}

export default Integration;
