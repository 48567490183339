import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import type CsvUploadModel from 'garaje/models/csv-upload';
import type ZoneModel from 'garaje/models/zone';
import type BlocklistFilterService from 'garaje/services/blocklist-filter';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type UploaderFactoryService from 'garaje/services/uploader-factory';
import { CsvUploadDestination, CsvUploadStatus } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import type { SinglePayload } from 'jsonapi/response';

const STATUS_POLL_INTERVAL = 750; // amount of time (in ms) to wait between polling calls

interface PropertyBlocklistCsvImportComponentSignature {
  Args: {
    /**
     * the property that the blocklist entries should be saved to
     */
    property: ZoneModel;
    /**
     * action triggered when upload process completes successfully. Receives no arguments.
     */
    uploadCompleted?: () => void;
  };
}

export default class PropertyBlocklistCsvImportComponent extends Component<PropertyBlocklistCsvImportComponentSignature> {
  @service('blocklistFilter') declare blocklistFilterService: BlocklistFilterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;
  @service declare uploaderFactory: UploaderFactoryService;

  @tracked importedCount = 0;

  saveBlocklistFiltersTask = task(async (csvFile: File) => {
    let csvUpload = this.store.createRecord('csv-upload');
    csvUpload.destination = CsvUploadDestination.BLOCKLIST_FILTERS;
    csvUpload.csvUploadable = this.args.property;

    const url = urlBuilder.v3.csvUpload();
    const uploader = this.uploaderFactory.createUploader({
      url,
    });
    try {
      const response = await uploader.upload<SinglePayload<CsvUploadModel>>(csvFile, {
        data: JSON.stringify((<SinglePayload<CsvUploadModel>>csvUpload.serialize()).data),
      });
      csvUpload.unloadRecord();
      this.store.pushPayload(response);
      csvUpload = this.store.peekRecord('csv-upload', response.data.id)!;
      await this.waitForUploadToFinishTask.perform(csvUpload);
      // reload blocklist filters
      await this.blocklistFilterService.queryByPropertyTask.perform(this.args.property.id);
      this.args.uploadCompleted?.();
    } catch (e) {
      csvUpload.unloadRecord();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      console.error(e); // eslint-disable-line no-console
      throw e; // re-throw the error so the base component can clear the upload
    }
  });

  waitForUploadToFinishTask = task(async (csvUpload: CsvUploadModel) => {
    let done = false;
    do {
      await timeout(zft(STATUS_POLL_INTERVAL));
      const {
        data: { attributes },
      } = await csvUpload.uploadStatus();
      const status = attributes['upload-status'];
      switch (status) {
        case CsvUploadStatus.IN_PROGRESS:
          this.importedCount = attributes['imported-count']!;
          break;
        case CsvUploadStatus.DONE:
          done = true;
          break;
        default:
          throw new Error(attributes.message);
      }
    } while (!done);
  });
}
