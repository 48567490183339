import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';

/**
 * @param {String}                isDeferedToFlowSettings
 * @param {Class<Flow>}           flow
 * @param {Function}              onChange
 * @param {Boolean}               readOnly
 * @param {Boolean}               unchanged
 */
export default class WelcomeEmail extends Component {
  @service flashMessages;
  @service metrics;

  @tracked isLoading = false;
  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;

  @dropTask
  *updateFlowWelcomeEmailConfigAndSave() {
    try {
      const hasDirtyWelcomeEmailPreference = this.args.flow.hasDirtyWelcomeEmailPreference();
      if (hasDirtyWelcomeEmailPreference) {
        yield this.args.flow.save();
        this.metrics.trackEvent('Welcome Email Edited - Flow', {
          flow_id: this.args.flow.id,
          flow_name: this.args.flow.name,
          recipients_edited: hasDirtyWelcomeEmailPreference,
        });
      }
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showFlash('error', e);
    }
  }

  @action
  onContinue() {
    if (this.args.flow.hasDirtyAttributes) {
      this.args.flow.rollbackAttributes();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
    this.args.onChange({ hasChanges: false });
  }

  @action
  cancel() {
    if (this.args.flow.hasDirtyAttributes) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.onContinue();
    }
  }

  @action
  changePreference(value) {
    set(this.args, 'flow.welcomeEmailPreference', value);
    this.args.onChange({ hasChanges: true });
  }
}
