import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { AnalyticsEventNames } from 'garaje/utils/enums';

import type AnalyticsCustomDashboardsController from './controller';

export default class AnalyticsCustomDashboardsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare state: StateService;

  title = 'Analytics · Custom Dashboards';

  beforeModel(): void {
    if (this.abilities.cannot('visit custom dashboards analytics')) {
      this.router.transitionTo('unauthorized');
    }
  }

  afterModel(): void {
    this.logMetrics();
  }

  logMetrics(): void {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
      visitorsSubscription,
      workplaceSubscription,
    } = this.state;

    const details = {
      companyId,
      locationId,
      app: visitorsSubscription?.app || workplaceSubscription?.app,
      plan: visitorsSubscription?.plan || workplaceSubscription?.plan,
      userId: this.currentAdmin?.id || '',
      employeeId: this.currentAdmin?.employee?.id || '',
    };
    this.metrics.trackEvent(AnalyticsEventNames.CUSTOM_DASHBOARDS_VISITED, details);
    this.metrics.logMonitorEvent(AnalyticsEventNames.CUSTOM_DASHBOARDS_VISITED, details);
  }

  resetController(controller: AnalyticsCustomDashboardsController, isExiting: boolean, transition: Transition): void {
    super.resetController(controller, isExiting, transition);

    if (isExiting) {
      controller.page = 1;
    }
  }
}
