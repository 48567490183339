import type ArrayProxy from '@ember/array/proxy';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type InfinityService from 'ember-infinity/services/infinity';
import type GroupModel from 'garaje/models/group';
import type { PromiseArray } from 'garaje/utils/type-utils';
import { hash } from 'rsvp';

import type LocationOverviewSignInFlowsController from './controller';

interface LocationOverviewSignInFlowsIndexRouteModel {
  groups: ArrayProxy<GroupModel> | GroupModel[];
}

export default class extends Route {
  @service declare abilities: AbilitiesService;
  @service declare store: StoreService;
  @service declare infinity: InfinityService;

  model(): Promise<LocationOverviewSignInFlowsIndexRouteModel> {
    let groups: PromiseArray<GroupModel, ArrayProxy<GroupModel>> | GroupModel[] = [];

    if (this.abilities.can('manage locations-grouping')) {
      groups = this.store.findAll('group');
    }

    return hash({
      groups,
    });
  }

  @action
  loading(transition: Transition): void {
    const controller = <LocationOverviewSignInFlowsController>(
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controllerFor('location-overview/sign-in-flows/index')
    );
    controller.loadingData = true;
    void transition.promise.finally(function () {
      controller.loadingData = false;
    });
  }
}
