import Service from '@ember/service';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { addDays, isSameDay, fromUnixTime } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

class RegistrationsService extends Service {
  @service scheduleGraphql;
  @service state;
  @service abilities;

  /* we have an array of registrations, one for each day
     we can do infinite loading, such as on the /schedule route
     we can use it as a cache for loading, such as on the /schedule/floors route
     and we can update them from wherever we make changes */
  @tracked days = [];

  /* we encapsulate graphql calls and updates to the `days` array in the following functions
     the argument pattern is `(date, argumentsObject)` */

  async findDay(date) {
    const day = this.days.find((day) => {
      return isSameDay(day.date, date);
    });

    return day || this.scheduleGraphql.fetchRegistrationByDate({ date });
  }

  async findReservation(reservationId) {
    return this.scheduleGraphql.fetchReservationById({ reservationId });
  }

  async unschedule(date, { reservation }) {
    const { screeningCard } = await this.findDay(date);

    await this.scheduleGraphql.unschedule({ reservationId: reservation?.id, inviteId: screeningCard.id });
    this.updateRegistration(date, (day) => ({
      ...day,
      reservation: null,
      screeningCard: null,
      peopleRegistered: Math.max(0, day.peopleRegistered - 1),
    }));

    return { invite: screeningCard, reservation };
  }

  async unschedulePartialDay(date, { reservationIds }) {
    const { screeningCard } = await this.findDay(date);
    await this.scheduleGraphql.unschedulePartialDay({ reservationIds, inviteId: screeningCard.id });
    this.updateRegistration(date, (day) => ({
      ...day,
      reservations: [],
      screeningCard: {
        ...screeningCard,
        id: null,
        __typename: 'SelfCertify',
      },
      peopleRegistered: Math.max(0, day.peopleRegistered - 1),
    }));

    return { invite: screeningCard, reservationIds };
  }

  async releaseDeskReservation({ reservation }) {
    await this.scheduleGraphql.releaseDeskReservation({ reservationId: reservation.id });
    this.updateRegistration(this.reservationDate(reservation), (day) => {
      return {
        ...day,
        reservation: null,
      };
    });
  }

  async releaseDeskPartialDay({ reservation }) {
    await this.scheduleGraphql.releaseDeskReservation({ reservationId: reservation.id });
    this.updateRegistration(this.reservationDate(reservation), (day) => {
      const reservations = day.reservations.filter((res) => res.id !== reservation.id);
      return {
        ...day,
        reservations,
      };
    });
  }

  async reserveDesk(date) {
    const { screeningCard } = await this.findDay(date);
    const { invite, reservation } = await this.scheduleGraphql.createInviteReservation({
      screeningCard,
      date,
      inviteId: screeningCard?.id,
    });

    this.updateRegistration(date, (day) => ({
      ...day,
      reservation,
      screeningCard: invite,
      peopleRegistered: day.peopleRegistered + (screeningCard ? 0 : 1),
    }));

    return { invite, reservation };
  }

  async reserveDeskPartialDay(date) {
    const { screeningCard } = await this.findDay(date);
    const { invite, reservation } = await this.scheduleGraphql.createInviteReservation({
      screeningCard,
      date,
      inviteId: screeningCard?.id,
    });
    this.updateRegistration(date, (day) => ({
      ...day,
      reservations: reservation ? [...day.reservations, reservation] : day.reservations,
      screeningCard: invite,
      peopleRegistered: day.peopleRegistered + (screeningCard ? 0 : 1),
    }));

    return { invite, reservation };
  }

  async changeDesk(date, { currentDeskId, newDeskId, reservationId }) {
    const reservation = await this.scheduleGraphql.changeDesk({ currentDeskId, newDeskId, reservationId });

    this.updateRegistration(date, (day) => ({
      ...day,
      reservation,
    }));

    return reservation;
  }

  async changeDeskPartialDay(date, { currentDeskId, newDeskId, reservationId }) {
    const reservation = await this.scheduleGraphql.changeDesk({ currentDeskId, newDeskId, reservationId });
    this.updateRegistration(date, (day) => {
      const updatedReservations = day.reservations.filter((res) => res.id !== reservationId);
      return {
        ...day,
        reservations: [...updatedReservations, reservation],
      };
    });

    return reservation;
  }

  async checkInReservation(date, { reservationId }) {
    const reservation = await this.scheduleGraphql.checkInReservation({ reservationId });
    this.updateRegistration(date, (day) => {
      const otherReservations = day.reservations.filter((res) => res.id !== reservationId && !res.checkInTime);
      return {
        ...day,
        reservations: [...otherReservations, reservation].sort((a, b) => a.startTime - b.startTime),
      };
    });
  }

  async checkOutReservation(date, { reservationId }) {
    await this.scheduleGraphql.releaseDeskReservation({ reservationId });
    this.updateRegistration(date, (day) => {
      const reservations = day.reservations.filter((res) => res.id !== reservationId);
      return {
        ...day,
        reservations: reservations,
      };
    });
  }

  async signInInvite(date) {
    const { reservation, screeningCard } = await this.findDay(date);

    const [entry] = await this.scheduleGraphql.signInInvite({
      inviteId: screeningCard.id,
      reservationId: reservation?.id,
    });

    this.updateRegistration(date, (day) => ({
      ...day,
      screeningCard: {
        ...day.screeningCard,
        entry,
        entryId: entry.id,
      },
    }));

    return entry;
  }

  async signInInvitePartialDay(date) {
    const { reservations, screeningCard } = await this.findDay(date);

    const [entry] = await this.scheduleGraphql.signInInvite({
      inviteId: screeningCard.id,
      reservationId: reservations[0]?.id,
    });
    const updatedReservation = {
      ...reservations[0],
      checkInTime: new Date(),
    };
    const newResArray = reservations.filter((res) => res.id !== reservations[0].id);
    newResArray.unshift(updatedReservation);

    this.updateRegistration(date, (day) => ({
      ...day,
      reservations: newResArray,
      screeningCard: {
        ...day.screeningCard,
        entry,
        entryId: entry.id,
      },
    }));

    return entry;
  }

  async signOutEntry(date) {
    const day = await this.findDay(date);

    const entry = await this.scheduleGraphql.signOutEntry({
      entryId: day.screeningCard.entryId,
      reservationId: day.reservation?.id,
      signedOutAt: new Date(),
    });

    this.updateRegistration(date, (day) => ({
      ...day,
      screeningCard: null,
      reservation: null,
    }));

    return entry;
  }

  async signOutEntryPartialDay(date) {
    const day = await this.findDay(date);

    const entry = await this.scheduleGraphql.signOutEntry({
      entryId: day.screeningCard.entryId,
      signedOutAt: new Date(),
    });

    this.updateRegistration(date, (day) => ({
      ...day,
      screeningCard: {
        ...day.screeningCard,
        id: null,
        __typename: 'SelfCertify',
      },
      reservations: [],
    }));

    return entry;
  }

  /* This could be called from outside the service, but we prefer creating functions within `registrations` that call graphql then call this */
  async updateRegistration(date, fn) {
    const updatedDays = this.days.map((day) => {
      if (isSameDay(day.date, date)) {
        return fn(day);
      } else {
        return day;
      }
    });
    this.days = updatedDays;
  }

  /*
    POPULATING THE DAYS ARRAY
    We call `loadMoreTask` whenever we want to add more records to the `days` array
    We call `dumpRecords` whenever we need to get rid of old records (for example, changing location); we'd typically call `loadMoreTask` right after
    `fetchMore` and `nextDay` should become private once we upgrade to babel-core 7.2 or above https://babeljs.io/blog/2018/12/03/7.2.0#private-instance-methods-8654httpsgithubcombabelbabelpull8654
  */

  loadMoreTask = task({ enqueue: true }, async (length = 30) => {
    const newRegistrations = await this.fetchMore({ startDate: this.nextDay, length: length });
    this.days = [...this.days, ...newRegistrations];
  });

  reservationDate(reservation) {
    // backend returns in seconds since Jan 1 1970
    return this.formatToLocationTimezone(fromUnixTime(reservation.startTime));
  }

  dumpRecords() {
    this.days = [];
  }

  formatToLocationTimezone(date) {
    if (!date) {
      return undefined;
    }

    const { timezone } = this.state.currentLocation;

    if (!timezone) {
      return new Date(date);
    }

    const utc = new Date(date).toUTCString();
    const locationTime = toZonedTime(new Date(utc), timezone);
    return locationTime;
  }

  async fetchMore({ startDate, length = 30 }) {
    if (this.abilities.can('use partial day booking desk')) {
      const response = await this.scheduleGraphql.fetchPartialDayRegistrations({ startDate, length });
      return response.registrationDates.map((rd) => ({
        ...rd,
        date: this.formatToLocationTimezone(rd.date),
      }));
    } else {
      const response = await this.scheduleGraphql.fetchRegistrations({ startDate, length });

      return response.registrationDates.map((rd) => ({
        ...rd,
        date: this.formatToLocationTimezone(rd.date),
      }));
    }
  }

  get nextDay() {
    const days = this.days;
    const hasDays = days.length > 0;
    if (hasDays) {
      const lastDay = days[days.length - 1];
      return addDays(new Date(lastDay.date), 1);
    } else {
      return this.formatToLocationTimezone(new Date());
    }
  }
}

export default RegistrationsService;
