import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { get, computed } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { gt, gte, equal } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { AsyncBelongsTo, AsyncHasMany, SyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { apiAction } from '@mainmatter/ember-api-actions';
import Agreeable from 'garaje/models/agreeable';
import type AgreeableNdaModel from 'garaje/models/agreeable-nda';
import type ApprovalStatusModel from 'garaje/models/approval-status';
import type AttendeeModel from 'garaje/models/attendee';
import type EmployeeModel from 'garaje/models/employee';
import type EntryModel from 'garaje/models/entry';
import type FlowModel from 'garaje/models/flow';
import type GroupInviteModel from 'garaje/models/group-invite';
import type LocationModel from 'garaje/models/location';
import type NotificationLogModel from 'garaje/models/notification-log';
import type ParentInviteContextModel from 'garaje/models/parent-invite-context';
import type PlatformJobModel from 'garaje/models/platform-job';
import type RecurringInviteModel from 'garaje/models/recurring-invite';
import type ReservationModel from 'garaje/models/reservation';
import type SignedAgreementsJobModel from 'garaje/models/signed-agreements-job';
import type UserModel from 'garaje/models/user';
import type UserDatum from 'garaje/models/user-datum';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type { Identifier } from 'garaje/models/user-document-template';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import type AjaxService from 'garaje/services/ajax';
import type CurrentLocationService from 'garaje/services/current-location';
import adapter from 'garaje/utils/decorators/adapter';
import embeddedBelongsTo from 'garaje/utils/embedded-belongs-to';
import { BlocklistFilterSource, FLOW_TYPE } from 'garaje/utils/enums';
import type PlainObject from 'garaje/utils/plain-object';
import type { RecordArray } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import { alias, not, filterBy, sortBy } from 'macro-decorators';

@adapter('v2')
class InviteModel extends Agreeable {
  @service declare ajax: AjaxService;
  @service declare currentLocation: CurrentLocationService;
  @service declare store: StoreService;

  @belongsTo('attendee', { async: true }) declare attendee: AsyncBelongsTo<AttendeeModel>;
  @belongsTo('user', { async: true }) declare creator: AsyncBelongsTo<UserModel>;
  @belongsTo('entry', { async: true }) declare entry: AsyncBelongsTo<EntryModel> | null;
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel> | EmployeeModel;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('recurring-invite', { async: true }) declare recurringInvite: AsyncBelongsTo<RecurringInviteModel>;
  @belongsTo('signed-agreements-job', { async: true })
  declare signedAgreementsJob: AsyncBelongsTo<SignedAgreementsJobModel>;
  /**
   * Read only. API does not actually read from this field to update the flow. UI might still update in a few places in order to keep in sync.
   */
  @belongsTo('flow', { async: true }) declare flow: AsyncBelongsTo<FlowModel> | FlowModel | undefined;
  @belongsTo('parent-invite-context', { async: false })
  declare parentInviteContext: AsyncBelongsTo<ParentInviteContextModel>;
  @belongsTo('group-invite', { async: false }) declare groupInvite: GroupInviteModel;

  @hasMany('employee', { async: false, inverse: null }) declare additionalHosts:
    | AsyncHasMany<EmployeeModel>
    | EmployeeModel[];
  @hasMany('location', { async: false, inverse: null }) declare childInviteLocations?:
    | AsyncHasMany<LocationModel>
    | LocationModel[]
    | null;
  @hasMany('notification-log', { async: false, inverse: null })
  declare multiTenancyVisitorNotificationLogs: SyncHasMany<NotificationLogModel>;
  @hasMany('platform-job', { async: true }) declare platformJobs: AsyncHasMany<PlatformJobModel>;
  @hasMany('reservation', { async: true }) declare reservations: AsyncHasMany<ReservationModel>;
  @hasMany('visitor-document', { async: false }) declare visitorDocuments:
    | SyncHasMany<VisitorDocumentModel>
    | RecordArray<VisitorDocumentModel>
    | NativeArray<VisitorDocumentModel>;

  @attr('boolean', { defaultValue: false }) declare preregistrationComplete: boolean;

  @attr('string') declare fullName: string;
  @attr('string') declare email: string;
  @attr('string') declare inviterName: string;
  @attr('date') declare expectedArrivalTime: Date;
  @attr('date') declare endTime: Date;
  @attr('date') declare checkedInAt: Date;
  @attr('string') declare recurringRule: string | null;
  @attr('string') declare rruleUntil: string | null;
  @attr('string') declare privateNotes: string;
  @attr('string') declare propertyNotes: string;
  @attr('boolean') declare beenHereBefore: boolean;
  @attr('number', { defaultValue: () => 0 }) declare additionalGuests: number;
  @attr('boolean', { defaultValue: false }) declare skipGuestNotification: boolean;
  @attr() declare nda: unknown;
  @attr() declare guestUpdatedAt: string;
  @attr('string') declare agreementsStatus: string;
  @attr() declare originalNdaSignDate: unknown;
  @attr() declare ndaAvailable: unknown;
  @attr('locality', { defaultValue: () => ({ placeId: '' }) }) declare locality: string;
  @attr('string') declare flowName: string;
  @attr('string') declare groupName: string;
  @attr('boolean', { defaultValue: false }) declare employeeScreeningFlow: boolean;
  @attr('boolean') declare arrived: boolean;
  @attr('userData', { defaultValue: () => [] }) declare userData: NativeArray<UserDatum>;
  @attr('string') declare photoUrl: string;
  @attr('array', { defaultValue: () => [] }) declare locationNames: string[];
  @attr('date') declare createdAt: Date;

  @gt('additionalGuests', 0) hasAdditionalGuests!: boolean;

  @embeddedBelongsTo('approval-status') declare approvalStatus?: ApprovalStatusModel;
  @gte('approvalStatus.failedReport.length', 1) didFailApprovalCheck!: boolean;
  @equal('currentApprovalStatus', 'denied') approvalWasDenied!: boolean;
  @equal('currentApprovalStatus', 'approved') approved!: boolean;
  @equal('currentApprovalStatus', 'pending') pending!: boolean;
  @equal('currentApprovalStatus', 'review') needsApprovalReview!: boolean;
  @alias('employeeScreeningFlow') isFromEmployeeScreening!: this['employeeScreeningFlow'];
  @alias('walkUpFlow') isFromWalkUp!: this['walkUpFlow'];

  // SQ-4574: Invert boolean in model to set same value in component
  @not('skipGuestNotification') sendGuestEmail!: boolean;

  // SQ-4727 + SQ-1723: Use Entry's approval status if available
  @computed('approvalStatus.status', 'entry.{id,approvalStatus.status}')
  get currentApprovalStatus(): string {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.entry?.get('approvalStatus')?.status || this.approvalStatus?.status || '';
  }

  @computed('currentLocation.location.employeeScreeningFlow', 'flow', 'flowName', 'isDestroyed', 'isDestroying')
  get walkUpFlow(): boolean {
    // This attribute seems to still get read by the `<Invites::SinglePrereg>` component during its teardown (in tests, at least).
    // The result of that is that this getter causes an error:
    // > Attempted to call store.peekAll(), but the store instance has already been destroyed
    // By bailing out if this is destroying/destroyed we avoid the error.
    if (this.isDestroying || this.isDestroyed) return false;

    // Refactor this once we can pull `PROPERTY_WALKUP` flow from the API.
    const localEmployeeScreeningFlow =
      this.store.peekAll('flow').findBy('employeeCentric', true) ||
      this.currentLocation.location?.employeeScreeningFlow;

    const isWalkup =
      localEmployeeScreeningFlow &&
      'type' in localEmployeeScreeningFlow &&
      localEmployeeScreeningFlow?.type === FLOW_TYPE.PROPERTY_WALKUP;

    return isWalkup || this.flowName === 'Property walk-up';
  }

  @computed('userData.@each.field', 'entry.userData.@each.field', 'inviterName')
  get host(): string {
    // eslint-disable-next-line ember/no-get
    const userData = this.entry ? <NativeArray<UserDatum>>get(this.entry, 'userData') || this.userData : this.userData;
    const hostField = userData.findBy('field', 'Host');
    // If there isn't any data inside the `userData` we will fallback it to the `inviterName`
    return hostField && isPresent(hostField.value) ? hostField.value : this.inviterName;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('platformJobs.@each.{pluginCategory,status}')
  get categorizedPlatformJobs(): Promise<Record<string, PlatformJobModel[]>> {
    if (this.platformJobs.isDestroyed) return Promise.resolve({});

    return this.platformJobs.then((platformJobs) => {
      return platformJobs.reduce<Record<string, PlatformJobModel[]>>((categorizedJobs, job) => {
        const category = job.pluginCategory;

        if (category && category !== 'nda') {
          categorizedJobs[category] = (categorizedJobs[category] || []).concat(job);
        }

        return categorizedJobs;
      }, {});
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('platformJobs.@each.{pluginCategory,status}')
  get ndaPlatformJobs(): Promise<Record<string, PlatformJobModel[]>> {
    if (this.platformJobs.isDestroyed) return Promise.resolve({});

    return this.platformJobs.then((platformJobs) => {
      return platformJobs.reduce<Record<string, PlatformJobModel[]>>((ndaJobs, job) => {
        const category = job.pluginCategory;

        if (category && category === 'nda') {
          ndaJobs[job.pluginName] = (ndaJobs[job.pluginName] || []).concat(job);
        }

        return ndaJobs;
      }, {});
    });
  }

  get needsLocationApprovalReview(): boolean {
    if (this.approvalStatus?.status !== 'review') return false;

    return this.approvalStatus?.failedReport.some((report) => {
      if (!report.reviewable || ['approved', 'denied'].includes(report.status)) return false;
      if (report.source === 'blacklist') {
        // @TODO: isn't this checking against itself if blacklistFilterSource is falsy?
        const blocklistFilterSource = report.blacklistFilterSource ?? BlocklistFilterSource.LOCATION;
        return blocklistFilterSource === BlocklistFilterSource.LOCATION;
      }
      return true;
    })
      ? true
      : false;
  }

  get needsPropertyApprovalReview(): boolean {
    if (!this.needsApprovalReview) return false;

    return this.approvalStatus?.failedReport.some((report) => {
      if (!report.reviewable || ['approved', 'denied'].includes(report.status)) {
        return false;
      }
      if (report.source === 'blacklist') {
        return report.blacklistFilterSource === BlocklistFilterSource.PROPERTY;
      }
      return false;
    })
      ? true
      : false;
  }

  @filterBy('multiTenancyVisitorNotificationLogs', 'successful') successfulLogs!: NotificationLogModel[];
  @sortBy('successfulLogs', 'createdAt:desc') sortedSuccessfulLogs!: NotificationLogModel[];

  get latestNotificationLog(): NotificationLogModel | undefined {
    return A(this.sortedSuccessfulLogs).firstObject;
  }

  get visitorEmail(): string {
    // eslint-disable-next-line ember/no-get
    const providedEmail = <string>get(this, 'entry.email');

    return providedEmail || this.email;
  }

  get groupInviteId(): string {
    // this needs to be explicitly typed here for some reason
    return (<InviteModel>this).belongsTo('groupInvite').id();
  }

  get isInGroupInvite(): boolean {
    // If this getter is called on a destroyed/destroying model instance (which happens in some tests, but not in the real app)
    // the call to `groupInviteId` (in particular its call to `.belongsTo()`) triggers an asserttion failure:
    // Assertion Failed: <model::invite:1> is not a record instantiated by @ember-data/store
    if (this.isDestroying || this.isDestroyed) return false;

    return Boolean(this.groupInviteId);
  }

  approveInvite(): Promise<unknown> {
    return this.reviewInvite({
      action: 'approve',
    });
  }

  denyInvite(): Promise<unknown> {
    return this.reviewInvite({
      action: 'deny',
    });
  }

  async printBadge(): Promise<unknown> {
    // If we are signed in, reprint the badge otherwise preprint it
    const entry = await this.entry;
    if (entry && entry.signInTime) {
      return entry.reprintBadge();
    } else {
      return this.ajax.request(urlBuilder.v1.printBadgesUrl(this.currentLocation.location.id), {
        type: 'POST',
        data: {
          invite_ids: [this.id],
        },
      });
    }
  }

  notifyInvite(data: PlainObject<unknown> | undefined): Promise<void> {
    return this.ajax.request(urlBuilder.v3.invites.sendNotification(this.id), {
      contentType: 'application/json',
      data,
      type: 'POST',
    });
  }

  visitorDocumentForTemplate(userDocumentTemplate: UserDocumentTemplateModel): VisitorDocumentModel | undefined {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return this.visitorDocumentForIdentifier(userDocumentTemplate.get('identifier'));
  }

  visitorDocumentForIdentifier(identifier: Identifier): VisitorDocumentModel | undefined {
    return this.visitorDocuments.findBy('identifier', identifier);
  }

  async reviewInvite(options: { action: 'approve' | 'deny' }): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'review', data: options });
  }

  declare agreeableNdas: SyncHasMany<AgreeableNdaModel>;
}

export default InviteModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    invite: InviteModel;
  }
}
