import ImporterService from './importer';
import { service } from '@ember/service';
import { getProperties } from '@ember/object';
import { task } from 'ember-concurrency';

export default class DesksCsvImporterService extends ImporterService {
  @service state;

  @task
  *handleImport(data) {
    const props = yield this._parseProps(data);

    return props;
  }

  _parseProps(data) {
    return getProperties(data, 'floorName', 'neighborhood', 'desk', 'enabled', 'assignedTo', 'amenities');
  }
}
