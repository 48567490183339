/* eslint-disable ember/no-component-lifecycle-hooks */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, set } from '@ember/object';
import { service } from '@ember/service';

import { loadGoogleMaps } from 'garaje/utils/script-loader';

/**
 * Render location position in a map
 * @param {Array<SkinnyLocation|Location>}         locations           List of locations to be rendered
 */

export default class LocationsMapComponent extends Component {
  @service localStorage;
  @service windowLocation;

  /**
   * @type {google.maps.Map}
   */
  map = null;

  /**
   * @type {Array<google.maps.Marker>}
   */
  markers = [];

  @computed('locations.@each.{latitude,longitude}')
  get locationsWithPosition() {
    return this.locations.filter((location) => location.latitude && location.longitude);
  }

  didInsertElement() {
    super.didInsertElement(...arguments);
    this._insertMap();
  }
  didUpdateAttrs() {
    super.didUpdateAttrs(...arguments);
    this._removeMarkerListeners(this.markers);
    this._insertMap();
  }

  willDestroyElement() {
    this._removeMarkerListeners(this.markers);
    super.willDestroyElement(...arguments);
  }

  async _insertMap() {
    if (this.locationsWithPosition.length < 1) {
      return;
    }
    await loadGoogleMaps();
    if (!window.google || !window.google.maps) {
      return;
    }
    const container = this.element.getElementsByClassName('locations-map-canvas')[0];
    const options = {
      disableDefaultUI: true,
      draggable: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
    };

    const map = new window.google.maps.Map(container, options);
    const markers = [];
    this.locationsWithPosition.forEach((location) => {
      const infowindow = new google.maps.InfoWindow({ content: location.nameWithCompanyName });
      const url = `https://envoy-public-assets.s3.amazonaws.com/icons/map-pin-${
        location.disabled ? 'grey' : 'red'
      }.png`;
      const marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(location.latitude, location.longitude),
        map,
        icon: { url, scaledSize: new window.google.maps.Size(22, 31) },
      });
      window.google.maps.event.addListener(marker, 'mouseover', () => infowindow.open(map, marker));
      window.google.maps.event.addListener(marker, 'mouseout', () => infowindow.close(map, marker));
      window.google.maps.event.addListener(marker, 'click', () => {
        this.localStorage.setItem('current_location', location.id);
        this.windowLocation.assign('/');
      });
      markers.push(marker);
    });
    map.fitBounds(this._generateBounds(markers));
    set(this, 'map', map);
    set(this, 'markers', markers);
  }

  /**
   * Center/Set Zoom of `map` to cover all visible Markers (locations)
   * @param {Array<google.maps.Marker>}   markers
   */
  _generateBounds(markers) {
    const bounds = new window.google.maps.LatLngBounds();
    markers.forEach((marker) => bounds.extend(marker.getPosition()));
    return bounds;
  }

  /**
   * Clears all listeners added to the markers
   * @param {Array<google.maps.Marker>}   markers
   */
  _removeMarkerListeners(markers) {
    if (!window.google || !window.google.maps.event.clearListeners) {
      return;
    }
    markers.forEach((marker) => {
      window.google.maps.event.clearListeners(marker, 'mouseover');
      window.google.maps.event.clearListeners(marker, 'mouseout');
      window.google.maps.event.clearListeners(marker, 'click');
    });
  }
}
