import Component from '@glimmer/component';

/**
 * Enumerate valid tailwind color classe names so that PurgeCSS does not remove them
 */
const SAFE_CLASSES = [
  // carbon
  'bg-carbon-10',
  'text-carbon-60',
  'fill-carbon-30',

  // pistachio
  'bg-pistachio-10',
  'text-pistachio-60',
  'fill-pistachio-30',

  // cilantro
  'bg-cilantro-10',
  'text-cilantro-60',
  'fill-cilantro-30',

  // papaya
  'bg-papaya-10',
  'text-papaya-60',
  'fill-papaya-30',

  // gem
  'bg-gem-10',
  'text-gem-60',
  'fill-gem-30',
];

function validClassName(className) {
  if (!SAFE_CLASSES.includes(className)) {
    throw `<Ui::Classic::Pillbox> Attempted to use ${className} which does not appear in the SAFE_CLASSES whitelist`;
  }
  return className;
}

export default class PillboxComponent extends Component {
  get color() {
    return this.args.color ?? 'carbon';
  }

  get fillClassName() {
    return validClassName(`fill-${this.color}-30`);
  }

  get bgClassName() {
    return validClassName(`bg-${this.color}-10`);
  }

  get textClassName() {
    return validClassName(`text-${this.color}-60`);
  }
}
