import AbstractSignInFieldActionRuleGroupModel from 'garaje/models/abstract/abstract-sign-in-field-action-rule-group';
import { belongsTo, hasMany } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-action-rule-group')
class GlobalSignInFieldActionRuleGroupModel extends AbstractSignInFieldActionRuleGroupModel {
  @belongsTo('global-flow', { async: true }) flow;
  @hasMany('global-sign-in-field-action', { async: true }) signInFieldActions;
  @hasMany('global-sign-in-field-actions-contact', { async: true }) signInFieldActionsContacts;
}

export default GlobalSignInFieldActionRuleGroupModel;
