import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type StateService from 'garaje/services/state';
import { GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, SECURITY } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const CAN_REVIEW_ROLES = [GLOBAL_ADMIN, LOCATION_ADMIN, RECEPTIONIST, SECURITY];

export default class IdScanningAbility extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare state: StateService;

  get canEnable(): boolean {
    return !!this.state.features?.canAccessIdScanning;
  }

  get canReview(): boolean {
    const { roleNames: roles } = this.currentAdmin;
    return isPresent(_intersection(CAN_REVIEW_ROLES, roles));
  }
}
