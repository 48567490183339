import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class SettingsVisitorEntrancesIndexRoute extends Route {
  @service state;
  @service store;

  async model() {
    const { currentLocation } = this.state;
    const visitorEntrances = await this.store.query('visitor-entrance', { filter: { location: currentLocation.id } });
    return hash({ visitorEntrances });
  }
}
