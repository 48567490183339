import Component from '@glimmer/component';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action, set } from '@ember/object';
import { task, dropTask, lastValue } from 'ember-concurrency';
import { empty } from 'macro-decorators';
import { APP } from 'garaje/utils/enums';

/**
 * Form for downgrading a subscription
 * @param {Class<Subscription>} subscription A subscription model
 * @param {Class<Plan>}         plan         A plan model
 * @param {String}              planName     The user-facing plan name we are downgrading to
 * @param {Array<String>}       activeUnits  List of names of active units (locations/delivery areas/etc)
 * @param {Function}            onDowngrade  A function to run after the subscription has been downgraded
 * @param {String}              title        Title of component
 * @param {String}              trackingId   Unique id for analytics tracking
 * @param {String}              viaLabel     Text on the CTA that brought user here
 * @param {String}              viaObject    Type of CTA that brought user here (button, text_link)
 */

export default class BillingDowngrade extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  @lastValue('fetchEstimateTask') estimate;
  @empty('estimate') isLoading;

  @action
  onInsert() {
    this.fetchEstimateTask.perform();
    this.trackModalEnteredTask.perform();
  }

  @task
  *fetchEstimateTask() {
    try {
      const { app } = this.args.subscription;
      let { period } = this.args.subscription;
      if (this.args.plan.id === 'basic') {
        period = app === 'rooms' ? 'monthly' : 'weekly';
      }

      return yield this.store
        .createRecord('subscription-estimate', {
          app: this.args.subscription.app,
          quantity:
            this.args.plan.id === 'basic' && this.args.subscription.app != 'rooms'
              ? 1
              : this.args.subscription.quantity,
          plan: this.args.subscription.app === APP.VISITORS ? this.args.plan.id : this.args.planName,
          period,
          subscription: this.args.subscription,
        })
        .save();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *handleSubmitTask(event) {
    event.preventDefault();

    if (this.fetchEstimateTask.isRunning) {
      return;
    }

    try {
      set(this.args.subscription, 'downgradePlan', this.args.plan);
      if (this.args.plan === 'basic') {
        set(this.args.subscription, 'period', 'weekly');
      }
      yield this.args.subscription.save();
      yield this.args.subscription.reload();
      this.flashMessages.showAndHideFlash('success', 'Downgrade scheduled');
      this.args.onDowngrade();
      this.metrics.trackEvent('Account modified', {
        product: this.args.subscription.app,
        account_change_type: 'subscription',
        account_change_object: 'plan',
        account_change_action: 'downgrade',
        object_change_from: this.args.subscription.normalizedPlanName,
        object_change_to: this.args.planName,
      });
    } catch (e) {
      this.args.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *trackModalEnteredTask() {
    yield this.metrics.trackPage('Modal entered', {
      action_id: this.args.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.args.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'downgrade', // purpose of modal
      via_object: this.args.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.args.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
      product: this.args.subscription.app, // Visitor, desk, rooms, etc
      object_change_from: this.args.subscription.normalizedPlanName, // Current plan
      object_change_to: this.args.planName, // New plan (intended)
    });
  }
}
