import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type AreaMapModel from 'garaje/models/area-map';
import adapter from 'garaje/utils/decorators/adapter';

import type DeskModel from './desk';
import type EmployeeModel from './employee';

@adapter('maps')
class DraftModel extends Model {
  // Attributes
  @attr('string') declare name: string;
  @attr('string') declare sharingStrategy: string;
  @attr('number') declare createdBy: number;
  @attr('number') declare lastUpdatedBy: number;
  @attr('number') declare versionNumber: number;
  @attr('number') declare createdAt: number;
  @attr('number') declare publishedAt: number;

  // Relationships
  @belongsTo('area-map', { async: true }) declare originalAreaMap: AsyncBelongsTo<AreaMapModel>;
  @belongsTo('area-map', { async: true }) declare draftAreaMap: AsyncBelongsTo<AreaMapModel>;
  @hasMany('desk', { async: true }) declare desks: AsyncHasMany<DeskModel>;
  @hasMany('employee', { async: true }) declare sharedEmployees: AsyncHasMany<EmployeeModel>;

  async publish(payload: unknown): Promise<unknown> {
    return await apiAction(this, { method: 'PUT', path: 'publish', data: payload });
  }
}

export default DraftModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    draft: DraftModel;
  }
}
