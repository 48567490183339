/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { filterBy } from '@ember/object/computed';
import { service } from '@ember/service';
import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type DeskModel from 'garaje/models/desk';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import urlBuilder from 'garaje/utils/url-builder';

class FloorModel extends Model {
  @service declare ajax: AjaxService;
  @service declare store: StoreService;

  // Attributes
  @attr('boolean') declare isActive: boolean;
  @attr('string') declare building: string;
  @attr('string') declare floorPlanUrl: string;
  @attr('string') declare floorPlanImgName: string;
  @attr('string') declare name: string;
  @attr('number') declare floorNumber: number;
  @attr('number') declare parentFloorId: number;

  // Relationships
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @hasMany('desk', { async: true }) declare desks: AsyncHasMany<DeskModel>;

  /**
   * NOTE: When viewing a floor, we retrieve all the desks and can determine the total active desks by filtering
   *       However, when viewing all floors, we receive this count via metadata.
   * This attr is passed as metadata placed on the model in the serializer
   */
  @attr('number') declare totalAssignableDesks: number;
  @filterBy('desks', 'enabled') declare assignableDesks: Array<DeskModel>;

  /**
   * @return {Number} The amount of desks in an `is_assignable` state
   */
  @computed('totalAssignableDesks', 'assignableDesks.[]')
  get activeDesksCount(): number {
    return this.hasMany('desks' as Exclude<keyof this, keyof Model>).ids().length
      ? this.assignableDesks.length
      : this.totalAssignableDesks;
  }

  async bulkDesksUpdate(payload: Record<string, unknown>): Promise<void> {
    const url = urlBuilder.bulkDesksUpdate();
    await this.ajax.request(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: payload,
    });

    const dirtyDesks = this.desks.filter((desk) => {
      //@ts-ignore
      return desk.hasDirtyAttributes && !desk.isNew;
    });
    //clear the dirty values
    dirtyDesks.forEach((desk) => {
      this.store.push({
        data: {
          id: desk.id,
          type: 'desk',
          attributes: {
            xPos: desk.xPos,
            yPos: desk.yPos,
            name: desk.name,
            neighborhood: desk.neighborhood,
            assignedTo: desk.assignedTo,
            enabled: desk.enabled,
          },
        },
      });
    });
  }

  async deleteFloorPlan(): Promise<unknown> {
    return await apiAction(this, { method: 'DELETE', path: 'floor-plan' });
  }
}

export default FloorModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    floor: FloorModel;
  }
}
