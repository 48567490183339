import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { alias } from 'macro-decorators';

/**
 * @param {boolean}           blockSelfServe
 * @param {Array<Location>}   locations
 * @param {object}            selectedPeriod
 * @param {Date}              renewalDate
 */
export default class SubscriptionsOverviewComponent extends Component {
  @service('location-billing') billingService;

  @alias('billingService.isDirty') isDirty;
  @alias('billingService.summaryOpened') summaryOpened;
  @alias('billingService.summaryDisabled') summaryDisabled;
  @alias('billingService.editingLocation') editingLocation;
  @alias('billingService.visitorsSubscriptionPlan') hasVisitorsSubscription;
  @alias('billingService.deliveriesSubscriptionPlan') hasDeliveriesSubscription;
  @alias('billingService.roomsSubscriptionPlan') hasRoomsSubscription;
  @alias('billingService.desksSubscriptionPlan') hasDesksSubscription;
  @alias('billingService.workplaceSubscriptionPlan') hasWorkplaceSubscription;
  @alias('billingService.currentPeriodDisplay') currentPeriodDisplay;

  get visitorsSvg() {
    return `/assets/images/app-icons/visitors.svg`;
  }
  get deliveriesSvg() {
    return `/assets/images/app-icons/deliveries.svg`;
  }
  get roomsSvg() {
    return `/assets/images/app-icons/rooms.svg`;
  }

  get desksSvg() {
    return `/assets/images/app-icons/desks.svg`;
  }

  get workplaceSvg() {
    return `/assets/images/app-icons/workplace.svg`;
  }

  @action
  selectLocationForEdit(location) {
    this.billingService.editingLocation = location;
  }

  @action
  summarize(location) {
    this.billingService.createLocationSubscriptionEstimate.perform(location);
    this.billingService.toggleSummaryOpened(true);
  }

  @action
  cancel() {
    this.billingService.editingLocation = null;
  }
}
