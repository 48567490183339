import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import { type Workbook } from 'garaje/utils/workbooks';

interface DeleteWorkbookDialogArgs {
  workbook: Workbook;
  closeDialog: () => void;
  afterDelete: () => void;
}

export default class DeleteWorkbookDialogComponent extends Component<DeleteWorkbookDialogArgs> {
  @service declare ajaxFetch: AjaxFetchService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;

  deleteWorkbook = dropTask(async () => {
    try {
      const {
        currentCompany: { id: companyId },
      } = this.state;

      const url = urlBuilder.analytics.getSingleWorkbookUrl(companyId, this.args.workbook.id);
      const options = { type: 'DELETE' };

      await this.ajaxFetch.request(url, options);

      this.args.afterDelete();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
