import { belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import AbstractAgreementModel, { withExtendedFields } from 'garaje/models/abstract/abstract-agreement';
import OnboardingChangesTracker from 'garaje/utils/decorators/onboarding-changes-tracker';

import type AgreementPageModel from './agreement-page';

const AbstractExtendedAgreementModel = withExtendedFields(AbstractAgreementModel);

@OnboardingChangesTracker({ taskName: 'loadFlowsTask', attributes: ['name'] })
class AgreementModel extends AbstractExtendedAgreementModel {
  @belongsTo('agreement-page', { async: true }) declare agreementPage: AsyncBelongsTo<AgreementPageModel>;
}

export default AgreementModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    agreement: AgreementModel;
  }
}
