import Model, { attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class Saml extends Model {
  @belongsTo('company', { async: true }) company;
  @attr('string', { defaultValue: '' }) fingerprint;
  @attr('string', { defaultValue: '' }) url;
  @attr('boolean', { defaultValue: false }) required;
}

export default Saml;
