/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { notEmpty } from '@ember/object/computed';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import type AbilitiesService from 'ember-can/services/abilities';
import AbstractFlowModel from 'garaje/models/abstract/abstract-flow';
import type CompanyModel from 'garaje/models/company';
import type FlowModel from 'garaje/models/flow';
import type GlobalAgreementPageModel from 'garaje/models/global-agreement-page';
import type GlobalBadgeModel from 'garaje/models/global-badge';
import type GlobalIdScanPageModel from 'garaje/models/global-id-scan-page';
import type GlobalPhotoPageModel from 'garaje/models/global-photo-page';
import type GlobalSignInFieldActionRuleGroupModel from 'garaje/models/global-sign-in-field-action-rule-group';
import type GlobalSignInFieldPageModel from 'garaje/models/global-sign-in-field-page';
import type GlobalSummaryPageModel from 'garaje/models/global-summary-page';
import type GlobalVisualComplianceConfigurationModel from 'garaje/models/global-visual-compliance-configuration';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type UserModel from 'garaje/models/user';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { STORE_RESPONSE_CONFIG_OPTIONS, LANGUAGE_SUPPORT } from 'garaje/utils/enums';
import uniq from 'lodash/uniq';

export default class GlobalFlowModel extends AbstractFlowModel {
  @service declare abilities: AbilitiesService;
  @service declare skinnyLocations: SkinnyLocationsService;

  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('global-summary-page', { async: true }) declare summaryPage: AsyncBelongsTo<GlobalSummaryPageModel>;
  @belongsTo('global-agreement-page', { async: true }) declare agreementPage: AsyncBelongsTo<GlobalAgreementPageModel>;
  @belongsTo('global-sign-in-field-page', { async: true })
  declare signInFieldPage: AsyncBelongsTo<GlobalSignInFieldPageModel>;
  @belongsTo('global-badge', { async: true }) declare badge: AsyncBelongsTo<GlobalBadgeModel>;
  @belongsTo('global-photo-page', { async: true }) declare photoPage: AsyncBelongsTo<GlobalPhotoPageModel>;
  @belongsTo('global-id-scan-page', { async: true }) declare idScanPage: AsyncBelongsTo<GlobalIdScanPageModel>;
  @belongsTo('global-visual-compliance-configuration', { async: true })
  declare visualComplianceConfiguration: AsyncBelongsTo<GlobalVisualComplianceConfigurationModel>;
  @hasMany('global-sign-in-field-action-rule-group', { async: true })
  declare signInFieldActionRuleGroups: AsyncHasMany<GlobalSignInFieldActionRuleGroupModel>;
  @hasMany('skinny-location', { async: true }) declare locations: AsyncHasMany<SkinnyLocationModel>;
  @hasMany('flow', { async: true }) declare flows: AsyncHasMany<FlowModel>;
  @hasMany('user', { async: true }) declare visitorDocumentContacts: AsyncHasMany<UserModel>;

  @hasMany('user', { async: true, inverse: null }) declare inviteApprovalContacts: AsyncHasMany<UserModel>;

  @attr('string', { defaultValue: '' }) declare name: string;
  @attr('string', { defaultValue: '' }) declare description: string;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
  @attr('boolean', { defaultValue: false }) declare idCheck: boolean;
  @attr('boolean', { defaultValue: false }) declare inviteApprovalsEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare visualComplianceEnabled: boolean;
  @attr('string', { defaultValue: STORE_RESPONSE_CONFIG_OPTIONS.STORED }) declare storeResponseConfig: string;
  @attr('immutable', { defaultValue: () => ({ name: {} }) }) declare customTranslations: Record<string, unknown>;
  @attr('boolean', { defaultValue: false }) declare additionalGuests: boolean;
  @attr('number') declare blueprint: number;

  isProtect = false;
  isGlobal = true;

  @notEmpty('translationEnabledLocales') translationLanguagesAvailable!: boolean;

  @computed('skinnyLocations.currentCompanyLocations.@each.{locale,enabledLocales}')
  get translationEnabledLocales(): string[] {
    const locations = this.skinnyLocations.currentCompanyLocations;
    const languages = locations.reduce<Array<string | string[]>>((array, location) => {
      array.push(location.locale);
      array.push(location.enabledLocales);
      return array;
    }, []);

    return uniq(languages.filter((l) => l !== LANGUAGE_SUPPORT.DEFAULT).flat());
  }

  get canEdit(): boolean {
    return this.abilities.can('edit global-flows');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-flow': GlobalFlowModel;
  }
}
