import Component from '@glimmer/component';
import { get, set, action } from '@ember/object';
import { service } from '@ember/service';
import { filter, reads } from 'macro-decorators';
import { localCopy } from 'tracked-toolbox';
import { task } from 'ember-concurrency';

const HIDDEN_FIELD_IDENTIFIERS = Object.freeze(['host', 'name']);
const NONE_OPTION = Object.freeze({ identifier: null, displayName: 'None' });
const LINE_OPTION = Object.freeze({ disabled: true });

const configurableFieldsFilterFn = (field) => {
  if (HIDDEN_FIELD_IDENTIFIERS.includes(field.identifier)) return false;

  // don't allow conditional fields to be mapped to ID scan values
  if (field.actionableSignInFields.length > 0) return false;

  return field.kind === 'text';
};

/**
 * @param {Array<SignInField>} signInFields         Required. Sign in fields to manage
 * @param {Flow}               flow                 Required. Used to lookup ID Scanning enabled
 * @param {Task}               saveFieldsTask       Required. Pass through of task to save fields
 * @param {Boolean}            isDisabled           Optional. Indicate inputs are entirely disabled
 * @param {Boolean}            isPristine           Required. Indicate unsaved changes
 * @param {Function}           onChange             Optional. Action to take when data modified
 */
export default class SignInFieldsFieldOptionsModalIdScanningComponent extends Component {
  @service store;

  optionsName = 'id-scanning';
  dropdownDestination = 'modal-overlays';

  @localCopy('args.signInFields', []) signInFields;

  @filter('signInFields', configurableFieldsFilterFn) configurableFields;
  @reads('fetchIdScanFieldsTask.lastSuccessful.value') idScanFields;
  @reads('fetchIdScanPageTask.lastSuccessful.value') idScanPage;

  get idScanFieldOptions() {
    const { idScanFields = [] } = this;
    const sortedIdScanFields = idScanFields.sortBy('displayName');

    return [NONE_OPTION, LINE_OPTION].concat(sortedIdScanFields.toArray());
  }

  constructor() {
    super(...arguments);
    this.fetchAllDataTask.perform();
  }

  onChange(field, attr, value) {
    if (typeof this.args.onChange !== 'function') return true;
    return this.args.onChange(field, attr, value);
  }

  fetchAllDataTask = task({ drop: true }, async () => {
    const idScanFields = await this.fetchIdScanFieldsTask.perform();
    const idScanPage = await this.fetchIdScanPageTask.perform();

    return { idScanFields, idScanPage };
  });

  fetchIdScanFieldsTask = task({ drop: true }, async () => {
    let idScanFields = this.store.peekAll('id-scan-field');

    if (!idScanFields.length) {
      idScanFields = await this.store.findAll('id-scan-field');
    }

    return idScanFields;
  });

  fetchIdScanPageTask = task({ drop: true }, async () => {
    const idScanPage = await get(this, 'args.flow.idScanPage');

    return idScanPage;
  });

  saveIdScanFieldsTask = task(async () => {
    await this.args.saveFieldsTask?.perform();
  });

  @action
  setIdScanField(field, selection) {
    if (this.onChange(field, 'idScanField', selection.identifier) === false) return;
    set(field, 'idScanField', selection.identifier);
  }
}
