import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, toDate } from 'date-fns-tz';
import { type Task } from 'ember-concurrency';
import { UserActionsType, WorkbookScopeType, type Workbook } from 'garaje/utils/workbooks';

interface CustomDashboardsTableArgs {
  customWorkbooks: Array<Workbook>;
  getWorkbooks: Task<void, []>;
  openWorkbook: Task<void, [Workbook, string]>;
  totalElements: number;
  pageCount: number;
  pageNumber: number;
  pageSize: number;
  loadPage: Task<void, [number]>;
}

export default class CustomDashboardsTableComponent extends Component<CustomDashboardsTableArgs> {
  @tracked isEditDialogOpen: boolean = false;
  @tracked workbookToEdit: Workbook | undefined = undefined;
  @tracked isDeleteDialogOpen: boolean = false;
  @tracked workbookToDelete: Workbook | undefined = undefined;

  @action
  setWorkbookToEdit(workbook: Workbook): void {
    this.workbookToEdit = workbook;
    this.isEditDialogOpen = true;
  }

  @action
  setWorkbookToDelete(workbook: Workbook): void {
    this.workbookToDelete = workbook;
    this.isDeleteDialogOpen = true;
  }

  @action
  afterEdit(): void {
    void this.args.getWorkbooks.perform();
    this.isEditDialogOpen = false;
  }

  @action
  afterDelete(): void {
    void this.args.getWorkbooks.perform();
    this.isDeleteDialogOpen = false;
  }

  getWorkbookScope(workbookScope: WorkbookScopeType): string {
    switch (workbookScope) {
      case WorkbookScopeType.COMPANY:
        return 'Global';
      case WorkbookScopeType.LOCATION:
        return 'Location';
      default:
        return '';
    }
  }

  getFormattedDate(timestamp: string): string {
    const date = toDate(timestamp);
    return format(date, "MMM dd, yyyy 'at' h:mm a");
  }

  canSeeMoreActionsBtn(userActions: Array<UserActionsType>): boolean {
    if (
      !userActions ||
      userActions.length < 1 ||
      (userActions.length === 1 && userActions[0] === UserActionsType.VIEW_EMBED)
    ) {
      // don't show btn if user can only view workbooks
      return false;
    }

    return true;
  }
}
