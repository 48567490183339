import { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';

import AbstractBadge from './abstract/abstract-badge';
import type ZoneModel from './zone';

export default class PropertyBadgeModel extends AbstractBadge {
  @attr('boolean') declare autoPrintEnabled: boolean;

  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'property-badge': PropertyBadgeModel;
  }
}
