import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type { Task } from 'ember-concurrency';
import { all, restartableTask } from 'ember-concurrency';
import type PluginInstallModel from 'garaje/models/plugin-install';
import type UserModel from 'garaje/models/user';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StatsigService from 'garaje/services/statsig';
import { alias, and, equal, or, not } from 'macro-decorators';

import type { SettingsNotificationsRouteModel } from './route';
import type SettingsNotificationsRoute from './route';

export default class VisitorsSettingsNotificationsController extends Controller {
  declare model: SettingsNotificationsRouteModel['currentLocation'];
  declare employees: SettingsNotificationsRouteModel['employees'];
  declare fallbackContacts: SettingsNotificationsRouteModel['fallbackContacts'];
  declare vrSubscription: SettingsNotificationsRouteModel['vrSubscription'];
  declare fallbackContactsTask: Task<SettingsNotificationsRouteModel['fallbackContacts'], []>;
  declare reloadFallbackContacts: SettingsNotificationsRoute['reloadFallbackContacts'];
  declare installedPlugins: NativeArray<PluginInstallModel>;
  declare connectedTenants: SettingsNotificationsRouteModel['connectedTenants'];
  declare connectLocationConfiguration: SettingsNotificationsRouteModel['connectLocationConfiguration'];

  @service declare abilities: AbilitiesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentLocation: CurrentLocationService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;
  @service declare statsig: StatsigService;

  queryParams = ['feature'];

  @tracked feature = '';
  @tracked fallbackContactsHasChanges = false;
  @tracked showBlankState?: boolean;

  @alias('currentLocation.location.hostNotificationsEnabled')
  hasNotificationEnabled!: CurrentLocationService['location']['hostNotificationsEnabled'];
  @not('hasNotificationEnabled') hasNotificationDisabled!: boolean;
  @and('hasNotificationDisabled', 'showBlankState') hasNoNotifications!: boolean;
  @equal('employees.meta.total', 0) hasNoEmployees!: boolean;
  @or('fallbackContactsHasChanges', 'model.hasDirtyAttributes') hasDirtyChanges!: boolean;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get locationIsConnectedToProperty(): boolean {
    return <number>this.connectedTenants.length > 0;
  }

  get showDeviceStatusNotificationsSection(): boolean {
    return (
      this.vrSubscription!.canEnableBadgePrinting &&
      (this.abilities.can('update printer notifications') || this.abilities.can('update device notifications'))
    );
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  searchUsersTask = restartableTask(async (term: string, selectedUsers: UserModel[]) => {
    const userRoles = await this.store.query('user-role', {
      filter: { name: term, location: this.currentLocation.location.id },
    });
    let users = await all(userRoles.map((role) => role.user));
    const selectedUserIds = selectedUsers.map((contact) => contact.id);
    // deduplicate since roles could point to same user
    // exclude selected users
    users = A(users)
      .uniqBy('id')
      .filter((user) => {
        return !selectedUserIds.includes(user.id);
      });
    return users;
  });

  @action
  async enable(): Promise<void> {
    const location = this.currentLocation.location;
    set(location, 'hostNotificationsEnabled', true);
    await location.save();
    this.showBlankState = false;
  }

  @action
  onUpdateFallbackContacts({ hasChanges }: { hasChanges: boolean }): void {
    this.fallbackContactsHasChanges = hasChanges;
  }

  @action
  async rollbackFallbackContacts(): Promise<void> {
    // Reset to clean state so confirm dialog is not sticky
    this.fallbackContactsHasChanges = false;
    this.feature = '';
    await this.reloadFallbackContacts(); // set by route
  }

  @action
  rollbackDirtyChanges(): void {
    if (<boolean>(<unknown>this.model.hasDirtyAttributes)) {
      this.model.rollbackAttributes();
    }
    if (this.fallbackContactsHasChanges) {
      this.send('rollbackFallbackContacts');
    }
  }

  @action
  trackTooltipShown(): void {
    this.statsig.logEvent('device-notifications-moved-tooltip-seen');
  }

  @action
  trackTooltipLinkClick(): void {
    this.statsig.logEvent('device-notifications-moved-tooltip-link-clicked');
  }
}
