import Component from '@glimmer/component';
import { A } from '@ember/array';
import { service } from '@ember/service';
import { localCopy } from 'tracked-toolbox';

export default class UserDocumentStepsComponent extends Component {
  @service router;

  @localCopy('args.steps', A()) steps;

  get sortedSteps() {
    return this.steps.sortBy('position');
  }

  get currentStep() {
    const step = this.steps.findBy('route', this.router.currentRouteName);

    // If the current route matches no steps, we assume all steps are completed
    return step?.position ?? Infinity;
  }
}
