import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class BillingPeriodController extends Controller {
  @service router;

  queryParams = ['viaLabel', 'viaObject'];
  viaLabel = null;
  viaObject = null;

  @tracked hasPaymentMethod;

  @action
  transitionToBilling() {
    this.router.transitionTo('billing');
  }
}
