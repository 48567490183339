import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HomepageItem from './homepage-item';

@adapter('growth')
class SetupGuideItemModel extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare title: string;

  @attr('string')
  declare description: string;

  @attr('boolean')
  declare enabled: boolean;

  @attr('number')
  declare rank: number;

  @attr('string')
  declare app: string;

  @attr('string')
  declare route: string;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare icon: object;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare metadata: {
    show_on_homepage?: boolean;
    show_on_stepper?: boolean;
    dashboard_incomplete_step_label?: string;
    dashboard_completed_step_label?: string;
    dashboard_button_complete_step?: boolean;
    dashboard_description_suffix?: string;
    dashboard_button_brand?: boolean;
    dashboard_button_label?: string;
    dashboard_is_step_modal?: boolean;
    external_route?: string;
    secondary_button_label?: string;
    secondary_button_route?: string;
    secondary_button_chmln_tour_id?: string;
    chmln_tour_id?: string;
    dashboard_group?: string;
    route_dynamic_field?: string;
    route_dynamic_field_model?: string;
  };

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare resources: object;

  @attr('boolean')
  declare completed: boolean;

  @belongsTo('homepage-item', { async: true })
  declare homepageItem: AsyncBelongsTo<HomepageItem>;

  get showOnHomepage(): boolean {
    return this.metadata.show_on_homepage !== undefined && this.metadata.show_on_homepage;
  }
}

export default SetupGuideItemModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'setup-guide-item': SetupGuideItemModel;
  }
}
