import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import EmployeeFilter from 'garaje/models/employee-filter';
import { TrackedArray } from 'tracked-built-ins';

class OneLoginModel extends Model {
  name = 'OneLogin';

  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;

  @attr('string', { defaultValue: '' }) declare apiKey: string;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
  @attr('string') declare createdAt: string;
  @attr('string') declare errorState: string;
  @attr('string') declare errorMessage: string;
  @attr('string', { defaultValue: 'unfiltered' }) declare filterType: string;

  // @ts-ignore
  @attr('tracked-array', {
    serializeItem: (item: EmployeeFilter) => item.serialize(),
    deserializeItem: (item: Partial<EmployeeFilter>) => new EmployeeFilter(item),
    defaultValue: () => new TrackedArray(),
  })
  declare filters: EmployeeFilter[];

  get companyFilters(): EmployeeFilter[] {
    return this.filters.filter((filter) => !filter.locationId);
  }

  get locationFilters(): EmployeeFilter[] {
    return this.filters.filter((filter) => !!filter.locationId);
  }
}

export default OneLoginModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'one-login': OneLoginModel;
  }
}
