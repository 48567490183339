import Component from '@glimmer/component';

/**
 * Renders a nav element containing TabItems if TabItems.length > 1
 *
 * @param {Array<{label: string, link: UILink}}   tabs a collection of TabItem objects to render
 * @param {Boolean}                               isSorted determines whether TabItems are sorted by their label
 */

export default class TabNavComponent extends Component {
  get tabs() {
    if (this.args.isSorted) return this.args.tabs.sortBy('label');

    return this.args.tabs;
  }
}
