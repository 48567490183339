import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, task, timeout } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type PluginInstall from 'garaje/models/plugin';
import type User from 'garaje/models/user';
import type UserRole from 'garaje/models/user-role';
import type VfdContactMethod from 'garaje/models/vfd-contact-method';
import type { VfdContactMethodSlackMetadata, VfdContactMethodTeamsMetadata } from 'garaje/models/vfd-contact-method';
import { VfdContactMethodKind } from 'garaje/models/vfd-contact-method';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import uniqBy from 'lodash/uniqBy';

const DEBOUNCE_TIMEOUT_MS = 250;
const USER_PAGE_SIZE = 25;

export const LOAD_MORE = Symbol('garaje:VirtualFrontDesk::NotificationContactDropdown:loadMore');

interface VirtualFrontDeskNotificationContactDropdownSignature {
  Args: {
    contactMethod: VfdContactMethod | undefined;
    contactMethodSelected: (contact: VfdContactMethod) => void;
    /**
     * List of contact methods that, should they appear in the search, should not be selectable.
     * This is useful for passing a list of existing contacts, so that they can't be selected multiple times.
     */
    disabledContactMethods?: VfdContactMethod[];
    /**
     * Passed through to the underlying <PowerSelect> as `@dropdownClass`.
     */
    dropdownClass?: string;
    isDisabled: boolean;
    /**
     * Placeholder text to use for the dropdown. Defaults to "Choose who to notify" if not otherwise provided.
     */
    placeholder?: string;
    slackPluginInstall: PluginInstall | undefined;
    teamsPluginInstall: PluginInstall | undefined;
    userCount: number | null;
  };
}

export type NotificationContactDropdownOption = {
  disabled?: boolean;
  kind: VfdContactMethodKind;
  metadata?: VfdContactMethodSlackMetadata | VfdContactMethodTeamsMetadata;
  user?: User;
};

type SearchResultsWithGroup = {
  groupName: string;
  options: Array<NotificationContactDropdownOption | typeof LOAD_MORE>;
};

type SlackChannelResponse = Array<{ id: string; name: string }>;
type TeamsGroupChatResponse = Array<{ id: string; name: string }>;

type UserSearchState = {
  page: number;
  loadedRoleCount: number;
  meta: { total: number } | null;
  query: string | null;
};

export default class VirtualFrontDeskNotificationContactDropdown extends Component<VirtualFrontDeskNotificationContactDropdownSignature> {
  @service declare store: Store;

  @tracked _users: User[] = [];
  #userSearchState: UserSearchState = {
    page: 0,
    loadedRoleCount: 0,
    meta: null,
    query: null,
  };

  get hasSlackIntegration(): boolean {
    return !!this.args.slackPluginInstall;
  }

  get hasTeamsIntegration(): boolean {
    return !!this.args.teamsPluginInstall;
  }

  /**
   * In order to support infinite scroll/lazy loading for users, we can't simply return the results
   * from ember-power-select's `@search` action, because we need to update the list as more users are
   * fetched. Instead, what we need to do is to manually maintain the list of options in a tracked way,
   * so that ember-power-select rerenders with the updated list.
   * Since the Slack and Teams searches are implemented as ember-concurrency tasks (and don't have infinite
   * loading) we can just use the return value from the last successful run to access the results; for users,
   * we have to keep that state on the component ourselves.
   */
  get options(): SearchResultsWithGroup[] | SearchResultsWithGroup['options'] {
    const slackChannels = this.hasSlackIntegration ? this.searchSlackChannels.lastSuccessful?.value : null;
    const teamsGroupChats = this.hasTeamsIntegration ? this.searchTeamsGroupChats.lastSuccessful?.value : null;

    const results: SearchResultsWithGroup[] = [];
    if (slackChannels && slackChannels.length > 0) {
      results.push({
        groupName: 'Slack channels',
        options: slackChannels.map((channel) => ({
          disabled: this.#shouldDisableSlackOption(channel),
          kind: VfdContactMethodKind.Slack,
          metadata: channel,
        })),
      });
    }

    if (teamsGroupChats && teamsGroupChats.length > 0) {
      results.push({
        groupName: 'Teams group chats',
        options: teamsGroupChats.map((groupChat) => ({
          disabled: this.#shouldDisableTeamsOption(groupChat),
          kind: VfdContactMethodKind.Teams,
          metadata: groupChat,
        })),
      });
    }

    if (this._users.length > 0) {
      const users: Array<NotificationContactDropdownOption | typeof LOAD_MORE> = this._users.map((user) => ({
        disabled: this.#shouldDisableUserOption(user),
        kind: VfdContactMethodKind.EnvoyUser,
        user,
      }));

      if (this.showLoadMore) {
        users.push(LOAD_MORE);
      }

      results.push({
        groupName: 'Employees',
        options: users,
      });
    }

    // if there's only one set of data that has results, don't bother showing groups
    if (results.length === 1) {
      return results[0]!.options;
    }

    return results;
  }

  // text shown below search box when dropdown is initially opened (before user types to search)
  get searchMessageText(): string {
    const employeeCount = this.args.userCount;
    const employeeCountPhrase = employeeCount
      ? `${new Intl.NumberFormat().format(employeeCount)} ${pluralize(employeeCount, 'employees', { withoutCount: true })}`
      : 'employees';
    if (this.hasSlackIntegration && this.hasTeamsIntegration) {
      return `Search across Slack channels, Teams group chats, or ${employeeCountPhrase}`;
    } else if (this.hasSlackIntegration) {
      return `Search across Slack channels or ${employeeCountPhrase}`;
    } else if (this.hasTeamsIntegration) {
      return `Search across Teams group chats or ${employeeCountPhrase}`;
    } else {
      return `Search across ${employeeCountPhrase}`;
    }
  }

  // placeholder text shown in search box before user types
  get searchPlaceholderText(): string {
    if (this.hasSlackIntegration && this.hasTeamsIntegration) {
      return 'Type a Slack channel, Teams group chat or an employee name';
    } else if (this.hasSlackIntegration) {
      return 'Type a Slack channel or an employee name';
    } else if (this.hasTeamsIntegration) {
      return 'Type a Teams group chat or an employee name';
    } else {
      return 'Type an employee name';
    }
  }

  search = restartableTask(async (term: string) => {
    await timeout(zft(DEBOUNCE_TIMEOUT_MS));
    const promises: Promise<unknown>[] = [this.searchUsers.perform(term)];
    if (this.hasSlackIntegration) {
      promises.push(this.searchSlackChannels.perform(term));
    }
    if (this.hasTeamsIntegration) {
      promises.push(this.searchTeamsGroupChats.perform(term));
    }
    await Promise.all(promises);
  });

  searchUsers = restartableTask(async (query: string) => {
    // this task is only called when a new search is performed; reset page & other saved state
    this.#userSearchState = {
      page: 0,
      loadedRoleCount: 0,
      meta: null,
      query,
    };
    this._users = [];

    await this.loadUserPage.perform(query);
  });

  loadNextUserPage = task(async () => {
    try {
      this.#userSearchState.page++;
      await this.loadUserPage.perform(this.#userSearchState.query!);
    } catch {
      // if loading fails, revert the saved page so another attempt tries to fetch the same records again
      this.#userSearchState.page--;
    }
  });

  // This task is not meant to be invoked directly, but it can't be made private (i.e, `#loadUserPage`) because
  // the babel transform for ember-concurrency tasks doesn't work with private fields.
  loadUserPage = task(async (query: string) => {
    const response = await this.store.query('user-role', {
      filter: {
        confirmedUsers: true,
        name: query,
      },
      include: 'user',
      page: {
        limit: USER_PAGE_SIZE,
        offset: this.#userSearchState.page * USER_PAGE_SIZE,
      },
      sort: 'user.full-name',
    });

    this.#userSearchState.meta = (<PaginatedRecordArray<UserRole>>(response as unknown)).meta;
    const userRoles = response.slice();
    this.#userSearchState.loadedRoleCount += userRoles.length;

    const thisPageUsers = await Promise.all(userRoles.map((userRole) => userRole.user));
    this._users = uniqBy([...this._users, ...thisPageUsers], 'id');
  });

  searchSlackChannels = restartableTask(async (query: string) => {
    const pluginInstall = this.args.slackPluginInstall;
    if (!pluginInstall) return null;
    let url = urlBuilder.slack.v2.searchChannelsUrl(pluginInstall.id);
    if (query) {
      url = `${url}?search=${encodeURIComponent(query)}`;
    }
    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = <SlackChannelResponse>await response.json();
        return data.map((item) => ({
          'channel-id': item.id,
          'channel-name': item.name,
        }));
      } else {
        console.error('fetching list of Slack channels failed: [%s] %s', response.status, response.statusText); // eslint-disable-line no-console
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    return [];
  });

  searchTeamsGroupChats = restartableTask(async (query: string) => {
    const pluginInstall = this.args.teamsPluginInstall;
    if (!pluginInstall) return null;
    let url = urlBuilder.teams.v2.searchChannelsUrl(pluginInstall.id);
    if (query) {
      url = `${url}?search=${encodeURIComponent(query)}`;
    }
    try {
      const response = await fetch(url, {
        credentials: 'include',
      });

      if (response.ok) {
        const data = <TeamsGroupChatResponse>await response.json();
        return data.map((item) => ({
          'channel-id': item.id,
          'channel-name': item.name,
        }));
      } else {
        console.error('fetching list of Teams group chats failed: [%s] %s', response.status, response.statusText); // eslint-disable-line no-console
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
    return [];
  });

  get showLoadMore(): boolean {
    if (!this.#userSearchState.meta) return false;
    return this.#userSearchState.loadedRoleCount < this.#userSearchState.meta.total;
  }

  #shouldDisableSlackOption(channel: VfdContactMethodSlackMetadata): boolean {
    const disabledContactMethods = this.args.disabledContactMethods;
    if (!disabledContactMethods || disabledContactMethods.length === 0) return false;
    return disabledContactMethods.some(
      (contactMethod) =>
        contactMethod.kind === VfdContactMethodKind.Slack &&
        contactMethod.metadata['channel-id'] === channel['channel-id'],
    );
  }

  #shouldDisableTeamsOption(groupChat: VfdContactMethodTeamsMetadata): boolean {
    const disabledContactMethods = this.args.disabledContactMethods;
    if (!disabledContactMethods || disabledContactMethods.length === 0) return false;
    return disabledContactMethods.some(
      (contactMethod) =>
        contactMethod.kind === VfdContactMethodKind.Teams &&
        contactMethod.metadata['channel-id'] === groupChat['channel-id'],
    );
  }

  #shouldDisableUserOption(user: User): boolean {
    const disabledContactMethods = this.args.disabledContactMethods;
    if (!disabledContactMethods || disabledContactMethods.length === 0) return false;
    return disabledContactMethods.some(
      (contactMethod) =>
        contactMethod.kind === VfdContactMethodKind.EnvoyUser && contactMethod.belongsTo('user').id() === user.id,
    );
  }
}
