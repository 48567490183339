import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocationsService from 'garaje/services/locations';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { AnalyticsEventNames } from 'garaje/utils/enums';

import type AnalyticsReportsV2Controller from './controller';

export interface AnalyticsReportsV2RouteParams {
  location_id: string;
}

export default class AnalyticsLocationSigmaReportsIndexRoute extends Route {
  title = 'Analytics · Reports · Envoy';

  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare locations: LocationsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;

  queryParams = {
    location_id: { refreshModel: true },
  };

  afterModel(): void {
    this.logMetrics();
  }

  logMetrics(): void {
    const {
      currentCompany: { id: companyId },
      currentLocation: { id: locationId },
      visitorsSubscription,
      workplaceSubscription,
    } = this.state;

    const details = {
      companyId,
      locationId,
      app: visitorsSubscription?.app || workplaceSubscription?.app,
      plan: visitorsSubscription?.plan || workplaceSubscription?.plan,
      userId: this.currentAdmin?.id || '',
      employeeId: this.currentAdmin.employee?.id || '',
    };
    this.metrics.trackEvent(AnalyticsEventNames.SCHEDULED_REPORTS_V2_VISITED, details);
    this.metrics.logMonitorEvent(AnalyticsEventNames.SCHEDULED_REPORTS_V2_VISITED, details);
  }

  setupController(controller: AnalyticsReportsV2Controller, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    const { location_id } = <AnalyticsReportsV2RouteParams>this.paramsFor(this.routeName);
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const protectedController = this.controllerFor('protected');

    if (location_id && this.state.currentLocation.id != location_id) {
      protectedController.send('switchLocation', location_id, 'analytics.reports-v2');

      this.locations.loadAllTask
        .perform()
        .then(() => {
          const matchingLocation = this.locations.allActive.filter((entry) => entry.id === location_id);
          if (matchingLocation.length === 0) {
            this.router.transitionTo('unauthorized');
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }

  activate(transition: Transition): void {
    super.activate(transition);
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);
    const { location_id } = <AnalyticsReportsV2RouteParams>this.paramsFor(this.routeName);
    if (location_id) {
      this.router.transitionTo({ queryParams: { location_id: null } });
    }
  }
}
