import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import type { ParentWorkbook, Workbook } from 'garaje/utils/workbooks';

interface EditWorkbookDialogArgs {
  workbook: Workbook;
  closeDialog: () => void;
  afterEdit: () => void;
}

export default class EditWorkbookDialogComponent extends Component<EditWorkbookDialogArgs> {
  @service declare ajaxFetch: AjaxFetchService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;

  @tracked name: string = '';
  @tracked parent: ParentWorkbook | null | undefined = undefined;
  @tracked description: string = '';
  @tracked errors = {
    name: false,
    description: false,
  };

  constructor(owner: unknown, args: EditWorkbookDialogArgs) {
    super(owner, args);

    this.name = this.args.workbook.name;
    this.parent = this.args.workbook.parent;
    this.description = this.args.workbook.description;
  }

  editWorkbook = dropTask(async () => {
    try {
      const {
        currentCompany: { id: companyId },
      } = this.state;

      this.errors = {
        name: isEmpty(this.name),
        description: isEmpty(this.description),
      };

      if (this.errors.name || this.errors.description) {
        return;
      }

      const url = urlBuilder.analytics.getSingleWorkbookUrl(companyId, this.args.workbook.id);
      const options = {
        type: 'PUT',
        headers: { Accept: 'application/json' },
        contentType: 'application/json',
        data: {
          name: this.name,
          description: this.description,
        },
      };

      await this.ajaxFetch.request(url, options);

      this.args.afterEdit();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
