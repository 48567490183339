import { assert } from '@ember/debug';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import Service, { service } from '@ember/service';
import type StateService from 'garaje/services/state';

export default class TrialService extends Service {
  @service declare state: StateService;

  trials = [
    'basic',
    'standard', // do we need to handle standard?
    'standard-1',
    'premium',
    'enterprise',
  ];

  // TODO: @heroiceric
  // This is unreliable because it returns false when company has not yet
  // resolved
  @computed('state.vrSubscription.{hasTrialDaysLeft,isOnTrial,isBasicPlan}')
  get canTrialFeature(): boolean {
    if (!this.state.vrSubscription?.isOnTrial) {
      return false;
    }
    return this.state.vrSubscription?.hasTrialDaysLeft || this.state.vrSubscription.isBasicPlan;
  }

  canAccess(requestedLevel: string, requestedPlanIntent?: string): boolean {
    const planIntent = requestedPlanIntent || this.state.vrSubscription?.plan;
    if (!this.#checkPrivilegeLevelExists(requestedLevel)) {
      assert(`Must pass a valid privilege level: ${this.trials.join(', ')}`);
    }

    return this.#hasSufficientPrivilege(planIntent!, requestedLevel);
  }

  #hasSufficientPrivilege(intent: string, ask: string): boolean {
    const indexIntent = this.trials.indexOf(intent);
    const indexAsk = this.trials.indexOf(ask);
    return indexAsk <= indexIntent;
  }

  #checkPrivilegeLevelExists(level: string): boolean {
    return this.trials.indexOf(level) > -1;
  }
}
