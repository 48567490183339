import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import { type Task } from 'ember-concurrency';
import type EntryModel from 'garaje/models/entry';
import type UserModel from 'garaje/models/user';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import { alias } from 'macro-decorators';

interface EntryFeedItemFlaggedArgs {
  approveEntryTask: Task<unknown, [EntryModel]>;
  blocklistContacts: UserModel[];
  context?: 'location' | 'property';
  denyEntryTask: Task<unknown, [EntryModel]>;
  entry: EntryModel;
  idScanContacts: UserModel[];
}
export default class EntryFeedItemFlagged extends Component<EntryFeedItemFlaggedArgs> {
  @service declare abilities: AbilitiesService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: Store;

  @alias('args.entry.approvalStatus.hasPendingIdScan') declare hasPendingIdScan: boolean;

  get canReview(): boolean {
    return this.abilities.can('review entry-approval', {
      context: this.context,
      report: this.args.entry.approvalStatus.failedReport,
    });
  }

  get canReviewOrReadBlocklistMatch(): boolean {
    return this.abilities.can('review or read blocklist match in security', {
      context: this.context,
      didFailBlocklistCheck: this.args.entry.approvalStatus?.didFailBlocklistCheck,
      report: this.args.entry.approvalStatus.failedReport,
    });
  }

  get colspan(): number {
    let cols = 1;
    const dashboardFields = this.currentLocation.location.dashboardFields;

    if (dashboardFields) {
      cols += dashboardFields.length;
    }

    return cols;
  }

  get contactedNames(): string[] {
    let contacts: string[] = [];
    if (this.args.entry.approvalStatus.didFailBlocklistCheck && this.args.blocklistContacts) {
      contacts = [...contacts, ...this.args.blocklistContacts.map((contact) => contact.fullName)];
    }
    if (
      this.args.entry.approvalStatus.didFailIdScanningCheck &&
      this.args.idScanContacts.map((contact) => contact.fullName)
    ) {
      contacts = [...contacts, ...this.args.idScanContacts.map((contact) => contact.fullName)];
    }
    return A(contacts).uniq();
  }

  get context(): string {
    return this.args.context ?? 'location';
  }

  approveEntryTask = task(async (entry: EntryModel) => {
    // This component-local version of the task exists so we can get instance-specific state.
    // The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
    // executes; otherwise, once the approved entry was reloaded (either with an `entry.reload()`
    // call or by the pubnub subscription that gets notified of changes to entries and pushes them
    // into the store out-of-band), this component gets torn down and the task is aborted before,
    // e.g., the flash message is shown. By keeping that logic out of this component, we don't
    // have to worry about that.
    // The instance-local state means that we can disable this entry's Approve/Deny buttons when
    // approveEntryTask or denyEntryTask is running without having to track state ourselves at
    // the higher level, or ended up disabling the buttons for _all_ entries instead of this one.
    await this.args.approveEntryTask.unlinked().perform(entry);
  });

  denyEntryTask = task(async (entry: EntryModel) => {
    // This component-local version of the task exists so we can get instance-specific state.
    // The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
    // executes; otherwise, once the denied entry was reloaded (either with an `entry.reload()`
    // call or by the pubnub subscription that gets notified of changes to entries and pushes them
    // into the store out-of-band), this component gets torn down and the task is aborted before,
    // e.g., the flash message is shown. By keeping that logic out of this component, we don't
    // have to worry about that.
    // The instance-local state means that we can disable this entry's Approve/Deny buttons when
    // approveEntryTask or denyEntryTask is running without having to track state ourselves at
    // the higher level, or ended up disabling the buttons for _all_ entries instead of this one.
    await this.args.denyEntryTask.unlinked().perform(entry);
  });

  @action
  trackClickEntryDetail(entry_id: string): void {
    this.metrics.trackEvent('Entry Details Clicked', { entry_id });
  }
}
