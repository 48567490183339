import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { task, dropTask } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type ImpressionsService from 'garaje/services/impressions';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import { or } from 'macro-decorators';
import { all } from 'rsvp';

import type { SecurityIndexRouteModel } from './route';

const MINIMUM_IPAD_VERSION_NEEDED_FOR_ID_SCANNING = '3.0.7';
const MINIMUM_IPAD_VERSION_NEEDED_FOR_VISUAL_COMPLIANCE = '3.3.0';

export default class SecurityIndexController extends Controller {
  declare model: SecurityIndexRouteModel;

  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service('current-location') declare currentLocationService: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare abilities: AbilitiesService;
  @service declare impressions: ImpressionsService;
  @service declare state: StateService;

  minimumIpadVersionNeededForVisualCompliance = MINIMUM_IPAD_VERSION_NEEDED_FOR_VISUAL_COMPLIANCE;

  @tracked isEditingBlockList = false;
  @tracked isVisualComplianceOpen = false;
  @tracked idScanningHasChanges = false;
  @tracked isUpgradeModalVisible = false;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED_FOR_ID_SCANNING)
  isAppUpdateRequiredForIdScanning!: boolean;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED_FOR_VISUAL_COMPLIANCE)
  isAppUpdateRequiredForVisualCompliance!: boolean;

  get canReadFullLegalNameSetting(): boolean {
    return this.abilities.can('read full legal name setting security');
  }

  get canReadWatchlist(): boolean {
    return this.abilities.can('read watchlist security');
  }

  get canReadBlocklist(): boolean {
    return this.abilities.can('read blocklist security');
  }

  get canUpdateBlocklist(): boolean {
    return this.abilities.can('update blocklist security');
  }

  get canReadOtherSecuritySettings(): boolean {
    return this.abilities.can('read other security settings security');
  }

  get showSetupGuide(): boolean {
    return this.isAdmin && this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper');
  }

  get canViewVisitorScreeningSection(): boolean {
    return (
      this.canReadBlocklist ||
      this.canReadWatchlist ||
      this.canReadFullLegalNameSetting ||
      this.canReadOtherSecuritySettings
    );
  }

  createSalesforceLead = dropTask(async () => {
    try {
      const salesforceLeadData = {
        data: {
          type: 'salesforce-leads',
          attributes: {
            email: this.currentAdmin.email,
            'lead-source': 'Security Upgrade',
            name: this.currentAdmin.fullName,
          },
        },
      };

      await this.ajax.post(urlBuilder.v3.salesforceLeads.createUrl(), { data: salesforceLeadData });
    } catch (error) {
      this.flashMessages.showFlash('error', 'Try again', {
        details: parseErrorForDisplay(error),
      });
      throw error;
    }
  });

  toggleBlocklistTask = task(async (enabled: boolean) => {
    const location = this.model.currentLocation;

    location.blocklistEnabled = enabled;
    if (enabled) {
      this.metrics.trackEvent('Security - Block list Enable Requested');
    }
    try {
      await location.save();

      await this.impressions.postImpression.perform(
        IMPRESSION_NAMES.SECURITY_VR_SCREENING_OPTIONS_BLOCK_LIST[enabled ? 'ENABLED' : 'DISABLED'],
      );
    } catch (_e) {
      this.flashMessages.showAndHideFlash('error', `There was an issue turning block list ${enabled ? 'on' : 'off'}`);
    }
  });

  @action
  hasAccessToIDScanningFeature(): boolean {
    return !!this.state.features?.canAccessIdScanning;
  }

  @action
  closeBlockList(): void {
    this.isEditingBlockList = false;
  }

  @action
  openBlockList(): void {
    this.isEditingBlockList = true;
  }

  @action
  onUpgradeBlockList(plan: string): void {
    this.metrics.trackEvent('Security - Block list Upgrade Clicked', { plan });
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpgradeIdScanning(plan: string): void {
    this.metrics.trackEvent('Security - ID Scanning Upgrade Clicked', { plan });
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpgradeFullLegalName(plan: string): void {
    this.metrics.trackEvent('Security - Full Legal Name Upgrade Clicked', { plan });
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpgradeWatchlist(plan: string): void {
    this.metrics.trackEvent('Security - Watchlist Upgrade Clicked', { plan });
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpgradePlatformPlugin(plan: string, plugin: string): void {
    this.metrics.trackEvent(`Security - ${plugin} Upgrade Clicked`, { plan });
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpgradeVisualCompliance(): void {
    this.isUpgradeModalVisible = true;
  }

  @action
  onUpdateIdScanning({ hasChanges }: { hasChanges: boolean }): void {
    this.idScanningHasChanges = hasChanges;
  }

  @action
  async rollbackIdScanning(): Promise<void> {
    // need to toggle flag right away as confirm model does not wait
    this.idScanningHasChanges = false;
    const location = this.model.currentLocation;
    location.rollbackIdScanContacts();
    const visitorTypes = await location.flows;
    const enabledVisitorTypes = visitorTypes.filter((vt) => vt.enabled);
    const idScanPages = await all(enabledVisitorTypes.map((vt) => vt.idScanPage));
    idScanPages.filter((isp) => isp.hasDirtyAttributes).forEach((isp) => isp.rollbackAttributes());
  }
}
