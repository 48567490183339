import EmberObject from '@ember/object';
import Evented from '@ember/object/evented';
import { run } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type CookieAuthService from 'garaje/services/cookie-auth';
import type SessionService from 'garaje/services/session';
import $ from 'jquery';
import { Promise } from 'rsvp';

const EventedEmberObject = EmberObject.extend(Evented);

export default class Uploader extends EventedEmberObject {
  declare ajaxSettings: Record<string, unknown>;
  declare headers: Record<string, string>;

  @service declare session: SessionService;
  @service declare cookieAuth: CookieAuthService;

  url: string | null = null;
  method = 'POST';
  paramNamespace: string | null = null;
  paramName = 'file';
  @tracked isUploading = false;

  upload<T>(files: FileList | File | File[], extra = {}): Promise<T> {
    const data = this.createFormData(files, extra);
    const { url, method } = this;

    this.isUploading = true;
    return <Promise<T>>this.ajax(url!, data, method);
  }

  createFormData(files: FileList | File | File[], extra: Record<string, string> = {}): FormData {
    const formData = new FormData();

    for (const prop in extra) {
      if (Object.prototype.hasOwnProperty.call(extra, prop)) {
        formData.append(this.toNamespacedParam(prop), extra[prop]!);
      }
    }

    if (files.constructor === FileList || files.constructor === Array) {
      const paramKey = `${this.toNamespacedParam(this.paramName)}[]`;

      for (let i = 0; i < files.length; i++) {
        formData.append(paramKey, files[i]!);
      }
    } else {
      formData.append(this.toNamespacedParam(this.paramName), <Blob>files);
    }

    return formData;
  }

  toNamespacedParam(name: string): string {
    return this.paramNamespace ? `${this.paramNamespace}[${name}]` : name;
  }

  didUpload(data: unknown): unknown {
    this.isUploading = false;
    this.trigger('didUpload', data);
    return data;
  }

  didError(jqXHR: Record<string, unknown> | null, textStatus: string, errorThrown: string): unknown {
    this.isUploading = false;

    const isObject = jqXHR !== null && typeof jqXHR === 'object';

    if (isObject) {
      jqXHR['then'] = null;
      if (!jqXHR['errorThrown']) {
        if (typeof errorThrown === 'string') {
          jqXHR['errorThrown'] = new Error(errorThrown);
        } else {
          jqXHR['errorThrown'] = errorThrown;
        }
      }
    }

    this.trigger('didError', jqXHR, textStatus, errorThrown);

    return jqXHR;
  }

  didProgress(event: ProgressEvent<EventTarget> & { percent?: number }): void {
    event.percent = (event.loaded / event.total) * 100;
    this.trigger('progress', event);
  }

  abort(): void {
    this.isUploading = false;
    this.trigger('isAborting');
  }

  ajax(url: string, params = {}, method = this.method): Promise<unknown> {
    const headers = this.headers || { Accept: 'application/json; charset=utf-8' };

    if (this.session.isAuthenticated) {
      headers['Authorization'] = `Bearer ${this.session.data.authenticated['access_token']}`;
    }

    const ajaxSettings = Object.assign(
      {},
      {
        contentType: false,
        processData: false,
        headers,
        xhr: () => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
          const xhr = <XMLHttpRequest>$.ajaxSettings.xhr();
          xhr.upload.onprogress = (e) => {
            this.didProgress(e);
          };
          this.one('isAborting', () => xhr.abort());
          return xhr;
        },
        url,
        data: params,
        method,
      },
      this.ajaxSettings,
    );

    return this.ajaxPromise(this.cookieAuth.jQueryDecorator(url, method, ajaxSettings));
  }

  ajaxPromise(settings: Record<string, unknown>): Promise<unknown> {
    return new Promise((resolve, reject) => {
      settings['success'] = (data: unknown) => {
        run(null, resolve, this.didUpload(data));
      };

      settings['error'] = (jqXHR: Record<string, unknown>, responseText: string, errorThrown: string) => {
        run(null, reject, this.didError(jqXHR, responseText, errorThrown));
      };

      // eslint-disable-next-line ember/no-jquery, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      $.ajax(settings);
    });
  }
}
