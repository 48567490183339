import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import InviteStateLabel from 'garaje/utils/invite-state-label';
import { INVITE_STATE } from 'garaje/utils/enums';
import _cloneDeep from 'lodash/cloneDeep';

/**
 * @param {gqlEntry}          entry             graphql representation of an entry
 * @param {gqlInvite}         invite            graphql representation of an invite
 * @param {Number}            maxOccupancy      amount allowed in the office
 * @param {Number}            peopleRegistered  total amount of people with invites for the day (not desk reservations)
 */
export default class ScheduleCardComponent extends Component {
  @service flashMessages;
  @service state;
  @service metrics;
  @service registrations;

  @tracked unscheduleModalIsOpen = false;
  @tracked unscheduleError = false;
  INVITE_STATE_ENUM = INVITE_STATE;

  get spotsRemaining() {
    return this.args.maxOccupancy - this.args.peopleRegistered;
  }

  get atCapacity() {
    return this.spotsRemaining === 0;
  }

  get schedulingBlocked() {
    return this.atCapacity && this.state.currentLocation.capacityLimitEnabled;
  }

  get inviteStateLabel() {
    return InviteStateLabel(this.args.inviteState);
  }

  get showDelegatedBookerName() {
    return this.delegatedBookerName;
  }

  get delegatedBookerName() {
    const { invite } = this.args;
    if (!invite?.employee?.email || !invite?.visitor?.email) {
      return null;
    }

    if (invite.employee.email === invite.visitor.email) {
      return null;
    }

    return invite.employee.name;
  }

  signInInviteTask = task({ drop: true }, async () => {
    const { screeningCard, reservation, date } = this.args;

    try {
      const entry = await this.registrations.signInInvitePartialDay(date);
      this.metrics.trackEvent('Web Employee Schedule - Sign In', {
        desk_id: reservation?.desk.id,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location.id,
        invite_date: screeningCard.expectedArrivalTime,
        invite_id: screeningCard.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed in');
    } catch (e) {
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  signOutEntryTask = task({ drop: true }, async () => {
    const { date, reservation, screeningCard } = this.args;

    try {
      const entry = await this.registrations.signOutEntryPartialDay(date);
      this.metrics.trackEvent('Web Employee Schedule - Sign Out', {
        desk_id: reservation?.desk.id,
        employee_entry_sign_out_at: entry.signedOutAt,
        entry_date: entry.signed,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location.id,
        invite_id: screeningCard.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed out');
    } catch (e) {
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  unscheduleTask = task({ drop: true }, async () => {
    this.unscheduleError = false;

    try {
      const { invite } = await this.registrations.unschedule(this.args.date, {});
      this.metrics.trackEvent('Web Employee Schedule - Unschedule', {
        desk_id: null,
        invite_date: invite.expectedArrivalTime,
        invite_id: invite.id,
        invite_location_id: invite.location.id,
        reservation_id: null,
      });
      this.unscheduleModalIsOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Reservation released');
    } catch (e) {
      this.unscheduleError = true;
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  scheduleTask = task({ drop: true }, async () => {
    const { date, bookDeskEnabled } = this.args;
    try {
      const { reservation, invite } = await this.registrations.reserveDeskPartialDay(date);
      this.metrics.trackEvent('Web Employee Schedule - Schedule', {
        desk_id: reservation?.desk.id,
        invite_date: invite.expectedArrivalTime,
        invite_id: invite.id,
        invite_location_id: invite.location.id,
        reservation_id: reservation?.id,
      });
      if (reservation || !bookDeskEnabled) {
        this.flashMessages.showAndHideFlash('success', 'Successfully scheduled');
      } else {
        this.flashMessages.showAndHideFlash('error', 'Desk could not be booked at this time');
      }
    } catch (e) {
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  @action
  closeUnscheduleModal() {
    this.unscheduleError = false;
    this.unscheduleModalIsOpen = false;
  }
}
