import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

import type { ProfileDocumentUploadRouteModel } from '../route';

export default class ProfileDocumentUploadReviewRoute extends Route {
  @service declare router: RouterService;

  afterModel({ userDocument, userDocumentTemplate }: ProfileDocumentUploadRouteModel): void {
    const requiredTemplateAttachment = userDocument?.checkRequiredTemplateAttachments();

    if (requiredTemplateAttachment) {
      void this.router.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        requiredTemplateAttachment.identifier,
      );
    }
  }
}
