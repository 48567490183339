import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';

export default class ResourcesIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;

  beforeModel(): void {
    if (this.abilities.cannot('visit resources')) {
      return void this.router.transitionTo('unauthorized');
    }
  }
}
