/* eslint-disable no-console */
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type LocationsService from 'garaje/services/locations';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

import type AnalyticsOccupancyIndexController from './controller';
export interface AnalyticsOccupancyRouteParams {
  location_id: string;
}

export default class AnalyticsOccupancyIndexRoute extends Route {
  title = 'Occupancy · Analytics · Envoy';

  @service declare state: StateService;
  @service declare store: Store;
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare locations: LocationsService;
  @service declare statsig: StatsigService;

  queryParams = {
    location_id: { refreshModel: true },
  };

  setupController(controller: AnalyticsOccupancyIndexController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    const { location_id } = <AnalyticsOccupancyRouteParams>this.paramsFor(this.routeName);
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const protectedController = this.controllerFor('protected');

    if (location_id && this.state.currentLocation.id != location_id) {
      protectedController.send('switchLocation', location_id, 'analytics.occupancy.index');

      this.locations.loadAllTask
        .perform()
        .then(() => {
          const matchingLocation = this.locations.allActive.filter((entry) => entry.id === location_id);
          if (matchingLocation.length === 0) {
            this.router.transitionTo('unauthorized');
          }
        })
        .catch((error) => {
          throw error;
        });
    }

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  activate(transition: Transition): void {
    super.activate(transition);
  }

  redirect(): void {
    // using the negative rather than affirmative to preserve code functionality from before
    const cannotUserVisitOccupancyAnalytic = this.abilities.cannot('visit occupancy-analytic');
    this.workplaceMetrics.trackEvent('OCCUPANCY_DASHBOARD_VISITED', { cannotUserVisitOccupancyAnalytic });

    if (cannotUserVisitOccupancyAnalytic) {
      this.router.transitionTo('unauthorized');
    } else if (this.abilities.cannot('visit-occupancy analytic')) {
      this.router.transitionTo('unauthorized');
    }
  }

  deactivate(transition: Transition): void {
    super.deactivate(transition);
    const { location_id } = <AnalyticsOccupancyRouteParams>this.paramsFor(this.routeName);
    if (location_id) {
      this.router.transitionTo({ queryParams: { location_id: null } });
    }
  }
}
