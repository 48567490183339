import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { all, dropTask, enqueueTask } from 'ember-concurrency';
import type EntryModel from 'garaje/models/entry';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { fetchCapacity } from 'garaje/utils/locations-capacity';

interface DeleteEntryComponentSignature {
  Args: {
    entries: EntryModel[];
    continue: (options: { entries: EntryModel[]; deleteGroup: boolean }) => void;
    cancel: () => void;
  };
}

export default class DeleteEntryComponent extends Component<DeleteEntryComponentSignature> {
  @service declare ajaxFetch: AjaxFetchService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare store: StoreService;

  get hasGroupParent(): boolean {
    return Boolean(this.args.entries.find((entry) => entry.hasAdditionalGuests));
  }

  deleteEntriesTask = enqueueTask(
    {
      maxConcurrency: 10,
    },
    async (entry: EntryModel, deleteGroup: boolean) => {
      if (entry?.signInTime) {
        // @ts-ignore
        const url = this.store.adapterFor('entry').urlForDeleteRecord(entry.id, 'entry');

        // This API is not done in the backend yet
        // we are assuming it for now
        await this.ajaxFetch.request(url, { type: 'DELETE', data: { 'delete-group': deleteGroup } });
      }
    },
  );

  deleteTask = dropTask(async (entries: EntryModel[], deleteGroup: boolean) => {
    try {
      await all(entries.map((entry) => this.deleteEntriesTask.perform(entry, deleteGroup)));

      if (entries.length > 1) {
        const entry_count = entries.reduce((count, entry) => count + entry.additionalGuests, entries.length);
        this.metrics.trackEvent('Bulk Entries Deleted', { entry_count });
      }

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.args.continue({ entries, deleteGroup });
      const { currentLocation } = this.state;
      if (currentLocation.capacityLimitEnabled) {
        await fetchCapacity(this.store, currentLocation, entries[0]!.signedInAt);
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  closeModal(): void {
    this.args.cancel();
  }
}
