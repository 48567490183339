import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type CompanyModel from './company';

@adapter('billing-v1')
class CouponCodeModel extends Model {
  @attr('string') declare couponSetId: string;
  @attr('string') declare name: string;
  @attr('string') declare durationType: string;
  @attr('number') declare period: number;
  @attr('string') declare periodUnit: string;
  @attr('date') declare validTill: Date;
  @attr('string') declare status: string;

  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
}

export default CouponCodeModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'coupon-code': CouponCodeModel;
  }
}
