import type Array from '@ember/array';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

import type Company from './company';

@adapter('rooms-v2')
@serializer('rooms')
class RoomLocationConfig extends Model {
  @belongsTo('company', { async: true })
  declare company: AsyncBelongsTo<Company>;

  @belongsTo('location', { async: true })
  declare location: AsyncBelongsTo<Model>;

  @attr('array') declare notificationEmails: Array<string>;
  @attr('boolean') declare globalAdminNotificationsEnabled: boolean;
  @attr('boolean') declare locationAdminNotificationsEnabled: boolean;
}

export default RoomLocationConfig;
