import Route from '@ember/routing/route';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { routeEvent } from 'garaje/utils/decorators/route';
import { hash } from 'rsvp';
import { get, setProperties } from '@ember/object';

export default class WorkplaceSettingsEmployeesRegistrationIndexRoute extends Route {
  title = 'Workplace · Employee registration manager · Envoy';

  @service abilities;
  @service flow;
  @service router;
  @service skinnyLocations;
  @service state;
  @service transitionConfirm;
  @service store;
  @service featureFlags;

  async beforeModel() {
    if (this.abilities.cannot('visit settings for workplace')) {
      return this.router.transitionTo('workplace');
    }
    return await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model() {
    const userDocumentTemplates = await this.store.findAll('user-document-template');
    const covid19VaccineCardDocumentTemplate = userDocumentTemplates.findBy('identifier', 'covid-19-vaccine-card');
    const covid19TestResultDocumentTemplate = userDocumentTemplates.findBy('identifier', 'covid-19-test-result');
    const locationId = this.state.currentLocation.id;

    let connectLocationConfiguration;
    const connectedTenants = await this.state.currentLocation.getPropertyConnections();

    if (connectedTenants.length) {
      // will create the value in BE if it doesn't exist
      connectLocationConfiguration = await this.state.currentLocation.getConnectConfiguration();
    }

    // TODO: Move this configuration fetching / computation logic to a shared user document
    //       service with identifiable conventions [VIS-3953]
    // TODO: Support polymorphic association between configuration record and Location / Company
    //       to enable fetching all relevant configurations in a single request instead of
    //       one-by-one [VIS-3954]
    const {
      covidTestConfigurations,
      vaccineConfigurations,
      employeeRegistrationConfiguration,
      pluginInstalls,
      plugins,
    } = await hash({
      covidTestConfigurations: covid19TestResultDocumentTemplate?.id
        ? this.store.query('user-document-template-configuration', {
            filter: {
              'user-document-template-id': covid19TestResultDocumentTemplate.id,
              'location-id': locationId,
            },
          })
        : [],
      vaccineConfigurations: covid19VaccineCardDocumentTemplate?.id
        ? this.store.query('user-document-template-configuration', {
            filter: {
              'user-document-template-id': covid19VaccineCardDocumentTemplate.id,
              'location-id': locationId,
            },
          })
        : [],
      employeeRegistrationConfiguration: await this.store.query('employee-registration-configuration', {
        filter: {
          'location-id': locationId,
        },
      }),
      pluginInstalls: await this.store.query('plugin-install', {
        filter: { location: locationId },
        page: { limit: 100 },
      }),
      plugins: this.store.query('plugin', { filter: { location: locationId } }),
    });

    return hash({
      covidTestDocumentTrackingConfiguration: get(covidTestConfigurations, 'firstObject'),
      vaccineDocumentTrackingConfiguration: get(vaccineConfigurations, 'firstObject'),
      employeeRegistrationConfiguration: get(employeeRegistrationConfiguration, 'firstObject'),
      pluginInstalls,
      plugins,
      connectLocationConfiguration,
      currentLocation: this.state.currentLocation,
    });
  }

  setupController(controller, model) {
    this.flow.setSelectedIfNoneAndOnlyOneChoice();
    controller.setMetaInStorage();
    this.controller.showWelcomeBanner = controller.shouldShowWelcomeBanner();
    setProperties(controller, {
      model: model,
    });
  }

  @routeEvent
  routeWillChange(transition) {
    const { canSave, isOpen, rollbackAll } = this.controller;

    if (canSave && isOpen && transition.targetName !== 'workplace.settings.employees.registration.edit') {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          rollbackAll();
        },
      });
    }
    return true;
  }
}
