import type { Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import type MailerTemplateModel from 'garaje/models/mailer-template';

import ApplicationSerializer from './application';

export default class MailerTemplateSerializer extends ApplicationSerializer {
  attrs = {
    mailerSections: {
      serialize: true,
    },
    active: {
      serialize: false,
    },
    envoyDefault: {
      serialize: false,
    },
    description: {
      serialize: false,
    },
    identifier: {
      serialize: false,
    },
    subject: {
      serialize: false,
    },
    subtitle: {
      serialize: false,
    },
  };

  // Avoid 400 error. Do not serialize envoyDefaultId for existing records
  serializeAttribute(snapshot: Snapshot<keyof ModelRegistry>, json: object, key: string, attribute: object): void {
    const record = <MailerTemplateModel>snapshot.record;

    if (!record?.isNew && key === 'envoyDefaultId') return;

    super.serializeAttribute(snapshot, json, key, attribute);
  }

  modelNameFromPayloadKey(key: string): string {
    if (key === 'locations') {
      return 'skinny-location';
    } else {
      return super.modelNameFromPayloadKey(key);
    }
  }

  payloadKeyFromModelName<K extends keyof ModelRegistry>(modelName: K): string {
    if (modelName === 'skinny-location') {
      return 'locations';
    } else {
      return super.payloadKeyFromModelName(modelName);
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'mailer-template': MailerTemplateSerializer;
  }
}
