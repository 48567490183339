import ApplicationSerializer from './application';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';

export default class GlobalSignInFieldSerializer extends ApplicationSerializer {
  @service() featureFlags;

  get attrs() {
    const attrs = {
      localized: {
        serialize: false,
      },
      actionableSignInFields: {
        serialize: false,
      },
      signInFieldActions: {
        serialize: false,
      },
    };

    return attrs;
  }

  modelNameFromPayloadKey(key) {
    if (key === 'flows') {
      return 'global-flow';
    } else if (key === 'sign-in-field-pages') {
      return 'global-sign-in-field-page';
    } else if (key === 'sign-in-fields') {
      return 'global-sign-in-field';
    } else {
      return super.modelNameFromPayloadKey(...arguments);
    }
  }
  normalize(typeClass, hash) {
    if (hash.relationships['actionable-sign-in-field-actions'].data.length) {
      const data = hash.relationships['actionable-sign-in-field-actions'].data;
      data.forEach(function (item) {
        item.type = `global-${item.type}`;
      });
    }
    if (hash.relationships['actionable-sign-in-fields'].data.length) {
      const data = hash.relationships['actionable-sign-in-fields'].data;
      data.forEach(function (item) {
        item.type = `global-${item.type}`;
      });
    }
    if (hash.relationships['sign-in-field-actions'].data.length) {
      const data = hash.relationships['sign-in-field-actions'].data;
      data.forEach(function (item) {
        item.type = `global-${item.type}`;
      });
    }
    return super.normalize(...arguments);
  }

  payloadKeyFromModelName(modelName) {
    if (modelName === 'global-flow') {
      return 'flows';
    } else if (modelName === 'global-sign-in-field-page') {
      return 'sign-in-field-pages';
    } else if (modelName === 'global-sign-in-field') {
      return 'sign-in-fields';
    } else {
      return super.payloadKeyFromModelName(...arguments);
    }
  }

  serializeIntoHash(hash, type, snapshot, options) {
    const payload = this.serialize(snapshot, options);
    const {
      data: { attributes },
    } = payload;

    if (!isEmpty(attributes.options)) {
      attributes.options.forEach((opt) => {
        opt.app = 'garaje'; // `app` param signfies to envoy-web to always create id's for dropdown options.
        if (opt.customTranslations) {
          opt['custom-translations'] = opt.customTranslations;
        }
        delete opt.customTranslations;
      });
    }
    Object.assign(hash, payload);
  }
}
