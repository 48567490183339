import Service from '@ember/service';
import { get, setProperties } from '@ember/object';
import { reject } from 'rsvp';
import { isBlank } from '@ember/utils';

import geocode from 'garaje/utils/geocode';
import tzlookup from 'garaje/utils/tzlookup';

const GEOCODE_ERROR_MAP = {
  ZERO_RESULTS: 'No results found for this address',
  OVER_QUERY_LIMIT: 'Maximum address lookup API reached, try again later',
  REQUEST_DENIED: 'Problem with lookup API, request denied',
  INVALID_REQUEST: 'Problem with lookup API, request invalid',
};

export default class AddressLookupService extends Service {
  geocoder = geocode;

  geocodeError(errorCode) {
    return GEOCODE_ERROR_MAP[errorCode] || `Unknown error ${errorCode}`;
  }

  updateAddress(inputAddress, model) {
    if (isBlank(inputAddress)) {
      return reject('Enter an address');
    }

    const self = this;
    let properties = {};

    return this.geocoder(inputAddress)
      .then(function (data) {
        const components = data.parsedAddress;
        properties = {
          latitude: data.latitude,
          longitude: data.longitude,
          address: inputAddress,
          addressLineOne: components.line1,
          city: components.city,
          state: components.state,
          zip: components.zip,
          country: components.country_code,
          googlePlaceId: data.place_id,
        };

        if (get(self, 'overwriteInputAddress')) {
          properties.inputAddress = data.address;
        }

        return tzlookup(data.latitude, data.longitude);
      })
      .then(function (data) {
        properties.timezone = data.timeZoneId;
      })
      .catch(function (errorCode) {
        throw self.geocodeError(errorCode);
      })
      .finally(function () {
        setProperties(model, properties);
      });
  }
}
