import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import { gt, alias } from 'macro-decorators';
import newRoleAssignmentChangeset from 'garaje/changesets/new-role-assignment';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { adminRoute } from 'garaje/utils/admin-routing';

export default class EmployeesAdminRolesEditController extends Controller {
  @service currentAdmin;
  @service flashMessages;
  @service metrics;
  @service router;

  @tracked roleAssignments = A();
  @tracked searchFilter;
  @tracked employee;

  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;

  @gt('unsavedRoles.length', 0) hasUnsavedChanges;

  get filteredRoles() {
    if (isBlank(this.searchFilter)) {
      return this.roleAssignments;
    }
    const lowerSearchFilter = this.searchFilter.toLowerCase();

    return this.roleAssignments.filter((role) => {
      const name = role.roleScopeName?.toLowerCase();
      return name.includes(lowerSearchFilter);
    });
  }

  get unsavedRoles() {
    return this.roleAssignments.filter((r) => get(r, 'isNew') || get(r, 'isDirty'));
  }

  get hasSyncedRoles() {
    return this.roleAssignments.any((role) => !role.manuallyAdded);
  }

  confirmRoleTask = task({ drop: true }, async (changeset) => {
    try {
      await changeset._content.deliverConfirmationEmail();
      this.flashMessages.showAndHideFlash('success', 'Invite re-sent!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  deleteRoleTask = task({ drop: true }, async (changeset) => {
    if (get(changeset, 'isNew')) {
      this.roleAssignments.removeObject(changeset);
      changeset._content.deleteRecord();
    } else {
      try {
        this.roleAssignments.removeObject(changeset);
        await changeset._content.destroyRecord();
        this.flashMessages.showAndHideFlash('success', 'Role deleted');
      } catch (e) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
    }
  });

  fetchRolesTask = task({ drop: true }, async (/* user */) => {
    try {
      const { roleAssignments } = this.model;
      const roleAssignmentChangesets = roleAssignments.map(newRoleAssignmentChangeset);
      this.roleAssignments = roleAssignmentChangesets;
    } catch (e) {
      this.roleAssignments = [];
    }
  });

  saveRoleTask = task({ drop: true }, async (changeset) => {
    await changeset.validate();
    if (get(changeset, 'isInvalid')) {
      return;
    }

    try {
      await changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  closeModal() {
    this.send('refreshModelOnParent');

    const path = adminRoute(this.router.currentRouteName);
    return this.router.transitionTo(path);
  }

  @action
  refreshModelOnParent() {
    this.send('refreshModel');
  }

  @action
  trackAndTransitionToBulk(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const { user } = this.model;

    this.metrics.trackEvent('Admin Roles - Add Admin Roles Button Clicked', {
      selected_admin_name: user.fullName,
      selected_admin_user_id: user.id,
      current_roles: this.roleAssignments.map((role) => role.roleId),
    });

    const path = adminRoute(this.router.currentRouteName);
    this.router.transitionTo(`${path}.bulk`, { queryParams: { user_id: user.id } });
  }

  @action
  routeToScimSettings(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.router.transitionTo('employees.scim-settings');
  }

  reset() {
    this.unsavedRoles.forEach((changeset) => {
      if (get(changeset, 'isNew')) {
        changeset._content.deleteRecord();
      } else {
        changeset.rollback();
      }
    });
    this.employee = null;
  }
}
