import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { alias, empty } from 'macro-decorators';
import { task, dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { v1 as uuid } from 'uuid';

/**
 * Form for purchasing additional subscription quantity
 * @param {Array<String>}        activeUnits         List of names of active units (locations/delivery areas/etc)
 * @param {String}               newPeriod           The new period the user is changing to
 * @param {Class<PaymentSource>} paymentSource       A payment source model
 * @param {Class<Subscription>}  subscription        A subscription model
 * @param {Function}             transitionToBilling Action to transition to billing
 * @param {String}               viaLabel            Text on the CTA used to open this modal
 * @param {String}               viaObject           Type of CTA used to open this modal (button or text_link)
 */

export default class BillingPeriodModal extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  @alias('fetchEstimateTask.lastSuccessful.value') estimate;
  @empty('estimate') isLoading;

  @tracked trackingId;

  get title() {
    return `Switch to ${this.args.newPeriod} billing`;
  }

  @action
  onInsert() {
    this.trackingId = uuid();
    this.fetchEstimateTask.perform();
    this.trackModalEnteredTask.perform();
  }

  @task
  *fetchEstimateTask() {
    try {
      const estimate = yield this.store
        .createRecord('subscription-estimate', {
          app: this.args.subscription.app,
          quantity: this.args.subscription.quantity,
          plan: this.args.subscription.plan,
          period: this.args.newPeriod,
          subscription: this.args.subscription,
        })
        .save();
      return estimate;
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *handleSubmitTask(event) {
    event.preventDefault();

    if (this.fetchEstimateTask.isRunning) {
      return;
    }

    try {
      const oldPeriod = this.args.subscription.period;
      set(this.args.subscription, 'period', this.args.newPeriod);
      yield this.args.subscription.save();
      this.flashMessages.showAndHideFlash('success', 'Billing period updated');
      this.args.transitionToBilling();
      this.metrics.trackEvent('Account modified', {
        product: this.args.subscription.app,
        account_change_type: 'subscription',
        account_change_object: 'period',
        account_change_action: 'updated',
        object_change_from: oldPeriod,
        object_change_to: this.args.newPeriod,
      });
    } catch (e) {
      this.args.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *trackModalEnteredTask() {
    yield this.metrics.trackPage('Modal entered', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'change_billing_period', // purpose of modal
      via_object: this.args.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.args.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
    });
  }
}
