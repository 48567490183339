import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type HomepageGroup from 'garaje/models/homepage-group';
import type ImpressionsService from 'garaje/services/impressions';
import type MetricsService from 'garaje/services/metrics';
import type StatsigService from 'garaje/services/statsig';
import { type RecordArray } from 'garaje/utils/type-utils';
import { localCopy } from 'tracked-toolbox';

export default class HomepageGroupContainer extends Component {
  @service declare statsig: StatsigService;
  @service declare store: Store;
  @service declare impressions: ImpressionsService;
  @service declare metrics: MetricsService;
  @localCopy('args.homepageGroups') declare homepageGroups: RecordArray<HomepageGroup>;

  @action
  async logEvent(eventName: string, eventValue: string | null, metadata = {}): Promise<void> {
    this.statsig.logEvent(eventName, eventValue, metadata);
    await this.impressions.postImpression.perform(`${eventName}_${eventValue}`);
    this.metrics.trackEvent(eventName, { eventValue });
  }

  reloadGroupsTask = task({ drop: true }, async () => {
    const homepageGroups = <RecordArray<HomepageGroup>>await this.store.query('homepage-group', {
      include:
        'homepage-item-group-mappings.homepage-item.setup-guide-items,homepage-item-group-mappings.homepage-item.marketing-items,homepage-item-group-mappings.homepage-item.helpdesk-items.helpdesk-questions,homepage-item-group-mappings.homepage-item.help-resources-items',
    });

    this.homepageGroups = homepageGroups;
  });
}
