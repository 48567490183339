/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias } from '@ember/object/computed';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { Promise as EmberPromise } from 'rsvp';

import SyncSettingsObject from 'garaje/utils/decorators/sync-settings-object';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * @param {Model<Company>}            company
 * @param {Array}                     locations
 * @param {Array}                     filterOptions
 * @param {Array}                     directoryGroups
 * @param {Function}                  showFlash
 * @param {Function}                  hideFlash
 * @param {Function}                  handleFailedQuery
 * @param {Function}                  addFilter
 * @param {Function}                  removeFilter
 */
@SyncSettingsObject
class OktaIntegration extends Component {
  @service ajax;
  @service syncManager;

  @alias('company.oktaIntegration') integrationData;

  filterComponents = [
    'employees/sync-settings/all-filter',
    'employees/sync-settings/company-filter',
    'employees/sync-settings/locations-filter',
  ];

  @action
  async preview(integration) {
    const payload = {
      data: {
        type: get(integration, 'filterType'),
        data: get(integration, 'filters').map((filter) => filter.serialize()),
      },
    };

    const postUsersUrl = urlBuilder.v2.integrations.oktaUsersUrl(integration.id);

    await this.ajax.request(postUsersUrl, {
      type: 'POST',
      data: JSON.stringify(payload),
      contentType: 'application/json',
    });

    const fetchOktaEmployeesUrl = await new EmberPromise((resolve) => {
      this.syncManager.one('okta#preview_completed', (fetchOktaEmployeesUrl) => {
        resolve(fetchOktaEmployeesUrl);
      });
    });

    const employees = await this.ajax.request(fetchOktaEmployeesUrl, {
      type: 'GET',
      contentType: 'application/json',
    });

    return employees;
  }
}

export default OktaIntegration;
