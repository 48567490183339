import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { MessageType } from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type MetricsService from 'garaje/services/metrics';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { AnalyticsEventNames } from 'garaje/utils/enums';
import { Workbook } from 'garaje/utils/sigma-workbooks';
import urlBuilder from 'garaje/utils/url-builder';

type GlobalOccupancyMessage = {
  event: 'showToaster' | 'showOccupancyCopyLinkModal' | 'closeOccupancyCopyLinkModal';
  type?: string; // optional, used when event is 'showToaster'
  msg?: string; // optional, used when event is 'showToaster'
  location_id?: string; // optional, used when event is 'showOccupancyCopyLinkModal'
};

export default class LocationOverviewOccupancyAnalyticsIndexController extends Controller {
  @service declare messageBus: MessageBusService;
  @service declare state: StateService;
  @service declare localStorage: LocalStorageService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked modalIframeUrl = '';
  @tracked showCopyLinkModal = false;
  @tracked loginRequired = false;
  @tracked isError = false;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;

    return urlBuilder.embeddedOccupancyGlobalAnalyticsUrl(companyId, this.loginRequired);
  }

  fetchSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const result = await this.sigmaUrl.getGlobalSigmaUrl(companyId, Workbook.GLOBAL_OCCUPANCY);
      return result;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      this.isError = true;
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });

  get sigmaIframeUrl(): string | null | undefined {
    return this.fetchSigmaUrlTask.last?.value;
  }

  logSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const details = {
        companyId,
        app: this.state.workplaceSubscription?.app,
        plan: this.state?.workplaceSubscription?.plan,
        userId: this.currentAdmin?.id || '',
        employeeId: this.currentAdmin.employee?.id || '',
      };
      this.metrics.trackEvent(AnalyticsEventNames.OCCUPANCY_GLOBAL_DASHBOARD_VISITED, details);
      this.metrics.logMonitorEvent(AnalyticsEventNames.OCCUPANCY_GLOBAL_DASHBOARD_VISITED, details);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });

  @action
  handleMessage(message: GlobalOccupancyMessage): void {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    if (message.event === 'showToaster') {
      const type = message.type as MessageType;
      const msg = message.msg || '';
      this.flashMessages.showAndHideFlash(type, msg);
    } else if (message.event === 'showOccupancyCopyLinkModal') {
      const copyUrl = window.location.href;
      this.modalIframeUrl = urlBuilder.embeddedOccupancyCopyLinkModal(copyUrl, this.loginRequired, companyId, '');
      this.showCopyLinkModal = true;
    } else if (message.event === 'closeOccupancyCopyLinkModal') {
      this.showCopyLinkModal = false;
    }
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
