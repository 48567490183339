import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class GlobalAnalyticsDesksRoute extends Route {
  @service declare skinnyLocations: SkinnyLocationsService;

  beforeModel(): void {
    // needed for global-analytics ability
    this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}
