import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask, timeout } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type FlowModel from 'garaje/models/flow';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type { TransientRecord } from 'jsonapi/response';
import { uniqueBy } from 'macro-decorators';
import type Location from 'utils/location-record';

const FILTER_FLOW_TYPES = [
  { name: 'All flows', value: 'ALL' },
  { name: 'Global flows', value: 'GLOBAL' },
  { name: 'Local flows', value: 'LOCAL' },
];

interface FlowParams {
  include: string;
  fields: Record<string, string>;
  sort: string;
  filter: { type?: string; name?: string; 'location-id'?: string };
  perPage: number;
  startingPage: number;
  perPageParam: string;
  pageParam: string;
  countParam: string;
  storeFindMethod: string;
}

type PaginatedFlows = PaginatedRecordArray<TransientRecord<FlowModel>>;

export default class extends Component {
  @service declare skinnyLocations: SkinnyLocationsService;

  @alias('infinityModel.content') allFlows!: PaginatedFlows['content'];
  @alias('infinityModel.meta') meta!: PaginatedFlows['meta'];
  @alias('skinnyLocations.readableByCurrentAdmin')
  availableLocations!: SkinnyLocationsService['readableByCurrentAdmin'];
  @uniqueBy('availableLocations', 'id') uniqLocations!: SkinnyLocationsService['readableByCurrentAdmin'];

  @tracked selectedLocationIds = '';
  @tracked selectedFilterFlowType = FILTER_FLOW_TYPES[0];
  @tracked searchValue = '';
  @tracked infinityModel: PaginatedFlows | null = null;

  queryModelName = 'flow';

  get queryParams(): FlowParams {
    const params: FlowParams = {
      include: 'location,global-flow.locations',
      fields: {
        flows: 'name,type,enabled,location,global-flow,position',
        locations: 'name,disabled',
        'global-flows': 'name,enabled,locations',
      },
      sort: 'location.name,position',
      filter: {},
      perPage: 20,
      startingPage: 0,
      perPageParam: 'page[limit]',
      pageParam: 'page[offset]',
      countParam: 'meta.total',
      storeFindMethod: 'queryTransient',
    };

    if (this.selectedLocationIds) {
      params.filter['location-id'] = this.selectedLocationIds;
    }

    if (this.selectedFilterFlowType?.value !== 'ALL') {
      if (this.selectedFilterFlowType?.value === 'LOCAL') {
        params.filter.type = 'local';
      } else {
        params.filter.type = 'Flows::GlobalChild';
      }
    }

    if (this.searchValue) {
      params.filter.name = this.searchValue;
    }

    return params;
  }

  get filterFlowTypes(): typeof FILTER_FLOW_TYPES {
    return FILTER_FLOW_TYPES;
  }

  @action
  selectLocationsFilter(options: Location[]): void {
    let ids = this.selectedLocationIds.split(',').filter((el) => isPresent(el));

    options.forEach((option) => {
      ids = ids.includes(option.id) ? ids.filter((id) => id !== option.id) : [...ids, option.id];
    });

    this.selectedLocationIds = ids.join(',');
  }

  @action
  changeSelectedFlowFilter(option: (typeof FILTER_FLOW_TYPES)[number]): void {
    this.selectedFilterFlowType = option;
  }

  @action
  setInfinityModel(_el: HTMLElement, [infinityModel]: [PaginatedFlows]): void {
    this.infinityModel = infinityModel;
  }

  @action
  clearFilters(): void {
    this.selectedLocationIds = '';
    this.selectedFilterFlowType = FILTER_FLOW_TYPES[0];
    this.searchValue = '';
  }

  filterByNameTask = restartableTask(async ({ target }: { target: HTMLInputElement }) => {
    await timeout(500);
    this.searchValue = target.value;
  });
}
