import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type HelpdeskItem from './helpdesk-item';

@adapter('growth')
class HelpdeskQuestion extends Model {
  @attr('string')
  declare identifier: string;

  @attr('string')
  declare question: string;

  @attr('boolean')
  declare enabled: boolean;

  @attr('number')
  declare rank: number;

  @attr('immutable', {
    defaultValue: () => ({}),
  })
  declare metadata: object;

  @belongsTo('helpdesk-item', { async: true })
  declare helpdeskItem: AsyncBelongsTo<HelpdeskItem>;
}

export default HelpdeskQuestion;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'helpdesk-question': HelpdeskQuestion;
  }
}
