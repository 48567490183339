import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldActionRuleGroupModel from 'garaje/models/abstract/abstract-sign-in-field-action-rule-group';
import type FlowModel from 'garaje/models/flow';
import type SignInFieldActionModel from 'garaje/models/sign-in-field-action';
import type SignInFieldActionContactModel from 'garaje/models/sign-in-field-actions-contact';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class SignInFieldActionRuleGroupModel extends AbstractSignInFieldActionRuleGroupModel {
  @belongsTo('flow', { async: true }) declare flow: AsyncBelongsTo<FlowModel>;
  @hasMany('sign-in-field-actions', { async: true }) declare signInFieldActions: AsyncHasMany<SignInFieldActionModel>;
  @hasMany('sign-in-field-actions-contact', { async: true })
  declare signInFieldActionsContacts: AsyncHasMany<SignInFieldActionContactModel>;
}

export default SignInFieldActionRuleGroupModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sign-in-field-action-rule-group': SignInFieldActionRuleGroupModel;
  }
}
