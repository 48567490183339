import Component from '@glimmer/component';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import { reads, gt } from 'macro-decorators';

interface BlocklistColumnComponentSignature {
  Args: {
    content: EntryModel | InviteModel;
  };
}

export default class BlocklistColumnComponent extends Component<BlocklistColumnComponentSignature> {
  @reads('args.content.approvalStatus.status') status!: string;
  @reads('args.content.approvalStatus.reviewerName') reviewerName!: string;
  @reads('args.content.approvalStatus.reviewedAt') reviewedAt!: Date;
  @gt('args.content.approvalStatus.blocklistReport.length', 0) isEnabled!: boolean;

  get isBlocklistEntry(): boolean {
    const enabled = this.isEnabled;
    if (!enabled) {
      return false;
    }

    const reports = this.args.content?.approvalStatus?.blocklistReport;
    return reports?.[0]?.result === 'fail';
  }
}
