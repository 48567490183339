import Service, { service } from '@ember/service';
import type CookiesService from 'ember-cookies/services/cookies';
import config from 'garaje/config/environment';

/*
 * Write cookie for envoy.com to know dashboard user is logged in
 */
export default class CookieForLoginService extends Service {
  @service declare cookies: CookiesService;

  write(): void {
    const cookieService = this.cookies;
    const { cookieDomain: domain } = config;
    cookieService.write('loggedIn', new Date().getTime(), { domain });
  }

  clear(): void {
    const cookieService = this.cookies;
    cookieService.clear('loggedIn');
  }
}
