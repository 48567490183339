import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import moment from 'moment-timezone';
import { service } from '@ember/service';

export default class Insights extends Component {
  @service ajax;
  @service windowLocation;
  @service store;
  @service state;
  @service router;

  @tracked hasFetchedLastPayment = false;
  @tracked lastPayment = null;
  @tracked lastPaymentDate = null;
  @tracked period = null;

  @tracked isResellerPartner = this.state.billingCompany.resellerPartner;
  @tracked currentCostsZero = this.checkCurrentCostsZero();
  @tracked onActiveVrBasic = this.checkOnActiveVrBasic();
  @tracked canViewEstimate = null;

  @tracked nextRenewalDate = null;
  @tracked nextRenewalDateStr = null;
  @tracked nextRenewalPlan = null;
  @tracked currentPlan = null;

  constructor() {
    super(...arguments);
    this.fetchLastPayment();
    this.setPlanEstimateDetails();
    this.updateCurrentPlanPeriod();
    this.checkCanViewEstimate();
  }

  setPlanEstimateDetails() {
    if (this.args.planEstimates) {
      this.nextRenewalDate = this.args.planEstimates.nextBillingAt;
      this.nextRenewalDateStr = moment(this.nextRenewalDate).format('MMM DD, YYYY');
      this.nextRenewalPlan = this.args.planEstimates.nextInvoiceEstimate;
      this.currentPlan = this.args.planEstimates.currentInvoiceEstimate;
    }
  }

  updateCurrentPlanPeriod() {
    if (this.currentPlan) {
      switch (this.currentPlan.period) {
        case 'monthly':
          this.period = 'month';
          break;
        case 'yearly':
          this.period = 'year';
          break;
        case 'quarterly':
          this.period = 'quarter';
          break;
        default:
          this.period = null;
      }
    }
  }

  async fetchLastPayment() {
    const { currentCompany } = this.state;
    const lastPaymentArray = await this.store.query('payment', {
      filter: { company: currentCompany.id, limit: 1, status: 'paid' },
    });
    this.hasFetchedLastPayment = true;
    this.lastPayment = lastPaymentArray.firstObject;
    if (this.lastPayment) {
      this.lastPaymentDate = moment(this.lastPayment.date).format('MMM DD, YYYY');
    }
  }

  checkOnActiveVrBasic() {
    if (this.args.vrSubscription) {
      return this.args.vrSubscription.plan === 'basic' && !this.args.vrSubscription.cancelled;
    }
    return false;
  }

  checkCurrentCostsZero() {
    return this.currentPlan && this.currentPlan.total <= 0;
  }

  checkCanViewEstimate() {
    if (this.nextRenewalPlan) {
      if (
        this.nextRenewalPlan.lineItems &&
        this.nextRenewalPlan.lineItems.length > 0 &&
        this.nextRenewalPlan.total > 0
      ) {
        this.canViewEstimate = true;
        return;
      }
    }
    this.canViewEstimate = false;
  }

  @action
  redirectToVrPlans() {
    this.router.transitionTo('billing.product-plans', 'visitors');
  }

  @action
  async downloadPdf() {
    if (this.lastPayment.isChargebeePayment) {
      const response = await this.ajax.request(this.lastPayment.pdf);
      window.open(response.url, '_blank');
    } else {
      window.open(this.lastPayment.pdf, '_blank');
    }
  }
}
