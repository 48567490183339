import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type AnnouncementRecipientModel from 'garaje/models/announcement-recipient';

export default class AnnouncementRecipientChannelModel extends Model {
  @belongsTo('announcement-recipient', { async: true })
  declare announcementRecipient: AsyncBelongsTo<AnnouncementRecipientModel>;

  // attribute
  @attr('string') declare channel: string;
  @attr('string') declare status: string;
  @attr('string') declare failureMessage: string;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-recipient-channel': AnnouncementRecipientChannelModel;
  }
}
