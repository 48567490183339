import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { all, dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { defer } from 'rsvp';

interface BulkActionConfirmComponentSignature {
  Args: {
    /**
     * generate list of action to perform
     */
    bulkActions: (selectedItems: unknown[]) => Array<Promise<unknown>>;
    /**
     * method to invoke after deleted
     */
    afterChange: () => void;
    /**
     * method to invoke after deleted
     */
    selectedItems: unknown[];
    /**
     * confirmation modal title
     */
    confirmTitle: string;
    /**
     * confirmation modal content
     */
    confirmMessage: string;
    /**
     * success flash message
     */
    successMessage: string;
    /**
     * failed flash message
     */
    failedMessage: string;
    /**
     * confirm action label
     */
    actionButtonLabel: string;
  };
}

export default class BulkActionConfirmComponent extends Component<BulkActionConfirmComponentSignature> {
  @service declare flashMessages: FlashMessagesService;

  @tracked selectedItems;

  constructor(owner: unknown, args: BulkActionConfirmComponentSignature['Args']) {
    super(owner, args);
    this.selectedItems = this.args.selectedItems;
  }

  @dropTask
  confirmBulkActionTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { abort?: () => void; continue?: () => void }) {
      const deferred = defer();

      this.abort = () => deferred.resolve(false);
      this.continue = () => deferred.resolve(true);

      return yield deferred.promise;
    },
  };

  confirmBulkActions = dropTask(async () => {
    const selectedItems = this.selectedItems;
    const flashMessages = this.flashMessages;
    // eslint-disable-next-line @typescript-eslint/await-thenable
    const confirmed = await this.confirmBulkActionTask.perform();

    if (!confirmed) {
      return;
    }

    const actions = this.args.bulkActions(selectedItems);

    try {
      await all(actions);
      flashMessages.showAndHideFlash('success', this.args.successMessage);
      this.selectedItems = [];
      this.args.afterChange();
    } catch (_error) {
      flashMessages.showAndHideFlash('error', this.args.failedMessage);
    }
  });
}
