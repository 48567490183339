import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type DeviceConfigStaticPageAttributeTranslationModel from 'garaje/models/device-config-static-page-attribute-translation';

export default class DeviceConfigStaticPageAttributeModel extends Model {
  @attr('string') declare image?: string | null;
  @attr('string') declare kind: 'message' | 'video' | 'image' | 'none';
  @attr('string') declare message?: string | null;
  @attr('string') declare videoUrl?: string | null;
  @attr('string') declare type: string;

  @hasMany('device-config-static-page-attribute-translation', { async: true })
  declare deviceConfigStaticPageAttributeTranslations: AsyncHasMany<DeviceConfigStaticPageAttributeTranslationModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'device-config-static-page-attribute': DeviceConfigStaticPageAttributeModel;
  }
}
