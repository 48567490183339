import type NativeArray from '@ember/array/-private/native-array';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import type { DetailedChangeset } from 'ember-changeset/types';
import { dropTask, type Task } from 'ember-concurrency';
import type { AgreementDocument } from 'garaje/models/abstract/abstract-agreement';
import type FlowModel from 'garaje/models/flow';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SubscriptionModel from 'garaje/models/subscription';
import type { FileUpload, ValidationResult } from 'garaje/pods/components/direct-uploader/component';
import { MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS_AND_FOOTER } from 'garaje/pods/visitors/settings/visitor-types/flow/legal-documents/edit/controller.js';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import fixVideoUrl from 'garaje/utils/fix-video-url';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { Locale } from 'garaje/utils/locale-options';
import LOCALE_OPTIONS, { isRTLLocale } from 'garaje/utils/locale-options';
import { sanitizeMarkdown } from 'garaje/utils/sanitize-markdown';
import uniq from 'lodash/uniq';
import { not } from 'macro-decorators';
import { TrackedObject } from 'tracked-built-ins';

import type { SupportedAgreements } from '../component';
const { escapeExpression } = (<typeof Handlebars>(<unknown>Ember.Handlebars)).Utils;

const MINIMUM_IPAD_VERSION_NEEDED = '3.3.6';

type EditableTextFields = 'body' | 'footer';

export type PendingUploadsPerLanguage = Record<string, FileUpload | undefined> & { default?: FileUpload };

export interface SaveOptions<T extends keyof SupportedAgreements> {
  pendingUploadsPerLanguage?: PendingUploadsPerLanguage;
  model: SupportedAgreements;
  field?: T | null;
  value?: SupportedAgreements[T] | null;
  propagable?: boolean;
}

interface EditTextComponentSignature {
  Args: {
    translationLanguagesAvailable: boolean;
    translationEnabledLocales: string[];
    defaultLocale: string;
    changeset: DetailedChangeset<SupportedAgreements>;
    flowName: string;
    flow: FlowModel;
    isAppUpdateRequired: boolean;
    isAppUpdateRequiredDocumentsAndFooter: boolean;
    model: SupportedAgreements;
    signInFields: NativeArray<SignInFieldModel>;
    propagable: boolean;
    tracking: Record<string, unknown>;
    trackLegalDocument: (model: SupportedAgreements) => void;
    vrSubscription: SubscriptionModel;
    isDisabled: boolean;
    updateAndSaveTask: Task<void, [SaveOptions<keyof SupportedAgreements>]>;
  };
}

interface VirtualFileUploadDocument {
  file: { name: string };
  document: AgreementDocument;
  reset: () => void;
  isValid: boolean;
}

interface CurrentDocumentContent {
  type: 'file' | 'url';
  content: string | File;
}

export default class EditTextComponent extends Component<EditTextComponentSignature> {
  scrollingContainerDivId = `${guidFor(this)}-scrolling-container`;

  @service declare currentLocation: CurrentLocationService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;

  @tracked selectedLocale;
  @tracked documentWasEdited = false;
  @tracked pendingUploadsPerLanguage: PendingUploadsPerLanguage = new TrackedObject<PendingUploadsPerLanguage>();
  @tracked isEditing = true;
  @tracked localeOptions = LOCALE_OPTIONS;
  @tracked ndaTextFormatted?: string;
  @tracked footerNdaTextFormatted?: string;
  @tracked documentErrorMessage?: string;
  @tracked documentUrlToDelete?: string;

  iPadVersionRequired = MINIMUM_IPAD_VERSION_NEEDED;
  ipadVersionRequiredForDocumentsAndFooter = MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS_AND_FOOTER;

  @not('hasInvalidVideoUrl') hasValidVideoUrl!: boolean;

  constructor(owner: unknown, args: EditTextComponentSignature['Args']) {
    super(owner, args);
    this.selectedLocale = this.args.defaultLocale;
  }

  get defaultLocaleOption(): Locale {
    return this.localeOptions.find(({ value }) => value === this.args.defaultLocale)!;
  }

  get hasBlankBlocks(): boolean {
    const currentBody = this.#getMessageValue(this.args.model, 'body');
    const currentFooter = this.#getMessageValue(this.args.model, 'footer');

    return isBlank(currentBody) && isBlank(currentFooter);
  }

  get hasPendingUploads(): boolean {
    return Object.entries(this.pendingUploadsPerLanguage).length > 0;
  }

  get deleteDocumentDisabled(): boolean {
    return this.hasBlankBlocks && !this.currentPendingUpload;
  }

  get uploaderDisabled(): boolean {
    return this.hasFile && !this.documentErrorMessage;
  }

  get currentDocumentContent(): CurrentDocumentContent | undefined {
    if (this.currentPendingUpload?.file) {
      return { type: 'file', content: this.currentPendingUpload.file };
    }

    if (this.currentDocument?.document['document-url']) {
      return { type: 'url', content: this.currentDocument.document['document-url'] };
    }
    return;
  }

  get currentFileName(): string | undefined {
    return this.currentPendingUpload?.file?.name ?? this.currentDocument?.document.filename;
  }

  get hasFile(): boolean {
    return Boolean(this.currentDocumentContent);
  }

  get currentDocument(): VirtualFileUploadDocument | undefined {
    let document: AgreementDocument | undefined;

    if (this.args.defaultLocale === this.selectedLocale) {
      document = this.args.model.document;
    } else {
      document = this.args.model.customTranslations.document?.[this.selectedLocale];
    }

    if (!document?.filename) return;

    return this.#generateVirtualDocument(document);
  }

  get currentPendingUpload(): FileUpload | undefined {
    if (this.args.defaultLocale === this.selectedLocale) return this.pendingUploadsPerLanguage['default'];
    return this.pendingUploadsPerLanguage[this.selectedLocale];
  }

  get isRTLLocale(): boolean {
    return isRTLLocale(this.selectedLocale);
  }

  get today(): string {
    const date = new Date();
    return date.toLocaleDateString(this.selectedLocale, { month: 'long', day: 'numeric', year: 'numeric' });
  }

  get isSaveDisabled(): boolean {
    const isRunning = this.args.updateAndSaveTask.isRunning;
    if ((this.documentWasEdited || Object.keys(this.pendingUploadsPerLanguage).length) && !isRunning) {
      return false;
    } else {
      const isPristine = this.args.changeset.isPristine;
      const isInvalid = this.args.changeset.isInvalid;
      return isPristine || isInvalid || isRunning;
    }
  }

  get availableLocales(): Array<{ label: string; value: string }> {
    const { defaultLocale, translationEnabledLocales, flow } = this.args;
    let locales = translationEnabledLocales;

    if (flow.isGlobal) {
      locales = uniq([translationEnabledLocales, defaultLocale].flat());
    }

    return this.localeOptions.filter((option) => locales.includes(option.value));
  }

  get messageValue(): string | undefined {
    return this.#getMessageValue(this.args.changeset, 'body');
  }

  get footerMessageValue(): string | undefined {
    return this.#getMessageValue(this.args.changeset, 'footer');
  }

  get variables(): string[] {
    const variables = ['date', 'company', 'name', 'field purpose of visit'];

    this.args.signInFields?.sortBy('position').forEach((field) => {
      if (field.isCustom) {
        variables.push(`field ${field.name}`);
      } else if (field.kind === 'email') {
        variables.push('email');
      } else if (field.kind === 'phone') {
        variables.push('phone');
      }
    });

    return variables.map((v) => `::${v.toUpperCase()}::`);
  }

  get hasInvalidVideoUrl(): boolean {
    const errors = this.args.changeset.errors;
    return errors.some(({ key }) => key === 'videoUrl');
  }

  get canRequireResign(): boolean {
    const changes = Object.keys(this.args.changeset.change || {});
    return (
      changes.includes('body') ||
      changes.includes('videoUrl') ||
      changes.includes('footer') ||
      this.hasPendingUploads ||
      this.documentWasEdited
    );
  }

  #changeLocale(value: string, key: EditableTextFields): void {
    if (this.args.defaultLocale === this.selectedLocale) {
      this.args.changeset[key] = value;
    } else {
      const customTranslations = this.args.changeset.customTranslations;
      if (isBlank(customTranslations[key])) {
        customTranslations[key] = {};
      }
      customTranslations[key]![this.selectedLocale] = value;
    }
  }

  #generateVirtualDocument(document: AgreementDocument): VirtualFileUploadDocument {
    return {
      // force direct uploader to show a file preview
      isValid: true,
      file: { name: document.filename! },
      document,
      reset: () => (this.documentUrlToDelete = document['document-url']),
    };
  }

  useEmbeddedVideoUrl(): void {
    const url = this.args.changeset.videoUrl;
    if (url && this.hasValidVideoUrl) {
      const fixed = fixVideoUrl(url);
      if (url !== fixed) {
        this.args.changeset.videoUrl = fixed;
      }
    }
  }

  @action
  textChanged(key: EditableTextFields, value: string): void {
    this.documentWasEdited = true;
    this.args.changeset.requireResign = true;
    if (this.args.translationLanguagesAvailable) {
      this.#changeLocale(value, key);
    } else {
      this.args.changeset[key] = value;
    }
  }

  @action
  applyChangeset(changeset: DetailedChangeset<SupportedAgreements>): void {
    changeset.execute();
    changeset.rollback();
  }

  @action
  formatNDAText(model: SupportedAgreements): void {
    const companyName = this.currentLocation.location?.companyName;
    const date = this.today;
    const defaultFields = ['DATE', 'COMPANY', 'NAME', 'EMAIL', 'PHONE'];

    const escapedCustomFields = this.args.signInFields.filterBy('isCustom').map((field) => {
      // eslint-disable-next-line no-useless-escape
      return field.name.toUpperCase().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    });

    const fieldsToMatch = defaultFields
      .concat(escapedCustomFields.map((field) => `FIELD ${field}`))
      .concat(['FIELD PURPOSE OF VISIT'])
      .join('|');

    let text: string | undefined = model.body;
    let footer: string | undefined = model.footer;

    if (this.currentLocation.location?.locale !== this.selectedLocale) {
      const customTranslations = model.customTranslations;
      text = customTranslations.body?.[this.selectedLocale];
      footer = customTranslations.footer?.[this.selectedLocale];
    }

    const formattedText = sanitizeMarkdown(text ?? '')
      .replace(new RegExp(`__(${fieldsToMatch})__`, 'g'), '**::$1::**')
      .replace(/::DATE::/g, date)
      .replace(/::COMPANY::/g, escapeExpression(companyName!))
      .replace(/::FIELD PURPOSE OF VISIT::/g, escapeExpression(this.args.flowName))
      .replace(/::(NAME|EMAIL|PHONE)::/g, 'Visitor:$1')
      .replace(new RegExp(`::FIELD (${escapedCustomFields.join('|')})::`, 'g'), 'Visitor:$1');

    const formattedFooterText = sanitizeMarkdown(footer ?? '')
      .replace(new RegExp(`__(${fieldsToMatch})__`, 'g'), '**::$1::**')
      .replace(/::DATE::/g, date)
      .replace(/::COMPANY::/g, escapeExpression(companyName!))
      .replace(/::FIELD PURPOSE OF VISIT::/g, escapeExpression(this.args.flowName))
      .replace(/::(NAME|EMAIL|PHONE)::/g, 'Visitor:$1')
      .replace(new RegExp(`::FIELD (${escapedCustomFields.join('|')})::`, 'g'), 'Visitor:$1');

    // Passed to <MarkdownToHtml>
    this.ndaTextFormatted = formattedText;
    this.footerNdaTextFormatted = formattedFooterText;
  }

  @action
  onToPreviewing(): void {
    const { eventName } = this.args.tracking;
    this.args.tracking['eventName'] = 'Legal Docs - Legal Document Previewed';
    this.args.trackLegalDocument(this.args.changeset);
    this.args.tracking['eventName'] = eventName;
  }

  @action
  onToEditing(): void {
    this.args.tracking['eventName'] = 'Legal Docs - Legal Document Edited';
  }

  @action
  performSave(): void {
    this.useEmbeddedVideoUrl();
    this.applyChangeset(this.args.changeset);
    void this.args.updateAndSaveTask
      .perform({
        pendingUploadsPerLanguage: this.pendingUploadsPerLanguage,
        model: this.args.model,
        field: null,
        value: null,
        propagable: this.args.propagable,
      })
      .then(() => {
        // nothing left to upload
        this.pendingUploadsPerLanguage = new TrackedObject<PendingUploadsPerLanguage>();
        this.documentWasEdited = false;
        this.documentErrorMessage = undefined;
        this.args.trackLegalDocument(this.args.model);
      });
  }

  @action
  updateName(name: string): void {
    this.args.changeset.name = name;
  }

  @action
  updateVideoUrl(url: string): void {
    this.args.changeset.videoUrl = url;
  }

  @action
  updateRequireResign(checked: boolean): void {
    this.args.changeset.requireResign = checked;
  }

  @action
  updatePendingUploads(pendingUpload?: FileUpload): void {
    const inCurrentLocale = this.args.defaultLocale === this.selectedLocale;
    const locale = this.args.translationLanguagesAvailable && !inCurrentLocale ? this.selectedLocale : 'default';

    if (pendingUpload) {
      this.pendingUploadsPerLanguage[locale] = pendingUpload;
    } else {
      delete this.pendingUploadsPerLanguage[locale];
    }

    // if there are any pending uploads, set require resign
    if (this.hasPendingUploads) this.args.changeset.requireResign = true;
  }

  @action
  updateValidity(result: ValidationResult): void {
    if (!result.sizeValid)
      this.documentErrorMessage =
        'The file must be 10mb or less. Consider compressing the PDF to reduce its file size.';
    else if (!result.typeValid) this.documentErrorMessage = 'The file must be a PDF.';
    else this.documentErrorMessage = undefined;
  }

  deleteFileTask = dropTask(async () => {
    try {
      await this.args.model.deleteDocument(this.documentUrlToDelete!);
      this.args.changeset.requireResign = true;
      this.documentWasEdited = true;
      this.documentUrlToDelete = undefined;
      this.flashMessages.showAndHideFlash('success', 'Document deleted');
    } catch (error) {
      // roll back internal document nulls if the deletion fails
      this.args.model.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  #getMessageValue(
    model: DetailedChangeset<SupportedAgreements> | SupportedAgreements,
    key: EditableTextFields,
  ): string | undefined {
    if (this.args.defaultLocale === this.selectedLocale) {
      return model[key];
    }
    const customTranslations = model.customTranslations;
    if (isBlank(customTranslations[key]) || typeof customTranslations[key]?.[this.selectedLocale] !== 'string') {
      return '';
    }
    return model.customTranslations[key]?.[this.selectedLocale];
  }
}
