import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

class BillingConfirmDowngradeRoute extends Route {
  @service state;
  @service store;

  model({ plan: planId }) {
    const plan = this.store.findRecord('plan', planId);
    const { vrSubscription } = this.state;
    const currentPlan = this.store.findRecord('plan', vrSubscription.plan);
    const estimate = this.store
      .createRecord('subscription-estimate', {
        app: 'visitors',
        quantity: vrSubscription.quantity,
        plan: vrSubscription.plan,
        period: vrSubscription.period,
        subscription: vrSubscription,
      })
      .save();
    const downgradeEstimate =
      planId === 'basic'
        ? null
        : this.store
            .createRecord('subscription-estimate', {
              app: 'visitors',
              quantity: vrSubscription.quantity,
              plan: planId,
              period: vrSubscription.period,
              subscription: vrSubscription,
            })
            .save();

    return hash({ currentPlan, plan, subscription: vrSubscription, estimate, downgradeEstimate });
  }
}

export default BillingConfirmDowngradeRoute;
