import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { set } from '@ember/object';

export default class DeliveriesSettingsRoute extends Route {
  @service state;

  setupController() {
    super.setupController(...arguments);
    set(this.state, 'showDeliveriesOnboarding', true);
  }
}
