import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask, task, timeout } from 'ember-concurrency';
import type DeviceModel from 'garaje/models/device';
import type PrinterModel from 'garaje/models/printer';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type SetupGuideStepperService from 'garaje/services/setup-guide-stepper';
import { APP } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import zft from 'garaje/utils/zero-for-tests';
import { localCopy } from 'tracked-toolbox';

const POLLING_INTERVAL = 1000; // check @device.isConnected every this often (in milliseconds) when pairing

interface DevicesNewIpadFormComponentSignature {
  Args: {
    /**
     * name of the route to transition to if "Cancel" button is clicked
     */
    cancelRoute: string;
    /**
     * new Device model
     */
    device: DeviceModel;
    /**
     * action triggered once the new device completes the pairing process
     */
    onConnected: () => void;
    /**
     * list of printers that can be selected to connect to the new device
     */
    printers: PrinterModel[];
    /**
     * an action triggered to create a new Device model; this is used if the initial one we were given _saved_ successfully but failed to pair. Since the backend logic for pairing only works on create, not update, we need to start over with a new model. However, since the model may have different relationships depending on how it's used (location vs. zone, for example), we can't just create a new one here. This function should return a Device model.
     */
    reinitializeDevice: () => DeviceModel;
    /**
     * optional flag to disable Save button. (This is in addition to the Save button becoming disabled while the save operation is running.)
     */
    saveButtonDisabled?: boolean;
    /**
     * whether the Printer selection portion of the form should be displayed. (Note that the Printer section will not displayed if `@printers` is empty, regardless of the value of this argument.)
     */
    showPrinters: boolean;
  };
  Blocks: {
    buttons: [saveRunning: boolean];
  };
}

interface PrinterOption {
  disabled: boolean;
  printer: PrinterModel;
}

/**
 * This component yields a `<:buttons>` named block to allow callers to insert additional content in the same part
 * of the UI as the "save" button". This block receives one argument:
 * @param saveRunning - whether or note the save task is currently running (this can be used to disable other buttons while saving)
 */
export default class DevicesNewIpadFormComponent extends Component<DevicesNewIpadFormComponentSignature> {
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare featureFlags: FeatureFlagsService;
  @service('setup-guide-stepper') declare setupGuideStepperService: SetupGuideStepperService;

  @localCopy('args.device') device!: DeviceModel;

  get userToken(): string {
    return this.device.userToken;
  }
  set userToken(value: string) {
    const userToken = (value || '').toUpperCase();
    this.device.userToken = userToken;
  }

  get isGrantExpired(): boolean {
    const expiredAt = this.device?.grantExpiresAt;
    return new Date() > expiredAt;
  }

  get printerOptions(): PrinterOption[] {
    return this.args.printers.map((printer) => ({
      disabled: printer.connectionType === 'bluetooth',
      printer,
    }));
  }

  get selectedPrinterOption(): PrinterOption | undefined {
    const selectedPrinterId = this.device.belongsTo('printer').id();
    return this.printerOptions.find((option) => option.printer.id === selectedPrinterId);
  }

  get showPrinters(): boolean {
    return this.args.showPrinters && this.args.printers.length > 0;
  }

  saveTask = dropTask(async () => {
    if (this.args.saveButtonDisabled) return;
    try {
      await this.device.save();
      this.metrics.trackEvent(
        'Device - New iPad Paired',
        { marketo_prop: 'yes' }, // this prop is useless but Marketo requires at least 1 prop on CustomActivity
        {
          // context is here to satisfy downstream adWords requirements
          context: {
            app: { namespace: 'com.envoy.dashboard', version: 1 },
            device: {
              type: window?.navigator?.vendor,
              advertisingId: 'web-browser',
            },
            os: { version: 1 },
          },
        },
      );

      await this.waitUntilConnectedOrExpired.perform();
      if (this.device.isConnected) {
        this.args.onConnected?.();

        if (this.featureFlags.isEnabled('growth_show_visitors_setup_guide_stepper')) {
          void this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
        }
      }
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  waitUntilConnectedOrExpired = task(async () => {
    while (!(this.device.isConnected || this.isGrantExpired)) {
      await timeout(zft(POLLING_INTERVAL));
    }
  });

  @action
  cancel(): void {
    // reset the state of this component on cancel because we might end up back on the same route,
    // in which case this component is not torn down and recreated but instead reused.
    // This can haopen when cancelling during the pairing of the first iPad for a location/property;
    // @cancelRoute might be the listing page, which redirects to the "new" page when there are no
    // paired iPads.
    void this.saveTask.cancelAll();
    this.newToken();
    this.router.transitionTo(this.args.cancelRoute);
  }

  @action
  newToken(): void {
    this.device = this.args.reinitializeDevice();
  }

  @action
  printerSelected({ printer }: { printer: PrinterModel }): void {
    this.device.printer = printer;
  }
}
