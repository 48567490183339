/* eslint-disable ember/no-get */
import type NativeArray from '@ember/array/-private/native-array';
import { get } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { readOnly } from '@ember/object/computed';
import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type { SyncHasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type CompanyRoleModel from 'garaje/models/company-role';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type LocationRoleModel from 'garaje/models/location-role';
import type UserModel from 'garaje/models/user';
import type ZoneModel from 'garaje/models/zone';
import type ZoneRoleModel from 'garaje/models/zone-role';
import type CurrentLocationService from 'garaje/services/current-location';
import type StateService from 'garaje/services/state';
import _intersection from 'lodash/intersection';
import { cached } from 'tracked-toolbox';

const employeeLocationsIncludesLocation = (employee: EmployeeModel, location: LocationModel) => {
  const locationId = get(location, 'id');

  // Mirage simulated data may not always include employeeLocations. :-(
  // Merging these arrays to search all possible employee location IDs.
  const employeeLocationIds = [
    ...employee.employeeLocations.map((empLoc) => `${empLoc.locationId}`),
    ...employee.hasMany('locations').ids(),
  ];

  return employeeLocationIds.includes(locationId);
};

interface SetRolesTaskOptions {
  currentUser: UserModel;
  currentCompany: CompanyModel;
  currentZone?: ZoneModel;
  currentLocation?: LocationModel;
}

interface SetEmployeeTaskOptions {
  currentUser: UserModel;
  currentCompany: CompanyModel;
  currentLocation: LocationModel;
}

export default class CurrentAdminService extends Service {
  @service declare currentLocation: CurrentLocationService;
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;

  @readOnly('state.currentUser') user!: StateService['currentUser'];
  @readOnly('user.email') email!: UserModel['email'];
  @readOnly('user.fullName') fullName!: UserModel['fullName'];
  @readOnly('user.id') id!: UserModel['id'];
  @readOnly('user.phoneNumber') phoneNumber!: UserModel['phoneNumber'];
  @readOnly('user.createdAt') createdAt!: UserModel['createdAt'];

  @tracked userRoles: Array<LocationRoleModel | CompanyRoleModel | ZoneRoleModel> = [];
  @tracked employee?: EmployeeModel | null;

  get isAdminLike(): boolean {
    return isPresent(_intersection(['Global Admin', 'Location Admin', 'Receptionist'], this.roleNames));
  }

  get canDeleteVisitor(): boolean {
    return this.abilities.can('delete entries');
  }

  @cached
  get roleNames(): string[] {
    return this.userRoles.map((role) => role.roleName);
  }

  @cached
  get allLocationRoleNames(): string[] {
    return this.locationRoles.map((role) => role.roleName);
  }

  @cached
  get companyRoles(): CompanyRoleModel[] | NativeArray<CompanyRoleModel> {
    if (this.user?.id) {
      // Async relatonships expected to be loaded by the time this is called.
      return <NativeArray<CompanyRoleModel>>(<unknown>get(this.user, 'companyRoles'));
    } else {
      return [];
    }
  }

  @cached
  get locationRoles(): SyncHasMany<LocationRoleModel> | LocationRoleModel[] {
    if (this.user?.id) {
      // Async relatonships expected to be loaded by the time this is called.
      return get(this.user, 'locationRoles');
    } else {
      return [];
    }
  }

  /* Company Roles */
  get isGlobalAdmin(): boolean {
    return this.roleNames.includes('Global Admin');
  }

  get isBillingAdmin(): boolean {
    return this.roleNames.includes('Billing');
  }

  /* Location Roles */
  get isEmployee(): boolean {
    return this.roleNames.length === 1 && this.roleNames.includes('Employee');
  }
  get isLocationAdmin(): boolean {
    return this.roleNames.includes('Location Admin');
  }
  get isReceptionist(): boolean {
    return this.roleNames.includes('Receptionist');
  }
  get isSecurity(): boolean {
    return this.roleNames.includes('Security');
  }

  setRolesTask = task(
    {
      restartable: true,
    },
    async ({ currentUser, currentLocation, currentCompany, currentZone }: SetRolesTaskOptions) => {
      const companyRoles = await get(currentUser, 'companyRoles');
      const locationRoles = get(currentUser, 'locationRoles');
      let filteredZoneRoles: ZoneRoleModel[] = [];
      let filteredLocationRoles: LocationRoleModel[] = [];

      if (this.abilities.can('use property admin-role') && currentZone) {
        filteredZoneRoles = currentUser.zoneRoles.filter((role) => {
          return role.belongsTo('zone').id() === currentZone.id;
        });
      }

      const filteredCompanyRoles = companyRoles.filter((role) => {
        return role.belongsTo('company').id() === currentCompany?.id;
      });

      if (currentLocation) {
        filteredLocationRoles = locationRoles.filter((role) => {
          return role.belongsTo('location').id() === currentLocation.id;
        });
      }

      this.userRoles = [...filteredCompanyRoles, ...filteredLocationRoles, ...filteredZoneRoles].filter(Boolean);
    },
  );

  setEmployeeTask = task(
    {
      restartable: true,
    },
    async ({ currentUser, currentLocation, currentCompany }: SetEmployeeTaskOptions) => {
      this.employee = null;

      const companyRoles = await currentUser.companyRoles;
      const locationRoles = currentUser.locationRoles;

      const companyRole = companyRoles.find((role) => {
        return role.belongsTo('company').id() === currentCompany.id;
      });

      const locationRole = locationRoles.find((role) => {
        return role.belongsTo('location').id() === currentLocation.id;
      });

      const userRole = companyRole || locationRole;

      let localEmployee: EmployeeModel | undefined;
      try {
        if (userRole) {
          localEmployee = await userRole.employee;

          if (localEmployee?.id) {
            await Promise.all([localEmployee.employeeLocations, localEmployee.bosses]);
          }
        }
      } catch (_e) {
        // @heroiceric
        // Don't break when the role does not have an employee relationship.
        // The API will respond with a 404 and break everything
      }

      if (localEmployee && employeeLocationsIncludesLocation(localEmployee, currentLocation)) {
        this.employee = localEmployee;
      }
    },
  );

  reset(): void {
    this.userRoles = [];
  }
}
