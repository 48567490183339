import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { alias, filterBy } from 'macro-decorators';

export default class BillingConfirmDowngradeController extends Controller {
  @service flashMessages;
  @service locations;
  @service router;

  @tracked showModal = false;

  @alias('model.downgradeEstimate.nextInvoiceEstimate') downgradeInvoiceEstimate;
  @alias('downgradeInvoiceEstimatePlans.firstObject') downgradeInvoiceEstimatePlan;
  @filterBy('downgradeInvoiceEstimate.lineItems', 'entityType', 'plan') downgradeInvoiceEstimatePlans;

  @dropTask
  *downgradeTask() {
    const subscription = this.model.subscription;
    set(subscription, 'downgradePlan', this.model.plan);

    try {
      yield subscription.save();
      yield subscription.reload();
      this.showModal = false;
      this.router.transitionTo('billing.plans');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  cancel() {
    this.router.transitionTo('billing.plans');
  }
}
