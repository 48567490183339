import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class AnnouncementsShowRoute extends Route {
  @service store;
  @service router;

  model({ announcement_id }) {
    const announcement = this.store.findRecord('announcement', announcement_id);

    return hash({
      announcement,
    });
  }

  afterModel({ announcement }) {
    if (!announcement) {
      this.router.transitionTo('workplace.announcements.list');
    }
  }
}
