import { Button, Dropdown, DropdownOptionType, FormLayout, Modal } from '@envoy/polarwind-react';
import { FormCheckbox, FormDropdown, FormTextField, ModalResult, useModal } from '@envoy/react-rich-components-core';
import pluralize from 'pluralize';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MESSAGE_AUDIENCE_TYPES } from '../../data/message-audience-types';
import { MESSAGE_DELIVERY_LABEL_MAP } from '../../data/message-delivery-methods';
import { MESSAGE_EMPLOYEE_AUDIENCES } from '../../data/message-employee-audiences';
import { MESSAGE_VISITOR_AUDIENCES } from '../../data/message-visitor-audiences';
import { TakeoverTooltip } from '../../data/takeover-tooltip';
import type {
  Audiences,
  GQLAudiences,
  GQLDeliveryMethod,
  LabelValue,
  Location,
  SendService,
  TemplateGroup,
} from '../../types';
import { GQLDeliveryMethods } from '../../types';
import { EmployeeGroupSelect } from '../employee-group-select/employee-group-select';
import './send-message.css';

export type SentMessage = {
  id: string;
  sentCount: number;
};

export type MessageInput = {
  locationId: string;
  title: string;
  message: string;
  deliveryMethods: GQLDeliveryMethod[];
  employeeGroup?: Audiences[];
  visitorGroup?: Audiences[];
  selectedEmployees?: string[];
  selectedGroups?: string[];
  critical: boolean;
  markAsSafe: boolean;
  takeoverActive?: boolean;
};

export interface SendMessageProps {
  service: SendService;
  locationId?: string;
  locationName?: string;
  createTemplateUrl: string;
  onSent(message: SentMessage): void;
  onError(errorMessage: string, error: unknown): void;
  sendMessage(input: MessageInput): Promise<SentMessage>;
}

const maxMessageLength = 320;

export interface MessageForm {
  title: string;
  message: string;
  deliveryMethods: GQLDeliveryMethod[];
  employeeGroup: Audiences[];
  visitorGroup: Audiences[];
  customSelections?: (DropdownOptionType & { type: string })[];
  critical: boolean;
  markAsSafe: boolean;
  takeoverActive?: boolean;
}

export const SendMessage = ({
  locationId,
  locationName,
  onSent,
  onError,
  service,
  createTemplateUrl,
  sendMessage,
}: SendMessageProps) => {
  const formMethods = useForm<MessageForm>({
    defaultValues: {
      title: '',
      message: '',
      deliveryMethods: [],
      employeeGroup: [],
      visitorGroup: [],
      critical: true,
      markAsSafe: true,
      takeoverActive: false,
    },
    mode: 'onChange',
  });
  const [availableTemplates, setAvailableTemplates] = useState<TemplateGroup[]>([]);
  const [deliveryMethods, setDeliveryMethods] = useState<GQLDeliveryMethods[]>([]);
  const [totalRecipientCount, setTotalRecipientCount] = useState<number>(0);
  const [loadingTotalRecipientCount, setLoadingTotalRecipientCount] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState('');
  const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>(locationId);
  const [selectedLocationName, setSelectedLocationName] = useState<string | undefined>(locationName);
  const [recipientGroupSelected, setRecipientGroupSelected] = useState(true);
  const [didSelectRecipient, setDidSelectRecipient] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [audiences, setAudiences] = useState<GQLAudiences>({
    employees: MESSAGE_EMPLOYEE_AUDIENCES.map((a) => ({ id: a.value })),
    visitors: MESSAGE_VISITOR_AUDIENCES.map((a) => ({ id: a.value })),
  });
  const [isSending, setIsSending] = useState(false);
  const [activeTakeoverMessage, setActiveTakeoverMessage] = useState<string>();

  const { isModalOpen, openModal, primaryClicked, secondaryClicked, closeClicked } = useModal();

  const {
    analytics,
    showCustomEmployeeSelect,
    showEmployeeSelect,
    showVisitorSelect,
    showTotalRecipientCount,
    canSelectScimGroups,
    canSendEmergencyNotifications,
    canSelectIndividualEmployees,
    canSendTakeovers,
  } = service;

  useEffect(() => {
    if (locationId) {
      return;
    }

    const asyncFn = async () => {
      const returnedLocations = await service.getLocations();

      setLocations(returnedLocations);
    };

    asyncFn().catch((error) => {
      onError('Could not load locations', error);
    });
  }, [locationId]);

  useEffect(() => {
    if (!selectedLocationId) {
      return;
    }

    const currentLocation = locations.find((data) => data.id === selectedLocationId);
    setSelectedLocationName(currentLocation?.name);

    const asyncFn = async () => {
      await Promise.all([
        service
          .getAvailableTemplates(selectedLocationId)
          .then((returnedAvailableTemplates) => setAvailableTemplates(returnedAvailableTemplates)),
        service.getAudiences(selectedLocationId).then((returnedAudiences) => setAudiences(returnedAudiences)),
        service.getAvailableDeliveryMethods(selectedLocationId).then((returnedDeliveryMethods) => {
          setDeliveryMethods(returnedDeliveryMethods);

          formMethods.setValue('deliveryMethods', returnedDeliveryMethods);
        }),
      ]);
    };

    asyncFn().catch((error) => {
      onError('Could not load data.', error);
    });
  }, [selectedLocationId]);

  const onTemplateSelected = (templateId: string) => {
    setSelectedTemplateId(templateId);
  };

  useEffect(() => {
    if (!selectedTemplateId || !selectedLocationId) {
      return;
    }

    void service
      .getTemplate(selectedLocationId, selectedTemplateId)
      .then((template) => {
        formMethods.reset({
          ...template,
          critical: template.markAsSafe,
        });
      })
      .catch((error) => onError('Could not load template.', error));
  }, [selectedTemplateId, selectedLocationId]);

  const [messageValue, criticalChecked, customSelections, employeeGroup, visitorGroup] = formMethods.watch([
    'message',
    'critical',
    'customSelections',
    'employeeGroup',
    'visitorGroup',
  ]);

  const checkRecipientFields = () => {
    // call trigger after state is updated
    setTimeout(() => {
      void formMethods.trigger('employeeGroup');
      void formMethods.trigger('visitorGroup');
      void formMethods.trigger('customSelections');
    }, 10);
  };

  const filterEmployeeSelections = (input: (DropdownOptionType & { type: string })[]) => {
    return input?.filter((s) => s.type === 'EMPLOYEE').map((s) => s.value) || [];
  };

  const filterEmployeeGroupSelections = (input: (DropdownOptionType & { type: string })[]) => {
    return (
      input
        ?.filter((s) => ['CUSTOM_SCIM_GROUP', 'DEPARTMENT', 'DIVISION', 'ORGANIZATION'].includes(s.type))
        .map((s) => s.value) || []
    );
  };

  useEffect(() => {
    const recipientSelected = !!(employeeGroup.length || visitorGroup.length || customSelections?.length);
    if (!didSelectRecipient && recipientSelected) {
      setDidSelectRecipient(true);
      setRecipientGroupSelected(true);
      checkRecipientFields();
    } else if (didSelectRecipient) {
      setRecipientGroupSelected(recipientSelected);
      checkRecipientFields();
    }
  }, [employeeGroup, visitorGroup, customSelections]);

  const onSubmit = async (data: MessageForm) => {
    try {
      if (!recipientGroupSelected || !didSelectRecipient) {
        setRecipientGroupSelected(false);
        checkRecipientFields();
        return;
      }
      setIsSending(true);

      const selectedEmployees = filterEmployeeSelections(data.customSelections || []);
      const selectedGroups = filterEmployeeGroupSelections(data.customSelections || []);

      if (canSendTakeovers && data.takeoverActive) {
        const takeoverMessage = await service.getActiveTakeoverMessage(selectedLocationId!);

        if (takeoverMessage) {
          setActiveTakeoverMessage(takeoverMessage);

          const result = await openModal();

          if (result == ModalResult.Closed) {
            setIsSending(false);

            return;
          } else if (result === ModalResult.Secondary) {
            data.takeoverActive = false;
          }
        }
      }

      const sentMessage = await sendMessage({
        ...data,
        employeeGroup: showEmployeeSelect && data.employeeGroup.length > 0 ? data.employeeGroup : undefined,
        selectedEmployees: showEmployeeSelect && selectedEmployees.length > 0 ? selectedEmployees : undefined,
        selectedGroups: showEmployeeSelect && selectedGroups.length > 0 ? selectedGroups : undefined,
        visitorGroup: showVisitorSelect && data.visitorGroup.length > 0 ? data.visitorGroup : undefined,
        locationId: selectedLocationId!,
        markAsSafe: data.critical && data.markAsSafe,
        takeoverActive: canSendTakeovers && data.critical && data.takeoverActive,
      });

      analytics.trackAnalytics('COMMUNICATIONS_SEND_MESSAGE_CLICKED', {
        messageId: sentMessage.id,
        locationId: selectedLocationId,
        announcementTemplateId: selectedTemplateId,
        criticalChecked: data.critical,
        markAsSafeChecked: data.markAsSafe,
        takeoverActiveChecked: data.takeoverActive,
        employeeGroup: data.employeeGroup,
        visitorGroup: data.visitorGroup,
        deliveryMethods: data.deliveryMethods,
        titleUsed: !!data.title,
      });

      onSent(sentMessage);
    } catch (error) {
      setIsSending(false);

      onError('Could not send message, please try again.', error);
    }
  };

  const templateOptions = useMemo(
    () =>
      availableTemplates.map<ComponentProps<typeof Dropdown>['options'][number]>((category) => ({
        label: category.name,
        options: category.templates.map((template) => ({ label: template.name, value: template.id })),
      })),
    [availableTemplates],
  );

  const replaceLocation = (label: string): string => {
    const locationLabel = selectedLocationName || locationName || 'primary location';
    return label.replace('%{location_name}', locationLabel);
  };

  const employeeGroupOptions = useMemo(
    () =>
      (audiences?.employees || []).map<DropdownOptionType>((employeeGroupOption) => {
        const modifiedLabel = replaceLocation(MESSAGE_AUDIENCE_TYPES[employeeGroupOption.id]);
        return {
          label: `${modifiedLabel}${employeeGroupOption.count !== undefined ? ` (${employeeGroupOption.count})` : ''}`,
          value: employeeGroupOption.id,
        };
      }),
    [audiences],
  );

  const visitorGroupOptions = useMemo(
    () =>
      (audiences?.visitors || []).map<ComponentProps<typeof Dropdown>['options'][number]>((visitorGroupOption) => ({
        label: `${MESSAGE_AUDIENCE_TYPES[visitorGroupOption.id]}${visitorGroupOption.count !== undefined ? ` (${visitorGroupOption.count})` : ''}`,
        value: visitorGroupOption.id,
      })),
    [audiences],
  );

  const deliveryMethodOptions = useMemo(
    () =>
      deliveryMethods.map((deliveryMethod) => ({
        label: MESSAGE_DELIVERY_LABEL_MAP[deliveryMethod],
        value: deliveryMethod,
      })),
    [deliveryMethods],
  );

  const locationOptions = useMemo(
    () => locations.map((location) => ({ label: location.name, value: location.id })),
    [locations],
  );

  const formDisabled = useMemo(() => !locationId && !selectedLocationId, [locationId, selectedLocationId]);

  const getTotalRecipientCount = async () => {
    try {
      if (!locationId) return;

      setLoadingTotalRecipientCount(true);

      const selectedEmployees = filterEmployeeSelections(customSelections || []);
      const selectedGroups = filterEmployeeGroupSelections(customSelections || []);

      const result = await service.getTotalRecipientCount(
        {
          employeeGroup: showEmployeeSelect ? employeeGroup : [],
          selectedEmployees: showEmployeeSelect ? selectedEmployees : [],
          selectedGroups: showEmployeeSelect ? selectedGroups : [],
          visitorGroup: showVisitorSelect ? visitorGroup : [],
        },
        locationId,
      );

      setTotalRecipientCount(result);
      setLoadingTotalRecipientCount(false);
    } catch (error) {
      onError('Could not load recipient count, please try again.', error);
    }
  };

  useEffect(() => {
    void getTotalRecipientCount();
  }, [customSelections, employeeGroup, visitorGroup, locationId]);

  /* eslint-disable @typescript-eslint/no-misused-promises */
  return (
    <>
      <FormProvider {...formMethods}>
        <form className="react-form" onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormLayout>
            {!locationId && (
              <Dropdown
                label="Location to send"
                required={true}
                options={locationOptions}
                onChange={(v) => setSelectedLocationId((v as LabelValue).value)}
                preselectValue={false}
                placeholder="Select a location"
                value={locationOptions.find((o) => o.value === selectedLocationId)}
                testId="message-name"
              />
            )}
            <Dropdown
              label="Template (optional)"
              options={templateOptions}
              isDisabled={formDisabled}
              onChange={(v) => onTemplateSelected((v as LabelValue).value)}
              preselectValue={false}
              placeholder="Select a template"
              testId="template-select"
              emptyNode={
                <>
                  No templates to choose from.{' '}
                  <a className="send-message__link" href={createTemplateUrl}>
                    Create template
                  </a>
                </>
              }
            />

            <FormTextField<MessageInput>
              name="title"
              label="Message title (optional)"
              disabled={formDisabled}
              placeholder="e.g. Urgent: Immediate Evacuation Required"
              tooltip="Message titles are not shown in SMS text messages"
              testId="message-title"
            />
            <div className="send-message__message">
              <FormTextField<MessageInput>
                name="message"
                label="Message"
                required="Write a message to send"
                disabled={formDisabled}
                maxLength={{ value: 320, message: 'Reduce the length of your message' }}
                multiline={6}
                placeholder="Write your message"
                testId="message-body"
              />
              <div className="send-message__message-text">
                <div className="send-message__message-error">{formMethods.formState.errors.message?.message}</div>
                <div className="send-message__message-length">
                  {messageValue.length}/{maxMessageLength}
                </div>
              </div>
            </div>
            <FormDropdown<MessageInput>
              name="deliveryMethods"
              label="Delivery method(s)"
              required="You must select at least 1 delivery method"
              isDisabled={formDisabled}
              isMulti
              options={deliveryMethodOptions}
              placeholder="Select how to deliver this message"
              testId="delivery-methods-select"
            />
            {showEmployeeSelect && (
              <EmployeeGroupSelect
                options={employeeGroupOptions}
                service={service}
                recipientGroupSelected={recipientGroupSelected}
                formMethodTrigger={formMethods.trigger}
                canSelectScimGroups={canSelectScimGroups}
                canSelectIndividualEmployees={canSelectIndividualEmployees}
                showCustomEmployeeSelect={showCustomEmployeeSelect}
                hasCustomValue={!!customSelections?.length}
                isDisabled={formDisabled}
              />
            )}
            {showVisitorSelect && (
              <FormDropdown<MessageInput>
                name="visitorGroup"
                label="Visitors"
                required={
                  !showEmployeeSelect || !recipientGroupSelected ? 'Please select at least one recipient' : undefined
                }
                isDisabled={formDisabled}
                isMulti
                options={visitorGroupOptions}
                placeholder="Select who should receive this message"
                testId="visitor-group-multi-select"
              />
            )}
            {canSendEmergencyNotifications && (
              <FormCheckbox<MessageInput>
                name="critical"
                label="Critical or priority message"
                disabled={formDisabled}
                tooltip="Use for time-sensitive updates to ensure your message is sent promptly during high activity times"
                testId="critical-checkbox"
              />
            )}
            {canSendEmergencyNotifications && criticalChecked && (
              <>
                <div className="send-message__indent">
                  <FormCheckbox<MessageInput>
                    name="markAsSafe"
                    label="Ask recipients to mark themselves as safe"
                    disabled={formDisabled}
                    testId="takeover-rooms-checkbox"
                  />
                </div>
                {canSendTakeovers && (
                  <div className="send-message__indent send-message__top-margin">
                    <FormCheckbox<MessageInput>
                      name="takeoverActive"
                      label="Takeover Rooms tablets in this location"
                      disabled={formDisabled}
                      testId="takeover-rooms-checkbox"
                      tooltip={TakeoverTooltip}
                    />
                  </div>
                )}
              </>
            )}
            {showTotalRecipientCount && (
              <div className="estimated-recipient-count" data-loading={loadingTotalRecipientCount}>
                <b>Total recipients</b> <span>{pluralize('person', totalRecipientCount, true)}</span>
              </div>
            )}
            <Button disabled={formDisabled || isSending} data-test-send>
              {isSending ? 'Sending' : 'Send now'}
            </Button>
          </FormLayout>
        </form>
      </FormProvider>
      <Modal
        isOpen={isModalOpen}
        title="Confirm takeover replacement"
        primaryText="Replace"
        primaryAction={primaryClicked}
        secondaryText="Keep current message"
        secondaryAction={secondaryClicked}
        onClose={closeClicked}
        showCloseButton={true}
      >
        <div className="send-message_active-takeover-body">
          <div>An emergency alert is currently live on screens in this location:</div>
          <div className="send-message__active-takeover-message">
            "<div className="send-message__active-takeover-message-inner">{activeTakeoverMessage}</div>"
          </div>
          <div>
            By confirming, your new message will replace the current message immediately. Are you sure you want to
            proceed?
          </div>
        </div>
      </Modal>
    </>
  );
};
