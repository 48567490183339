import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';

import type TicketCategoryModel from './ticket-category';
import type TicketConfigurationModel from './ticket-configuration';

class TicketCategoryEmailModel extends Model {
  @attr('string') declare email: string;

  @belongsTo('ticket-category', { async: false }) declare ticketCategory: TicketCategoryModel | null;
  @belongsTo('ticket-configuration', { async: true })
  declare ticketConfiguration: AsyncBelongsTo<TicketConfigurationModel>;
}

export default TicketCategoryEmailModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'ticket-category-email': TicketCategoryEmailModel;
  }
}
