import Service, { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { enqueueTask, waitForProperty } from 'ember-concurrency';
import type PaymentSourceModel from 'garaje/models/payment-source';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import type { RecordArray } from 'garaje/utils/type-utils';
import { alias, bool, not } from 'macro-decorators';

type PaymentSources = RecordArray<PaymentSourceModel> | PaymentSourceModel[];

export default class BillingService extends Service {
  @service declare state: StateService;
  @service declare store: StoreService;

  @bool('loadPaymentSources.last.isSuccessful') isLoaded!: boolean;
  @not('isLoaded') isLoading!: boolean;
  @alias('loadPaymentSources.lastSuccessful.value') paymentSources!: PaymentSources;
  @alias('loadPaymentSources.lastSuccessful.value.firstObject') paymentSource!: PaymentSourceModel | null | undefined;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    void this.loadPaymentSources.perform();
  }

  // the task gets performed on init with the enqueue modifier,
  // so that the payment source gets loaded in a non-blocking way,
  // then subsequent task instances will enqueue and wait
  // for the init'd task instance to complete,
  // and then early return with the result of the init'd task instance
  loadPaymentSources = enqueueTask(async (forceReload = false): Promise<PaymentSources | null> => {
    if (this.loadPaymentSources.lastSuccessful && !forceReload) {
      return this.loadPaymentSources.lastSuccessful.value;
    }
    await waitForProperty(this, 'state.currentCompany.id', () => true); // we can not continue without a company id if user is passport-only or unconfirmed
    const companyId = this.state.currentCompany.id;
    let paymentSources: RecordArray<PaymentSourceModel> | PaymentSourceModel[] = await this.store.query(
      'payment-source',
      {
        filter: { 'company-id': companyId },
      },
    );
    if (isEmpty(paymentSources)) {
      paymentSources = [this.store.createRecord('payment-source', { companyId })];
    } else {
      paymentSources.forEach((paymentSource) => {
        paymentSource.companyId = companyId;
      });
    }
    return paymentSources;
  });
}
