import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { format, subMinutes } from 'date-fns';
import { task } from 'ember-concurrency';
import type StateService from 'garaje/services/state';

interface AvailableResourcePopupArgs {
  selectedDate: Date;
  featureName: string;
  mapFloorName: string;
  resourceType: string;
  isReservedByCurrentUser: boolean;
  onReleaseResource: () => Promise<void>;
  onCancel: () => void;
}

export default class AvailableResourcePopup extends Component<AvailableResourcePopupArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;

  get formattedDate(): string {
    const displayDate = subMinutes(this.args.selectedDate, this.state.minutesBetweenTimezones(this.args.selectedDate));
    return format(displayDate, 'MMMM dd, yyyy');
  }

  onReleaseResourceTask = task({ drop: true }, async () => {
    return await this.args.onReleaseResource();
  });
}
