import Component from '@glimmer/component';
import { service } from '@ember/service';
import { set } from '@ember/object';
import { dropTask } from 'ember-concurrency';

/**
 * @param {Class<Flow>}               flow
 * @param {Class<Location>}           location
 * @param {Boolean}                   readOnly
 * @param {Boolean}                   surveyEnabled
 */
export default class VisitorSurvey extends Component {
  @service flashMessages;
  @service metrics;

  @dropTask
  *updateFlowSurveyConfigAndSave(value = null) {
    set(this.args.flow, 'visitorSurveyEnabled', value);
    const actionName = value === true ? 'enabled' : 'disabled';
    const msg = `Survey email ${actionName} for ${this.args.flow.name}`;
    try {
      yield this.args.flow.save();
      const name = value ? 'Survey Enabled - Flow' : 'Survey Disabled - Flow';
      this.metrics.trackEvent(name, {
        flow_id: this.args.flow.id,
        flow_name: this.args.flow.name,
        location_id: this.args.location.id,
        location_name: this.args.location.name,
      });
      this.flashMessages.showAndHideFlash('success', msg);
    } catch (e) {
      this.flashMessages.showFlash('error', e);
    }
  }
}
