import Component from '@glimmer/component';
import { format, subMinutes } from 'date-fns';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class ReservationApprovalScreen extends Component {
  @service state;

  get formattedDate() {
    const { successfulReservations } = this.args;
    const startTime = successfulReservations[0].startTime;
    return format(subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)), 'eeee, MMMM do yyyy');
  }

  @action
  formattedMultiDate(res) {
    const startTime = res.startTime;
    return format(subMinutes(res.startTime, this.state.minutesBetweenTimezones(startTime)), 'eee, MMM dd, yyyy');
  }

  @action
  formattedPartialDate(res) {
    const startTime = res.startTime;
    const endTime = res.endTime;
    const formattedStartTime = format(subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)), 'h:mmaaa');
    const formattedEndTime = format(subMinutes(endTime, this.state.minutesBetweenTimezones(endTime)), 'h:mmaaa');
    const date = format(subMinutes(startTime, this.state.minutesBetweenTimezones(startTime)), 'eee, MMM dd, yyyy');
    return `${date} from ${formattedStartTime} to ${formattedEndTime}`;
  }
}
