import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Function}                  updateWarning
 * @param {Array}                     localeOptions
 * @param {Model<Location>}           model
 * @param {Function}                  transitionToRoute
 * @param {Function}                  updateLocale
 */
export default class I18nConfig extends Component {
  @service flashMessages;
  @service workplaceMetrics;
  @tracked isEditing = false;

  get isDirty() {
    if (this.args.model) {
      return 'locale' in this.args.model.changedAttributes();
    } else {
      return false;
    }
  }

  @dropTask
  *saveTask(location) {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_LANGUAGE_SETTINGS_SAVE_BUTTON_CLICKED');
    try {
      yield location.save();
      yield location.belongsTo('config').reload();

      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.isEditing = false;
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', error);
    }
  }

  @action
  rollback() {
    this.args.model.rollbackAttributes();
  }

  @action
  edit() {
    this.workplaceMetrics.trackEvent('LOCATION_SETTINGS_LANGUAGE_SETTINGS_EDIT_BUTTON_CLICKED');
    this.isEditing = true;
  }

  @action
  closeEditing() {
    this.isEditing = false;
  }
}
