import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assert } from '@ember/debug';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isNone } from '@ember/utils';
import { task, dropTask, restartableTask, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import urlBuilder from 'garaje/utils/url-builder';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { isBlank } from '@ember/utils';

const MINIMUM_BLOCKLIST_RECORD_THRESHOLD = 2;

/**
 * Base component for common blocklist configuration features. You almost certainly do not want to use
 * this component directly; instead, use one of the more specific components (or create your own as needed):
 * - <Security::ConfigureBlocklist::Location> for managing location/company blocklist
 * - <Security::ConfigureBlocklist::Property> for managing a multi-tenant property blocklist
 *
 * @param {object[]} blocklistFilters             - array (or array-like Ember Data result) of all blocklist filters to display
 * @param {string}   csvImportRoute               - string representing the route to the blocklist CSV upload page
 * @param {string}   filterParentRelationshipName - string with the name of the relationship to which a new filter entry should be saved (e.g., "location" or "property")
 * @param {object}   filterParent                 - Ember Data model of the parent to which a new filter entry should be attached
 * @param {boolean}  hasCreatePermission          - boolean indicating whether the user has the ability to create blocklist filter records
 * @param {boolean}  hasUploadPermission          - boolean indicating whether the user has the ability to upload a blocklist CSV
 * @param {Function} loadBlocklistContacts        - function to load blocklist contacts. should return an array or array-like Ember Data result (or a Promise that resolves to that)
 * @param {Function} loadBlocklistFilters         - function to load blocklist filters. should return an array or array-like Ember Data result (or a Promise that resolves to that)
 * @param {Function} [onContactsChange]           - function triggered when list of contacts is changed or saved. Received a single Boolean argument, indicating whether the list is changed & unsaved (true) or not (false).
 * @param {boolean}  readOnly                     - boolean indicating whether the blocklist should be read-only or not (blocklist contacts and filter records)
 * @param {Function} saveBlocklistContacts        - function to handle saving new list of blocklist notification contacts. Receives one argument (an array of all currently-selected contacts). If this function returns a promise it is awaited.
 * @param {Function} searchUsers                  - function to return a filtered list of admins that can be selected as notification contacts. Receives one argument (a string representing the current search query) and should return an array of User models, an array-like Ember Data result, or a Promise that resolves to either of those
 */
export default class ConfigureBlocklist extends Component {
  @service ajax;
  @service flashMessages;
  @service metrics;
  @service store;
  @service featureFlags;
  @service uploaderFactory;
  @service exporter;

  @tracked searchText = '';
  @tracked blocklistFilterInModal = undefined;
  @tracked blocklistContacts = [];
  @tracked blocklistFilters = [];
  @tracked hasChangedBlocklistContacts = false;
  @tracked tempPhoto;
  @tracked tempId;

  get aboveMinimumBlocklistRecordThreshold() {
    return this.blocklistFilters.length > MINIMUM_BLOCKLIST_RECORD_THRESHOLD;
  }

  get sortedBlocklistFilters() {
    assert('You must pass @blocklistFilters to <Security::ConfigureBlocklist>', !isNone(this.args.blocklistFilters));
    return this.args.blocklistFilters.toArray().sortBy('fullName');
  }

  /*
   * Filters blocklistFilters based on the current value of 'searchText'
   */
  get filteredBlocklistFilters() {
    const { searchText, sortedBlocklistFilters } = this;

    if (isBlank(searchText)) {
      return sortedBlocklistFilters;
    }

    return sortedBlocklistFilters.filter((blocklistFilter) => {
      const regexp = new RegExp(searchText, 'i');
      return blocklistFilter.searchable.search(regexp) !== -1;
    });
  }

  async loadBlocklistFilters() {
    const blocklistFilters = await this.args.loadBlocklistFilters();
    this.blocklistFilters = blocklistFilters.toArray();
  }

  @action
  onInsert() {
    this.initBlocklistFiltersTask.perform();
    this.initBlocklistContactsTask.perform();
  }

  @action
  onDestroy() {
    if (this.blocklistFilterInModal && this.blocklistFilterInModal.isNew && !this.blocklistFilterInModal.isDestroying) {
      this.blocklistFilterInModal.unloadRecord();
    }
  }

  @restartableTask
  *mutateSearchText(searchText) {
    yield timeout(zft(200));

    this.searchText = searchText;
  }

  @dropTask
  *exportBlocklistFiltersAsCsv() {
    assert('You must pass @blocklistFilters to <Security::ConfigureBlocklist>', !isNone(this.args.blocklistFilters));
    yield timeout(zft(200));
    try {
      yield this.exporter.exportCsv.perform('blocklist-export.csv', this.args.blocklistFilters);
      this.flashMessages.showAndHideFlash('success', 'Block list downloaded!');
    } catch {
      this.flashMessages.showFlash('error', 'Block list download failed');
    }
  }

  @task
  *initBlocklistFiltersTask() {
    assert(
      'You must pass a @loadBlocklistFilters function as an argument to <Security::ConfigureBlocklist>',
      typeof this.args.loadBlocklistFilters === 'function',
    );
    try {
      yield this.loadBlocklistFilters();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  @task
  *initBlocklistContactsTask() {
    assert(
      'You must pass @loadBlocklistContacts to <Security::ConfigureBlocklist>',
      typeof this.args.loadBlocklistContacts === 'function',
    );
    try {
      this.blocklistContacts = yield this.args.loadBlocklistContacts();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
    }
  }

  @task
  *searchUsersTask(term) {
    assert('You must pass @searchUsers to <Security::ConfigureBlocklist>', typeof this.args.searchUsers === 'function');

    const re = new RegExp(`.*${term}.*`, 'i');
    const currentContactsIds = this.blocklistContacts.mapBy('id');
    const users = yield this.args.searchUsers(term);

    return users
      .uniqBy('id') // dedup since roles could point to same user
      .reject(({ id }) => currentContactsIds.includes(id)) // remove existing contacts
      .filter(({ fullName }) => fullName.match(re));
  }

  @task
  *saveBlocklistContactsTask() {
    assert(
      'You must pass @saveBlocklistContacts to <Security::ConfigureBlocklist>',
      typeof this.args.saveBlocklistContacts === 'function',
    );

    try {
      yield this.args.saveBlocklistContacts(this.blocklistContacts);
      this.hasChangedBlocklistContacts = false;
      this.args.onContactsChange?.(false);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error adding administrator', 'Please try again.');
    }
  }

  @task
  *uploadPhotoTask(photo, id) {
    try {
      const uploader = this.uploaderFactory.createUploader({
        url: urlBuilder.v3.blocklistFilter.photoUpload(id),
        method: 'POST',
        headers: { Accept: 'application/vnd.api+json' },
      });
      yield uploader.upload(photo);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error uploading photo', 'Please try again.');
    }
  }

  @task
  *deletePhotoTask(id) {
    try {
      yield this.ajax.del(urlBuilder.v3.blocklistFilter.photoUpload(id));
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error removing photo', 'Please try again.');
    }
  }

  @task
  *saveBlocklistFilterTask(blocklistFilter, photo) {
    const isNew = blocklistFilter.isNew;
    const editedFields = Object.keys(blocklistFilter.changedAttributes());
    const shouldDeletePhoto =
      !isNew &&
      !photo &&
      !blocklistFilter.photo.dataUrl &&
      !blocklistFilter.photo.original &&
      editedFields.includes('photo');

    try {
      if (photo) this.tempPhoto = blocklistFilter.photo.dataUrl;

      this.metrics.trackJobEvent(`Security - Blocked Person ${isNew ? 'Creation' : 'Update'} Requested`, {
        block_list_filter_id: blocklistFilter.id,
        edited_fields: editedFields,
      });

      if (shouldDeletePhoto) {
        // delete the photo before we save the record so that the photo is gone by the time the save updates the record
        yield this.deletePhotoTask.perform(blocklistFilter.id);
      }

      yield blocklistFilter.save();
      yield this.loadBlocklistFilters();

      this.metrics.trackJobEvent(`Security - Blocked Person Record ${isNew ? 'Created' : 'Updated'}`, {
        block_list_filter_id: blocklistFilter.id,
        processing_result: 'success',
      });

      this.tempId = blocklistFilter.id;
      this.blocklistFilterInModal = null;

      const message_title = isNew ? 'Saved!' : 'Updated!';

      this.flashMessages.showAndHideFlash('success', message_title);
      this.metrics.trackJobEvent(`Viewed Flash Message`, {
        type: 'success',
        message_title,
        message_code: isNew ? 'blocked_person_create_success' : 'blocked_person_update_success',
        message_code_type: 'block_list',
      });

      if (photo) {
        yield this.uploadPhotoTask.perform(photo, blocklistFilter.id);
        yield blocklistFilter.reload(); // reload the filter to get the uploaded photo
      }

      this.tempPhoto = null;
      this.tempId = null;
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackJobEvent(`Security - Blocked Person Record ${isNew ? 'Created' : 'Updated'}`, {
        block_list_filter_id: blocklistFilter.id,
        processing_result: 'failure',
        error_message,
      });

      const message_title = isNew ? 'Save failed' : 'Update failed';
      this.flashMessages.showAndHideFlash('error', message_title, 'Please try again.');

      this.metrics.trackJobEvent(`Viewed Flash Message`, {
        type: 'error',
        message_title,
        message_code: error_message,
        message_code_type: 'block_list',
      });
    }
  }

  @task
  *deleteBlocklistFilterTask(blocklistFilter) {
    try {
      this.metrics.trackJobEvent(`Security - Blocked Person Deletion Requested`, {
        block_list_filter_id: blocklistFilter.id,
      });

      const updatedBlocklistFilters = this.blocklistFilters.filter((item) => item.id !== blocklistFilter.id);
      yield blocklistFilter.destroyRecord();
      this.blocklistFilters = updatedBlocklistFilters;
      this.blocklistFilterInModal = null;

      this.metrics.trackJobEvent('Security - Blocked Person Record Deleted', {
        block_list_filter_id: blocklistFilter.id,
        processing_result: 'success',
      });

      const message_title = 'Deleted record';
      this.flashMessages.showAndHideFlash('success', message_title);
      this.metrics.trackJobEvent(`Viewed Flash Message`, {
        type: 'success',
        message_title,
        message_code: 'blocked_person_delete_success',
        message_code_type: 'block_list',
      });
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackJobEvent('Security - Blocked Person Record Deleted', {
        block_list_filter_id: blocklistFilter.id,
        processing_result: 'failure',
        error_message,
      });

      const message_title = 'Delete failed';
      this.flashMessages.showAndHideFlash('error', message_title, 'Please try again.');
      this.metrics.trackJobEvent(`Viewed Flash Message`, {
        type: 'error',
        message_title,
        message_code: error_message,
        message_code_type: 'block_list',
      });
    }
  }

  @action
  createBlocklistFilter() {
    assert(
      'You must pass @filterParentRelationshipName to <Security::ConfigureBlocklist>',
      !isNone(this.args.filterParentRelationshipName),
    );
    assert('You must pass @filterParent function to <Security::ConfigureBlocklist>', !isNone(this.args.filterParent));
    const newBlocklistFilter = this.store.createRecord('blacklist-filter', {
      [this.args.filterParentRelationshipName]: this.args.filterParent,
    });
    this.blocklistFilterInModal = newBlocklistFilter;
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Nav to Add New Blocked Person Button Clicked', { button_text: 'Add new' });
  }

  @action
  editBlocklistFilter(blocklistFilter) {
    this.blocklistFilterInModal = blocklistFilter;
    this.metrics.startJob();
    this.metrics.trackJobEvent('Security - Nav to Edit Blocked Person Button Clicked', { button_text: 'Edit' });
  }

  @action
  closeBlocklistFilterModal(blocklistFilter) {
    blocklistFilter && blocklistFilter.rollbackAttributes();
    this.blocklistFilterInModal = null;
  }

  @action
  updateBlocklistContacts(users) {
    this.hasChangedBlocklistContacts = true;
    this.args.onContactsChange?.(true);
    this.blocklistContacts = users;
  }
}
