import Route from '@ember/routing/route';
import { action, get, set } from '@ember/object';
import { service } from '@ember/service';
import { hash } from 'rsvp';
import { v4 as uuid } from 'uuid';
import EmberObject from '@ember/object';

export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsRoute extends Route {
  @service metrics;

  model() {
    const { flow, vrSubscription } = this.modelFor('visitors.settings.visitor-types.flow');
    const agreementPage = get(flow, 'agreementPage');
    const agreements = agreementPage.then((page) => get(page, 'agreements'));
    const currentLocation = this.modelFor('current-location');
    const signInFields = get(flow, 'signInFieldPage').then((page) => get(page, 'signInFields'));
    const tracking = EmberObject.create({
      jobId: uuid(),
      currentLocationId: currentLocation.id,
    });

    return hash({
      agreements,
      agreementPage,
      flow,
      signInFields,
      tracking,
      vrSubscription,
    });
  }

  resetController() {
    const model = this.modelFor(this.routeName);
    set(model.tracking, 'jobId', null);
    set(model.tracking, 'eventName', null);
    set(model.tracking, 'currentLocationId', null);
  }

  @action
  trackAddLegalDocStarted() {
    const model = this.modelFor(this.routeName);
    this.metrics.trackEvent('Legal Docs - Go To Add Legal Document Button Clicked', {
      button_text: 'add another document',
      location_id: model.tracking.currentLocationId,
      job_id: model.tracking.jobId,
    });
    set(model.tracking, 'eventName', 'Legal Docs - Add Legal Document Save Clicked');
  }

  @action
  trackEditLegalDocStarted() {
    const model = this.modelFor(this.routeName);
    set(model.tracking, 'eventName', 'Legal Docs - Legal Document Edited');
  }
}
