import Component from '@glimmer/component';
import type LocationModel from 'garaje/models/location';
import type { TransientRecord } from 'jsonapi/response';

interface SignInFlowsFlowsByLocationCardLocationRowComponentSignature {
  Args: {
    locationObj: TransientRecord<LocationModel>;
    meta?: {
      'total-count'?: {
        'location-id': {
          [key: string]: number;
        };
      };
    };
  };
}

export default class SignInFlowsFlowsByLocationCardLocationRowComponent extends Component<SignInFlowsFlowsByLocationCardLocationRowComponentSignature> {
  get flowCount(): number | undefined {
    const { locationObj } = this.args;
    return this.args.meta?.['total-count']?.['location-id'][locationObj.id];
  }
}
