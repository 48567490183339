import { isBlank } from '@ember/utils';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type NotificationScheduleModel from 'garaje/models/notification-schedule';
import adapter from 'garaje/utils/decorators/adapter';
import addressAttributes from 'garaje/utils/decorators/models/address-attributes';

const DEFAULT_NOTIFICATION_TEMPLATE_SUBJECT =
  'You have a delivery! Please pick it up at %{delivery_area} at your earliest convenience';
const DEFAULT_NOTIFICATION_TEMPLATE_BODY = 'Dear %{recipient}, you have a new delivery waiting at %{delivery_area}';

@adapter('deliveries')
@addressAttributes
class DeliveryAreaModel extends Model {
  defaultNotificationTemplateBody = DEFAULT_NOTIFICATION_TEMPLATE_BODY;
  defaultNotificationTemplateSubject = DEFAULT_NOTIFICATION_TEMPLATE_SUBJECT;

  @belongsTo('notification-schedule', { async: true })
  declare notificationSchedule: AsyncBelongsTo<NotificationScheduleModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  @attr('date') declare createdAt: Date;
  @attr('boolean', { defaultValue: false }) declare disableSnooze: boolean;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
  @attr('string') declare locationName: string;
  @attr('string') declare name: string;
  @attr('string', { defaultValue: DEFAULT_NOTIFICATION_TEMPLATE_BODY }) declare notificationTemplateBody: string;
  @attr('string', { defaultValue: DEFAULT_NOTIFICATION_TEMPLATE_SUBJECT }) declare notificationTemplateSubject: string;
  @attr('boolean', { defaultValue: false }) declare requirePhoto: boolean;
  @attr('boolean', { defaultValue: false }) declare requireSignature: boolean;
  @attr('string') declare timeZone: string;

  get requiresSecurePickUp(): boolean {
    return this.requirePhoto || this.requireSignature;
  }

  get nameWithLocationName(): string {
    const name = this.name;
    const locationName = this.locationName;
    return isBlank(locationName) ? name : `${locationName}: ${name}`;
  }
}

export default DeliveryAreaModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'delivery-area': DeliveryAreaModel;
  }
}
