import Controller from '@ember/controller';
import { service } from '@ember/service';
import { filterBy } from 'macro-decorators';

export default class LocationOverviewLocationsController extends Controller {
  @service featureFlags;
  @service('skinnyLocations') skinnylocationsService;
  @service('locations') locationsService;
  @filterBy('model.groups', 'isNew', false) groups;
}
