// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed, set, setProperties } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

/**
 * List of groups
 * @param {Array<Group>}         groups           List of groups to be rendered
 */

export default class GroupsTableComponent extends Component {
  @service flashMessages;
  @service metrics;

  sortField = 'name';
  sortDirection = 'asc';

  @computed('groups.@each.name', 'sortDirection', 'sortField')
  get sortedGroups() {
    const sorted = this.groups.sortBy(this.sortField);
    if (this.sortDirection === 'desc') {
      sorted.reverse();
    }
    return sorted;
  }

  @dropTask
  *removeGroupTask() {
    try {
      yield this.groupToRemove.destroyRecord();

      this.metrics.trackEvent('Group Action Taken', {
        action: 'group_deleted',
        group_name: this.groupToRemove.name,
      });

      setProperties(this, { groupToRemove: null, showGroupRemovalConfirmation: false });
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @dropTask
  *changeGroupName(group, name) {
    set(group, 'name', name);
    try {
      yield group.save();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  sortGroups(sortField, sortDirection) {
    setProperties(this, { sortField, sortDirection });
  }

  /**
   * Sets `group` to be removed and show the confirmation modal
   * @param {Group} groupToRemove
   */
  @action
  confirmGroupDeletion(groupToRemove) {
    setProperties(this, { groupToRemove, showGroupRemovalConfirmation: true });
  }

  /**
   * Hides confirmation modal and resets `group` to be removed
   */
  @action
  closeRemovalConfirmation() {
    setProperties(this, { groupToRemove: null, showGroupRemovalConfirmation: false });
  }
}
