import type { AsyncBelongsTo } from '@ember-data/model';
import { attr, belongsTo } from '@ember-data/model';
import AbstractSummaryPageModel from 'garaje/models/abstract/abstract-summary-page';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import adapter from 'garaje/utils/decorators/adapter';

export const EXPLOYEE_SUMMARY_PAGE = Object.freeze({
  defaultMessage:
    '# Not approved to work in the workplace \n\nWe’re sorry, but you won’t be allowed into the workplace. Please try again tomorrow.',
});

@adapter('summary-page')
class EmployeeSummaryPageModel extends AbstractSummaryPageModel {
  @belongsTo('employee-screening-flow', { async: true }) declare flow: AsyncBelongsTo<EmployeeScreeningFlowModel>;

  @attr('string', { defaultValue: EXPLOYEE_SUMMARY_PAGE.defaultMessage }) declare message: string;
  @attr('string', { defaultValue: EXPLOYEE_SUMMARY_PAGE.defaultMessage }) declare defaultMessage: string;
}

export default EmployeeSummaryPageModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-summary-page': EmployeeSummaryPageModel;
  }
}
