import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

// NOTE: this file is extended by a vfd settings route
class SettingsWelcomeScreenRoute extends Route {
  @service abilities;
  @service state;
  @service('global-setting-batch') globalSettingBatchService;
  @service router;

  titleToken = 'Welcome screen';

  beforeModel() {
    if (this.abilities.cannot('visit advance-settings')) {
      this.router.transitionTo('billing');
    }
  }

  model() {
    const { currentLocation, vrSubscription } = this.state;

    if (this.abilities.can('propagate global-setting-batch')) {
      const globalSettingBatchService = this.globalSettingBatchService;
      globalSettingBatchService.createGlobalSettingBatch({ parent: currentLocation });
    }

    return hash({ currentLocation, vrSubscription });
  }

  setupController(controller) {
    super.setupController(...arguments);
    const { revision } = this.paramsFor('application');

    controller.params = revision ? { revision: revision } : null;
  }
}

export default SettingsWelcomeScreenRoute;
