import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type MetricsService from 'garaje/services/metrics';
import type PlanFeaturesService from 'garaje/services/plan-features';
import type StateService from 'garaje/services/state';
import type TrialService from 'garaje/services/trial';
import { alias, equal } from 'macro-decorators';

export interface CtaEventProperties extends AnalyticsTraits {
  cta_id: string;
  cta_title: string | null;
  cta_body: string | null;
  cta_type: string;
  cta_clickable_type: string | null;
  cta_clickable_text: string | null;
  cta_intent: string;
}

export interface UpgradeQueryParams {
  plan: 'standard' | 'premium';
  period: 'monthly' | 'yearly';
}

export default class FeatureOrUpgradeButton extends Component<{
  featureName: string;
  isAuthorized: boolean;
  isSlack: boolean;
  level: string;
  logCTAEvents: boolean;
  onUpgrade?: () => void | null;
  shouldCheckMinLevel: boolean;
  upgradeButtonText: string;
  upgradeClass: string;
  upgradeQueryParams: UpgradeQueryParams;
  vrOnly: boolean;
}> {
  @service declare abilities: AbilitiesService;
  @service declare metrics: MetricsService;
  @service declare planFeatures: PlanFeaturesService;
  @service declare state: StateService;
  @service declare trial: TrialService;

  @alias('state.vrSubscription') declare vrSubscription: StateService['vrSubscription'];
  @alias('vrSubscription.hasTrialDaysLeft') declare hasTrialDaysLeft: boolean;
  @alias('trial.trials') declare plans: TrialService['trials'];
  @equal('vrSubscription.plan', 'basic') declare isBasicUser: boolean;

  get levelString(): string {
    const planName = this.planFeatures.planIdToName(this.args.level);
    return capitalize(planName || '');
  }

  get showSignUpForVR(): boolean {
    const vrActive = isPresent(this.vrSubscription);

    return !vrActive && this.args.vrOnly && !this.args.isSlack;
  }

  get canAccessFeature(): boolean {
    const trial = this.trial;
    // eslint-disable-next-line ember/no-get
    const canTrialFeature = get(trial, 'canTrialFeature');
    const isAuthorized = this.args.isAuthorized;
    const level = this.args.level;
    const shouldCheckMinLevel = this.args.shouldCheckMinLevel;
    const hasDeliveries = !!this.state?.features?.canAccessDeliveriesApplication;

    if (isAuthorized) {
      return true;
    }
    if (this.args.isSlack && hasDeliveries) {
      return true;
    }
    if (canTrialFeature) {
      return trial.canAccess(level);
    }
    if (shouldCheckMinLevel) {
      return this._hasMinLevel(level);
    }
    return false;
  }

  get upgradeButtonText(): string {
    return this.args.upgradeButtonText ?? `Upgrade to ${this.levelString}`;
  }

  _hasMinLevel(level: string): boolean {
    // eslint-disable-next-line ember/no-get
    const actualPlan = this.plans.indexOf(get(this, 'vrSubscription.plan') as string);
    const requiredPlan = this.plans.indexOf(level);
    return actualPlan >= requiredPlan;
  }

  @action
  upgrade(): void {
    if (typeof this.args.onUpgrade === 'function') {
      this.args.onUpgrade();
    }
  }

  ctaEventProperties(intent: string, text: string): CtaEventProperties {
    return {
      cta_id: this.args.featureName
        ? `${this.args.featureName}_feature_or_upgrade_button`
        : 'feature_or_upgrade_button',
      cta_title: null,
      cta_body: null,
      cta_type: 'button',
      cta_clickable_type: this.abilities.can('visit billing') ? 'button' : 'none',
      cta_clickable_text: this.abilities.can('visit billing') ? text : null,
      cta_intent: intent,
    };
  }

  get shouldLogCTAEvents(): boolean {
    // default behavior is to log, unless @logCTAEvents is explicitly false (not just falsy)
    return this.args.logCTAEvents !== false;
  }

  @action
  logCTAViewedEvent(intent: string, element: HTMLElement): void {
    if (this.shouldLogCTAEvents) {
      this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties(intent, element.innerText.trim()));
    }
  }

  @action
  logCTAClickedEvent(intent: string, event: Event): void {
    if (this.shouldLogCTAEvents) {
      this.metrics.trackEvent(
        'CTA Clicked',
        this.ctaEventProperties(
          intent,
          (event.target as null | (EventTarget & { innerText: string }))!.innerText.trim(),
        ),
      );
    }
  }
}
