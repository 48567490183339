import Model, { attr, belongsTo } from '@ember-data/model';
import { isDefaultNotificationPeriod } from 'garaje/utils/notification-schedule';
import NotificationPeriod from 'garaje/models/notification-period';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('deliveries')
class NotificationSchedule extends Model {
  @belongsTo('delivery-area', { async: true }) deliveryArea;
  @attr('boolean') active;

  @attr('immutable-array', {
    deserializeItem: (item) => new NotificationPeriod(item),
    serializeItem: (item) => item.serialize(),
    defaultValue: () => [],
  })
  notificationPeriods;

  get isDefault() {
    return this.active && this.hasDefaultNotificationPeriods;
  }

  get hasDefaultNotificationPeriods() {
    const { notificationPeriods } = this;
    return notificationPeriods.length === 7 && notificationPeriods.every(isDefaultNotificationPeriod);
  }
}

export default NotificationSchedule;
