import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';
import { task, timeout, dropTask, restartableTask, lastValue } from 'ember-concurrency';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { pluralize } from 'ember-inflector';
import { v1 as uuid } from 'uuid';
import zft from 'garaje/utils/zero-for-tests';
import { alias, or, empty } from 'macro-decorators';
import normalizeCouponCode from 'garaje/utils/normalize-coupon-code';
import { WORKPLACE_SEAT_MINIMUM } from 'garaje/utils/plan-details';

/**
 * Form for purchasing additional subscription quantity
 * @param {Array<String>}        activeUnits         Required - List of names of active units (locations/delivery areas/etc)
 * @param {Class<PaymentSource>} paymentSource       Required - A payment source model
 * @param {Class<Subscription>}  subscription        Required - A subscription model
 * @param {Function}             transitionToBilling Required - Action to transition to billing
 * @param {String}               viaLabel            Required - Text on the CTA used to open this modal
 * @param {String}               viaObject           Required - Type of CTA used to open this modal (button or text_link)
 * @param {Function}             closeAction         Not Required - function used to close modal rather than route to billing, default behavior is to route to billing
 */

export default class AddQuantityModal extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  @tracked couponCode = null;
  @tracked isWorkplace = this.args.subscription.app === 'empxp';
  @tracked lastValidCouponCode = null;
  @tracked quantity =
    this.isWorkplace && this.args.subscription.quantity < WORKPLACE_SEAT_MINIMUM
      ? WORKPLACE_SEAT_MINIMUM - this.args.subscription.quantity
      : 1;
  @tracked trackingId = uuid();

  @tracked isAddDisabledMinSeat = false;

  minWorkplaceSeatNumber = WORKPLACE_SEAT_MINIMUM;

  @lastValue('fetchEstimateTask') estimate;
  @empty('estimate') isInitiallyLoading;
  @alias('estimate.nextInvoiceEstimate.lineItems.firstObject.unitAmount') unitCost;
  @or('updateQuantityTask.isRunning', 'fetchEstimateTask.isRunning') isLoading;

  get newCost() {
    if (!this.unitCost) {
      return 0;
    }
    return this.unitCost * this.quantity;
  }

  get title() {
    return `Purchase ${pluralize(this.args.subscription.unitType)}`;
  }

  get newDiscount() {
    if (!this.lastValidCouponCode) {
      return null;
    }
    return this.estimate.nextInvoiceEstimate.discounts.firstObject;
  }

  constructor() {
    super(...arguments);
    this.fetchEstimateTask.perform();
    this.trackModalEnteredTask.perform();
  }

  @restartableTask
  *updateQuantityTask({ target }) {
    let { value: newQuantity } = target;
    if (!newQuantity) {
      return;
    }
    newQuantity = parseInt(newQuantity, 10);
    if (isNaN(newQuantity) || newQuantity < 1 || newQuantity > 99) {
      // The user has typed an invalid value into the input
      target.value = this.quantity;
    } else {
      this.quantity = newQuantity;
      if (this.isWorkplace) {
        if (this.args.subscription.quantity + newQuantity < WORKPLACE_SEAT_MINIMUM) {
          this.isAddDisabledMinSeat = true;
          return;
        } else {
          this.isAddDisabledMinSeat = false;
        }
      }
      yield timeout(zft(500));
      yield this.fetchEstimateTask.perform(this.quantity);
    }
  }

  @dropTask
  *applyCouponCodeTask(couponCode) {
    this.couponCode = normalizeCouponCode(couponCode);
    yield this.fetchEstimateTask.perform();
    this.couponCode = null;
  }

  @restartableTask
  *fetchEstimateTask() {
    const couponCode = this.couponCode !== null ? this.couponCode : this.lastValidCouponCode;
    const previousEstimate = this.estimate;

    try {
      const { app, quantity, plan, period } = this.args.subscription;
      const estimate = yield this.store
        .createRecord('subscription-estimate', {
          app,
          quantity: quantity + this.quantity,
          plan,
          period,
          couponCode,
          subscription: this.args.subscription,
        })
        .save();

      this.lastValidCouponCode = couponCode;
      this.couponCode = null;

      return estimate;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
      return previousEstimate;
    }
  }

  @dropTask
  *handleSubmitTask(event) {
    event.preventDefault();

    if (this.fetchEstimateTask.isRunning) {
      return;
    }

    try {
      const oldQuantity = this.args.subscription.quantity;
      const newQuantity = oldQuantity + this.quantity;
      set(this.args.subscription, 'quantity', newQuantity);
      set(this.args.subscription, 'couponCode', this.lastValidCouponCode);
      yield this.args.subscription.save();
      this.flashMessages.showAndHideFlash('success', 'Purchase complete');
      this.args.transitionToBilling();
      this.metrics.trackEvent('Account modified', {
        product: this.args.subscription.app,
        account_change_type: 'subscription',
        account_change_object: 'quantity',
        account_change_action: 'expansion',
        object_change_from: oldQuantity,
        object_change_to: newQuantity,
      });
    } catch (e) {
      this.args.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @task
  *trackModalEnteredTask() {
    yield this.metrics.trackPage('Modal entered', {
      action_id: this.trackingId, // Auto-generated ID to link multiple properties firing to a single action
      modal_title: this.title, // title of the modal
      modal_type: 'subscription_change', // modal type
      modal_purpose: 'add_quantity', // purpose of modal
      via_object: this.args.viaObject, // object interacted with that triggered the modal opening
      via_action: 'click', // type of interaction
      via_label: this.args.viaLabel, // Label of the button or element being interacted with
      num_warnings_shown: 0, // Number of warning boxes displayed in the Modal. Can be 0.
      warning_text: null, // Text list (JSON is ok) of the message displayed in the warning boxes. Useful to track in case we want to optimize these in the future.
      product: this.args.subscription.app, // Visitor, desk, rooms, etc
      current_quantity: this.args.subscription.quantity, // Current quantity
    });
  }
}
