import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import { DISCARDED_RESPONSE_STRINGS } from 'garaje/utils/enums';

interface EntriesInputFieldComponentSignature {
  Args: {
    changeset: DetailedChangeset<SignInFieldModel>;
    update: (value: string) => void;
    disabled: boolean;
    isReadonly: boolean;
    label: string;
  };
}

export default class EntriesInputFieldComponent extends Component<EntriesInputFieldComponentSignature> {
  @tracked isFocused = false;

  get displayValue(): string | null | undefined {
    if (this.args.changeset.value === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER) {
      return DISCARDED_RESPONSE_STRINGS.DISPLAY_TEXT;
    }

    return this.args.changeset.value;
  }

  get isDisabled(): boolean {
    return (
      this.args.disabled || this.args.isReadonly || this.args.changeset.value === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER
    );
  }

  get isRequired(): boolean {
    return this.args.changeset.required && !this.args.changeset.allowVisitorRespondents;
  }
}
