import Route from '@ember/routing/route';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaRoute extends Route {
  @service abilities;
  @service state;
  @service store;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('manage deliveries')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model({ delivery_area_id }) {
    const { currentLocation } = this.state;
    const deliveryAreas = findAllDeliveryAreasForLocation(this.store, currentLocation);
    const deliveryArea = this.store.findRecord('delivery-area', delivery_area_id); // eslint-disable-line camelcase
    return hash({ currentLocation, deliveryArea, deliveryAreas });
  }

  afterModel({ currentLocation, deliveryArea }) {
    const locationId = deliveryArea.belongsTo('location').id();

    // if we change location, we want to make sure that the delivery area the
    // user is looking at, belongs to "currentLocation".
    if (locationId !== currentLocation.id || !deliveryArea.enabled) {
      return this.router.transitionTo('deliveries.settings.delivery-areas.index');
    }
  }
}
