import Model, { attr, belongsTo } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import type LocationModel from 'garaje/models/location';

interface TakeoverMetadata {
  title: string;
  message: string;
  created_at: string;
}

export default class TakeoverModel extends Model {
  @attr() declare metadata: TakeoverMetadata;
  @attr('number') declare startAt: Date;
  @attr('number') declare endAt: number;

  @belongsTo('location', { async: true }) declare location: LocationModel;
  @belongsTo('company', { async: true }) declare company: CompanyModel;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    takeover: TakeoverModel;
  }
}
