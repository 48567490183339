import ApplicationSerializer from './application';
import { get } from '@ember/object';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';

export default class extends ApplicationSerializer {
  attrs = {
    approvalStatus: {
      serialize: false,
    },
  };

  @service state;
  @service currentLocation;

  normalize(store, hash) {
    if (hash.relationships) {
      hash.relationships.reservations = { links: { related: urlBuilder.rms.reservation(hash.id) } };
    }
    return super.normalize(...arguments);
  }

  serializeIntoHashForCreate(hash, type, snapshot, options) {
    Object.assign(hash, this.serializeForCreate(snapshot, options));
  }

  /*
   * Using a custom serialize method for create so
   * that iPad and Garaje can share the same route
   * for create. Here's what we are changing:
   *
   * * Strip unpermitted parameters
   *
   *   e.g.:
   *   { data: { attribues: {
   *     'full-name': 'bob s',
   *     'agreement-link': 'bit.ly/yey' }}}
   *
   *   will result in:
   *   { data: { attribues: {
   *     'full-name': 'bob s' }}}
   *
   * * Convert user-data from array to object
   *
   *   Originall we send:
   *   { data: { attribues: {
   *     'user-data': [{field: 'field name', value: '42']}}}
   *
   *   API expects:
   *   { data: { attribues: {
   *     'user-data': {{'field name': '42'}}}}
   *
   * * Rename field to match what API expects
   *
   *   Originally we send:
   *   { data: { attribues: {
   *     'signed-in-at': '[timestamp]' }}}
   *
   *   API expects:
   *   { data: { attribues: {
   *     'finalized-at': '[timestamp]' }}}
   *
   */
  serializeForCreate() {
    const json = this.serialize(...arguments);

    // Strip model props that are not permitted for create
    const permittedProperties = [
      'current-location-id',
      'email',
      'phone',
      'print-badge',
      'private-notes',
      'property-notes',
      'send-host-notification',
      'signed-in-at',
      'user-data',
      'full-name',
      'flow-name',
      'locality',
    ];

    const flow = get(this, 'currentLocation.flows').findBy('name', json.data.attributes['flow-name']);
    const additionalGuests = get(flow, 'additionalGuests');

    if (get(this, 'state.vrSubscription.canAccessGroupSignIn') && additionalGuests) {
      permittedProperties.push('additional-guests');
    }

    Object.keys(json.data.attributes).forEach((prop) => {
      if (permittedProperties.indexOf(prop) === -1) {
        delete json.data.attributes[prop];
      }
    });

    // Convert user-data from array to object
    const userDataArray = json.data.attributes['user-data'];
    const userDataObj = {};

    userDataArray.forEach((item) => {
      userDataObj[item.field] = item.value;
    });

    json.data.attributes['user-data'] = userDataObj;

    // Rename field to match what create expects
    json.data.attributes['finalized-at'] = json.data.attributes['signed-in-at'];
    delete json.data.attributes['signed-in-at'];

    return json;
  }
}
