import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type AnnouncementModel from 'garaje/models/announcement';
import type { ANNOUNCEMENT_EMPLOYEE_AUDIENCE, ANNOUNCEMENT_VISITOR_AUDIENCE } from 'garaje/utils/enums';

export default class AnnouncementAudienceModel extends Model {
  @belongsTo('announcement', { async: true }) declare announcement: AsyncBelongsTo<AnnouncementModel>;

  // Attributes
  @attr('string') declare employeeAudience?: ANNOUNCEMENT_EMPLOYEE_AUDIENCE;
  @attr('string') declare visitorAudience?: ANNOUNCEMENT_VISITOR_AUDIENCE;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-audience': AnnouncementAudienceModel;
  }
}
