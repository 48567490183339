import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import _countBy from 'lodash/countBy';
import { unique } from 'macro-decorators';

/**
 * @param {ApprovalStatus}  approvalStatus
 */
export default class ApprovalBlocklistReport extends Component {
  @service store;

  @tracked blocklistFilter = null;

  @unique('blocklistFilterIdsFromReports') blocklistFiltersIdsThatMatched;

  constructor() {
    super(...arguments);
    this.loadBlocklistFilterTask.perform();
  }

  get blocklistFilterIdsFromReports() {
    return this.args.approvalStatus.blocklistReport
      .map((blocklistReport) => blocklistReport.blacklistFilterId)
      .filter((blocklistFilterId) => !!blocklistFilterId);
  }

  get blocklistFilterReportCounts() {
    return _countBy(this.blocklistFilterIdsFromReports);
  }

  get topMatchedFilterId() {
    const counts = this.blocklistFilterReportCounts;
    const sorted = this.blocklistFiltersIdsThatMatched.sort((a, b) => counts[b] - counts[a]);
    return sorted[0];
  }

  @task
  @waitFor
  *loadBlocklistFilterTask() {
    if (!this.topMatchedFilterId) return;
    const blocklistFilter = yield this.store.findRecord('blacklist-filter', this.topMatchedFilterId);
    this.blocklistFilter = blocklistFilter;
  }
}
