import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type FeatureConfigService from 'garaje/services/feature-config';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';

export default class RoombaIndexRoute extends Route {
  @service declare router: RouterService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare featureConfig: FeatureConfigService;

  async beforeModel(): Promise<void> {
    if (!this.featureConfig.isEnabled('rooms.accessRoomsTab')) {
      return void this.router.transitionTo('roomba.landing-page');
    }

    try {
      await this.roombaGraphql.getCompany();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('company does not exist yet. creating!', e);

      // TODO: This might be handled by billing? not sure
      await this.roombaGraphql.createCompany();
    }

    void this.router.replaceWith('roomba.list');
  }
}
