import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get } from '@ember/object';

export default class TouchlessWalkinsRoute extends Route {
  @service state;
  @service featureFlags;

  titleToken = 'Touchless walk-ins';

  async model() {
    const { currentLocation, vrSubscription } = this.state;
    const config = await get(currentLocation, 'config');

    return { config, location: currentLocation, subscription: vrSubscription };
  }
}
