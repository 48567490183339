import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class EntriesRedirectRoute extends Route {
  @service router;

  beforeModel(transition) {
    return this.router.transitionTo(`/visitors${transition.intent.url}`);
  }
}
