import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { and, reads, or } from 'macro-decorators';
import { getOwner } from '@ember/application';

/**
 * @param {Class<Location>}       currentLocation
 * @param {Class<Subscription>}   vrSubscription
 * @param {Boolean}               showTrialExpired
 */
export default class BannerManager extends Component {
  @service bannerManager;
  @service featureFlags;
  @service isOpen;
  @service metrics;
  @service state;

  get growthBanner() {
    return this.bannerManager.growthBanner;
  }

  get growthBannerComponentName() {
    const growthBanner = this.growthBanner;
    if (growthBanner) {
      const bannerComponentName = `banners/${growthBanner.componentName}`;
      // Before we call the `component` helper, we want to verify that the given
      // component name is valid. This is accomplished by looking up the component
      // and checking whether it resolves to anything. However, we can't use
      // the object (or its `.class` property) as the argument to the `component`
      // helper, so if the name is valid we just pass it along.
      const growthBannerComponent = getOwner(this).factoryFor(`component:${bannerComponentName}`);
      if (growthBannerComponent) {
        return bannerComponentName;
      }
      this.metrics.trackEvent('Growth banner component missing', {
        component_name: growthBanner.componentName,
      });
    }
    return null;
  }

  @reads('bannerManager.sorted') banners;
  @reads('bannerManager.metaFor') metaFor;
  @and('args.currentLocation', 'args.currentLocation.disabled') isLocationDisabled;
  @and('args.currentLocation', 'args.vrSubscription.delinquent') isCompanyDelinquent;

  // This feature flag provides customers with longer
  // payment cycles a better UX (https://envoycom.atlassian.net/browse/VR-7979)
  @or('featureFlags.hideDelinquencyMessage', 'state.billingCompany.isAchTransfer') hideDelinquencyMessage;

  @action
  async dismissGrowthBanner() {
    if (!this.growthBanner) return;

    try {
      await this.growthBanner.dismiss();
    } finally {
      this.bannerManager.growthBanner = null;
    }
  }
}
