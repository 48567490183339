import Ember from 'ember';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce, cancel, later } from '@ember/runloop';
import { toCoords, toLatLngArray, toLatLngObject } from 'garaje/helpers/leaflet-coords';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import leaflet from 'leaflet';
import setupMarkerExtensions from 'garaje/utils/leaflet-marker-extensions';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import zft from 'garaje/utils/zero-for-tests';
import { isEmpty } from '@ember/utils';

setupMarkerExtensions();

/**
 * Attributes
 *
 * @param {String}          imageUrl                  Required. The url of the map image
 * @param {Boolean}         isReady                   Required. Set to true once the image is loaded
 * @param {Function}        toggleIsReady             Required. Set isReady once map image is loaded
 * @param {Number}          mapWidth                  Required. Map image width
 * @param {Number}          mapHeight                 Required. Map image width
 * @param {Function}        onMarkerClick             Required. Action to take when a marker is clicked. The associated desk is passed as a param
 * @param {Function}        onMapClick                Optional. Action to take when the map is clicked. Take two parameters. latlng: a latlng object, hasOpenPopup: boolean that is true if a popup is open/permanent
 * @param {Boolean}         canDragMarkers            Optional. If true markers will be draggable. Used for changing desk location
 * @param {Function}        onMarkerUpdate            Optional. Callback when a desk's position is modified
 * @param {Boolean}         showPopupOnMarkerAdd      Optional. Show the popup automatically when a marker is added
 * @param {Boolean}         isSnappingEnabled         Optional. Snaps desks when dragging a marker
 * @param {Array<Desk>}     placedDesks               Optional. Desks currently placed on the map (has coordinates). Should have attribute iconClassname and iconSize
 * @param {Class<Desk>}     activeDesk                Optional. The desk currently being placed/moved/highlighted
 * @param {Boolean}         showTooltipOnHover        Optional. Shows tooltips when markers are hovered
 * @param {Desk}            selectedDesk              Optional. Desk that is currently selected
 * @param {Boolean}         showDraggableTooltip      Optional. When true this param will show the draggable tooltip if there is an active desk with no popups open and the mouse is in the map
 *
 * Yielded block components
 *
 * floor-toggle: Component for adding a floor toggle in the map
 * draggable: Tooltip that will follow the cursor in the map
 * desks-dropdown: Component for desks dropdown. Used for viewing unplaced desks
 * tooltip: Tooltip to show when hovering or clicking a marker.
 * settings: Settings menu button
 */

export default class DesksMap extends Component {
  crs = leaflet.CRS.Simple;

  @service store;
  /**
   * @type {Number} Tracking position of the cursor
   */
  @tracked cursorX;
  @tracked cursorY;

  /**
   * @type {Number} Map container dimensions
   */
  @tracked containerWidth;
  @tracked containerHeight = 800; // initially, match up with `.leaflet-container` css

  /**
   * @type {Boolean} When a marker is hovered over
   */
  @tracked isInteraction = false;

  /**
   * @type {Boolean} Debounces the interaction when hovering off to prevent flickers
   */
  @tracked debouncedInteractionOff;

  /**
   * @type {Boolean} When a popup is visible
   */
  @tracked isPopupOpen = false;
  @tracked map;

  @tracked polylineX = [];
  @tracked polylineY = [];
  @tracked zoomDelta = 0.5;
  originalZoomValue = 0;
  snapDistanceWithZoom = 0;

  get isLandscape() {
    return this.args.mapWidth > this.args.mapHeight;
  }

  get mapDimensions() {
    return [this.args.mapWidth, this.args.mapHeight];
  }

  /**
   * The "draggable" is the floating div that follows the user's cursor when placing desks
   */
  get isDraggableVisible() {
    const { activeDesk, showDraggableTooltip } = this.args;
    return (
      activeDesk &&
      showDraggableTooltip &&
      !this.isInteraction &&
      !this.isPopupOpen &&
      !isEmpty(this.cursorX) &&
      !isEmpty(this.cursorY)
    );
  }

  @computed('isLandscape', 'containerHeight', 'containerWidth', 'args.{mapWidth,mapHeight}')
  get maxZoom() {
    if (this.isLandscape) {
      return Math.sqrt(this.containerHeight / this.args.mapHeight);
    }
    return Math.sqrt(this.containerWidth / this.args.mapWidth);
  }

  @computed('isLandscape', 'containerHeight', 'containerWidth', 'args.{mapWidth,mapHeight}')
  get minZoom() {
    const delta = 1.35; //this delta prevents images from getting cut off
    if (this.isLandscape) {
      return -Math.sqrt(this.args.mapWidth / this.containerWidth) * delta;
    }

    return -Math.sqrt(this.args.mapHeight / this.containerHeight) * delta;
  }

  get bounds() {
    const { mapWidth, mapHeight } = this.args;
    return new leaflet.LatLngBounds(
      leaflet.CRS.Simple.unproject({ x: 0, y: 0 }, 0),
      leaflet.CRS.Simple.unproject({ x: mapWidth, y: -mapHeight }, 0),
    );
  }

  @action
  onLoadImage() {
    // Without the timeout, when image is cached LeafletMap will render the markers before the image is fit to the bounds
    // When the image is fit the markers will be out of place. The timeout gives time for the image to be fit after loading
    later(
      this,
      () => {
        if (!Ember.testing) {
          this.map.fitBounds(this.bounds);
          this.originalZoomValue = this.map.getZoom();
        }
        this.args.toggleIsReady(true);
      },
      500,
    );
  }

  @action
  registerMap({ target: map }) {
    this.map = map;
  }

  @action
  onInsert() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  @action
  onDestroy() {
    window.removeEventListener('resize', this.handleResize);
    if (!this.args.preventDeskResetOnDestroy) {
      if (this.args.placedDesks) {
        this.args.placedDesks.forEach((desk) => {
          delete desk.iconHtml;
          delete desk.iconClassname;
          delete desk.iconSize;
        });
      }
    }
  }

  handleClickTask = task({ drop: true }, async ({ latlng }) => {
    await timeout(10); // Safari sends two click events due to a bug.  By having this timeout in a dropTask, we can discard one of those click events.  Otherwise, we would place two desks.
    const { onMapClick } = this.args;
    const hasOpenPopup = this.map.getPanes().popupPane.childElementCount;
    if (onMapClick) {
      onMapClick(latlng, hasOpenPopup);
    } else {
      if (!this.bounds.contains(latlng) && !!zft(1)) {
        // todo: this is temporary for helping while debugging. handle more gracefully
        alert('A desk cannot exist off the map.');
      }
    }
  });

  handleAddMarkerTask = task({ drop: true }, async (marker, ev) => {
    const { showPopupOnMarkerAdd } = this.args;
    this.resetCursor();
    if (showPopupOnMarkerAdd) {
      // This timeout allows the marker to render before showing the popup
      // otherwise a leaflet TypeError is thrown
      await timeout(zft(200));
      later(() => {
        ev.target.bounce();
      });
      marker.setIsPermanent(true);
      ev.target.openPopup();
    }
  });

  findCloseDesk(marker, axis, dragDesk) {
    const { placedDesks } = this.args;
    return placedDesks.find((placedDesk) => {
      const placedDeskLatLng = toLatLngObject(placedDesk, this.mapDimensions);
      return (
        placedDeskLatLng[axis] - this.snapDistanceWithZoom < marker[axis] &&
        marker[axis] < placedDeskLatLng[axis] + this.snapDistanceWithZoom &&
        placedDesk !== dragDesk
      );
    });
  }

  findAllAlignedDesks(desk, closeDesk, dimension) {
    const { placedDesks } = this.args;
    return placedDesks.filter((placedDesk) => placedDesk[dimension] == closeDesk[dimension] && desk !== placedDesk);
  }

  @action
  onDragStart() {
    const { mapWidth, mapHeight } = this.args;
    const zoomLevel = (this.map.getZoom() - this.originalZoomValue) / this.zoomDelta;
    const mapContainerHeight = 800;
    const mapContainerWidth = 958;
    const snapDistance = 10;
    const normalizedSnapValue = mapWidth > mapHeight ? mapWidth / mapContainerWidth : mapHeight / mapContainerHeight;
    const normalizedSnapDistance = normalizedSnapValue * snapDistance;
    this.snapDistanceWithZoom = normalizedSnapDistance * Math.pow(0.8, zoomLevel);
  }

  @action
  onDrag(desk, ev) {
    const { isSnappingEnabled } = this.args;
    if (isSnappingEnabled) {
      const markerLatLng = ev.target.getLatLng();
      const closeXDesk = this.findCloseDesk(markerLatLng, 'lng', desk);
      const closeYDesk = this.findCloseDesk(markerLatLng, 'lat', desk);

      let closeXDeskLatLng;
      let closeYDeskLatLng;
      let allXAlignedDesks;
      let allYAlignedDesks;
      if (closeXDesk) {
        closeXDeskLatLng = toLatLngObject(closeXDesk, this.mapDimensions);
        allXAlignedDesks = this.findAllAlignedDesks(desk, closeXDesk, 'xPos');
      }
      if (closeYDesk) {
        closeYDeskLatLng = toLatLngObject(closeYDesk, this.mapDimensions);
        allYAlignedDesks = this.findAllAlignedDesks(desk, closeYDesk, 'yPos');
      }

      if (closeXDesk) {
        if (closeYDesk) {
          ev.target.setLatLng({ lat: closeYDeskLatLng.lat, lng: closeXDeskLatLng.lng });
          this.polylineX = [[closeYDeskLatLng.lat, closeXDeskLatLng.lng]];
        } else {
          ev.target.setLatLng({ lat: markerLatLng.lat, lng: closeXDeskLatLng.lng });
          this.polylineX = [[markerLatLng.lat, closeXDeskLatLng.lng]];
        }
        allXAlignedDesks.forEach((alignedDesk) => {
          const alignedLatLng = toLatLngObject(alignedDesk, this.mapDimensions);
          this.polylineX.push([alignedLatLng.lat, alignedLatLng.lng]);
        });
      } else {
        this.polylineX = [];
      }

      if (closeYDesk) {
        if (closeXDesk) {
          ev.target.setLatLng({ lat: closeYDeskLatLng.lat, lng: closeXDeskLatLng.lng });
          this.polylineY = [[closeYDeskLatLng.lat, closeXDeskLatLng.lng]];
        } else {
          ev.target.setLatLng({ lat: closeYDeskLatLng.lat, lng: markerLatLng.lng });
          this.polylineY = [[closeYDeskLatLng.lat, markerLatLng.lng]];
        }
        allYAlignedDesks.forEach((alignedDesk) => {
          const alignedLatLng = toLatLngObject(alignedDesk, this.mapDimensions);
          this.polylineY.push([alignedLatLng.lat, alignedLatLng.lng]);
        });
      } else {
        this.polylineY = [];
      }
    }
  }

  @action
  onDragEnd(desk, { target }) {
    this.resetCursor();
    this.polylineX = [];
    this.polylineY = [];
    if (this.bounds.contains(target.getLatLng())) {
      this.args.onMarkerUpdate(desk, toCoords(target.getLatLng(), this.mapDimensions));
    } else {
      target.closePopup();
      target.setLatLng(toLatLngArray([desk, this.mapDimensions]));
      target.shake();
    }
  }

  @action
  handleResize() {
    const { clientWidth, clientHeight } = document.querySelector('#desks-map-wrapper');
    this.containerHeight = clientHeight;
    this.containerWidth = clientWidth;
  }

  @action
  handleMouseMove(ev) {
    const { offsetX, offsetY } = ev.originalEvent;

    if (!this.isInteraction) {
      this.cursorX = offsetX;
      this.cursorY = offsetY;
    }
  }

  @action
  togglePopupOpen() {
    this.resetCursor();
    this.isPopupOpen = true;
  }

  @action
  togglePopupClosed() {
    this.resetCursor();
    this.isPopupOpen = false;
  }

  @action
  toggleInteractionOn() {
    cancel(this.debouncedInteractionOff);
    this.isInteraction = true;
  }

  @action
  toggleInteractionOff() {
    this.resetCursor();
    this.debouncedInteractionOff = debounce(this, this.turnOffInteraction, 250);
  }

  @action
  turnOffInteraction() {
    this.isInteraction = false;
    this.resetCursor();
  }

  /**
   * @returns {Void} resetting the cursor temporarily resets the draggableDiv
   */
  @action
  resetCursor() {
    this.cursorX = null;
    this.cursorY = null;
  }
}
