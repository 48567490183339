import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { all } from 'rsvp';
import { alias } from 'macro-decorators';
import { hourOptions, endTimeOptions, startTimeOptions } from 'garaje/utils/hour-options';
import { SLACK_V2_PLUGIN_KEY, MSTEAMS_V2_PLUGIN_KEY } from 'garaje/utils/enums';

export default class DesksGetStartedController extends Controller {
  @service router;
  @service flashMessages;
  @service state;
  @service store;

  @alias('state.desksSubscription') desksSubscription;
  @alias('state.currentLocation') currentLocation;

  @tracked deactivating = false;
  @tracked showDisableDayConfirmation = false;

  slackV2PluginKey = SLACK_V2_PLUGIN_KEY;
  msTeamsV2PluginKey = MSTEAMS_V2_PLUGIN_KEY;
  allowedPlugins = [this.slackV2PluginKey, this.msTeamsV2PluginKey];

  get currentLocationEnabledDesks() {
    return this.state.currentLocationEnabledDesks;
  }

  defaultSelectedTime = { label: '10:00 am', value: '10:00:00' };
  defaultPartialDayTime = { value: 60, label: '60 minutes' };

  get currentDeskLocation() {
    return this.store.peekRecord('desk-location', this.currentLocation.id);
  }

  get selectedDays() {
    return this.model.workplaceDays.filter((day) => day.active);
  }

  get hourOptions() {
    return hourOptions(30);
  }

  get dirtyWorkplaceDays() {
    return this.model.workplaceDays.filter((day) => day.hasDirtyAttributes);
  }

  @action
  startHourOptions(day) {
    return startTimeOptions(day.endTime, 15, true);
  }

  @action
  endHourOptions(day) {
    return endTimeOptions(day.startTime, 15, true);
  }

  get partialDayOptions() {
    const options = [];
    for (let minutes = 15; minutes <= 120; minutes += 15) {
      options.push({ value: minutes, label: `${minutes} minutes` });
    }
    return options;
  }

  @action
  selectDay(day) {
    day.active = !day.active;
  }

  @action
  deskBookingHoursChange(day, label, selected) {
    set(day, label, selected.value);
  }

  @action
  applyBookingHoursToAll() {
    const [dayApplied] = this.selectedDays;
    this.selectedDays.slice(1, this.selectedDays.length).forEach((day) => {
      day.startTime = dayApplied.startTime;
      day.endTime = dayApplied.endTime;
    });
  }

  @action
  showDisableDaysModal() {
    const isDayDisabled = this.dirtyWorkplaceDays.find(
      (dirtyDay) => dirtyDay.changedAttributes().active && !dirtyDay.changedAttributes().active[1],
    );
    if (isDayDisabled) {
      this.showDisableDayConfirmation = true;
    } else {
      this.saveWorkplaceDaysTask.perform();
    }
  }

  /**
   * Activates Desks at location if licenses available or on trial
   *
   * @param {Event} evt the DOM event
   */
  @dropTask
  *toggleDesksActiveTask(evt) {
    if (typeof evt?.preventDefault === 'function') {
      evt.preventDefault();
    }
    const { currentLocationEnabledDesks } = this;

    if (currentLocationEnabledDesks.active) {
      currentLocationEnabledDesks.active = false;
      yield currentLocationEnabledDesks.save();
    } else {
      currentLocationEnabledDesks.active = true;
      currentLocationEnabledDesks.save();
    }
    this.deactivating = false;
    // Reload state service with the new subscription added
    yield this.state.initSubscriptionStateTask.perform();

    this.router.transitionTo('desks.landing-page');
  }

  @dropTask
  *deskCheckInToggleTask(value) {
    if (value) {
      set(this.currentLocationEnabledDesks, 'checkInRequiredBy', this.defaultSelectedTime.value);
      set(this.currentLocationEnabledDesks, 'checkInDuration', this.defaultPartialDayTime.value);
    } else {
      set(this.currentLocationEnabledDesks, 'checkInRequiredBy', null);
      set(this.currentLocationEnabledDesks, 'checkInDuration', null);
    }
    yield this.currentLocationEnabledDesks.save();
  }

  @dropTask
  *deskCheckInTimeChangeTask(time) {
    set(this.currentLocationEnabledDesks, 'checkInRequiredBy', time.value);
    yield this.currentLocationEnabledDesks.save();
  }

  @dropTask
  *deskCheckInDurationChangeTask(time) {
    set(this.currentLocationEnabledDesks, 'checkInDuration', time.value);
    yield this.currentLocationEnabledDesks.save();
  }

  @dropTask
  *toggleDeskAutoAssignDeskTask() {
    const { currentDeskLocation } = this;

    set(currentDeskLocation, 'autoAssignDesk', !currentDeskLocation.autoAssignDesk);
    try {
      yield currentDeskLocation.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      currentDeskLocation.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving desk auto-assignment.');
    }
  }

  @dropTask
  *saveWorkplaceDaysTask() {
    try {
      yield all(this.dirtyWorkplaceDays.invoke('save'));
      this.showDisableDayConfirmation = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error saving booking hours.');
    }
  }
}
