import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { PrinterConnectionType } from 'garaje/models/abstract/printer';

type ConnectionTypeSelectOption = {
  label: string;
  value: PrinterConnectionType;
};

const BLUETOOTH_CONNECTION_TYPE: ConnectionTypeSelectOption[] = [{ label: 'Bluetooth', value: 'bluetooth' }];

const CONNECTION_TYPES: ConnectionTypeSelectOption[] = [
  { label: 'Wi-Fi', value: 'ip' },
  { label: 'Ethernet', value: 'ip' },
];

interface PrinterConnectionTypeSelectComponentSignature {
  Args: {
    /**
     * set to true if the given printer support bluetooth
     */
    bluetoothEnabled: boolean;
    /**
     * selected connection type
     */
    connectionType: ConnectionTypeSelectOption;
    /**
     * action to change connection type
     */
    changeConnectionType: (connectionType: ConnectionTypeSelectOption) => void;
  };
}

export default class PrinterConnectionTypeSelectComponent extends Component<PrinterConnectionTypeSelectComponentSignature> {
  @tracked selectedConnectionType;

  elementId = guidFor(this);

  constructor(owner: unknown, args: PrinterConnectionTypeSelectComponentSignature['Args']) {
    super(owner, args);
    this.selectedConnectionType = this.args.connectionType;
  }

  get connectionTypes(): ConnectionTypeSelectOption[] {
    // do not include BT connection options unless @bluetoothEnabled is true
    if (this.args.bluetoothEnabled) {
      return CONNECTION_TYPES.concat(BLUETOOTH_CONNECTION_TYPE);
    }

    return CONNECTION_TYPES;
  }
}
