import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import type Model from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { task, waitForProperty } from 'ember-concurrency';
import config from 'garaje/config/environment';
import { OauthProvider } from 'garaje/graphql/generated/roomba-types';
import type { Connection } from 'garaje/graphql/generated/roomba-types';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import type { ModelFrom } from 'garaje/utils/type-utils';
import { localCopy } from 'tracked-toolbox';

import type RoombaSettingsIndexRoute from './route';

export default class RoombaSettingsIndexController extends Controller {
  @service declare featureConfig: FeatureConfigService;
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare state: StateService;

  declare model: ModelFrom<RoombaSettingsIndexRoute>;

  @localCopy('model.company.settingsAccessCode') declare settingsAccessCode: string;
  @tracked selectedDeleteConnection: Connection | null = null;
  @tracked deleteConnectionShouldRemoveRooms = false;

  get googleConnections(): typeof this.model.company.connections {
    return this.model.company.connections.filter((connection) => connection.provider === OauthProvider.Google);
  }

  get oauthProviders(): typeof OauthProvider {
    return OauthProvider;
  }

  get roomNotificationSettingsActive(): boolean {
    return this.featureConfig.isEnabled('roomMeetings.notifications');
  }

  get hasLinkedLocation(): typeof this.model.company.locations {
    return this.model.company.locations?.filter((loc) => loc.id === this.state.currentLocation?.id);
  }

  get canPairDevices(): boolean {
    return (
      this.model.company.connections.length > 0 &&
      this.model.company.connections.some((connection) => connection.roomsCount != null && connection.roomsCount > 0)
    );
  }

  get shouldShowLinkLocationComponent(): boolean {
    return config.environment === 'development';
  }

  refreshRoomList = task({ drop: true }, async () => {
    try {
      this.roombaMetrics.trackEvent('calendar_settings_refresh_calendars_clicked', {
        locationId: this.state.currentLocation?.id,
      });

      const { errors } = await this.roombaGraphql.synchronizeCompanyRoomList();
      if (errors.length === 0) {
        this.flashMessages.showAndHideFlash('success', 'Calendars synced!');
      } else {
        this.flashMessages.showAndHideFlash('warning', 'Encountered some errors while syncing calendars.');
        console.error('refreshRoomList errors:', errors); // eslint-disable-line no-console
      }
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Error syncing calendars.');
    }

    this.selectedDeleteConnection = null;
    this.deleteConnectionShouldRemoveRooms = false;
  });

  deleteConnection = task({ drop: true }, async () => {
    try {
      this.roombaMetrics.trackEvent('calendar_settings_remove_calendar_clicked', {
        locationId: this.state.currentLocation?.id,
      });

      if (this.selectedDeleteConnection != null) {
        await this.roombaGraphql.unlinkConnection({
          connectionId: this.selectedDeleteConnection.id,
          shouldDeleteAssociatedRooms: this.deleteConnectionShouldRemoveRooms,
        });
        this.flashMessages.showAndHideFlash('success', 'Calendar removed!');
      } else {
        this.flashMessages.showAndHideFlash('error', 'Error removing calendar account.');
      }
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Error removing calendar account.');
    }

    this.selectedDeleteConnection = null;
    this.deleteConnectionShouldRemoveRooms = false;
  });

  saveSettingAccessCode = task({ drop: true }, async (code: string) => {
    if (code.length < 4) {
      return;
    }
    try {
      const result = await this.roombaGraphql.updateCompanyConfig({
        settingsAccessCode: code,
      });
      this.settingsAccessCode = result.settingsAccessCode;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  onBlurSettingAccessCode = task({ drop: true }, async () => {
    await waitForProperty(this, 'saveSettingAccessCode.isIdle', () => true);
    const { company } = this.model;
    this.settingsAccessCode = company.settingsAccessCode;
  });

  handleLinkLocationTask = task({ drop: true }, async () => {
    const { currentLocation, company } = this.model;

    if (!currentLocation) {
      return;
    }

    try {
      await this.roombaGraphql.createLocation({
        id: currentLocation.id,
        // TODO remove this and change the type of currentLocation
        name: (currentLocation as Model & { name: string }).name,
        companyId: company.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Linked!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  });

  // Only numbers for settings access code
  @action
  validateSettingAccessCode({ target }: { target: HTMLInputElement }): void {
    target.value = target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
  }
}
