import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type DraftModel from 'garaje/models/draft';
import type StateService from 'garaje/services/state';

import type MapsDraftsController from './controller';

export interface MapsDraftsRouteModel {
  originalAreaMap: AreaMapModel | undefined;
  drafts: DraftModel[];
}

export default class MapsDraftsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (this.abilities.cannot('visit drafts for maps') || this.abilities.cannot('edit maps')) {
      void this.router.transitionTo('unauthorized');
    }
  }

  async model(): Promise<MapsDraftsRouteModel> {
    const areaMaps = await this.store.query('area-map', {
      filter: {
        location: this.state.currentLocation?.id,
        'include-draft-area-maps': true,
      },
      include: 'drafts',
    });

    const originalAreaMap = areaMaps.find((areaMap) => !areaMap.hasMany('drafts').ids().length);
    const draftAreaMapIds = areaMaps
      .filter((areaMap) => areaMap.hasMany('drafts').ids().length)
      .map((areaMap) => areaMap.id);
    const drafts = this.store
      .peekAll('draft')
      .filter((draft) => draftAreaMapIds.includes(draft.belongsTo('draftAreaMap').id()));

    return {
      originalAreaMap,
      drafts,
    };
  }

  setupController(controller: MapsDraftsController, model: MapsDraftsRouteModel, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.drafts = model.drafts;
  }
}
