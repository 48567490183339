import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Model<Flow>}            flow
 * @param {Function}               onChange
 * @param {Function}               searchUsersTask
 * @param {Boolean}                isDisabled
 */
export default class VisitorDocumentContacts extends Component {
  @service flashMessages;

  @tracked hasChanged = false;

  @action
  updateContacts(contacts) {
    this.hasChanged = true;
    this.args.onChange(contacts);
  }

  @dropTask
  *saveContactsTask() {
    try {
      yield this.args.flow.save();
      this.hasChanged = false;
      this.flashMessages.showAndHideFlash('success', 'Contacts saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
