import Service from '@ember/service';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

const BASE_URL = config.growthApiHost;

export default class GrowthService extends Service {
  @service ajax;
  @service metrics;

  post = task(async (headers, requestUrl, data) => {
    if (!BASE_URL) {
      return;
    }

    const postUrl = urlBuilder.growth.getGrowthServiceUrl() + requestUrl;

    const settings = {
      postUrl,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        data: data,
      }),
    };

    if (headers != null) settings.headers = headers;

    try {
      return await this.ajax.request(postUrl, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('Growth Service Post Request Failed', {
        type: 'error',
        message_title: error_message,
      });
    }
  });

  get = task({ drop: true }, async (requestUrl, data) => {
    if (!BASE_URL) {
      return;
    }

    const getUrl = urlBuilder.growth.getGrowthServiceUrl() + requestUrl;

    const settings = {
      getUrl,
      type: 'GET',
      contentType: 'application/json',
      data: data,
    };

    try {
      return await this.ajax.request(getUrl, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('Growth Service Get Request Failed', {
        type: 'error',
        message_title: error_message,
      });
    }
  });

  delete = task(async (requestUrl) => {
    if (!BASE_URL) {
      return;
    }

    const deleteUrl = urlBuilder.growth.getGrowthServiceUrl() + requestUrl;

    const settings = {
      deleteUrl,
      type: 'DELETE',
    };

    try {
      return await this.ajax.request(deleteUrl, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('Growth Service Delete Request Failed', {
        type: 'error',
        message_title: error_message,
      });
    }
  });
}
