import { dependentKeyCompat } from '@ember/object/compat';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import type AssignmentModel from 'garaje/models/assignment';
import type CompanyModel from 'garaje/models/company';
import type CompanyRoleModel from 'garaje/models/company-role';
import type EmployeeLocationModel from 'garaje/models/employee-location';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type LocationRoleModel from 'garaje/models/location-role';
import type RecipientAliasModel from 'garaje/models/recipient-alias';
import type UserModel from 'garaje/models/user';
import type AjaxService from 'garaje/services/ajax';
import urlBuilder from 'garaje/utils/url-builder';
import type { SinglePayload } from 'jsonapi/response';

export enum LocationType {
  REMOTE = 'remote',
  ONSITE = 'onsite',
}

class EmployeeModel extends Model {
  @service declare ajax: AjaxService;
  @service declare store: StoreService;

  @hasMany('assignment', { async: true }) declare assignments: AsyncHasMany<AssignmentModel>;
  @hasMany('employee', { async: true, inverse: null }) declare assistants: AsyncHasMany<EmployeeModel>;
  @hasMany('employee', { async: true, inverse: null }) declare bosses: AsyncHasMany<EmployeeModel>;
  @belongsTo('employee', { async: true, inverse: null }) declare manager: AsyncBelongsTo<EmployeeModel> | EmployeeModel;
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @hasMany('company-roles', { async: true }) declare companyRoles: AsyncHasMany<CompanyRoleModel>;
  @hasMany('invite', { async: true }) declare invites: AsyncHasMany<InviteModel>;
  @hasMany('locations', { async: true }) declare locations: AsyncHasMany<LocationModel>;
  @hasMany('employee-locations', { async: true }) declare employeeLocations: AsyncHasMany<EmployeeLocationModel>;
  @hasMany('location-roles', { async: true }) declare locationRoles: AsyncHasMany<LocationRoleModel>;
  /**
   * @readonly
   */
  @belongsTo('user', { async: true, inverse: null }) declare user: AsyncBelongsTo<UserModel> | UserModel;
  @hasMany('recipient-aliases', { async: true }) declare recipientAliases: AsyncHasMany<RecipientAliasModel>;

  @attr('boolean') declare ccEmployee: boolean;
  @attr('date') declare createdAt: Date;
  @attr('boolean') declare deleted: boolean;
  @attr('boolean') declare doNotSync: boolean;
  @attr('string') declare email: string;
  @attr('string') declare name: string;
  @attr('string') declare phoneNumber: string;
  /**
   * @readonly
   */
  @attr('string') declare profilePictureUrl: string;
  @attr('boolean') declare hideFromHostList: boolean;
  /**
   * @readonly
   */
  @attr('boolean') declare hideFromEmployeeSchedule: boolean;
  @attr('boolean', { defaultValue: false }) declare manuallyAdded: boolean;
  @attr('string') declare department: string;
  @attr('number') declare defaultLocationId: number;
  @attr('string') declare locationType: LocationType | null;
  @attr('string') declare title: string;

  css = '';

  get fullName(): string {
    return this.name;
  }

  get isdeleted(): boolean {
    return this.deleted;
  }

  @dependentKeyCompat
  get firstCharacter(): string {
    return this.name.charAt(0).toUpperCase();
  }

  async setRole(roleName: string, location: LocationModel): Promise<void> {
    const user = (await this.user) || (await this.createUser());
    void user.setRole(roleName, location);
  }

  async createUser(): Promise<UserModel> {
    const url = urlBuilder.v3.employees.inviteUser(this.id);
    const options = {
      headers: {
        Accept: 'application/vnd.api+json',
      },
    };
    const payload: SinglePayload<UserModel> = await this.ajax.post(url, options);
    const serializer = this.store.serializerFor('user');
    // @ts-ignore serializer function not used with all required parameters
    const normalized = serializer.normalizeCreateRecordResponse(this.store, 'user', payload);
    const userRecord = <UserModel>this.store.push(normalized);

    this.user = userRecord;

    return userRecord;
  }

  async isEmployedAtLocation(locationId: string): Promise<boolean> {
    const employeeLocations = await this.employeeLocations;
    const employeeLocationIds = employeeLocations.map((el) => el.belongsTo('location').id());
    return employeeLocationIds.includes(locationId);
  }
}

export default EmployeeModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    employee: EmployeeModel;
  }
}
