import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

export default class ShareFeedbackModalComponent extends Component {
  @service declare statsig: StatsigService;

  @action openFeedbackEmail(): void {
    window.open('mailto:feedback@envoy.com', '_blank');
  }

  get feedbackLink(): string {
    this.statsig.logEvent('feedback_link_clicked');
    return 'https://www.userinterviews.com/opt-in/8tzfhfosHpGcspCYecLvWFd1';
  }

  @action onInsert(): void {
    this.statsig.logEvent('feedback_modal_opened');
  }

  @action logJoinNowClick(): void {
    this.statsig.logEvent('feedback_join_now_clicked');
  }
}
