import Model, { belongsTo, attr } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v2')
class EntryExportJobModel extends Model {
  // Relations
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  // Attributes
  @attr('number', { defaultValue: 0 }) declare progress: number;
  @attr('array') declare locationIds: LocationModel['id'][];
  @attr('date') declare endDate: Date;
  @attr('string') declare filter: string;
  @attr('string') declare query: string;
  @attr('string') declare sort: string;
  @attr('date') declare startDate: Date;
  @attr('string') declare status: string;
  @attr('number') declare totalCount: number;
  @attr('string') declare url: string;

  // Accepted formats: csv and xlsx
  @attr('string', { defaultValue: 'csv' }) declare exportFormat: string;

  get isDone(): boolean {
    return this.status === 'done';
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'entry-export-job': EntryExportJobModel;
  }
}

export default EntryExportJobModel;
