/* eslint-disable no-console */
import Service, { service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import type cookies from 'ember-simple-auth/session-stores/cookies';
import fetch from 'fetch';
import urlBuilder from 'garaje/utils/url-builder';

export default class SigmaUrlService extends Service {
  @service declare cookies: cookies;

  async getLocationSigmaUrl(companyId: string, locationId: string, workbookId: string): Promise<string> {
    const url = urlBuilder.analytics.getLocationSigmaUrl(companyId, locationId, workbookId);
    let response;
    try {
      const csrfToken = this.cookies.read('csrf_token') as string;
      const headers = {
        'X-CSRF-Token': csrfToken,
      };
      response = await Promise.race([
        fetch(url, {
          credentials: 'include',
          headers,
          method: 'GET',
        }),
        timeout(15000).then(() => {
          throw new Error('Timeout exceeded while fetching Sigma URL');
        }),
      ]);
      if (!response?.ok) {
        throw new Error(`Failed to fetch Sigma url ${response?.status}`);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`Error fetching Sigma url: ${e}`);
      throw new Error('Error fetching Sigma url');
    }
    const finalUrl = (await response.text()) || '';
    return finalUrl;
  }

  async getGlobalSigmaUrl(companyId: string, workbookId: string): Promise<string> {
    const url = urlBuilder.analytics.getCompanySigmaUrl(companyId, workbookId);
    let response;
    try {
      const csrfToken = this.cookies.read('csrf_token') as string;
      const headers = {
        'X-CSRF-Token': csrfToken,
      };
      response = await Promise.race([
        fetch(url, {
          credentials: 'include',
          headers,
          method: 'GET',
        }),
        timeout(15000).then(() => {
          throw new Error('Timeout exceeded while fetching Sigma URL');
        }),
      ]);
      if (!response.ok) {
        throw new Error(`Failed to fetch Sigma url ${response.status}`);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`Error fetching Sigma url: ${e}`);
      throw new Error('Error fetching Sigma url');
    }
    const finalUrl = (await response.text()) || '';
    return finalUrl;
  }

  async getCustomDashboardSigmaUrl(url: string): Promise<string> {
    let response;
    try {
      const csrfToken = this.cookies.read('csrf_token') as string;
      const headers = {
        'X-CSRF-Token': csrfToken,
      };
      response = await fetch(url, {
        credentials: 'include',
        headers,
        method: 'GET',
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch Sigma url ${response.status}`);
      }
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      console.log(`Error fetching Sigma url: ${e}`);
    }
    const finalUrl = (await (response as Response).text()) || '';
    return finalUrl;
  }
}
