import { hash } from 'rsvp';
import { set } from '@ember/object';
import normalizePlanId from 'garaje/utils/normalize-plan-id';
import planRank from 'garaje/utils/plan-rank';
import Route from '@ember/routing/route';
import { v1 as uuid } from 'uuid';
import { APP } from 'garaje/utils/enums';
import { service } from '@ember/service';

export default class BillingProductPlansDowngradeRoute extends Route {
  @service router;
  @service store;

  model({ plan: planName }) {
    const { subscription, activeUnits } = this.modelFor('billing.product-plans');
    const normalizedPlanId = normalizePlanId(planName, subscription.app);
    const plan = this.store.findRecord('plan', normalizedPlanId);

    return hash({ activeUnits, plan, planName, subscription, trackingId: uuid() });
  }

  afterModel({ plan, subscription }) {
    const isValidDowngrade = planRank(plan.id) < planRank(subscription.plan);
    if (!plan || !subscription.canDowngradeSelfServe || !isValidDowngrade || subscription.isDeliveries) {
      return this.router.transitionTo('billing.product-plans.index', subscription.appName);
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    set(
      controller,
      'showDowngradeFeatures',
      model.subscription.app === APP.VISITORS && !model.subscription.isEnterprisePlan,
    );
  }
}
