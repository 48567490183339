import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';

/**
 * @param {Class}     plan
 * @param {Boolean}   hasPaidSubscription
 */
export default class SelectPlanButton extends Component {
  @service locationBilling;
  @service router;

  @action
  click() {
    if (this.selectType === 'downgrade') {
      this.locationBilling.startDowngradeToSubscriptionPlan.perform(this.args.plan);
    } else {
      this.locationBilling.endSubscriptionPlanTrial(this.args.plan.app);
      this.locationBilling.setSubscriptionPlan(this.args.plan);
    }
  }

  get initialSubscriptionPlanRank() {
    const app = this.args.plan.app;
    if (!app) {
      return 0;
    }
    const name = capitalize(app);
    return this.locationBilling[`initial${name}SubscriptionPlan`]?.rank;
  }

  get selectType() {
    return this.args.plan.rank > this.initialSubscriptionPlanRank
      ? 'upgrade'
      : this.args.plan.rank < this.initialSubscriptionPlanRank
        ? 'downgrade'
        : 'continue';
  }

  get buttonClass() {
    return this.selectType !== 'downgrade' || !this.args.hasPaidSubscription ? 'btn--brand' : 'btn--outlineFill';
  }

  get buttonText() {
    const startsWith = !this.args.hasPaidSubscription
      ? `Select`
      : this.selectType === 'continue'
        ? 'Continue with'
        : capitalize(this.selectType) + ' to';
    return `${startsWith} ${this.args.plan.details.name}`;
  }
}
