import Service, { service } from '@ember/service';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const URL = config.growthApiHost + '/a/growth/api/v1/feature-impressions';

interface Impression {
  count: number;
}

export default class ImpressionsService extends Service {
  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare session: SessionService;
  @service declare state: StateService;

  postImpression = task(async (type: string): Promise<void> => {
    const adminRoles = [];
    const isGlobalAdmin = this.currentAdmin.isGlobalAdmin ? 'Global Admin' : null;
    const isLocationAdmin = this.currentAdmin.isLocationAdmin ? 'Location Admin' : null;

    if (isGlobalAdmin) {
      adminRoles.push(isGlobalAdmin);
    }

    if (isLocationAdmin) {
      adminRoles.push(isLocationAdmin);
    }

    const settings = {
      URL,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        data: {
          type: 'feature-impression',
          attributes: {
            'feature-name': type,
            'location-id': this.state.currentLocation?.id,
            'company-id': this.state.currentCompany?.id,
            'admin-roles': adminRoles,
            'user-id': this.state.currentUser?.id,
          },
        },
      }),
    };

    try {
      return await this.ajax.request<void>(URL, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('POST Feature Impressions failed', {
        type: 'error',
        message_title: error_message,
        feature_name: type,
        location_id: this.state.currentLocation?.id,
        company_id: this.state.currentCompany?.id,
      });
    }
  });

  getImpressions = task(async (type: string | string[]): Promise<Impression[] | undefined> => {
    if (!URL) {
      return;
    }

    const settings = {
      URL,
      type: 'GET',
      contentType: 'application/json',
      data: {
        filter: {
          feature_name: Array.isArray(type) ? type.join(',') : type,
          location_id: this.state.currentLocation?.id,
          company_id: this.state.currentCompany?.id,
        },
      },
    };

    try {
      return await this.ajax.request<Impression[]>(URL, settings);
    } catch (e) {
      const error_message = parseErrorForDisplay(e);
      this.metrics.trackEvent('GET Feature Impressions failed', {
        type: 'error',
        message_title: error_message,
        feature_name: Array.isArray(type) ? 'multiple' : type,
        location_id: this.state.currentLocation?.id,
        company_id: this.state.currentCompany?.id,
      });

      return;
    }
  });
}
