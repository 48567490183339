import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';

export default class SettingsWelcomeScreenReturningVisitorsRoute extends Route {
  @service state;

  titleToken = 'Returning visitors';

  async model() {
    const { currentLocation, vrSubscription } = this.state;
    const config = await get(currentLocation, 'config');
    const flows = await this.state.loadFlows({ reload: false });

    return { config, flows, subscription: vrSubscription };
  }
}
