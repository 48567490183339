import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StatsigService from 'garaje/services/statsig';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

import type { CommunicationsNewTemplateModel } from './route';

export default class CommunicationsNewTemplateController extends Controller {
  declare model: CommunicationsNewTemplateModel;

  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare statsig: StatsigService;

  saveTask = dropTask(async () => {
    try {
      const changeset = this.model.changeset;
      await changeset.validate();

      if (changeset.isInvalid) {
        return;
      }
      await changeset.save();
      this.metrics.trackEvent('Created announcement template', { announcementTemplateId: changeset.id });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      void this.router.transitionTo('communications.templates');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
