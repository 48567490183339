import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type StateService from 'garaje/services/state';

export default class GlobalAnalyticsOccupancySettingsIndexRoute extends Route {
  title = 'Occupancy Global Analytics Settings · Envoy';

  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;

  redirect(): void {
    if (this.abilities.cannot('visit occupancy-global-settings')) {
      this.router.transitionTo('unauthorized');
    } else {
      this.router.transitionTo('location-overview.occupancy.settings');
    }
  }
}
