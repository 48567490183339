import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { notEmpty } from '@ember/object/computed';

/**
 * @param {Function}    onClick
 * @param {Object}      response
 */
export default class PrinterError extends Component {
  @service abilities;
  @service metrics;

  // Array<{label: string, path: string|null}>
  @tracked flowLinks = null;
  @tracked printerErrors;

  printerPath = 'visitors.devices.printers';

  @notEmpty('flowLinks') hasBadgeNotEnabledError;
  @notEmpty('printerErrors') hasPrinterNotConnectedError;

  constructor() {
    super(...arguments);
    if (!this.args.response) {
      return;
    }
    const badgeErrors = this._badgeErrors(this.args.response);
    if (badgeErrors.length > 0) {
      const flowRelations = this._flowRelations(badgeErrors);
      let flowLinks = [];
      if (this.abilities.can('manage badge')) {
        flowLinks = this._flowLinksWithPath(flowRelations, this.args.response);
      } else if (this.abilities.can('print badge')) {
        flowLinks = this._flowLinksWithoutPath(flowRelations, this.args.response);
      }
      if (flowLinks.length > 2) {
        flowLinks[flowLinks.length - 2].separator = ', and';
      } else if (flowLinks.length === 2) {
        flowLinks[0].separator = ' and ';
      }
      flowLinks[flowLinks.length - 1].separator = '';
      this.flowLinks = flowLinks;
    }
    this.printerErrors = this._printerConnectionErrors(this.args.response);
  }

  _badgeErrors(response) {
    return response.data.filter((r) => {
      const hasError = r.attributes.result === 'error';
      const hasBadgeErrorStatus = r.attributes.statuses.includes('error_badge_not_enabled');
      return hasError && hasBadgeErrorStatus;
    });
  }

  _printerConnectionErrors(response) {
    return response.data.filter((r) => {
      const hasError = r.attributes.result === 'error';
      const hasPrinterConnectionErrorStatus = r.attributes.statuses.includes('error_printer_not_connected');
      return hasError && hasPrinterConnectionErrorStatus;
    });
  }

  _flowRelations(badgePrintJobErrors) {
    return badgePrintJobErrors.map((e) => e.relationships.flow.data).uniqBy('id');
  }

  _flowLinksWithPath(flowRelations, response) {
    return flowRelations.map((identityObject) => {
      const flow = this._findFlowInIncludes(identityObject, response);
      return {
        label: flow.attributes.name,
        separator: ',',
        path: 'visitors.settings.visitor-types.flow.badge',
        id: identityObject.id,
      };
    });
  }

  _flowLinksWithoutPath(flowRelations, response) {
    return flowRelations.map((identityObject) => {
      const flow = this._findFlowInIncludes(identityObject, response);
      return { label: flow.attributes.name, separator: ',', path: null, id: null };
    });
  }

  _findFlowInIncludes(identityObject, response) {
    return response.included.find((resource) => {
      return resource.type === 'flows' && resource.id === identityObject.id;
    });
  }

  @action
  handleClick({ target }) {
    this.args.onClick();
    this.metrics.trackEvent('Flash Message Link Clicked', { link_url: target.href });
  }
}
