import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type AccessEventModel from 'garaje/models/access-event';
import type { Field } from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import type WindowLocationService from 'garaje/services/window-location';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import zft from 'garaje/utils/zero-for-tests';
import { alias } from 'macro-decorators';

interface AccessEventsTableAccessEventFeedItemComponentArgs {
  fields: AccessEventsDashboardField[];
  accessEvent: AccessEventModel;
}

export interface AccessEventsDashboardField extends Field {
  sort?: string;
  show?: boolean;
}

/**
 * @param {String}                      context - The context in which the component is being used ("location" or "property"). Passed to EntryApprovalAbility#canReview.
 * @param {Class<AccessEvent>}          accessEvent
 * @param {Array}                       fieldOptions
 * @param {Boolean}                     hasPhoto
 * @param {object}                      location
 */
export default class AccessEventFeedItem extends Component<AccessEventsTableAccessEventFeedItemComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare ajax: AjaxService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare windowLocation: WindowLocationService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked itemFullNameWasTruncated = false;
  @tracked itemHostWasTruncated = false;

  tooltipDelay = zft(400);

  @alias('state.currentCompany') currentCompany!: StateService['currentCompany'];

  // colspan for the denied message is equal the the number of shown fieldOptions minus 1 for the name field, which is already in the row and is not included in the colspan
  get deniedColspan(): number {
    return this.args.fields.filter((field) => field.show).length - 1;
  }

  get isWorkplaceRoute(): boolean {
    return this.router.isActive('workplace');
  }

  get accessEvent(): AccessEventModel {
    return this.args.accessEvent;
  }

  @action
  trackOpenActionMenu(access_event_id: string): void {
    this.metrics.trackEvent('Access Event Overflow Opened', { access_event_id });
  }

  @action
  trackClickAccessEventDetail(access_event_id: string): void {
    this.metrics.trackEvent('Access Event Details Clicked', { access_event_id });
  }
}
