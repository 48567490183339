import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';

class FallbackContactModel extends Model {
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
}

export default FallbackContactModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'fallback-contact': FallbackContactModel;
  }
}
