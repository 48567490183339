import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type AbilitiesService from 'ember-can/services/abilities';
import type CompanyModel from 'garaje/models/company';
import type SubscriptionModel from 'garaje/models/subscription';
import type ZoneModel from 'garaje/models/zone';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type GlobalRoutingService from 'garaje/services/global-routing';
import type MetricsService from 'garaje/services/metrics';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import type ThinkificService from 'garaje/services/thinkific';
import { alias, or } from 'macro-decorators';

interface SideBarComponentSignature {
  Args: {
    addLocation: () => void;
    boss: () => void;
    clickLogo: () => void;
    company: CompanyModel;
    editProfile: () => void;
    isBoss: boolean;
    logout: () => void;
    vrSubscription?: SubscriptionModel;
    switchLocationFromMenu: () => void;
    switchZoneFromMenu: () => void;
    transitionToLocationOverview: () => void;
    isSidebarViewportHeight: boolean;
    currentZone: ZoneModel;
    zones: ZoneModel[];
  };
}

export default class SideBarComponent extends Component<SideBarComponentSignature> {
  @service declare abilities: AbilitiesService;
  @service declare currentLocation: CurrentLocationService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare router: RouterService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare state: StateService;
  @service declare thinkific: ThinkificService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare globalRouting: GlobalRoutingService;

  @alias('state.resellerPartner') resellerPartner!: StateService['resellerPartner'];
  @alias('state.hasChildren') hasChildren!: StateService['hasChildren'];
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isReceptionist', 'currentAdmin.isLocationAdmin') isAdmin!: boolean;

  get showGlobalMenu(): boolean {
    if (this.globalRouting.isNestedGlobalRoute()) return true;
    return this.router.currentRouteName.startsWith('location-overview');
  }

  get showMyLocations(): boolean {
    return this.router.currentRouteName.startsWith('my-locations');
  }

  get showConnectMenu(): boolean {
    return this.router.currentRouteName.startsWith('connect');
  }

  get isOnPropertyRoute(): boolean {
    return this.router.currentRouteName.startsWith('property');
  }

  get showPropertyMenu(): boolean {
    return this.isOnPropertyRoute || Boolean(this.args.currentZone);
  }

  get hasLocationSwitcher(): boolean {
    return (
      this.skinnyLocations.readableByCurrentAdmin?.length > 1 ||
      this.abilities.can('create locations') ||
      this.abilities.can('visit global-overview') ||
      this.args.zones?.length >= 1
    );
  }

  get ariaNavigationLabel(): string {
    if (this.showGlobalMenu) {
      return 'Global Overview Menu';
    } else if (this.showMyLocations) {
      return 'My Locations Menu';
    } else if (this.showConnectMenu) {
      return 'Connect Overview Menu';
    } else if (this.showPropertyMenu) {
      return 'Property Menu';
    } else {
      return 'Location Menu';
    }
  }

  get showActivityLogLink(): boolean {
    return (
      (this.args.vrSubscription?.isEnterprisePlan ?? false) &&
      this.abilities.can('visit changelog') &&
      !this.isOnPropertyRoute &&
      !this.showConnectMenu
    );
  }

  get showBillingLink(): boolean {
    return (
      this.abilities.can('visit billing') &&
      (!this.resellerPartner || this.featureFlags.isEnabled('growth_billing_page_access_override')) &&
      !this.isOnPropertyRoute &&
      !this.showConnectMenu
    );
  }

  get showBuyDevicesAndBadgesLink(): boolean {
    return this.abilities.can('visit device') && !this.isOnPropertyRoute && !this.showConnectMenu;
  }

  get showLearningAcademyLink(): boolean {
    return this.featureFlags.isEnabled('learning-academy-in-dashboard') && this.isAdmin;
  }

  @action
  trackClickEvent(trackingString: string): void {
    if (trackingString && trackingString.length > 0) {
      this.metrics.trackEvent(trackingString);
    }
  }

  @action
  trackActivityLog(): void {
    this.trackClickEvent('SideBar-ActivityLog');
  }

  @action
  async redirectToThinkific(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    this.trackClickEvent('SideBar-LearningCenter');
    const redirectUrl = await this.thinkific.getThinkificUrlTask.perform();
    window.location.href = redirectUrl!;
  }
}
