import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { scheduleOnce } from '@ember/runloop';
import { service } from '@ember/service';

export default class IntegrationsIndexRoute extends Route {
  @service router;

  titleToken = 'Integrations';

  model() {
    return hash({
      plugins: this.modelFor('integrations').plugins,
      pluginInstalls: this.modelFor('integrations').pluginInstalls,
      vrSubscription: this.modelFor('integrations').vrSubscription,
      deliveriesSubscription: this.modelFor('integrations').deliveriesSubscription,
      workplaceSubscription: this.modelFor('integrations').workplaceSubscription,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    const scrollTo = controller.scrollTo;
    if (scrollTo) {
      controller.scrollTo = null; // clear out the query param so it only scrolls once
      scheduleOnce('afterRender', this, this._scrollIntoView, scrollTo);
    }
  }

  _scrollIntoView(scrollTo) {
    const el = document.getElementById(scrollTo);
    el && el.scrollIntoView();
  }
}
