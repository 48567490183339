import { ReactNode } from 'react';

export const TakeoverTooltip: ReactNode = (
  <>
    Displays message on all operational Rooms tablets in this location.{' '}
    <a
      className="text-blue-500 underline transition-colors hover:text-blue-700"
      href="http://envoy.help/en/articles/10753625-emergency-notification-rooms-tablet-takeover"
      rel="noopener noreferrer"
      target="_blank"
    >
      Learn more
    </a>
    .
  </>
);
