import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import Changeset from 'ember-changeset';
import { createCapacityValidator } from 'garaje/utils/locations-capacity';
import moment from 'moment-timezone';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';
import { service } from '@ember/service';

import buildInviteValidations from 'garaje/validations/invite';
import lookupValidator from 'ember-changeset-validations';

export default class WorkplaceScheduledController extends Controller {
  @service router;
  @service featureFlags;
  @service locationFeatureFlags;
  @service store;

  @tracked changeset;

  setupChangeset() {
    const { store, model } = this;

    const validations = buildInviteValidations({
      validateCapacity: createCapacityValidator(store, model.location),
      checkEmailRequired:
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location'),
    });
    const validator = lookupValidator(validations);

    this.changeset = new Changeset(model.invite, validator, validations);
  }

  @action
  modelDidSave(date) {
    const queryParams = {};

    if (date) {
      queryParams.date = moment(date).format('YYYY-MM-DD');
    }

    this.router.transitionTo('workplace.entries', { queryParams });
  }

  get modalElement() {
    return document.getElementById('modal');
  }

  @dropTask
  showConfirmationModalTask = {
    *perform() {
      const deferred = defer();

      this.abort = () => deferred.reject();
      this.continue = () => deferred.resolve();

      return yield deferred.promise;
    },
  };
}
