import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaPickUpRoute extends Route {
  @service abilities;
  @service store;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('edit-pick-up-settings delivery-areas')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { deliveryArea } = this.modelFor('deliveries.settings.delivery-areas.delivery-area');

    const devices = this.store.query('delivery-device', {
      filter: {
        'delivery-area': deliveryArea.id,
      },
    });

    return hash({
      deliveryArea,
      devices,
    });
  }
}
