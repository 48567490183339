import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get, set } from '@ember/object';
import { task, dropTask } from 'ember-concurrency';
import Changeset from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { format } from 'date-fns';

import { validatePresence } from 'ember-changeset-validations/validators';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

const Validations = {
  apiKey: validatePresence({
    presence: true,
    message: "can't be blank",
  }),
};

/**
 * @param {Object}              oneLogin
 * @param {String}              integration
 * @param {Function}            openAction
 * @param {Function}            closeAction
 * @param {Function}            save
 * @param {Function}            integrationDisconnected
 */
export default class OneLoginConfigBox extends Component {
  @service currentAdmin;
  @service flashMessages;

  @tracked changeset;

  constructor() {
    super(...arguments);

    const validator = lookupValidator(Validations);
    this.changeset = new Changeset(this.args.oneLogin ?? {}, validator, Validations);
  }

  // Check if the record exist, we don't care about the state or if is new.
  get connected() {
    return !isEmpty(this.args.oneLogin);
  }

  get hasDirtyAttributes() {
    return this.changeset.isDirty || this.changeset.isNew;
  }

  get isOpen() {
    return this.args.integration === 'one-login';
  }

  get createdAt() {
    const createdAt = get(this.changeset, 'createdAt') ? new Date(get(this.changeset, 'createdAt')) : new Date();
    return format(createdAt, 'MMMM d, yyyy');
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  destroyRecord() {
    return this.args.oneLogin.destroyRecord();
  }

  @action
  close() {
    if (this.changeset && this.hasDirtyAttributes && !get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.changeset.rollback();
      } else {
        return;
      }
    }

    if (this.changeset && get(this.changeset, 'isNew')) {
      if (this.confirmAction()) {
        this.destroyRecord().then(() => {
          this.args.integrationDisconnected();
        });
      } else {
        return;
      }
    }

    this.args.closeAction();
  }

  confirmDisconnect() {
    return window.confirm('Are you sure you want to disconnect this integration?');
  }

  /**
     if callSave is true then the changeset is passed up to an action
     which will return a promise.

     The purpose of the attribute is to keep backwards compatibility
     with old functionality while allowing us to have an
     ember-concurrency task to wrap "save" calls in the changeset.
  **/
  @dropTask
  *saveTask(callSave = true) {
    const { changeset } = this;

    yield changeset.validate();

    if (!changeset.isValid) {
      return;
    }

    if (callSave) {
      yield this.args.save(changeset);
    } else {
      yield changeset.save();
    }
  }

  @dropTask
  *disableTask() {
    try {
      set(this, 'changeset.enabled', false);
      yield this.saveTask.perform(false);
      this.close();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this, 'changeset.enabled', true);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @dropTask
  *enableTask() {
    try {
      set(this, 'changeset.enabled', true);
      yield this.saveTask.perform(false);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      set(this, 'changeset.enabled', false);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }

  @action
  toggleEnable(isEnable) {
    if (isEnable) {
      this.enableTask.perform();
    } else {
      this.disableTask.perform();
    }
  }

  @task
  *disconnectTask() {
    if (!this.confirmDisconnect()) {
      return;
    }
    try {
      yield this.destroyRecord();
      this.close();
      this.args.integrationDisconnected(true);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  }
}
