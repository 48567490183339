import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class IntegrationsIndexRoute extends Route {
  @service router;
  @service integrationsManager;
  @service state;
  @service featureFlags;
  @service store;

  titleToken = 'Integrations';

  beforeModel() {
    if (!this.featureFlags.isEnabled('appStore')) {
      this.router.replaceWith('integrations');
    }
  }

  model() {
    const { currentCompany, vrSubscription, deliveriesSubscription, workplaceSubscription, currentLocation } =
      this.state;

    const integrationsHash = {
      integrations: this.integrationsManager.loadIntegration(currentCompany.id),
      vrSubscription: vrSubscription,
      deliveriesSubscription,
      workplaceSubscription,
      plugins: this.store.query('plugin', {
        filter: { location: currentLocation.id },
      }),
      pluginInstalls: this.store.query('plugin-install', {
        filter: { location: currentLocation.id },
        page: { limit: 100 },
      }),
    };

    return hash(integrationsHash);
  }
}
