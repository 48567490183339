import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { belongsTo, hasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type LocationModel from 'garaje/models/location';
import type VisitorGuideSectionModel from 'garaje/models/visitor-guide-section';
import type { SinglePayload, SingleResponse } from 'jsonapi/response';
import { cached } from 'tracked-toolbox';

class VisitorGuideModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @hasMany('visitor-guide-section', { async: true })
  declare visitorGuideSections: AsyncHasMany<VisitorGuideSectionModel>;

  get hasEdits(): boolean {
    return this.visitorGuideSections.any((s) => !!s.hasDirtyAttributes);
  }

  @cached
  get sortedVisitorGuideSections(): VisitorGuideSectionModel[] {
    const sectionsArray = this.visitorGuideSections.slice();
    return sectionsArray.sort((a, b) => a.position - b.position);
  }

  rollbackSections(): void {
    if (this.hasEdits) {
      this.visitorGuideSections.forEach((s) => s.rollbackAttributes());
    }
  }

  async getHTMLPreview(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'preview', data: this.generatePreviewPayload() });
  }

  generatePreviewPayload(): SinglePayload<VisitorGuideModel> {
    const payload = <SinglePayload<VisitorGuideModel>>this.serialize();
    if (!payload.data.relationships) payload.data.relationships = {};
    const sortedSections = this.sortedVisitorGuideSections;
    const sections = sortedSections.reduce<Array<SingleResponse<VisitorGuideSectionModel>>>((acc, section) => {
      if (!section.enabled) {
        return acc;
      }
      const { data } = <SingleResponse<VisitorGuideSectionModel>>section.serialize();
      data.attributes.title =
        section.iconType === 'visiting_company_name' ? `Visiting ${'%{company_name}'}` : section.title;
      data.attributes.iconType = section.iconType; // iconType is read only
      acc.push({ data });
      return acc;
    }, []);
    payload['data']['relationships']['visitor-guide-sections'] = sections;
    return payload;
  }
}

export default VisitorGuideModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'visitor-guide': VisitorGuideModel;
  }
}
