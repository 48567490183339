import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { RECURRING_OPTIONS } from 'garaje/pods/components/invites/select-recurring-invites/component';

const OPTIONS = { ...RECURRING_OPTIONS, ALL: undefined };

/**
 * @param {Function}            close              action to close the modal
 * @param {Function}            onSubmit       task performed when confirming
 * @param {Array<Invite>}       invites
 * @param {Boolean}             showCount
 */
export default class DeleteInvitesConfirmation extends Component {
  @service featureFlags;

  @tracked sendNotificationCallback;
  @tracked scope = OPTIONS.THIS;

  recurringOptions = OPTIONS;

  get totalInvites() {
    return this.args.invites.length;
  }

  get shouldSendNotificationCallback() {
    const isFromEmployeeScreening = this.args.invites.every((invite) => invite.isFromEmployeeScreening);
    const isFromWalkup = this.args.invites.every((invite) => invite.isFromWalkUp);

    return !isFromEmployeeScreening && !isFromWalkup;
  }

  @action
  onSelectScope(scope) {
    this.scope = scope;
  }

  @dropTask
  *onSubmitTask(e) {
    e.preventDefault();
    if (this.shouldSendNotificationCallback) {
      this.sendNotificationCallback = () => this.args.onSubmit(this.scope);
    } else {
      yield this.args.onSubmit(this.scope);
      this.args.close();
    }
  }
}
