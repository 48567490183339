import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, set } from '@ember/object';
import { service } from '@ember/service';
import { not, reads } from 'macro-decorators';
import { resetToDefault as resetNotificationSchedule } from 'garaje/utils/notification-schedule';
import { dropTask } from 'ember-concurrency';
import NotificationPeriod from 'garaje/models/notification-period';

/**
 * @param {Class}       notificationSchedule
 * @param {String}      timeZone
 */
export default class NotificationSchedule extends Component {
  @service flashMessages;

  @tracked isOpen = false;
  @tracked showUnsavedEditsWarning = false;

  @reads('args.notificationSchedule.hasDirtyAttributes') hasChanges;
  @not('hasChanges') unchanged;

  @dropTask
  *save() {
    try {
      yield this.args.notificationSchedule.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (_error) {
      const message = 'There was an error saving your changes. Please try again.';
      this.flashMessages.showAndHideFlash('error', message);
    }
  }

  @action
  onContinue() {
    if (this.hasChanges) {
      this.args.notificationSchedule.rollbackAttributes();
    }
    this.showUnsavedEditsWarning = false;
    this.isOpen = false;
  }

  @action
  cancel() {
    if (this.hasChanges) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.onContinue();
    }
  }

  @action
  edit() {
    this.isOpen = true;
  }

  @action
  resetNotificationSchedule() {
    resetNotificationSchedule(this.args.notificationSchedule);
  }

  @action
  updateNotificationPeriod(notificationPeriod, attrs) {
    const { notificationSchedule } = this.args;
    const notificationPeriods = [...notificationSchedule.notificationPeriods];
    const newPeriod = Object.freeze(new NotificationPeriod({ ...notificationPeriod, ...attrs }));
    notificationPeriods.splice(notificationPeriods.indexOf(notificationPeriod), 1, newPeriod);
    set(notificationSchedule, 'notificationPeriods', Object.freeze(notificationPeriods));
  }
}
