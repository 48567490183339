import { service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type { GetRoomQuery } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import moment from 'moment-timezone';

interface RoombaCalendarPermissionsArgs {
  room: GetRoomQuery['room'];
}

export default class RoombaCalendarPermissions extends Component<RoombaCalendarPermissionsArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare roombaGraphql: RoombaGraphqlService;

  get lastCheckedAt(): string | undefined {
    const timestamp = this.args.room.calendarPermissionsStatus?.permissionsLastCheckedAt;
    return timestamp ? moment(timestamp).format('h:mm a z on MMMM D, YYYY') : undefined;
  }

  constructor(owner: unknown, args: RoombaCalendarPermissionsArgs) {
    super(owner, args);
    const room = this.args.room;
    if (room.calendarPermissionsStatus?.permissionsLastCheckedAt === null) {
      void this.recheckTask.perform();
    }
  }

  recheckTask = task({ drop: true }, async () => {
    try {
      await this.roombaGraphql.checkCalendarPermissions({ roomId: this.args.room.id });
    } catch (e) {
      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
    }
  });
}
