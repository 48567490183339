import { action } from '@ember/object';
import { throttle, later, scheduleOnce, cancel } from '@ember/runloop';
import { type EmberRunTimer } from '@ember/runloop/types';
import { htmlSafe } from '@ember/template';
import { type SafeString } from '@ember/template/-private/handlebars';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Ember from 'ember';
import { resolve } from 'rsvp';
import { localCopy } from 'tracked-toolbox';

const { escapeExpression } = (<typeof Handlebars>(<unknown>Ember.Handlebars)).Utils;

interface VirtualEachArgs {
  items: unknown[];
  height?: number;
  rowPadding?: number;
  itemHeight?: number;
  positionIndex: number;
  scrollTimeout?: number;
  onScrollBottomed?: (startAt: number, endAt: number) => unknown;
}

interface VirtualEachItem {
  raw: unknown;
  actualIndex: number;
  virtualIndex: number;
}

export default class VirtualEach extends Component<VirtualEachArgs> {
  isWebkit = /WebKit/.test(navigator && navigator.userAgent);
  declare element: HTMLDivElement;

  @localCopy('args.items') items!: VirtualEachArgs['items'];
  @localCopy('args.height', 200) height!: NonNullable<VirtualEachArgs['height']>;
  @localCopy('args.itemHeight', 20) itemHeight!: NonNullable<VirtualEachArgs['itemHeight']>;
  @localCopy('args.scrollTimeout', 30) scrollTimeout!: VirtualEachArgs['scrollTimeout'];
  @localCopy('args.rowPadding', 1) bufferSize!: NonNullable<VirtualEachArgs['rowPadding']>;
  @localCopy('args.positionIndex') positionIndex!: VirtualEachArgs['positionIndex'];

  @tracked scrolledByWheel = false;
  @tracked startAt = 0;
  @tracked totalHeight = 0;
  @tracked scrollThrottleTimeout?: EmberRunTimer;
  @tracked scrollBottomedTimeout?: EmberRunTimer;
  @tracked scheduledRender?: EmberRunTimer;

  @action
  onInsert(element: HTMLDivElement): void {
    this.element = element;

    this.#updateTotalHeight();
  }

  get style(): SafeString {
    return htmlSafe(`height: ${this.height}px;`);
  }

  get contentStyle(): SafeString {
    return htmlSafe(`height: ${escapeExpression(this.contentHeight.toString())}px; margin-top: ${this.marginTop}px;`);
  }

  get visibleItems(): VirtualEachItem[] {
    const { items, startAt, itemCount, bufferSize } = this;
    const itemsLength = items.length;
    const endAt = Math.min(itemsLength, startAt + itemCount);
    const { onScrollBottomed } = this.args;

    if (typeof onScrollBottomed === 'function' && startAt + itemCount - bufferSize >= itemsLength) {
      // eslint-disable-next-line ember/no-side-effects
      this.scrollBottomedTimeout = later(() => onScrollBottomed(startAt, endAt), 5);
    }

    return items.slice(startAt, endAt).map((item, index) => {
      return {
        raw: item,
        actualIndex: startAt + index,
        virtualIndex: index,
      };
    });
  }

  get itemCount(): number {
    const { bufferSize, height, itemHeight } = this;
    return Math.ceil(height / itemHeight) + bufferSize;
  }

  get marginTop(): number {
    const { bufferSize, itemHeight, totalHeight, startAt, itemCount: visibleItemCount } = this;
    const margin = startAt * itemHeight;
    const maxMargin = Math.max(0, totalHeight - (visibleItemCount - 1) * itemHeight + bufferSize * itemHeight);

    return Math.min(maxMargin, margin);
  }

  get contentHeight(): number {
    return this.totalHeight - this.marginTop;
  }

  @action
  calculateVisibleItems(positionIndex = NaN): void {
    const { startAt, itemHeight } = this;
    const scrolledAmount = this.element.scrollTop;
    const visibleStart = isNaN(positionIndex) ? Math.floor(scrolledAmount / itemHeight) : positionIndex;

    if (visibleStart !== startAt) {
      this.startAt = visibleStart;
    }
  }

  @action
  onUpdate(): void {
    this.scrollTo();
  }

  @action
  onUpdateItems(): void {
    this.#updateTotalHeight();
  }

  @action
  scrollTo(): void {
    const { bufferSize, positionIndex, itemHeight, totalHeight, itemCount, items, startAt } = this;
    const startingIndex = isNaN(positionIndex) ? startAt : Math.max(positionIndex, 0);
    const startingPadding = itemHeight * startingIndex;
    const maxVisibleItemTop = Math.max(0, items.length - itemCount + bufferSize);
    const maxPadding = Math.max(0, totalHeight - (itemCount - 1) * itemHeight + bufferSize * itemHeight);
    const sanitizedIndex = Math.min(startingIndex, maxVisibleItemTop);
    const sanitizedPadding = startingPadding > maxPadding ? maxPadding : startingPadding;

    const afterRenderFn = () => {
      this.calculateVisibleItems(sanitizedIndex);
      this.element.scrollTop = sanitizedPadding;
    };

    this.scheduledRender = scheduleOnce('afterRender', this, afterRenderFn);
  }

  @action
  onDestroy(): void {
    cancel(this.scheduledRender);
    cancel(this.scrollThrottleTimeout);
    cancel(this.scrollBottomedTimeout);
  }

  @action
  onScroll(e: Event): void {
    e.preventDefault();
    const { scrollTimeout, isWebkit, scrolledByWheel } = this;

    if (scrollTimeout && isWebkit && scrolledByWheel) {
      this.scrolledByWheel = false;
      this.scrollThrottleTimeout = throttle(() => {
        this.calculateVisibleItems();
      }, scrollTimeout);
      return;
    }

    this.calculateVisibleItems();
  }

  @action
  onWheel(): void {
    this.scrolledByWheel = true;
  }

  #updateTotalHeight() {
    void resolve(this.items).then((items) => {
      this.totalHeight = Math.max(items.length * this.itemHeight, 0);
    });
  }
}
