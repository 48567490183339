// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { isBlank, isEmpty } from '@ember/utils';
import { get, action } from '@ember/object';
import { service } from '@ember/service';
import { timeout, task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { arrayToSentence } from 'garaje/helpers/array-to-sentence';
import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

/**
 * @param {String}                      title Title of the form
 * @param {EmberObject}                 changeset
 * @param {Model<Subscription>}         vrSubscription
 * @param {Function}                    searchEmployees Action to search employees
 * @param {Function}                    addEmployee Action to add employee
 * @param {Function}                    addToLocation Action to add from other location to current location
 * @param {Task}                        afterAddEmployeeTask Task to perform after add employee
 */
export default class AddNewEmployeeForm extends Component {
  @service state;
  @service flashMessages;
  @service ajax;
  @service router;
  @service store;
  @service featureFlags;
  @service skinnyLocations;
  @service workplaceMetrics;
  @service statsig;

  @tracked doesEmployeeExist = false;
  @tracked employeeInCurrentLocation = false;
  @tracked employeeId = null;
  @tracked selectedEmployeeLocations = this.initialSelectedLocations;
  @tracked remainingEmployeeLocations = this.availableLocations;
  @tracked employeeLocationsError = false;

  defaultCountry = config.defaultCountry;

  @(employeesSearcherTask().restartable())
  searchAllEmployeesTask;

  _resetForSearch() {
    this.employeeId = null;
    this.doesEmployeeExist = false;
    this.employeeInCurrentLocation = false;
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get initialSelectedLocations() {
    const initialLocations = [];

    if (!this.isGlobalOverview) {
      const { currentLocation } = this.state;
      initialLocations.push({
        location: currentLocation.skinnyLocation,
        disabled: false,
        nameWithCompanyName: currentLocation.nameWithCompanyName,
      });
    }

    return initialLocations;
  }

  get availableLocations() {
    const exceptIds = [];
    const initialLocations = this.initialSelectedLocations;
    if (initialLocations.length > 0) {
      initialLocations.forEach((initialLoc) => {
        exceptIds.push(initialLoc.location.id);
      });
    }

    const availableLocations = [];

    this.skinnyLocations.manageableByCurrentAdmin.forEach((loc) => {
      if (exceptIds.length < 1 || !exceptIds.includes(loc.id)) {
        const disabled = !isEmpty(loc.disabledToEmployeesAt);
        let nameWithCompanyName = loc.nameWithCompanyName;
        if (disabled) {
          nameWithCompanyName += ' (Disabled)';
        }

        availableLocations.push({
          location: loc,
          disabled,
          nameWithCompanyName,
        });
      }
    });

    return this.sortLocationsByDisabled(availableLocations);
  }

  get canAddToAnotherLocation() {
    if (this.isGlobalOverview) return false;

    return this.skinnyLocations.hasMultipleLocations && !this.employeeInCurrentLocation;
  }

  @action
  onEmployeeLocationAdd(selectedLoc) {
    this.selectedEmployeeLocations = [...this.selectedEmployeeLocations, selectedLoc];

    if (this.selectedEmployeeLocations.length > 0) {
      this.employeeLocationsError = false;
    }

    this.remainingEmployeeLocations = this.remainingEmployeeLocations.filter(
      (employeeLoc) => employeeLoc.location.id !== selectedLoc.location.id,
    );
  }

  @action
  onEmployeeLocationDelete(locToDelete) {
    this.remainingEmployeeLocations = [...this.remainingEmployeeLocations, locToDelete];
    this.remainingEmployeeLocations = this.sortLocationsByDisabledAndName(this.remainingEmployeeLocations);

    this.selectedEmployeeLocations = this.selectedEmployeeLocations.filter(
      (employeeLoc) => employeeLoc.location.id !== locToDelete.location.id,
    );
  }

  @action
  searchAllEmployees(term) {
    return this.searchAllEmployeesTask.perform(term, {}, { withoutLocation: true });
  }

  @action
  addExistingEmployeeToLocation() {
    return this.addToLocation(this.employeeId);
  }

  searchEmployeesTask = task({ restartable: true }, async (term) => {
    if (isBlank(term) || term.length < 3) {
      return [];
    } else {
      await timeout(zft(250));
      const params = { filter: { query: term } };

      if (!this.isGlobalOverview) {
        params.filter.locations = this.state.currentLocation.id;
      }

      return await this.searchEmployees(params);
    }
  });

  saveTask = task({ drop: true }, async (changeset) => {
    this.workplaceMetrics.trackEvent('EMPLOYEE_DIRECTORY_NEW_EMPLOYEE_SAVE_BUTTON_CLICKED');
    await changeset.validate();

    const hasSelectedLocations = this.selectedEmployeeLocations.length > 0;

    if (!hasSelectedLocations) {
      this.employeeLocationsError = true;
      this.flashMessages.showFlash('error', 'Please add locations for this employee');
    } else if (!changeset.isValid) {
      const errorTypes = [];

      if (changeset.error?.name?.validation) {
        errorTypes.push('name');
      }
      if (changeset.error?.email?.validation) {
        errorTypes.push('properly formatted email');
      }
      if (changeset.error?.phoneNumber?.validation) {
        errorTypes.push('properly formatted phone number');
      }

      const error = `Please have a ${arrayToSentence(errorTypes)}`;
      this.flashMessages.showFlash('error', error);
    } else {
      try {
        if (this.isGlobalOverview && hasSelectedLocations) {
          changeset.locations = [];
        }

        if (!this.isGlobalOverview) {
          // set the current location as the default location
          this.changeset.defaultLocationId = parseInt(this.state.currentLocation.id) || null;
        }

        const savedEmployee = await this.addEmployee(changeset);

        if (hasSelectedLocations) {
          this.selectedEmployeeLocations.forEach(async (loc) => {
            await this.saveEmployeeLocation.perform(loc.location, savedEmployee).catch(throwUnlessTaskDidCancel);
          });
        }

        await this.afterAddEmployeeTask.perform(savedEmployee);
        this.flashMessages.showAndHideFlash('success', 'Saved employee!');
      } catch (e) {
        changeset.rollback();
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showAndHideFlash('error', errorText);
      }
    }
  });

  saveEmployeeLocation = task(async (location, employee) => {
    const employeeLocation = this.store.createRecord('employee-location', {
      location,
      employee,
      manuallyAdded: true,
    });
    await employeeLocation.save();
  });

  checkIfEmployeeExistsTask = task({ restartable: true }, async (email) => {
    if (isBlank(email)) {
      return;
    } else if (email.length < 3) {
      return;
    } else {
      await timeout(zft(250));
      this._resetForSearch();
      try {
        const { currentCompany, currentLocation } = this.state;
        const params = {
          filter: { email, deleted: false, company: currentCompany.id },
          include: 'employee-locations',
        };
        let result = await this.searchEmployees(params, 1);
        result = result.filterBy('email', email);
        const employee = get(result, 'firstObject');

        if (employee) {
          if ((await employee.employeeLocations).length > 0) {
            this.employeeId = employee.id;
            this.doesEmployeeExist = true;

            // Employee exists but may be on this current location
            if (await employee.isEmployedAtLocation(currentLocation.id)) {
              this.employeeInCurrentLocation = true;
            }
          } else {
            this.store.unloadRecord(employee);
          }
        }
      } catch (error) {
        // this endpoint returns 404 not found if it cannot find a match
        if (get(error, 'errors.firstObject.status') === '404') {
          this._resetForSearch();
        } else {
          const errorText = parseErrorForDisplay(error);
          this.flashMessages.showAndHideFlash('error', errorText);
        }
      }
    }
  });

  sortLocationsByDisabled(arrayToSort) {
    return arrayToSort.sort((a, b) => {
      if (a.disabled && b.disabled) {
        return 0;
      } else if (!a.disabled && b.disabled) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  sortLocationsByDisabledAndName(arrayToSort) {
    return arrayToSort.sort((a, b) => {
      if (!a.disabled && b.disabled) {
        return -1;
      }
      if (a.disabled && !b.disabled) {
        return 1;
      }
      if (a.nameWithCompanyName < b.nameWithCompanyName) {
        return -1;
      }
      if (a.nameWithCompanyName > b.nameWithCompanyName) {
        return 1;
      }
      return 0;
    });
  }
}
