import Model, { attr, belongsTo } from '@ember-data/model';
import type Agreeable from 'garaje/models/agreeable';
import type AgreementModel from 'garaje/models/agreement';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v1')
class AgreeableNdaModel extends Model {
  @belongsTo('agreeable', { async: false, polymorphic: true }) declare agreeable: Agreeable;
  @belongsTo('agreement', { async: false, inverse: null }) declare agreement: AgreementModel;

  @attr('boolean') declare agreementOptional: boolean;
  @attr('date') declare signedAt: Date;
}

export default AgreeableNdaModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'agreeable-nda': AgreeableNdaModel;
  }
}
