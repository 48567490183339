import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import ScimMapping from 'garaje/models/scim-mapping';
import type ScimPrimaryLocationConfigurationModel from 'garaje/models/scim-primary-location-configuration';
import type { SingleResponse } from 'jsonapi/response';
import { equal } from 'macro-decorators';

const OVERWRITABLE_SETTINGS = {
  accessTokenId: 'access token',
};

class ScimIntegrationModel extends Model {
  static OVERWRITABLE_SETTINGS = Object.freeze(OVERWRITABLE_SETTINGS);
  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('scim-primary-location-configuration', { async: true })
  declare scimPrimaryLocationConfiguration: AsyncBelongsTo<ScimPrimaryLocationConfigurationModel>;

  @attr('string') declare provider: string;
  @attr('string') declare accessToken: string;
  @attr('string') declare state: string;
  @attr('string') declare createdAt: string;
  @attr('string') declare updatedAt: string;
  @attr('string') declare lastSyncAt: string;
  // @ts-ignore - using custom options
  @attr('immutable-array', {
    deserializeItem: (item: object) => new ScimMapping(item),
    serializeItem: (item: ScimMapping) => item.serialize(),
    defaultValue: null,
  })
  declare locationMapping: ScimMapping[];

  @equal('state', 'pending') declare isPending: boolean;
  @equal('state', 'enabled') declare isEnabled: boolean;

  async regenerateToken(): Promise<SingleResponse<ScimIntegrationModel>> {
    return <SingleResponse<ScimIntegrationModel>>await apiAction(this, { method: 'POST', path: 'regenerate-token' });
  }
}
export default ScimIntegrationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-integration': ScimIntegrationModel;
  }
}
