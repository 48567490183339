import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import type ChameleonService from 'garaje/services/chameleon';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';

export default class VirtualFrontDeskLandingPageController extends Controller {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: StoreService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare statsig: StatsigService;
  @service declare chameleon: ChameleonService;

  queryParams = ['ref'];
  ref: string | null = null;

  @action
  redirectToGlobalOverview(): void {
    void this.router.transitionTo('location-overview.virtual-front-desk.locations');
  }

  get isOnGlobalOverview(): boolean {
    return this.router.isActive('location-overview');
  }

  get canAccessVirtualFrontDesk(): boolean | undefined {
    return this.state.features?.canAccessVirtualFrontDesk;
  }

  get isCancelled(): boolean | undefined {
    return this.state.vfdSubscription?.cancelled;
  }

  get isOnExpiredTrial(): boolean | undefined {
    return this.state.vfdSubscription?.onExpiredTrial;
  }

  get isGlobalAdmin(): boolean {
    return this.currentAdmin.isGlobalAdmin;
  }

  get learnMoreLink(): string {
    return this.isGlobalAdmin && this.canAccessVirtualFrontDesk
      ? 'https://envoy.help/en/articles/9176168-virtual-front-desk'
      : 'https://envoy.com/products/virtual-front-desk';
  }
}
