import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ScheduleIndexRoute extends Route {
  @service state;
  @service store;
  @service abilities;

  async model() {
    const result = {
      covid19VaccineDocumentTrackingEnabled: false,
      covid19VaccineDocumentLink: null,
      emergencyNotificationConfiguration: null,
      userHasPhoneNumber: false,
    };
    if (this.state.currentLocationEnabledDesks?.id) {
      await this.store.findRecord('desk-location', this.state.currentLocationEnabledDesks.id, {
        reload: true,
        include: 'workplace-days',
      });
      const workplaceDays = this.store
        .peekAll('workplaceDay')
        .filter((day) => {
          return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks.id;
        })
        .sort((a, b) => a.id - b.id);

      result.workplaceDays = workplaceDays;
    }

    if (this.abilities.can('see-features emergency-notification')) {
      result.emergencyNotificationConfiguration =
        await this.state.currentLocation.getEmergencyNotificationConfiguration();

      const userPhoneNumber = !!this.state.currentUser.phoneNumber;

      if (result.emergencyNotificationConfiguration.passivePhoneNumberCollectionEnabledAt) {
        if (userPhoneNumber) {
          result.userHasPhoneNumber = true;
        } else {
          const employee = await this.store.query('employee', {
            filter: {
              'email-in': this.state.currentUser.email,
            },
          }).firstObject;

          result.userHasPhoneNumber = !!employee?.phoneNumber;
        }
      }
    }

    const userDocumentTemplates = await this.store.findAll('user-document-template');
    const userDocumentTemplate = userDocumentTemplates.findBy('identifier', 'covid-19-vaccine-card');

    if (userDocumentTemplate?.id) {
      const userDocumentTemplateConfigurations = await this.store.query('user-document-template-configuration', {
        filter: {
          'user-document-template-id': userDocumentTemplate.id,
          'location-id': this.state.currentLocation.id,
        },
      });
      const userDocumentTemplateConfiguration = userDocumentTemplateConfigurations.firstObject;
      result.covid19VaccineDocumentTrackingEnabled = !!userDocumentTemplateConfiguration?.active;

      const userDocuments = await this.store.query('user-document', {
        include: 'user-document-links',
        filter: {
          user: this.state.currentUser.id,
          'user-document-template-identifier': userDocumentTemplate.identifier,
        },
      });
      result.covid19VaccineDocumentLink = userDocuments.firstObject?.userDocumentLinks?.firstObject;
    }

    return result;
  }

  setupController(controller) {
    super.setupController(...arguments);

    const { scheduleEligibilityOffset } = controller.state.currentLocation;
    const offset = controller.storedScheduleEligibilityOffset;

    if (offset !== scheduleEligibilityOffset) {
      if (scheduleEligibilityOffset == null) {
        controller.closeScheduleEligibilityOffsetNotification();
      } else {
        controller.removeStoredScheduleEligibilityOffset();
      }
    }

    controller.registrations.dumpRecords();
    controller.registrations.loadMoreTask.perform(controller.daysToLoad);
  }
}
