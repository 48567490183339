import { service } from '@ember/service';
import Controller from '@ember/controller';
import { action, get } from '@ember/object';

export default class DeliveriesLogDeliverySignOutPhotoController extends Controller {
  @service router;

  @action
  close() {
    const delivery = get(this.model, 'delivery');
    this.router.transitionTo('deliveries.log.delivery', get(delivery, 'id'));
  }
}
