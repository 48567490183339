import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import type VisitorsOnboardingService from 'garaje/services/visitors-onboarding';

import type AnalyticsVisitorsController from './controller';

class AnalyticsVisitorsIndexRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare visitorsOnboarding: VisitorsOnboardingService;

  titleToken = 'Analytics';

  setupController(controller: AnalyticsVisitorsController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  beforeModel(): void | Promise<unknown> {
    const { vrSubscription } = this.state;
    if (this.abilities.cannot('visit-visitors analytics')) {
      if (vrSubscription?.canAccessAnalytics) {
        this.router.transitionTo('unauthorized');
      } else {
        this.flashMessages.showFlash('error', 'Analytics is not available on this plan');
        this.router.transitionTo('dashboard');
      }
    }
  }

  async model(): Promise<void> {
    await this.visitorsOnboarding.loadSteps();
  }

  redirect(_model: unknown, transition: Transition): void {
    this.visitorsOnboarding.gateRoute(this, transition);
  }
}

export default AnalyticsVisitorsIndexRoute;
