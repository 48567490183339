import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { alias } from 'macro-decorators';

/**
 * @param {Model<Changelog>}            changelog
 */
export default class LogBox extends Component {
  @service state;

  @tracked showingLocations;
  @tracked parent;
  @tracked locations;

  @alias('state.currentCompany') currentCompany;

  @action
  onInsert() {
    this.loadParentLocation();
    this.loadLocations();
  }

  async loadParentLocation() {
    const parent = await get(this.args.changelog, 'parentLocation');
    this.parent = parent;
  }

  async loadLocations() {
    const locations = await get(this.args.changelog, 'locations');
    this.locations = locations;
  }

  get monoDifference() {
    const useMonoFont = ['logo', 'welcomeImage', 'color', 'welcomeBackgroundColor'];
    const items = get(this.args.changelog, 'items');
    const firstItem = get(items, 'firstObject');
    return firstItem && useMonoFont.includes(get(firstItem, 'field'));
  }

  get hasPreview() {
    const withPreview = ['body', 'logo', 'welcomeImage', 'imageFileUrls', 'filterRules'];

    const items = get(this.args.changelog, 'items');
    const firstItem = get(items, 'firstObject');
    // We only want to show a preview if there is only one log
    // and if that log refers to something that changed
    // and not something set for the first time.
    return (
      get(items, 'length') === 1 &&
      withPreview.includes(get(firstItem, 'field')) &&
      isPresent(get(firstItem, 'value')[0]) &&
      isPresent(get(firstItem, 'value')[1])
    );
  }

  get locationCreatedOrDisabled() {
    const changelog = this.args.changelog;
    const event = get(changelog, 'event');
    const firstItemType = get(changelog, 'items.firstObject.type');
    const firstItemField = get(changelog, 'items.firstObject.field');
    return firstItemType === 'locations' && (event === 'create' || firstItemField === 'disabled');
  }

  get singleLocation() {
    const totalLocations = this.totalLocations;
    const parentPresent = this.parentPresent;
    // There are two scenarios where there is a single location
    // either when parent is not present or when there are no child locations
    return totalLocations < 1 || (!parentPresent && totalLocations < 2);
  }

  get parentPresent() {
    return isPresent(this.parent);
  }

  get totalLocations() {
    return this.args.changelog.hasMany('locations').ids().length;
  }

  get parentLocation() {
    if (isPresent(this.parent)) {
      return this.parent;
    } else {
      return this.locations ? get(this.locations, 'firstObject') : null;
    }
  }

  get otherLocations() {
    let locations = this.locations.toArray();
    if (!this.parentPresent) {
      // Removes the first one
      locations = locations.slice(1);
    }
    return locations;
  }
}
