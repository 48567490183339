import Service, { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';

export default class GlobalOverviewAccessService extends Service {
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;

  // =======================
  // = GLOBAL OVERVIEW
  // =======================
  get hasAnyGlobalOverviewAbility(): boolean {
    return (
      this.canVisitGlobalLocation ||
      this.canVisitGlobalVisitor ||
      this.canVisitGlobalWorkplace ||
      this.canVisitGlobalProtect ||
      this.canVisitGlobalAnalytics ||
      this.canVisitGlobalManage ||
      this.canVisitGlobalCommunications ||
      this.canVisitGlobalEmployees
    );
  }

  firstAccessibleRouteForGlobalOverview(): string | null {
    if (this.canVisitGlobalLocation) {
      return 'location-overview.locations';
    }

    if (this.canVisitGlobalVisitor) {
      return this.firstAccessibleRouteForGlobalVisitor();
    }

    if (this.canVisitGlobalWorkplace) {
      return 'location-overview.workplace.analytics';
    }

    if (this.canVisitGlobalProtect) {
      return this.firstAccessibleRouteForGlobalProtect();
    }

    if (this.canVisitGlobalAnalytics) {
      return this.firstAccessibleRouteForGlobalAnalytics();
    }

    if (this.canVisitGlobalManage) {
      return this.firstAccessibleRouteForGlobalManage();
    }

    if (this.canVisitGlobalEmployees) {
      return 'location-overview.employees';
    }

    return null;
  }

  get canVisitGlobalLocation(): boolean {
    return this.abilities.can('visit global-locations');
  }

  get canVisitGlobalVisitor(): boolean {
    return this.abilities.can('visit global-visitors');
  }

  get canVisitGlobalCommunications(): boolean {
    return this.abilities.can('visit global-communication');
  }

  get canVisitGlobalWorkplace(): boolean {
    return (
      this.abilities.can('visit global protect-analytics') && this.featureFlags.isEnabled('workplace-garaje-launch')
    );
  }

  get canVisitGlobalProtect(): boolean {
    return (
      (this.abilities.can('visit protect in global-analytics') ||
        this.abilities.can('visit analytics upsell for global-analytics')) &&
      !this.featureFlags.isEnabled('workplace-garaje-launch')
    );
  }

  get canVisitGlobalAnalytics(): boolean {
    return this.abilities.can('visit analytics in global-analytics');
  }

  get canVisitGlobalManage(): boolean {
    return this.abilities.can('visit global manage-settings');
  }

  get canVisitGlobalEmployees(): boolean {
    return this.abilities.can('visit global employee-directory');
  }

  // =======================
  // = GLOBAL VISITOR ACCESS
  // =======================
  get hasAnyGlobalVisitorAbility(): boolean {
    return this.canVisitVisitorLog || this.canVisitFlows || this.canVisitDevicesOrPrinters;
  }

  firstAccessibleRouteForGlobalVisitor(): string | null {
    if (this.canVisitVisitorLog) {
      return 'location-overview.visitor-log';
    }

    if (this.canVisitInviteLog) {
      return 'location-overview.invite-log';
    }

    if (this.canVisitFlows) {
      return 'location-overview.sign-in-flows';
    }

    if (this.canVisitDevicesOrPrinters) {
      return 'location-overview.global-devices';
    }

    return null;
  }

  get canVisitInviteLog(): boolean {
    return this.abilities.can('visit global-invite-log');
  }

  get canVisitVisitorLog(): boolean {
    return this.abilities.can('visit global-visitor-log');
  }

  get canVisitFlows(): boolean {
    return this.abilities.can('visit global-flows');
  }

  get canVisitDevicesOrPrinters(): boolean {
    return this.abilities.can('read global-devices') || this.abilities.can('view nav item for global-printers');
  }

  // =======================
  // = GLOBAL ANALYTICS ACCESS
  // =======================
  firstAccessibleRouteForGlobalAnalytics(): string | null {
    if (this.canVisitOccupancyAnalytics) {
      return 'location-overview.occupancy.analytics';
    }

    if (this.canVisitAttendance) {
      return 'location-overview.analytics.attendance.overview';
    }

    if (this.canVisitBenchmarkingAnalytics) {
      return 'location-overview.analytics.benchmarking';
    }

    if (this.canVisitConsolidatedVisitorsAnalytics) {
      return 'location-overview.analytics.visitors';
    }

    if (this.canVisitConsolidatedProtectAnalytics) {
      return 'location-overview.analytics.protect';
    }

    if (this.canVisitDeliveriesAnalytics) {
      return 'location-overview.analytics.deliveries';
    }

    if (this.canVisitConsolidatedDesksAnalytics) {
      return 'location-overview.analytics.desks';
    }

    if (this.canVisitRoomsAnalytics) {
      return 'location-overview.analytics.rooms';
    }

    if (this.canVisitSpaceIndexAnalytics) {
      return 'location-overview.analytics.space-index';
    }

    if (this.canVisitCustomDashboards) {
      return 'location-overview.analytics.custom-dashboards';
    }

    if (this.canVisitOccupancyGlobalSettings) {
      return 'location-overview.occupancy.settings';
    }

    return null;
  }

  get canVisitOccupancyAnalytics(): boolean {
    return this.abilities.can('visit occupancy in global-analytics');
  }

  get canVisitConsolidatedVisitorsAnalytics(): boolean {
    return this.abilities.can('visit visitors in global-analytics');
  }

  get canVisitConsolidatedProtectAnalytics(): boolean {
    return this.abilities.can('visit protect in global-analytics');
  }

  get canVisitConsolidatedDesksAnalytics(): boolean {
    return this.abilities.can('visit desks in global-analytics');
  }

  get canVisitOccupancyGlobalSettings(): boolean {
    return this.abilities.can('visit occupancy-global-settings');
  }

  get canVisitAttendance(): boolean {
    return this.abilities.can('visit attendance in global-analytics');
  }

  get canVisitDeliveriesAnalytics(): boolean {
    return this.abilities.can('visit deliveries in global-analytics');
  }

  get canVisitRoomsAnalytics(): boolean {
    return this.abilities.can('visit rooms in global-analytics');
  }

  get canVisitBenchmarkingAnalytics(): boolean {
    return this.abilities.can('visit benchmarking in global-analytics');
  }

  get canVisitCustomDashboards(): boolean {
    return this.abilities.can('visit custom dashboards in global-analytics');
  }

  get canVisitSpaceIndexAnalytics(): boolean {
    return this.abilities.can('visit space index in global-analytics');
  }

  // =======================
  // = GLOBAL PROTECT ACCESS
  // =======================
  firstAccessibleRouteForGlobalProtect(): string | null {
    if (this.abilities.can('visit protect in global-analytics')) {
      return 'location-overview.protect.analytics.index';
    }

    if (this.abilities.can('visit analytics upsell for global-analytics')) {
      return 'location-overview.protect.analytics.upsell';
    }

    return null;
  }

  // =======================
  // = GLOBAL MANAGE ACCESS
  // =======================
  firstAccessibleRouteForGlobalManage(): string | null {
    if (this.canVisitGlobalAdmins) {
      return 'location-overview.manage.admin-users';
    }

    if (this.canVisitGlobalCustomRoles) {
      return 'location-overview.manage.roles';
    }

    if (this.canVisitAccountSettings) {
      return 'location-overview.manage.account-settings';
    }

    if (this.canVisitAuditLog) {
      return 'location-overview.manage.audit-log';
    }

    return null;
  }

  get canVisitGlobalAdmins(): boolean {
    return this.abilities.can('visit global admins in manage-settings');
  }

  get canVisitGlobalCustomRoles(): boolean {
    return this.abilities.can('visit global custom roles in manage-settings');
  }

  get canVisitAccountSettings(): boolean {
    return this.abilities.can('visit account settings in manage-settings');
  }

  get canVisitAuditLog(): boolean {
    return this.abilities.can('visit global audit log in manage-settings');
  }
}
