import Model, { attr } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import includes from 'garaje/utils/decorators/includes';
import { gt } from 'macro-decorators';

@adapter('billing-v1')
export default class FeatureModel extends Model {
  // Query params
  @attr('string') declare company_id: string;
  @attr('string') declare location_id?: string;

  // Return array
  @attr('array') declare features: string[];

  // Feature gates
  @includes('features', 'access_control_auto_sign_in') declare canAccessAutoCheckIn: boolean;
  @includes('features', 'analytics') declare canAccessAnalytics: boolean;
  @includes('features', 'analytics_viewer_role') declare canAccessAnalyticsViewerRole: boolean;
  @includes('features', 'app_desks') declare canAccessDesksApplication: boolean;
  @includes('features', 'app_deliveries') declare canAccessDeliveriesApplication: boolean;
  @includes('features', 'app_empxp') declare canAccessWorkplaceApplication: boolean;
  @includes('features', 'app_rooms') declare canAccessRoomsApplication: boolean;
  @includes('features', 'app_visitors') declare canAccessVisitorsApplication: boolean;
  @includes('features', 'assistants') declare canAccessAssistants: boolean;
  @includes('features', 'attendance_analytics') declare canAccessAttendanceAnalytics: boolean;
  @includes('features', 'blacklist') declare canAccessBlocklist: boolean;
  @includes('features', 'boss') declare canAccessBoss: boolean;
  @includes('features', 'box') declare canAccessBox: boolean;
  @includes('features', 'capacity_limits') declare canAccessCapacityLimits: boolean;
  @includes('features', 'check_in_notification') declare canAccessCheckInNotification: boolean;
  @includes('features', 'conditional_alerts') declare canAccessConditionalAlerts: boolean;
  @includes('features', 'conditional_sign_in_fields') declare canAccessConditionalSignInFields: boolean;
  @includes('features', 'custom_admin_roles_visitors') declare canManageCustomAdminRolesVisitors: boolean;
  @includes('features', 'custom_admin_roles_workplace') declare canManageCustomAdminRolesWorkplace: boolean;
  @includes('features', 'custom_analytics') declare canAccessCustomAnalytics: boolean;
  @includes('features', 'custom_badge') declare canAccessCustomBadge: boolean;
  @includes('features', 'custom_notifications') declare canAccessCustomNotifications: boolean;
  @includes('features', 'delegated_booking') declare canAccessDelegatedBooking: boolean;
  @includes('features', 'deliveries_analytics') declare canAccessDeliveriesAnalytics: boolean;
  @includes('features', 'desk_reservation_delegated_management') declare canUseDbeam: boolean;
  @includes('features', 'desk_reservation_partial_day_booking') declare canUsePartialDayBooking: boolean;
  @includes('features', 'desk_unlimited_quantity') declare canAccessUnlimitedDesks: boolean;
  @includes('features', 'desks_analytics') declare canAccessDesksAnalytics: boolean;
  @includes('features', 'directory_sync') declare canAccessDirectorySync: boolean;
  @includes('features', 'emergency_notifications') declare canAccessEmergencyNotifications: boolean;
  @includes('features', 'emergency_notifications_employees') declare canAccessEmergencyNotificationsEmployees: boolean;
  @includes('features', 'emergency_notifications_visitors') declare canAccessEmergencyNotificationsVisitors: boolean;
  @includes('features', 'employee_screening_flow') declare canAccessEmployeeScreeningFlow: boolean;
  @includes('features', 'facial_recognition') declare canAccessFacialRecognition: boolean;
  @includes('features', 'global_analytics') declare canAccessGlobalAnalytics: boolean;
  @includes('features', 'global_overview') declare canAccessGlobalOverview: boolean;
  @includes('features', 'global_printers') declare canAccessGlobalPrinters: boolean;
  @includes('features', 'global_sign_in_flows') declare canAccessGlobalSignInFlows: boolean;
  @includes('features', 'group_invites') declare canAccessGroupInvitesFeature: boolean;
  @includes('features', 'group_sign_in') declare canAccessGroupSignIn: boolean;
  @includes('features', 'host_search_options') declare canAccessHostSearchOptions: boolean;
  @includes('features', 'id_check') declare canAccessIDCheck: boolean;
  @includes('features', 'id_scanning') declare canAccessIdScanning: boolean;
  @includes('features', 'inactivity_logout') declare canAccessInactivityLogout: boolean;
  @includes('features', 'invite_approvals') declare canRequireInviteApproval: boolean;
  @includes('features', 'legal_name_validation') declare canAccessFullLegalName: boolean;
  @includes('features', 'locations_grouping') declare canAccessLocationsGrouping: boolean;
  @includes('features', 'microsoft_365') declare canAccessMicrosoft365: boolean;
  @includes('features', 'multi_location_invites') declare canAccessMultiLocationInvites: boolean;
  @includes('features', 'multiple_flows') declare canAccessMultipleFlows: boolean;
  @includes('features', 'multiple_hosts') declare canAccessMultipleHosts: boolean;
  @includes('features', 'multiple_printers') declare canAccessMultiplePrinters: boolean;
  @includes('features', 'multiple_visitor_types') declare canAccessMultipleVisitorTypes: boolean;
  @includes('features', 'nda_bcc') declare canAccessNdaBcc: boolean;
  @includes('features', 'nda_enabled') declare includesNda: boolean;
  @includes('features', 'occupancy_analytics') declare canAccessOccupancyAnalytics: boolean;
  @includes('features', 'pre_registration_required') declare canAccessPreRegistrationRequired: boolean;
  @includes('features', 'presign_nda') declare hasPresignNda: boolean;
  @includes('features', 'privacy_and_booking_controls') declare canAccessPrivacyAndBookingControls: boolean;
  @includes('features', 'protect_legacy_features') declare canAccessProtectLegacyFeatures: boolean;
  @includes('features', 'rooms_analytics') declare canAccessRoomsAnalytics: boolean;
  @includes('features', 'salesforce') declare canAccessSalesforce: boolean;
  @includes('features', 'saml') declare canAccessSaml: boolean;
  @includes('features', 'scim_admin_sync') declare canAccessScimAdminSync: boolean;
  @includes('features', 'scheduled_reports') declare canAccessScheduledReports: boolean;
  @includes('features', 'sign_in_from_invite') declare canSignInFromInvite: boolean;
  @includes('features', 'sign_in_visitors') declare canSignInVisitors: boolean;
  @includes('features', 'slack') declare canAccessSlack: boolean;
  @includes('features', 'slideshows') declare canAccessSlideshows: boolean;
  @includes('features', 'sms_notification') declare canAccessSmsNotification: boolean;
  @includes('features', 'static_qr_codes') declare canAccessStaticQrCodes: boolean;
  @includes('features', 'strategic_insights_analytics') declare canAccessStrategicInsightsAnalytics: boolean;
  @includes('features', 'unlimited_employees') declare canAccessUnlimitedEmployees: boolean;
  @includes('features', 'vaccination_upload_and_approval') declare canAccessVaccinationUploadAndApproval: boolean;
  @includes('features', 'video_in_legal_document') declare hasVideoInLegalDocument: boolean;
  @includes('features', 'virtual_front_desk') declare canAccessVirtualFrontDesk: boolean;
  @includes('features', 'visitor_badge_printing') declare canEnableBadgePrinting: boolean;
  @includes('features', 'visitor_photos') declare canAccessVisitorPhotos: boolean;
  @includes('features', 'visitor_survey') declare canAccessVisitorSurvey: boolean;
  @includes('features', 'visual_compliance') declare canAccessVisualCompliance: boolean;
  @includes('features', 'welcome_guide') declare canAccessWelcomeGuide: boolean;
  @includes('features', 'whitegloved_from_email_name') declare hasWhiteglovedFromEmailName: boolean;
  @gt('features.length', 0) hasAnyFeatures!: boolean;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    feature: FeatureModel;
  }
}
