import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class WorkplaceAnnouncementsListRoute extends Route {
  @service router;

  model() {
    const { announcements } = this.modelFor('workplace.announcements');

    return {
      announcements,
    };
  }

  afterModel({ announcements }) {
    if (!announcements.length) {
      this.router.transitionTo('workplace.announcements.landing-page');
    }
  }
}
