import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { ITEM } from 'garaje/utils/enums';
import { sortBy, alias, not } from 'macro-decorators';

/**
 * @param {String}    app
 * @param {Boolean}   multiple
 */
export default class SubscriptionEditor extends Component {
  @service('location-billing') billingService;
  @service state;

  @alias('billingService.billingCart') billingCart;
  @alias('billingService.sectionOpened') sectionOpened;
  @alias('billingService.editingQuantities') editingQuantities;
  @alias('billingService.allowedPeriods') allowedPeriods;
  @alias('billingService.currentPeriodDisplay') currentPeriodDisplay;
  @alias('billingService.isDirty') isDirty;
  @alias('billingService.renewalDate') renewalDate;
  @alias('billingService.hasPaidSubscription') hasPaidSubscription;
  @alias('billingService.visitorsTrialDaysLeft') visitorsTrialDaysLeft;
  @alias('billingService.roomsTrialDaysLeft') roomsTrialDaysLeft;
  @alias('billingService.cartDeliveriesTrialDaysLeft') deliveriesTrialDaysLeft;
  @alias('billingService.cartVisitorsTrial') cartVisitorsTrial;
  @alias('billingService.cartRoomsTrial') cartRoomsTrial;
  @alias('billingService.cartDeliveriesTrial') cartDeliveriesTrial;
  @alias('billingService.visitorsSubscriptionModification') visitorsSubscriptionModification;
  @alias('billingService.deliveriesSubscriptionModification') deliveriesSubscriptionModification;
  @alias('billingService.roomsSubscriptionModification') roomsSubscriptionModification;

  @not('hasTrialDaysLeft') hasNoTrialDaysLeft;
  @not('selectedPlan') hasNoSelectedPlan;
  @sortBy('availablePlans', 'sequence') sortedPlans;

  get displayType() {
    const currentRank = this.initialSubscriptionPlan.rank || 0;
    const nextRank = this.subscriptionModification.subscriptionPlan?.rank || 0;
    return currentRank > nextRank ? 'downgrading' : 'upgrading';
  }

  get icon() {
    switch (this.args.app) {
      case 'deliveries':
        return `/assets/images/app-icons/deliveries.svg`;
      case 'rooms':
        return `/assets/images/app-icons/rooms.svg`;
      default:
        return `/assets/images/app-icons/visitors.svg`;
    }
  }

  get item() {
    switch (this.args.app) {
      case 'deliveries':
        return ITEM.DELIVERIES;
      case 'rooms':
        return ITEM.ROOMS;
      default:
        return ITEM.VISITORS;
    }
  }

  get name() {
    return capitalize(this.args.app);
  }

  get initialSubscriptionPlan() {
    return this.billingService[`initial${this.name}SubscriptionPlan`];
  }

  get activeQuantity() {
    let quantity = 0;
    switch (this.args.app) {
      case 'visitors':
        quantity = this.state.activeLocationsCount.last.value;
        break;
      case 'deliveries':
        quantity = this.state.activeDeliveryAreasCount.last.value;
        break;
      case 'rooms':
        quantity = this.state.activeRoomsCount.last.value;
        break;
    }
    return quantity;
  }

  get price() {
    return this.billingService[`cart${this.name}PriceTotal`];
  }

  get selectedPlan() {
    return this.billingService[`cart${this.name}Plan`];
  }

  get trialDaysLeft() {
    return this[`${this.args.app}TrialDaysLeft`];
  }

  get subscriptionModification() {
    return this[`${this.args.app}SubscriptionModification`];
  }

  get cartTrial() {
    return this[`cart${this.name}Trial`];
  }

  get hasTrialDaysLeft() {
    return !!this.trialDaysLeft;
  }

  get selectedPlanQuantity() {
    return this.billingService[`cart${this.name}LocationQuantitiesTotal`];
  }

  get locationQuantities() {
    return this.billingService[`cart${this.name}LocationQuantities`];
  }

  get availablePlans() {
    return this.billingService[`${this.args.app}Plans`];
  }

  @action
  openSection() {
    this.billingService.toggleSectionOpened(this.args.app);
  }

  @action
  setPlan(plan) {
    this.billingService.setSubscriptionPlan(plan);
  }

  @action
  setQuantity(locationId, locationName, quantity) {
    this.billingService.setLocationQuantity(this.args.app, locationId, locationName, quantity);
  }

  @action
  clearQuantities() {
    this.billingService.clearQuantities();
  }
}
