import $ from 'jquery';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'garaje/config/environment';
import 'moment-timezone'; // to use moment with timezone
import Ember from 'ember';
import Intercom from '@intercom/messenger-js-sdk';

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  constructor() {
    super(...arguments);

    if (config.intercomAppId) {
      this.Intercom = Intercom({
        app_id: config.intercomAppId,
        hide_default_launcher: false,
      });
    }
  }

  async ready() {
    if (config.isCypress && !window.Ember) {
      // we need this, because cypress tests are expecting Ember global in window
      window.Ember = Ember;
    }

    window.global = window; // for rome package

    // cancels the loading timer when app is completely ready to go - initializer doesn't work b/c we have deferReadiness for google maps
    window.clearTimeout(window.loadingTimer);
    const loadingMessage = $('.load-error');
    if (loadingMessage.first()) {
      loadingMessage.first().removeClass('display');
    }

    window.appStartTime = performance.now();
    window.appMeasurementTaken = false;
  }
}

loadInitializers(App, config.modulePrefix);
