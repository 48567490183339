import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type BlocklistFilterImporterService from 'garaje/services/blocklist-filter-importer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import Papa from 'papaparse';

interface BlocklistCsvImportComponentSignature {
  Args: {
    /**
     * number of records that have been imported so far
     */
    importedCount: number;
    /**
     * ember-concurrency task to `perform` to save blocklist filters
     */
    saveBlocklistFiltersTask: Task<unknown, [File]>;
  };
}

export default class BlocklistCsvImportComponent extends Component<BlocklistCsvImportComponentSignature> {
  @service('blocklistFilterImporter') declare importer: BlocklistFilterImporterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  @tracked fileName = '';
  @tracked records: unknown[] = [];

  get csvFile(): File {
    const csvData = Papa.unparse(this.records, {
      header: false,
      columns: [
        'id',
        'fullName',
        'description',
        'reason',
        'aliases',
        'emails',
        'phoneNumbers',
        'otherKeywords',
        'imageUrl',
        'externalId',
      ],
    });
    const blob = new Blob([csvData]);
    return new File([blob], 'block-list.csv', { type: 'text/csv' });
  }

  @action
  clearBlocklistFilters(): void {
    this.fileName = '';
    this.records = [];
  }

  importBlocklistFiltersTask = task(async (file: File) => {
    this.fileName = file.name;
    const result = await this.importer.importRecordsFromCsvFile.perform(file);
    this.records = result.filter(Boolean);
    const wrongRows = result.length - this.records.length;
    if (wrongRows > 0 && this.records.length > 0) {
      const message = `${pluralize(wrongRows, 'row')} ${wrongRows > 1 ? 'were' : 'was'} not loaded`;
      this.flashMessages.showFlash(
        'error',
        message,
        'Please check that all rows in your file have a reason for blocking and at least one matching field',
      );
    }
  });

  saveBlocklistFiltersTask = task(async () => {
    try {
      await this.args.saveBlocklistFiltersTask.perform(this.csvFile);
    } catch {
      this.clearBlocklistFilters();
    }
  });
}
