import { modifier } from 'ember-modifier';

type PositionalArgs = [() => void];
type NamedArgs = { containerSelector: string; useFurthestAncestor?: boolean };

function closestAncestor(element: HTMLElement, selector: string) {
  return element.closest(selector);
}

function furthestAncestor(element: HTMLElement, selector: string) {
  let ancestor;
  while ((ancestor = element.parentElement?.closest(selector))) {
    element = <HTMLElement>ancestor;
  }
  return element;
}

export default modifier(
  (element: HTMLElement, [action]: PositionalArgs, { containerSelector, useFurthestAncestor }: NamedArgs) => {
    const root = useFurthestAncestor
      ? furthestAncestor(element, containerSelector)
      : closestAncestor(element, containerSelector);

    const intersectionHandler = (entries: IntersectionObserverEntry[]) => {
      if (entries.every((entry) => entry.isIntersecting)) {
        action();
      }
    };

    const observer = new IntersectionObserver(intersectionHandler, {
      root,
      rootMargin: '10px',
      threshold: 0,
    });
    observer.observe(element);
    return () => {
      observer.unobserve(element);
    };
  },
);
