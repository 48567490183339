import Service, { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { APP } from 'garaje/utils/enums';

/**
 * A facade for `metrics` so that we can log to both segment and datadog
 */
export default class WorkplaceMetricsService extends Service {
  @service declare metrics: MetricsService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;

  /**
   * Logs to both datadog and segment.
   *
   * This function also adds a bunch of shared stuff.
   */
  trackEvent(event: string, properties: Record<string, unknown> = {}, options: Record<string, unknown> = {}): void {
    const propertiesWithSharedContext = {
      ...this.getSharedContext(),
      ...properties,
    };
    this.metrics.trackEvent(event, propertiesWithSharedContext, options);
    this.logMonitorEvent(event, propertiesWithSharedContext);
  }

  /**
   * Logs error to datadog
   */
  logMonitorError({ event, debugExtras, error }: Parameters<MetricsService['logMonitorError']>[0]): void {
    const debugExtrasWithSharedContext = {
      ...this.getSharedContext(),
      ...debugExtras,
      event,
    };
    this.metrics.logMonitorError({ event, debugExtras: debugExtrasWithSharedContext, error });
  }

  /**
   * Logs to datadog. Like `metricsService.logMonitorEvent` but adds Workplace specific context.
   */
  logMonitorEvent(event: string, properties: Record<string, unknown> = {}): void {
    const propertiesWithSharedContext = {
      ...this.getSharedContext(),
      ...properties,
      event,
    };
    this.metrics.logMonitorEvent(event, propertiesWithSharedContext);
  }

  private getSharedContext() {
    return {
      product: APP.WORKPLACE,
      workplaceTier: this.state.workplaceSubscription?.plan,
      visitorsTier: this.state.visitorsSubscription?.plan,
      isLegacyProtectEnabled: this.state.visitorsSubscription?.canAccessProtectLegacyFeatures,
      isAdmin: this.currentAdmin?.isAdminLike,
      isAssistant: this.currentAdmin?.employee?.hasMany('bosses')?.ids()?.length !== 0,
    };
  }
}
