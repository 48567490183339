import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import checkType from 'garaje/utils/check-file-type';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action } from '@ember/object';
import { alias, and, equal } from 'macro-decorators';

export default class EmployeesImportController extends Controller {
  @service employeeUpload;
  @service featureFlags;
  @service flashMessages;
  @service router;
  @service state;
  @service workplaceMetrics;
  @service statsig;

  @alias('state.vrSubscription') vrSubscription;
  @alias('state.workplaceSubscription') workplaceSubscription;
  @alias('state.currentCompany') currentCompany;
  @and('vrSubscription', 'vrSubscription.canAccessAssistants') canAccessAssistants;
  @equal('state.currentLocation.employeesCsvUploadStatus', 'in_progress') isServerProcessing;

  @tracked isProcessing = false;
  @tracked parsedObjects = null;
  @tracked originalFile = null;
  @tracked isActive;
  maxPreviewLength = 500;

  get cannotImportCSVEmployeeLimit() {
    // only gate if gating feature flag is enabled
    if (!this.featureFlags.isEnabled('employee-feature-gating')) return false;

    return (
      !this.state.features?.canAccessUnlimitedEmployees &&
      !this.model.isConnectedToProperty &&
      this.parsedObjects?.length > 50
    );
  }

  get csvHeaders() {
    const headers = ['name', 'email', 'phone_number'];
    if (this.canAccessAssistants) {
      headers.push('assistant_emails');
    }
    return [...headers, 'department', 'title', 'primary_location', 'manager_email', 'onsite_remote'];
  }

  get exampleCsv() {
    let firstRow = ['Arya Griffin', 'arya@glacier.com', '+1 (206) 921-3401'];
    let secondRow = ['Jake Mitchell', 'jake@glacier.com', ''];

    if (this.canAccessAssistants) {
      firstRow.push('bbarr@glacier.com');
      secondRow.push('');
    }

    firstRow = [...firstRow, 'Sales', 'Director', 'San Francisco', 'sgarcia@glacier.com', 'Onsite'];
    secondRow = [...secondRow, '', '', 'Glacier Miami', '', 'Remote'];

    return [firstRow, secondRow];
  }

  @action
  async uploadCsvToServer() {
    try {
      this.workplaceMetrics.trackEvent('EMPLOYEE_DIRECTORY_SELECT_CSV_FILE_BUTTON_CLICKED');
      await this.employeeUpload.uploadEmployeesCSV(this.originalFile);

      if (this.router.currentRouteName?.includes('employees.directory')) {
        // action is caught in employees/directory/route, if this gets called from
        // employees/import it'll fail as directory is not the parent of that path
        this.send('resetEmployees');
      }

      this.parsedObjects = null;

      this.flashMessages.showAndHideFlash('success', 'Saved!');

      if (this.isActive) {
        this.router.transitionTo('employees');
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  toggleProcessing() {
    this.isProcessing = !this.isProcessing;
  }

  @action
  handleCsvResults(contents, originalFile) {
    this.workplaceMetrics.trackEvent('EMPLOYEE_DIRECTORY_IMPORT_CSV_FILE_BUTTON_CLICKED');
    this.originalFile = originalFile;
    const checkResult = checkType(originalFile, ['csv']);

    if (checkResult.isValid) {
      const formattedArray = [];
      contents.data.forEach(function (datum) {
        let object;
        // passing in a CSV without a header row is legacy behavior. department upload is
        // only supported for CSVs with a header row.
        if (Array.isArray(datum)) {
          // do not extend the array mapping below to include department. this is on
          // purpose.
          object = { name: datum[0], email: datum[1], phone: datum[2], assistant: datum[3] };
        } else {
          object = {
            name: datum.name,
            email: datum.email,
            phone: datum.phone_number,
            assistant: datum.assistant_emails,
            department: datum.department,
            title: datum.title,
            primaryLocation: datum.primary_location,
            managerEmail: datum.manager_email,
            onsiteRemote: datum.onsite_remote,
          };
        }
        formattedArray.pushObject(object);
      });
      this.parsedObjects = formattedArray;
    } else {
      const text = `Only files with extensions .csv are allowed. You uploaded a file with extension ${checkResult.extension}.`;
      this.flashMessages.showFlash('error', text);
    }
  }
}
