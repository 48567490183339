import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { camelize } from '@ember/string';
import { get, set } from '@ember/object';

/**
 * @returns {*}
 */
export default function (Class) {
  const DecoratedClass = class extends Class {
    @service store;

    async save(args = {}) {
      const attributesChanged = this._cleanPropagate(Object.keys(this.changedAttributes()));
      const result = await super.save(...arguments);

      /*
        Prevent propagable changes if { propagable: false } passed in as argument.
        We're using this flag when multiple agreements are being edited, to prevent propagating
        changes until we can update how global settings batch works to support multiple documents
        @thomascchen
      */
      if (args?.propagable === false) {
        return result;
      }

      // Handles changes made to the model that can be propagables and set
      // those changed attributes into the `globalSettingBatch` singleton model
      // This model is singleton
      const globalSettingBatch = get(this.store.peekAll('global-setting-batch'), 'firstObject');
      if (globalSettingBatch && isPresent(attributesChanged)) {
        const updatedAttributesKey = this._updatedAttributesKey();
        const updatedAttributes = new Set(get(globalSettingBatch, updatedAttributesKey));
        attributesChanged.forEach((attr) => updatedAttributes.add(attr));
        set(globalSettingBatch, updatedAttributesKey, [...updatedAttributes]);
      }
    }

    // The `updatedAttributes` may not always be directly under model name attribute
    _updatedAttributesKey() {
      let modelName = camelize(this.constructor.modelName);

      /*
        The global-settings-batch API endpoint is not yet ready to handle updated attributes that aren't in the
        agreementPage key. Currently overriding this so that we can use the new agreements data model for a single
        document flow, without breaking the global-settings-batch behavior.
        @thomascchen
      */
      if (modelName === 'agreement') {
        modelName = 'agreementPage';
      }

      const overwritableParent = this.constructor.OVERWRITABLE_PARENT;
      if (overwritableParent) {
        return `${overwritableParent}.${modelName}.updatedAttributes`;
      } else {
        return `${modelName}.updatedAttributes`;
      }
    }

    // Make sure to only propagate overwritable attributes
    _cleanPropagate(attributes) {
      const settings = Object.keys(this.constructor.OVERWRITABLE_SETTINGS);
      return attributes.filter((attr) => settings.includes(attr));
    }

    async propagableReload() {
      await this.reload();
      const relodableRelations = this.constructor.PROPAGATED_RELOADABLE_RELATIONS;
      if (isPresent(relodableRelations)) {
        for (const relation of relodableRelations.belongsTo) {
          // Only realod if it's present
          if (isPresent(this.belongsTo(relation).value())) {
            await this.belongsTo(relation).reload();
          }
        }
        for (const relation of relodableRelations.hasMany) {
          if (isPresent(this.hasMany(relation).value())) {
            await this.hasMany(relation).reload();
          }
        }
      }
    }
  };

  // reassign the original class name as the name of the decorated class
  Object.defineProperty(DecoratedClass, 'name', { value: Class.name });

  return DecoratedClass;
}
