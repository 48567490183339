import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import { type WorkbooksResponse, type Workbook, WorkbookScopeType, UserActionsType } from 'garaje/utils/workbooks';

interface CustomDashboardsLayoutArgs {
  scope: WorkbookScopeType;
  locationId?: string;
  page: number;
  setPage: (page: number) => void;
}

export default class CustomDashboardsLayoutComponent extends Component<CustomDashboardsLayoutArgs> {
  @service declare abilities: AbilitiesService;
  @service declare ajaxFetch: AjaxFetchService;
  @service declare flashMessages: FlashMessagesService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare state: StateService;

  @tracked limit = 25;
  @tracked totalElements = 0;
  @tracked totalPages = 0;
  @tracked customWorkbooks: Array<Workbook> = [];
  @tracked sigmaIframeUrl: string | null | undefined = undefined;

  constructor(owner: unknown, args: CustomDashboardsLayoutArgs) {
    super(owner, args);

    void this.getWorkbooks.perform();
  }

  get isLoading(): boolean {
    return this.getWorkbooks.isRunning || this.openWorkbook.isRunning;
  }

  get canCreateDashboard(): boolean {
    if (this.args.scope === WorkbookScopeType.COMPANY) {
      return this.abilities.can('create custom dashboard for global-analytics');
    } else {
      return this.abilities.can('create custom dashboard for analytics');
    }
  }

  get showCreateBtn(): boolean {
    return this.canCreateDashboard && !this.isLoading && !this.sigmaIframeUrl;
  }

  getWorkbooks = dropTask(async () => {
    try {
      const { scope, locationId } = this.args;
      const offset = (this.args.page - 1) * this.limit;

      const url = urlBuilder.analytics.getCustomWorkbooksUrl(
        this.state.currentCompany.id,
        scope,
        this.args.page,
        this.limit,
        offset,
        locationId,
      );
      const options = {
        type: 'GET',
        headers: {
          Accept: 'application/json',
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      };

      const response: WorkbooksResponse = await this.ajaxFetch.request(url, options);

      this.customWorkbooks = response.content;
      this.totalElements = response.totalElements;
      this.totalPages = response.totalPages;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  loadPage = dropTask(async (pageNumber: number) => {
    const oldPage = this.args.page;
    this.args.setPage(pageNumber);
    try {
      await this.getWorkbooks.perform();
    } catch {
      this.args.setPage(oldPage);
    }
  });

  openWorkbook = dropTask(async (workbook: Workbook, mode: 'view' | 'edit') => {
    if (!this.canViewWorkbook(workbook.userActions)) return;

    try {
      const {
        currentCompany: { id: companyId },
        currentLocation: { id: locationId },
      } = this.state;

      let url = '';
      if (mode === 'edit') {
        if (workbook.scope === WorkbookScopeType.COMPANY) {
          url = urlBuilder.analytics.getEditCompanySigmaUrl(companyId, workbook.id);
        } else {
          // location level workbook
          url = urlBuilder.analytics.getEditLocationSigmaUrl(companyId, workbook.id, locationId);
        }
      } else if (mode === 'view') {
        if (workbook.scope === WorkbookScopeType.COMPANY) {
          url = urlBuilder.analytics.getCompanySigmaUrl(companyId, workbook.id);
        } else {
          // location level workbook
          url = urlBuilder.analytics.getLocationSigmaUrl(companyId, locationId, workbook.id);
        }
      }

      const result = await this.sigmaUrl.getCustomDashboardSigmaUrl(url);
      this.sigmaIframeUrl = result;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  @action
  resetSigmaUrl(): void {
    this.sigmaIframeUrl = undefined;
  }

  canViewWorkbook(userActions: Array<UserActionsType>): boolean {
    return userActions.includes(UserActionsType.VIEW_EMBED);
  }
}
