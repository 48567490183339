import Controller from '@ember/controller';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';
import { filterBy, mapBy } from 'macro-decorators';

export default class SettingsWelcomeScreenReturningVisitorsController extends Controller {
  @service flashMessages;
  @service featureFlags;
  @service featureConfig;

  @filterBy('model.flows', 'enabled') enabledFlows;
  @mapBy('enabledFlows', 'signInFieldPage') signInFieldPagesPerFlow;

  get signInFieldsPerFlow() {
    return this.signInFieldPagesPerFlow.mapBy('signInFields');
  }

  get hasEmailOrPhoneField() {
    return this.signInFieldsPerFlow.every((signInFields) => signInFields.isAny('isEmailOrPhoneField'));
  }

  get hasIdScanEnabled() {
    const idScanEnabled = this.enabledFlows.any((flow) => get(flow, 'idScanPage.enabled'));
    return idScanEnabled && this.model.config.beenHereBefore;
  }

  get isBeenHereBeforeDisabled() {
    return this.toggleBeenHereBefore.isRunning || !this.hasEmailOrPhoneField;
  }

  get isFacialRecognitionEnabledDisabled() {
    return this.toggleFacialRecognitionEnabled.isRunning;
  }

  toggleBeenHereBefore = dropTask(async (value) => {
    const { config } = this.model;

    config.beenHereBefore = value;

    if (!value) {
      config.facialRecognitionEnabled = false;
    }

    try {
      await config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  toggleFacialRecognitionEnabled = dropTask(async (value) => {
    const { config } = this.model;

    config.facialRecognitionEnabled = value;

    try {
      await config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  togglePassportEnabled = dropTask(async (value) => {
    const { config } = this.model;

    config.passportEnabled = value;

    try {
      await config.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      config.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
