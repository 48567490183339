import Component from '@glimmer/component';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import { action, set } from '@ember/object';
import { guidFor } from '@ember/object/internals';

/**
 * @param {Changeset<SignInField>}          field
 * @param {Boolean}                         isRTL
 * @param {Function}                        delete
 * @param {Boolean}                         linkToExternal
 * @param {Boolean}                         isDisabled
 * @param {Object}                          handleItem
 */
export default class SignInFieldsEnvoyHostComponent extends Component {
  inputId = 'input-' + guidFor(this);

  @service state;
  /**
   * @type {Boolean}
   */
  @reads('state.currentLocation.hostNotificationsEnabled') hostNotificationsEnabled;
  /**
   * @type {Boolean}
   */
  showDeleteConfirmationModal = false;

  @action
  handleDeleteClicked() {
    if (this.hostNotificationsEnabled) {
      set(this, 'showDeleteConfirmationModal', true);
    } else {
      this.args.delete(this.args.field);
    }

    return false;
  }
}
