import { Button, FormLayout, Modal, Stack, Label, TextField } from '@envoy/polarwind-react';
import { FormCheckbox, FormDropdown, FormTextField, FormTextEditor } from '@envoy/react-rich-components-core';
import pluralize from 'pluralize';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { MESSAGE_AUDIENCE_TYPES } from '../../data/message-audience-types';
import { TakeoverTooltip } from '../../data/takeover-tooltip';
import { useTemplateForm } from '../../hooks/use-template-form';
import type { MessageCategory, Template, TemplateService } from '../../types';
import { EmployeeGroupSelect } from '../employee-group-select/employee-group-select';
import './template-form.css';

export type TemplateFormProps = {
  service: TemplateService;
  values?: Partial<Template>;
  templateId?: string;
  canEditForm?: boolean;
  isLoading?: boolean;
  onSave: (values: Partial<Template & { selectedEmployees: string[]; selectedGroups: string[] }>) => Promise<void>;
  createCategory: (name: string) => Promise<MessageCategory | undefined>;
  onDelete?: () => Promise<void>;
};

export const TemplateForm: FC<TemplateFormProps> = (props) => {
  const form = useForm<Template>({
    defaultValues: {},
    mode: 'onChange',
  });

  const [message, locations, customSelections, criticalChecked] = form.watch([
    'message',
    'locations',
    'customSelections',
    'critical',
  ]);

  const { showEmployeeSelect, showVisitorSelect, canSendTakeovers } = props.service;

  const { values, isLoading, canEditForm, onDelete } = props;

  const {
    categories,
    deliveryMethods,
    employeeAudienceOptions,
    visitorAudienceOptions,
    locationOptions,
    messageVariables,
    isDisabled,
    isSaving,
    isCreatingCategory,
    isDeleting,
    newCategoryName,
    showCreateCategoryModal,
    loadState,
    fillForm,
    handleDelete,
    handleSave,
    handleCreateCategory,
    setNewCategoryName,
    setShowCreateCategoryModal,
  } = useTemplateForm(props, form);

  useEffect(() => {
    void loadState();
  }, []);

  useEffect(() => {
    fillForm();
  }, [values]);

  useEffect(() => {
    setNewCategoryName('');
  }, [showCreateCategoryModal]);

  const { isDirty } = form.formState;

  useEffect(() => {
    if (!isDirty) {
      return;
    }

    if (criticalChecked) {
      form.setValue('markAsSafe', true);
      if (canSendTakeovers) {
        form.setValue('takeoverActive', true);
      }
    } else {
      form.setValue('markAsSafe', false);
      form.setValue('takeoverActive', false);
    }
  }, [criticalChecked, form]);

  return (
    <FormProvider {...form}>
      <form className="react-form" onSubmit={(e) => void handleSave(e)} data-loading={isLoading}>
        <FormLayout>
          <div className="template-form-split-row">
            <FormTextField<Template>
              name="name"
              label="Template name"
              disabled={!canEditForm}
              placeholder="e.g. Flood Warning"
              required="Name field is required"
              testId="template-name"
            />

            <FormDropdown<Template>
              name="category"
              label="Template category"
              required="Category field is required"
              isDisabled={!canEditForm}
              dropdownAction={{
                label: 'Create a category',
                onClick: () => setShowCreateCategoryModal(true),
              }}
              options={categories.map((c) => ({
                label: c.name,
                value: c.id,
              }))}
              preselectValue={false}
              placeholder="Select a category"
              testId="category-select"
            />
          </div>

          <FormTextField<Template>
            name="title"
            label="Message title (optional)"
            disabled={!canEditForm}
            placeholder="e.g. Urgent: Immediate Evacuation Required"
            tooltip="Message titles are not shown in SMS text messages"
            testId="template-title"
          />

          <div className="send-message__message">
            <FormTextEditor<Template>
              name="message"
              label="Message"
              required="Message field is required"
              disabled={!canEditForm}
              maxLength={{ value: 320, message: 'Reduce the length of your message' }}
              variables={messageVariables}
              multiline={6}
              placeholder="Write your message"
              key={values?.message}
              testId="template-message"
            />
            <div className="send-message__message-text">
              <div className="send-message__message-error">{form.formState.errors.message?.message}</div>
              <div className="send-message__message-length">{message?.length || 0}/320</div>
            </div>
          </div>

          <FormDropdown<Template>
            name="defaultChannels"
            label="Delivery method(s)"
            required="You must select at least 1 delivery method"
            isDisabled={!canEditForm}
            isMulti
            options={deliveryMethods}
            placeholder="Select how to deliver this message"
            testId="delivery-methods-select"
          />

          {showEmployeeSelect && (
            <EmployeeGroupSelect
              options={employeeAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id].replace('%{location_name}', 'send location'),
                value: a.id,
              }))}
              showCustomEmployeeSelect={props.service.showCustomEmployeeSelect}
              canSelectScimGroups={props.service.canSelectScimGroups}
              canSelectIndividualEmployees={props.service.canSelectIndividualEmployees}
              hasCustomValue={!!customSelections?.length}
              service={props.service}
              isDisabled={!canEditForm}
              isTemplate
            />
          )}

          {showVisitorSelect && (
            <FormDropdown<Template>
              name="defaultVisitorAudiences"
              label="Default visitors"
              isDisabled={!canEditForm}
              isMulti
              options={visitorAudienceOptions.map((a) => ({
                label: MESSAGE_AUDIENCE_TYPES[a.id],
                value: a.id,
              }))}
              placeholder="Select who should receive this message"
              testId="visitor-group-multi-select"
            />
          )}

          <FormCheckbox
            name="critical"
            label="Critical or priority message"
            disabled={!canEditForm}
            tooltip="Use for time-sensitive updates to ensure your message is sent promptly during high activity times"
            testId="critical-checkbox"
          />
          {criticalChecked && (
            <div className="send-message__indent">
              <FormCheckbox
                name="markAsSafe"
                label="Ask recipients to mark themselves as safe"
                disabled={!canEditForm}
                testId="mark-as-safe"
              />
              {canSendTakeovers && (
                <div className="send-message__top-margin">
                  <FormCheckbox
                    name="takeoverActive"
                    label="Takeover Rooms tablets in this location"
                    disabled={!canEditForm}
                    testId="takeover-rooms-tablets"
                    tooltip={TakeoverTooltip}
                  />
                </div>
              )}
            </div>
          )}
          {!canEditForm && (
            <Label label="Locations applied">
              <Stack>
                {values?.locationNames?.map((name) => {
                  return (
                    <div className="location-pill" data-pill={name}>
                      {name}
                    </div>
                  );
                })}
              </Stack>
            </Label>
          )}

          {canEditForm && (
            <FormDropdown<Template>
              name="locations"
              label="Locations applied"
              required="You must select at least one location to publish"
              isDisabled={!canEditForm}
              isMulti
              options={locationOptions.map((l) => ({
                label: l.name,
                value: l.id,
              }))}
              placeholder="Select the locations that this template applies to"
              testId="locations-select"
            />
          )}

          <div className="template-form-button-group">
            <Button type="submit" disabled={!canEditForm || isDisabled || isSaving || isDeleting} data-test-publish>
              Publish to {pluralize('location', locations?.length || 0, true)}
            </Button>

            {canEditForm && onDelete && (
              <Button
                outline
                type="button"
                disabled={isSaving || isDeleting}
                onClick={() => handleDelete()}
                data-test-delete
              >
                Delete
              </Button>
            )}
          </div>
        </FormLayout>
      </form>
      <Modal
        testId="create-category-modal"
        title="Create new category"
        primaryAction={() => handleCreateCategory()}
        primaryDisabled={isCreatingCategory || !newCategoryName.trim()}
        primaryText="Create"
        canClose={!isCreatingCategory}
        onClose={() => setShowCreateCategoryModal(false)}
        secondaryText="Cancel"
        showCloseButton={false}
        isOpen={showCreateCategoryModal}
      >
        <TextField
          value={newCategoryName}
          labelHidden
          autoFocus
          placeholder="Enter category name"
          onChange={(value: string) => setNewCategoryName(value.trimStart() || '')}
          testId="category-name"
        />
      </Modal>
    </FormProvider>
  );
};
