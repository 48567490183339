import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class DeliveriesLandingPageRoute extends Route {
  @service state;
  @service router;

  titleToken = 'Deliveries';

  beforeModel() {
    if (this.state.features?.canAccessDeliveriesApplication) {
      return this.router.transitionTo('deliveries.index');
    }
  }
}
