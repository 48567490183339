import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';
import type EmployeeModel from 'garaje/models/employee';
import type MapFloorModel from 'garaje/models/map-floor';
import type MapVersionModel from 'garaje/models/map-version';
import type CurrentLocationService from 'garaje/services/current-location';
import type StateService from 'garaje/services/state';
import { reads } from 'macro-decorators';

interface VersionsSidebarItemArgs {
  mapVersion: MapVersionModel;
  currentMapVersion: MapVersionModel;
  currentMapFloor?: MapFloorModel;
  isInsideGroup?: boolean;
}

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';

export default class VersionsSidebarItem extends Component<VersionsSidebarItemArgs> {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare currentLocation: CurrentLocationService;

  @reads('currentLocation.timezone', DEFAULT_TIMEZONE) timezone!: string;

  get isActive(): boolean {
    return this.args.currentMapVersion.id === this.args.mapVersion.id;
  }

  get formattedDate(): string {
    const formatPattern = this.args.isInsideGroup ? 'h:mm aaa' : 'LLL d, h:mm aaa';

    return formatInTimeZone(this.args.mapVersion.createdAt * 1000, this.timezone, formatPattern);
  }

  get employees(): EmployeeModel[] {
    const employeeIds = this.args.mapVersion.hasMany('employees').ids();

    return this.store.peekAll('employee').filter((e) => employeeIds.includes(e.id));
  }
}
