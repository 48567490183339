import { helper } from '@ember/component/helper';

type ActionFn = (e: Event) => unknown;
type PositionalArgs = [ActionFn?];

export function disableBubbling([action]: PositionalArgs) {
  return function (event: Event): unknown {
    event.stopPropagation();

    if (action) {
      return action(event);
    }
    return null;
  };
}

export default helper(disableBubbling);
