import { hasMany } from '@ember-data/model';
import type { AsyncHasMany } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

import EntryExportJob from './entry-export-job';

class LocationExportJob extends EntryExportJob {
  @hasMany('location', { async: true }) declare locations: AsyncHasMany<LocationModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'location-export-job': LocationExportJob;
  }
}

export default LocationExportJob;
