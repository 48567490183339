import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type BlacklistFilterModel from 'garaje/models/blacklist-filter';
import type MetricsService from 'garaje/services/metrics';
import { and, empty, match, not, or } from 'macro-decorators';

const KB = 1024;
const NAME_REGEX = /(\S+ )+\S+/; // at least 2 non-space characters with a space in between
const PHONE_REGEX = /^.*(\d.*){7,}$/; // at least 7 numbers

interface BlocklistFilterModalComponentSignature {
  Args: {
    blocklistFilter: BlacklistFilterModel;
    close: () => void;
    delete: () => void;
    isSaving: boolean;
    maxPhotoSizeKb: number;
    /**
     * boolean indicating whether the blocklist should be read-only or not
     */
    readOnly: boolean;
    save: (photoFile: File) => void;
  };
}

export default class BlocklistFilterModalComponent extends Component<BlocklistFilterModalComponentSignature> {
  @service declare metrics: MetricsService;

  nameRegex = NAME_REGEX;
  phoneRegex = PHONE_REGEX;

  @tracked changeset;
  @tracked dataUrl: string | null = null;
  @tracked photoThumb: string | null = null;
  @tracked maxPhotoSizeKb;
  @tracked photoFile: File | null = null;
  @tracked isPhotoFileTooBig = false;
  @tracked didAttemptSubmit = false;
  @tracked uploadPhotoFileInputId = null;
  @tracked updatePhotoFileInputId = null;
  @tracked didEditFullName = false;
  @tracked blocklistFilter;

  // dataUrl comes first so that an updated preview shows instead of an existing thumb
  @or('dataUrl', 'photoThumb') photoPreview!: string;
  @or('fullNameIsValid', 'emptyFullName') fullNameIsValidOrEmpty!: boolean;
  @or('hasEmptyMatchingFields', 'emptyReason') isMissingRequiredFields!: boolean;
  @or('isMissingRequiredFields', 'fullNameHasError') canNotSubmit!: boolean;
  @not('fullNameIsValidOrEmpty') fullNameHasError!: boolean;
  @not('hasEmptyMatchingFields') hasAtLeastOneMatchingField!: boolean;
  @not('emptyReason') hasAReason!: boolean;
  @match('changeset.fullName', NAME_REGEX) fullNameIsValid!: boolean;
  @empty('changeset.reason') emptyReason!: boolean;
  @empty('changeset.fullName') emptyFullName!: boolean;
  @empty('changeset.aliases') emptyAliases!: boolean;
  @empty('changeset.emails') emptyEmails!: boolean;
  @empty('changeset.phoneNumbers') emptyPhoneNumbers!: boolean;
  @empty('changeset.otherKeywords') emptyOtherKeywords!: boolean;
  @and('emptyFullName', 'emptyAliases', 'emptyEmails', 'emptyPhoneNumbers', 'emptyOtherKeywords')
  hasEmptyMatchingFields!: boolean;

  constructor(owner: unknown, args: BlocklistFilterModalComponentSignature['Args']) {
    super(owner, args);
    // use a changeset so that the form isn't mutating the underlying model prior to validation/saving
    // otherwise edits show up in the table view behind the modal
    const { blocklistFilter, maxPhotoSizeKb } = this.args;
    this.blocklistFilter = blocklistFilter;
    this.changeset = Changeset(blocklistFilter);
    this.dataUrl = blocklistFilter?.photo.dataUrl;
    this.photoThumb = blocklistFilter?.photo.thumb;
    this.maxPhotoSizeKb = maxPhotoSizeKb || 500;
  }

  @action
  onInsert(): void {
    this.metrics.trackJobEvent('Security - Blocked Person Modal Opened', {
      block_list_filter_id: this.blocklistFilter?.id,
    });
  }

  @action
  onDestroy(): void {
    this.metrics.trackJobEvent('Security - Blocked Person Modal Closed', {
      block_list_filter_id: this.blocklistFilter?.id,
    });
  }

  updatePhoto(attrs: { dataUrl: string | null; thumb?: string | null; original?: string | null }): void {
    const { changeset } = this;
    const photoCopy = {
      ...changeset.photo,
      ...attrs,
    };
    changeset.photo = photoCopy;
  }

  @action
  setPhotoFile(file: File): void {
    if (file.size < this.maxPhotoSizeKb * KB) {
      this.photoFile = file;
      this.isPhotoFileTooBig = false;
    } else {
      this.isPhotoFileTooBig = true;
    }
  }

  @action
  setPhotoDataUrl(dataUrl: string): void {
    // https://github.com/poteto/ember-changeset#updates
    // we can't do mut in the template because nested changeset mut doesn't work
    // once we upgrade to ember-changeset 3.x we can use the changeset-set template helper to do this
    // this.changeset.set('photo.dataUrl', dataUrl); // eslint-disable-line ember/use-ember-get-and-set
    this.dataUrl = dataUrl;
    this.updatePhoto({ dataUrl });
  }

  @action
  removePhoto(): void {
    this.updatePhoto({
      dataUrl: null,
      thumb: null,
      original: null,
    });

    this.photoFile = null;
    this.dataUrl = null;
    this.photoThumb = null;
  }

  @action
  handleSubmit(): void {
    this.didAttemptSubmit = true;
    this.metrics.trackJobEvent(
      `Security - Blocked Person ${<boolean>(<unknown>this.blocklistFilter?.isNew) ? 'Creation' : 'Update'} Button Clicked`,
      {
        button_text: 'Save',
        form_state: this.canNotSubmit ? 'invalid' : 'valid',
      },
    );
    if (this.canNotSubmit) {
      return;
    } else {
      this.changeset.execute();
      this.args.save(this.photoFile!);
    }
  }

  @action
  handleDelete(): void {
    this.metrics.trackJobEvent('Security - Blocked Person Delete Button Clicked', { button_text: 'Delete' });
    this.args.delete();
  }

  @action
  closeModal(): void {
    this.metrics.trackJobEvent('Security - Blocked Person Close Modal Button Clicked', { button_text: 'X' });
    this.args.close();
  }
}
