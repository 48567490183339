import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MetricsService from 'garaje/services/metrics';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { AnalyticsEventNames } from 'garaje/utils/enums';
import { Workbook } from 'garaje/utils/sigma-workbooks';
import urlBuilder from 'garaje/utils/url-builder';

export default class AttendanceController extends Controller {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked loginRequired = false;
  @tracked isError = false;

  get legacyIframeUrl(): string {
    const companyId = this.state?.currentCompany?.id;
    const view = 'overview';

    return urlBuilder.embeddedAttendanceGlobalAnalyticsUrl(this.loginRequired, companyId, view);
  }

  fetchSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const result = await this.sigmaUrl.getGlobalSigmaUrl(companyId, Workbook.GLOBAL_ATTENDANCE);
      return result;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      this.isError = true;
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });

  get sigmaIframeUrl(): string | null | undefined {
    return this.fetchSigmaUrlTask.last?.value;
  }

  logSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const details = {
        companyId,
        app: this.state.workplaceSubscription?.app,
        plan: this.state?.workplaceSubscription?.plan,
        userId: this.currentAdmin?.id || '',
        employeeId: this.currentAdmin.employee?.id || '',
      };
      this.metrics.trackEvent(AnalyticsEventNames.ATTENDANCE_GLOBAL_DASHBOARD_VISITED, details);
      this.metrics.logMonitorEvent(AnalyticsEventNames.ATTENDANCE_GLOBAL_DASHBOARD_VISITED, details);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
