import { action } from '@ember/object';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { addDays, addMonths, addYears, format, getUnixTime, startOfMonth, subMonths } from 'date-fns';
import { toZonedTime, fromZonedTime } from 'date-fns-tz';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask, type Task, task, type TaskInstance } from 'ember-concurrency';
import type { ScheduledAssignment } from 'garaje/graphql/generated/map-features-types';
import type DeskModel from 'garaje/models/desk';
import type EmployeeModel from 'garaje/models/employee';
import type MetricsService from 'garaje/services/metrics';
import type ResourceOverviewService from 'garaje/services/resource-overview';
import type StateService from 'garaje/services/state';
import { defer } from 'rsvp';

interface MyTaskInstance extends TaskInstance<void> {
  continue: () => void;
  abort: () => void;
  delete: () => void;
}

interface ScheduleDeskAssignmentModalArgs {
  selectedDeskResource: DeskModel;
  modalLifecycleTask: Task<void, unknown[]>;
  editDesk: (selectedDeskResource: DeskModel, key: string, value: unknown[]) => void;
  additionalDetails: {
    employees: EmployeeModel[];
  };
  searchEmployeesTask: Task<EmployeeModel[], unknown[]>;
  includeDeleted: string;
  onEmployeeFocusOutTask: Task<void, unknown[]>;
  updateAssignmentsPanel: (employeeId: string, assignments: ScheduledAssignment[]) => Promise<void>;
  deleteAssignment: (assignmentDeskId: string, assignmentEmployeeId: string) => void;
}

export default class ScheduleDeskAssignmentModal extends Component<ScheduleDeskAssignmentModalArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare abilities: AbilitiesService;
  @service declare metrics: MetricsService;
  @service declare resourceOverview: ResourceOverviewService;

  @tracked showingDate: Date = this.currentDateAtOfficeTimezone;
  @tracked calendarDate: Date = this.currentDateAtOfficeTimezone;
  @tracked dateSelected: boolean = false;
  @tracked showCalendar: boolean = false;
  @tracked originalScheduledEmployee: EmployeeModel | null = null;
  @tracked scheduledEmployee: EmployeeModel | null = null;
  @tracked scheduledEmployeeError: string[] = [];
  @tracked searchedEmployees: EmployeeModel[] = [];
  @tracked isUnassigning: boolean = false;
  clickListener: ((event: MouseEvent) => void) | null = null;

  constructor(owner: unknown, args: ScheduleDeskAssignmentModalArgs) {
    super(owner, args);
    if (this.args.selectedDeskResource) {
      void this.initialize();
    }
  }

  initialize(): void {
    const scheduledEmployeeId = this.args.selectedDeskResource?.assignmentEmployeeId();
    if (scheduledEmployeeId == 'Pending unassignment') {
      this.isUnassigning = true;
    } else if (scheduledEmployeeId) {
      this.scheduledEmployee = this.store.peekRecord('employee', scheduledEmployeeId);
    }
    const scheduledDate = this.args.selectedDeskResource?.assignmentStartDate();
    if (scheduledDate) {
      this.showingDate = new Date(scheduledDate);
      this.calendarDate = new Date(scheduledDate);
      this.dateSelected = true;
    }
  }

  get selectedDates(): Date[] {
    return [this.showingDate];
  }

  @action
  toggleUnassignment(): void {
    this.isUnassigning = !this.isUnassigning;
    if (this.isUnassigning) {
      this.scheduledEmployee = null;
      this.scheduledEmployeeError = [];
    }
  }

  @dropTask
  processDeskAssignmentTask = {
    *perform(this: {
      context: ScheduleDeskAssignmentModal;
      abort?: () => void;
      continue?: () => Promise<void>;
      delete?: () => void;
    }): Generator<Promise<unknown>, void, void> {
      const deferred = defer();

      this.abort = () => {
        deferred.resolve(false);
        void (this.context.args.modalLifecycleTask.last as MyTaskInstance).abort();
      };
      this.continue = async () => {
        // remove the 'old' assignment from the employee, if it existed
        if (this.context.originalScheduledEmployee) {
          this.context.args.deleteAssignment(
            this.context.args.selectedDeskResource.id,
            this.context.originalScheduledEmployee.id,
          );
        }
        const currentEmployee = this.context.store
          .peekAll('employee')
          .toArray()
          .find((employee) => employee.belongsTo('user').id() === this.context.state.currentUser?.id);
        const assignmentDetails = {
          'employee-id': this.context.scheduledEmployee?.id ?? null,
          'start-time': this.context.showingDate.toISOString(),
          'scheduled-by': currentEmployee?.id ?? null,
          'should-send-notification': true,
        };
        this.context.args.editDesk(this.context.args.selectedDeskResource, 'assignmentDetails', [assignmentDetails]);

        // once we implement the gql <> ember data adapter, these assignments will already be in the store and we won't need to "create" them here
        this.context.store.createRecord('assignment', {
          startTime: this.context.showingDate.toISOString(),
          shouldSendNotification: true,
          scheduledBy: currentEmployee?.id,
          desk: this.context.args.selectedDeskResource,
          employee: this.context.scheduledEmployee,
        });
        // update the assignments panel --> should extract this out into a function
        if (assignmentDetails['employee-id'] !== null) {
          const gqlAssignments = await this.context.resourceOverview.fetchExistingAssignmentsForEmployee(
            assignmentDetails['employee-id'],
          );
          await this.context.args.updateAssignmentsPanel(assignmentDetails['employee-id'], gqlAssignments);
        }
        deferred.resolve(true);
        this.context.metrics.trackEvent('Desk Assignment Created', {
          userId: this.context.state.currentUser?.id,
          deskId: this.context.args.selectedDeskResource?.id,
          employeeId: this.context.scheduledEmployee?.id ?? null,
        });
        void (this.context.args.modalLifecycleTask.last as MyTaskInstance).continue();
      };
      this.delete = () => {
        if (this.context.scheduledEmployee?.id) {
          this.context.args.deleteAssignment(
            this.context.args.selectedDeskResource.id,
            this.context.scheduledEmployee.id,
          );
        }
        deferred.resolve(true);
        this.context.metrics.trackEvent('Desk Assignment Deleted', {
          userId: this.context.state.currentUser?.id,
          deskId: this.context.args.selectedDeskResource?.id,
          employeeId: this.context.scheduledEmployee?.id ?? null,
        });
        void (this.context.args.modalLifecycleTask.last as MyTaskInstance).continue();
      };
      this.context.originalScheduledEmployee = this.context.scheduledEmployee;
      return yield deferred.promise;
    },
  };

  get isEmployeeSelectDisabled(): boolean {
    return this.args.onEmployeeFocusOutTask.isRunning || this.isUnassigning;
  }

  get existingDeskAssignee(): string | undefined {
    const assignedTo = this.args.selectedDeskResource.assignedTo;
    if (assignedTo) {
      return this.store.peekAll('employee').find((employee) => {
        return assignedTo === employee.email;
      })?.name;
    } else {
      return undefined;
    }
  }

  get cannotManageDesks(): boolean {
    return this.abilities.cannot('manage-desk desks');
  }

  clearEmployeeErrors(): void {
    this.scheduledEmployeeError = [];
  }

  get employeesToShow(): EmployeeModel[] {
    if (this.searchedEmployees.length) {
      return this.searchedEmployees;
    }
    return this.args.additionalDetails.employees;
  }

  @action
  removeOutsideClickHandler(): void {
    if (this.clickListener) {
      document.removeEventListener('click', this.clickListener);
      this.clickListener = null;
    }
  }

  @action
  registerOutsideClickHandler(): void {
    this.clickListener = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isInsideDateTrigger = !!target.closest('.date-trigger');
      const isOutsideCalendar = !target.closest('.dashboardCalendar');
      if (isOutsideCalendar && !isInsideDateTrigger) {
        // Close the calendar if click was outside and not on date-trigger
        this.toggleCalendar(false);
      }
    };
    document.addEventListener('click', this.clickListener);
  }
  get showingDateToString(): string {
    if (this.dateSelected) {
      return this.showingDate.toLocaleString('default', {
        timeZone: this.state.currentLocation.timezone,
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });
    } else {
      return 'Select a date';
    }
  }

  onScheduledEmployeeSearchTask = task({ restartable: true }, async (searchTerm: string) => {
    const extraFilters = {
      locations: this.state.currentLocation.id,
      deleted: false,
    };
    if (searchTerm) {
      this.searchedEmployees = await this.args.searchEmployeesTask.perform(searchTerm, extraFilters);
    }

    const validEmployee = this.searchedEmployees.find(
      (employee) => searchTerm.toLowerCase().trim() === employee.name?.toLowerCase(),
    );
    if (validEmployee) {
      this.clearEmployeeErrors();
    } else if (searchTerm === '') {
      this.searchedEmployees = [];
      this.scheduledEmployee = null;
      this.clearEmployeeErrors();
    } else {
      this.scheduledEmployeeError = ["Employee doesn't exist"];
    }
  });

  @action
  reset(): void {
    this.dateSelected = false;
    this.showCalendar = false;
    this.scheduledEmployee = null;
    this.originalScheduledEmployee = null;
    this.scheduledEmployeeError = [];
    this.showingDate = this.currentDateAtOfficeTimezone;
    this.calendarDate = this.currentDateAtOfficeTimezone;
  }

  @action
  toggleCalendar(value: boolean): void {
    this.showCalendar = value;
  }

  @action
  goBackOneMonth(): void {
    this.calendarDate = subMonths(this.calendarDate, 1);
    this.showCalendar = false;
  }

  @action
  goForwardOneMonth(): void {
    this.calendarDate = addMonths(this.calendarDate, 1);
    this.showCalendar = false;
  }

  @action
  selectDate(selectedDate: { unix: () => number }): void {
    const epochMilli = selectedDate.unix() * 1000;
    this.dateSelected = true;
    this.showingDate = new Date(epochMilli);
  }

  get dateLimits(): { startDate: number; endDate: number } {
    const limits: { startDate: number; endDate: number } = { startDate: 0, endDate: 0 };
    const locationDate = this.currentDateAtOfficeTimezone;
    limits.startDate = getUnixTime(addDays(locationDate, 1));
    limits.endDate = getUnixTime(addYears(locationDate, 2));
    return limits;
  }

  get isValidScheduledEmployeeSelected(): boolean {
    return !!(this.scheduledEmployee && this.scheduledEmployeeError.length === 0);
  }

  get isScheduleButtonDisabled(): boolean {
    return (!this.isValidScheduledEmployeeSelected && !this.isUnassigning) || !this.dateSelected;
  }

  @action
  onScheduledEmployeeSelect(option: EmployeeModel | 'loadMore'): void {
    if (option !== 'loadMore') {
      this.scheduledEmployee = option;
      this.scheduledEmployeeError = [];
    }
  }

  get showDeleteButton(): boolean {
    return this.args.includeDeleted === 'true';
  }

  @action
  deleteAssignment(): void {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    (this.processDeskAssignmentTask as unknown as Task<void, unknown[]>).last.delete();
  }

  get currentDateAtOfficeTimezone(): Date {
    const startDate = new Date();
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const officeTimeZone = this.state.currentLocation.timezone || 'UTC';
    const utcDate = fromZonedTime(startDate, userTimeZone);
    return toZonedTime(utcDate, officeTimeZone);
  }

  get currentMonth(): string {
    return format(startOfMonth(this.calendarDate), 'yyyy-MM-dd');
  }

  @action
  closeCalendar(): void {
    this.reset();
  }
}
