import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 * @param {String}            type
 * @param {String}            header
 */
export default class MessageBox extends Component {
  @tracked type;

  constructor() {
    super(...arguments);
    this.type = this.args.type ?? 'info';
  }
}
