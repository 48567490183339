import Route from '@ember/routing/route';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { getUnixTime, addSeconds, addMinutes, startOfDay } from 'date-fns';
import type AreaMapModel from 'garaje/models/area-map';
import type FloorModel from 'garaje/models/floor';
import type MapFloorModel from 'garaje/models/map-floor';
import type ReservationModel from 'garaje/models/reservation';
import type StateService from 'garaje/services/state';
import { SECONDS_IN_A_DAY } from 'garaje/utils/enums';

export default class MapsRoute extends Route {
  title = 'Maps · Envoy';

  @service declare state: StateService;
  @service declare store: Store;

  async model(): Promise<{ currentUserReservationMapFloor: MapFloorModel | null; areaMap: AreaMapModel | undefined }> {
    const { currentLocation } = this.state;
    const currentLocationId = currentLocation?.id;
    const areaMaps = await this.store.query('area-map', {
      filter: { location: currentLocationId },
      include: 'map-floors',
    });
    let areaMap: AreaMapModel | undefined = areaMaps.firstObject;

    // Create a new area map if one doesn't exist
    // This should be removed once the backend initializes db with area-map for locations with a desk subscription
    if (!areaMap) {
      areaMap = await this.store.createRecord('area-map', { entityId: currentLocationId }).save();
    }

    const startOfLocationDay = addMinutes(startOfDay(new Date()), this.state.minutesBetweenTimezones());
    const filter = {
      'location-id': currentLocationId,
      'user-id': this.state.currentUser?.id,
      'start-date': getUnixTime(startOfLocationDay),
      'end-date': getUnixTime(addSeconds(startOfLocationDay, SECONDS_IN_A_DAY - 1)),
    };
    const currentUserReservations = await this.store.query('reservation', {
      filter,
      include: 'desk',
    });
    let currentUserReservationMapFloor: MapFloorModel | null = null;
    if (currentUserReservations.firstObject) {
      const currentUserRes: ReservationModel = currentUserReservations.firstObject;
      const desk = await currentUserRes.desk;
      const currentUserReservationFloor: FloorModel = desk.floor;
      if (currentUserReservationFloor) {
        currentUserReservationMapFloor = this.store.peekRecord('map-floor', currentUserReservationFloor.id);
      }
    }
    return { areaMap, currentUserReservationMapFloor };
  }
}
