import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StatsigService from 'garaje/services/statsig';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';

export default class WelcomeChecklistComponent extends Component {
  @service declare flashMessages: FlashMessagesService;
  @service declare statsig: StatsigService;
  @service declare abilities: AbilitiesService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked isDesktop = window.innerWidth > 1100;

  constructor(owner: unknown, args: Record<string, unknown>) {
    super(owner, args);
    this.logStatsigEvent('workplace-welcome-page-viewed');
    // eslint-disable-next-line @typescript-eslint/unbound-method
    window.addEventListener('resize', this.updateIsDesktop);
  }

  shouldGoToOccupancyAnalytics(): boolean {
    return this.abilities.can('visit occupancy-analytic');
  }

  @action
  updateIsDesktop(): void {
    this.isDesktop = window.innerWidth > 1100;
  }

  @action
  logStatsigEvent(event_name: string): void {
    this.statsig.logEvent(event_name);
    this.workplaceMetrics.trackEvent(`WORKPLACE_SETUP_GUIDE_${event_name.toLocaleUpperCase()}`);
  }
}
