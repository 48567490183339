import { A } from '@ember/array';
import type ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type { TaskInstance } from 'ember-concurrency';
import { all, hash, restartableTask } from 'ember-concurrency';
import type AgreementModel from 'garaje/models/agreement';
import type ConfigModel from 'garaje/models/config';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type TenantModel from 'garaje/models/tenant';
import type UserModel from 'garaje/models/user';
import type ProtectedController from 'garaje/pods/protected/controller';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlowService from 'garaje/services/flow';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import type StateService from 'garaje/services/state';
import dirtyCheck from 'garaje/utils/decorators/dirty-check';
import { getStatusCode } from 'garaje/utils/parse-error';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { RecordArray } from 'garaje/utils/type-utils';

import type { VisitorsInvitesRouteModel } from '../route';

import type VisitorsInvitesShowController from './controller';

interface VisitorsInvitesShowRouteParams {
  invite_id: string;
}

export interface VisitorsInvitesShowRouteModel {
  agreements: ArrayProxy<AgreementModel> | undefined;
  blocklistContacts: ArrayProxy<UserModel>;
  config: ConfigModel;
  connectedTenants: RecordArray<TenantModel>;
  flows: FlowModel[];
  invite: InviteModel;
  location: LocationModel;
  signInFields: ArrayProxy<SignInFieldModel> | undefined;
}

@dirtyCheck
class VisitorsInvitesShowRoute extends Route {
  @service declare featureFlags: FeatureFlagsService;
  @service declare flow: FlowService;
  @service declare router: RouterService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare state: StateService;
  @service declare store: StoreService;

  // start: dirty-check overrides
  _dirtyObject = 'changeset';
  _dirtyAttr = 'isDirty';
  // end: dirty-check overrides

  model(params: VisitorsInvitesShowRouteParams): TaskInstance<VisitorsInvitesShowRouteModel | undefined> {
    return this.modelTask.perform(params);
  }

  setupController(
    controller: VisitorsInvitesShowController,
    model: VisitorsInvitesShowRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.setupChangeset();
  }

  modelTask = restartableTask(
    {
      cancelOn: 'deactivate',
    },
    async ({ invite_id }: VisitorsInvitesShowRouteParams) => {
      const location = this.state.currentLocation;
      const blocklistContacts = await location.blocklistContacts;
      // Preload custom fields for flows. Since we use the fields to
      // build the UI, we want to make sure the data is loaded before
      // rendering something.
      const { flows, connectedTenants } = <VisitorsInvitesRouteModel>this.modelFor('visitors.invites');

      // If no userDocumentTemplateConfigurations exist, then no need to fetch
      // Visitor Document records later
      const hasAnyUserDocumentTemplateConfigurations = A(flows).any(
        (f) => !!f.activeUserDocumentTemplateConfigurations.length,
      );

      // TODO: @heroiceric
      // This ensures we only reload config if it was already loaded. If we try
      // to reload when the config has not been previously loaded, we get an
      // error.
      //
      // I think this problem should go away when we're able to upgrade to newer
      // versions of ember data.
      if (location.belongsTo('config').value()) {
        await location.belongsTo('config').reload();
      }

      const inviteInclude = [
        'agreeable-ndas',
        'agreeable-ndas.agreement',
        'parent-invite-context',
        'child-invite-locations',
      ];

      if (connectedTenants.length) inviteInclude.push('multi-tenancy-visitor-notification-logs');

      if (this.state.features?.canAccessMultipleHosts) {
        inviteInclude.push('additional-hosts');
      }

      let invite;

      try {
        invite = await this.store.findRecord('invite', invite_id, { reload: true, include: inviteInclude.join() });

        await location.matchupInviteOrEntryFieldsWithLocationConfig(invite);
      } catch (e) {
        if (getStatusCode(e) === 410) {
          this.router.transitionTo('visitors.invites.deleted');

          return;
        } else {
          throw e;
        }
      }

      const config = await location.config;

      const inviteLocation = await invite?.location;
      if (inviteLocation) {
        this.maybeSwitchCurrentLocation(location, inviteLocation);
      }

      this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
      await this.flow.initService();

      let agreements: ArrayProxy<AgreementModel> | undefined;
      let signInFields: ArrayProxy<SignInFieldModel> | undefined;

      // [SQ-5019]
      // V2 endpoint includes associated IDs only. Now, fetch actual records.
      const childInviteLocations = invite.hasMany('childInviteLocations').ids();
      const additionalHosts = invite.hasMany('additionalHosts').ids();

      await all([
        ...childInviteLocations.map((relId) => this.store.findRecord('location', relId)),
        ...additionalHosts.map((relId) => this.store.findRecord('employee', relId)),
      ]);

      if (invite.belongsTo('flow').id()) {
        let flow: FlowModel | undefined;
        try {
          flow = await invite.flow;
        } catch (_error) {
          // no op if the flow is not found so we don't break the page
        }
        if (flow) {
          const agreementPage = await flow.agreementPage;
          if (agreementPage && agreementPage.enabled) {
            agreements = await agreementPage.agreements;
          }

          const signInFieldPage = await flow.signInFieldPage;
          if (signInFieldPage) {
            signInFields = await signInFieldPage.signInFields;
          }

          if (flow.isProtect) {
            await this.flow.getFlowWithIncludes(flow.id);
          }

          // Fetch associated Visitor Documents only if:
          //   1. There are any relevant UserDocumentTemplateConfigurations
          //   2. The Visitor Document feature flag is enabled
          if (hasAnyUserDocumentTemplateConfigurations) {
            try {
              invite.visitorDocuments = await this.store.query('visitor-document', {
                include:
                  'user-document-template,user-document-attachments,user-document-attachments.user-document-template-attachment',
                filter: { 'invite-id': invite.id },
              });
            } catch (_) {
              // UserDocumentTemplateConfigurations will load for everyone until
              // [BE] Jira task https://envoycom.atlassian.net/browse/VIS-4390 is completed.
              // For now, don't bomb out if the visitor-document request fails.
            }
          }
        }
      }

      if (isBlank(invite.additionalGuests)) {
        invite.additionalGuests = 0;
      }

      return hash({
        agreements,
        blocklistContacts,
        config,
        connectedTenants,
        flows,
        invite,
        location,
        signInFields,
      });
    },
  );

  maybeSwitchCurrentLocation(currentLocation: LocationModel, inviteLocation: LocationModel): void {
    if (currentLocation.id !== inviteLocation.id) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      const protectedController = <ProtectedController>this.controllerFor('protected');
      protectedController.send('switchLocation', inviteLocation.id);
    }
  }
}

export default VisitorsInvitesShowRoute;
