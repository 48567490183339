import Route from '@ember/routing/route';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class MyLocationsRoute extends Route {
  @service skinnyLocations;

  model() {
    return this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }
}
