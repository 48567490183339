import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';
import serializer from 'garaje/utils/decorators/serializer';

import type Company from './company';

@adapter('rooms-v1')
@serializer('rooms')
class Connection extends Model {
  @attr('string') declare code: string;
  @attr('string') declare email: string;
  @attr('string') declare provider: string;
  @attr('string') declare tenant: string;

  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<Company>;
}

export default Connection;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    connection: Connection;
  }
}
