import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { next } from '@ember/runloop';

export default class SlideToggleComponent extends Component {
  @tracked element;

  @action
  saveElementReference(element) {
    this.element = element;
  }

  @action
  handleKeyUp(event) {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
      event.stopImmediatePropagation();
      const input = this.element;
      const toggled = !input.checked;
      this.args.onClick(toggled);
    }
  }

  @action
  toggle(event) {
    event.preventDefault();
    next(() => this.args.onClick(!this.args.isChecked));
  }
}
