import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import type FlashMessagesService from 'garaje/services/flash-messages';

/**
 * params: currentLocation
 */
export default class SettingsBinaryDownloadComponent extends Component {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
}
