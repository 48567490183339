import Model, { belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type ScimIntegrationModel from 'garaje/models/scim-integration';
import type ScimPrimaryLocationMappingModel from 'garaje/models/scim-primary-location-mapping';

class ScimPrimaryLocationConfigurationModel extends Model {
  @belongsTo('scim-integration', { async: true }) declare scimIntegration:
    | AsyncBelongsTo<ScimIntegrationModel>
    | ScimIntegrationModel;
  @hasMany('scim-primary-location-mapping', { async: true })
  declare scimPrimaryLocationMappings: AsyncHasMany<ScimPrimaryLocationMappingModel>;
}

export default ScimPrimaryLocationConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'scim-primary-location-configuration': ScimPrimaryLocationConfigurationModel;
  }
}
