import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import type UserModel from 'garaje/models/user';
import type CurrentAdminService from 'garaje/services/current-admin';
import { routeEvent } from 'garaje/utils/decorators/route';

import type ProfileIndexController from './controller';

export default class ProfileIndexRoute extends Route {
  declare controller: ProfileIndexController;

  @service declare currentAdmin: CurrentAdminService;

  model(): UserModel {
    return this.currentAdmin.user;
  }

  setupController(controller: ProfileIndexController, model: UserModel, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller._buildChangeset(model);
  }

  @routeEvent
  routeWillChange(transition: Transition): Transition | boolean {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const isDirty = this.controller.changeset.isDirty || !this.controller.changeset.isValid;

    if (isDirty && !window.confirm('You will lose any unsaved changes. Are you sure you want to continue?')) {
      return transition.abort();
    } else {
      next(this, function () {
        // eslint-disable-next-line ember/no-controller-access-in-routes
        this.controller.resetProperties();
      });
      return true;
    }
  }
}
