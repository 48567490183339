import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { toUp, toDown } from 'ember-animated/transitions/move-over';

export default class LocationOverviewItem extends Component<{
  value: number;
  valueChange: number;
  changeType: string;
  prefix: string;
  suffix: string;
  type: string;
  productRoute: string;
  percentage: boolean | undefined;
  logEvent: (params: unknown) => void;
}> {
  @service declare router: RouterService;

  rules({ oldItems, newItems }: { oldItems: unknown[]; newItems: unknown[] }): unknown {
    return oldItems[0]! < newItems[0]! ? toDown : toUp;
  }

  @action
  goToProductRoute(): void {
    const event = this.args.productRoute.replace('.', '_');
    this.args.logEvent(`homepage_stats_${event}_clicked`);
    void this.router.transitionTo(this.args.productRoute);
  }
}
