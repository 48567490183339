import Controller from '@ember/controller';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { type PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type TenantModel from 'garaje/models/tenant';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { alias } from 'macro-decorators';

import { type TenantRouteListModel } from './route';

export default class PropertySettingsTenantsListController extends Controller {
  @service declare flashMessages: FlashMessagesService;

  declare model: TenantRouteListModel;

  queryParams = ['search'];

  @tracked search = '';
  @tracked tenantToDisconnect: TenantModel | null = null;
  @tracked tenantToRegenerate: TenantModel | null = null;
  @tracked tenantToInvite: TenantModel | null = null;
  @tracked tenantToDelete: TenantModel | null = null;
  @tracked loadingData = false;

  @alias('model.loadTenantsTask.lastSuccessful.value') tenants!: PaginatedRecordArray<TenantModel>;

  @tracked total = 0;

  get actionableTenant(): TenantModel | null {
    return this.tenantToRegenerate ?? this.tenantToInvite;
  }

  doSearch(search: string): void {
    this.search = search;
  }

  @action
  onSearchInput(event: InputEvent): void {
    if (event?.target instanceof HTMLInputElement) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      debounce(this, this.doSearch, event.target.value, 200);
    }
  }

  @action
  confirmDeleteTenant(tenant: TenantModel): void {
    this.tenantToDelete = tenant;
  }

  @action
  cancelDeleteTenant(): void {
    this.tenantToDelete = null;
  }

  deleteTenantTask = dropTask(async () => {
    if (!this.tenantToDelete) return;

    try {
      await this.tenantToDelete.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Tenant deleted.');

      this.total -= 1;
      this.tenants.removeObject(this.tenantToDelete);

      this.tenantToDelete = null;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });

  @action
  confirmDisconnectTenant(tenant: TenantModel): void {
    this.tenantToDisconnect = tenant;
  }

  @action
  cancelDisconnectTenant(): void {
    this.tenantToDisconnect = null;
  }

  @action
  cancelActionableTenant(): void {
    this.tenantToRegenerate = null;
    this.tenantToInvite = null;
  }

  @action
  saveActionableTenant(): void {
    this.model.loadTenantsTask.perform(this.model.property).catch(throwUnlessTaskDidCancel);
  }

  disconnectTenantTask = dropTask(
    waitFor(async () => {
      try {
        await this.tenantToDisconnect?.disconnect();
        await this.tenantToDisconnect?.reload();

        this.flashMessages.showAndHideFlash('success', 'Tenant disconnected.');
        this.tenantToDisconnect = null;
      } catch (e) {
        this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      }
    }),
  );
}
