import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get, set, setProperties } from '@ember/object';
import { alias, equal } from 'macro-decorators';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';
import { v1 as uuid } from 'uuid';
import { WORKPLACE_SEAT_MINIMUM } from 'garaje/utils/plan-details';

export default class BillingIndexConfirmPurchaseController extends Controller {
  @service router;
  @service flashMessages;
  @service store;
  @service state;

  @tracked lastValidCouponCode = null;
  @tracked isEditingCC = false;
  @tracked showActivateDesksModal = false;
  // Disable the pay button until the seat requirement for workplace premium plus is met
  @tracked isPaymentDisabledMinSeats = false;

  @alias('model.subscription.isDesks') isDesks;
  @equal('model.subscription.app', 'empxp') isWorkplace;

  minWorkplaceSeatNumber = WORKPLACE_SEAT_MINIMUM;

  get isVrSubscribed() {
    return this.model.productIntent === 'vr' && this.model.subscription.isSubscribed;
  }

  get product() {
    if (this.model.productIntent === 'vr') {
      return 'visitors';
    }

    return this.model.productIntent;
  }

  @dropTask
  *applyCouponCode() {
    const couponRedemption = this.store.createRecord('coupon-redemption', {
      id: uuid(),
      code: this.lastValidCoupon,
      subscription: this.model.subscription,
    });

    try {
      yield couponRedemption.save();
    } catch (_error) {
      const errorMessage = couponRedemption.errors.messages[0] || 'Referral code could not be applied';
      couponRedemption.destroyRecord();
      this.flashMessages.showAndHideFlash('error', errorMessage);
    }
  }

  @dropTask
  *purchaseTask() {
    const subscription = this.model.subscription;
    const period = get(subscription, 'period');
    const quantity = get(this.model, 'quantity');

    try {
      if (subscription.isBasicPlan && subscription.isDeliveries) {
        setProperties(subscription, {
          period,
          plan: 'standard',
          couponCode: this.lastValidCouponCode,
        });

        yield subscription.save();
      } else if (this.isDesks) {
        if (this.model.paymentSource.exists) {
          setProperties(subscription, {
            period,
            plan: 'standard',
            quantity,
            couponCode: this.lastValidCouponCode,
          });

          yield subscription.save();
        } else {
          this.isEditingCC = true;
          return;
        }
      } else {
        yield subscription.purchase({
          period: period,
          quantity: quantity,
          coupon: this.lastValidCouponCode,
        });
      }

      // Reload state service with the new subscription added
      yield this.state.initSubscriptionStateTask.perform();

      this.flashMessages.showAndHideFlash('success', 'Thanks!');

      if (this.isDesks && this.model.activeUnits > 0 && this.model.quantity * 25 > this.model.activeUnits) {
        this.isEditingCC = false;
        this.showActivateDesksModal = true;
      } else {
        this.router.transitionTo('billing');
      }
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  transitionToBilling() {
    this.router.transitionTo('billing');
  }
  @action
  transitionToMaps() {
    this.router.transitionTo('spaces.maps');
  }
  @action
  setLastValidCouponCode(couponCode) {
    this.lastValidCouponCode = couponCode;
  }
  @action
  setNewPeriod(newPeriod) {
    set(this.model, 'subscription.period', newPeriod);
  }
  @action
  setQuantity(quantity) {
    const oldQuantity = this.model.quantity;

    // Prevent a null or negative quantity from causing an error in the subscription-estimate call
    if (!quantity || isNaN(quantity) || quantity < 0) {
      set(this.model, 'quantity', oldQuantity);
      return;
    }

    if (this.isWorkplace) {
      if (quantity < WORKPLACE_SEAT_MINIMUM) {
        this.isPaymentDisabledMinSeats = true;
      } else {
        this.isPaymentDisabledMinSeats = false;
      }
    }

    set(this.model, 'quantity', quantity);
  }
}
