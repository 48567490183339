import type { AsyncHasMany, AsyncBelongsTo } from '@ember-data/model';
import Model, { belongsTo, hasMany } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

class ParentInviteContextModel extends Model {
  @belongsTo('location', { async: true, inverse: null }) declare parentInviteLocation: AsyncBelongsTo<LocationModel>;
  @hasMany('location', { async: true, inverse: null }) declare childInviteLocations: AsyncHasMany<LocationModel>;
}

export default ParentInviteContextModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'parent-invite-context': ParentInviteContextModel;
  }
}
