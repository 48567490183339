import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, type Task } from 'ember-concurrency';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import type { Workbook, ParentWorkbook, WorkbookScopeType, WorkbooksResponse } from 'garaje/utils/workbooks';

interface CreateWorkbookDialogArgs {
  closeDialog: () => void;
  getWorkbooks: Task<void, []>;
  scope: WorkbookScopeType;
}

export default class CreateWorkbookDialogComponent extends Component<CreateWorkbookDialogArgs> {
  @service declare ajaxFetch: AjaxFetchService;
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;

  @tracked name: string = '';
  @tracked parent: ParentWorkbook | undefined = undefined;
  @tracked description: string = '';
  @tracked errors = {
    name: false,
    parent: false,
    description: false,
  };
  @tracked allWorkbooks: Array<Workbook> = [];

  constructor(owner: unknown, args: CreateWorkbookDialogArgs) {
    super(owner, args);

    void this.getAllWorkbooks.perform();
  }

  get isLoading(): boolean {
    return this.getAllWorkbooks.isRunning;
  }

  get workbookOptions(): Array<ParentWorkbook> {
    return this.allWorkbooks.map((workbook: Workbook) => {
      return {
        id: workbook.id,
        name: workbook.name,
        description: workbook.description,
      };
    });
  }

  getAllWorkbooks = dropTask(async () => {
    try {
      const scope = this.args.scope;

      const url = urlBuilder.analytics.getAllWorkbooksUrl(this.state.currentCompany.id, scope);
      const options = {
        type: 'GET',
        headers: { Accept: 'application/json' },
      };

      const response: WorkbooksResponse = await this.ajaxFetch.request(url, options);

      this.allWorkbooks = response.content;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  createWorkbook = dropTask(async () => {
    try {
      const {
        currentCompany: { id: companyId },
      } = this.state;

      this.errors = {
        name: isEmpty(this.name),
        parent: isEmpty(this.parent),
        description: isEmpty(this.description),
      };

      if (this.errors.name || this.errors.parent || this.errors.description) {
        return;
      }

      const url = urlBuilder.analytics.getCreateWorkbookUrl(companyId);
      const options = {
        type: 'POST',
        headers: { Accept: 'application/json' },
        contentType: 'application/json',
        data: {
          name: this.name,
          description: this.description,
          parent: this.parent?.id,
        },
      };

      await this.ajaxFetch.request(url, options);

      await this.args.getWorkbooks.perform();

      this.args.closeDialog();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });
}
