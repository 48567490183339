import Controller from '@ember/controller';
import config from 'garaje/config/environment';
import { service } from '@ember/service';
import { get, action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AppStoreController extends Controller {
  @service currentLocation;
  @service embeddedApp;
  @service flashMessages;
  @service localStorage;
  @service state;
  @service workflows;

  @tracked loginRequired = false;

  constructor() {
    super(...arguments);
    this.embeddedApp.subscribe(this.handleMessage);
  }

  get iframeUrl() {
    return `${config.appStoreHost}/entrypoint?loginRequired=${this.loginRequired}`;
  }

  get context() {
    return {
      currentLocation: {
        id: get(this.currentLocation, 'id'),
        employeeScreeningEnabled: get(this.currentLocation, 'employeeScreeningEnabled'),
        employeeScreeningFlow: get(this.currentLocation, 'employeeScreeningFlow')
          ? { employeeScreeningRequired: get(this.currentLocation, 'employeeScreeningFlow.employeeScreeningRequired') }
          : null,
      },
      adhocPlugins: this.state.currentCompany.adhocPlugins,
      billingTier: get(this, 'state.vrSubscription.plan'),
      zoneId: this.model.currentZone?.id,
    };
  }

  @action
  handleMessage(message) {
    if (message.event === 'showToaster') {
      const type = message.type;
      const msg = message.msg;
      this.flashMessages.showAndHideFlash(type, msg);
    }
    if (message.event == 'scrollTo') {
      window.scrollTo({
        top: message.y,
        behavior: 'smooth',
      });
    }
    if (message.event === 'workflowsAuth') {
      this.workflowsAuth(message.pieceName, message.connectionName, message.token);
    }
  }
  @action
  trackLoggedIn() {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'as_logged_in'
     * On Login -> as_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set as_logged_in = true in localstorage
     * On Logout -> Remove as_logged_in
     */

    const asLoggedIn = this.localStorage.getItem('as_logged_in');

    if (!asLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('as_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }

  @action
  async workflowsAuth(pieceName, connectionName, token) {
    const workflowsAuthResponse = await this.workflows.connect({
      pieceName: pieceName,
      connectionName: connectionName,
      token: token,
    });

    this.embeddedApp.sendMessage('app-store', {
      event: 'workflowsAuthResponse',
      data: workflowsAuthResponse,
    });
  }
}
