import Component from '@glimmer/component';
import type { VfdContactMethodTeamsMetadata } from 'garaje/models/vfd-contact-method';

type VirtualFrontDeskNotificationContactDropdownContactMethodTeamsChannelComponentSignature = {
  Args: {
    channel: VfdContactMethodTeamsMetadata | null;
  };
};

export default class VirtualFrontDeskNotificationContactDropdownContactMethodTeamsChannelComponent extends Component<VirtualFrontDeskNotificationContactDropdownContactMethodTeamsChannelComponentSignature> {
  get channelName(): string | null {
    if (this.args.channel) {
      return '#' + this.args.channel['channel-name'];
    }
    return null;
  }
}
