import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class DashboardRoute extends Route {
  @service currentAdmin;
  @service state;
  @service abilities;
  @service router;

  beforeModel(transition) {
    if (!this.abilities.can('visit admin-dashboard')) {
      this._redirectToDefaultDashboardRoute(transition);
    }
  }

  _redirectToDefaultDashboardRoute(transition) {
    // There is a possibility that a previous route will trigger a redirect that happens in a promise.
    // So we manually check that the current transition is not aborted before
    // issuing potentially conflicting redirects
    if (transition.isAborted !== true) {
      const { preRegistrationEnabled } = this.state.currentLocation;
      if (this.currentAdmin.isEmployee && this.abilities.can('visit employee-schedule')) {
        this.router.transitionTo('schedule');
      } else if (!this.currentAdmin.isAdminLike && preRegistrationEnabled) {
        this.router.transitionTo('visitors.invites');
      } else {
        this.router.transitionTo('visitors.entries');
      }
    }
  }
}
