import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import fade from 'ember-animated/transitions/fade';

/**
 * @param {String}  title - Section title. Clickable to expand/collapse by default. For basic
 *                          title, use <:title> named block and pass in your own header
 * @param {Boolean} [isOpen=true] - Control section expanded or collapsed state externally
 * @param {Array}   items - Array to yield in <:items>. Each item will be pre-wrapped in a WellItem
 */
export default class WellSection extends Component {
  @tracked _isOpen = true;

  transition = fade;

  get isOpen() {
    return this.args.isOpen ?? this._isOpen;
  }

  @action
  toggleSection() {
    this._isOpen = !this.isOpen;
  }
}
