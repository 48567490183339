import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type DetailedChangeset } from 'ember-changeset/types';
import { task, dropTask } from 'ember-concurrency';
import ticketCategoryEmailChangeset from 'garaje/changesets/ticket-category-email';
import type LocationModel from 'garaje/models/location';
import type TicketCategoryModel from 'garaje/models/ticket-category';
import type TicketCategoryEmailModel from 'garaje/models/ticket-category-email';
import type TicketConfigurationModel from 'garaje/models/ticket-configuration';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type StoreService from 'garaje/services/store';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import isValidUrl from 'garaje/utils/is-valid-url';
import { all, defer } from 'rsvp';

interface RoomServiceRequestComponentArgs {
  location: LocationModel;
}

type TicketCategoryEmailChangeset = DetailedChangeset<TicketCategoryEmailModel>;

export default class RoomServiceRequestComponent extends Component<RoomServiceRequestComponentArgs> {
  @service declare roombaGraphql: RoombaGraphqlService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;

  @tracked isOpen = false;
  @tracked ticketConfiguration: TicketConfigurationModel | undefined;
  @tracked ticketCategoryEmailChangesets: Array<TicketCategoryEmailChangeset> = [];
  @tracked newTicketCategoryEmailChangesets: Array<TicketCategoryEmailChangeset> = [];
  @tracked documentationLink = '';
  @tracked urlErrors: Array<string> = [];

  get saveButtonEnabled(): boolean {
    const allValid = this.allEmailChangesets.every((changeset) => changeset.isValid);
    const anyDirtyOrDeletedEmails = this.allEmailChangesets.some(
      (changeset) => changeset.isDirty || changeset.data.isDeleted,
    );
    const anyDirtyOrDeletedFields = anyDirtyOrDeletedEmails || (this.ticketConfiguration?.hasDirtyAttributes ?? false);
    return allValid && anyDirtyOrDeletedFields && this.urlErrors.length == 0;
  }

  get allEmailChangesets(): Array<TicketCategoryEmailChangeset> {
    return [...this.ticketCategoryEmailChangesets, ...this.newTicketCategoryEmailChangesets];
  }

  get ticketCategories(): Array<TicketCategoryModel> | undefined {
    return this.ticketConfiguration?.ticketCategories.filter((category) => !category.isNew);
  }

  @action
  onDeleteTicketCategory(changesetToDelete: TicketCategoryEmailChangeset): void {
    // @ts-ignore for some reason the linter thinks isNew is a function
    if (changesetToDelete.data.isNew) {
      this.newTicketCategoryEmailChangesets = this.newTicketCategoryEmailChangesets.filter(
        (changeset) => changeset !== changesetToDelete,
      );
    } else {
      changesetToDelete.data.deleteRecord();
    }
  }

  @action
  addAnotherEmail(): void {
    const newTicketEmail = this.store.createRecord('ticket-category-email', {
      ticketConfiguration: this.ticketConfiguration,
    });
    this.newTicketCategoryEmailChangesets = [
      ...this.newTicketCategoryEmailChangesets,
      ticketCategoryEmailChangeset(newTicketEmail),
    ];
  }

  @action
  didInsertPanel(): void {
    void this.loadTicketingConfigurationTask.perform();
  }

  @action
  didInsertContent(): void {
    if (this.ticketConfiguration) {
      this.ticketCategoryEmailChangesets = this.ticketConfiguration.ticketCategoryEmails
        .toArray()
        .map((ticketEmail) => {
          return ticketCategoryEmailChangeset(ticketEmail);
        });
      if (this.ticketCategoryEmailChangesets.length === 0) {
        this.addAnotherEmail();
      }
    }
  }

  @action
  willDestroyContent(): void {
    this.ticketCategoryEmailChangesets = [];
    this.newTicketCategoryEmailChangesets = [];
    this.ticketConfiguration?.ticketCategoryEmails.toArray().forEach((ticketEmail) => {
      // @ts-ignore for some reason the linter thinks isNew is a function
      if (ticketEmail.isNew) {
        this.store.unloadRecord(ticketEmail);
      }
    });
  }

  @action
  toggleOpen(): void {
    this.isOpen = !this.isOpen;
  }

  @action
  edit(): void {
    if (!this.ticketConfiguration || !this.ticketConfiguration.enabled) {
      void this.enableTask.perform();
    } else {
      this.toggleOpen();
    }
  }

  @action
  updateDocumentationLink(value: string): void {
    this.documentationLink = value;

    if (!value || isValidUrl(value)) {
      this.urlErrors = [];
      if (this.ticketConfiguration) {
        this.ticketConfiguration.serviceRequestUrl = value;
      }
    } else {
      this.urlErrors = ['Please enter a valid URL'];
    }
  }

  deleteTicketCategoryTask = task({ drop: true }, async (ticketCategory: TicketCategoryModel) => {
    try {
      const location = this.args.location;
      await ticketCategory.destroyRecord();
      this.flashMessages.showAndHideFlash('success', 'Success!', `"${ticketCategory.name}" category was deleted`);

      this.metrics.trackEvent('Ticketing category deleted', {
        locationId: location.id,
      });
    } catch (error) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(error));
    }
  });

  saveEmailsTask = task({ drop: true }, async () => {
    try {
      const changesetsToSave = this.allEmailChangesets.filter(
        (changeset) => changeset.isDirty || changeset.data.isDeleted,
      );

      await all(changesetsToSave.map((changeset) => changeset.save()));
      await this.ticketConfiguration?.save();

      this.flashMessages.showAndHideFlash('success', 'Success!');

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  });

  enableTask = task({ drop: true }, async () => {
    try {
      const location = this.args.location;
      this.ticketConfiguration =
        this.ticketConfiguration ||
        this.store.createRecord('ticket-configuration', {
          location,
        });
      this.ticketConfiguration.enabled = true;
      await this.ticketConfiguration.save();
      this.flashMessages.showAndHideFlash('success', 'Ticketing enabled successfully!');

      this.metrics.trackEvent('Ticketing enabled', {
        locationId: location.id,
      });

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  });

  disableTask = task({ drop: true }, async () => {
    try {
      const location = this.args.location;

      if (this.ticketConfiguration) {
        this.ticketConfiguration.enabled = false;
        await this.ticketConfiguration.save();
      }

      this.flashMessages.showAndHideFlash('success', 'Ticketing disabled!');

      this.metrics.trackEvent('Ticketing disabled', {
        locationId: location.id,
      });

      this.toggleOpen();
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  });

  loadTicketingConfigurationTask = task({ drop: true }, async () => {
    const locationId = this.args.location.id;
    const roomConfigType = 'RoomTicketConfiguration';
    const filter = { location: locationId, type: roomConfigType };
    try {
      const response = await this.store.query('ticket-configuration', {
        filter,
        include: 'ticket-categories,ticket-category-emails.ticket-category',
      });
      this.ticketConfiguration = response.firstObject;
      this.documentationLink = this.ticketConfiguration?.serviceRequestUrl || '';
    } catch (e) {
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  });

  @dropTask
  confirmTicketDeletionModalTask: {
    perform(category: TicketCategoryModel): Generator<Promise<unknown>, void, void>;
  } = {
    *perform(
      this: {
        context: RoomServiceRequestComponent;
        abort?: () => void;
        continue?: () => Promise<void>;
      },
      category: TicketCategoryModel,
    ): Generator<Promise<unknown>, void, void> {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        let { ticketCategoryEmailChangesets, newTicketCategoryEmailChangesets } = this.context;
        const { deleteTicketCategoryTask } = this.context;
        // Remove the category from the list of ticket categories
        const changesetsToDelete = ticketCategoryEmailChangesets.filter(
          (changeset) => changeset.ticketCategory?.id === category.id,
        );

        const newChangesetsToDelete = newTicketCategoryEmailChangesets.filter(
          (changeset) => changeset.ticketCategory?.id === category.id,
        );

        await deleteTicketCategoryTask.perform(category);

        changesetsToDelete.forEach((changesetToDelete) => {
          ticketCategoryEmailChangesets = ticketCategoryEmailChangesets.filter(
            (changeset) => changeset !== changesetToDelete,
          );
        });
        newChangesetsToDelete.forEach((newChangesetToDelete) => {
          newTicketCategoryEmailChangesets = newTicketCategoryEmailChangesets.filter(
            (changeset) => changeset !== newChangesetToDelete,
          );
        });

        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };

  @dropTask
  showCreateTicketModalTask = {
    *perform(this: {
      context: RoomServiceRequestComponent;
      abort?: () => void;
      continue?: () => void;
    }): Generator<Promise<unknown>, void, void> {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        deferred.resolve(true);
      };

      return yield deferred.promise;
    },
  };
}
