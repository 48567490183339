import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { addDays } from 'date-fns';
import { alias, or } from 'macro-decorators';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

const TRIAL_EXTENSION_DAYS = 14;

export default class GrowthVisitorsBasicToPremiumTrialBanner extends Component {
  @service impressions;
  @service state;
  @service flashMessages;
  @service bannerManager;
  @service currentAdmin;

  @alias('state.vrSubscription') subscription;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') isAdmin;

  get canStartTrial() {
    return this.isAdmin && this.subscription.timesTrialExtended === 0;
  }

  @dropTask
  *startPremiumTrialTask() {
    if (!this.canStartTrial) return;

    this.subscription.trialEndDate = addDays(new Date(), TRIAL_EXTENSION_DAYS);

    try {
      yield this.subscription.save();
      yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VISITORS_BASIC_TO_PREMIUM_TRIAL);
      this.flashMessages.showAndHideFlash('success', 'Premium trial started!');
      this.bannerManager.growthBanner = null;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      this.subscription.rollbackAttributes();
    }
  }
}
