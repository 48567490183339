import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import type ScimIntegrationModel from 'garaje/models/scim-integration';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

interface ConfirmRegenerateDialogArgs {
  model: ScimIntegrationModel;
  closeDialog: () => void;
  formattedProvider: string;
}

export default class ConfirmRegenerateDialog extends Component<ConfirmRegenerateDialogArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare store: StoreService;

  regenerateToken = task({ drop: true }, async () => {
    try {
      await this.args.model.regenerateToken().then((data) => {
        return this.store.push(this.store.normalize('scim-integration', data.data));
      });
      this.args.closeDialog();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
