import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { DetailedChangeset } from 'ember-changeset/types';
import type EntryModel from 'garaje/models/entry';
import type InviteModel from 'garaje/models/invite';
import type SignInFieldModel from 'garaje/models/sign-in-field';

interface EntriesNotAssignableFlowFieldsComponentSignature {
  Args: {
    model: EntryModel | InviteModel;
    userDataChangeset: Array<DetailedChangeset<SignInFieldModel>>;
    signInFields?: SignInFieldModel[];
    handleUpdate: () => void;
  };
}

export default class EntriesNotAssignableFlowFieldsComponent extends Component<EntriesNotAssignableFlowFieldsComponentSignature> {
  get mappedSignInFields(): Record<string, unknown> {
    return (this.args.signInFields ?? []).reduce<Record<string, unknown>>((map, field) => {
      map[field.name] = field.value;
      return map;
    }, {});
  }

  get renderableFields(): Array<DetailedChangeset<SignInFieldModel>> {
    const mappedSignInFields = this.mappedSignInFields;

    return this.args.userDataChangeset.filter(
      (fieldChangeset) =>
        <boolean>(<unknown>fieldChangeset.isLoaded) &&
        (fieldChangeset.isCustom || mappedSignInFields[fieldChangeset.name]),
    );
  }

  @action
  onUpdate(): void {
    if (this.args.handleUpdate) {
      this.args.handleUpdate();
    }
  }
}
