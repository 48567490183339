import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AccessEventModel from 'garaje/models/access-event';
import type ChameleonService from 'garaje/services/chameleon';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { hash } from 'rsvp';

import type WorkplaceAccessEventsController from './controller';

export interface WorkplaceAccessEventsRouteParams {
  startDate: string | null;
  endDate: string | null;
  date: string | null;
  query: string;
  filter: string;
  refresh?: string;
  sort: string;
}

// TODO: Update this
export interface WorkplaceAccessEventsRouteModel {
  currentLocationId: string;
  date: string | null;
  accessEvents: AccessEventModel[];
  filter: string;
  query: string;
  sort: string;
}

export default class WorkplaceAccessEventsRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare chameleon: ChameleonService;

  titleToken = 'Access Events';

  memoizedModel: WorkplaceAccessEventsRouteModel | null = null;

  beforeModel(): void {
    if (this.abilities.cannot('visit workplace')) {
      this.router.transitionTo('unauthorized');
    }
    // Set all query params to null
    const queryParams = {
      date: null,
      startDate: null,
      endDate: null,
    };
    void this.router.transitionTo('workplace.access-events', { queryParams });
  }

  // TODO: add additional field to route params
  async model({
    startDate,
    endDate,
    date,
    query,
    filter,
    sort,
  }: WorkplaceAccessEventsRouteParams): Promise<WorkplaceAccessEventsRouteModel> {
    const { currentLocation } = this.state;
    const accessEvents: AccessEventModel[] = [];

    if (query && this.memoizedModel && this.memoizedModel.currentLocationId === currentLocation.id) {
      return Object.assign(this.memoizedModel, { startDate, endDate, date, query, filter, sort, accessEvents });
    }

    const currentLocationId = currentLocation.id;

    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_VIEWED');
    this.chameleon.trackDashboardPageEvent('ACCESS_EVENT_LOG_VIEWED');

    return hash({
      currentLocationId,
      startDate,
      endDate,
      date,
      accessEvents,
      filter,
      query,
      sort,
    });
  }

  async afterModel(model: WorkplaceAccessEventsRouteModel): Promise<void> {
    this.memoizedModel = model;
    // eslint-disable-next-line ember/no-controller-access-in-routes
    const controller = <WorkplaceAccessEventsController>this.controllerFor('workplace.access-events');

    await controller.loadPluginStatuses.perform().catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Error loading plugin statuses', e);
      // Return null to prevent this promise from failing
      return null;
    });
  }

  setupController(
    controller: WorkplaceAccessEventsController,
    model: WorkplaceAccessEventsRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);

    controller.startDate = null;
    controller.endDate = null;
    controller.startTime = null;
    controller.endTime = null;

    controller.setStartAndEndTime();
    controller.clearSearch();
    controller.date = '';
  }

  resetController(controller: WorkplaceAccessEventsController, isExiting: boolean): void {
    if (isExiting) {
      controller.clearSearch();
      controller.startDate = null;
      controller.endDate = null;
      controller.date = '';
    }
  }
}
