import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type StateService from 'garaje/services/state';
import { WorkbookScopeType } from 'garaje/utils/workbooks';

export default class AnalyticsCustomDashboardsController extends Controller {
  @service declare state: StateService;

  queryParams = ['page'];
  scope = WorkbookScopeType.LOCATION;

  @tracked page = 1;

  get locationId(): string {
    return this.state.currentLocation.id;
  }
}
