import ApplicationSerializer from './application';

export default class TakeoverSerializer extends ApplicationSerializer {
  attrs = {
    startAt: {
      serialize: false,
    },
    metadata: {
      serialize: false,
    },
  };
}
