import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type AreaMapModel from 'garaje/models/area-map';
import type AjaxService from 'garaje/services/ajax';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type { AssignmentCountsPerRole } from 'garaje/utils/custom-roles';
export default class MapVersionsService extends Service {
  @service declare store: Store;
  @service declare metrics: MetricsService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare ajax: AjaxService;

  @tracked assignmentCountsPerRole: AssignmentCountsPerRole = {};

  snapshotTask = task({ drop: true }, async (source: string, areaMap: AreaMapModel) => {
    if (!this.featureFlags.isEnabled('map-version-history')) {
      return;
    }

    const mapVersion = this.store.createRecord('map-version');

    mapVersion.source = source;
    mapVersion.areaMap = areaMap;

    await mapVersion.save();

    this.metrics.trackEvent('Saved Map Version');
  });
}
