/* eslint-disable ember/no-get */
import { get, set } from '@ember/object';
import { service } from '@ember/service';
import Model, { type AsyncHasMany, type SyncHasMany, attr, hasMany } from '@ember-data/model';
import type Store from '@ember-data/store';
import type CompanyRoleModel from 'garaje/models/company-role';
import type EmailModel from 'garaje/models/email';
import type LocationModel from 'garaje/models/location';
import type LocationRoleModel from 'garaje/models/location-role';
import type NotificationPreferenceModel from 'garaje/models/notification-preference';
import type PhoneNumberModel from 'garaje/models/phone-number';
import type ReservationModel from 'garaje/models/reservation';
import type UserDocumentModel from 'garaje/models/user-document';
import type ZoneRoleModel from 'garaje/models/zone-role';
import type AjaxService from 'garaje/services/ajax';
import type StateService from 'garaje/services/state';
import type { RoleAssignmentResponse, RoleScopeType } from 'garaje/utils/custom-roles';
import { COMPANY_ROLES } from 'garaje/utils/roles';
import urlBuilder from 'garaje/utils/url-builder';

export default class UserModel extends Model {
  @service declare ajax: AjaxService;
  @service declare state: StateService;
  @service declare store: Store;

  // Relationships
  @hasMany('company-roles', { async: true }) declare companyRoles: AsyncHasMany<CompanyRoleModel>;
  @hasMany('email', { async: true }) declare emails: AsyncHasMany<EmailModel>;
  @hasMany('location-roles', { async: false }) declare locationRoles: SyncHasMany<LocationRoleModel>;
  @hasMany('zone-roles', { async: false }) declare zoneRoles: SyncHasMany<ZoneRoleModel>;
  @hasMany('phone-number', { async: true }) declare phoneNumbers: AsyncHasMany<PhoneNumberModel>;
  @hasMany('notification-preference', { async: true })
  declare notificationPreferences: AsyncHasMany<NotificationPreferenceModel>;
  @hasMany('reservation', { async: true }) declare reservations: AsyncHasMany<ReservationModel>;
  @hasMany('user-document', { async: true }) declare userDocuments: AsyncHasMany<UserDocumentModel>;

  // Attributes
  @attr('string') declare avatar: string;
  @attr('date') declare createdAt: Date;
  @attr('string') declare email: string;
  @attr('string') declare fullName: string;
  @attr('string') declare intercomIdentityHash: string;
  @attr('string') declare newPassword: string;
  @attr('string') declare newPasswordConfirmation: string;
  @attr('string') declare password: string;
  @attr('string') declare phoneNumber: string;
  @attr('string', { defaultValue: 'accepted' }) declare privacyPolicyAcceptance: string;
  @attr('boolean') declare hideFromEmployeeSchedule: boolean;
  @attr('boolean', { defaultValue: true }) declare visitorsProductVideosEnabled: boolean;

  // This will return true if the user is using passport
  @attr('boolean', { defaultValue: false }) declare hasProfile: boolean;
  @attr('boolean', { defaultValue: false }) declare pushEnabled: boolean;
  @attr('boolean', { defaultValue: false }) declare invitePushEnabled: boolean;

  // Profile photo
  @attr('string') declare userPhotoUrl: string;
  @attr('string') declare userPhoto: string;

  get firstName(): string {
    return this.fullName.replace(/ .*/, '');
  }

  get lastName(): string | undefined {
    const lastName = this.fullName.match(/ .*/)?.[0];
    return lastName?.trim();
  }

  setRole(roleName: string, location: LocationModel): Promise<LocationRoleModel | CompanyRoleModel> {
    let member: CompanyRoleModel | LocationRoleModel | undefined;
    if (COMPANY_ROLES.includes(roleName)) {
      const { currentCompany: company } = this.state;
      member = this.store.createRecord('company-role', {
        roleName,
        company,
        user: this,
      });
    } else {
      member = this.locationRoles.find(function (locationRole) {
        return (
          get(locationRole, 'roleName') === 'Employee' &&
          locationRole.belongsTo('location').id() === get(location, 'id')
        );
      });

      /*
       If an user has a location-role of type Employee, we just
       promote that user to the new role.
       */
      if (member) {
        set(member, 'roleName', roleName);
      } else {
        member = this.store.createRecord('location-role', { roleName, location, user: this });
      }
    }

    return member.save().catch((err) => {
      if (member) this.store.unloadRecord(member);
      throw err;
    });
  }

  async bulkCreateRoles(
    userId: string,
    roleId: string,
    roleScopeIds: string[],
    roleScopeType: RoleScopeType,
  ): Promise<void> {
    const url = urlBuilder.v2.bulkCreateRoles();

    const response: RoleAssignmentResponse = await this.ajax.request(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: JSON.stringify({
        data: {
          'user-id': userId,
          'role-id': roleId,
          'role-scope-ids': roleScopeIds,
          'role-scope-type': roleScopeType,
        },
      }),
    });

    this.store.pushPayload('role-assignment', response);
  }

  async bulkDeleteRoles(roleAssignmentIds: string[]): Promise<void> {
    const url = urlBuilder.v2.bulkDeleteRoles();

    await this.ajax.request(url, {
      type: 'POST',
      contentType: 'application/vnd.api+json',
      data: JSON.stringify({
        data: {
          'role-assignment-ids': roleAssignmentIds,
        },
      }),
    });

    roleAssignmentIds.forEach((id) => {
      const roleAssignment = this.store.peekRecord('role-assignment', id);
      if (roleAssignment) this.store.unloadRecord(roleAssignment);
    });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
