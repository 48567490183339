import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get } from '@ember/object';

export default class EmployeesDirectoryDocumentReviewSingleDocumentRoute extends Route {
  @service store;
  @service state;

  async model({ id }) {
    const locationId = this.state.currentLocation.id;
    const userDocumentLink = await this.store.findRecord('user-document-link', id, {
      include:
        'user-document.user,user-document.user-document-attachments,user-document.user-document-template.user-document-template-attachments,user-document.user-document-location-contexts',
      filter: { 'user-document-location-contexts-location': locationId },
      reload: true,
    });

    const identifier = get(userDocumentLink, 'userDocument.userDocumentTemplate.identifier');

    // Only need to load 2 records to see if there are other docs to review
    const userDocumentLinks = await this.store.query('user-document-link', {
      include: 'user-document,user-document.user-document-template',
      filter: { 'approval-status': 'review', 'user-document-template-identifier': identifier },
      page: { limit: 2 },
    });

    return {
      userDocumentLink,
      userDocumentLinks,
    };
  }
}
