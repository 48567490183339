import { A } from '@ember/array';
import ObjectProxy from '@ember/object/proxy';
import { service } from '@ember/service';
import { isNone, isEmpty } from '@ember/utils';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentLinkModel from 'garaje/models/user-document-link';
import type { Identifier, TemplateInputField } from 'garaje/models/user-document-template';
import UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type StateService from 'garaje/services/state';
import { cached } from 'tracked-toolbox';

export const DATE_FORMAT = 'yyyy-MM-dd';

class InputField extends ObjectProxy<TemplateInputField> {
  declare content: TemplateInputField;
  declare userDocument: AbstractDocumentModel;

  get value() {
    return this.userDocument.inputFieldsData[this.content.identifier] || this.content.default_value;
  }

  set value(val) {
    this.userDocument.inputFieldsData = Object.freeze({
      ...this.userDocument.inputFieldsData,
      [this.content.identifier]: val,
    });
  }

  save() {
    return this.userDocument.save();
  }
}

export class DateInputField extends InputField {
  @tracked declare userDocument: AbstractDocumentModel;
  @tracked timezone?: string;

  get value(): Date {
    return <Date>super.value;
  }

  set value(date: Date) {
    if (!date) return; // prevent Invalid time value error

    if (this.timezone) {
      date = toZonedTime(date, this.timezone);
    }

    super.value = format(date, DATE_FORMAT);
  }
}

export type AllInputFields = InputField | DateInputField;

export default class AbstractDocumentModel extends Model {
  @service declare state: StateService;

  @belongsTo('user-document-template') declare userDocumentTemplate:
    | AsyncBelongsTo<UserDocumentTemplateModel>
    | UserDocumentTemplateModel;

  @hasMany('user-document-link') declare userDocumentLinks: AsyncHasMany<UserDocumentLinkModel>;
  @hasMany('user-document-attachment') declare userDocumentAttachments: AsyncHasMany<UserDocumentAttachmentModel>;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('boolean', { defaultValue: true }) declare active: boolean;
  @attr('immutable', { defaultValue: () => ({}) }) declare inputFieldsData: Record<string, unknown>;

  get _userDocumentTemplate(): UserDocumentTemplateModel | undefined {
    return this.userDocumentTemplate instanceof UserDocumentTemplateModel
      ? this.userDocumentTemplate
      : this.userDocumentTemplate?.content;
  }

  get issueDate(): string | undefined {
    return <string | undefined>(
      this.inputFields.find((inputField) => inputField.content?.identifier === 'issue_date')?.value
    );
  }

  @cached
  get inputFields(): AllInputFields[] {
    const inputFields = this._userDocumentTemplate?.inputFields
      ? A(this._userDocumentTemplate.inputFields)
      : A<TemplateInputField>();

    return inputFields.sortBy('position').map((inputField) => {
      if (inputField.type === 'date') {
        return DateInputField.create({
          userDocument: this,
          content: inputField,
          timezone: this.state.currentLocation?.timezone,
        });
      }

      return InputField.create({ userDocument: this, content: inputField });
    });
  }

  get requiredInputFields(): AllInputFields[] {
    return this.inputFields.filter((inputField) => inputField.content?.required);
  }

  get invalidInputFields(): AllInputFields[] {
    return this.requiredInputFields.filter((inputField) => isNone(inputField.value));
  }

  get sortableDate(): Date | string {
    return this.issueDate ?? this.createdAt;
  }

  get identifier(): Identifier | undefined {
    return this._userDocumentTemplate?.identifier;
  }

  get title(): string | undefined {
    return this._userDocumentTemplate?.title;
  }

  // TODO: Support multiple UserDocumentLink records with polymorphic association
  //       to Company OR Location. For now, returning the most recently created record.
  get userDocumentLink(): UserDocumentLinkModel | undefined {
    return this.userDocumentLinks.sortBy('createdAt').reverse()[0];
  }

  get isDenied(): boolean {
    return this.userDocumentLink?.approvalStatus === 'denied';
  }

  get isReview(): boolean {
    return this.userDocumentLink?.approvalStatus === 'review';
  }

  get isApproved(): boolean {
    return this.userDocumentLink?.approvalStatus === 'approved';
  }

  get inputFieldDateFormat(): string {
    return DATE_FORMAT;
  }

  /**
   * Returns an attachment for a specific template attachment id
   *
   * @param userDocumentTemplateAttachmentId - the template attachment id to look by
   * @param attachments - array of UserDocumentAttachments to search
   * @returns a UserDocumentAttachment
   */
  getAttachment(
    userDocumentTemplateAttachmentId: string,
    attachments = this.userDocumentAttachments,
  ): UserDocumentAttachmentModel | undefined {
    return attachments.find(
      (attachment) => attachment.belongsTo('userDocumentTemplateAttachment').id() === userDocumentTemplateAttachmentId,
    );
  }

  /**
   * Checks to see if all previous required template attachments steps have been completed
   *
   * @param currentTemplateAttachment - the template attachment currently being addressed. Optional, will check all attachments if not passed in.
   * @returns Returns earliest occurring required template attachment that is not completed. Undefined if all required are completed.
   */
  checkRequiredTemplateAttachments(
    currentTemplateAttachment?: UserDocumentTemplateAttachmentModel,
  ): UserDocumentTemplateAttachmentModel | undefined {
    let templateAttachmentToReturnTo: UserDocumentTemplateAttachmentModel | undefined;

    const sortedUserDocumentTemplateAttachments =
      this._userDocumentTemplate?.userDocumentTemplateAttachments.sortBy('position');

    const stopIndex = currentTemplateAttachment
      ? sortedUserDocumentTemplateAttachments?.indexOf(currentTemplateAttachment)
      : sortedUserDocumentTemplateAttachments?.length;

    let attachmentIndex = 0;

    while (attachmentIndex < stopIndex! && !templateAttachmentToReturnTo) {
      const currentTemplateAttachment = sortedUserDocumentTemplateAttachments?.objectAt(attachmentIndex);
      const attachment = this.getAttachment(currentTemplateAttachment!.id);

      if (currentTemplateAttachment?.required && !attachment?.isValid) {
        templateAttachmentToReturnTo = currentTemplateAttachment;
      }

      attachmentIndex++;
    }

    return templateAttachmentToReturnTo;
  }

  get hasAttachedFile(): boolean {
    return this.userDocumentAttachments.isAny('isValid');
  }

  get hasInputFieldData(): boolean {
    return Object.keys(this.inputFieldsData).length > 0;
  }

  get isValidDocument(): boolean {
    const templateRequiredAttachmentIds = this._userDocumentTemplate?.userDocumentTemplateAttachments
      .filterBy('required')
      .mapBy('id');
    const requiredAttachmentsToDocument = A(
      this.userDocumentAttachments.mapBy('userDocumentTemplateAttachment'),
      // @ts-ignore
    ).filterBy('required');
    const documentAttachmentIds = requiredAttachmentsToDocument.mapBy('id');
    const isRequirementMet = templateRequiredAttachmentIds?.every((requiredAttachmentId) =>
      documentAttachmentIds.includes(requiredAttachmentId),
    );

    // If a template requires an attachment that is not found on this document, this record is not valid.
    return isRequirementMet
      ? // @ts-ignore
        isEmpty(this.invalidInputFields) && requiredAttachmentsToDocument.isEvery('isValid')
      : false;
  }

  get mostRecentAttachment(): UserDocumentAttachmentModel | undefined {
    return this.userDocumentAttachments.sortBy('updatedAt').lastObject;
  }

  get isCapableOfAttachments(): boolean {
    const expectedAttachments = this._userDocumentTemplate?.userDocumentTemplateAttachments || A();
    return <number>expectedAttachments.length > 0;
  }
}
