/* eslint-disable ember/no-computed-properties-in-native-classes */
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';
import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany, SyncHasMany } from '@ember-data/model';
import { attr, belongsTo, hasMany } from '@ember-data/model';
import { apiAction } from '@mainmatter/ember-api-actions';
import Agreeable from 'garaje/models/agreeable';
import type ApprovalStatus from 'garaje/models/approval-status';
import type DeviceModel from 'garaje/models/device';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type NotificationLogModel from 'garaje/models/notification-log';
import type PlatformJobModel from 'garaje/models/platform-job';
import type ReservationModel from 'garaje/models/reservation';
import type SignedAgreementsJobModel from 'garaje/models/signed-agreements-job';
import type UserModel from 'garaje/models/user';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';
import type { Identifier } from 'garaje/models/user-document-template';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import type StateService from 'garaje/services/state';
import embeddedBelongsTo from 'garaje/utils/embedded-belongs-to';
import { FLOW_TYPE } from 'garaje/utils/enums';
import type { RecordArray } from 'garaje/utils/type-utils';
import type { CollectionResponse } from 'jsonapi/response';
import { equal, gt, gte, filterBy, sortBy } from 'macro-decorators';

import type AgreeableNdaModel from './agreeable-nda';
import type UserDatum from './user-datum';

type SignContext = 'iPad' | 'Dashboard' | 'Passport';
type CheckStatus = 'Checked' | 'Not checked';
//
// Keeping aliases for backwards compatibility while we complete the
// move to entries/v2
//
class EntryModel extends Agreeable {
  @service declare state: StateService;
  // For entry details
  @belongsTo('entry', { async: true, inverse: 'previousEntries' })
  declare previousEntryParent: AsyncBelongsTo<EntryModel>;
  @hasMany('entry', { async: true, inverse: 'previousEntryParent' }) declare previousEntries: AsyncHasMany<EntryModel>;
  @belongsTo('signed-agreements-job', { async: true })
  declare signedAgreementsJob: AsyncBelongsTo<SignedAgreementsJobModel>;

  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('device', { async: true }) declare device: AsyncBelongsTo<DeviceModel>;
  @belongsTo('device', { async: true }) declare signOutDevice: AsyncBelongsTo<DeviceModel>;
  @belongsTo('invite', { async: true }) declare invite: AsyncBelongsTo<InviteModel>;
  @hasMany('platform-job', { async: true }) declare platformJobs: AsyncHasMany<PlatformJobModel>;

  @belongsTo('entry', { async: true, inverse: 'additionalGuestEntries' })
  declare groupParent: AsyncBelongsTo<EntryModel>;
  @belongsTo('user', { async: true }) declare signInUser: AsyncBelongsTo<UserModel>;
  /**
   * Read only. API does not actually read from this field to update the flow. UI might still update in a few places in order to keep in sync.
   */
  @belongsTo('flow', { async: true }) declare flow: AsyncBelongsTo<FlowModel> | FlowModel;
  @hasMany('entry', { async: true, inverse: 'groupParent' }) declare additionalGuestEntries: AsyncHasMany<EntryModel>;
  @hasMany('reservation', { async: true }) declare reservations: AsyncHasMany<ReservationModel>;
  @hasMany('visitor-document', { async: false }) declare visitorDocuments:
    | SyncHasMany<VisitorDocumentModel>
    | RecordArray<VisitorDocumentModel>;
  @hasMany('notification-log', { async: false, inverse: null })
  declare inviteMultiTenancyVisitorNotificationLogs: SyncHasMany<NotificationLogModel>;

  /**
   * primary host
   */
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel> | EmployeeModel | null;
  @hasMany('employee', { async: true, inverse: null }) declare additionalHosts:
    | AsyncHasMany<EmployeeModel>
    | EmployeeModel[];

  @attr('string') declare fullName: string;
  @attr('string') declare email: string;
  @attr('string') declare host: string | null;
  @attr('boolean', { defaultValue: false }) declare employeeScreeningFlow: boolean;
  @attr('boolean') declare agreementRefused: boolean;
  @attr('date') declare signedInAt: Date;
  @attr('date') declare signedOutAt: Date;
  @attr('date') declare checkedInAt: Date;
  @attr('string') declare smsStatus: string;
  @attr('string') declare smsStatusNormalized: string;
  @attr('string') declare emailStatus: string;
  @attr('string') declare slackStatus: string;
  @attr('string') declare pushStatus: string;
  @attr('string') declare nda: string;
  @attr('string') declare passportAvatar: string;
  @attr('date') declare originalNdaSignDate: Date;
  @attr('string') declare privateNotes: string;
  @attr('string') declare propertyNotes: string;
  @attr('string') declare groupName: string;
  @attr('array', { defaultValue: () => [] }) declare locationNames: string[];

  // For Visitor Sign In
  @attr('boolean') declare printBadge: boolean;
  @attr('boolean') declare sendHostNotification: boolean;
  @attr('number') declare currentLocationId: number;

  @attr('string') declare signedInVia: SignContext;
  @attr('string') declare signedInBy: string;
  @attr('string') declare signedOutVia: SignContext;
  @attr('string') declare signedOutBy: string;

  @attr('string') declare idCheckStatus: CheckStatus;

  /**
   * Group sign-in with additional guest
   */
  @attr('number', { defaultValue: () => 0 }) declare additionalGuests: number;

  /**
   * We can't rely on flowName to associate entries with a flow, and we
   * can't use the "Purpose of visit" field since it changes based on
   * the locale.
   *
   * With this field, the API will tell us the exact key we can use to
   * extract the "Purpose of visit" or flow name out of entry.user_data.
   */
  @attr('string') declare povKey: string;
  @attr('string') declare flowName: string;
  @attr('locality', { defaultValue: () => ({ placeId: '' }) }) declare locality: string;
  @attr('string') declare agreementsStatus: string;
  @attr('userData', { defaultValue: () => [] }) declare userData: NativeArray<UserDatum>;

  @attr('object') declare thumbnails: Record<string, string>;

  @embeddedBelongsTo('approval-status') declare approvalStatus: ApprovalStatus;
  @alias('host') hostName!: this['host'];
  @alias('signedInAt') signInTime!: this['signedInAt'];
  @alias('signedOutAt') signOutTime!: this['signedOutAt'];
  @alias('nda') agreementLink!: this['nda'];
  @alias('employeeScreeningFlow') isFromEmployeeScreening!: this['employeeScreeningFlow'];
  @alias('walkUpFlow') isFromWalkUp!: this['walkUpFlow'];
  @alias('device') signInDevice!: this['device'];

  @gte('approvalStatus.failedReport.length', 1) didFailApprovalCheck!: boolean;
  @equal('approvalStatus.status', 'denied') approvalWasDenied!: boolean;
  @equal('approvalStatus.status', 'approved') approved!: boolean;
  @equal('approvalStatus.status', 'review') needsApprovalReview!: boolean;
  @gt('additionalGuests', 0) hasAdditionalGuests!: boolean;

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('platformJobs.@each.{pluginCategory,status}')
  get categorizedPlatformJobs(): Promise<Record<string, PlatformJobModel[]>> {
    if (this.platformJobs.isDestroyed) return Promise.resolve({});

    return this.platformJobs.then((platformJobs) => {
      return platformJobs.reduce<Record<string, PlatformJobModel[]>>((categorizedJobs, job) => {
        const category = job.pluginCategory;

        if (category && category !== 'nda') {
          categorizedJobs[category] = (categorizedJobs[category] || []).concat(job);
        }

        return categorizedJobs;
      }, {});
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('platformJobs.@each.{pluginCategory,status}')
  get ndaPlatformJobs(): Promise<Record<string, PlatformJobModel[]>> {
    if (this.platformJobs.isDestroyed) return Promise.resolve({});

    return this.platformJobs.then((platformJobs) => {
      return platformJobs.reduce<Record<string, PlatformJobModel[]>>((ndaJobs, job) => {
        const category = job.pluginCategory;

        if (category && category === 'nda') {
          ndaJobs[job.pluginName] = (ndaJobs[job.pluginName] || []).concat(job);
        }

        return ndaJobs;
      }, {});
    });
  }

  /**
   * @deprecated use flow relationship instead
   */
  @computed(
    'employeeScreeningFlow',
    'flow.id',
    'isFromEmployeeScreening',
    'location.flows.@each.{employeeCentric,name}',
    'purposeOfVisit.value',
    'state.currentLocation.employeeScreeningFlow',
  )
  get currentFlow(): FlowModel | EmployeeScreeningFlowModel | undefined | null {
    if (this.employeeScreeningFlow) {
      return this.state.currentLocation?.employeeScreeningFlow;
    }

    // eslint-disable-next-line ember/no-get
    return (<RecordArray<FlowModel>>get(this, 'location.flows') ?? []).findBy('id', <string>get(this, 'flow.id'));
  }

  @computed('flowName', 'purposeOfVisit.value')
  get walkUpFlow(): boolean {
    const isWalkup = this.purposeOfVisit?.value === FLOW_TYPE.PROPERTY_WALKUP;
    return isWalkup || this.flowName === 'Property walk-up';
  }

  @computed('userData.@each.field')
  get purposeOfVisit(): UserDatum | undefined {
    return this.userData.findBy('field', 'Purpose of visit'); // TODO: use localized version
  }

  @filterBy('inviteMultiTenancyVisitorNotificationLogs', 'successful') successfulLogs!: NotificationLogModel[];
  @sortBy('successfulLogs', 'createdAt:desc') sortedSuccessfulLogs!: NotificationLogModel[];

  get latestNotificationLog(): NotificationLogModel | undefined {
    return A(this.sortedSuccessfulLogs).firstObject;
  }

  approveEntry(): Promise<unknown> {
    return this.reviewEntry({
      action: 'approve',
    });
  }

  denyEntry(): Promise<unknown> {
    return this.reviewEntry({
      action: 'deny',
    });
  }

  visitorDocumentForTemplate(
    userDocumentTemplate: UserDocumentTemplateModel | AsyncBelongsTo<UserDocumentTemplateModel>,
  ): VisitorDocumentModel | undefined {
    // eslint-disable-next-line ember/no-get
    return this.visitorDocumentForIdentifier(<Identifier>get(userDocumentTemplate, 'identifier'));
  }

  visitorDocumentForIdentifier(identifier: Identifier): VisitorDocumentModel | undefined {
    return this.visitorDocuments.findBy('identifier', identifier);
  }

  async reprintBadge(): Promise<CollectionResponse<{ result: string; statuses: string[] }>> {
    return <CollectionResponse<{ result: string; statuses: string[] }>>(
      await apiAction(this, { method: 'POST', path: 'print-badge' })
    );
  }

  async reviewEntry(options: { action: 'approve' | 'deny' }): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'review', data: options });
  }

  declare agreeableNdas: SyncHasMany<AgreeableNdaModel>;
  declare _signOutTime: Date;
}

export default EntryModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    entry: EntryModel;
  }
}
