import Component from '@glimmer/component';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { alias } from 'macro-decorators';
import { validatePresence, validateFormat } from 'ember-changeset-validations/validators';
import lookupValidator from 'ember-changeset-validations';
import validateConditional from 'garaje/validators/conditional';
import Changeset from 'ember-changeset';

function buildValidations() {
  return {
    videoUrl: validateConditional({
      if(key, newValue, oldValue, { kind }, model) {
        return (kind || get(model, 'kind')) === 'video';
      },
      then: validateFormat({
        type: 'url',
        allowBlank: false,
      }),
      else() {
        // Mark this field as valid, We don't need to validate since we
        // don't use the location value
        return true;
      },
    }),
    message: validateConditional({
      if(key, newValue, oldValue, { kind }, model) {
        return (kind || get(model, 'kind')) === 'message';
      },
      then: validatePresence(true),
      else() {
        // Mark this field as valid, We don't need to validate since we
        // don't use the location value
        return true;
      },
    }),
  };
}

export default class extends Component {
  @service globalSettingBatch;

  @alias('globalSettingBatch.currentGlobalSettingBatch') currentGlobalSettingBatch;

  constructor() {
    super(...arguments);

    this.summaryPage = this.args.summaryPage;
    this.changeset = this.args.changeset ?? this._buildChangeset();
  }

  _buildChangeset() {
    const validations = buildValidations(this);
    const validator = lookupValidator(validations);
    return new Changeset(this.summaryPage, validator, validations);
  }
}
