import Model, { belongsTo, attr, type AsyncBelongsTo } from '@ember-data/model';
import type UserModel from 'garaje/models/user';
import adapter from 'garaje/utils/decorators/adapter';

adapter('v2');
class PhoneNumberModel extends Model {
  @belongsTo('user', { async: true }) declare user: AsyncBelongsTo<UserModel>;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('string') declare phoneNumber: string;
}

export default PhoneNumberModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'phone-number': PhoneNumberModel;
  }
}
