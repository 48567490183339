/* eslint-disable ember/use-ember-get-and-set */
import Service, { service } from '@ember/service';
import type MessageBusService from 'garaje/services/message-bus';

/**
 * This service exists to provide a better interface to subscribe events
 * from an embedded app.
 */
export default class EmbeddedAppService extends Service {
  #messageSenders = new Map<string, (message: unknown) => void>();

  @service declare messageBus: MessageBusService;

  subscribe(handler: (message: unknown) => void): void {
    this.messageBus.on('embedded-app-message', handler);
  }

  unsubscribe(handler: (message: unknown) => void): void {
    this.messageBus.off('embedded-app-message', handler);
  }

  trigger(message: unknown): void {
    this.messageBus.trigger('embedded-app-message', message);
  }

  registerMessageSender(id: string, sender: (message: unknown) => void): void {
    this.#messageSenders.set(id, sender);
  }

  unregisterMessageSender(id: string): void {
    this.#messageSenders.delete(id);
  }

  sendMessage(id: string, message: unknown): void {
    const sender = this.#messageSenders.get(id);
    if (sender) {
      sender(message);
    } else {
      throw new Error(`No message sender registered for id: ${id}`);
    }
  }
}
