import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import urlBuilder from 'garaje/utils/url-builder';

/**
 * Privacy policy review modal for accepting updated privacy policy.
 * @param {User} user
 */
export default class PolicyReviewModal extends Component {
  @service ajax;
  @service flashMessages;

  get user() {
    return this.args.user;
  }

  get isNewUser() {
    return this.user.privacyPolicyAcceptance === 'not_accepted';
  }

  @dropTask
  *saveTask() {
    try {
      yield this.ajax.request(urlBuilder.v3.users.acceptPrivacyPolicy(), {
        contentType: 'application/json',
        type: 'POST',
      });
      this.user.reload();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', `Error accepting privacy policy.`);
    }
  }
}
