import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

import type GlobalAnalyticsVisitorsController from './controller';

export default class GlobalAnalyticsVisitorsDashboardRoute extends Route {
  @service declare abilities: AbilitiesService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare state: StateService;

  beforeModel(): void {
    const { vrSubscription } = this.state;
    if (this.abilities.cannot('visit-visitors global-analytics') || !vrSubscription?.canAccessGlobalAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }

  setupController(controller: GlobalAnalyticsVisitorsController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.showModal = false;

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }
}
