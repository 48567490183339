import { action, get } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format, getDate, addMinutes, startOfDay, isAfter, isToday, isEqual, fromUnixTime } from 'date-fns';
import { task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type {
  EntryFragmentFragment,
  InviteFragmentFragment,
  ReservationFragmentFragment,
} from 'garaje/graphql/generated/employee-schedule-types';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type RegistrationsService from 'garaje/services/registrations';
import type StateService from 'garaje/services/state';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { APPROVAL_STATUS, INVITE_STATE } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import InviteStateLabel from 'garaje/utils/invite-state-label';
import type { ValueOf } from 'type-fest';

export type GraphqlEntry = EntryFragmentFragment;

export type GraphqlReservation = ReservationFragmentFragment;

export type GraphqlInvite = InviteFragmentFragment;

export default class DayAvailabilityComponent extends Component<{
  date: number | Date;
  entry: GraphqlEntry;
  invite: GraphqlInvite;
  location: StateService['currentLocation'];
  maxOccupancy: number;
  peopleRegistered: number;
  reservation: GraphqlReservation;
  screeningCard?: GraphqlInvite;
  requiredDocumentApprovalExpiresAt?: string | number | Date;
  hasRequiredDocumentApproval?: boolean;
}> {
  @service declare flashMessages: FlashMessagesService;
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare registrations: RegistrationsService;
  @service declare featureConfig: FeatureConfigService;
  @service declare workplaceMetrics: WorkplaceMetricsService;

  @tracked unscheduleModalIsOpen = false;
  @tracked unscheduleError = false;
  INVITE_STATE_ENUM = INVITE_STATE;

  get showDelegatedBookerName(): boolean {
    return !!this.delegatedBookerName;
  }

  get delegatedBookerName(): string | null {
    if (!this.invite?.employee?.email || !this.invite?.visitor?.email) {
      return null;
    }

    if (this.invite.employee.email === this.invite.visitor.email) {
      return null;
    }

    return this.invite.employee.name;
  }

  get darkMode(): boolean {
    return (
      [INVITE_STATE.SCHEDULED, INVITE_STATE.APPROVED, INVITE_STATE.PENDING, INVITE_STATE.SIGNED_IN] as ValueOf<
        typeof INVITE_STATE
      >[]
    ).includes(this.inviteState);
  }
  get errorMode(): boolean {
    return ([INVITE_STATE.DENIED, INVITE_STATE.PAST_WINDOW] as ValueOf<typeof INVITE_STATE>[]).includes(
      this.inviteState,
    );
  }
  get dayOfWeek(): string {
    return format(this.args.date, 'eee');
  }
  get date(): number {
    return getDate(this.args.date);
  }
  get reservationDate(): Date {
    return fromUnixTime(this.args.reservation.startTime!);
  }
  get spotsRemaining(): number {
    return this.args.maxOccupancy - this.args.peopleRegistered;
  }
  get atCapacity(): boolean {
    return this.spotsRemaining === 0;
  }
  get schedulingBlocked(): boolean {
    return this.atCapacity && this.args.location.capacityLimitEnabled;
  }
  get approvalStatus(): string | null | undefined {
    return this.invite?.approvalStatus?.status;
  }
  get isApproved(): boolean {
    return this.approvalStatus == APPROVAL_STATUS.APPROVED;
  }
  get isDenied(): boolean {
    return this.approvalStatus == APPROVAL_STATUS.DENIED;
  }
  get invite(): GraphqlInvite | null {
    return this.args.screeningCard?.__typename == 'Invite' ? this.args.screeningCard : null;
  }
  get inviteStateLabel(): unknown {
    return InviteStateLabel(this.inviteState);
  }
  get isNotRegistered(): boolean {
    return !this.isApproved && !this.isDenied;
  }
  get screeningWindowStarted(): boolean {
    const { registrationEligibilityStartOffset, employeeScreeningFlow } = this.args.location;
    // eslint-disable-next-line ember/no-get
    if (employeeScreeningFlow && !get(employeeScreeningFlow, 'employeeScreeningRequired')) {
      return true;
    }

    const startOfWindow = addMinutes(startOfDay(this.args.date), registrationEligibilityStartOffset ?? 0);
    return isAfter(new Date(), startOfWindow) || isEqual(new Date(), startOfWindow);
  }
  get screeningWindowEnded(): boolean {
    const { registrationEligibilityEndOffset, employeeScreeningFlow } = this.args.location;
    // eslint-disable-next-line ember/no-get
    if (employeeScreeningFlow && !get(employeeScreeningFlow, 'employeeScreeningRequired')) {
      return false;
    }

    const endOfWindow = addMinutes(startOfDay(this.args.date), registrationEligibilityEndOffset || 0);
    return isAfter(new Date(), endOfWindow) || isEqual(new Date(), endOfWindow);
  }
  get isToday(): boolean {
    return isToday(this.args.date);
  }
  get isRegistered(): boolean {
    return this.invite!.isPresigned || !this.preregistrationLink;
  }
  get preregistrationLink(): string | null {
    return this.invite!.preregistrationLink;
  }
  get registerUrl(): string | null {
    const garajeReturnUrl = `${config.host}/schedule%3FselectedInviteId%3D${this.invite!.id}`;

    return this.noRequiredDocumentApproval ? null : `${this.preregistrationLink}?garajeReturnUrl=${garajeReturnUrl}`;
  }

  get isEditReservationConfigEnabled(): boolean {
    return this.featureConfig.isEnabled('deskReservations.editing');
  }

  get isCancelReservationConfigEnabled(): boolean {
    return this.featureConfig.isEnabled('deskReservations.canceling');
  }

  get noRequiredDocumentApproval(): boolean {
    const { date, requiredDocumentApprovalExpiresAt, hasRequiredDocumentApproval } = this.args;
    const isExpiredDocument =
      requiredDocumentApprovalExpiresAt &&
      (isAfter(date, new Date(requiredDocumentApprovalExpiresAt)) ||
        isEqual(date, new Date(requiredDocumentApprovalExpiresAt)));
    return isExpiredDocument || !hasRequiredDocumentApproval;
  }

  get inviteState(): ValueOf<typeof INVITE_STATE> {
    if (this.isNotRegistered) {
      if (this.screeningWindowEnded) {
        return INVITE_STATE.PAST_WINDOW;
      } else if (!this.invite) {
        return INVITE_STATE.UNSCHEDULED;
      }
      return INVITE_STATE.SCHEDULED;
    } else {
      if (!this.invite!.entry) {
        // registered states
        if (this.isApproved) return INVITE_STATE.APPROVED;
        if (this.isDenied) return INVITE_STATE.DENIED;
        return INVITE_STATE.PENDING;
      }
      return INVITE_STATE.SIGNED_IN;
    }
  }

  unscheduleTask = task({ drop: true }, async () => {
    this.unscheduleError = false;

    try {
      const unscheduleResponse: { invite: GraphqlInvite; reservation: GraphqlReservation } =
        await this.registrations.unschedule(this.args.date, {
          reservation: this.args.reservation,
        });
      const { invite, reservation } = unscheduleResponse;
      this.metrics.trackEvent('Web Employee Schedule - Unschedule', {
        desk_id: reservation?.desk?.id,
        invite_date: invite.expectedArrivalTime,
        invite_id: invite.id,
        invite_location_id: invite.location!.id,
        reservation_id: reservation?.id,
      });
      this.unscheduleModalIsOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Reservation released');
    } catch (e) {
      this.unscheduleError = true;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay((e as unknown as any).errors?.firstObject));
    }
  });

  scheduleTask = task({ drop: true }, async () => {
    const { date } = this.args;
    this.workplaceMetrics.trackEvent('YOUR_SCHEDULE_SCHEDULE_BTN_CLICKED');
    try {
      const reserveDeskResponse: { reservation?: GraphqlReservation; invite: GraphqlInvite } =
        await this.registrations.reserveDesk(date);
      const { invite, reservation } = reserveDeskResponse;
      this.workplaceMetrics.trackEvent('YOUR_SCHEDULE_SCHEDULE_SUCCESS');
      this.metrics.trackEvent('Web Employee Schedule - Schedule', {
        desk_id: reservation?.desk.id,
        invite_date: invite.expectedArrivalTime,
        invite_id: invite.id,
        invite_location_id: invite.location!.id,
        reservation_id: reservation?.id,
      });
      // @ts-ignore there is no variable called bookDeskEnabled
      if (reservation || !this.bookDeskEnabled) {
        this.flashMessages.showAndHideFlash('success', 'Reservation scheduled');
      } else {
        this.flashMessages.showAndHideFlash('error', 'Desk could not be booked at this time');
      }
    } catch (e) {
      this.workplaceMetrics.trackEvent('YOUR_SCHEDULE_SCHEDULE_FAILED');
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay((e as unknown as any).errors?.firstObject));
    }
  });

  releaseDeskReservationTask = task({ drop: true }, async () => {
    const { reservation } = this.args;

    try {
      await this.registrations.releaseDeskReservation({ reservation });
      this.metrics.trackEvent('Web Employee Schedule - Release Desk', {
        reservation_id: reservation.id,
        desk_id: reservation.desk.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Desk released');
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay((e as unknown as any).errors?.firstObject));
    }
  });

  signInInviteTask = task({ drop: true }, async () => {
    const { screeningCard, reservation, date } = this.args;

    this.workplaceMetrics.trackEvent('YOUR_SCHEDULE_SIGN_IN_BTN_CLICKED');

    try {
      const entry: GraphqlEntry = (await this.registrations.signInInvite(date)) as GraphqlEntry;
      this.workplaceMetrics.trackEvent('YOUR_SCHEDULE_SIGN_IN_SUCCESS');
      this.metrics.trackEvent('Web Employee Schedule - Sign In', {
        desk_id: reservation?.desk.id,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location!.id,
        invite_date: screeningCard!.expectedArrivalTime,
        invite_id: screeningCard!.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed in');
    } catch (e) {
      this.workplaceMetrics.logMonitorError({ event: 'YOUR_SCHEDULE_SIGN_IN_FAILED', error: e });
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay((e as unknown as any).errors?.firstObject));
    }
  });

  signOutEntryTask = task({ drop: true }, async () => {
    const { date, reservation, screeningCard } = this.args;

    try {
      const entry: GraphqlEntry = (await this.registrations.signOutEntry(date)) as GraphqlEntry;
      this.metrics.trackEvent('Web Employee Schedule - Sign Out', {
        desk_id: reservation?.desk.id,
        employee_entry_sign_out_at: entry.signedOutAt,
        entry_date: entry.signedInAt,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location!.id,
        invite_id: screeningCard!.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed out');
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unnecessary-type-assertion, @typescript-eslint/no-explicit-any
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay((e as unknown as any).errors?.firstObject));
    }
  });

  @action
  closeUnscheduleModal(): void {
    this.unscheduleError = false;
    this.unscheduleModalIsOpen = false;
  }
}
