import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { loadWorkflows } from 'garaje/utils/script-loader';

class AppsRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;

  beforeModel() {
    const currentZone = this.modelFor('current-zone')?.currentZone;

    if (this.abilities.cannot('visit settings') && !currentZone) {
      this.router.transitionTo('unauthorized');
    } else if (this.abilities.cannot('visit advance-settings') && !currentZone) {
      this.router.transitionTo('billing');
    }
    if (!this.featureFlags.isEnabled('appStore')) {
      this.router.replaceWith('integrations');
    }
  }

  model() {
    return {
      currentZone: this.modelFor('current-zone').currentZone,
    };
  }

  afterModel() {
    return loadWorkflows();
  }
}

export default AppsRoute;
