import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import moment from 'moment-timezone';
import { MINUTES_IN_A_DAY } from 'garaje/utils/enums';

/**
 * @param {Date}                signInTime
 * @param {Function}            signOut
 * @param {String}              signOutText
 */
export default class SignOutDropdown extends Component {
  @service metrics;

  startTime = new Date();

  get timesArray() {
    const times = [];
    let hours;
    let minutes;
    let ampm;
    const signInTime = moment(this.args.signInTime);
    const totalMinutes = signInTime.minutes() + signInTime.hours() * 60;
    const roundedMinutes = 15 * Math.round(totalMinutes / 15);

    for (let i = roundedMinutes; i < MINUTES_IN_A_DAY; i += 15) {
      hours = Math.floor(i / 60);
      minutes = i % 60;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      ampm = hours % 24 < 12 ? 'AM' : 'PM';
      hours = hours % 12;
      if (hours === 0) {
        hours = 12;
      }
      times.push(`${hours}:${minutes} ${ampm}`);
    }
    return times;
  }

  get closestTime() {
    const dates = this.timesArray.map(function (date) {
      return moment(date, 'h:mm a').toDate();
    });
    const startTime = this.startTime;
    let nearestDate;
    let nearestDiff = Infinity;
    for (let i = 0; i < dates.length; ++i) {
      const diff = +dates[i] - startTime;
      if (diff > 0 && diff < nearestDiff) {
        nearestDiff = diff;
        nearestDate = dates[i];
      }
    }
    return nearestDate;
  }

  @action
  onChange(e) {
    this.args.signOut(e.target.value);
  }

  @action
  signOut() {
    this.metrics.trackEvent('Dashboard Entry - Signed Out', { action_origin: 'entry_page' });
    this.args.signOut();
  }
}
