import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { isBlank } from '@ember/utils';
import { action, get, set, setProperties } from '@ember/object';
import { service } from '@ember/service';
import ObjectProxy from '@ember/object/proxy';
import { task, all, restartableTask } from 'ember-concurrency';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { or, empty, filter } from 'macro-decorators';

const wrapLocation = function (location) {
  return ObjectProxy.create({
    content: location,
    fullName: `${get(location, 'companyName')} ${get(location, 'name')}`,
    disabled: false, // Ember power select has a conflicting property name
  });
};

export default class MeetingController extends Controller {
  @service currentLocation;
  @service flashMessages;
  @service locations;
  @service store;
  @service logger;
  @service router;

  @tracked validated = false;
  @tracked employee;
  @tracked selectedLocation;

  sendGuestNotification = false;
  defaultAddressLabel = 'No address given';

  setupLocation() {
    // This will default to the current location if location on the meeting is not loaded or null
    const selectedLocation = get(this.model, 'location.id') && get(this.model, 'location');
    const selectedOrDefaultLocation = selectedLocation || this.currentLocation;
    this.selectedLocation = wrapLocation(selectedOrDefaultLocation);
  }

  @or('selectedLocation.address', 'defaultAddressLabel') meetingAddress;
  @empty('attendeesWithoutNames') allAttendeesHaveNames;
  @filter('model.attendees', (attendee) => isBlank(attendee.name)) attendeesWithoutNames;

  get availableLocations() {
    const availableLocations = get(this.locations, 'readableByCurrentAdmin').filterBy('preRegistrationEnabled', true);
    // There is a naming conflict with location object and ember power select
    // where the "disabled" property on locations disables the options in the UI
    return availableLocations.map((location) => {
      return wrapLocation(location);
    });
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: true,
    },
    prefix: true,
  }).restartable())
  _searchEmployeesTask;

  @restartableTask
  *registerTask() {
    const selectedLocationId = get(this.selectedLocation, 'id');
    const location = yield this.store.findRecord('location', selectedLocationId);
    const employee = yield get(this.model, 'employee');
    const hasMVTSetIfRequired = get(this.model, 'flowName');
    const meetingHasEmployee = employee ? true : false;
    const validationError = !(meetingHasEmployee && this.allAttendeesHaveNames) || !hasMVTSetIfRequired;

    set(this.model, 'location', location);

    if (validationError) {
      this.validated = true;
      this.logger.info(
        'Meeting form failed to validate https://www.pivotaltracker.com/n/projects/1610817/stories/139252355',
      );
      this.flashMessages.showFlash('error', 'Please fill all the required fields.');
    } else {
      yield all([this.model.save(), this.saveAttendees.perform(), this.createInvites.perform(location)]);
      this.router.transitionTo('visitors.invites.index');
    }
  }

  @restartableTask
  *searchEmployees(term) {
    const extraFilters = {
      locations: get(this.selectedLocation, 'id'),
    };
    return yield this._searchEmployeesTask.perform(term, extraFilters);
  }

  @task
  *saveAttendees() {
    const attendees = yield get(this.model, 'attendees');
    yield attendees.save();
  }

  @task
  *createInvites(location) {
    const invitedAttendees = yield get(this.model, 'attendees');

    const deleteExistingLocations = invitedAttendees.reduce((acc, attendee) => {
      if (get(attendee, 'hasInvite')) {
        acc.push(this.deleteInvite.perform(attendee));
      }
      return acc;
    }, []);

    const createNewInvites = invitedAttendees.reduce((acc, attendee) => {
      if (get(attendee, 'sendInvite')) {
        acc.push(this.createNewInvite.perform({ attendee, location }));
      }
      return acc;
    }, []);

    try {
      yield all([...deleteExistingLocations, ...createNewInvites]);
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'There was an error saving your invites');
    }
  }

  @task
  *deleteInvite(attendee) {
    const invite = yield get(attendee, 'invite');
    yield invite.destroyRecord();
  }

  @task
  *createNewInvite({ attendee, location }) {
    const invite = yield location.createInvite(this.model.expectedArrivalTime, attendee.name, attendee.email);
    yield invite.save();
    setProperties(invite, {
      attendee,
      inviterName: get(this.model, 'employee.name'),
      skipGuestNotification: !this.sendGuestNotification,
      flowName: this.model.flowName,
    });
    yield invite.save(); // Update with the attendee relationship
  }

  @task
  *clearInvalidHost() {
    const employee = yield get(this.model, 'employee');

    if (!employee) {
      return; // No need to clear if there is no employee
    }

    const employeeLocations = yield get(employee, 'locations');
    const selectedLocationId = get(this.selectedLocation, 'id');
    const employeeBelongsToSelectedLocation = yield employeeLocations.findBy('id', selectedLocationId);

    if (!employeeBelongsToSelectedLocation) {
      this.employee = null;
      set(this.model, 'employee', null);
    }
  }

  @action
  setEmployee(employee) {
    set(this.model, 'employee', employee);
    this.employee = employee;
  }

  @action
  setSelectedLocation(location) {
    this.selectedLocation = location;
    this.clearInvalidHost.perform();
  }
}
