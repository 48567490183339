import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class BillingPeriodRoute extends Route {
  @service state;
  @service locations;
  @service router;

  model({ product }) {
    const { paymentSource } = this.modelFor('billing');
    if (paymentSource.isACH) {
      return this.router.transitionTo('billing');
    }

    let activeUnits, subscription;
    switch (product) {
      case 'deliveries':
        subscription = this.state.realDeliveriesSubscription;
        activeUnits = this.modelFor('billing.index').deliveryAreas.filterBy('enabled').mapBy('nameWithLocationName');
        break;
      case 'rooms':
        subscription = this.state.realRoomsSubscription;
        break;
      case 'visitors':
        subscription = this.state.vrSubscription;
        activeUnits = this.locations.active.mapBy('nameWithCompanyName');
        break;
      case 'desks':
        subscription = this.state.realDesksSubscription;
        activeUnits = this.modelFor('billing.index').activeDeskLocations.length;
        break;
    }

    if (!subscription || subscription.isBasicPlan) {
      return this.router.transitionTo('billing');
    }

    const newPeriod = subscription.nextPeriod === 'yearly' ? 'monthly' : 'yearly';

    return hash({ activeUnits, paymentSource, newPeriod, subscription });
  }

  setupController(controller, { paymentSource, subscription }) {
    super.setupController(...arguments);
    controller.hasPaymentMethod = paymentSource.exists && !subscription.delinquent;
  }
}
