import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type GlobalRoutingService from 'garaje/services/global-routing';

export default class GlobalVisitorComponent extends Component {
  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;
  @service declare globalRouting: GlobalRoutingService;

  get visitorsRoute(): string | null {
    return this.globalOverviewAccess.firstAccessibleRouteForGlobalVisitor();
  }

  get isInviteLogActive(): boolean {
    return this.globalRouting.isNestedGlobalRoute() || this.router.isActive('location-overview.invite-log');
  }
}
