import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { action, get, set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads } from '@ember/object/computed';
import { all, timeout, dropTask, task } from 'ember-concurrency';
import { v4 as uuid } from 'uuid';
import zft from 'garaje/utils/zero-for-tests';
import { dasherize } from '@ember/string';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';

/**
 * @param {Model<Location>}           location
 * @param {Boolean}                   unchanged
 * @param {Function}                  onChange
 */
export default class VisitorEmailsVisitorGuide extends Component {
  @service flashMessages;
  @service metrics;
  @service impressions;
  @service store;
  @service state;

  @tracked isOpen = false;
  @tracked isEditing = false;
  @tracked isPreviewing = false;
  @tracked visitorGuide = null;
  @tracked sections = null;
  @tracked showUnsavedEditsWarning = false;
  @tracked showModalTranslations = false;

  @reads('args.location.visitorGuideEnabled') isEnabled;

  constructor() {
    super(...arguments);
    this.onEdit = this.onEdit.bind(this);
    if (this.args.location.visitorGuideEnabled) {
      this.loadVisitorGuideTask.perform();
    }
  }

  get hasAccessToWelcomeGuide() {
    return !!this.state.features?.canAccessWelcomeGuide;
  }

  @dropTask
  *enableVisitorGuideTask() {
    yield this.loadVisitorGuideTask.perform();
    if (this.visitorGuide) {
      this.args.onChange({ hasChanges: true });
    } else {
      yield this.createVisitorGuide();
      yield this.loadVisitorGuideTask.perform();
    }
    this.isOpen = true;
    this.toEditing();
  }

  @dropTask
  *disableVisitorGuideTask() {
    set(this.args.location, 'visitorGuideEnabled', false);
    yield this.saveLocation.perform();
    this.isOpen = false;
    this.toEditing();
    this.metrics.trackEvent('Visitor Guide - Disabled', {
      location_id: this.args.location.id,
      visitor_guide_id: this.visitorGuide?.id,
    });
    yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_EMAILS_WELCOME_GUIDE['DISABLED']);
  }

  @dropTask
  *saveLocation() {
    try {
      yield this.args.location.save();
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @task
  *saveVisitorGuide() {
    try {
      if (this.sections.isAny('enabled')) {
        yield this.saveDirtySections();
        if (!this.args.location.visitorGuideEnabled) {
          set(this.args.location, 'visitorGuideEnabled', true);
          yield this.args.location.save();
          this.metrics.trackEvent('Visitor Guide - Enabled', {
            location_id: this.args.location.id,
            visitor_guide_id: this.visitorGuide?.id,
          });
          yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_EMAILS_WELCOME_GUIDE['ENABLED']);
        }
      } else if (this.args.location.visitorGuideEnabled) {
        set(this.args.location, 'visitorGuideEnabled', false);
        yield this.args.location.save();
        yield this.impressions.postImpression.perform(IMPRESSION_NAMES.VR_EMAILS_WELCOME_GUIDE['DISABLED']);
      }
      this.isOpen = false;
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  async saveDirtySections() {
    try {
      const dirtySections = this.sections.filterBy('hasDirtyAttributes', true);
      await all(dirtySections.invoke('save'));
      this.args.onChange({ hasChanges: false });
    } catch (_e) {
      this.args.onChange({ hasChanges: true });
    }
  }

  @task
  *saveAndTrackEdits() {
    const diffs = this.sections.reduce((acc, section) => {
      if (section.hasDirtyAttributes) {
        acc[section.id] = {};
        const changes = section.changedAttributes();
        Object.keys(changes).forEach((key) => {
          acc[section.id][dasherize(key)] = changes[key];
        });
      }
      return acc;
    }, {});
    yield this.saveVisitorGuide.perform();
    const edit_id = uuid();
    Object.keys(diffs).forEach((id) => {
      const section = this.sections.findBy('id', id);
      this.metrics.trackEvent('Visitor Guide - Edited', {
        edit_id,
        location_id: this.args.location.id,
        visitor_guide_id: this.visitorGuide?.id,
        section_title: { key: id, value: get(section, 'title') },
        section_position: { key: id, value: get(section, 'position') },
        section_enablement: { key: id, value: get(section, 'enabled') },
        section_diff: diffs[id],
        enabled_languages: this.args.location.enabledLocales,
      });
    });
  }

  @task
  *saveAndTrackTranslations() {
    yield this.saveVisitorGuide.perform();
    const locales = this.sections
      .reduce(function (acc, s) {
        const translations = get(s, 'customTranslations') || { body: {} };
        Object.keys(translations['body']).forEach((locale) => acc.push(locale));
        return acc;
      }, [])
      .uniq();
    this.metrics.trackEvent('Visitor Guide - Translations Edited', {
      location_id: this.args.location.id,
      visitor_guide_id: this.visitorGuide?.id,
      translation_edits: {
        key: this.visitorGuide?.id,
        value: locales,
      },
    });
  }

  async createVisitorGuide() {
    const visitorGuide = await get(this.args.location, 'visitorGuide');
    if (visitorGuide) {
      return;
    }
    try {
      const visitorGuide = this.store.createRecord('visitor-guide', {
        location: this.args.location,
      });
      await visitorGuide.save();
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @task
  *loadVisitorGuideTask() {
    try {
      const visitorGuide = yield get(this.args.location, 'visitorGuide');
      if (!visitorGuide) {
        return;
      }
      const sections = yield get(visitorGuide, 'visitorGuideSections');
      this.visitorGuide = visitorGuide;
      this.sections = sections;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }

  @dropTask
  *reloadSectionsTask() {
    yield this.saveDirtySections();
    yield all(this.sections.invoke('reload'));
    yield timeout(zft(1000));
  }

  @action
  toEditing() {
    this.isEditing = true;
    this.isPreviewing = false;
    this.showUnsavedEditsWarning = false;
  }

  @action
  toPreviewing() {
    this.isEditing = false;
    this.isPreviewing = true;
    this.showUnsavedEditsWarning = false;
  }

  @action
  trackPreview() {
    this.metrics.trackEvent('Visitor Guide Previewed - Location', {
      location_id: this.args.location.id,
      visitor_guide_id: this.visitorGuide?.id,
    });
  }

  onEdit() {
    if (this.args.unchanged) {
      this.args.onChange({ hasChanges: true });
    }
  }

  @action
  continue() {
    this.sections.forEach((section) => {
      section.rollbackAttributes();
    });
    this.isOpen = false;
    this.showUnsavedEditsWarning = false;
    this.toEditing();
  }

  @action
  edit() {
    this.toEditing();
    this.isOpen = true;
  }

  @action
  cancel() {
    if (this.sections.isAny('hasDirtyAttributes')) {
      this.showUnsavedEditsWarning = true;
    } else {
      this.continue();
    }
  }
}
