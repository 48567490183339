import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type AnnouncementTemplateModel from 'garaje/models/announcement-template';
import type CommunicationsService from 'garaje/services/communications-service';
import type StateService from 'garaje/services/state';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import { EXAMPLE_ANNOUNCEMENT_TEMPLATES } from 'garaje/utils/enums';
import announcementTemplateValidations from 'garaje/validations/announcement-template';

export interface CommunicationsNewTemplateParams {
  example_id?: string;
}

export interface CommunicationsNewTemplateModel {
  announcementTemplate: AnnouncementTemplateModel;
  changeset: DetailedChangeset<AnnouncementTemplateModel>;
  isSlackInstalled: boolean;
  isTeamsInstalled: boolean;
}

export default class CommunicationsNewTemplateRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare communicationsService: CommunicationsService;
  @service declare transitionConfirm: TransitionConfirmService;

  queryParams = {
    example_id: {
      refreshModel: true,
    },
  };

  async model({ example_id }: CommunicationsNewTemplateParams): Promise<CommunicationsNewTemplateModel | void> {
    try {
      const exampleTemplate = EXAMPLE_ANNOUNCEMENT_TEMPLATES.find((e) => e.id === example_id);

      const newTemplate = this.store.createRecord('announcement-template', {
        name: exampleTemplate?.name || '',
        title: exampleTemplate?.title || '',
        message: exampleTemplate?.message || '',
        defaultChannels: exampleTemplate?.defaultChannels || ['sms', 'email', 'push', 'slack', 'ms_teams'],
        defaultEmployeeAudiences: exampleTemplate?.defaultEmployeeAudiences || [],
        defaultVisitorAudiences: exampleTemplate?.defaultVisitorAudiences || [],
        markAsSafe: exampleTemplate?.markAsSafe || false,
        takeoverActive: exampleTemplate?.takeoverActive || false,
        published: true,
        creator: this.state.currentUser,
        company: this.state.currentCompany,
      });

      const validator = lookupValidator(announcementTemplateValidations);

      const slackTeamsInstalled = await this.communicationsService.getSlackTeamsInstalled();

      return {
        ...slackTeamsInstalled,
        announcementTemplate: newTemplate,
        changeset: Changeset(newTemplate, validator, announcementTemplateValidations),
      };
    } catch (_error) {
      void this.router.transitionTo('unauthorized');
    }
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { changeset } = <CommunicationsNewTemplateModel>this.modelFor(this.routeName);

    // If leaving this route with unsaved changes, show confirmation modal
    if (changeset.isDirty) {
      return void this.transitionConfirm.displayConfirmTask.perform(transition, {
        header: 'Unsaved changes',
        body: 'Are you sure you want to discard all changes made to this template?',
        confirmText: 'Discard changes',
        cancelText: 'Keep editing',
        continue() {
          changeset.rollback();
        },
      });
    }
  }
}
