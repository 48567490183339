import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { alias } from 'macro-decorators';

export default class extends Controller {
  @service('global-setting-batch') globalSettingBatchService;
  @service currentAdmin;

  queryParams = ['showFieldOptions'];

  @tracked showFieldOptions = null;

  @alias('globalSettingBatchService.currentGlobalSettingBatch') globalSettingBatch;
  @alias('currentAdmin.isGlobaldmin') isGlobalAdmin;

  changeset = null;

  get isLocationAdmin() {
    return this.currentAdmin.isLocationAdmin && !this.isGlobalAdmin;
  }

  @action
  registerChangeset(changeset) {
    this.changeset = changeset;
  }
}
