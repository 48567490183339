import Service from '@ember/service';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get, set } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { isBlank, isEmpty, isPresent } from '@ember/utils';
import SetupGuideRoomsStep from 'garaje/models/locations-setup-guide-rooms-step';
import urlBuilder from 'garaje/utils/url-builder';

const COMPANY_STEPS_TITLES = ['Upload your logo', 'Set up your employee directory'];
const DELIVERIES_STEPS_TITLES = ['Get the mobile app', 'Record your first delivery'];

/**
 * Provides and tracks onboarding steps / status for various products
 *
 * If you are looking for the visitors setup guide steps it has it's own service!
 * Check out the VisitorsOnboarding service instead.
 */
export default class SetupGuideStepsService extends Service {
  @service ajax;
  @service store;
  @service state;
  @service localStorage;

  @alias('loadStepsTask.isRunning') loadingData;

  @dropTask
  *loadStepsTask() {
    const {
      currentLocation: { id: locationId },
    } = this.state;
    try {
      const allRoomsCompleted = this.localStorage.getItem(`all_rooms_steps_completed_${locationId}`);
      if (!allRoomsCompleted) {
        const roomsOnboardingStatusUrl = urlBuilder.v2.roomsOnboardingStatus(locationId);
        const { value } = yield this.ajax.request(roomsOnboardingStatusUrl);
        set(this, 'roomsOnboardingStatus', value);
      } else {
        set(this, 'roomsOnboardingStatus', null);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      set(this, 'roomsOnboardingStatus', null);
    }

    try {
      const allLocationsCompleted = this.localStorage.getItem(`all_location_steps_completed_${locationId}`);
      let locationStepsResponse = [];
      if (!allLocationsCompleted) {
        locationStepsResponse = yield this.store.query('locations-setup-guide-step', {
          filter: { location: locationId },
          include: 'setup-guide-step',
        });
        set(this, 'locationsSetupGuideSteps', locationStepsResponse);
      } else {
        set(this, 'locationsSetupGuideSteps', []);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      set(this, 'locationsSetupGuideSteps', []);
    }

    // Prevent making unnecessary calls in the future
    if (isPresent(this.locationsSetupGuideSteps) && this.companyStepsComplete && this.deliveriesStepsComplete) {
      this.localStorage.setItem(`all_location_steps_completed_${locationId}`, true);
    }

    if (isPresent(this.roomsOnboardingStatus) && this.roomsStepsComplete) {
      this.localStorage.setItem(`all_rooms_steps_completed_${locationId}`, true);
    }
  }

  /**
   * @returns {boolean} Indicates that all company steps have been completed
   */
  @computed('companySteps.@each.completed')
  get companyStepsComplete() {
    return this.companySteps.every((companyStep) => companyStep.completed);
  }

  /**
   * @returns {Array<object>} Array of LocationSetupGuideSteps
   */
  @computed('locationsSetupGuideSteps.[]')
  get companySteps() {
    if (isEmpty(this.locationsSetupGuideSteps)) {
      return [];
    }
    return this.locationsSetupGuideSteps.filter((locationSetupGuideStep) =>
      COMPANY_STEPS_TITLES.includes(get(locationSetupGuideStep, 'setupGuideStep.title')),
    );
  }

  /**
   * @returns {boolean} indicates all deliveries steps have been completed
   */
  @computed('deliveriesSteps.@each.completed')
  get deliveriesStepsComplete() {
    return this.deliveriesSteps.every((deliveriesStep) => deliveriesStep.completed);
  }

  /**
   * @returns {Array<object>} Array of LocationSetupGuideSteps
   */
  @computed('locationsSetupGuideSteps.[]')
  get deliveriesSteps() {
    if (isEmpty(this.locationsSetupGuideSteps)) {
      return [];
    }
    return this.locationsSetupGuideSteps.filter((locationSetupGuideStep) =>
      DELIVERIES_STEPS_TITLES.includes(get(locationSetupGuideStep, 'setupGuideStep.title')),
    );
  }

  /**
   * @returns {boolean} Indicates all rooms steps have been completed
   */
  @computed('roomsSteps.@each.completed')
  get roomsStepsComplete() {
    return this.roomsSteps.every((roomsStep) => roomsStep.isCompletedOrSkipped);
  }

  /**
   * @returns {number} number of rooms steps user has not completed
   */
  @computed('roomsSteps.@each.isCompletedOrSkipped')
  get numberRoomsStepsNotCompleted() {
    if (isBlank(this.roomsSteps)) {
      return 0;
    }
    return this.roomsSteps.reduce((accumulator, { isCompletedOrSkipped }) => {
      return isCompletedOrSkipped ? accumulator : accumulator + 1;
    }, 0);
  }

  /**
   * @returns {Array<SetupGuideRoomsStep>} Array of SetupGuideRoomsSteps
   */
  @computed('roomsOnboardingStatus')
  get roomsSteps() {
    if (isEmpty(this.roomsOnboardingStatus)) {
      return [];
    }
    const {
      has_calendar: hasCalendar,
      assigned_locations: assigned,
      paired_rooms: paired,
    } = this.roomsOnboardingStatus;

    return [
      new SetupGuideRoomsStep(hasCalendar, 'Import your company’s room calendars', 'roomsSettings'),
      new SetupGuideRoomsStep(assigned, 'Select the rooms in your workplace location', 'roomsCalendars'),
      new SetupGuideRoomsStep(paired, 'Pair an iPad to each room', 'roomsList'),
    ];
  }
}
