import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type CompanyModel from 'garaje/models/company';
import type ZoneModel from 'garaje/models/zone';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('multi-tenancy')
class ConnectFloorModel extends Model {
  @attr('string') declare name: string;
  @attr('number') declare position: number;

  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;

  @hasMany('zone', { async: true }) declare suites: AsyncHasMany<ZoneModel>;
}

export default ConnectFloorModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-floor': ConnectFloorModel;
  }
}
