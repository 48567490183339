import Component from '@glimmer/component';
import { get } from '@ember/object';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { filterBy } from '@ember/object/computed';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { all, dropTask, restartableTask } from 'ember-concurrency';

import normalizeCouponCode from 'garaje/utils/normalize-coupon-code';

/**
 * @param {String}              lastValidCouponCode
 * @param {String}              period
 * @param {String}              plan
 * @param {String}              product
 * @param {Number}              quantity
 * @param {String}              selectedPlan
 * @param {Function<Action>}    setLastValidCouponCode
 * @param {Function<Action>}    setNewPeriod
 */

export default class OrderConfirmation extends Component {
  @service flashMessages;
  @service state;
  @service store;

  @tracked couponCode = null;
  @tracked subscriptionEstimate;
  @tracked subscriptionEstimateForOtherPeriod;

  @filterBy('subscriptionEstimate.nextInvoiceEstimate.lineItems', 'entityType', 'addon') addons;
  @filterBy('subscriptionEstimate.nextInvoiceEstimate.lineItems', 'entityType', 'plan') planLineItems;
  @filterBy('subscriptionEstimateForOtherPeriod.nextInvoiceEstimate.lineItems', 'entityType', 'plan')
  planLineItemsForOtherPeriod;

  get planUnitAmount() {
    if (this.planLineItems) {
      return get(this.planLineItems, 'firstObject.unitAmount') ?? 0;
    }
    return 0;
  }

  get planUnitAmountForOtherPeriod() {
    if (this.planLineItemsForOtherPeriod) {
      return get(this.planLineItemsForOtherPeriod, 'firstObject.unitAmount') ?? 0;
    }
    return 0;
  }

  get planDiscountAmount() {
    if (this.planLineItems) {
      return get(this.planLineItems, 'firstObject.discountAmount') ?? 0;
    }
    return 0;
  }

  get planDiscountAmountForOtherPeriod() {
    if (this.planLineItemsForOtherPeriod) {
      return get(this.planLineItemsForOtherPeriod, 'firstObject.discountAmount') ?? 0;
    }
    return 0;
  }

  get planTaxAmount() {
    if (this.planLineItems) {
      return get(this.planLineItems, 'firstObject.taxAmount') ?? 0;
    }
    return 0;
  }

  get planTaxAmountForOtherPeriod() {
    if (this.planLineItemsForOtherPeriod) {
      return get(this.planLineItemsForOtherPeriod, 'firstObject.taxAmount') ?? 0;
    }
    return 0;
  }

  get discounts() {
    if (this.subscriptionEstimate) {
      return get(this.subscriptionEstimate, 'nextInvoiceEstimate.discounts') ?? [];
    }
    return [];
  }

  get locationType() {
    if (this.args.product === 'desks') {
      return 'desks license';
    }
    if (this.args.product === 'deliveries') {
      return 'delivery area';
    }
    if (this.args.product === 'empxp') {
      return '';
    }
    return 'location';
  }

  get subscription() {
    switch (this.args.product) {
      case 'deliveries':
        return this.state.realDeliveriesSubscription;
      case 'desks':
        return this.state.realDesksSubscription;
      case 'empxp':
        return this.state.workplaceSubscription;
      default:
        return this.state.vrSubscription;
    }
  }

  @dropTask
  *applyCouponCodeTask(couponCode) {
    this.couponCode = normalizeCouponCode(couponCode);
    yield this.fetchEstimateTask.perform();
    this.couponCode = null;
  }

  @restartableTask
  *fetchEstimateTask() {
    const couponCode = this.couponCode !== null ? this.couponCode : this.args.lastValidCouponCode;

    try {
      const subscriptionEstimate = this.store.createRecord('subscription-estimate', {
        app: this.args.product,
        quantity: this.args.quantity,
        plan: this.args.product === 'deliveries' || this.args.product === 'desks' ? 'standard' : this.args.selectedPlan,
        period: this.args.period,
        couponCode,
        subscription: this.subscription,
      });

      // workplace does not support a monthly option in the billing service
      if (['empxp', 'vfd'].includes(this.args.product)) {
        yield subscriptionEstimate.save();
        this.couponCode = null;
        this.subscriptionEstimate = subscriptionEstimate;
        this.subscriptionEstimateForOtherPeriod = null;

        this.args.setLastValidCouponCode(couponCode);
        return;
      }

      const subscriptionEstimateForOtherPeriod = this.store.createRecord('subscription-estimate', {
        app: this.args.product,
        quantity: this.args.quantity,
        plan: this.args.product === 'deliveries' || this.args.product === 'desks' ? 'standard' : this.args.selectedPlan,
        period: this.args.period === 'monthly' ? 'yearly' : 'monthly',
        couponCode,
        subscription: this.subscription,
      });

      yield all([subscriptionEstimate.save(), subscriptionEstimateForOtherPeriod.save()]);

      this.couponCode = null;
      this.subscriptionEstimate = subscriptionEstimate;
      this.subscriptionEstimateForOtherPeriod = subscriptionEstimateForOtherPeriod;

      this.args.setLastValidCouponCode(couponCode);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));

      if (this.subscriptionEstimate === undefined && this.args.lastValidCouponCode) {
        this.args.setLastValidCouponCode(null);
        yield this.fetchEstimateTask.perform();
      }
    }
  }
}
