import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type StateService from 'garaje/services/state';

import type LocationOverviewInviteLogController from './controller';

interface LocationOverviewInviteLogRouteModel {
  vrSubscription: StateService['vrSubscription'];
  currentLocation: StateService['currentLocation'];
}

export default class LocationOverviewInviteLogRoute extends Route {
  queryParams = {
    locations: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    startDate: {
      refreshModel: true,
    },
    endDate: {
      refreshModel: true,
    },
    sort: {
      refreshModel: true,
    },
    status: {
      refreshModel: true,
    },
    visitorType: {
      refreshModel: true,
    },
  };

  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;
  @service declare state: StateService;

  beforeModel(): void {
    if (!this.globalOverviewAccess.canVisitInviteLog) {
      this.router.transitionTo('unauthorized');
    }
  }

  model(): LocationOverviewInviteLogRouteModel {
    return {
      vrSubscription: this.state.vrSubscription,
      currentLocation: this.state.currentLocation,
    };
  }

  setupController(
    controller: LocationOverviewInviteLogController,
    model: LocationOverviewInviteLogRouteModel,
    transition: Transition,
  ): void {
    super.setupController(controller, model, transition);
    controller.invites = undefined;
    void controller.loadInvitesTask.perform();

    // load flows once per route entry
    if (!controller.flows) void controller.loadFlowsTask.perform();
  }

  resetController(controller: LocationOverviewInviteLogController, isExiting: boolean): void {
    if (isExiting) controller.flows = undefined;
  }
}
