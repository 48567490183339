/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { computed } from '@ember/object';
import { or } from '@ember/object/computed';
import { service } from '@ember/service';
import { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type Model from '@ember-data/model';
import type Store from '@ember-data/store';
import type CompanyConfigurationModel from 'garaje/models/company-configuration';
import adapter from 'garaje/utils/decorators/adapter';
import moment from 'moment-timezone';

import type GoogleAppModel from './google-app';
import UserDocumentLinkable from './user-document-linkable';
import type VfdCompanyConfiguration from './vfd-company-configuration';

// As of Ember v3.28, polymorphic associations (like UserDocumentLinkable)
// can only be declared on native model classes through inheritance.
// https://guides.emberjs.com/release/models/relationships/#toc_polymorphism
// ("Classic" Ember Objects support multiple polymorphic associations through
// Ember Mixin.) Conversations on the Ember Data channel in Discord indicate
// hope for supporting polymorphic associations with "plain" relationship
// declarations (no inheritance or mixins) "soon." [revisit polymorphic]
// ~ Dana F. 2021.Dec.14

@adapter('v2')
class CompanyModel extends UserDocumentLinkable {
  @service declare store: Store;

  @hasMany('company-role', { async: true })
  declare companyRoles: AsyncHasMany<Model>;

  @belongsTo('google-app', { async: true })
  declare googleApp: AsyncBelongsTo<GoogleAppModel>;

  @belongsTo('ifttt-integration', { async: true })
  declare iftttIntegration: AsyncBelongsTo<Model>;

  @belongsTo('okta-integration', { async: true })
  declare oktaIntegration: AsyncBelongsTo<Model>;

  @belongsTo('one-login', { async: true })
  declare oneLogin: AsyncBelongsTo<Model>;

  @hasMany('location', { async: true })
  declare locations: AsyncHasMany<Model>;

  @belongsTo('salesforce-integration', { async: true })
  declare salesforceIntegration: AsyncBelongsTo<Model>;

  @belongsTo('saml', { async: true })
  declare saml: AsyncBelongsTo<Model>;

  @belongsTo('scim-integration', { async: true })
  declare scimIntegration: AsyncBelongsTo<Model>;

  @belongsTo('slack-integration', { async: true })
  declare slackIntegration: AsyncBelongsTo<Model>;

  @belongsTo('subscription', { async: true })
  declare subscription: AsyncBelongsTo<Model>;

  @belongsTo('subscription', { async: true })
  declare deliveriesSubscription: AsyncBelongsTo<Model>;

  @belongsTo('zapier-integration', { async: true })
  declare zapierIntegration: AsyncBelongsTo<Model>;

  @belongsTo('company-configuration', { async: true })
  declare companyConfiguration: AsyncBelongsTo<CompanyConfigurationModel>;

  @belongsTo('vfd-company-configuration', { async: true })
  declare vfdCompanyConfiguration: AsyncBelongsTo<VfdCompanyConfiguration>;

  @attr()
  declare adhocPlugins: string[];

  @attr('boolean')
  declare active: boolean;

  @attr('date')
  declare createdAt: Date;

  @attr('string')
  declare employeesLastSyncAt: string;

  @attr('string')
  declare employeesSyncState: string;

  @attr('string')
  declare name: string;

  @attr('string')
  declare onboardingLocationsCount: string;

  @attr('string')
  declare onboardingStatus: string;

  @attr('string')
  declare planIntent: string;

  @attr('boolean')
  declare samlConnected: boolean;

  @attr('boolean', { defaultValue: false })
  declare gtalkEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare callEnabled: boolean;

  @attr('boolean', { defaultValue: false })
  declare isBeta: boolean;

  @attr('boolean', { defaultValue: false })
  declare office365Enabled: boolean;

  get synchronizing(): boolean {
    return ['scheduled', 'synchronizing'].indexOf(this.employeesSyncState) >= 0;
  }

  get syncedSince(): string {
    return moment(this.employeesLastSyncAt).fromNow();
  }

  @or('oneLogin.content', 'googleApp.content', 'oktaIntegration.content', 'scimIntegration.content')
  declare directorySyncProvider: boolean;

  @computed('googleApp.content', 'store')
  get employeesFilter(): unknown {
    const integration = this.googleApp.content;
    if (!integration) {
      // preserving behavior from before
      return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let filter: any;

    filter = integration.filters;
    filter.id = integration.id;

    switch (filter.type) {
      case 'locations':
        filter.locations = filter.data;
        break;
      case 'company':
        filter.query = filter.data.query;
        break;
    }
    const employeeFilter = this.store.peekAll('employees-filter').get('firstObject'); // eslint-disable-line ember/use-ember-get-and-set

    if (employeeFilter) {
      // Reset model so it doesn't show as dirty.
      (employeeFilter as Model).rollbackAttributes();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    filter = this.store.push(this.store.normalize('employees-filter', filter));

    return filter as unknown;
  }
}

export default CompanyModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    company: CompanyModel;
  }
}
