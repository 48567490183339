import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class extends Component {
  @tracked sortBy = 'Name';
  @tracked sortDirection = 'asc';

  queryModelName = 'global-flow';

  get queryParams(): Record<string, unknown> {
    const sortBy = this.sortBy.toLowerCase();
    // add id as secondary sort to make pagination of flows with same name deterministic
    const sort = `${this.sortDirection === 'asc' ? sortBy : `-${sortBy}`},id`;
    return {
      include: 'locations,flows',
      fields: {
        'global-flows': 'name,description,locations,flows',
        locations: 'name',
        flows: 'name',
      },
      sort,
      perPage: 15,
      startingPage: 0,
      perPageParam: 'page[limit]',
      pageParam: 'page[offset]',
      countParam: 'meta.total',
      storeFindMethod: 'queryTransient',
    };
  }

  @action
  sortGlobalFlows(sortBy: string, sortDirection: string): void {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }
}
