import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { scheduleOnce } from '@ember/runloop';
import iframeWriteDoc from 'garaje/utils/iframe-write-doc';

/**
 * @param {Model}                 visitorSurveyConfiguration
 * @param {Function}              onPreview
 */
export default class VisitorEmailsVisitorSurveyVsPreviewingResultsView extends Component {
  @service flashMessages;

  @tracked previewHTML;
  @tracked subject;

  constructor() {
    super(...arguments);
    this.loadAnalyticsPreviewHTMLTask.perform();
  }

  @task
  *loadAnalyticsPreviewHTMLTask() {
    try {
      const { body, subject } = yield this.args.visitorSurveyConfiguration.getAnalyticsviewPreview();
      this.previewHTML = body;
      this.subject = subject;
      scheduleOnce('afterRender', this, iframeWriteDoc, 'analytics-iframe', this.previewHTML);
      scheduleOnce('afterRender', this, this.args.onPreview, 'results');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', e);
    }
  }
}
