import type { Permission } from './ui-permissions';

export type Matrix = {
  rows: Array<MatrixRow>;
};

export type MatrixRow = {
  kind: MatrixKind;
  displayNameKey: string;
  displayDescriptionKey: string;
  enabled: boolean; // determines if user can edit permission
  locked: boolean; // determines if user has correct subscription to edit
  selected: boolean; // determines if permission is already added
  item: MatrixItem | null;
};

export enum MatrixKind {
  GRID = 'grid',
  PRODUCT = 'product',
  CATEGORY = 'category',
  PERMISSION_SET = 'permission-set',
}

export type MatrixItem = {
  dependencyFor: Array<string>;
  dependsOn: Array<string>;
  id: string;
  permissions: Array<Permission>;
};

export const displayNameKeyMapping: { [displayNameKey: string]: string } = {
  // GRID
  'grid.permissions.name': 'Permissions',
  'grid.visitors.name': 'Visitors permissions',
  'grid.workplace.name': 'Workplace permissions',

  // PRODUCT
  'product.visitors.name': 'Visitors',
  'product.analytics.name': 'Analytics',
  'product.workplace.name': 'Workplace',
  'product.maps.name': 'Maps',
  'product.desks.name': 'Desks',
  'product.rooms.name': 'Rooms',
  'product.deliveries.name': 'Deliveries',
  'product.communications.name': 'Communications',
  'product.apps.name': 'Apps',
  'product.user_management.name': 'User management',
  'product.location_settings.name': 'Location settings',
  'product.account_management.name': 'Account management',

  // CATEGORY
  'category.visitor_log.name': 'Visitors log and entries',
  'category.invite_log.name': 'Invites',
  'category.devices.name': 'Devices',
  'category.invite_settings.name': 'Invite settings and notifications',
  'category.sign_in_flows.name': 'Sign-in flows',
  'category.visitor_emails.name': 'Emails and guides for visitors',
  'category.blocklist.name': 'Blocklist',
  'category.security_screening.name': 'Security screening',
  'category.full_visitor_history.name': 'Full visitor history',
  'category.visitors.name': 'Visitors',
  'category.occupancy.name': 'Occupancy',
  'category.attendance.name': 'Attendance',
  'category.deliveries.name': 'Deliveries',
  'category.desks.name': 'Desks',
  'category.rooms.name': 'Rooms',
  'category.strategic_insights.name': 'Strategic Insights',
  'category.custom_analytics.name': 'Custom Analytics',
  'category.employee_log.name': 'Employee log',
  'category.scheduling.name': 'Employee scheduling and capacity',
  'category.announcements.name': 'Announcements',
  'category.ticketing.name': 'Workplace ticketing',
  'category.reservations.name': 'Reservation log',
  'category.room_settings.name': 'Room settings',
  'category.room_devices.name': 'Devices',
  'category.delivery_log.name': 'Delivery log and entries',
  'category.delivery_settings.name': 'Delivery locations and settings',
  'category.employee_directory.name': 'Employee directory',
  'category.admin_management.name': 'Admin',
  'category.activity_log.name': 'Activity log',
  'category.locations.name': 'Locations',
  'category.billing.name': 'Billing',
  'category.vfd_configuration.name': 'Virtual front desk',

  // COMPANY PERMISSION SETS
  'company.visitors.entry.read_set.name': 'View all visitor entries',
  'company.visitors.entry.create_set.name': 'Add visitor entries',
  'company.visitors.entry.update_set.name': 'Edit visitor entries',
  'company.visitors.entry.delete_set.name': 'Delete visitor entries',
  'company.visitors.entry.export_set.name': 'Export all visitor entries',
  'company.visitors.invite.read_set.name': 'View all invites',
  'company.visitors.invite.update_set.name': 'Edit all invites',
  'company.visitors.invite.delete_set.name': 'Delete all invites',
  'company.visitors.invite.export_set.name': 'Export all invites',
  'company.visitors.device.read_set.name': 'View devices',
  'company.visitors.device.manage_set.name': 'Manage devices and device notifications',
  'company.visitors.invite_settings.manage_set.name': 'Manage invite settings and notifications',
  'company.visitors.sign_in_flows.read_set.name': 'View visitor flows',
  'company.visitors.local_sign_in_flows.manage_set.name': 'Manage local visitor flows',
  'company.visitors.global_sign_in_flows.manage_set.name': 'Manage global visitor flows',
  'company.visitors.email_guide.manage_set.name': 'Manage emails and guides for visitors',
  'company.visitors.blocklist.read_set.name': 'View blocklist',
  'company.visitors.blocklist.update_set.name': 'Edit blocklist',
  'company.visitors.blocklist.approve_set.name': 'Approve blocklist matches',
  'company.visitors.screening_settings.manage_set.name': 'Manage other screening settings',
  'company.visitors.visitor_history.export_set.name': 'Export full visitor history',
  'company.visitors.vfd_configuration.read_set.name': 'View Virtual Front Desk settings',
  'company.visitors.vfd_configuration.manage_set.name': 'Manage Virtual Front Desk settings',

  'company.analytics.visitors_dash.read_set.name': 'View Visitors analytics',
  'company.analytics.visitors_dash.schedule_set.name': 'Schedule Visitors data reports',
  'company.analytics.occupancy_dash.read_set.name': 'View occupancy analytics',
  'company.analytics.office_attendance.read_set.name': 'View and export attendance analytics',
  'company.analytics.occupancy_dash.schedule_set.name': 'Schedule or share occupancy data',
  'company.analytics.deliveries_dash.read_set.name': 'View Deliveries analytics',
  'company.analytics.desks_dash.read_set.name': 'View Desks analytics',
  'company.analytics.rooms_dash.read_set.name': 'View Rooms analytics',
  'company.analytics.strategic_insights_dash.read_set.name': 'View Strategic Insights',
  'company.analytics.custom_analytics_dash.read_set.name': 'View Custom Dashboards',
  'company.analytics.custom_analytics_dash.customize_set.name': 'Create and Customize Dashboards',

  'company.workplace.employee.manage_set.name': 'Manage employee log',
  'company.workplace.employee.export_set.name': 'Export employee log',
  'company.workplace.employee_registration_flow.read_set.name': 'View employee registration flow',
  'company.workplace.employee_registration_flow.edit_set.name': 'Edit employee registration flow',
  'company.workplace.employee_scheduling_settings.manage_set.name': 'Manage employee scheduling',
  'company.workplace.employee_announcement.manage_set.name': 'Manage announcements',
  'company.workplace.ticket.manage_set.name': 'Manage workplace ticketing',

  'company.maps.live_map.manage_set.name': 'Manage live maps & neighborhoods',
  'company.maps.draft_map.manage_set.name': 'Manage draft maps',

  'company.desks.desk.manage_set.name': 'Manage desks & neighborhoods',
  'company.desks.desk_settings.manage_set.name': 'Manage desk settings',
  'company.desks.reservation.manage_set.name': 'Manage desk reservations',

  'company.rooms.calendar.sync_set.name': 'Sync company calendar',
  'company.rooms.room.assign_set.name': 'Assign rooms to location',
  'company.rooms.room.manage_set.name': 'Manage rooms',
  'company.rooms.device.manage_set.name': 'Manage devices',

  'company.deliveries.delivery.read_set.name': 'View all deliveries',
  'company.deliveries.delivery.create_set.name': 'Add deliveries (mobile app)',
  'company.deliveries.delivery.update_delete_set.name': 'Edit and delete deliveries',
  'company.deliveries.delivery.export_set.name': 'Export deliveries log',
  'company.deliveries.delivery_area.create_set.name': 'Add delivery areas',
  'company.deliveries.delivery_settings.manage_set.name': 'Manage delivery settings',
  'company.deliveries.delivery_device.manage_set.name': 'Manage delivery devices',

  'company.communications.announcement.manage_set.name': 'Send and manage messages',
  'company.communications.announcement_template.manage_set.name': 'Manage message templates',
  'company.communications.communication_setting.manage_set.name': 'Manage settings',

  'company.apps.company_app.manage_set.name': 'Install and edit company-wide apps',
  'company.apps.location_app.manage_set.name': 'Install and edit location-based apps',

  'company.user_management.employee.read_set.name': 'View employees',
  'company.user_management.employee.manage_set.name': 'Manage employees',
  'company.user_management.employee.export_set.name': 'Export employees',
  'company.user_management.admin.read_set.name': 'View admins',
  'company.user_management.company_admin.manage_set.name': 'Manage company admins',
  'company.user_management.location_admin.manage_set.name': 'Manage location admins',
  'company.user_management.connect_admin.manage_set.name': 'Manage connect admins',
  'company.user_management.custom_role.manage_set.name': 'Create custom admin roles',
  'company.user_management.activity_log.read_set.name': 'View activity log items',

  'company.location_settings.location_settings.manage_set.name': 'Manage location settings',
  'company.location_settings.setup_guide.read_set.name': 'View setup guides',

  'company.account_management.location.manage_set.name': 'Manage locations',
  'company.account_management.billing_settings.manage_set.name': 'Manage billing details',

  // LOCATION PERMISSION SETS
  'location.visitors.entry.read_set.name': 'View all visitor entries',
  'location.visitors.entry.create_set.name': 'Add visitor entries',
  'location.visitors.entry.update_set.name': 'Edit visitor entries',
  'location.visitors.entry.delete_set.name': 'Delete visitor entries',
  'location.visitors.entry.export_set.name': 'Export all visitor entries',
  'location.visitors.invite.read_set.name': 'View all invites',
  'location.visitors.invite.update_set.name': 'Edit all invites',
  'location.visitors.invite.delete_set.name': 'Delete all invites',
  'location.visitors.invite.export_set.name': 'Export all invites',
  'location.visitors.device.read_set.name': 'View devices',
  'location.visitors.device.manage_set.name': 'Manage devices and device notifications',
  'location.visitors.invite_settings.manage_set.name': 'Manage invite settings and notifications',
  'location.visitors.sign_in_flows.read_set.name': 'View visitor flows',
  'location.visitors.local_sign_in_flows.manage_set.name': 'Manage local visitor flows',
  'location.visitors.email_guide.manage_set.name': 'Manage emails and guides for visitors',
  'location.visitors.blocklist.read_set.name': 'View blocklist',
  'location.visitors.blocklist.update_set.name': 'Edit blocklist',
  'location.visitors.blocklist.approve_set.name': 'Approve blocklist matches',
  'location.visitors.screening_settings.manage_set.name': 'Manage other screening settings',
  'location.visitors.visitor_history.export_set.name': 'Export full visitor history',

  'location.analytics.visitors_dash.read_set.name': 'View Visitors analytics',
  'location.analytics.visitors_dash.schedule_set.name': 'Schedule Visitors data reports',
  'location.analytics.occupancy_dash.read_set.name': 'View occupancy analytics',
  'location.analytics.office_attendance.read_set.name': 'View and export attendance analytics',
  'location.analytics.occupancy_dash.schedule_set.name': 'Schedule or share occupancy data',
  'location.analytics.deliveries_dash.read_set.name': 'View Deliveries analytics',
  'location.analytics.desks_dash.read_set.name': 'View Desks analytics',
  'location.analytics.rooms_dash.read_set.name': 'View Rooms analytics',
  'location.analytics.strategic_insights_dash.read_set.name': 'View Strategic Insights',
  'location.analytics.custom_analytics_dash.read_set.name': 'View Custom Dashboards',

  'location.workplace.employee.manage_set.name': 'Manage employee log',
  'location.workplace.employee.export_set.name': 'Export employee log',
  'location.workplace.employee_registration_flow.read_set.name': 'View employee registration flow',
  'location.workplace.employee_registration_flow.edit_set.name': 'Edit employee registration flow',
  'location.workplace.employee_scheduling_settings.manage_set.name': 'Manage employee scheduling',
  'location.workplace.employee_announcement.manage_set.name': 'Manage announcements',
  'location.workplace.ticket.manage_set.name': 'Manage workplace ticketing',

  'location.maps.live_map.manage_set.name': 'Manage live maps & neighborhoods',
  'location.maps.draft_map.manage_set.name': 'Manage draft maps',

  'location.desks.desk.manage_set.name': 'Manage desks & neighborhoods',
  'location.desks.desk_settings.manage_set.name': 'Manage desk settings',
  'location.desks.reservation.manage_set.name': 'Manage desk reservations',

  'location.rooms.room.assign_set.name': 'Assign rooms to location',
  'location.rooms.room.manage_set.name': 'Manage rooms',
  'location.rooms.device.manage_set.name': 'Manage devices',

  'location.deliveries.delivery.read_set.name': 'View all deliveries',
  'location.deliveries.delivery.create_set.name': 'Add deliveries (mobile app)',
  'location.deliveries.delivery.update_delete_set.name': 'Edit and delete deliveries',
  'location.deliveries.delivery.export_set.name': 'Export deliveries log',
  'location.deliveries.delivery_area.create_set.name': 'Add delivery areas',
  'location.deliveries.delivery_settings.manage_set.name': 'Manage delivery settings',
  'location.deliveries.delivery_device.manage_set.name': 'Manage delivery devices',

  'location.communications.announcement.manage_set.name': 'Send and manage messages',
  'location.communications.announcement_template.manage_set.name': 'Manage message templates',
  'location.communications.communication_setting.manage_set.name': 'Manage settings',

  'location.apps.location_app.manage_set.name': 'Install and edit location-based apps',

  'location.user_management.employee.read_set.name': 'View employees',
  'location.user_management.employee.manage_set.name': 'Manage employees',
  'location.user_management.employee.export_set.name': 'Export employees',
  'location.user_management.admin.read_set.name': 'View admins',
  'location.user_management.location_admin.manage_set.name': 'Manage location admins',
  'location.user_management.activity_log.read_set.name': 'View activity log items',

  'location.location_settings.location_settings.manage_set.name': 'Manage location settings',
  'location.location_settings.setup_guide.read_set.name': 'View setup guides',
};

export const displayDescriptionKeyMapping: { [displayDescriptionKey: string]: string } = {
  // GRID
  'grid.permissions.description':
    'Permissions define the level of access a user has to specific products and features. For location roles, permissions are applied for specific location(s) when assigning roles to users.',
  'grid.visitors.description': 'Upgrade to Visitors Enterprise to customize these permissions.',
  'grid.workplace.description': 'Upgrade to Workplace Premium Plus to customize these permissions.',

  // COMPANY PERMISSION SETS
  'company.visitors.entry.read_set.description': 'View all visitor entries',
  'company.visitors.entry.create_set.description': 'Add visitor entries, including signing in invites',
  'company.visitors.entry.update_set.description': 'Edit visitor entries, including visitor and arrival details',
  'company.visitors.entry.delete_set.description': 'Delete entries from visitor log',
  'company.visitors.entry.export_set.description': 'Export all visitor entries',
  'company.visitors.invite.read_set.description': 'View all invites',
  'company.visitors.invite.update_set.description': 'Edit all invites, including expected visitor details and host',
  'company.visitors.invite.delete_set.description': 'Delete all invites',
  'company.visitors.invite.export_set.description': 'Export all invites',
  'company.visitors.device.read_set.description': 'View iPads and printers, including status',
  'company.visitors.device.manage_set.description':
    'Connect, edit, and remove iPads and printers. Edit device status notifications.',
  'company.visitors.invite_settings.manage_set.description':
    'View and edit invite settings and invite-related notifications for hosts and admin. Generate and share a security desk link.',
  'company.visitors.sign_in_flows.read_set.description': 'View kiosk settings and sign in flows per visitor type',
  'company.visitors.local_sign_in_flows.manage_set.description':
    'Edit kiosk settings and local sign in flows per visitor type. Includes delivery contact notifications settings, ID scanning, and manual ID check settings.',
  'company.visitors.global_sign_in_flows.manage_set.description': 'Edit global sign in flows',
  'company.visitors.email_guide.manage_set.description':
    'View and edit invitation and welcome emails, welcome guides, and visitor feedback surveys',
  'company.visitors.blocklist.read_set.description': 'View blocklist',
  'company.visitors.blocklist.update_set.description': 'Edit blocklist',
  'company.visitors.blocklist.approve_set.description': 'Review and approve visitors who match blocklist records',
  'company.visitors.screening_settings.manage_set.description':
    "Manage watchlist and 'Ask visitor to provide their full legal name' setting",
  'company.visitors.visitor_history.export_set.description': 'Export full visitor history',
  'company.visitors.vfd_configuration.read_set.description': 'View Virtual Front Desk settings',
  'company.visitors.vfd_configuration.manage_set.description': 'Manage Virtual Front Desk settings',

  'company.analytics.visitors_dash.read_set.description': 'View Visitors analytics',
  'company.analytics.visitors_dash.schedule_set.description': 'Send a scheduled report of your visitors data',
  'company.analytics.occupancy_dash.read_set.description':
    'View occupancy analytics. Does not include occupancy data by individual.',
  'company.analytics.office_attendance.read_set.description':
    'View individual employee attendance data, including within the Occupancy analytics. Includes exporting tables as images or files.',
  'company.analytics.occupancy_dash.schedule_set.description':
    'Schedule or share workplace occupancy analytics by email. Includes sharing occupancy data at a global level.',
  'company.analytics.deliveries_dash.read_set.description': 'View Deliveries analytics',
  'company.analytics.desks_dash.read_set.description': 'View Desks analytics',
  'company.analytics.rooms_dash.read_set.description': 'View Rooms analytics',
  'company.analytics.strategic_insights_dash.read_set.description': 'View Strategic Insights dashboard',
  'company.analytics.custom_analytics_dash.read_set.description': 'View any custom dashboard at your company.',
  'company.analytics.custom_analytics_dash.customize_set.description': 'Create / customize dashboards at your company.',

  'company.workplace.employee.manage_set.description':
    'View and manage employees who have scheduled to come in and employees on site',
  'company.workplace.employee.export_set.description': 'Export employee log',
  'company.workplace.employee_registration_flow.read_set.description': 'View employee registration flows',
  'company.workplace.employee_registration_flow.edit_set.description': 'Edit employee registration flows',
  'company.workplace.employee_scheduling_settings.manage_set.description':
    'Edit employee schedule visibility settings, required documents, and employee sign-in and sign-out notifications',
  'company.workplace.employee_announcement.manage_set.description': 'Post and archive employee announcements',
  'company.workplace.ticket.manage_set.description':
    'Manage settings of ticketing categories and email address tickets are sent to',

  'company.maps.live_map.manage_set.description':
    'Add and delete floors, manage resources on the map, and manage neighborhoods',
  'company.maps.draft_map.manage_set.description': 'Add, edit and delete floors, maps, and points of interest',

  'company.desks.desk.manage_set.description':
    'Add or delete desks, rename desks, manage desk amenities & neighborhoods, assign permanent desks, and deactivate the location for desks',
  'company.desks.desk_settings.manage_set.description':
    'Manage settings including booking days or hours, space saver, and automatic desk assignment',
  'company.desks.reservation.manage_set.description':
    'Add, edit, or remove desk reservations on behalf of other employees',

  'company.rooms.calendar.sync_set.description': 'Connect and sync company calendar',
  'company.rooms.room.assign_set.description': 'Assign synced rooms to location',
  'company.rooms.room.manage_set.description':
    'View, add, edit, or remove rooms. Manage rooms on the map. Edit room settings, connect or remove tablets from rooms. Manage tablet display settings.',
  'company.rooms.device.manage_set.description': 'Manage device status notifications and admin access codes to tablets',

  'company.deliveries.delivery.read_set.description': 'View all deliveries',
  'company.deliveries.delivery.create_set.description': 'Scan deliveries using the Envoy mobile app',
  'company.deliveries.delivery.update_delete_set.description': 'Edit delivery details and delete deliveries',
  'company.deliveries.delivery.export_set.description': 'Export deliveries log',
  'company.deliveries.delivery_area.create_set.description': 'Add delivery areas',
  'company.deliveries.delivery_settings.manage_set.description':
    'Edit delivery settings, including notification settings and nicknames',
  'company.deliveries.delivery_device.manage_set.description': 'Add, edit, or remove delivery devices',

  'company.communications.announcement.manage_set.description':
    'Send messages to employees and/or visitors, view message log and message details, and archive messages',
  'company.communications.announcement_template.manage_set.description':
    'Add, edit, or delete templates for all locations',
  'company.communications.communication_setting.manage_set.description':
    'Manage message categories and other global settings',

  'company.apps.company_app.manage_set.description':
    'Install and configure company-wide apps, including directory apps, Slack, Teams, and more. This includes SCIM sync settings for employees and admins.',
  'company.apps.location_app.manage_set.description':
    'Install and configure location-based apps, including access control, wifi, and more',

  'company.user_management.employee.read_set.description': 'View all employees for all locations',
  'company.user_management.employee.manage_set.description':
    'Add and remove employees, except employees synced via SCIM groups',
  'company.user_management.employee.export_set.description': 'Export all employees for all locations',
  'company.user_management.admin.read_set.description': 'View admin roles assigned to users',
  'company.user_management.company_admin.manage_set.description':
    'Assign or remove company roles from users. Includes default and custom roles.',
  'company.user_management.location_admin.manage_set.description':
    'Assign or remove location roles from users. Includes default and custom roles.',
  'company.user_management.connect_admin.manage_set.description':
    'Assign or remove connect roles from users. Includes default and custom roles.',
  'company.user_management.custom_role.manage_set.description': 'Create and edit custom admin roles',
  'company.user_management.activity_log.read_set.description': 'View activity log entries for all locations',

  'company.location_settings.location_settings.manage_set.description':
    'Edit location info, branding, API key, language, daily capacity limits, and sign out settings for all locations. Connect properties and deactivate locations.',
  'company.location_settings.setup_guide.read_set.description': 'View guides to get started with Envoy',

  'company.account_management.location.manage_set.description': 'Add locations. Add, edit, or remove location groups.',
  'company.account_management.billing_settings.manage_set.description':
    'View and edit billing details. Receive billing related notifications',

  // LOCATION PERMISSION SETS
  'location.visitors.entry.read_set.description': 'View all visitor entries',
  'location.visitors.entry.create_set.description': 'Add visitor entries, including signing in invites',
  'location.visitors.entry.update_set.description': 'Edit visitor entries, including visitor and arrival details',
  'location.visitors.entry.delete_set.description': 'Delete entries from visitor log',
  'location.visitors.entry.export_set.description': 'Export all visitor entries',
  'location.visitors.invite.read_set.description': 'View all invites',
  'location.visitors.invite.update_set.description': 'Edit all invites, including expected visitor details and host',
  'location.visitors.invite.delete_set.description': 'Delete all invites',
  'location.visitors.invite.export_set.description': 'Export all invites',
  'location.visitors.device.read_set.description': 'View iPads and printers, including status',
  'location.visitors.device.manage_set.description':
    'Connect, edit, and remove iPads and printers. Edit device status notifications.',
  'location.visitors.invite_settings.manage_set.description':
    'View and edit invite settings and invite-related notifications for hosts and admin. Generate and share a security desk link.',
  'location.visitors.sign_in_flows.read_set.description': 'View kiosk settings and sign in flows per visitor type',
  'location.visitors.local_sign_in_flows.manage_set.description':
    'Edit kiosk settings and local sign in flows per visitor type. Includes delivery contact notifications settings, ID scanning, and manual ID check settings.',
  'location.visitors.email_guide.manage_set.description':
    'View and edit invitation and welcome emails, welcome guides, and visitor feedback surveys',
  'location.visitors.blocklist.read_set.description': 'View blocklist',
  'location.visitors.blocklist.update_set.description': 'Edit blocklist',
  'location.visitors.blocklist.approve_set.description': 'Review and approve visitors who match blocklist records',
  'location.visitors.screening_settings.manage_set.description':
    "Manage watchlist and 'Ask visitor to provide their full legal name' setting",
  'location.visitors.visitor_history.export_set.description': 'Export full visitor history',

  'location.analytics.visitors_dash.read_set.description': 'View Visitors analytics',
  'location.analytics.visitors_dash.schedule_set.description': 'Send a scheduled report of your visitors data',
  'location.analytics.occupancy_dash.read_set.description':
    'View occupancy analytics. Does not include occupancy data by individual.',
  'location.analytics.office_attendance.read_set.description':
    'View individual employee attendance data, including within the Occupancy analytics. Includes exporting tables as images or files.',
  'location.analytics.occupancy_dash.schedule_set.description':
    'Schedule or share workplace occupancy analytics by email',
  'location.analytics.deliveries_dash.read_set.description': 'View Deliveries analytics',
  'location.analytics.desks_dash.read_set.description': 'View Desks analytics',
  'location.analytics.rooms_dash.read_set.description': 'View Rooms analytics',
  'location.analytics.strategic_insights_dash.read_set.description': 'View Strategic Insights dashboard',
  'location.analytics.custom_analytics_dash.read_set.description': 'View any custom dashboard at your location.',

  'location.workplace.employee.manage_set.description':
    'View and manage employees who have scheduled to come in and employees on site',
  'location.workplace.employee.export_set.description': 'Export employee log',
  'location.workplace.employee_registration_flow.read_set.description': 'View employee registration flows',
  'location.workplace.employee_registration_flow.edit_set.description': 'Edit employee registration flows',
  'location.workplace.employee_scheduling_settings.manage_set.description':
    'Edit employee schedule visibility settings, required documents, and employee sign-in and sign-out notifications',
  'location.workplace.employee_announcement.manage_set.description': 'Post and archive employee announcements',
  'location.workplace.ticket.manage_set.description':
    'Manage settings of ticketing categories and email address tickets are sent to',

  'location.maps.live_map.manage_set.description':
    'Add and delete floors, manage resources on the map, and manage neighborhoods',

  'location.maps.draft_map.manage_set.description': 'Add, edit and delete floors, maps, and points of interest',

  'location.desks.desk.manage_set.description':
    'Add or delete desks, rename desks, manage desk amenities & neighborhoods, assign permanent desks, and deactivate the location for desks',
  'location.desks.desk_settings.manage_set.description':
    'Manage settings including booking days or hours, space saver, and automatic desk assignment',
  'location.desks.reservation.manage_set.description':
    'Add, edit, or remove desk reservations on behalf of other employees',

  'location.rooms.room.assign_set.description': 'Assign synced rooms to location',
  'location.rooms.room.manage_set.description':
    'View, add, edit, or remove rooms. Manage rooms on the map. Edit room settings, connect or remove tablets from rooms. Manage tablet display settings.',
  'location.rooms.device.manage_set.description':
    'Manage device status notifications and admin access codes to tablets',

  'location.deliveries.delivery.read_set.description': 'View all deliveries',
  'location.deliveries.delivery.create_set.description': 'Scan deliveries using the Envoy mobile app',
  'location.deliveries.delivery.update_delete_set.description': 'Edit delivery details and delete deliveries',
  'location.deliveries.delivery.export_set.description': 'Export deliveries log',
  'location.deliveries.delivery_area.create_set.description': 'Add delivery areas',
  'location.deliveries.delivery_settings.manage_set.description':
    'Edit delivery settings, including notification settings and nicknames',
  'location.deliveries.delivery_device.manage_set.description': 'Add, edit, or remove delivery devices',

  'location.communications.announcement.manage_set.description':
    'Send messages to employees and/or visitors, view message log and message details, and archive messages',
  'location.communications.announcement_template.manage_set.description':
    'Add, edit, or delete message templates assigned to location',
  'location.communications.communication_setting.manage_set.description':
    'Export people currently on-site and manage message settings',

  'location.apps.location_app.manage_set.description':
    'Install and configure location-based apps, including access control, wifi, and more',

  'location.user_management.employee.read_set.description': 'View all employees at the location',
  'location.user_management.employee.manage_set.description':
    'Add and remove employees, except employees synced via SCIM groups',
  'location.user_management.employee.export_set.description': 'Export all employees at the location',
  'location.user_management.admin.read_set.description': 'View admin roles assigned to users',
  'location.user_management.location_admin.manage_set.description':
    'Assign or remove location roles from users. Includes default and custom roles.',
  'location.user_management.activity_log.read_set.description': 'View activity log entries',

  'location.location_settings.location_settings.manage_set.description':
    'Edit location info, branding, API key, language, daily capacity limits, and sign out settings for all locations. Connect properties and deactivate locations.',
  'location.location_settings.setup_guide.read_set.description': 'View guides to get started with Envoy',
};
