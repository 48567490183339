import { action } from '@ember/object';
import { scheduleOnce } from '@ember/runloop';
import { dasherize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dedupeTracked, localCopy } from 'tracked-toolbox';

function id(parentId: string, child: string | { id: string }) {
  if (typeof child === 'object') {
    return `${parentId}-${child.id}`;
  } else {
    return `${parentId}-${dasherize(child)}`;
  }
}

interface ComboBoxSignature {
  Args: {
    activeOptionClass?: string;
    ariaLabel?: string;
    arialLabelledBy?: string;
    closestOption?: string;
    emptyOption?: string;
    inputClass?: string;
    inputId?: string;
    invalidInputClass?: string;
    invalidLabelClass?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isReadonly?: boolean;
    listboxClass?: string;
    onInput?: (value: string) => string;
    onFocusOut?: () => void;
    onSelect?: (value: string) => void;
    options: string[];
    optionClass?: string;
    placeholder?: string;
    scrollToSelection?: boolean;
    searchField?: string;
    selected?: string | { id: string; [key: string]: string };
    selectedOptionClass?: string;
    showDropdownArrow?: boolean;
    shouldDisableOption?: (option: string) => boolean;
    type?: string;
    validateUserInput?: (userInput: string, selectedValue: string) => boolean;
  };
}

export default class ComboBox extends Component<ComboBoxSignature> {
  @tracked declare element: HTMLElement;
  @tracked isUserInputValid = true;
  @tracked userInput: string | null = null;

  @dedupeTracked shouldShowListbox = false;
  @dedupeTracked activedescendant: string | null = null;

  @localCopy('args.emptyOption', 'no options') emptyOption!: string;
  @localCopy('args.scrollToSelection', true) scrollToSelection!: boolean;
  @localCopy('args.type', 'text') type!: string;

  @action
  registerElement(element: HTMLElement): void {
    this.element = element;
  }

  // tracks the id of the currently focused option
  get selectedValue(): string {
    return this.args.searchField && this.args.selected && typeof this.args.selected === 'object'
      ? this.args.selected[this.args.searchField]!
      : <string>this.args.selected || '';
  }

  get value(): string | null {
    if (!this.args.selected || !this.isUserInputValid) {
      return this.userInput;
    } else {
      return this.selectedValue;
    }
  }

  get optionIds(): string[] {
    return (this.args.options || []).map((option) => id(this.element.id, option));
  }

  get selectedId(): string | null {
    if (this.args.selected) {
      return id(this.element.id, this.args.selected);
    }
    return null;
  }

  get closestOptionId(): string | null {
    if (this.args.closestOption) {
      return id(this.element.id, this.args.closestOption);
    }
    return null;
  }

  @action
  focusIn(): void {
    this._handleFocusIn();
  }

  @action
  focusOut(event: FocusEvent): void {
    const { relatedTarget } = event;
    // focusOut fires when focus is moved to the options
    // only handle a focusOut if focus is actual leaving the component
    if (!this.element.contains(<Node>relatedTarget)) {
      this.args.onFocusOut?.();
      this._handleFocusOut();
    }
  }

  @action
  keyUp(e: KeyboardEvent): void {
    // handle keyboard events. allow user to navigate combobox according to WAI-ARIA Combobox spec
    switch (e.key) {
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        this._handleArrowUp();
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        this._handleArrowDown();
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Enter':
        this._handleEnter();
        e.stopPropagation();
        e.preventDefault();
        break;

      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this._handleEscape();
        e.stopPropagation();
        e.preventDefault();
        break;
    }
  }

  showListBox(): void {
    const { isDisabled, isReadonly } = this.args;

    this.shouldShowListbox = !(isDisabled || isReadonly);
  }

  _handleArrowUp(): void {
    //    select the previous selection
    // OR return focus to the input if there is no previous selection
    const input = <HTMLElement>this.element.querySelector('[role="combobox"]');
    let activeOptionId = null;
    const activeOptionIndex = this.activedescendant ? this.optionIds.indexOf(this.activedescendant) : -1;
    if (activeOptionIndex === 0) {
      input?.focus();
    } else if (activeOptionIndex !== -1) {
      activeOptionId = this.optionIds[activeOptionIndex - 1];
    } else if (this.closestOptionId) {
      activeOptionId = this.closestOptionId;
    }
    if (activeOptionId) {
      const activeOptionEl = document.getElementById(activeOptionId);
      activeOptionEl?.focus();
      this.activedescendant = activeOptionId;
    }
  }

  _handleArrowDown(): void {
    //    noop if at bottom of list and has active option
    // OR focus the next option if has active option
    // OR focus the selected option if no active option and has selection
    // OR focus the first option if no active option and no selection
    let activeOptionId: string | undefined;
    if (!this.shouldShowListbox) {
      this.showListBox();
      this._scrollToSelection();
    }
    if (this.activedescendant) {
      const activeOptionIndex = this.optionIds.indexOf(this.activedescendant);
      if (activeOptionIndex !== this.optionIds.length - 1) {
        activeOptionId = this.optionIds[activeOptionIndex + 1];
      } else {
        activeOptionId = this.activedescendant;
      }
    } else if (this.args.selected) {
      const selectedIndex = this.selectedId ? this.optionIds.indexOf(this.selectedId) : -1;
      // the selected item may not exist in the options
      // if it doesn't use the closest option, otherwise use the first option
      if (selectedIndex !== -1) {
        activeOptionId = this.optionIds[selectedIndex];
      } else if (this.closestOptionId) {
        activeOptionId = this.closestOptionId;
      } else {
        activeOptionId = this.optionIds[0];
      }
    } else {
      activeOptionId = this.optionIds[0];
    }
    if (activeOptionId) {
      const activeOptionEl = document.getElementById(activeOptionId);
      activeOptionEl?.focus();
      this.activedescendant = activeOptionId;
    }
  }

  _handleEnter(): void {
    //    select the focused suggestion
    // OR select the first suggestion as a default
    const activeOptionEl = document.getElementById(this.activedescendant || this.optionIds[0]!);
    activeOptionEl?.click();
  }

  _handleEscape(): void {
    // clear the userInput and suggestions, and return focus to input
    const input = <HTMLElement>this.element.querySelector('[role="combobox"]');
    this.userInput = '';
    this.activedescendant = null;
    input?.focus();
    this.shouldShowListbox = false; // hide the listbox after focusIn fires
  }

  _handleFocusIn(): void {
    if (!this.shouldShowListbox) {
      this.showListBox();
      this._scrollToSelection();
    }
  }

  _handleFocusOut(): void {
    if (!this.isUserInputValid) {
      // prevent the input being left in an invalid state
      this.userInput = this.selectedValue;
      this.isUserInputValid = true;
    }
    this.shouldShowListbox = false;
    this.activedescendant = null;
  }

  _scrollToSelection(): void {
    let scrollToId: string | null = null;
    if (this.selectedId && this.optionIds.includes(this.selectedId)) {
      scrollToId = this.selectedId;
    } else if (this.closestOptionId && this.optionIds.includes(this.closestOptionId)) {
      scrollToId = this.closestOptionId;
    }
    if (this.scrollToSelection && scrollToId) {
      // schedule afterRender to make sure the dropdown options are rendered
      /* eslint-disable ember/no-incorrect-calls-with-inline-anonymous-functions */

      // ember-lifeline explicitly doesn't allow scheduleTask on 'afterRender'
      // eslint-disable-next-line ember/no-runloop
      scheduleOnce('afterRender', this, function () {
        if (this.isDestroying || this.isDestroyed) return;
        const el = document.getElementById(scrollToId);
        el?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'start' });
      });
      /* eslint-enable ember/no-incorrect-calls-with-inline-anonymous-functions */
    }
  }

  @action
  didType(event: InputEvent): void {
    this.showListBox();
    let userInput = (<HTMLInputElement>event.target!).value;
    this.userInput = userInput;
    userInput = this.args.onInput?.(userInput) ?? userInput;
    const isUserInputValid = this.args.validateUserInput?.(userInput, this.selectedValue) ?? true;
    this.isUserInputValid = isUserInputValid;
    if (isUserInputValid) {
      this.args.onSelect?.(userInput);
    }
  }

  @action
  shouldDisableOption(option: string): boolean {
    return this.args.shouldDisableOption?.(option) ?? false;
  }

  @action
  didSelect(option: string): void {
    if (!this.shouldDisableOption(option)) {
      const input = <HTMLElement>this.element.querySelector('[role="combobox"]');
      this.args.onSelect?.(option);
      this.isUserInputValid = true;
      this.activedescendant = null;
      input?.focus();
      this.shouldShowListbox = false; // hide the listbox after focusIn fires
    }
  }

  @action
  didClickInput(): void {
    this.showListBox();
    this._scrollToSelection();
  }
}
