import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsWelcomeScreenIndexRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo('visitors.settings.welcome-screen.design');
  }
}
