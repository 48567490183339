import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaDevicesNewRoute extends Route {
  @service store;

  model() {
    const store = this.store;
    const { deliveryArea } = this.modelFor('deliveries.settings.delivery-areas.delivery-area');
    const devices = store.query('delivery-device', { filter: { 'delivery-area': deliveryArea.id } });

    const newDevice = store.createRecord('deliveryDevice', { deliveryArea });

    return hash({
      currentDevices: devices,
      newDevice,
    });
  }

  resetController(controller) {
    const newDevice = controller.model.newDevice;

    if (get(newDevice, 'isNew')) {
      newDevice.deleteRecord();
    }
  }
}
