import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask, task } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type CompanyModel from 'garaje/models/company';
import type EmployeeModel from 'garaje/models/employee';
import type LocationModel from 'garaje/models/location';
import type { KioskForceUpgradeMessage } from 'garaje/models/location';
import type SubscriptionModel from 'garaje/models/subscription';
import type ZoneModel from 'garaje/models/zone';
import type AuthzService from 'garaje/services/authz';
import type ContextSwitcherService from 'garaje/services/context-switcher';
import type CurrentAdminService from 'garaje/services/current-admin';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type GrowthFeatureSetupService from 'garaje/services/growth-feature-setup';
import type ImpressionsService from 'garaje/services/impressions';
import type IsOpenService from 'garaje/services/is-open';
import type LocalStorageService from 'garaje/services/local-storage';
import type LoggerService from 'garaje/services/logger';
import type MetricsService from 'garaje/services/metrics';
import type PubnubService from 'garaje/services/pubnub';
import type SessionService from 'garaje/services/session';
import type StateService from 'garaje/services/state';
import { IMPRESSION_NAMES, SETUP_NAMES } from 'garaje/utils/enums';
import { alias, or } from 'macro-decorators';
import moment from 'moment-timezone';
import { resolve } from 'rsvp';
import semver from 'semver';

import type { CurrentZoneRouteModel } from '../current-zone/route';

interface EmployeeGatingEventProps extends AnalyticsTraits {
  company_id: string;
  employeeCount: number | null;
  project: string;
  product: string;
  timestamp: number;
}
interface EmployeeGatingModalQueryProps {
  page?: {
    limit: number;
    offset: number;
  };
  filter?: Record<string, unknown>;
  include?: string;
  sort?: string;
}

export default class ProtectedController extends Controller {
  declare model: {
    kioskForceUpgradeMessage: KioskForceUpgradeMessage;
  };

  // Where do these get set?
  declare desksSubscription?: SubscriptionModel;
  declare roomsSubscription?: SubscriptionModel;
  declare manuallyAddedFilter?: boolean;
  declare documentStatusFilter?: boolean;
  declare page: number;
  //
  @service declare abilities: AbilitiesService;
  @service declare contextSwitcher: ContextSwitcherService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare currentLocation: CurrentLocationService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare impressions: ImpressionsService;
  @service declare isOpen: IsOpenService;
  @service declare growthFeatureSetup: GrowthFeatureSetupService;
  @service declare localStorage: LocalStorageService;
  @service declare logger: LoggerService;
  @service declare metrics: MetricsService;
  @service declare pubnub: PubnubService;
  @service declare router: RouterService;
  @service declare session: SessionService;
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare authz: AuthzService;
  @service declare globalOverviewAccess: GlobalOverviewAccessService;

  @tracked zones: CurrentZoneRouteModel['zones'] | ZoneModel[] = [];
  @tracked currentZone?: ZoneModel | null = null;

  @tracked dismissableForceUpgradeFlash = true;
  @tracked showTrialExtendedModal = false;
  @tracked showPremiumNudgeModal = true;

  // in cypress mode, we want the sidebar height not to be pinned to a max of 100vh so
  // that we can see the entire sidebar content. if we set the sidebar to have 100vh
  // height, the menu will be cut off on short pages like the welcome screen settings
  // pages.
  isSidebarViewportHeight = !config.isCypress;
  @tracked newLocationOrZoneName = '';
  @tracked switchCompanyWarning = false;
  @tracked switchToCompanyId: string | null = null;
  @tracked _isNewVersionAvailable?: boolean;
  @tracked limit = 50;
  @tracked employees = [];
  @tracked totalEmployees: number | null = 0;
  @tracked showEmployeeGatingModal = false;
  @tracked showEmployeeActionModal = false;
  @tracked showPremiumFeatureModal = false;
  @tracked showEmployeeDeletionModal = false;
  @tracked showConfirmDeleteModal = false;
  @tracked showEmployeeImportModal = false;
  @tracked employeesToDelete: EmployeeModel[] | null = null;
  @tracked employeesToImport: EmployeeModel[] | null = null;
  @tracked csvToDelete: EmployeeModel[] | null = null;
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, ember/no-get
  @tracked currentLocationId = get(this.currentLocation, 'id');
  @tracked gatingImpressionCount = null;
  @tracked canCloseExperience = true;
  @tracked impressionsCount = 0;
  @tracked showContactBdrModalPrimary = false;
  @tracked showContactBdrModal = false;
  @tracked showContactBdrConfirmationModal = false;
  @tracked showSignupQuestionsModal = false;
  @tracked showSignupQuestionsCloseModal = false;
  @tracked newLocationId?: string;

  @alias('state.vrSubscription') vrSubscription!: StateService['vrSubscription'];
  @alias('state.workplaceSubscription') workplaceSubscription!: StateService['workplaceSubscription'];
  @alias('state.currentCompany') company!: StateService['currentCompany'];
  @alias('state.currentUser') user!: StateService['currentUser'];
  @alias('user.privacyPolicyAcceptance')
  privacyPolicyAcceptance!: StateService['currentUser']['privacyPolicyAcceptance'];
  @alias('vrSubscription.isSubscribed') isSubscribed!: SubscriptionModel['isSubscribed'];
  @alias('company.active') isCompanyActive!: CompanyModel['active'];
  @alias('currentAdmin.isBillingAdmin') isBillingAdmin!: CurrentAdminService['isBillingAdmin'];
  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin!: CurrentAdminService['isGlobalAdmin'];
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin!: CurrentAdminService['isLocationAdmin'];
  @or('trialIsNotExpired', 'onPlansOrSubscribePage') hasTrialDaysLeftOrOnPlans!: boolean;
  @or('isSubscribed', 'isCompanyActive') trialIsNotExpired!: boolean;

  get isBoss(): boolean {
    return this.localStorage.getItem('is_boss') === 'true';
  }

  get isNewVersionAvailable(): boolean {
    if (isPresent(this._isNewVersionAvailable)) {
      return this._isNewVersionAvailable;
    }

    const currentSemver = semver.coerce(this.currentLocation.location?.currentVersion);
    if (!currentSemver) {
      return false;
    }
    const versions = this.currentLocation
      // not using get breaks auto tracking
      // eslint-disable-next-line ember/use-ember-get-and-set
      .get('iPadVersions')
      .map((version) => semver.coerce(version)?.version)
      .filter((version) => !!version);
    const currentMinorVersion = currentSemver.version.replace(currentSemver.patch as unknown as string, '0');
    return versions.some((version) => semver.lt(version!, currentMinorVersion));
  }

  set isNewVersionAvailable(value: boolean) {
    this._isNewVersionAvailable = value;
  }

  get onPlansOrSubscribePage(): boolean {
    const routeName = this.router.currentRouteName;
    return routeName === 'billing.plans' || routeName === 'billing.subscribe';
  }

  get employeeGatingEventProps(): EmployeeGatingEventProps {
    return {
      company_id: this.company.id,
      employeeCount: this.totalEmployees,
      project: '50 Employees Feature Gating',
      product: 'employees',
      timestamp: Math.floor(new Date().getTime() / 1000),
    };
  }

  get showTrialExpired(): boolean {
    if (!this.vrSubscription) {
      return false;
    }
    if (this.featureFlags.isEnabled('growth-trial-end-interstitial')) {
      return false;
    }
    const plan = this.vrSubscription.plan;
    return plan !== 'basic' && !this.hasTrialDaysLeftOrOnPlans;
  }

  get showTrialEndModal(): boolean {
    // don't show modal if feature flag is not enabled
    if (!this.featureFlags.isEnabled('growth-trial-end-interstitial')) return false;

    // don't show modal if visitor subscription is not a trial
    if (!(this.vrSubscription?.onTrial || this.vrSubscription?.onExpiredTrial)) return false;

    // only show modal on Visitors pages
    if (!this.isOpen.isVizRegOpen) return false;

    // show modal when trial is within 3 days of expiration
    const now = moment();
    const trialEndDate = moment(this.vrSubscription.trialEndDate);
    const doesTrialExpireWithinThreeDays = now.isBetween(moment(trialEndDate).subtract(3, 'days'), trialEndDate);
    if (doesTrialExpireWithinThreeDays) return true;

    return false;
  }

  get showLocationGatingModal(): boolean {
    if (!this.vrSubscription) return false;

    // don't show modal if it is on location billing
    if (this.featureFlags.isEnabled('locationBilling')) return false;

    // don't show modal when adding a new location
    if (this.router.currentRouteName == 'new-location') return false;

    // only show modal on Visitors pages
    if (!this.isOpen.isVizOpen) return false;

    // don't show modal if visitor subscription is on trial
    if (this.isOnTrial) return false;

    // show the modal for all basic plan users
    if (this.vrSubscription.isBasicPlan) return true;

    // don't show modal if the gate is not enabled and
    // the customer is on visitors enterprise or ACH payment
    if (
      !this.featureFlags.isEnabled('growth-show-location-gating') &&
      (this.vrSubscription.isEnterprisePlan || this.state.billingCompany.isAchTransfer)
    )
      return false;

    return true;
  }

  get showPremiumNudgeNotification(): boolean {
    if (this.featureFlags.isEnabled('growth_show_personalized_homepage')) {
      return false;
    }

    const isBasicUser =
      this.vrSubscription &&
      (this.vrSubscription.isBasicPlan || (this.vrSubscription.plan && this.vrSubscription.plan.includes('standard')));
    if (
      this.vrSubscription &&
      !isBasicUser &&
      this.featureFlags.isEnabled('employeeFeatureGating') &&
      this.vrSubscription.hasTrialDaysLeft &&
      this.vrSubscription.onTrial
    ) {
      const date1 = new Date(this.vrSubscription.trialEndDate).getTime();
      const date2 = new Date().getTime();
      const diffTime = Math.abs(date1 - date2);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays === 3 && this.showPremiumNudgeModal) {
        return true;
      }
      return false;
    }
    return false;
  }

  get dashboardOrVisitorsOpen(): boolean {
    return this.isOpen.isVizRegOpen || this.isOpen.isDashboardOpen;
  }

  get showPrivacyPolicyModal(): boolean {
    return !this.isBoss && !(this.privacyPolicyAcceptance === 'accepted');
  }

  get isOnTrial(): boolean {
    return Boolean(this.vrSubscription && this.vrSubscription.onTrial);
  }

  get canViewBuyNowLink(): boolean {
    return this.abilities.can('visit billing') && !this.state.billingCompany.resellerPartner;
  }

  requireReAuthenticationForCompany = dropTask(async () => {
    const companyId = this.switchToCompanyId;
    const urlAfterInvalidation = `${config.rootURL}login?companyId=${companyId}`;
    this.localStorage.setItem('urlAfterInvalidation', urlAfterInvalidation);
    await this.session.invalidate();
  });

  getEmployeeGatingModal = dropTask(async () => {
    let isConnectedToProperty = false;
    if (this.state.currentLocation) isConnectedToProperty = await this.state.currentLocation.isConnectedToProperty();
    if (
      this.featureFlags.isEnabled('employeeFeatureGating') &&
      this.dashboardOrVisitorsOpen &&
      (!this.state.features?.canAccessUnlimitedEmployees || this.isOnTrial) &&
      !isConnectedToProperty
    ) {
      if (
        this.vrSubscription &&
        this.vrSubscription.isBasicPlan &&
        this.featureFlags.isEnabled('employeeGatingHardSell')
      ) {
        await this.impressions.postImpression.perform(IMPRESSION_NAMES.EMPLOYEE_GATING);
        const impressions = await this.impressions.getImpressions.perform(IMPRESSION_NAMES.EMPLOYEE_GATING);
        this.impressionsCount = impressions!.length;
        if (this.impressionsCount > 3) {
          this.canCloseExperience = false;
        }
        if (!this.canCloseExperience) {
          this.metrics.trackEvent('User unable to close employee gating experience', this.employeeGatingEventProps);
        }
      }
      const employees = <PaginatedRecordArray<EmployeeModel>>await this.store.query('employee', this.buildQuery());
      this.totalEmployees = employees.meta.total;
      if (this.totalEmployees > 50 && !this.state.features?.canAccessUnlimitedEmployees) {
        this.showEmployeeGatingModal = true;
        this.metrics.trackEvent('50 Employee Gating Modal Shown', this.employeeGatingEventProps);
      } else if (this.totalEmployees > 50 && this.isOnTrial) {
        const localStorageModalShown = this.localStorage.getItem('premium-feature-gating-modal-shown');
        const growthServiceModalShown = await this.impressions.getImpressions.perform(
          IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN,
        );
        const hasSeenModal = localStorageModalShown || growthServiceModalShown;
        if (!hasSeenModal) {
          this.showPremiumFeatureModal = true;
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN);
          this.metrics.trackEvent('Premium Feature Modal Shown', this.employeeGatingEventProps);
        }
        if (localStorageModalShown && !growthServiceModalShown) {
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN);
        }
      }
    }
  });

  getContactBdrModalTask = task({ drop: true }, async () => {
    if (
      !this.featureFlags.isEnabled('contact-bdr') ||
      !(this.isGlobalAdmin || this.isBillingAdmin) ||
      !this.isOpen.isDashboardOpen
    )
      return;
    const hasSeenBdrModal = await this.impressions.getImpressions.perform(IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN);
    const hasSeenBdrModalOnBasic = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN_BASIC,
    );
    const hasBeenRoutedToCP = await this.impressions.getImpressions.perform(
      IMPRESSION_NAMES.CHILI_PIPER_MEETING_URL_OPENED,
    );

    if (this.vrSubscription && this.vrSubscription.onTrial && hasSeenBdrModal?.length === 0) {
      this.showContactBdrModalPrimary = true;
      await this.impressions.postImpression.perform(IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN);
    } else if (this.vrSubscription && this.vrSubscription.isBasicPlan && hasSeenBdrModalOnBasic?.length === 0) {
      const entryParams = {
        filter: {
          endDate: moment().add(1, 'days').format('YYYY-MM-DD'),
          startDate: moment().subtract(13, 'days').format('YYYY-MM-DD'),
        },
      };

      const entries = await this.store.query('entry', entryParams);

      if (entries.toArray().length > 10) {
        this.showContactBdrModalPrimary = true;
        await this.impressions.postImpression.perform(IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN_BASIC);
      }
    } else if (this.vrSubscription && this.vrSubscription.onTrial && hasBeenRoutedToCP?.length === 0) {
      const date1 = this.vrSubscription.trialEndDate.getTime();
      const date2 = new Date().getTime();
      const diffTime = Math.abs(date1 - date2);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 7 && hasSeenBdrModal?.length === 1 && hasSeenBdrModal[0]!.count < 3) {
        const entries = await this.store.query('entry', {});

        if (entries.toArray().length >= 14) {
          this.showContactBdrModalPrimary = true;
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN);
        }
      } else if (hasSeenBdrModal?.length === 1 && hasSeenBdrModal[0]!.count === 1) {
        const hasActionsCompleted = await this.checkActionsCompleted.perform();

        if (hasActionsCompleted) {
          this.showContactBdrModalPrimary = true;
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.CONTACT_BDR_MODAL_SHOWN);
        }
      }
    }
  });

  checkActionsCompleted = task({ drop: true }, async () => {
    const actions = ['custom_notifications_enabled', 'sign_in_photos_enabled', 'agreement_page_enabled'];
    let actionCount = 0;
    for (const action of actions) {
      if (actionCount == 1) {
        break;
      }
      const result = await this.growthFeatureSetup.getSetupTask.perform(action);
      if (result.length > 0) {
        actionCount = actionCount + 1;
      }
    }
    return actionCount > 0;
  });

  checkSignupQuestions = task({ drop: true }, async () => {
    if (this.featureFlags.isEnabled('customer-signup-survey') && this.currentAdmin.isAdminLike) {
      const hasCompletedSurvey = await this.growthFeatureSetup.getSetupTask.perform(
        SETUP_NAMES.SIGNUP_SURVEY_COMPLETED,
      );
      if (isPresent(hasCompletedSurvey)) {
        return;
      }

      const hasClosedSurvey = await this.impressions.getImpressions.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_CLOSED);
      if (isPresent(hasClosedSurvey)) {
        return;
      }
      // only Connect customers will see it right after signup
      if (
        !(this.state.tenantConnectionRequests || this.state.tenantConnections) ||
        !(this.state.tenantConnectionRequests?.length !== 0 || this.state.tenantConnections?.length !== 0)
      ) {
        return;
      }
      this.showSignupQuestionsModal = true;
    }
  });

  closeSignupQuestionsModal = task({ drop: true }, async () => {
    this.showSignupQuestionsModal = false;
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_SUBMITTED);
    this.router.transitionTo('visitors.entries');
  });

  closeSignupQuestionsCloseModal = task({ drop: true }, async () => {
    this.showSignupQuestionsCloseModal = false;
    this.showSignupQuestionsModal = false;
    await this.impressions.postImpression.perform(IMPRESSION_NAMES.SIGNUP_SURVEY_CLOSED);
  });

  @action
  openContactBdrModal(): void {
    this.showContactBdrModalPrimary = false;
    this.showContactBdrModal = true;
  }

  @action
  openConfirmationModal(): void {
    this.showContactBdrModal = false;
    this.showContactBdrConfirmationModal = true;
  }

  @action
  switchZoneFromMenu(zone: ZoneModel): void {
    const companyId = zone.belongsTo('company').id();
    const zoneDoesNotBelongToCurrentCompany = this.session.companyId !== companyId;

    if (zoneDoesNotBelongToCurrentCompany) {
      this.switchToCompanyId = companyId;
      this.switchCompanyWarning = true;
      this.newLocationOrZoneName = zone.name;
    } else {
      const baseRoute = this.router.currentRouteName.startsWith('property.')
        ? this.router.currentRouteName
        : 'property';
      void this.contextSwitcher.transitionToPropertyRoute(baseRoute, zone);
    }
  }

  @action
  switchLocationFromMenu(location: LocationModel): void {
    let transition: Transition | Promise<unknown> = resolve();
    const currentRouteName = this.router.currentRouteName;
    switch (currentRouteName) {
      case 'employees.directory.employee':
        transition = this.router.transitionTo('employees');
        break;
      case 'visitors.entry':
        transition = this.router.transitionTo('visitors.entries');
        break;
      case 'deliveries.log.index':
        transition = this.router.transitionTo({ queryParams: { deliveryArea: 'current location' } });
        break;
      case 'desks.show.index':
        transition = this.router.transitionTo('desks');
        break;
      case 'desks.show':
        transition = this.router.transitionTo('desks');
        break;
      case 'spaces.maps.edit.show':
        transition = this.router.transitionTo('spaces.maps.edit');
        break;
      case 'spaces.maps.live.show':
        transition = this.router.transitionTo('spaces.maps.live');
        break;
      case 'desks.reservations':
        transition = this.router.transitionTo({
          queryParams: {
            employees: [],
            desks: [],
            floors: [],
          },
        });
    }
    if (/^location-overview|^my-locations|^connect/.test(currentRouteName)) {
      // Since location overview is a _global_ route,
      // we want to redirect to something relative to the location
      transition = this.router.transitionTo('dashboard');
    }

    const companyId = location.belongsTo('company').id();
    const locationDoesNotBelongToCurrentCompany = this.session.companyId !== companyId;

    if (locationDoesNotBelongToCurrentCompany) {
      this.switchToCompanyId = companyId;
      this.switchCompanyWarning = true;
      this.newLocationOrZoneName = location.name;
    } else {
      let redirect: string;
      let redirectArgs: Record<string, unknown>;
      const newLocationId = location.id;

      // apply proper redirect if coming from a property
      if (this.contextSwitcher.zoneId) {
        redirect = 'dashboard';
      }

      if (this.router.currentRouteName === 'manage.admin-users.index') {
        if (this.abilities.cannot('visit location in admin-roles', newLocationId)) {
          redirect = 'dashboard';
        } else {
          // switching location from admins page should pre-filter it by the new location.
          this.newLocationId = location.id;
          redirect = 'manage.admin-users';
          redirectArgs = { queryParams: { locationsFilter: [this.newLocationId] } };
        }
      }

      if ('followRedirects' in transition) transition = transition.followRedirects();

      void transition
        .then(() => this.send('switchLocation', newLocationId, redirect, redirectArgs))
        .catch((e) => this.logger.error(`Transition to newLocation ${newLocationId} failed: ${e}`));
    }
  }

  @action
  transitionToLocationOverview(): void {
    const route = this.globalOverviewAccess.firstAccessibleRouteForGlobalOverview();
    if (route) {
      this.router.transitionTo(route);
      return;
    }

    this.router.transitionTo('unauthorized');
  }

  @action
  switchLocation(locationId: string, redirect: string, redirectArgs: Record<string, unknown>): boolean {
    // This is called from the new-location controller
    this.pubnub.instanceFor('current-location')?.unsubscribe({
      channel: this.currentLocation.location.pubnubChannelEntry,
    });

    this.contextSwitcher.locationId = locationId;

    if (redirect && redirectArgs) {
      this.router.transitionTo(redirect, redirectArgs);
    } else if (redirect) {
      this.router.transitionTo(redirect);
    }

    return true;
  }

  @action
  addLocation(): void {
    this.router.transitionTo('new-location');
  }

  @action
  boss(): void {
    this.send('redirectToBoss', '/dashboard');
  }

  @action
  trackInterstitalControlGroup(): void {
    const { currentUser } = this.state;
    const timestamp = Math.floor(new Date().getTime() / 1000);
    this.metrics.trackEvent('Trial End Interstitial Control Group', {
      user_id: currentUser.id,
      company_id: this.company.id,
      project: 'dashboard',
      product: 'visitors',
      timestamp: timestamp,
    });
  }

  @action
  dismissForceUpgradeMessage(): void {
    this.dismissableForceUpgradeFlash = false;
    this.metrics.trackEvent('CTA Dismissed', {
      cta_id: 'force_upgrade_banner',
      cta_type: 'banner',
      cta_clickable_type: 'none',
      cta_title: this.model.kioskForceUpgradeMessage.title,
      cta_body: this.model.kioskForceUpgradeMessage.body,
    });
  }

  buildQuery(): EmployeeGatingModalQueryProps {
    const limit = this.limit;
    const offset = (this.page - 1) * limit;
    const params: {
      page?: { limit: number; offset: number };
      filter?: Record<string, unknown>;
      include?: string;
      sort?: string;
    } = {};

    params.page = { limit, offset };

    params.filter = {
      locations: this.currentLocation.location?.id,
      deleted: false,
    };

    if (this.manuallyAddedFilter) {
      params.filter['manually-added'] = this.manuallyAddedFilter;
    }

    if (this.documentStatusFilter) {
      params.filter['document-approval-status'] = this.documentStatusFilter;
      params.filter['document-identifier'] = 'covid-19-vaccine-card';
    }

    params.include = 'assistants';
    params.sort = 'name';
    return params;
  }

  @action
  closeGatingExperience(): void {
    if (this.showEmployeeGatingModal) {
      this.showEmployeeGatingModal = false;
      this.metrics.trackEvent('50 Employee Gating Modal Closed', this.employeeGatingEventProps);
      if (this.impressionsCount === 3) {
        this.showEmployeeActionModal = true;
      }
    } else if (this.showPremiumFeatureModal) {
      this.showPremiumFeatureModal = false;
      this.metrics.trackEvent('Premium Feature Modal Closed', this.employeeGatingEventProps);
    }
  }

  @action
  closeEmployeeActionModal(): void {
    this.showEmployeeActionModal = false;
    this.metrics.trackEvent('Employee Action Modal Closed', this.employeeGatingEventProps);
  }

  @action
  closeEmployeeGatingModal(): void {
    if (this.showEmployeeGatingModal) {
      this.showEmployeeGatingModal = false;
      this.metrics.trackEvent('50 Employee Gating Modal Closed', this.employeeGatingEventProps);
      if (!this.isBillingAdmin) {
        if (this.featureFlags.isEnabled('employeeGatingImport')) {
          this.showEmployeeImportModal = true;
          this.metrics.trackEvent('50 Employee Gating Import Modal Shown', this.employeeGatingEventProps);
        } else {
          this.showEmployeeDeletionModal = true;
          this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
        }
      }
    } else if (this.showPremiumFeatureModal) {
      this.showPremiumFeatureModal = false;
      this.metrics.trackEvent('Premium Feature Modal Closed', this.employeeGatingEventProps);
    }
  }

  @action
  closeEmployeeImportModal(data: EmployeeModel[]): void {
    this.showEmployeeImportModal = false;
    this.employeesToImport = data;
    this.metrics.trackEvent('50 Employee Gating Import Modal Closed', this.employeeGatingEventProps);
    this.showEmployeeDeletionModal = true;
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
  }

  @action
  closeGatingViaImport(): void {
    this.showEmployeeImportModal = false;
    this.totalEmployees = null;
    this.metrics.trackEvent(
      '50 Employee Gating Import Modal Closed Via Import of Employees',
      this.employeeGatingEventProps,
    );
  }

  @action
  closeEmployeeDeletionModal(employeeArray: EmployeeModel[], isCsv: boolean): void {
    this.showEmployeeDeletionModal = false;
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Closed', this.employeeGatingEventProps);
    if (isPresent(employeeArray) && !isCsv) {
      this.employeesToDelete = employeeArray;
      this.showConfirmDeleteModal = true;
    } else if (isPresent(employeeArray) && isCsv) {
      this.csvToDelete = employeeArray;
      this.showConfirmDeleteModal = true;
    }
    this.metrics.trackEvent('Delete Confirmation Modal Shown', this.employeeGatingEventProps);
  }

  @action
  closeConfirmDeleteModal(csv?: EmployeeModel[]): void {
    if (csv) {
      this.employeesToImport = csv;
    }
    this.showConfirmDeleteModal = false;
    this.metrics.trackEvent('Delete Confirmation Modal Closed', this.employeeGatingEventProps);
    this.showEmployeeDeletionModal = true;
  }

  @action
  afterDelete(updatedCsv?: EmployeeModel[]): void {
    // refresh model
    if (updatedCsv) {
      this.employeesToImport = updatedCsv;
      this.showConfirmDeleteModal = false;
      this.showEmployeeDeletionModal = true;
    } else if (this.employeesToDelete && this.totalEmployees! - this.employeesToDelete.length > 50) {
      this.totalEmployees = this.totalEmployees! - this.employeesToDelete.length;
      this.showConfirmDeleteModal = false;
      this.showEmployeeDeletionModal = true;
    } else {
      this.showConfirmDeleteModal = false;
      this.totalEmployees = null;
    }
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
  }

  @action
  trackUpgradeToPremiumClick(): void {
    this.metrics.trackEvent('Upgrade To Premium Clicked', this.employeeGatingEventProps);
  }

  @action
  _clickLogo(isBoss: boolean): void {
    this.send('clickLogo', isBoss);
  }

  @action
  _editProfile(): void {
    this.send('editProfile');
  }

  @action
  _logout(): void {
    this.send('logout');
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/controller' {
  interface Registry {
    protected: ProtectedController;
  }
}
