import Controller from '@ember/controller';
import { inject as controller } from '@ember/controller';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { alias, reads, or } from 'macro-decorators';

export default class LocationOverviewGlobalDevicesIpadsController extends Controller {
  @service abilities;
  @service flashMessages;
  @service skinnyLocations;

  @controller('location-overview.global-devices') globalDevices;

  queryParams = ['deviceType', 'locationId', 'pageNumber', 'sortBy', 'sortDirection', 'status', 'wifiNetwork'];

  @tracked wifiNetwork = '';
  @tracked deviceType = '';
  @tracked locationId = '';
  @tracked status = '';
  @tracked pageNumber = 1;
  @tracked selectedIPads = [];
  @tracked isShowingDeleteConfirmation;
  @alias('globalDevices.showList') showList;
  @alias('model.iPads') iPads;
  @reads('model.iPads.meta.total') totalRecords;
  @reads('model.iPads.meta.wifi-networks') wifiNetworks;
  @reads('model.iPads.meta.device-types') deviceTypes;
  @or('deviceType', 'locationId', 'wifiNetwork') isFiltered;

  get allowSelect() {
    return this.abilities.can('update global-devices') || this.abilities.can('delete global-devices');
  }

  get isSelectAllIndeterminate() {
    const selectedCount = this.selectedIPads.length;
    const isSelectAll = this.isSelectAll;
    return selectedCount > 0 && !isSelectAll;
  }

  get isSelectAll() {
    const selectedCount = this.selectedIPads.length;
    const ipadCount = this.iPads.length;
    return ipadCount === selectedCount;
  }

  @task
  *deleteIpadsTasks() {
    const ipads = this.selectedIPads;
    for (const ipad of ipads) {
      try {
        yield ipad.destroyRecord();
      } catch (e) {
        const errorText = parseErrorForDisplay(e);
        this.flashMessages.showFlash('error', errorText);
        return;
      }
    }
    this.flashMessages.showFlash('success', 'iPads deleted successfully');
    this.selectedIPads = [];
    yield this.send('refreshModel');
    this.isShowingDeleteConfirmation = false;
  }

  @action
  resetSelectedIPads() {
    this.selectedIPads = [];
  }

  @action
  setAndClearPage(setter, val) {
    setter(val);
    this.pageNumber = 1;
  }

  @action
  unselectIPad(ipad) {
    this.selectedIPads.removeObject(ipad);
  }

  @action
  selectIPad(ipad) {
    this.selectedIPads.addObject(ipad);
  }

  @action
  selectAllIPads() {
    const iPads = this.iPads.toArray();
    if (this.isSelectAll) {
      this.selectedIPads = [];
    } else {
      this.selectedIPads = [...iPads];
    }
  }

  @action
  clearAllFilters() {
    this.deviceType = '';
    this.locationId = '';
    this.pageNumber = 1;
    this.status = '';
    this.wifiNetwork = '';
  }
}
