/* eslint-disable ember/no-computed-properties-in-native-classes */
import Service, { service } from '@ember/service';
import ObjectProxy from '@ember/object/proxy';
import { computed, get, set, action } from '@ember/object';
import { alias, reads, filterBy, or, and } from '@ember/object/computed';
import { waitForProperty, keepLatestTask, dropTask, all, lastValue } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { isPresent } from '@ember/utils';
import _sum from 'lodash/sum';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { COMPANY_ROLES, LOCATION_ROLES, ZONE_ROLES } from 'garaje/utils/roles';
import config from 'garaje/config/environment';
import { isAfter, fromUnixTime } from 'date-fns';

const VISITORS_STEPS = [
  'devices',
  'entries',
  'visitorsEmployeesImport',
  'fields',
  'photo',
  'nda',
  'newPrinter',
  'newInvite',
];

const ADMIN_ROLES = [...LOCATION_ROLES, ...COMPANY_ROLES, ...ZONE_ROLES];

const DEFAULT_ACCENT_COLOR = '#ef3934';

//Thu Sep 01 2022 08:00:00 GMT+0000
const COMPANY_CREATED_AT_GATE = 1662019200;

export class VisitorsOnboardingStep extends ObjectProxy {
  started = false;
  @alias('content.id') id;
  @alias('content.setupGuideStep.title') title;
  @alias('content.setupGuideStep.subtitle') description;
  @alias('content.setupGuideStep.stepNumber') order;
  @alias('content.setupGuideStep.page') page;
  @alias('content.setupGuideStep.skippable') skippable;
  @alias('content.routeName') routeName;
  @alias('content.reviewed') reviewed;
  @alias('content.skipped') skipped;
  @alias('content.completed') completed;
  @alias('content.pageResourceId') pageResourceId;
  @alias('done') isCompletedOrSkipped;
  @or('reviewed', 'skipped', 'completed') done;
  save() {
    return this.content.save();
  }
}

export default class VisitorsOnboardingService extends Service {
  lastLocationId = null;

  @service abilities;
  @service router;
  @service state;
  @service store;
  @service flashMessages;
  @service featureFlags;
  @service impressions;
  @service locations;

  @reads('state.currentLocation.id') currentLocationId;

  @reads('state.currentLocation.visitorsOnboardingComplete') locationOnboardingComplete;

  @filterBy('steps', 'done') stepsDone;

  @filterBy('steps', 'done', false) stepsRemaining;

  @reads('stepsRemaining.firstObject') currentStep;

  @alias('state.currentUser') currentUser;
  @alias('state.currentUser.visitorsProductVideosEnabled') visitorsProductVideosEnabled;
  @alias('state.vrSubscription') subscription;
  @alias('state.currentCompany') currentCompany;

  @lastValue('loadImpressionsTask') onboardingImpressions = [];

  @tracked totalEmployees = 0;
  @tracked totalAdmins = 0;
  @tracked totalFlows = 0;
  @tracked hasSignInFields = false;
  @tracked totalLegalDocuments = 0;
  @tracked hasEnabledBadges = false;
  @tracked hasEnabledPhotos = false;
  @tracked hasIpad = false;
  @tracked touchlessWalkinEnabled = false;
  @tracked hasLogoOrAccentColor = false;
  @tracked hasDesign = false;
  @tracked showIPadPrompt = false;
  @tracked highlightIPad = false;
  @tracked showSkipSetupBanner = false;
  @tracked waitToLoadFlows = false;

  @computed('steps.[]', 'currentStep')
  get nextStep() {
    const { steps, currentStep } = this;
    if (currentStep) {
      return this.steps.objectAt(steps.indexOf(currentStep) + 1);
    }
    return undefined;
  }

  @computed('steps.[]')
  get dashboardSteps() {
    return this.steps.slice(0, 3);
  }

  @computed('locationOnboardingComplete')
  get isEnabled() {
    if (this.featureFlags.isEnabled('visitorsOnboardingVideoFlow')) {
      return false;
    }

    return this.abilities.can('view onboarding steps for visitors') && !this.locationOnboardingComplete;
  }

  @and('isEnabled', 'steps.0.done') isStarted;

  @computed('loadStepsTask.lastSuccessful.value')
  get steps() {
    if (this.loadStepsTask.lastSuccessful) {
      return this.loadStepsTask.lastSuccessful.value;
    }
    this.loadSteps();
    return [];
  }

  @computed('steps.@each.routeName', 'router.currentRouteName')
  get currentRouteStep() {
    return this.steps.find((step) => this.router.isActive(get(step, 'routeName')));
  }

  get s3Url() {
    return config.s3url;
  }

  get onboardingVideoWalkthroughsEnabled() {
    return this.visitorsProductVideosEnabled === undefined || this.visitorsProductVideosEnabled;
  }

  get videoWalkthroughEnabled() {
    return (
      this.featureFlags.isEnabled('visitorsOnboardingVideoFlow') &&
      isAfter(this.currentCompany.createdAt, fromUnixTime(COMPANY_CREATED_AT_GATE)) &&
      this.abilities.can('view onboarding steps for visitors') &&
      this.onboardingVideoWalkthroughsEnabled &&
      this.subscription
    );
  }

  get showVideoWalkthrough() {
    return this.videoWalkthroughEnabled && this.loadImpressionsTask.lastSuccessful;
  }

  @keepLatestTask
  *loadStepsTask() {
    const locationSteps = yield this.queryStepsTask.perform();

    return locationSteps
      .map((locationStep) =>
        VisitorsOnboardingStep.create({
          content: locationStep,
        }),
      )
      .filter((step) => VISITORS_STEPS.includes(get(step, 'page')))
      .sortBy('order');
  }

  @dropTask
  *completeOnboardingTask() {
    try {
      set(this.state.currentLocation, 'visitorsOnboardingComplete', true);
      yield this.state.currentLocation.save();
    } catch (e) {
      this.state.currentLocation.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @keepLatestTask
  *queryStepsTask() {
    return yield this.store.query('locations-setup-guide-step', {
      filter: { location: this.currentLocationId },
      include: 'setup-guide-step',
    });
  }

  @dropTask
  *loadImpressionsTask() {
    if (!this.featureFlags.isEnabled('visitorsOnboardingVideoFlow')) {
      return [];
    }

    try {
      const impressions = yield this.impressions.getImpressions.perform([
        IMPRESSION_NAMES.VISITORS_ONBOARDING_FIRST_VIEW,
        IMPRESSION_NAMES.VISITORS_ONBOARDING_IPAD_YES,
        IMPRESSION_NAMES.VISITORS_ONBOARDING_IPAD_NO,
      ]);

      this.showIPadPrompt =
        !impressions.any(
          (impression) =>
            impression.feature_name === IMPRESSION_NAMES.VISITORS_ONBOARDING_IPAD_YES ||
            impression.feature_name === IMPRESSION_NAMES.VISITORS_ONBOARDING_IPAD_NO,
        ) && this.featureFlags.isEnabled('visitorsOnboardingIpadOptimization');

      return impressions;
    } catch (e) {
      this.showIPadPrompt = false;
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *loadEmployeesTask() {
    const employees = yield this.store.query('employee', {
      include: 'assistants',
      page: { limit: 10, offset: 0 },
      filter: { deleted: false },
    });
    this.totalEmployees = employees.meta.total;
  }

  @dropTask
  *loadUserRolesTask() {
    const userRoles = yield this.store.query('user-role', {
      filter: { roles: ADMIN_ROLES.join(',') },
    });
    const users = yield all(userRoles.map((role) => get(role, 'user')));
    this.totalAdmins = users.uniqBy('id').length;
  }

  @dropTask
  *loadFlowsTask(reload = true) {
    yield waitForProperty(this, 'waitToLoadFlows', (val) => val === false);
    const flows = yield this.state.loadFlows({ reload: reload });
    const agreementPages = flows.map((flow) => get(flow, 'agreementPage'));
    const agreements = yield all(agreementPages.map((agreementPage) => get(agreementPage, 'agreements')));
    const badges = yield all(flows.map((flow) => get(flow, 'badge')));
    const photoPages = yield all(flows.map((flow) => get(flow, 'photoPage')));

    this.totalFlows = flows.length;
    //we check if there is an extra sign-in field added manually
    //the created flows have 3 fields by default, so we count only from the 4th field
    this.hasSignInFields =
      _sum(flows.map((flow) => Math.max(get(flow, 'signInFieldPage.signInFields.length') - 3, 0))) > 0;

    this.totalLegalDocuments = agreements
      .compact()
      .reduce((acc, val) => [...acc, ...val.toArray()], [])
      .rejectBy('name', 'Visitor Non-disclosure Agreement').length;
    this.hasEnabledBadges = badges.compact().isAny('enabled');
    this.hasEnabledPhotos = photoPages.compact().isAny('enabled');
  }

  @dropTask
  *loadLocationsTask(reload = false) {
    yield this.locations.loadAllTask.perform(reload);

    const configs = yield all(this.locations.allActive.map((location) => get(location, 'config')));
    yield all(configs.map((config) => config.slideshows));

    this.touchlessWalkinEnabled = configs.isAny('guestQrCodeEnabled');
    this.hasLogoOrAccentColor = this.locations.allActive.any(
      (location) => isPresent(location.logoUrl) || location.color.toLowerCase() !== DEFAULT_ACCENT_COLOR,
    );
    this.hasDesign = this.locations.allActive.any(
      (location) =>
        get(location.config, 'welcomeImage') ||
        get(location.config, 'slideshows').length ||
        get(location.config, 'welcomeBackgroundColorEnabled'),
    );
  }

  @dropTask
  *loadIpadsTask() {
    const ipads = yield this.store.query('device', { sort: 'name' });

    this.hasIpad = ipads.length > 0;
  }

  @dropTask
  *toggleVisitorsProductVideosEnabledTask() {
    this.currentUser.visitorsProductVideosEnabled = !this.visitorsProductVideosEnabled;

    try {
      yield this.currentUser.save();
    } catch (e) {
      this.currentUser.rollbackAttributes();
    }
  }

  async gateRoute(route, transition) {
    const { isEnabled, isStarted } = this;
    const stepRouteName = get(this, 'currentStep.routeName');
    const routeName = transition.to.name;
    if (isEnabled && !isStarted && isPresent(routeName) && isPresent(stepRouteName) && stepRouteName !== routeName) {
      return this.router.transitionTo(stepRouteName);
    }
  }

  loadSteps() {
    const { loadStepsTask, lastLocationId, currentLocationId } = this;
    const isSameLocation = lastLocationId === currentLocationId;
    set(this, 'lastLocationId', currentLocationId);

    if (isPresent(lastLocationId) && isSameLocation && loadStepsTask.last) {
      return loadStepsTask.last;
    }

    if (isPresent(lastLocationId) && !isSameLocation) {
      loadStepsTask.cancelAll({ reason: 'LocationChange', resetState: true });
    }

    return loadStepsTask.perform();
  }

  @action
  toggleSkipSetupBanner() {
    this.showSkipSetupBanner = !this.showSkipSetupBanner;
  }
}
