import Controller from '@ember/controller';
import zft from 'garaje/utils/zero-for-tests';
import { service } from '@ember/service';
import { set } from '@ember/object';
import { dropTask, timeout } from 'ember-concurrency';

const TOGGLE_SNOOZE_DISABLED_DEBOUNCE = zft(1000);

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaNotificationsController extends Controller {
  @service featureFlags;
  @service flashMessages;

  @dropTask
  *toggleSnoozeDisabled(value) {
    const { deliveryArea } = this.model;
    set(deliveryArea, 'disableSnooze', !value);

    yield timeout(TOGGLE_SNOOZE_DISABLED_DEBOUNCE);

    try {
      yield deliveryArea.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      deliveryArea.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }
}
