import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationBillingRoute extends Route {
  @service('location-billing') billingService;
  @service state;
  @service abilities;
  @service featureFlags;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('visit billing') || this.state.billingCompany.resellerPartner) {
      this.router.transitionTo('unauthorized');
    }

    if (!this.featureFlags.isEnabled('locationBilling')) {
      this.router.replaceWith('billing');
    }
  }

  model() {
    return this.billingService.initService();
  }
}
