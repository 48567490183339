import Component from '@glimmer/component';
import clamp from 'clamp';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
 * @param {Number}                    lines the maximum # of lines the text should be displayed on
 * @param {Number}                    lineHeight the line-height of the lines of text
 * @param {String}                    text the content being "clamped"
 * @param {Function}                  handleTruncate a callback function executed after truncation
 * @param {Boolean}                   useNative
 */
export default class LineClamp extends Component {
  @tracked clampedText = null;

  @action
  handleInsert(element) {
    const { lines, useNative, handleTruncate } = this.args;

    const { clamped: clampedText } = clamp(element, {
      clamp: lines,
      truncationChar: '…',
      useNativeClamp: !!useNative,
    });

    if (clampedText) {
      this.clampedText = clampedText;
    }

    handleTruncate?.(!!clampedText);
  }
}
