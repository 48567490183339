import { service } from '@ember/service';
import type StateService from 'garaje/services/state';

import ApplicationSerializer from './application';

export default class AgreementSerializer extends ApplicationSerializer {
  @service declare state: StateService;

  constructor(...args: ConstructorParameters<typeof ApplicationSerializer>) {
    super(...args);

    const {
      state: { features, vrSubscription },
    } = this;
    const isBccFeatureEnabled = Boolean(features?.canAccessNdaBcc) || Boolean(vrSubscription?.canAccessNdaBcc);

    this.attrs = {
      documentUrl: {
        serialize: false,
      },
      bccEmail: {
        serialize: isBccFeatureEnabled,
      },
      sendToBcc: {
        serialize: isBccFeatureEnabled,
      },
    };
  }
}

declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    agreement: AgreementSerializer;
  }
}
