import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type FlowModel from 'garaje/models/flow';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('id-scan-page')
class IdScanPageModel extends Model {
  @belongsTo('flow', { async: true }) declare flow: AsyncBelongsTo<FlowModel>;
  @attr('boolean') declare enabled: boolean;
  @attr('boolean', { defaultValue: false }) declare imagesVisible: string;
  @attr('string', { defaultValue: 'back' }) declare camera: string;
  @attr('string', { defaultValue: null }) declare rescanAfter: string | null;
  @attr('number', { defaultValue: 12 }) declare rescanAfterValue: number;

  isGlobal = false;
}

export default IdScanPageModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'id-scan-page': IdScanPageModel;
  }
}
