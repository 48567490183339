import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { computed, get } from '@ember/object';
import { alias } from '@ember/object/computed';

/**
 *
 * @returns {Class}
 */
function withEmployeeRegistrationFunctionality(Class) {
  return class withEmployeeRegistrationFunctionality extends Class {
    @service state;
    @service store;
    @service currentAdmin;

    @tracked openedModal = '';
    @tracked selectedEmployeeScreeningFlow = null;
    @tracked deletingEmployeeScreeningFlow = null;
    @tracked globalEmployeeScreeningFlows = [];
    @tracked groups = [];
    @tracked currentSkinnyLocation = null;

    @alias('state.currentLocation') currentLocation;
    @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
    @alias('currentAdmin.isLocationAdmin') isLocationAdmin;
    @alias('state.currentLocation.employeeScreeningFlow') activeEmployeeScreeningFlow;
    @alias('selectedEmployeeScreeningFlow') activeOrSelectedEmployeeScreeningFlow;

    @computed('selectedEmployeeScreeningFlow.id', 'activeEmployeeScreeningFlow.id')
    get canDisableEmployeeScreeningFlow() {
      if (!this.activeEmployeeScreeningFlow) {
        return false;
      }
      const selectedId = get(this, 'selectedEmployeeScreeningFlow.id');
      const activeId = get(this, 'activeEmployeeScreeningFlow.id');
      return this.selectedEmployeeScreeningFlow ? selectedId === activeId : true;
    }

    @computed('activeEmployeeScreeningFlow.id', 'selectedEmployeeScreeningFlow.id')
    get canSaveEmployeeScreeningFlow() {
      const selectedId = get(this, 'selectedEmployeeScreeningFlow.id');
      const activeId = get(this, 'activeEmployeeScreeningFlow.id');
      return this.selectedEmployeeScreeningFlow && (!this.activeEmployeeScreeningFlow || selectedId !== activeId);
    }

    async setSelectedIfNoneAndOnlyOneChoice() {
      const { globalEmployeeScreeningFlows, activeOrSelectedEmployeeScreeningFlow } = this;
      if (!activeOrSelectedEmployeeScreeningFlow && globalEmployeeScreeningFlows.length === 1) {
        const firstFlow = await globalEmployeeScreeningFlows.firstObject;
        this.selectedEmployeeScreeningFlow = await this.getFlowWithIncludes(firstFlow.id);
      }
    }

    @computed('activeEmployeeScreeningFlow')
    get isEmployeeScreeningFlowConfigured() {
      return !!this.activeEmployeeScreeningFlow;
    }

    async initEmployeeRegistrationState() {
      const locationId = await this.currentLocation.id;

      this.globalEmployeeScreeningFlows = await this.store.findAll('employee-screening-flow');
      this.groups = this.store.peekAll('group');

      const currentSkinnyLocation = this.store.peekRecord('skinny-location', locationId);
      const currentSkinnyLocationFlowId = currentSkinnyLocation?.belongsTo('employeeScreeningFlow').id();

      this.currentSkinnyLocation = currentSkinnyLocation;
      this.selectedEmployeeScreeningFlow = currentSkinnyLocationFlowId
        ? currentSkinnyLocation.employeeScreeningFlow
        : null;
    }

    async getFlowWithIncludes(flowId) {
      const locationId = await this.currentLocation.id;
      const employeeScreeningFlow = await this.store.findRecord('employee-screening-flow', flowId, {
        reload: true,
        include:
          'agreement-page,' +
          'agreement-page.agreements,' +
          'summary-page,' +
          'sign-in-field-page,' +
          'sign-in-field-page.sign-in-field-actions,' +
          'sign-in-field-page.actionable-sign-in-fields,' +
          'sign-in-field-page.actionable-sign-in-field-actions,' +
          'sign-in-field-page.sign-in-fields,' +
          'sign-in-field-page.sign-in-fields.sign-in-field-actions,' +
          'sign-in-field-page.sign-in-fields.sign-in-field-actions.sign-in-field,' +
          'sign-in-field-page.sign-in-fields.actionable-sign-in-field-actions,' +
          'sign-in-field-page.sign-in-fields.actionable-sign-in-fields,' +
          'sign-in-field-action-rule-groups,' +
          'sign-in-field-action-rule-groups.sign-in-field-actions,' +
          'sign-in-field-action-rule-groups.sign-in-field-actions-contacts,' +
          'sign-in-field-action-rule-groups.sign-in-field-actions-contacts.employee',
      });
      this.groups = this.store.peekAll('group');
      this.currentSkinnyLocation = this.store.peekRecord('skinny-location', locationId);
      return employeeScreeningFlow;
    }
  };
}

export default withEmployeeRegistrationFunctionality;
