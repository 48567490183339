import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import { adminRoute } from 'garaje/utils/admin-routing';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { RoleScopeType } from 'garaje/utils/custom-roles';

class EmployeesAdminRolesSingleDeleteController extends Controller {
  @service router;
  @service flashMessages;
  @service currentAdmin;

  get hasSyncedRoles() {
    return this.model.roleAssignments.any((role) => !role.manuallyAdded);
  }

  // used for location admins
  get locationRolesToDeleteText() {
    const { roleAssignments } = this.model;

    const locations = roleAssignments.reduce((acc, role) => {
      if (role.roleScopeType === RoleScopeType.LOCATION && role.editable) {
        acc.push(role.roleScopeName);
      }
      return acc;
    }, []);

    switch (locations.length) {
      case 0:
        return 'their locations';
      case 1:
        return locations[0];
      case 2:
        return locations.join(' and ');
      default:
        return `${locations[0]}, ${locations[1]}, and ${locations.length - 2} more ${pluralize(
          locations.length - 2,
          'location',
          { withoutCount: true },
        )}`;
    }
  }

  @action
  closeModal() {
    this.send('refreshModel');

    const path = adminRoute(this.router.currentRouteName);
    return this.router.transitionTo(path);
  }

  deleteAllRolesTask = task({ drop: true }, async () => {
    try {
      const { user, roleAssignments } = this.model;

      // use the role-assignments API to delete all roles instead of making separate calls
      const roleAssignmentIds = roleAssignments.reduce((acc, role) => {
        if (role.editable) {
          acc.push(role.id);
        }
        return acc;
      }, []);

      await user.bulkDeleteRoles(roleAssignmentIds);

      this.flashMessages.showAndHideFlash('success', `Admin deleted`);
      this.closeModal();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  });

  // only location + company roles, zone roles dont sync
  filterOutSyncedRoles(roles) {
    return roles?.length > 0 ? roles.filter((role) => role.manuallyAdded) : [];
  }
}

export default EmployeesAdminRolesSingleDeleteController;
