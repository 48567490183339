import Component from '@glimmer/component';

/**
 * @param {Boolean}             isExpandable
 * @param {Boolean}             isOpen
 * @param {Boolean}             isInactive
 * @param {Function}            onCancel
 * @param {Function}            toEditing
 * @param {Function}            toPreviewing
 */
export default class SettingsPanel extends Component {
  get isExpandable() {
    return this.args.isExpandable ?? true;
  }
}
