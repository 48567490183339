import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type TenantConnectionRequestModel from './tenant-connection-request';

@adapter('multi-tenancy')
class ConnectionRequestInviteModel extends Model {
  @attr('string') declare email: string;
  @attr('string') declare fullName: string;

  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;

  @belongsTo('tenant-connection-request', { async: true })
  declare tenantConnectionRequest: AsyncBelongsTo<TenantConnectionRequestModel>;
}

export default ConnectionRequestInviteModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connection-request-invite': ConnectionRequestInviteModel;
  }
}
