import { action } from '@ember/object';
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { readOnly } from '@ember/object/computed';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type EntryModel from 'garaje/models/entry';
import type GroupModel from 'garaje/models/group';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type { LocationOverviewVisitorLogRouteModel } from 'garaje/pods/location-overview/visitor-log/route';
import type { EntryExportParams } from 'garaje/services/async-export-manager';
import type AsyncExportManagerService from 'garaje/services/async-export-manager';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MessageBusService from 'garaje/services/message-bus';
import type StateService from 'garaje/services/state';
import type { RecordArray } from 'garaje/utils/type-utils';
import urlBuilder from 'garaje/utils/url-builder';
import { or, uniqueBy } from 'macro-decorators';
import moment from 'moment-timezone';

const SIGNED_OUT_FILTERS = [
  { name: 'All visitors' },
  { name: 'Currently signed in', val: 'not-signed-out' },
  { name: 'Signed out', val: 'signed-out' },
];

const EXPORT_FORMATS = [
  {
    name: 'CSV',
    val: 'csv',
    desc: 'Download a CSV file. Data exports as a single sheet; location name will appear in a column.',
  },
  {
    name: 'XLSX',
    val: 'xlsx',
    desc: 'Download an Excel file. Each location’s data will appear on a separate sheet.',
  },
];

interface VisitorLogTableComponentSignature {
  Args: {
    entries: RecordArray<EntryModel>;
    params: LocationOverviewVisitorLogRouteModel['params'];
    pageNumber: number;
    pageSize: number;
    recordCount: number;
    setPageNumber: (pageNumber: number) => void;
    sortDirection: string;
    setSortDirection: (sortDirection: string) => void;
    sortBy: string;
    setSortBy: (sortBy: string) => void;
    query: string;
    setQuery: (query?: string) => void;
    availableLocations: SkinnyLocationModel[];
    groups: RecordArray<GroupModel>;
    locationIds: string;
    setLocationIds: (locationIds?: string) => void;
    signedOut: string;
    setSignedOut: (signedOut?: string) => void;
    startDate: string;
    endDate: string;
    setDateRange: (startDate: string, endDate: string) => void;
    selectedDateRange: string;
    loadingData: boolean;
  };
}

export default class VisitorLogTable extends Component<VisitorLogTableComponentSignature> {
  @service declare state: StateService;
  @service declare messageBus: MessageBusService;
  @service declare asyncExportManager: AsyncExportManagerService;
  @service declare featureFlags: FeatureFlagsService;

  @tracked showExportModal = false;
  @tracked selectedExportFormat;

  companyId: string | null = null;
  defaultLocationFilter;
  signedOutFilters;
  exportFormats;

  @or('args.sortBy', 'args.sortDirection', 'args.locationIds', 'args.signedOut') isFiltered!: boolean;
  @readOnly('args.query') isSearched!: string;
  @uniqueBy('args.availableLocations', 'id') uniqLocations!: SkinnyLocationModel[];

  get noResultMessage(): string {
    if (this.isFiltered && !this.isSearched) {
      return 'Try changing your filters.';
    } else if (this.isFiltered && this.isSearched) {
      return 'Try adjusting your search or changing your filters.';
    } else if (!this.isFiltered && this.isSearched) {
      return 'Try adjusting your search.';
    }

    return '';
  }

  get locationOptions(): Partial<SkinnyLocationModel>[] {
    return [this.defaultLocationFilter, ...this.uniqLocations];
  }

  get exportIframeUrl(): string {
    let modalLabel: string;
    let locationIds = this.args.locationIds;
    if (locationIds === '') {
      locationIds = this.args.availableLocations.map((loc) => loc.id).join(',');
    }
    const companyId = this.companyId;
    const defaultEndDate = moment().format('YYYY-MM-DD');
    const defaultStartDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    const locationCount = locationIds.split(',').length;
    if (this.args.availableLocations.length === locationCount) {
      modalLabel = 'All locations';
    } else if (locationCount > 1) {
      modalLabel = locationCount + ` locations`;
    } else {
      modalLabel = this.args.availableLocations.find((loc) => loc.id === locationIds)?.name ?? 'All locations';
      // findBy('id', locationIds).name;
    }
    return urlBuilder.embeddedEntryExportModalUrl(
      companyId!,
      locationIds,
      null,
      defaultStartDate,
      defaultEndDate,
      modalLabel,
    );
  }

  constructor(...args: ConstructorParameters<typeof Component<VisitorLogTableComponentSignature>>) {
    super(...args);

    this.defaultLocationFilter = {
      name: 'All locations',
    };
    this.exportFormats = EXPORT_FORMATS;
    this.selectedExportFormat = EXPORT_FORMATS[0];
    this.signedOutFilters = SIGNED_OUT_FILTERS;
    this.companyId = this.state.currentCompany.id;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  @action
  toggleModal(): void {
    const modalStatus = this.showExportModal;
    this.showExportModal = !modalStatus;
  }

  handleMessage(message: { event: string }): void {
    if (message.event === 'showEntryExportModal') {
      this.toggleModal();
    } else if (message.event === 'closeExportModal') {
      this.toggleModal();
    }
  }

  @action
  exportEntries(withFilters: boolean): unknown {
    this.showExportModal = false;

    const startDate = this.args.params.filter?.startDate ?? '';
    const endDate = this.args.params.filter?.endDate ?? '';

    let params: EntryExportParams & { action_origin: string } = {
      sort: '',
      query: '',
      startDate: startDate ? moment(startDate).toDate() : undefined,
      endDate: endDate ? moment(endDate).toDate() : undefined,
      filter: '',
      exportFormat: this.selectedExportFormat?.val ?? 'csv',
      action_origin: 'location_overview',
    };

    if (!withFilters) {
      return this.asyncExportManager.exportEntriesTask.perform(params);
    }

    params = Object.assign({}, params, {
      sort: this.args.params.sort ?? '',
      query: this.args.params.filter?.query ?? '',
      filter: this.args.params.filter?.status ?? '',
      exportFormat: this.selectedExportFormat?.val ?? 'csv',
      locationIds: (this.args.params.filter?.location ?? '').split(',').filter((id) => id),
    });

    return this.asyncExportManager.exportEntriesTask.perform(params);
  }

  @action
  clearAllFilters(): void {
    this.args.setQuery();
    this.args.setSignedOut();
    this.args.setLocationIds('');
  }

  @action
  resetPageNumber(): void {
    this.args.setPageNumber(1);
  }

  @action
  selectLocationsFilter(options: SkinnyLocationModel[]): void {
    let ids = this.args.locationIds.split(',').filter((el) => isPresent(el));
    options.forEach((option) => {
      ids = ids.includes(option.id) ? ids.filter((id) => id !== option.id) : [...ids, option.id];
    });
    this.args.setLocationIds(ids.join(','));
  }

  @action
  selectSignedOutFilter(signedOutFilter: { val: string }): void {
    this.args.setSignedOut(signedOutFilter.val);
  }

  @action
  setExportFormat(value: this['selectedExportFormat']): void {
    this.selectedExportFormat = value;
  }
}
