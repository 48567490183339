import Route from '@ember/routing/route';
import { findAllDeliveryAreasForLocation } from 'garaje/utils/delivery-area';
import { service } from '@ember/service';

export default class DeliveriesSettingsDeliveryAreasRoute extends Route {
  @service state;
  @service abilities;
  @service router;
  @service store;

  titleToken = 'Delivery Areas';

  beforeModel() {
    if (this.abilities.cannot('visit delivery-areas')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { currentLocation } = this.state;
    return findAllDeliveryAreasForLocation(this.store, currentLocation);
  }
}
