import { useCallback, useRef, useState } from 'react';

export enum ModalResult {
  Primary,
  Secondary,
  Closed,
}

export function useModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModalResolver = useRef<(result: ModalResult) => void>();

  const openModal = () => {
    return new Promise<ModalResult>((resolve) => {
      openModalResolver.current = resolve;

      setIsModalOpen(true);
    });
  };

  const setResult = (result: ModalResult) => {
    openModalResolver.current && openModalResolver.current(result);
    openModalResolver.current = undefined;

    setIsModalOpen(false);
  };

  const primaryClicked = useCallback(() => {
    setResult(ModalResult.Primary);
  }, []);

  const secondaryClicked = useCallback(() => {
    setResult(ModalResult.Secondary);
  }, []);

  const closeClicked = useCallback(() => {
    setResult(ModalResult.Closed);
  }, []);

  return {
    isModalOpen,
    openModal,
    primaryClicked,
    secondaryClicked,
    closeClicked,
  };
}
