import $ from 'jquery';
import ApplicationAdapter from './application';
import _chunk from 'lodash/chunk';
import { service } from '@ember/service';
import { resolve } from 'rsvp';

const MAX_PAGE_SIZE = 50;

export default class EmployeeAdapter extends ApplicationAdapter {
  @service windowLocation;

  coalesceFindRequests = true;

  /*
    Envoy-web only supports requesting up to 50 items at once. Here we split
    all the employees into groups of up to 50 in order to request them separately.
  */
  groupRecordsForFindMany() {
    const [snapshots] = super.groupRecordsForFindMany(...arguments);
    return _chunk(snapshots, MAX_PAGE_SIZE);
  }

  query(store, type, query) {
    if (query.url === '/export') {
      let url = this.buildURL(type.modelName, null, null, 'query', query);
      /* eslint-disable camelcase */
      const params = {
        company_id: query.company,
      };
      /* eslint-enable camelcase */
      if (query.locations) {
        params['filter[locations]'] = query.locations;
      }

      url += `/export?${$.param(params)}`;
      this.windowLocation.assign(url);

      return resolve({ data: [] });
    } else {
      return super.query(...arguments);
    }
  }
}
