import Route from '@ember/routing/route';
import { routeEvent } from 'garaje/utils/decorators/route';
import { service } from '@ember/service';

export default class DesksImportRoute extends Route {
  @service abilities;
  @service featureFlags;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('import-desks desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    return this.modelFor('desks');
  }

  @routeEvent
  routeWillChange() {
    this.controller.clearAll();
  }
}
