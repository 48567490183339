import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { format } from 'date-fns';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';

const VARIABLES = ['%{recipient}', '%{carrier}', '%{delivery_area}', '%{date_received}'];

/**
 * @param {Class}     deliveryArea
 * @param {Class}     location
 */
export default class NotificationMessage extends Component {
  @service featureFlags;
  @service flashMessages;

  @tracked previewingView = 'Email';
  @tracked isEditingCustomNotification = true;

  variables = VARIABLES;

  get previewBody() {
    return this.previewTextFor(this.args.deliveryArea.notificationTemplateBody);
  }

  get previewSubject() {
    return this.previewTextFor(this.args.deliveryArea.notificationTemplateSubject);
  }

  get variablePlaceholders() {
    const carriers = ['Amazon', 'DHL', 'FedEx', 'Apple', 'UPS', 'Aramex'];

    return {
      recipientName: 'Sophia Fitzroy',
      recipientEmail: 'sophia@email.com',
      carrier: carriers[Math.floor(Math.random() * carriers.length)],
      locationName: this.args.location.name,
      deliveryAreaName: this.args.deliveryArea.name,
      notes: 'Optional notes',
      dateReceived: format(new Date(), 'MMMM d, yyyy'),
    };
  }

  previewTextFor(text) {
    const placeholders = this.variablePlaceholders;
    return text
      .replace(/%{recipient}/g, placeholders.recipientName)
      .replace(/%{carrier}/g, placeholders.carrier)
      .replace(/%{delivery_area}/g, placeholders.deliveryAreaName)
      .replace(/%{date_received}/g, placeholders.dateReceived);
  }

  @dropTask
  *saveDeliveryArea() {
    try {
      yield this.args.deliveryArea.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
