/* eslint-disable ember/no-component-lifecycle-hooks */
import ModalDialog from 'ember-modal-dialog/components/modal-dialog';

const BODY_CLASSES = ['overflow-hidden'];
const FIXED_BODY_CLASSES = ['fixed', 'w-full'];

export default ModalDialog.extend({
  didInsertElement() {
    this._super(...arguments);
    const hasScrollBar = window.innerWidth > document.body.clientWidth;
    if (hasScrollBar) {
      document.body.style.marginRight = `${window.innerWidth - document.body.clientWidth}px`;
    }
    document.body.classList.add(...BODY_CLASSES);
    if (this.fixedBody) document.body.classList.add(...FIXED_BODY_CLASSES);
  },

  willDestroyElement() {
    this._super(...arguments);
    document.body.classList.remove(...BODY_CLASSES);
    if (this.fixedBody) document.body.classList.remove(...FIXED_BODY_CLASSES);
    document.body.style.marginRight = '';
  },
});
