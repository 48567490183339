import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type TenantModel from 'garaje/models/tenant';

export default class TenantRoleModel extends Model {
  @attr('string') declare roleName: string;
  @attr('string') declare roleType: string;

  @belongsTo('tenant', { async: true }) declare tenant: AsyncBelongsTo<TenantModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tenant-role': TenantRoleModel;
  }
}
