import Controller from '@ember/controller';
import { isPresent } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { later } from '@ember/runloop';
import zft from 'garaje/utils/zero-for-tests';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { SummaryPageChangeset } from 'garaje/changesets/summary-page';
import { RULE_ACTION_OPTIONS } from 'garaje/utils/enums';
import { alias, filterBy } from 'macro-decorators';

export default class WorkplaceSettingsEmployeesRegistrationEditController extends Controller {
  @service flashMessages;
  @service skinnyLocations;
  @service localStorage;
  @service state;
  @service flow;
  @service router;
  @service currentAdmin;
  @service workplaceMetrics;

  @alias('currentAdmin.isGlobalAdmin') isGlobalAdmin;
  @alias('currentAdmin.isLocationAdmin') isLocationAdmin;
  @alias('flow.selectedEmployeeScreeningFlow') selectedEmployeeScreeningFlow;
  @alias('flow.openedModal') openedModal;
  @alias('flow.deletingEmployeeScreeningFlow') deletingEmployeeScreeningFlow;

  @tracked showInstructionsModal = false;
  @tracked showLocationsModal = false;
  @tracked selectedLocations = [];
  @tracked nameIsDirty = false;
  @tracked headerTextIsDirty = false;
  @tracked denialMessageIsDirty = false;

  shouldReloadCurrentLocation = false;

  @filterBy('model.signInFieldActionRuleGroups', 'isNew', false) savedRuleGroups;

  get isDisabled() {
    return this.isLocationAdmin && !this.isGlobalAdmin;
  }

  get hasDeniedRules() {
    return this.savedRuleGroups.reduce((acc, ruleGroup) => {
      return ruleGroup.actions.includes(RULE_ACTION_OPTIONS.DENY) || acc;
    }, false);
  }

  get summaryPageChangeset() {
    const { summaryPage } = this.model;
    return isPresent(summaryPage) ? new SummaryPageChangeset(summaryPage) : null;
  }

  @action
  openEditFlowLocationsModal() {
    this.showLocationsModal = true;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_FLOW_ASSIGNED_LOCATIONS_MODAL_OPENED');
  }

  @action
  closeModal() {
    this.showLocationsModal = false;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_FLOW_ASSIGNED_LOCATIONS_MODAL_CLOSED');
  }

  @action
  setDirtyStateName(value) {
    this.nameIsDirty = value;
  }

  @action
  setDirtyStateHeaderText(value) {
    this.headerTextIsDirty = value;
  }

  @action
  setDirtyStateDenialMessage(value) {
    this.denialMessageIsDirty = value;
  }

  @action
  isDirty() {
    const { model, nameIsDirty, headerTextIsDirty, denialMessageIsDirty } = this;
    const { flow } = model;

    if (flow.isDeleted) {
      return false;
    }

    const fields = get(flow.signInFieldPage, 'signInFields');
    const dirtyFields = fields.any((field) => field.hasDirtyAttributes);
    const dirtyFlow = flow.hasDirtyAttributes;
    const dirtyLocation = this.state.currentLocation.hasDirtyAttributes;
    const dirtySummaryPage = this.summaryPageChangeset?.isDirty;

    return (
      dirtyFields ||
      dirtyFlow ||
      dirtyLocation ||
      dirtySummaryPage ||
      nameIsDirty ||
      headerTextIsDirty ||
      denialMessageIsDirty
    );
  }

  /**
   * Task for saving the flow
   *
   * @function onSaveTask
   */
  @dropTask
  *onSaveTask() {
    const locationsRelation = get(this.model.flow, 'locations');
    locationsRelation.clear();
    locationsRelation.pushObjects(this.selectedLocations);
    try {
      yield this.model.flow.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.showLocationsModal = false;
      const instructionsShowed = this.localStorage.getItem('employee_registration_instructions');
      if (!instructionsShowed) {
        later(() => {
          this.showInstructionsModal = true;
          this.localStorage.setItem('employee_registration_instructions', true);
        }, zft(900));
      }
      if (this.shouldReloadCurrentLocation) {
        this.state.currentLocation.reload();
        this.shouldReloadCurrentLocation = false;
      }
    } catch (e) {
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  }

  @action
  rollbackAll() {
    const { flow } = this.model;
    const { currentLocation } = this.state;
    const fields = get(flow.signInFieldPage, 'signInFields');

    currentLocation.rollbackAttributes();
    flow.rollbackAttributes();
    fields.filterBy('hasDirtyAttributes').map((field) => field.rollbackAttributes());
    this.summaryPageChangeset?.rollback();
    this.nameIsDirty = false;
    this.headerTextIsDirty = false;
    this.denialMessageIsDirty = false;
  }

  /**
   * Handles the selection or removal of a location
   *
   * @param {object} location LocationModel
   * @param {MouseEvent} _evt Location checkbox click event
   */
  @action
  onLocationToggled(location, _evt) {
    if (this.selectedLocations.findIndex((selected) => selected.id === location.id) < 0) {
      this.selectedLocations = [location, ...this.selectedLocations];
    } else {
      const newSelectedLocations = this.selectedLocations.filter((selected) => selected.id !== location.id);
      this.selectedLocations = newSelectedLocations;
    }
    if (this.state.currentLocation.id === location.id) {
      this.shouldReloadCurrentLocation = !this.shouldReloadCurrentLocation;
    }
  }

  @action
  confirmDelete(flow) {
    this.deletingEmployeeScreeningFlow = flow;
    this.openedModal = 'confirm-delete';
  }

  @action
  goBack() {
    this.selectedEmployeeScreeningFlow = this.model.flow;
    this.router.transitionTo('workplace.settings.employees.registration');
  }

  @action
  onDeselectAll() {
    this.selectedLocations = [];
  }

  @action
  onSelectAll() {
    this.selectedLocations = [...this.skinnyLocations.manageableByCurrentAdmin];
  }

  /**
   * Rolls back custom denied message
   *
   * @param {object} changeset SummaryPageModel Changeset
   */
  @action
  rollbackDeniedMessage(changeset) {
    changeset.rollback();
  }

  /**
   * Saves a custom denied message
   *
   * @param {object} changeset SummaryPageModel Changeset
   */
  @action
  async submitDeniedMessage(changeset) {
    const { flashMessages } = this;
    try {
      await changeset.validate();
      if (get(changeset, 'isValid')) {
        await changeset.save();
        flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch (e) {
      flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e;
    }
  }
}
