import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3')
export default class EmergencyNotificationConfigurationModel extends Model {
  @attr('date', { defaultValue: null }) declare passivePhoneNumberCollectionEnabledAt: Date | null;
  @attr('date', { defaultValue: null }) declare emergencyNotificationsEnabledAt: Date | null;

  // Relationships
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'emergency-notification-configuration': EmergencyNotificationConfigurationModel;
  }
}
