import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

/**
 * @param {String}              name
 * @param {Function}            sortAction
 * @param {Boolean}             isActive
 * @param {String}              sortDirection
 * @param {String}              sortField
 */
export default class SortableHeaderColumn extends Component {
  @tracked nameWasTruncated;

  get isAsc() {
    const { isActive, sortDirection } = this.args;
    return isActive && sortDirection === 'asc';
  }

  get isDesc() {
    const { isActive, sortDirection } = this.args;
    return isActive && sortDirection === 'desc';
  }

  @action
  toggleSortAction() {
    const { isActive, sortDirection, sortField, sortAction } = this.args;
    let dir = 'asc';
    if (isActive && sortDirection === 'asc') {
      dir = 'desc';
    }
    sortAction(sortField, dir);
  }

  @action
  onTruncate(nameWasTruncated) {
    this.nameWasTruncated = nameWasTruncated;
  }
}
