import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { APP } from 'garaje/utils/enums';
import { alias, or } from 'macro-decorators';
import { action } from '@ember/object';

export default class WorkplaceStartTrialPageController extends Controller {
  @service router;
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service locationBilling;
  @service productActivation;
  @service state;
  @service store;
  @service workplaceMetrics;

  @alias('currentAdmin.isLocationAdmin') canRequestTrial;
  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin') canStartTrial;
  @tracked workplaceSubscription = this.state.workplaceSubscription;
  @tracked showIntakeForm = false;

  get app() {
    return APP.WORKPLACE;
  }

  @action
  async openIntakeForm() {
    this.showIntakeForm = true;
    try {
      await this.startTrialTask.perform();
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  async closeIntakeForm() {
    this.showIntakeForm = false;
    this.flashMessages.showAndHideFlash('success', 'Trial started!');
    await this.router.transitionTo('workplace.setup-guide', {
      queryParams: { postTrial: 'workplace' },
    });
  }

  @dropTask
  // eslint-disable-next-line require-yield
  *startTrialTask(evt) {
    if (typeof evt?.preventDefault === 'function') {
      evt.preventDefault();
    }
    yield this.productActivation.startWorkplaceTrialTask.perform();

    yield this.state.initSubscriptionStateTask.perform();

    this.workplaceMetrics.trackEvent('PRODUCT_LANDING_PAGE_START_TRIAL_BUTTON_CLICKED', { product: APP.WORKPLACE });
  }
}
