import Component from '@glimmer/component';

export interface TemplateViewData {
  id: string;
  name: string;
  title: string;
  message: string;
  defaultChannels: string[];
  defaultEmployeeAudiences: string[];
  defaultVisitorAudiences: string[];
  published: boolean;
  markAsSafe: boolean;
  takeoverActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  categoryName: string;
  locationsToDisplay: string;
  remainingLocations: string[];
  locationId: string[];
}

interface AnnouncementTemplateListArgs {
  filteredTemplateViewData: TemplateViewData[];
  hasMorePages: boolean;
  loadMore: () => void;
}

const TABLE_COLUMNS = [
  { name: 'Category' },
  { name: 'Template name' },
  { name: 'Locations applied' },
  { name: 'Last updated' },
];

export default class AnnouncementTemplateList extends Component<AnnouncementTemplateListArgs> {
  get tableHeaders(): string[] {
    return TABLE_COLUMNS.map((column) => column.name);
  }
}
