/* eslint-disable ember/no-get */
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import EmberObject, { computed, get, getProperties, set, setProperties, action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { reads, not, sort } from '@ember/object/computed';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { underscore, camelize } from '@ember/string';
import { buildWaiter } from '@ember/test-waiters';
import { isEmpty, isPresent } from '@ember/utils';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type { Task, TaskInstance } from 'ember-concurrency';
import { dropTask, task, all } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import config from 'garaje/config/environment';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type RecurringInviteModel from 'garaje/models/recurring-invite';
import RecurringRule from 'garaje/models/recurring-rule';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldActionModel from 'garaje/models/sign-in-field-action';
import type UserModel from 'garaje/models/user';
import type UserDatum from 'garaje/models/user-datum';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import { RECURRING_OPTIONS } from 'garaje/pods/components/invites/select-recurring-invites/component';
import type ActiveStorageService from 'garaje/services/active-storage-extension';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import addOrUpdateUserData from 'garaje/utils/user-data';
import buildFlowFieldValidations from 'garaje/validations/flow-field';
import { bool } from 'macro-decorators';
import moment from 'moment-timezone';
import { localCopy } from 'tracked-toolbox';
import type { ValueOf } from 'type-fest';

const testWaiter = buildWaiter('invites-single-prereg-component-waiter');
const isTesting = config.environment === 'test';

interface InvitesSinglePreregComponentSignature {
  Args: {
    blocklistContacts: UserModel[];
    config: Record<string, unknown>;
    dateChanged: (date: Date) => void;
    flows: NativeArray<FlowModel>;
    hostFieldIsRequired: boolean;
    invite: InviteModel;
    isEditInvite: boolean;
    isMultiLocationInvite: boolean;
    inviteChangeset: DetailedChangeset<InviteModel>;
    legalDocumentDescriptions: Record<string, unknown>;
    locationIsConnectedToProperty: boolean;
    locations: NativeArray<LocationModel>;
    modelDidPartialSave: (invite: InviteModel | DetailedChangeset<InviteModel>, error?: unknown) => void;
    modelDidSave: (
      date?: Date,
      goToIndex?: boolean,
      inviteAttrs?: Partial<InviteModel>,
      options?: { addAnother?: boolean },
    ) => void;
    onFlowChanged: (flow: string, inviteChangeset?: DetailedChangeset<InviteModel>) => void;
    onLocationSelectedTask: Task<void, []>;
    shouldShowLegalDocumentDescriptions: boolean;
    shouldShowRecurrence: boolean;
  };
}

interface SaveOptions {
  addAnother?: boolean;
  whichInvites?: ValueOf<typeof RECURRING_OPTIONS>;
}

/**
 * Form for creating or editing an invite
 */
export default class InvitesSinglePreregComponent extends Component<InvitesSinglePreregComponentSignature> {
  @service declare abilities: AbilitiesService;
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service declare activeStorageExtension: ActiveStorageService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare store: StoreService;
  @service declare state: StateService;

  @localCopy('args.invite.employee') owner!: EmployeeModel;
  @localCopy('args.invite.sendGuestEmail') sendGuestEmail!: InviteModel['sendGuestEmail'];
  @localCopy('args.config', {}) config!: Record<string, unknown>;
  @localCopy('args.flows', []) flows!: NativeArray<FlowModel>;

  @tracked confirmRemovalOfDocument: VisitorDocumentModel | null = null;
  @tracked flowToChangeTo?: FlowModel;
  @tracked hasAttachments = false;
  @tracked inviteChanges: unknown;
  @tracked isShowingEditRecurringInvite = false;
  @tracked isShowingDeleteConfirmation = false;
  @tracked sendNotificationCallback: unknown;
  @tracked editAffectsMultipleLocationsModal = false;
  @tracked showConfirmVisitorTypeChangeModal = false;
  @tracked emailHasFocus = false;

  @not('args.invite.needsApprovalReview') isNotPendingApproval!: boolean;
  @reads('args.inviteChangeset.expectedArrivalTime') minRruleUntilDate!: InviteModel['expectedArrivalTime'];
  @reads('args.inviteChangeset.expectedArrivalTime') expectedArrivalTime!: InviteModel['expectedArrivalTime'];
  @reads('args.inviteChangeset.location.timezone') timezone!: LocationModel['timezone'];
  @sort('signInFields', (a: SignInFieldModel, b: SignInFieldModel) => a.position - b.position)
  sortedSignInFields!: SignInFieldModel[];
  @reads('selectedFlow.activeUserDocumentTemplateConfigurations')
  activeUserDocumentTemplateConfigurations!: FlowModel['activeUserDocumentTemplateConfigurations'];
  @bool('args.inviteChangeset.change.visitorDocuments') isVisitorDocumentsModified!: boolean;

  get canAccessMultipleHosts(): boolean {
    return this.state.features?.canAccessMultipleHosts ?? false;
  }

  get canDelete(): boolean {
    return this.abilities.can('delete invite', this.args.invite) && this.isNotPendingApproval;
  }

  get canEdit(): boolean {
    const { isEditInvite, invite } = this.args;

    // If editing an existing invite, check permissions
    // If creating an invite, always allow "editing"
    return isEditInvite ? this.abilities.can('edit invite', invite) : true;
  }

  get canReview(): boolean {
    const { isEditInvite, invite } = this.args;

    // If editing an existing invite, check permissions
    // If creating an invite, always allow "editing"
    return isEditInvite
      ? this.abilities.can('review entry-approval', {
          context: 'location',
          report: invite.approvalStatus?.failedReport,
        })
      : true;
  }

  get cannotReview(): boolean {
    return !this.canReview;
  }

  get secondaryLocations(): AsyncHasMany<LocationModel> | LocationModel[] {
    return this.args.inviteChangeset.childInviteLocations ?? [];
  }

  get emailSignInField(): SignInFieldModel | undefined {
    return this.signInFields?.findBy('isEmail');
  }

  get isEmailRequired(): boolean {
    if (
      !(
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location')
      )
    ) {
      return false;
    }

    return !!this.emailSignInField?.required;
  }

  get canSetPropertyNotes(): boolean {
    // "property notes" field only appears if the current location is connected to a property.
    // It should also only be shown to someone who can edit the invite (e.g. Global Admin, Location Admin,
    // Front Desk Admins, or the employee who created the invite).
    if (!this.args.locationIsConnectedToProperty) {
      return false;
    }
    if (<boolean>(<unknown>this.args.invite.isNew)) {
      return true;
    }
    return this.canEdit;
  }

  get hasRecurringInvite(): boolean {
    return isPresent(this.args.invite.belongsTo('recurringInvite').id());
  }

  get hasDatetimeChange(): boolean {
    return isPresent(this.args.inviteChangeset.changes.find((change) => change['key'] === 'expectedArrivalTime'));
  }

  get hasEmailChange(): boolean {
    return isPresent(this.args.inviteChangeset.changes.find((change) => change['key'] === 'email'));
  }

  get shouldSendNotification(): boolean {
    return !this.args.invite.isNew && !this.hasEmailChange && this.hasDatetimeChange;
  }

  get disabledRecurringOptions(): { THIS: boolean } | null {
    return this.isVisitorDocumentsModified ? { THIS: true } : null;
  }

  get defaultRecurringOption(): string {
    return this.isVisitorDocumentsModified ? RECURRING_OPTIONS.THIS_AND_FOLLOWING : RECURRING_OPTIONS.THIS;
  }

  get location(): AsyncBelongsTo<LocationModel> {
    return this.args.invite?.location;
  }

  get shouldShowSecondaryLocations(): boolean {
    if (!this.state.features?.canAccessMultiLocationInvites) return false;
    if (this.isMultiLocationEdit) return false;
    return this.args.locations?.length > 1;
  }

  get isMultiLocationEdit(): boolean {
    return this.args.isEditInvite && this.args.isMultiLocationInvite;
  }

  get showEditAffectsMultipleLocationsModal(): boolean {
    return this.args.isEditInvite && this.args.isMultiLocationInvite && this.editAffectsMultipleLocationsModal;
  }

  get locationNames(): string[] {
    return this.args.invite.locationNames ?? [];
  }

  get secondaryLocationNames(): string {
    const childLocationNames = this.locationNames.slice(1);
    return childLocationNames.join(', ');
  }

  get primaryLocationName(): string | undefined {
    const [parentLocationName, ...childLocationNames] = this.locationNames;
    return childLocationNames.length ? parentLocationName : '';
  }

  @computed('args.inviteChangeset.location.id', 'selectedFlow.globalFlow.locations')
  get optionsForSecondaryLocations(): LocationModel[] {
    const { selectedFlow } = this;

    if (!selectedFlow) return [];

    const primaryLocationId = get(this, 'args.inviteChangeset.location.id');
    const globalFlow = <GlobalFlowModel>(get(selectedFlow, 'globalFlow.content') ?? get(selectedFlow, 'globalFlow'));

    if (typeof globalFlow?.hasMany !== 'function') return [];

    // A global flow may be associated to locations an employee cannot access.
    // Load locations from store instead of fetching globalFlow.locations association.
    const locationIds = globalFlow.hasMany('locations').ids();

    return <LocationModel[]>(
      locationIds
        .map((locId) => this.store.peekRecord('location', locId))
        .filter((loc) => loc !== null && loc.id !== primaryLocationId)
    );
  }

  @computed('args.invite.creator.id', 'currentAdmin.user.id')
  get isCreator(): boolean {
    const creatorId = this.args.invite.belongsTo('creator').id();
    const currentAdminId = this.currentAdmin.user?.id;

    return Boolean(creatorId && currentAdminId && creatorId === currentAdminId);
  }
  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.invite.hasAdditionalGuests', 'selectedFlow.additionalGuests')
  get displayAdditionalGuests(): boolean {
    if (this.args.invite.hasAdditionalGuests) {
      return true;
    }

    return Boolean(
      this.state.features?.canAccessGroupSignIn &&
        this.selectedFlow &&
        'additionalGuests' in this.selectedFlow &&
        this.selectedFlow.additionalGuests,
    );
  }

  get flowOptions(): NativeArray<FlowModel> {
    return this.flows.rejectBy('isProtect').sortBy('position');
  }

  @computed(
    'args.{invite.flow,invite.flow.id,invite.flowName,invite.isDestroying,inviteChangeset.flow,inviteChangeset.flow.id}',
    'flows.@each.{id,name}',
    'purposeOfVisit.value',
    'state.currentLocation.employeeScreeningFlow.id',
  )
  get selectedFlow():
    | AsyncBelongsTo<FlowModel>
    | EmployeeScreeningFlowModel
    | FlowModel
    | Partial<FlowModel>
    | null
    | undefined {
    // the flow rel is always correct and might not match the flowName or purpose of visit
    let flowId = get(this.args, 'inviteChangeset.flow.id') || get(this.args, 'invite.flow.id');

    if (this.args.invite.isDestroying) return null;

    flowId = flowId || this.args.invite.belongsTo('flow').id();

    if (!flowId) return null;

    if (this.purposeOfVisit.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.state.currentLocation.employeeScreeningFlow;
    }

    const foundFlow = this.flows.find((f) => f.id === flowId) || this.store.peekRecord('flow', <string>flowId);

    if (foundFlow) return foundFlow;
    if (this.args.inviteChangeset.flow) return this.args.inviteChangeset.flow;
    if (this.args.invite.flowName) return { name: this.args.invite.flowName };

    return;
  }

  @computed('selectedFlow.{id,signInFieldPage.signInFields.@each.name,signInFieldPage.signInFields.isSettled}')
  get signInFields(): NativeArray<SignInFieldModel> {
    if (!this.selectedFlow) {
      return A();
    }

    const signInFields = get(this.selectedFlow, 'signInFieldPage.signInFields') as NativeArray<SignInFieldModel>;

    if (signInFields?.length) {
      const flowId = <string>(get(this.selectedFlow, 'id') ?? get(this.selectedFlow, 'content.id') ?? 'unknown');
      const pageId = <string>(get(this.selectedFlow, 'signInFieldPage.id') ?? 'unknown');
      this.metrics.trackEvent('Sign-in Fields Loaded', {
        flowId,
        pageId,
        totalFields: signInFields.length,
        fieldNames: signInFields.map((field) => field.name),
      });
    }

    return signInFields;
  }

  @computed('selectedFlow.{type,employeeCentric}')
  get isFromEmployeeScreening(): boolean {
    if (!this.selectedFlow) {
      return false;
    }
    return Boolean(
      get(this.selectedFlow, 'employeeCentric') || get(this.selectedFlow, 'type') === 'Flows::EmployeeScreening',
    );
  }

  @action
  checkForAttachments(): void {
    this.hasAttachments =
      A(this.visitorDocuments).isAny('hasAttachedFile') || A(this.visitorDocuments).isAny('hasInputFieldData');
  }

  get visitorDocuments(): VisitorDocumentModel[] {
    const {
      activeUserDocumentTemplateConfigurations,
      args: { inviteChangeset },
    } = this;

    return activeUserDocumentTemplateConfigurations?.sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        inviteChangeset.visitorDocumentForTemplate(
          'content' in userDocumentTemplate ? userDocumentTemplate.content! : userDocumentTemplate,
        ) || this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get isValidVisitorDocuments(): boolean {
    const documentsToCheck = this.visitorDocuments?.filter((document) => document.hasAttachedFile);
    if (isEmpty(documentsToCheck)) return true;

    return documentsToCheck.every((document) => document.isValidDocument);
  }

  @computed('sortedSignInFields.[]', 'userDataChangesets')
  get fieldChangesets(): Array<DetailedChangeset<SignInFieldModel>> {
    return this.sortedSignInFields.map((field) => {
      return get(this.userDataChangesets, `field-${field.id}`) || this.buildFieldChangeset(field);
    });
  }

  @computed('fieldChangesets.@each.value', 'selectedFlow')
  get renderableFieldChangesets(): DetailedChangeset<SignInFieldModel>[] {
    const renderableFields = this.fieldChangesets.filter((fieldChangeset) => {
      if (get(fieldChangeset, 'isTopLevel')) {
        return true;
      }

      const action = <SignInFieldActionModel>get(fieldChangeset, 'actionableSignInFieldActions.firstObject');

      // Do not attempt to automatically async fetch related signInField
      // If signInField record isn't already fulfilled, it will probably not load
      const isApplicableAction = action && action.belongsTo('signInField').value() && get(action, 'signInField.id');

      if (!isApplicableAction) {
        return false;
      }

      const changesetParent = this.fieldChangesets.find((changeset) => changeset.id === get(action, 'signInField.id'));

      return changesetParent && get(changesetParent, 'value') === get(action, 'dropdownOption.value');
    });

    if (renderableFields.length === 0) {
      const flowId = <string>(
        (this.selectedFlow
          ? get(this.selectedFlow, 'id') || get(this.selectedFlow, 'content.id') || 'unknown'
          : 'unknown')
      );

      this.metrics.trackEvent('Sign-in Fields Issue', {
        issue: 'No fields rendered',
        flowId,
      });
    }

    return renderableFields;
  }

  @computed('args.inviteChangeset.{validationMap.email,email}')
  get canSendGuestEmail(): boolean {
    const email = this.args.inviteChangeset.email;
    const validation = <[(...args: unknown[]) => boolean]>this.args.inviteChangeset.validationMap?.['email'];

    const validate = Array.isArray(validation)
      ? (...args: unknown[]) => {
          return validation.every((v) => v(...args) === true);
        }
      : () => true;

    return Boolean(email && validate('email', email) === true);
  }

  @computed('args.inviteChangeset.userData.[]')
  get phoneNumber(): UserDatum | undefined {
    const userData = this.args.inviteChangeset.userData || A();
    return userData.findBy('field', 'Your Phone Number');
  }

  @computed('args.inviteChangeset.isNew', 'timezone')
  get minArrivalDate(): moment.Moment | string {
    const timezone = get(this, 'timezone');

    if (<boolean>(<unknown>this.args.inviteChangeset.isNew)) {
      return timezone ? moment().tz(timezone) : moment();
    }

    return '';
  }

  @computed('args.inviteChangeset.isNew', 'expectedArrivalTime', 'timezone')
  get minArrivalTime(): string {
    const timezone = this.timezone;
    const now = timezone ? moment().tz(timezone).format('h:mm a') : moment().format('h:mm a');

    if (!this.expectedArrivalTime) return now;

    const isNew = get(this, 'args.inviteChangeset.isNew');
    const isForToday = moment(this.expectedArrivalTime).isSame(moment(), 'day');

    return isNew && isForToday ? now : '';
  }

  @computed('expectedArrivalTime', 'timezone')
  get selectedTime(): string {
    if (!this.expectedArrivalTime) return '';

    const timezone = this.timezone;
    const time = timezone ? moment(this.expectedArrivalTime).tz(timezone) : moment(this.expectedArrivalTime);
    return time.format('h:mm a');
  }

  // we only care if the model changes since the POV field can't be deleted
  // this is not a Flow model but rather the user-data containing the flow-name
  @computed('args.inviteChangeset.{userData.@each.field,flowName}')
  get purposeOfVisit(): UserDatum | Partial<UserDatum> {
    const userData = this.args.inviteChangeset.userData || A();
    const flowName = this.args.inviteChangeset.flowName;

    return userData.findBy('field', 'Purpose of visit') || { field: 'Purpose of visit', value: flowName };
  }

  @computed('args.{invite.userData,inviteChangeset.location.id}', 'selectedFlow', 'signInFields')
  get userDataChangesets(): Record<string, DetailedChangeset<SignInFieldModel>> {
    const initialState = <Record<string, DetailedChangeset<SignInFieldModel>>>EmberObject.create({});

    // bootstrap the initial state for the changeset so it uses whatever is in the invite.userData
    // this is required to show pre-existing data
    const userData = this.args.invite.userData || A();
    userData.forEach((option, index) => {
      const fieldName = get(option, 'field');
      const value = get(option, 'value');
      // ignore purpose of visit here
      // eslint-disable-next-line ember/use-ember-get-and-set
      if (value !== get(this.selectedFlow ?? {}, 'name') && fieldName) {
        let field = this.signInFields?.findBy('name', fieldName);

        if (!field) {
          field = {
            id: `${index}-${<string>get(this, 'args.inviteChangeset.location.id')}`, // pseudo-id
            name: fieldName,
            // @ts-ignore
            isLoaded: true,
          };
        }

        const fieldChangeset = this.buildFieldChangeset(field);
        set(fieldChangeset, 'value', value); // set the initial value

        set(initialState, this.pseudoIdForField(fieldChangeset), fieldChangeset);
      }
    });
    return initialState;
  }

  get shouldShowGroupName(): boolean {
    if (!this.featureFlags.isEnabled('visitors-group-invite-updates-m1')) return false;
    if (!this.state.features?.canAccessGroupInvitesFeature) return false;

    return this.args.invite?.isInGroupInvite;
  }

  isVisitorDocumentPersistable(visitorDocument: VisitorDocumentModel): boolean {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;

    return isAssociatedToActiveTemplate && hasAttachmentsWithPendingUploads;
  }

  @(employeesSearcherTask({
    filter: { deleted: false, excludeHidden: true },
    prefix: false,
  }).restartable())
  searchEmployeesTask!: EmployeeSearcherTask;

  @action
  saveAndAddAnother(): void {
    void this.saveTask.perform({ addAnother: true });
  }

  @action
  onFormSubmit(e: Event): void {
    e.preventDefault();
    if (this.secondaryLocationNames.length && this.args.isEditInvite) {
      this.toggleMultiLocationsEditModal();
    }

    if (this.editAffectsMultipleLocationsModal) {
      return;
    }
    if (this.hasRecurringInvite) {
      this.isShowingEditRecurringInvite = true;
    } else if (this.shouldSendNotification) {
      this.sendNotificationCallback = () => this.saveTask.perform();
    } else {
      void this.saveTask.perform();
    }
  }

  @action
  onSubmitRecurringInvite(whichInvites: SaveOptions['whichInvites']): TaskInstance<void> {
    return this.saveTask.perform({ whichInvites });
  }

  @action
  buildFieldChangeset(field: SignInFieldModel | undefined): DetailedChangeset<SignInFieldModel> {
    const validations = buildFlowFieldValidations(field);
    const validator = lookupValidator(validations);
    return Changeset(field, validator, validations);
  }

  @action
  async updatePropsRecurringInvite(recurringInvite: RecurringInviteModel): Promise<RecurringInviteModel> {
    const inviteChangeset = this.args.inviteChangeset;
    const newExpectedArrivalTime = inviteChangeset.expectedArrivalTime;

    setProperties(recurringInvite, {
      email: inviteChangeset.email,
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      employee: inviteChangeset.employee?.content ?? inviteChangeset.employee,
      fullName: inviteChangeset.fullName,
      location: inviteChangeset.location?.content,
      privateNotes: inviteChangeset.privateNotes,
      visitorDocuments: inviteChangeset.visitorDocuments,
      childLocations: await inviteChangeset.childInviteLocations,
    });

    if (newExpectedArrivalTime) {
      const existingStartTime = moment(recurringInvite.startTime);
      const newHour = moment(newExpectedArrivalTime).hour();
      const newMinute = moment(newExpectedArrivalTime).minute();
      const newStartTime = moment(existingStartTime).hour(newHour).minute(newMinute).toDate();

      setProperties(recurringInvite, {
        startTime: newStartTime,
      });
    }

    if (inviteChangeset.recurringRule) {
      recurringInvite.recurringRule = inviteChangeset.recurringRule;
    }

    return recurringInvite;
  }

  @action
  modifySecondaryLocations(locations?: LocationModel[]): void {
    this.args.inviteChangeset.childInviteLocations = locations;
  }

  saveRecurringInviteTask = dropTask(async (existingRecurringInvite: RecurringInviteModel, whichInvites) => {
    const inviteChangeset = this.args.inviteChangeset;
    let recurringInvite: RecurringInviteModel;

    if (existingRecurringInvite && existingRecurringInvite.id && whichInvites === 'all') {
      recurringInvite = await this.updatePropsRecurringInvite(existingRecurringInvite);
    } else {
      set(inviteChangeset, 'skipGuestNotification', !this.sendGuestEmail && this.canSendGuestEmail);

      // eslint-disable-next-line @typescript-eslint/await-thenable
      recurringInvite = await this.store.createRecord('recurring-invite', {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        employee: inviteChangeset.employee?.content ?? inviteChangeset.employee,
        location: inviteChangeset.location?.content,
        fullName: inviteChangeset.fullName,
        email: inviteChangeset.email,
        privateNotes: inviteChangeset.privateNotes,
        recurringRule: inviteChangeset.recurringRule,
        skipGuestNotification: inviteChangeset.skipGuestNotification,
        visitorDocuments: inviteChangeset.visitorDocuments,
        propertyNotes: inviteChangeset.propertyNotes,
        locality: inviteChangeset.locality,
      });

      recurringInvite.childLocations = await inviteChangeset.childInviteLocations;
      recurringInvite.startTime = this.expectedArrivalTime;

      if (whichInvites === RECURRING_OPTIONS.THIS_AND_FOLLOWING) {
        setProperties(recurringInvite, {
          parentRecurringInvite: existingRecurringInvite,
        });

        if (!recurringInvite.recurringRule) {
          recurringInvite.recurringRule = existingRecurringInvite.recurringRule;
        }
      }
    }

    if (!get(this, 'selectedFlow.additionalGuests')) {
      // make additional guests always 0 if selected flow doesn't support it
      recurringInvite.additionalGuests = 0;
    } else {
      recurringInvite.additionalGuests = this.args.inviteChangeset.additionalGuests;
    }

    recurringInvite.userData = this.args.inviteChangeset.userData;
    // @ts-ignore
    recurringInvite.flow = this.selectedFlow;
    inviteChangeset.rollback();
    return recurringInvite.save();
  });

  saveTask = dropTask(async (options: SaveOptions = {}) => {
    const defaultOptions = { addAnother: false };

    options = Object.assign({}, defaultOptions, options);

    const inviteChangeset = this.args.inviteChangeset;
    await inviteChangeset.validate();

    // Only validate RENDERED / VISIBLE field changesets
    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      await changeset?.validate();
    }

    if (this.selectedFlow && !get(this.selectedFlow, 'id')) {
      this.flashMessages.showFlash('error', 'This sign-in flow no longer exists. Please select another one.');
      return;
    }

    if (inviteChangeset.isInvalid) {
      const specificError = <string>get(inviteChangeset, 'errors.firstObject.validation');
      this.flashMessages.showFlash('error', 'Please fill all the required fields.', specificError);
      return;
    }

    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      if (get(changeset, 'isInvalid')) {
        const specificError = <string>get(changeset, 'errors.firstObject.validation.firstObject');
        this.flashMessages.showFlash('error', 'Please fill all the required fields.', specificError);
        return;
      }
    }

    if (!this.isValidVisitorDocuments) {
      this.flashMessages.showFlash('error', 'Please enter all required information');
      return;
    }

    // If invite has additional hosts added *AND* has multiple locations or is recurring, show an error and don't save.
    // This behavior will be improved in the future; this solution is a stop-gap measure to simply prevent recurring
    // or multi-location invites from being created at all.
    if (<number>inviteChangeset.additionalHosts?.length > 0) {
      const isRecurring = !!inviteChangeset.recurringRule;
      const hasMultipleLocations = <number>inviteChangeset.childInviteLocations?.length > 0;
      let error;
      if (isRecurring && hasMultipleLocations) {
        error =
          'Recurring invites and multiple locations are not supported for invites with multiple hosts. Please either remove the additional hosts, or make the invite a one-time invite for a single location.';
      } else if (isRecurring) {
        error =
          'Recurring invites cannot be created with multiple hosts. Please remove additional hosts or create a one-time invite.';
      } else if (hasMultipleLocations) {
        error = 'Please remove either secondary locations or additional hosts to save this invite.';
      }
      if (error) {
        this.flashMessages.showFlash('error', 'Unable to invite / save', error);
        return;
      }
    }

    set(inviteChangeset, 'skipGuestNotification', !this.sendGuestEmail && this.canSendGuestEmail);

    const userDataChangesets = this.userDataChangesets;

    Object.values(userDataChangesets).forEach((fieldChangeset) => {
      if (get(fieldChangeset, 'isDirty')) {
        addOrUpdateUserData(inviteChangeset, fieldChangeset.name, fieldChangeset.value);
      }
    });

    if (!get(this, 'selectedFlow.additionalGuests')) {
      // make additional guests always 0 if selected flow doesn't
      // support it
      set(inviteChangeset, 'additionalGuests', 0);
    }

    const token = isTesting ? testWaiter.beginAsync() : false;

    try {
      const date = this.expectedArrivalTime;
      const recurringInvite = await this.args.invite.recurringInvite;
      const recurringInviteId = recurringInvite ? recurringInvite.id : null;
      const whichInvites = options.whichInvites;
      const visitorDocuments = <VisitorDocumentModel[]>(<unknown>inviteChangeset?.visitorDocuments) ?? A();
      // if there's a recurring rule in the invite changeset OR if a recurring rule exists AND
      // we are editing this and following or all invites in a recurring rule series
      const isSavingRecurringInvite =
        get(inviteChangeset, 'recurringRule') ||
        (recurringInviteId &&
          (whichInvites === RECURRING_OPTIONS.THIS_AND_FOLLOWING || whichInvites === RECURRING_OPTIONS.ALL));

      let saveError;

      if (isSavingRecurringInvite) {
        const inviteAttrs = getProperties(
          inviteChangeset,
          'flowName',
          'expectedArrivalTime',
          'employee',
          'inviterName',
        );

        const savedRecurringInvite = await this.saveRecurringInviteTask.perform(recurringInvite, whichInvites);

        try {
          await this.saveVisitorDocumentsTask.perform(savedRecurringInvite, visitorDocuments);
        } catch (_) {
          // Recurring invites create "child" invites async on the backend.
          // If a recurring invite saves but its documents do not, there is not
          // much we can do to position the user to retry the upload. For now,
          // proceeding with existing logic with a flash message to report that
          // something went wrong. ~ dana mar.3.2022
          saveError = 'Recurring invite saved! But one or more document uploads failed';
        }

        if (options.addAnother) {
          this.metrics.trackEvent('Dashboard Invite - Add Another', {
            recurring_invite_id: savedRecurringInvite.id,
          });
        }

        const goToIndex = Boolean(saveError) || !options.addAnother;

        this.args.modelDidSave(date, goToIndex, inviteAttrs);
      } else {
        const invite = await inviteChangeset.save();

        // fixes issue where response from BE differs from POST vs GET and approvalStatus does not get re-serialized properly when reloaded via GET
        this.args.invite.rollbackAttributes?.();

        try {
          await this.saveVisitorDocumentsTask.perform(invite, visitorDocuments);
        } catch (error) {
          this.flashMessages.showFlash('error', 'Invite saved! But one or more document uploads failed');

          // Handle invite successfully persisted but associated visitor document upload/save failed
          return this.args.modelDidPartialSave?.(invite, error);
        }

        if (options.addAnother) {
          this.metrics.trackEvent('Dashboard Invite - Add Another', { invite_id: invite.id });
        }

        this.args.modelDidSave(date, !options.addAnother, invite, { addAnother: options.addAnother });
      }

      // Clean changeset values
      Object.values(userDataChangesets).forEach((fieldChangeset) => {
        set(fieldChangeset, 'value', '');
      });

      if (saveError) {
        this.flashMessages.showFlash('error', saveError);
      } else {
        this.flashMessages.showAndHideFlash('success', 'Saved!');
      }
    } catch (e) {
      this.metrics.trackEvent('Error creating invite', {
        invite: inviteChangeset,
      });

      this.flashMessages.showFlash('error', parseErrorForDisplay(e));

      // NOTE we want to throw the error so rejection can be handled downstream as well
      throw e;
    } finally {
      if (token) testWaiter.endAsync(token);
    }
  });

  @action
  setHost(employee: EmployeeModel): void {
    const inviteChangeset = this.args.inviteChangeset;
    inviteChangeset.inviterName = employee?.name;
    inviteChangeset.employee = employee;
  }

  deleteRecurringInviteTask = dropTask(async (existingRecurringInvite: RecurringInviteModel) => {
    // this will be the end date for deleting this and following events
    // it's technically still a modify event, so PATCH request to v3/recurring-invites
    // the end date will be today, local time
    const date = moment(this.expectedArrivalTime).format('YYYY-MM-DD');
    const dayBeforeDate = moment(this.expectedArrivalTime).subtract(1, 'day');

    // get the existing recurrence rule and change it to be exactly as it is with a new until date
    // use recurrenceRule
    const parsedRule = RecurringRule.parse(existingRecurringInvite.recurringRule);
    parsedRule.until = dayBeforeDate;
    const newRRule = parsedRule.toString();

    setProperties(existingRecurringInvite, {
      recurringRule: newRRule,
    });
    await existingRecurringInvite.save();
    this.metrics.trackEvent('Recurring Invite Delete Requested', {
      recurring_invite_id: existingRecurringInvite.id,
      invite_id: get(this.args.inviteChangeset, 'id'),
    });
    void this.router.transitionTo('visitors.invites', {
      queryParams: { date },
    });
  });

  deleteTask = dropTask(async (options: { thisAndFollowing?: boolean } = {}) => {
    try {
      const recurringInvite = await this.args.invite.recurringInvite;
      const recurringInviteId = recurringInvite ? recurringInvite.id : null;
      const isDeletingRecurringInvite = recurringInviteId && options.thisAndFollowing;

      // check if this is part of a recurring invite
      if (isDeletingRecurringInvite) {
        void this.deleteRecurringInviteTask.perform(recurringInvite);
      } else {
        await this.args.invite.destroyRecord();

        this.flashMessages.showAndHideFlash('success', 'Deleted');
        this.args.modelDidSave();
      }
    } catch (_e) {
      this.flashMessages.showFlash('error', 'Invite cannot be deleted');
    }
  });

  @action
  onDeleteInvite(whichInvite: ValueOf<typeof RECURRING_OPTIONS>): TaskInstance<void> {
    const thisAndFollowing = whichInvite === RECURRING_OPTIONS.THIS_AND_FOLLOWING;
    return this.deleteTask.perform({ thisAndFollowing });
  }

  @action
  setDate(date: Date): void {
    // this is setting a date object, possible cause of bugs at the end of the month
    set(this.args.inviteChangeset, 'expectedArrivalTime', date);
    if (this.args.dateChanged) {
      this.args.dateChanged(date);
      this.args.inviteChangeset.rruleUntil = null;
      this.args.inviteChangeset.recurringRule = null;
    }
  }

  @action
  toggleMultiLocationsEditModal(): void {
    this.editAffectsMultipleLocationsModal = !this.editAffectsMultipleLocationsModal;
  }

  @action
  toggleConfirmVisitorTypeChangeModal(): void {
    this.showConfirmVisitorTypeChangeModal = !this.showConfirmVisitorTypeChangeModal;
  }

  @action
  toggleConfirmVisitorTypeChangeModalAndClear(): void {
    this.modifySecondaryLocations();
    this.updateFlow(this.flowToChangeTo!);
    this.showConfirmVisitorTypeChangeModal = !this.showConfirmVisitorTypeChangeModal;
  }

  @action
  updateFlow(flow: FlowModel): void {
    const previousFlow = this.args.inviteChangeset.flowName;

    this.metrics.trackEvent('Visitor Type Changed', {
      previousFlow,
      newFlow: flow?.name,
      flowId: flow?.id,
    });

    // Added to handle resetting fields for Multi-Location Invite option
    if (!this.showConfirmVisitorTypeChangeModal && this.args.inviteChangeset.childInviteLocations?.length) {
      this.toggleConfirmVisitorTypeChangeModal();
      this.flowToChangeTo = flow;
      return;
    }
    // TODO merge these in app level, and move the setting to the serializer
    set(this.purposeOfVisit, 'value', flow.name);
    this.args.inviteChangeset.flowName = flow.name;
    // assign flow (not sent up via PATCH request, but used by the UI. `flow-name` currently controls what flow gets assigned)
    this.args.inviteChangeset.flow = flow;

    this.args.onFlowChanged?.(flow.name, this.args.inviteChangeset);
  }

  @action
  pseudoIdForField(field: { id: string }): string {
    // The key provided to set must be a string.
    return `field-${field.id}`;
  }

  @action
  updateUserDataField(changeset: DetailedChangeset<SignInFieldModel>): void {
    this.userDataChangesets[this.pseudoIdForField(changeset)] = changeset;
  }

  @action
  setAdditionalHosts(hosts: EmployeeModel[]): void {
    this.args.inviteChangeset.additionalHosts = hosts;
  }

  @action
  setHostAndUserData(changeset: DetailedChangeset<SignInFieldModel>, employee: EmployeeModel): void {
    // Employee is nil when host is cleared
    const employeeName = employee && employee.name;

    changeset.value = employeeName;
    this.updateUserDataField(changeset);

    const inviteChangeset = this.args.inviteChangeset;
    inviteChangeset.employee = employee;
    inviteChangeset.inviterName = employeeName;
  }

  @action
  searchEmployees(term: string): TaskInstance<EmployeeModel[]> {
    const extraFilters = {
      locations: get(this.args.inviteChangeset.location, 'id'),
    };
    return this.searchEmployeesTask.perform(term, extraFilters);
  }

  @action
  setRrule(recurringRule: string): void {
    this.args.inviteChangeset.recurringRule = recurringRule;
  }

  @action
  handleGuestEmail({ target }: { target: HTMLInputElement }): void {
    this.sendGuestEmail = target.checked;
  }

  @action
  trackReadOnlyClicksOnly(invite_id: string): void {
    if (this.args.shouldShowRecurrence && this.args.isEditInvite) {
      this.metrics.trackEvent('Recurring Events - Read Only Expected Arrival Date/Time Clicked', { invite_id });
    }
  }

  @action
  didSelectTime(newTime: string): void {
    const timezone = get(this, 'timezone');
    let date;

    if (this.expectedArrivalTime) {
      date = timezone ? moment(this.expectedArrivalTime).tz(timezone) : moment(this.expectedArrivalTime);
    } else {
      date = timezone ? moment().tz(timezone) : moment();
    }
    const newDate = moment(newTime, 'h:mm a');

    date.hours(newDate.hours());
    date.minutes(newDate.minutes());
    get(this, 'setDate').call(this, date.toDate());
  }

  @action
  attachFileToDocument(
    visitorDocument: VisitorDocumentModel,
    userDocumentTemplateAttachment: UserDocumentTemplateAttachmentModel,
    update: File | string,
  ): void {
    const { inviteChangeset } = this.args;

    // This updates the changeset with minimal modification to underlying model data
    if (inviteChangeset) {
      const visDocs = A([...inviteChangeset.visitorDocuments.toArray(), visitorDocument]).uniqBy('identifier');

      set(inviteChangeset, 'visitorDocuments', visDocs);
    }

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument: VisitorDocumentModel): void {
    const {
      hasRecurringInvite,
      args: { inviteChangeset },
    } = this;

    if (visitorDocument.isNew || hasRecurringInvite) {
      const visDocs = A([...inviteChangeset.visitorDocuments.toArray()]).without(visitorDocument);

      inviteChangeset.visitorDocuments = visDocs;

      if (<boolean>(<unknown>visitorDocument.isNew)) visitorDocument.unloadRecord();
    } else {
      this.confirmRemovalOfDocument = visitorDocument;
    }
  }

  saveVisitorDocumentsTask = task(
    async (invite: InviteModel | RecurringInviteModel, visitorDocuments: VisitorDocumentModel[]) => {
      return await all(
        visitorDocuments.map((visitorDocument) => this.saveVisitorDocumentTask.perform(invite, visitorDocument)),
      );
    },
  );

  saveVisitorDocumentTask = task(
    async (invite: InviteModel | RecurringInviteModel, visitorDocument: VisitorDocumentModel) => {
      if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

      // Associate the invite's location to the visitor document (fallback to current location)
      const location =
        this.store.peekRecord('location', invite.belongsTo('location').id()) || this.state.currentLocation;
      const association = <'invites' | 'recurringInvites'>(
        pluralize(camelize((<{ modelName: string }>(<unknown>invite.constructor)).modelName))
      );

      visitorDocument.locations = A([location]);
      // @ts-ignore
      visitorDocument[association].addObject(invite);

      await this.uploadVisitorDocumentAttachmentsTask.perform(invite, visitorDocument);
      await visitorDocument.save();

      // Cleanup attachments
      // eslint-disable-next-line @typescript-eslint/await-thenable
      await visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

      return visitorDocument;
    },
  );

  uploadVisitorDocumentAttachmentsTask = task(
    async (invite: InviteModel | RecurringInviteModel, visitorDocument: VisitorDocumentModel) => {
      const { activeStorageExtension } = this;
      const directUploadURL = '/a/visitors/api/direct-uploads';
      const userDocumentTemplateId = visitorDocument.belongsTo('userDocumentTemplate').id();

      if (!(invite?.id && userDocumentTemplateId)) return;

      const pathSegment = underscore(pluralize((<{ modelName: string }>(<unknown>invite.constructor)).modelName));
      const prefix = `user-documents/${userDocumentTemplateId}/${pathSegment}/${invite.id}`;
      const endpoint = `${directUploadURL}?prefix=${prefix}`;

      return await all(
        visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
          this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension),
        ),
      );
    },
  );

  @task uploadDocumentAttachmentTask = {
    progress: 0,

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    *perform(
      userDocumentAttachment: UserDocumentAttachmentModel,
      endpoint: string,
      activeStorageExtension: ActiveStorageService,
    ) {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const { signedId } = yield activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress: number) => {
          this.progress = progress;
          if (!userDocumentAttachment.isDestroyed && !userDocumentAttachment.isDestroying) {
            userDocumentAttachment.uploadProgress = progress;
          }
        },
      });

      userDocumentAttachment.file = <string>signedId;

      return <string>signedId;
    },
  };

  removeVisitorDocumentFromInviteTask = task(async (visitorDocument: VisitorDocumentModel, invite: InviteModel) => {
    const { userDocumentTemplate } = visitorDocument;

    try {
      await visitorDocument.removeFromInvites([invite]);
      invite.visitorDocuments.removeObject(visitorDocument);
      invite.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
      this.flashMessages.showAndHideFlash('success', `${visitorDocument.title} removed from invite`);
    } catch (_) {
      const template =
        userDocumentTemplate && 'content' in userDocumentTemplate ? userDocumentTemplate.content : userDocumentTemplate;
      if (template) invite.visitorDocuments.removeObject(invite.visitorDocumentForTemplate(template)!);
      invite.visitorDocuments.addObject(visitorDocument);
      this.flashMessages.showFlash('error', `Failed to remove ${visitorDocument.title} from invite`);
    }
  });

  @action
  emailOnFocusIn(): void {
    this.emailHasFocus = true;
  }

  @action
  emailOnFocusOut(): void {
    this.emailHasFocus = false;
  }
}
