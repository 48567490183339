import { action } from '@ember/object';
import Controller from '@ember/controller';
import { service } from '@ember/service';
import { BANNERS } from 'garaje/utils/enums';

export default class BillingProductPlansUpgradeController extends Controller {
  @service router;
  @service bannerManager;

  @action
  transitionToBilling() {
    if (this.model.subscription.app === 'deliveries' && this.model.deliveryLimit.enforceLimit) {
      this.model.deliveryLimit.reload();
    }
    this.bannerManager.remove(BANNERS.UPGRADE_REQUIRED);
    this.router.transitionTo('billing.index');
  }
}
