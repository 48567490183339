import Controller from '@ember/controller';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { ValidationErr } from 'ember-changeset-validations';
import lookupValidator from 'ember-changeset-validations';
import { dropTask, restartableTask } from 'ember-concurrency';
import type LocationModel from 'garaje/models/location';
import type AddressLookupService from 'garaje/services/address-lookup';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import LocationValidations from 'garaje/validations/location';
import { reject } from 'rsvp';

export default class VisitorsSettingsPreRegistrationUpgradeController extends Controller {
  declare model: LocationModel;

  @service declare addressLookup: AddressLookupService;
  @service declare flashMessages: FlashMessagesService;
  @service declare router: RouterService;

  @tracked changeset!: DetailedChangeset<LocationModel>;

  _buildChangeset(model: LocationModel): void {
    const validator = lookupValidator(LocationValidations);
    this.changeset = Changeset(model, validator, LocationValidations);
  }

  get addressIsEmpty(): boolean {
    const locationHasAddress = this.model.address;
    const addressHasBeenAdded = this.changeset.isDirty && this.changeset.isValid;
    return !locationHasAddress && !addressHasBeenAdded;
  }

  enablePreRegistrationTask = dropTask(async () => {
    try {
      const changeset = this.changeset;
      changeset.preRegistrationEnabled = true;
      await changeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.router.transitionTo('visitors.settings.invites');
    } catch (error) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(error));
    }
  });

  validateAndUpdateAddress = restartableTask(async () => {
    const changeset = this.changeset;
    const addressLookup = this.addressLookup;

    try {
      await addressLookup.updateAddress(changeset.address, changeset);
    } catch (error) {
      changeset.addError('address', <ValidationErr>error);
      await reject(error);
    }
  });
}
