import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { pluralize } from 'ember-inflector';
import { MINUTES_IN_A_DAY } from 'garaje/utils/enums';

/**
 * @param {Model<Location>}         location
 * @param {Function}                onChange Action to set scheduleEligibilityOffset
 */
export default class SchedulingLimitComponent extends Component {
  @service flashMessages;
  @service metrics;
  @service workplaceMetrics;

  @tracked expandableSettingsPanelExpanded = false;

  constructor() {
    super(...arguments);

    this.offsetOptions = [
      { offset: null, label: 'As far as possible' },
      { offset: -MINUTES_IN_A_DAY, label: 'Today only' },
      { offset: -MINUTES_IN_A_DAY * 2, label: 'Today & tomorrow' },
    ];

    for (let weekOffset = 1; weekOffset < 13; weekOffset++) {
      this.offsetOptions.push({
        offset: weekOffset * 7 * -MINUTES_IN_A_DAY,
        label: `${pluralize(weekOffset, 'week')} in advance`,
      });
    }
  }

  /**
   * @return {Boolean}
   */
  get isLocationDirty() {
    return (
      this.args.location.hasDirtyAttributes &&
      Object.prototype.hasOwnProperty.call(this.args.location.changedAttributes(), 'scheduleEligibilityOffset')
    );
  }

  /**
   * @task `saveTask`
   */
  @dropTask
  *saveTask() {
    try {
      const {
        scheduleEligibilityOffset: [old_value, new_value],
      } = this.args.location.changedAttributes();
      yield this.args.location.save();
      this.metrics.trackEvent('Protect - Scheduling Limits Updated', {
        old_value,
        new_value,
      });
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.expandableSettingsPanelExpanded = false;
    } catch (e) {
      let message = 'Server error. Please try again.';

      if (e.isAdapterError) {
        message = e.errors.mapBy('detail').join(', ');
      }

      this.flashMessages.showFlash('error', message);
    }
  }

  @action
  rollback() {
    const { scheduleEligibilityOffset: offset } = this.args.location.changedAttributes();

    offset && this.args.onChange(offset[0]);
  }

  @action
  expandPanel() {
    this.expandableSettingsPanelExpanded = true;
    this.workplaceMetrics.trackEvent('WORKPLACE_SETTINGS_EDIT_SCHEDULING_LIMITS_BUTTON_CLICKED');
  }
}
