import Component from '@glimmer/component';
import { service } from '@ember/service';
import _cloneDeep from 'lodash/cloneDeep';
import { format, getDate, isBefore, isToday, startOfDay, addMinutes } from 'date-fns';
import { getPartialTimes } from 'garaje/utils/hour-options';
import { APPROVAL_STATUS, INVITE_STATE } from 'garaje/utils/enums';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import config from 'garaje/config/environment';
import { get } from '@ember/object';

/**
 * @param {Array}             reservations      array of gql reservations
 * @param                      screeningCard     screeningCard for the day
 * @param {Date}              date              date for the invite
 * @param {number}            requiredDocumentApprovalExpiresAt
 * @param {boolean}           hasRequiredDocumentApproval
 * @param {Function}          selectInvite
 * @param {boolean}           bookDeskEnabled
 * @param                    invite            graphql representation of an invite
 * @param {number}            maxOccupancy      amount allowed in the office
 * @param {number}            peopleRegistered  total amount of people with invites for the day (not desk reservations)
 */
export default class DayAvailabilityComponent extends Component {
  @service state;
  @service metrics;
  @service flashMessages;
  @service registrations;

  INVITE_STATE_ENUM = INVITE_STATE;

  get dayOfWeek() {
    return format(this.args.date, 'eee');
  }
  get date() {
    return getDate(this.args.date);
  }

  get darkMode() {
    return [INVITE_STATE.SCHEDULED, INVITE_STATE.APPROVED, INVITE_STATE.PENDING, INVITE_STATE.SIGNED_IN].includes(
      this.inviteState,
    );
  }

  get errorMode() {
    return [INVITE_STATE.DENIED, INVITE_STATE.PAST_WINDOW].includes(this.inviteState);
  }

  get isNotRegistered() {
    return !this.isApproved && !this.isDenied;
  }

  get invite() {
    return this.args.screeningCard?.__typename == 'Invite' ? this.args.screeningCard : null;
  }

  get approvalStatus() {
    return this.invite?.approvalStatus?.status;
  }

  get isApproved() {
    return this.approvalStatus == APPROVAL_STATUS.APPROVED;
  }

  get isDenied() {
    return this.approvalStatus == APPROVAL_STATUS.DENIED;
  }

  get isToday() {
    return isToday(this.args.date);
  }

  get preregistrationLink() {
    return this.invite?.preregistrationLink || '';
  }
  get registerUrl() {
    const garajeReturnUrl = `${config.host}/schedule%3FselectedInviteId%3D${this.invite?.id}`;

    return this.noRequiredDocumentApproval ? null : `${this.preregistrationLink}?garajeReturnUrl=${garajeReturnUrl}`;
  }

  get screeningWindowStarted() {
    const { registrationEligibilityStartOffset, employeeScreeningFlow } = this.state.currentLocation;
    if (employeeScreeningFlow && !get(employeeScreeningFlow, 'employeeScreeningRequired')) {
      return true;
    }
    const startOfWindow = addMinutes(startOfDay(this.args.date), registrationEligibilityStartOffset || 0);
    return !isBefore(new Date(), startOfWindow);
  }

  get screeningWindowEnded() {
    const { registrationEligibilityEndOffset, employeeScreeningFlow } = this.state.currentLocation;
    if (employeeScreeningFlow && !get(employeeScreeningFlow, 'employeeScreeningRequired')) {
      return false;
    }
    const endOfWindow = addMinutes(startOfDay(this.args.date), registrationEligibilityEndOffset || 0);
    return !isBefore(new Date(), endOfWindow);
  }

  get isShowingInviteCta() {
    return (
      this.screeningWindowStarted &&
      [INVITE_STATE.SCHEDULED, INVITE_STATE.APPROVED, INVITE_STATE.SIGNED_IN].includes(this.inviteState)
    );
  }

  get inviteState() {
    if (this.isNotRegistered) {
      if (this.screeningWindowEnded) {
        return INVITE_STATE.PAST_WINDOW;
      } else if (!this.invite) {
        return INVITE_STATE.UNSCHEDULED;
      }
      return INVITE_STATE.SCHEDULED;
    } else {
      if (!this.invite?.entry) {
        // registered states
        if (this.isApproved) return INVITE_STATE.APPROVED;
        if (this.isDenied) return INVITE_STATE.DENIED;
        return INVITE_STATE.PENDING;
      }
      return INVITE_STATE.SIGNED_IN;
    }
  }

  get noRequiredDocumentApproval() {
    const { date, requiredDocumentApprovalExpiresAt, hasRequiredDocumentApproval } = this.args;
    const isExpiredDocument =
      requiredDocumentApprovalExpiresAt && !isBefore(date, new Date(requiredDocumentApprovalExpiresAt));
    return isExpiredDocument || !hasRequiredDocumentApproval;
  }

  get isDayBookable() {
    const { date, workplaceDays } = this.args;
    if (workplaceDays) {
      const currentDay = format(date, 'eeee');
      const workplaceDay = workplaceDays.find((day) => day.dayOfWeek === currentDay);
      return workplaceDay.active;
    }
    return true;
  }

  get bookDeskEnabled() {
    const { bookDeskEnabled, date, workplaceDays } = this.args;
    const currentDay = format(date, 'eeee');
    const workplaceDay = workplaceDays.find((day) => day.dayOfWeek === currentDay);
    const [_, partialEnd] = getPartialTimes(startOfDay(date), workplaceDay);
    if (isToday(date)) {
      return bookDeskEnabled && isBefore(new Date(), partialEnd);
    }
    return bookDeskEnabled;
  }

  signInInviteTask = task({ drop: true }, async () => {
    const { screeningCard, reservation, date } = this.args;

    try {
      const entry = await this.registrations.signInInvitePartialDay(date);
      this.metrics.trackEvent('Web Employee Schedule - Sign In', {
        desk_id: reservation?.desk.id,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location.id,
        invite_date: screeningCard.expectedArrivalTime,
        invite_id: screeningCard.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed in');
    } catch (e) {
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });

  signOutEntryTask = task({ drop: true }, async () => {
    const { date, reservation, screeningCard } = this.args;

    try {
      const entry = await this.registrations.signOutEntryPartialDay(date);
      this.metrics.trackEvent('Web Employee Schedule - Sign Out', {
        desk_id: reservation?.desk.id,
        employee_entry_sign_out_at: entry.signedOutAt,
        entry_date: entry.signed,
        entry_flow_name: entry.flowName,
        entry_id: entry.id,
        entry_location_id: entry.location.id,
        invite_id: screeningCard.id,
        reservation_id: reservation?.id,
      });
      this.flashMessages.showAndHideFlash('success', 'Signed out');
    } catch (e) {
      console.error('error', e); // eslint-disable-line no-console
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e.errors?.firstObject));
    }
  });
}
