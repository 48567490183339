import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { buildChangeset, FakeChangeset } from 'garaje/utils/legal-document';
import { isAppUpdateRequired } from 'garaje/utils/check-app-version';
import { alias } from 'macro-decorators';
import { MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS_AND_FOOTER } from '../edit/controller';

const MINIMUM_IPAD_VERSION_NEEDED = '3.3.6';
export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsNewController extends Controller {
  @service featureFlags;
  @service currentLocation;

  @tracked bccChangeset;
  @tracked ndaChangeset;
  @tracked changeset;

  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED) isAppUpdateRequired;
  @isAppUpdateRequired('model.ipads', MINIMUM_IPAD_VERSION_NEEDED_FOR_DOCUMENTS_AND_FOOTER)
  isAppUpdateRequiredDocumentsAndFooter;

  @alias('currentLocation.languagesAvailable') translationLanguagesAvailable;

  setupChangeset() {
    this.bccChangeset = buildChangeset(this.model.agreement); // changeset to keep track of sendToBcc and bccEmail changes
    this.ndaChangeset = buildChangeset(this.model.agreement); // changeset to keep track of body, require-resign and duration changes
    this.changeset = new FakeChangeset([this.bccChangeset, this.ndaChangeset]); // fake changeset wrapping the two main sections changesets
  }

  get translationEnabledLocales() {
    return [get(this.currentLocation, 'enabledLocales'), get(this.currentLocation, 'locale')].flat().uniq();
  }

  get isSaved() {
    return !this.model.agreement.isNew && !this.model.agreement.hasDirtyAttributes;
  }

  get legalDocumentHeader() {
    return document.getElementById('legal-document-header');
  }

  @action
  addNewLegalDocument() {
    this.send('refreshModel');
    window.scrollTo(0, 0);
  }

  @action
  _trackAddLegalDocStarted() {
    this.send('trackAddLegalDocStarted');
  }
}
