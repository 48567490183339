import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type LocationModel from 'garaje/models/location';
import type ProtectedController from 'garaje/pods/protected/controller';
import { FlowType } from 'garaje/utils/enums';

export default class LocationOverviewSignInFlowsController extends Controller {
  @service declare router: RouterService;
  @controller('protected') declare protectedController: ProtectedController;

  @tracked showGlobalFlowCreationModal = false;
  @tracked loadingData = false;

  @action
  handleLocationTransition(location: LocationModel): void {
    this.protectedController.send('switchLocation', location.id, 'visitors.settings.visitor-types');
  }

  @action
  handleFlowTransition(flow: { data: FlowModel; id: string }): Transition | void {
    if (flow.data.type === FlowType.GLOBAL_CHILD) {
      return this.router.transitionTo(
        'location-overview.sign-in-flows.show.index',
        (<GlobalFlowModel>(<unknown>flow.data.globalFlow)).id,
      );
    }

    this.protectedController.send(
      'switchLocation',
      (<LocationModel>(<unknown>flow.data.location)).id,
      'visitors.settings.visitor-types.flow.index',
      flow.id,
    );
  }
}
