import Route from '@ember/routing/route';

interface RouteParams {
  resource_type_id: string;
}

export default class ResourcesResourceTypeIndexRoute extends Route {
  model({ resource_type_id }: RouteParams): void {
    // eslint-disable-next-line no-console
    console.log(resource_type_id);
  }
}
