import Evented from '@ember/object/evented';
import Service from '@ember/service';

/**
 * events:
 *
 * flow-refresh
 * global-flow-refresh
 */
export default class InfinityPubsubService extends Service.extend(Evented) {}
