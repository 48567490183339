import Component from '@glimmer/component';

/**
 * @param {String}            style     inset || full
 */
export default class SpContent extends Component {
  get style() {
    return this.args.style ?? 'inset';
  }
}
