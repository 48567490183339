import { service } from '@ember/service';
import { get } from '@ember/object';
import { restartableTask } from 'ember-concurrency';
import RSVP from 'rsvp';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

function validateAndUpdateAddressTask(Class) {
  return class ValidateAndUpdateAddressTask extends Class {
    @service addressLookup;

    @restartableTask
    *validateAndUpdateAddress(allowToFail = false) {
      const changeset = this.changeset;
      const addressLookup = this.addressLookup;

      try {
        yield addressLookup.updateAddress(get(changeset, 'address'), changeset);
      } catch (error) {
        if (allowToFail) {
          yield RSVP.resolve();
        } else {
          const errorText = parseErrorForDisplay(error);
          changeset.addError('address', errorText);
          yield RSVP.reject(error);
        }
      }
    }
  };
}

export default validateAndUpdateAddressTask;
