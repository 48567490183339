import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type StoreService from '@ember-data/store';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';

import type UploadController from '../controller';
import type { ProfileDocumentUploadRouteModel } from '../route';

export interface ProfileDocumentUploadAttachmentRouteModel extends ProfileDocumentUploadRouteModel {
  userDocumentTemplateAttachment?: UserDocumentTemplateAttachmentModel;
  userDocumentAttachment?: UserDocumentAttachmentModel;
}

export default class ProfileDocumentUploadAttachmentRoute extends Route {
  @service declare store: StoreService;
  @service declare router: RouterService;

  model({ attachment }: { attachment: string }): ProfileDocumentUploadAttachmentRouteModel {
    const parentModel = <ProfileDocumentUploadRouteModel>this.modelFor('profile.document.upload');
    const { userDocumentTemplate, userDocument } = parentModel;
    const userDocumentTemplateAttachment = userDocumentTemplate?.userDocumentTemplateAttachments.findBy(
      'identifier',
      attachment,
    );

    let userDocumentAttachment;

    if (userDocumentTemplateAttachment) {
      userDocumentAttachment = userDocument?.getAttachment(userDocumentTemplateAttachment.id);
      if (!userDocumentAttachment) {
        userDocumentAttachment = this.store.createRecord('user-document-attachment');
        userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
        userDocumentAttachment.userDocument = userDocument!._content;
      }
    }

    return Object.assign({}, parentModel, { userDocumentTemplateAttachment, userDocumentAttachment });
  }

  afterModel({
    userDocumentTemplateAttachment,
    userDocument,
    userDocumentTemplate,
  }: ProfileDocumentUploadAttachmentRouteModel): void {
    if (isPresent(userDocumentTemplateAttachment)) {
      // eslint-disable-next-line ember/no-controller-access-in-routes
      (<UploadController>this.controllerFor('profile.document.upload')).currentDocumentTemplateAttachment =
        userDocumentTemplateAttachment;
    } else {
      void this.router.transitionTo('profile.document.upload', userDocumentTemplate!.identifier);
    }

    const requiredTemplateAttachment = userDocument?.checkRequiredTemplateAttachments(userDocumentTemplateAttachment);
    if (requiredTemplateAttachment) {
      void this.router.transitionTo(
        'profile.document.upload.attachment',
        userDocumentTemplate!.identifier,
        requiredTemplateAttachment.identifier,
      );
    }
  }
}
