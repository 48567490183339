import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type { MessageType } from 'garaje/services/flash-messages';
import type LocalStorageService from 'garaje/services/local-storage';
import type MessageBusService from 'garaje/services/message-bus';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

interface ReportData {
  companyId?: string | number;
  id?: string | number;
  [key: string]: unknown; // For any additional properties
}

export type ScheduledReportGlobalEvent = 'showCreateModal' | 'closeCreateModal' | 'showToaster';

export interface ScheduledReportGlobalMessage {
  event: ScheduledReportGlobalEvent;
  query?: {
    data?: ReportData;
  };
  type?: MessageType; // Used with showToaster event
  msg?: string; // Message content for showToaster event
}

export default class AnalyticsGlobalSigmaReportsController extends Controller {
  @service declare localStorage: LocalStorageService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare messageBus: MessageBusService;

  @tracked showModal = false;
  @tracked modalIframeUrl = '';
  @tracked modalData = {};

  @tracked loginRequired = false;

  constructor(properties: Record<string, unknown>) {
    super(properties);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.messageBus.on('embedded-app-message', this, this.handleMessage);
  }

  /**
   * @return {String}
   */
  get iframeUrl(): string {
    const {
      currentCompany: { id: companyId },
    } = this.state;
    return urlBuilder.scheduledReportsV2GlobalUrl(this.loginRequired, companyId);
  }

  @action
  toggleShowModal(): void {
    this.showModal = !this.showModal;
  }

  @action
  handleMessage(message: ScheduledReportGlobalMessage): void {
    const data = message?.query?.data || {};
    const companyId = (message?.query?.data?.companyId as string) || '';
    if (message.event === 'showCreateModal') {
      this.modalData = data;
      this.modalIframeUrl = urlBuilder.sigmaScheduleReportsModalUrlGlobal(companyId, this.loginRequired);
      this.toggleShowModal();
    } else if (message.event === 'closeCreateModal') {
      this.showModal = false;
    } else if (message.event === 'showToaster') {
      const type = message.type as MessageType;
      const msg = message.msg || '';
      this.flashMessages.showAndHideFlash(type, msg);
    }
  }

  @action
  trackLoggedIn(): void {
    /**
     * Issue: When user logs out from the Garaje app there is no way Next.js app knows it also needs to logout.
     * Due to this the cookies of the logout user may persist.
     * Solution: After login, force login the user on his first visit to this app. This refreshes all the cookies
     * To know if the user is visiting for the first time we will maintain a global state in localstorage called 'wa_logged_in'
     * On Login -> wa_logged_in does not exists in localstorage -> its first visit
     * On first visit -> SignIn, set wa_logged_in = true in localstorage
     * On Logout -> Remove wa_logged_in
     */

    const waLoggedIn = this.localStorage.getItem('wa_logged_in');

    if (!waLoggedIn) {
      this.loginRequired = true;
      this.localStorage.setItem('wa_logged_in', true);
    } else {
      this.loginRequired = false;
    }
  }
}
