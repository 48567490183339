import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import { belongsTo, hasMany } from '@ember-data/model';
import AbstractSignInFieldActionRuleGroupModel from 'garaje/models/abstract/abstract-sign-in-field-action-rule-group';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type EmployeeSignInFieldActionModel from 'garaje/models/employee-sign-in-field-action';
import type EmployeeSignInFieldActionsContactModel from 'garaje/models/employee-sign-in-field-actions-contact';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field-action-rule-group')
class EmployeeSignInFieldActionRuleGroupModel extends AbstractSignInFieldActionRuleGroupModel {
  @belongsTo('employee-screening-flow', { async: true }) declare flow: AsyncBelongsTo<EmployeeScreeningFlowModel>;
  @hasMany('employee-sign-in-field-action', { async: true })
  declare signInFieldActions: AsyncHasMany<EmployeeSignInFieldActionModel>;
  @hasMany('employee-sign-in-field-actions-contact', { async: true })
  declare signInFieldActionsContacts: AsyncHasMany<EmployeeSignInFieldActionsContactModel>;
}

export default EmployeeSignInFieldActionRuleGroupModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-sign-in-field-action-rule-group': EmployeeSignInFieldActionRuleGroupModel;
  }
}
