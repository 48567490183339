import Model, { attr, hasMany, type AsyncHasMany } from '@ember-data/model';
import type SkinnyLocationModel from 'garaje/models/skinny-location';

class GroupModel extends Model {
  @hasMany('skinny-location', { async: true }) declare locations: AsyncHasMany<SkinnyLocationModel>;
  @attr('string') declare name: string;

  get totalLocations(): number {
    return (<GroupModel>this).hasMany('locations').ids().length;
  }

  get total(): number {
    return this.totalLocations;
  }
}

export default GroupModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    group: GroupModel;
  }
}
