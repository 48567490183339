import Controller from '@ember/controller';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type SubscriptionsService from 'garaje/services/subscriptions';
import { or } from 'macro-decorators';

export default class LandingPageController extends Controller {
  @service declare currentAdmin: CurrentAdminService;
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare subscriptions: SubscriptionsService;
  @service declare featureFlags: FeatureFlagsService;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isBillingAdmin')
  declare canStartTrial: boolean;

  createSubscriptionTask = task({ drop: true }, async () => {
    this.roombaMetrics.trackEvent('start_get_started_clicked');
    await this.subscriptions.createRoomsSubscriptionTask.perform();
  });

  isWorkplaceFeatureEnabled(): boolean {
    return this.featureFlags.isEnabled('workplace-garaje-launch');
  }
}
