import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { reads } from 'macro-decorators';

import type { GroupOption, NameKey, SupportedModel } from '../component';

export interface MultiModelSelectorGroupRowComponentSignature {
  Args: {
    option: GroupOption;
    selectedItems?: SupportedModel[];
    onSelected?: (items: SupportedModel[]) => void;
    onGroupSelected?: (groupOption: GroupOption) => void;
    nameKey: NameKey;
    groupModelDisplayName: string;
  };

  Element: HTMLElement;
}

export default class MultiModelSelectorGroupRowComponent extends Component<MultiModelSelectorGroupRowComponentSignature> {
  inputId = 'checkbox-' + guidFor(this);

  @reads('args.option') declare option: GroupOption;
  @reads('args.selectedItems', []) declare selectedItems: SupportedModel[];

  get checkedState(): string {
    const groupItems = this.option.data.items.map((l) => l);
    const { selectedItems } = this;

    if (groupItems.every((l) => selectedItems.includes(l))) {
      return 'checked';
    }

    if (groupItems.some((l) => selectedItems.includes(l))) {
      return 'indeterminate';
    }

    return '';
  }

  @action
  onGroup(): void {
    this.args.onGroupSelected?.(this.option);
    this.#syncCheckboxState();
  }

  /**
   * Clicking an "indeterminate" checkbox typically causes that checkbox to
   * become checked. Instead, we uncheck the input and deselect all group members.
   * To override the default behavior of HTML checkboxes, #syncCheckboxState
   * applies the current checkedState to the input element.
   */
  #syncCheckboxState(): void {
    const { inputId, checkedState } = this;
    const checkbox = <HTMLInputElement>document.getElementById(inputId);

    switch (checkedState) {
      case 'checked':
        checkbox.checked = true;
        checkbox.indeterminate = false;
        break;
      case 'indeterminate':
        checkbox.checked = false;
        checkbox.indeterminate = true;
        break;
      default:
        checkbox.checked = false;
        checkbox.indeterminate = false;
        break;
    }
  }
}
