import { type Snapshot } from '@ember-data/store';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';

import V2Adapter from './v2';

export default class LocationAdapter extends V2Adapter {
  // requests to location/:id/request_ping use api/v3 while
  // the rest of location uses api/v2
  buildURL<K extends keyof ModelRegistry>(
    modelName?: K,
    id?: string,
    snapshot?: Snapshot<K>,
    requestType?: string,
  ): string {
    let url = super.buildURL(modelName, id, snapshot);
    if (['PING', 'v3'].includes(requestType!)) {
      url = url.replace('api/v2', 'api/v3');
    }
    return url;
  }
}
