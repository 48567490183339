import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { get } from '@ember/object';
import UserDocumentTemplate from 'garaje/models/user-document-template';

export default class VaccinationAlertModalComponent extends Component {
  @service router;

  @action
  navigateToUpload() {
    this.router.transitionTo(
      'profile.document.upload',
      get(this.args.configuration.userDocumentTemplateConfiguration, 'userDocumentTemplate.identifier'),
    );
    // selectively dismiss all modals to avoid any irregular user experiences with modals
    // popping up during transitions
    this.args.onClose(UserDocumentTemplate.validIdentifiers);
  }
}
