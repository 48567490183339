import Model, { attr, belongsTo } from '@ember-data/model';
import type UserModel from 'garaje/models/user';
import type VfdConfigurationModel from 'garaje/models/vfd-configuration';

export enum VfdContactMethodKind {
  EnvoyUser = 'envoy-user',
  Slack = 'slack',
  Teams = 'msteams',
}

export type VfdContactMethodSlackMetadata = {
  'channel-id': string;
  'channel-name': string;
};

export type VfdContactMethodTeamsMetadata = {
  'channel-id': string;
  'channel-name': string;
};

type VfdContactMethodMetadata = Record<string, never> | VfdContactMethodSlackMetadata | VfdContactMethodTeamsMetadata;

class VfdContactMethod extends Model {
  @belongsTo('vfd-configuration', { async: false }) declare configuration: VfdConfigurationModel;
  @belongsTo('user', { async: false }) declare user: UserModel | null; // must be empty when kind != "envoy-user"

  @attr('string') declare kind: VfdContactMethodKind;
  @attr('object') declare metadata: VfdContactMethodMetadata;
  @attr('number') declare contactPriority: number;
}

export default VfdContactMethod;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface VfdContactMethod {
    'vfd-contact-method': VfdContactMethod;
  }
}
