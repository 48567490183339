// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import Model, { attr, belongsTo } from '@ember-data/model';
import type { App } from 'garaje/utils/enums';
import { PERIOD } from 'garaje/utils/enums';
import { getPlanDetails, FEATURES, DOWNGRADE_RESTRICTIONS } from 'garaje/utils/plan-details';

export default class SubscriptionPlanModel extends Model {
  @attr('string') declare app: App;
  @attr('string') declare name: string;
  @attr('string') declare level: 'basic' | 'standard' | 'premium' | 'premium-plus' | 'enterprise';
  @attr('string') declare period: 'weekly' | 'monthly' | 'quarterly' | 'yearly';
  @attr('number') declare rank: 0 | 10 | 20 | 25 | 30;
  /**
   * Monthly
   */
  @attr('number') declare price: number;
  /**
   * Is available for self serve OR for sale
   */
  @attr('boolean') declare available: boolean;

  @belongsTo('subscription-plan', { async: false, inverse: null }) declare alternate: SubscriptionPlanModel;

  get pricePerPeriod(): number {
    if (this.period === PERIOD.YEARLY) {
      return this.price * 12;
    }
    return this.price;
  }

  @computed('period')
  get periodDisplay(): 'monthly' | 'yearly' {
    if (this.period === 'yearly') {
      return 'yearly';
    } else {
      return 'monthly';
    }
  }

  get details(): ReturnType<typeof getPlanDetails> {
    return getPlanDetails(this.app, this.level);
  }

  @computed('details.sequence')
  get sequence(): number | undefined {
    return this.details?.sequence;
  }

  @computed('app', 'level')
  get upgradeFeatures(): typeof FEATURES {
    return FEATURES.filter((feature) => feature.app === this.app && feature.level === this.level && feature.upgrade);
  }

  @computed('app', 'level')
  get downgradeFeatures(): typeof FEATURES {
    return FEATURES.filter((feature) => feature.app === this.app && feature.level === this.level && feature.downgrade);
  }

  @computed('app', 'level')
  get downgradeRestrictions(): typeof DOWNGRADE_RESTRICTIONS {
    return DOWNGRADE_RESTRICTIONS.filter(
      (restriction) => restriction.app === this.app && restriction.level === this.level,
    );
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'subscription-plan': SubscriptionPlanModel;
  }
}
