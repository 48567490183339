import Component from '@glimmer/component';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';

export default class CustomizeBranding extends Component {
  @service state;
  @service visitorsOnboarding;

  @alias('state.currentLocation') currentLocation;
  @alias('visitorsOnboarding.subscription.canAccessSlideshows') canAccessSlideshows;
  @alias('visitorsOnboarding.touchlessWalkinEnabled') touchlessWalkinEnabled;
  @alias('visitorsOnboarding.hasLogoOrAccentColor') hasLogoOrAccentColor;
  @alias('visitorsOnboarding.hasDesign') hasDesign;
  @alias('visitorsOnboarding.s3Url') s3Url;

  constructor() {
    super(...arguments);
    this.visitorsOnboarding.loadLocationsTask.perform();
  }
}
