import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type DeskLocationModel from './desk-location';

@adapter('rms')
class WorkplaceDayModel extends Model {
  @belongsTo('desk-location', { async: true }) declare deskLocation: AsyncBelongsTo<DeskLocationModel>;

  @attr('string') declare dayOfWeek: string;
  @attr('string') declare startTime: string;
  @attr('string') declare endTime: string;
  @attr('boolean') declare active: boolean;
}

export default WorkplaceDayModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'workplace-day': WorkplaceDayModel;
  }
}
