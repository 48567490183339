import ArrayProxy from '@ember/array/proxy';
import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import type Store from '@ember-data/store';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type WorkplaceDayModel from 'garaje/models/workplace-day';
import type ApolloService from 'garaje/services/apollo-extension';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LiveMapService from 'garaje/services/live-map';
import type MapVersionsService from 'garaje/services/map-versions';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';

import type MapsLiveShowController from './controller';

interface ModelParams {
  mapFeatures: ArrayProxy<MapFeatureModel>;
}

export interface ModelResponse {
  areaMap: AreaMapModel;
  mapFloor: MapFloorModel | null;
  mapFloors: ArrayProxy<MapFloorModel>;
  mapFeatures: ArrayProxy<MapFeatureModel>;
  desksInLocation: ArrayProxy<DeskModel>;
  workplaceDays: WorkplaceDayModel[];
}

interface MapDataResponse extends Omit<ModelResponse, 'workplaceDays'> {
  workplaceDays?: WorkplaceDayModel[];
}
interface AfterModelParams {
  mapFloors: ArrayProxy<MapFloorModel>;
  mapFloor: MapFloorModel;
}

export default class MapsLiveShowRoute extends Route {
  @service declare store: Store;
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;
  @service declare apollo: ApolloService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare mapVersions: MapVersionsService;
  @service declare liveMap: LiveMapService;

  queryParams = {
    selectedTime: {
      refreshModel: false,
    },
    selectedResourceFilter: {
      refreshModel: false,
    },
    selectedEmployeeId: {
      refreshModel: false,
    },
  };

  async beforeModel(): Promise<void> {
    if (this.featureFlags.isEnabled('snapshot-for-live-map')) {
      if (this.abilities.cannot('visit-live-maps maps')) {
        return void this.router.transitionTo('unauthorized');
      }

      return void this.metrics.trackEvent('Accessed live map');
    }

    // If we are loading the map via snapshot, we will need to query for map floors to check if there is an image
    const mapFloors = await this.store.query('map-floor', { filter: { location: this.state.currentLocation?.id } });
    const hasMapFloorWithRasterImage = isPresent(
      mapFloors.filter((floor) => {
        return floor.rasterImageUrl;
      }),
    );

    if (!hasMapFloorWithRasterImage && this.abilities.cannot('visit-live-maps maps')) {
      return void this.router.transitionTo('unauthorized');
    }

    return void this.metrics.trackEvent('Accessed live map');
  }

  async fetchWorkplaceDays(): Promise<WorkplaceDayModel[]> {
    let workplaceDays: WorkplaceDayModel[] = [];
    if (this.state.currentLocationEnabledDesks?.id) {
      await this.store.findRecord('desk-location', this.state.currentLocationEnabledDesks.id, {
        reload: true,
        include: 'workplace-days',
      });

      workplaceDays = this.store.peekAll('workplaceDay').filter((day: WorkplaceDayModel) => {
        return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks?.id;
      }) as WorkplaceDayModel[];
    }
    return workplaceDays;
  }

  // This is the original way we were fetching the map features and desks which will eventually be replaced by the snapshot method
  async getMapDataScatterGather(floorId: string): Promise<MapDataResponse> {
    const { areaMap } = this.modelFor('spaces.maps') as { areaMap: AreaMapModel };

    const mapFloors: ArrayProxy<MapFloorModel> = areaMap.mapFloors;
    const mapFloor = this.store.peekRecord('map-floor', floorId) as MapFloorModel;

    const mapFeatures = await this.store.query('map-feature', {
      filter: {
        floor: floorId,
      },
    });

    const { currentLocation } = this.state;
    const desksInLocation = await this.store.query('desk', {
      filter: {
        'location-id': currentLocation?.id,
        booked: true,
        permanentlyAssigned: true,
      },
    });

    return {
      areaMap,
      mapFloors,
      mapFloor,
      mapFeatures,
      desksInLocation,
    };
  }

  // This is the new way we are loading and fetching the static map data from the live-map endpoint
  async getMapDataFromSnapshot(floorId: string): Promise<MapDataResponse> {
    const { areaMap } = this.modelFor('spaces.maps') as { areaMap: AreaMapModel };

    await this.liveMap.getUpdatedLiveMapData();

    const mapFloors = this.store
      .peekAll('map-floor')
      .filter((mapFloor) => mapFloor.belongsTo('areaMap').id() === areaMap.id);
    const mapFloor = mapFloors.find((floor) => floor.id === floorId) as MapFloorModel;
    // Extract map features once we've loaded the store with the snapshot data
    const mapFeatures = this.store.peekAll('map-feature');
    const mapFeaturesForFloor = mapFeatures.filter((mapFeature) => mapFeature.belongsTo('mapFloor').id() === floorId);
    const normalizedMapFeaturesForFloor = ArrayProxy.create({
      content: mapFeaturesForFloor,
    }) as ArrayProxy<MapFeatureModel>;

    // Extract desks
    const desks = this.store.peekAll('desk');
    const normalizedDesks = ArrayProxy.create({ content: desks }) as ArrayProxy<DeskModel>;

    const normalizedMapFloors = ArrayProxy.create({ content: mapFloors }) as ArrayProxy<MapFloorModel>;

    // todo: load generic resources from the store and return them in the model?

    return {
      areaMap,
      mapFloors: normalizedMapFloors,
      mapFloor,
      mapFeatures: normalizedMapFeaturesForFloor,
      desksInLocation: normalizedDesks,
    };
  }

  async model({ floor_id }: { floor_id: string }): Promise<ModelResponse> {
    const modelResponse = this.featureFlags.isEnabled('snapshot-for-live-map')
      ? await this.getMapDataFromSnapshot(floor_id)
      : await this.getMapDataScatterGather(floor_id);

    const workplaceDays = await this.fetchWorkplaceDays();

    return {
      ...modelResponse,
      workplaceDays,
    };
  }

  afterModel({ mapFloors, mapFloor }: AfterModelParams): void {
    const hasFloorInLocation = mapFloors.any((floor: MapFloorModel) => floor.id === mapFloor?.id);

    if (!hasFloorInLocation) {
      void this.router.transitionTo('spaces.maps.live.show', mapFloors.firstObject?.id || '');
    }

    if (!mapFloor.rasterImageUrl) {
      const floorWithUrl = mapFloors.find((floor) => floor.rasterImageUrl);

      if (floorWithUrl) {
        return void this.router.transitionTo('spaces.maps.live.show', floorWithUrl.id);
      }

      return void this.router.transitionTo('spaces.maps');
    }
  }

  setupController(controller: MapsLiveShowController, model: ModelParams, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.mapFeatures = model.mapFeatures.toArray();
    void controller.loadResoucesDataTask.perform();
    controller.setSelectedDesk();
  }
}
