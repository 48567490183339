import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

import { type NewTenantRouteModel } from './route';

export default class PropertySettingsTenantsNewController extends Controller {
  @service declare router: RouterService;

  declare model: NewTenantRouteModel;

  @action
  onClose(): void {
    this.router.transitionTo('property.settings.tenants');
  }

  @action
  onSave(): void {
    this.model.loadTenantsTask.perform(this.model.property).catch(throwUnlessTaskDidCancel);
  }
}
