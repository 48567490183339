import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type CurrentAdminService from 'garaje/services/current-admin';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import { AnalyticsEventNames } from 'garaje/utils/enums';

import type AnalyticsGlobalScheduledReportsV2Controller from './controller';

export default class AnalyticsGlobalSigmaReportsRoute extends Route {
  @service declare currentAdmin: CurrentAdminService;
  @service declare metrics: MetricsService;
  @service declare state: StateService;

  afterModel(): void {
    this.logMetrics();
  }

  logMetrics(): void {
    const {
      currentCompany: { id: companyId },
      visitorsSubscription,
      workplaceSubscription,
    } = this.state;

    const details = {
      companyId,
      app: visitorsSubscription?.app || workplaceSubscription?.app,
      plan: visitorsSubscription?.plan || workplaceSubscription?.plan,
      userId: this.currentAdmin?.id || '',
      employeeId: this.currentAdmin?.employee?.id || '',
    };
    this.metrics.trackEvent(AnalyticsEventNames.SCHEDULED_REPORTS_V2_GLOBAL_VISITED, details);
    this.metrics.logMonitorEvent(AnalyticsEventNames.SCHEDULED_REPORTS_V2_GLOBAL_VISITED, details);
  }

  setupController(controller: AnalyticsGlobalScheduledReportsV2Controller, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);
    controller.showModal = false;
  }
}
