import { belongsTo, hasMany } from 'ember-data/relationships';
import AbstractSignInFieldModel from 'garaje/models/abstract/abstract-sign-in-field';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('sign-in-field')
class GlobalSignInFieldModel extends AbstractSignInFieldModel {
  @belongsTo('global-sign-in-field-page', { async: true, inverse: 'signInFields' }) signInFieldPage;
  @hasMany('global-sign-in-field', { async: true }) actionableSignInFields;
  @hasMany('global-sign-in-field-action', { async: true }) signInFieldActions;
  @hasMany('global-sign-in-field-action', { async: true, inverse: 'actionableSignInField' })
  actionableSignInFieldActions;
}

export default GlobalSignInFieldModel;
