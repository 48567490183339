import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LocationOverviewAnalyticsUpsellRoute extends Route {
  @service abilities;
  @service router;

  buildRouteInfoMetadata() {
    return { hideBanners: true };
  }

  redirect() {
    if (this.abilities.cannot('visit billing')) {
      this.router.transitionTo('location-overview.analytics');
    }
  }
}
