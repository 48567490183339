import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type FlowModel from 'garaje/models/flow';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('visual-compliance-configuration')
class GlobalVisualComplianceConfigurationModel extends Model {
  @belongsTo('global-flow', { async: true }) declare flow: AsyncBelongsTo<FlowModel>;

  // location attrs
  // For contactIds, BE accepts number[] | string[] but returns number[].
  // If BE updated to provide string IDs, remove number[] type.
  @attr('array', { defaultValue: () => [] }) declare contactIds: number[] | string[];
  @attr('string') declare secno: string;
  @attr('string') declare password: string;
  @attr('boolean', { defaultValue: true }) declare credentialsInvalid: boolean;
  @attr('boolean', { defaultValue: false }) declare reviewOnMatch: boolean;

  // flow attrs
  @attr('array', { defaultValue: () => [] }) declare fieldsToCheck: string[];
}

export default GlobalVisualComplianceConfigurationModel;

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'global-visual-compliance-configuration': GlobalVisualComplianceConfigurationModel;
  }
}
