// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed, get } from '@ember/object';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import Model, { type AsyncHasMany, attr, belongsTo, hasMany, type AsyncBelongsTo } from '@ember-data/model';
import type Store from '@ember-data/store';
import { apiAction } from '@mainmatter/ember-api-actions';
import type CompanyModel from 'garaje/models/company';
import type EmployeeRegistrationConfigurationModel from 'garaje/models/employee-registration-configuration';
import type EmployeeScreeningFlowModel from 'garaje/models/employee-screening-flow';
import type FlowModel from 'garaje/models/flow';
import type GlobalFlowModel from 'garaje/models/global-flow';
import type VfdConfigurationModel from 'garaje/models/vfd-configuration';
import type ZoneModel from 'garaje/models/zone';

export default class SkinnyLocationModel extends Model {
  @service declare store: Store;

  @belongsTo('company', { async: true }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('zone', { async: false }) declare property: ZoneModel;
  @belongsTo('vfd-configuration', { async: true }) declare vfdConfiguration: AsyncBelongsTo<VfdConfigurationModel>;

  @hasMany('group', { async: true }) declare groups: AsyncHasMany<Model>;
  @hasMany('flow', { async: true }) declare flows: AsyncHasMany<FlowModel>;

  @attr('boolean', { defaultValue: false }) declare disabled: boolean;
  @attr('date', { defaultValue: null }) declare disabledToEmployeesAt: Date;
  @attr('string') declare name: string;
  @attr('string') declare companyNameOverride: string;
  @attr('string') declare latitude: string;
  @attr('string') declare longitude: string;
  @attr('string') declare locale: string;
  @attr('string') declare timezone: string;

  @attr('array', { defaultValue: () => [] }) declare enabledLocales: string[];
  @attr('array', { defaultValue: () => [] }) declare userDocumentRequirements: unknown[];

  @computed('groups.[]')
  get group(): Model | undefined {
    return this.groups.firstObject;
  }

  get totalFlows(): number {
    return (<SkinnyLocationModel>this).hasMany('flows').ids().length;
  }

  @computed('companyNameOverride', 'company.name', 'name')
  get nameWithCompanyName(): string {
    // eslint-disable-next-line ember/no-get
    const companyName = this.companyNameOverride || <string>get(this.company, 'name');
    return isBlank(companyName) ? this.name : `${companyName} ${this.name}`;
  }

  async realLocation(): Promise<Model> {
    const realLocation = <Model>this.store.peekRecord('location', this.id);
    if (realLocation) {
      return realLocation;
    }
    return this.store.findRecord('location', this.id);
  }

  @belongsTo('employee-screening-flow', { inverse: 'locations' })
  declare employeeScreeningFlow: AsyncBelongsTo<EmployeeScreeningFlowModel>;

  @belongsTo('employee-registration-configuration', { inverse: 'location', async: false })
  declare employeeRegistrationConfiguration: AsyncBelongsTo<EmployeeRegistrationConfigurationModel>;

  @hasMany('global-flow')
  declare globalFlows: AsyncHasMany<GlobalFlowModel>;

  async disableLocation(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'disable-to-employees' });
  }

  async enableLocation(): Promise<unknown> {
    return await apiAction(this, { method: 'POST', path: 'enable-to-employees' });
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'skinny-location': SkinnyLocationModel;
  }
}
