import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import type StatsigService from 'garaje/services/statsig';

import type { KioskDemoPage } from '../../provider/component';

export default class FinalScreen extends Component<KioskDemoPage> {
  @service declare router: RouterService;
  @service declare statsig: StatsigService;

  @action async onPairClick(): Promise<void> {
    if (this.args.isUserLoggedIn && !this.args.isDemoInstance) {
      this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-pair-ipad-clicked`);
    }
    await this.router.transitionTo('visitors.devices.ipads').catch();
  }

  @action async onQRClick(): Promise<void> {
    if (this.args.isUserLoggedIn && !this.args.isDemoInstance) {
      this.statsig.logEvent(`kiosk-demo-${this.args.demoName}-qr-code-clicked`);
    }
    await this.router.transitionTo('visitors.settings.welcome-screen.touchless-walkins').catch();
  }
}
