import Controller from '@ember/controller';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { reads, equal } from 'macro-decorators';

export default class IntegrationsIndexController extends Controller {
  @service state;
  @service router;
  @service featureFlags;
  @service integrationsManager;
  @service flashMessages;
  @service store;

  @reads('state.currentLocation') currentLocation;
  @reads('state.currentCompany') currentCompany;
  @reads('currentCompany.saml.content') samlIntegration;
  @reads('currentCompany.googleApp.content') googleIntegration;
  @reads('currentCompany.oneLogin.content') oneLoginIntegration;
  @reads('currentCompany.oktaIntegration.content') oktaIntegration;
  @equal('currentCompany.scimIntegration.provider', 'onelogin') oneLoginSCIM;
  @equal('currentCompany.scimIntegration.provider', 'okta') oktaSCIM;
  @equal('currentCompany.scimIntegration.provider', 'azure') entraIdSCIM; // microsoft renamed azure to entra id
  @equal('currentCompany.scimIntegration.provider', 'centrify') delineaSCIM; // centrify renamed to delinea
  @reads('currentCompany.scimIntegration.content') scimIntegration;
  @reads('state.features.canAccessDirectorySync') canAccessDirectorySync;
  @reads('state.features.canAccessSaml') canAccessSaml;

  get hasScimIntegration() {
    return this.featureFlags.isEnabled('scimIntegration', {
      created_at: get(this.currentCompany, 'createdAt'),
    });
  }

  // this is to return the Active Directory plugin install if it exists
  get activeSyncPluginInstalls() {
    // find plugins in the sync category
    const syncPlugins = get(this.model, 'plugins').filterBy('category', 'sync');
    const pluginInstalls = get(this.model, 'pluginInstalls');

    // find active installs that belong to any plugin in the sync category
    const syncPluginInstalls = pluginInstalls.filter((install) => {
      return syncPlugins.findBy('id', get(install, 'plugin.id')) !== undefined;
    });

    return syncPluginInstalls.filter(function (install) {
      const status = get(install, 'status');
      return status === 'active' || status === 'pending';
    });
  }

  get currentSyncIntegration() {
    let currentIntegration = false;
    const activeSyncPluginInstalls = this.activeSyncPluginInstalls;

    if (this.googleIntegration) {
      currentIntegration = 'Google';
    }

    if (this.oneLoginIntegration || this.oneLoginSCIM) {
      currentIntegration = 'OneLogin';
    }

    if (this.oktaIntegration || this.oktaSCIM) {
      currentIntegration = 'Okta';
    }

    if (this.entraIdSCIM) {
      currentIntegration = 'Entra ID';
    }

    if (this.delineaSCIM) {
      currentIntegration = 'Delinea';
    }

    // This would say e.g. 'OKTA SCIM' if you installed the SCIM
    // version and then lost priviliges for SCIM.
    const featureFlags = this.featureFlags;
    if (!get(featureFlags, 'scimIntegration')) {
      if (this.scimIntegration) {
        currentIntegration += ' SCIM';
      }
    }

    if (activeSyncPluginInstalls.length) {
      currentIntegration = get(activeSyncPluginInstalls, 'firstObject.plugin.name');
    }

    return currentIntegration;
  }

  get hasSync() {
    return this.currentSyncIntegration !== false;
  }

  @action
  goToPluginInstallConfig(integration) {
    this.router.transitionTo('employees.scim-settings', { queryParams: { integration } });
  }

  installGoogleAppsTask = task(async () => {
    const { currentCompany } = this.state;
    const integrationsManager = this.integrationsManager;
    // Clean any existing error this.store.unloadAll('google-app');
    this.store.unloadAll('google-app');

    try {
      await integrationsManager.connectGoogleApps(currentCompany);
      currentCompany.reload();
      this.router.transitionTo('employees.scim-settings');
    } catch (response) {
      let message = "Sorry, we couldn't connect your account.";
      if (response?.errors) {
        const [error] = response.errors;
        if (error) {
          message = error.messages.join(', ');
        }
      }
      this.flashMessages.showAndHideFlash('error', message);
    }
  });

  installOneLoginTask = task(async () => {
    const { currentCompany } = this.state;
    this.store.createRecord('one-login', { company: currentCompany });
    await this.router.transitionTo('employees.scim-settings', {
      queryParams: { from: 'employees.integrations', integration: 'one-login' },
    });
  });

  installOktaTask = task(async () => {
    const { currentCompany } = this.state;
    this.store.createRecord('okta-integration', { company: currentCompany });
    await this.router.transitionTo('employees.scim-settings', {
      queryParams: { from: 'employees.integrations', integration: 'okta' },
    });
  });

  installScimTask = task(async (provider) => {
    const { currentCompany } = this.state;
    this.store.createRecord('scim-integration', { company: currentCompany, provider }).save();
    await this.router.transitionTo('employees.scim-settings', {
      queryParams: { from: 'employees.integrations', integration: 'scim' },
    });
  });

  installSamlTask = task(async () => {
    const { currentCompany } = this.state;
    this.store.createRecord('saml', { company: currentCompany });
    await this.router.transitionTo('employees.scim-settings', {
      queryParams: { from: 'employees.integrations', integration: 'saml' },
    });
  });
}
