import { A } from '@ember/array';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { endOfDay, formatISO, startOfDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { restartableTask, type TaskInstance } from 'ember-concurrency';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import type AccessEventModel from 'garaje/models/access-event';
import type { Field } from 'garaje/models/location';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';
import type WorkplaceMetricsService from 'garaje/services/workplace-metrics';
import { DATE_FNS_YYYY_MM_DD, modifyDateInTimeZone, parseYyyyMmDdInTimeZone } from 'garaje/utils/date-fns-tz-utilities';
import { reads } from 'macro-decorators';
import { resolve } from 'rsvp';

const HEADER_ENUMS = {
  name: 'Name',
  autoSignInStatus: 'Auto sign-in status',
  entranceName: 'Entrance Name',
  integrationName: 'Integration Name',
  eventTimeHeader: 'Event Time',
  date: 'Date',
  eventType: 'Event Type',
};

interface AccessEventTableArgs {
  query: string;
  startDate: string;
  endDate: string;
  date: string;
  setTotalAccessEvents: (count: number) => void;
  reset: boolean;
  initializeReset: () => void;
}

interface AccessEventQueryParams {
  'location-ids': string;
  'start-time'?: string;
  'end-time'?: string;
  query?: string;
  employee?: string;
}

interface SortableField extends Field {
  sort?: string;
}

type AccessEventPage = PaginatedRecordArray<AccessEventModel> & { meta: { 'total-count': number } };

const limit = 25;

export default class AccessEventTable extends Component<AccessEventTableArgs> {
  @service declare workplaceMetrics: WorkplaceMetricsService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @tracked accessEvents: AccessEventModel[] = [];
  @tracked sort: string = '-message-timestamp';
  page = 0;
  totalAccessEvents!: number;

  @reads('currentLocation.timezone', 'America/Los_Angeles') timezone!: string;

  primaryHeaders = [
    { name: HEADER_ENUMS.name, componentName: 'custom-column', sort: 'person-name' },
    { name: HEADER_ENUMS.entranceName, componentName: 'custom-column' },
    { name: HEADER_ENUMS.integrationName, componentName: 'custom-column' },
    { name: HEADER_ENUMS.eventTimeHeader, componentName: 'custom-column', sort: 'message-timestamp' },
    { name: HEADER_ENUMS.eventType, componentName: 'custom-column' },
  ];

  get dateWithDefault(): string {
    return this.args.date || formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get startDateWithDefault(): Date {
    if (this.args.startDate) return new Date(this.args.startDate);

    const { dateWithDefault } = this;

    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const start = modifyDateInTimeZone(date, this.timezone, startOfDay);

    return start;
  }

  get endDateWithDefault(): Date {
    if (this.args.endDate) return new Date(this.args.endDate);

    const { dateWithDefault } = this;
    const date = parseYyyyMmDdInTimeZone(dateWithDefault, new Date(), this.timezone);
    const end = modifyDateInTimeZone(date, this.timezone, endOfDay);

    return end;
  }

  accessEventParams(pageNumber: number): Record<string, unknown> {
    const { currentLocation } = this.state;
    const offset = (pageNumber - 1) * limit;
    const filter: AccessEventQueryParams = {
      'location-ids': currentLocation.id,
    };

    const { query } = this.args;

    if (isPresent(query)) {
      filter.query = query;
    }

    filter['start-time'] = formatISO(this.startDateWithDefault);
    filter['end-time'] = formatISO(this.endDateWithDefault);

    return {
      filter,
      page: { limit: limit, offset },
      sort: this.sort,
    };
  }

  get accessEventDashboardFields(): Field[] {
    const { currentLocation } = this.state;
    const dashboardFields = currentLocation.dashboardFields;
    return [...dashboardFields];
  }

  get hasMorePages(): boolean {
    return this.accessEvents.length < this.totalAccessEvents;
  }

  get fieldOptions(): Field[] {
    let headers: SortableField[] = [...this.primaryHeaders];
    headers = headers.map(({ name, componentName, sort }) => {
      return {
        name,
        componentName,
        // always show name
        show:
          name === 'Name' || name === 'Event Time'
            ? true
            : isPresent(A(this.accessEventDashboardFields).findBy('name', name)),
        disabled: name === 'Name' || name === 'Event Time' ? true : false,
        sort,
      };
    });
    return headers;
  }

  get todayAsString(): string {
    return formatInTimeZone(new Date(), this.timezone, DATE_FNS_YYYY_MM_DD);
  }

  get isToday(): boolean {
    const { dateWithDefault, startDateWithDefault, endDateWithDefault, todayAsString } = this;

    if (dateWithDefault !== todayAsString) return false;
    if (formatInTimeZone(startDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;
    if (formatInTimeZone(endDateWithDefault, this.timezone, DATE_FNS_YYYY_MM_DD) !== todayAsString) return false;

    return true;
  }

  _loadMore(): void | Promise<void> | TaskInstance<void> {
    // Because of the way loadMore works -- there are scenarios where
    // this can be called if destroyed
    if (this.isDestroyed) {
      return;
    }
    if (this.hasMorePages) {
      return this.loadAccessEvents.perform();
    } else {
      return resolve();
    }
  }

  @action
  loadMore(): void | Promise<void> | TaskInstance<void> {
    if (this.loadAccessEvents.isRunning) {
      return;
    }
    return this._loadMore();
  }

  resetTable(): void {
    this.page = 0;
    this.accessEvents = [];
  }

  @action
  reset(): void {
    if (this.args.reset) {
      this.resetTable();
    }
  }

  get sortField(): string {
    return this.sort.replace(/^[-|+]/g, '');
  }

  get sortDirection(): string {
    return this.sort.startsWith('-') ? 'desc' : 'asc';
  }

  @action
  sortAccessEvents(field: string, direction: string): void {
    // set sort
    const dir = direction === 'asc' ? '' : '-';
    this.sort = `${dir}${field}`;
    this.metrics.trackEvent('Access Events Sorted', { sort_field_name: field });

    // reset the page before refetching sorted data
    this.resetTable();
    void this.loadAccessEvents.perform();
  }

  loadAccessEvents = restartableTask(async () => {
    this.workplaceMetrics.trackEvent('ACCESS_EVENT_LOG_LOADING_ACCESS_EVENTS');
    try {
      if (this.page == 0 || this.args.reset) {
        this.resetTable();
      }
      const nextPage = this.page + 1;
      const accessEventParams = this.accessEventParams(nextPage);

      // fetch the page of access events
      const accessEvents = <AccessEventPage>await this.store.query('access-event', accessEventParams);

      // set page to be ready for next fetch when needed
      this.page = nextPage;
      if (this.args.reset) {
        this.args.initializeReset();
      }

      // append the just-fetched access events to the existing array
      this.accessEvents = [...this.accessEvents, ...accessEvents.toArray()];

      // fetch the total for display and also to know when to stop paginating
      this.totalAccessEvents = accessEvents.meta?.['total-count'] ?? 0;
      this.args.setTotalAccessEvents(this.totalAccessEvents);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      // TODO: handle error state here. try...catch is quite big.
      this.page = 0;
    }
  });
}
