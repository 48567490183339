import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type EmployeeModel from 'garaje/models/employee';

class RecipientAliasModel extends Model {
  @belongsTo('employee', { async: true }) declare recipientEmployee: AsyncBelongsTo<EmployeeModel>;

  @attr('string') declare name: string;
  @attr('number') declare locationId: number;
}

export default RecipientAliasModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'recipient-alias': RecipientAliasModel;
  }
}
