import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { scheduleOnce } from '@ember/runloop';
import iframeWriteDoc from 'garaje/utils/iframe-write-doc';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

/**
 * @param {Model<Location>}           location
 */
export default class VisitorEmailsWelcomeEmailWePreviewing extends Component {
  @service flashMessages;

  @tracked previewHTML;
  @tracked subject;

  constructor() {
    super(...arguments);
    this.loadWelcomeEmailPreviewHTMLTask.perform();
  }

  @task
  *loadWelcomeEmailPreviewHTMLTask() {
    try {
      const {
        data: {
          attributes: { body, subject },
        },
      } = yield this.args.location.getWelcomeEmailPreview();
      this.previewHTML = body;
      this.subject = subject;
      scheduleOnce('afterRender', this, iframeWriteDoc, 'email-iframe', this.previewHTML);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
