import Model, { attr, belongsTo } from '@ember-data/model';
import type { AsyncBelongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

import type UserDocument from './user-document';

export default class UserDocumentLocationContext extends Model {
  @belongsTo('user-document', { async: true }) declare userDocument: AsyncBelongsTo<UserDocument>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  @attr('string') declare approvalStatus: string;
  @attr('string') declare expirationDate: string; // A date in string format ("YYYY-MM-DD")
  @attr('boolean') declare expired: boolean;
}
