import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type SigmaUrlService from 'garaje/services/sigma-url';
import type StateService from 'garaje/services/state';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';
import { AnalyticsEventNames } from 'garaje/utils/enums';

export default class GlobalAnalyticsSpaceIndexController extends Controller {
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare sigmaUrl: SigmaUrlService;
  @service declare userFeatureFlags: UserFeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare currentAdmin: CurrentAdminService;

  @tracked isError = false;

  fetchSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const result = await this.sigmaUrl.getGlobalSigmaUrl(companyId, 'space_index');
      return result;
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', 'Error loading dashboard');
      this.isError = true;
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });

  get sigmaIframeUrl(): string | null | undefined {
    return this.fetchSigmaUrlTask.last?.value;
  }

  logSigmaUrlTask = task({ drop: true }, async () => {
    await this.state.initializeState.last;
    const companyId = this.state?.currentCompany?.id;
    try {
      const details = {
        companyId,
        app: this.state.workplaceSubscription?.app,
        plan: this.state?.workplaceSubscription?.plan,
        userId: this.currentAdmin?.id || '',
        employeeId: this.currentAdmin.employee?.id || '',
      };
      this.metrics.trackEvent(AnalyticsEventNames.SPACE_INDEX_GLOBAL_DASHBOARD_VISITED, details);
      this.metrics.logMonitorEvent(AnalyticsEventNames.SPACE_INDEX_GLOBAL_DASHBOARD_VISITED, details);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return;
    }
  });
}
