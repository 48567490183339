import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class VisitorsSettingsVisitorTypesFlowLegalDocumentsEditRoute extends Route {
  @service transitionConfirm;
  @service store;

  model({ agreement_id }) {
    const { agreements, flow, signInFields, vrSubscription } = this.modelFor(
      'visitors.settings.visitor-types.flow.legal-documents',
    );
    const agreement = this.store.findRecord('agreement', agreement_id);
    const { ipads } = this.modelFor('visitors.settings');
    const { tracking } = this.modelFor('visitors.settings.visitor-types.flow.legal-documents');

    return hash({
      agreement,
      /** Sometimes when we transition from the legal-documents.edit route
       * after deleting a record via the transition-confirm dialog, the transition occurs
       * before the agreement deletion has persisted for some reason. We filter out
       * any agreements here that may have been marked for deletion but not yet persisted
       * at the time that we enter this route.
       */
      agreements: agreements.filterBy('isDeleted', false),
      flow,
      ipads,
      signInFields,
      tracking,
      vrSubscription,
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupChangeset();
  }

  @routeEvent
  routeWillChange(transition) {
    const agreementChangeset = this.controller.changeset;
    const { agreement } = this.controller.model;
    const confirmTask = this.transitionConfirm.displayConfirmTask;

    if (agreement.isDeleted) {
      /**
       * This confirmTask is for confirming whether the user wants to delete the agreement before proceeding
       * If isDeleted and hasDirtyAttributes are both true, the agreement has been marked for deletion locally
       * If isSaving is false, we assume that the agreement hasn't yet been persisted and allow the user
       * to continue or cancel
       * continue: save the agreement and persist the deletion
       * cancel: undo the deletion by rolling back changes
       * We'll re-enter this willTransition action during the isSaving event and let that pass through
       * https://api.emberjs.com/ember-data/3.8/classes/DS.Model/properties?anchor=isDeleted
       */
      if (!agreement.isSaving) {
        confirmTask.perform(transition, {
          header: 'Delete document?',
          body: 'Are you sure? This action cannot be undone.',
          confirmText: 'Delete',
          cancel() {
            agreement.rollbackAttributes();
          },
          continue() {
            agreement.save();
          },
        });
      }
      // This confirmTask is for confirming whether the user wants to discard unsaved changes
      // when transitioning away from the edit form.
    } else if (agreementChangeset.isDirty) {
      confirmTask.perform(transition, {
        continue() {
          agreementChangeset.rollback();
        },
      });
    }
  }
}
