import { A } from '@ember/array';
import { assert } from '@ember/debug';
import Service from '@ember/service';
import { task, all } from 'ember-concurrency';
import type { ParseResult } from 'papaparse';
import Papa from 'papaparse';

/**
 * TODO: this is bad, and needs fixin
 * We need to implement proper validation for these columns.
 */

function transformHeader(columnString: string) {
  const words = columnString.trim().split(' ');
  if (words.length === 1) {
    if (columnString.toLowerCase() === 'id') {
      return 'id';
    }

    return columnString.trim().replace(/^\w/, (c) => c.toLowerCase());
  }

  return words
    .map((h) => h.toLowerCase())
    .map((h) => h.replace(/^\w/, (c) => c.toUpperCase()))
    .join('')
    .replace(/^\w/, (c) => c.toLowerCase());
}

export default class ImporterService extends Service {
  importRecordsFromCsvFile = task(async (file: File) => {
    const fileContents = await this._readFileContent(file);
    const csvData = await this._parseCsvFile(fileContents);
    const records = A(csvData.data).map((data) => this.handleImport.perform(data));
    return await all(records);
  });

  handleImport = task(async (data: unknown): Promise<unknown> => {
    // eslint-disable-next-line @typescript-eslint/await-thenable
    await assert('Classes derived from ImporterService should implement a handleImport(data) task', data);
    return data;
  });

  convertDelimitedStringToArray(delimitedString: string): string[] | string {
    if (!delimitedString) {
      return '';
    }
    delimitedString = delimitedString.toString();
    return delimitedString
      .split(',')
      .map((item) => item.trim())
      .filter((result) => result);
  }

  _parseCsvFile(fileContents: FileReader['result']): Promise<ParseResult<unknown>> {
    return new Promise((resolve, reject) => {
      Papa.parse(<string>fileContents, {
        header: true,
        dynamicTyping: false,
        skipEmptyLines: true,
        transformHeader: (columnString) => transformHeader(columnString),
        complete: (results) => resolve(results),
        error: (error: Error) => reject(error),
      });
    });
  }

  _readFileContent(file: File): Promise<FileReader['result']> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target!.result);
      reader.onerror = (error) => reject(<Error>(<unknown>error));
      reader.readAsText(file);
    });
  }
}
