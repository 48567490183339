import { formatISO } from 'date-fns';

/**
 *
 * @param str the string to test
 * @returns whether the string is an ISO string
 */
export default function isIsoString(str: string): boolean {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z?/.test(str)) return false;
  return formatISO(new Date(str)) === str;
}
