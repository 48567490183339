import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';

import type AnnouncementModel from './announcement';
import type AnnouncementRecipientChannelModel from './announcement-recipient-channel';
import type EmployeeModel from './employee';

export default class AnnouncementRecipientModel extends Model {
  @belongsTo('announcement', { async: true }) declare announcement: AsyncBelongsTo<AnnouncementModel>;
  @belongsTo('employee', { async: true }) declare employee: AsyncBelongsTo<EmployeeModel>;

  @hasMany('announcement-recipient-channel', { async: true })
  declare announcementRecipientChannels: AsyncHasMany<AnnouncementRecipientChannelModel>;

  // attribute
  @attr('string') declare name: string;
  @attr('string') declare phoneNumber?: string;
  @attr('string') declare employeeId: string;
  @attr('string') declare visitorEmail?: string;
  @attr('string') declare status: string;
  @attr('boolean') declare smsSent: boolean;
  @attr('boolean') declare emailSent: boolean;
  @attr('boolean') declare pushSent: boolean;
  @attr('boolean') declare slackSent: boolean;
  @attr('boolean') declare msTeamsSent: boolean;
  @attr('boolean') declare markedAsSafe: boolean;
  @attr('date') declare markedAsSafeAt?: Date;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'announcement-recipient': AnnouncementRecipientModel;
  }
}
