import Model, { attr, belongsTo, hasMany, type AsyncBelongsTo, type AsyncHasMany } from '@ember-data/model';
import type EmployeeGroupModel from 'garaje/models/employee-group';
import type LocationModel from 'garaje/models/location';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('v3-coalesce')
class EmployeeGroupRoleModel extends Model {
  @hasMany('location', { async: true }) declare locations: AsyncHasMany<LocationModel>;
  @belongsTo('employee-group', { async: true }) declare employeeGroup: AsyncBelongsTo<EmployeeGroupModel>;

  @attr('string') declare roleName: string;
  @attr('string') declare customRoleId: string;
}

export default EmployeeGroupRoleModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'employee-group-role': EmployeeGroupRoleModel;
  }
}
