import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type EmployeeModel from 'garaje/models/employee';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type StateService from 'garaje/services/state';
import type L from 'leaflet';
import { all } from 'rsvp';

import type { RouteModel } from './route';

export default class VersionsController extends Controller {
  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare store: Store;
  @service declare router: RouterService;

  @tracked declare model: RouteModel;

  @tracked selectedNeighborhoods: NeighborhoodModel[] = [];
  @tracked employees: EmployeeModel[] = [];
  @tracked leafletMap?: L.Map;
  @tracked employeesPage = 0;
  @tracked employeesCount = 0;
  @tracked selectedFeature: MapFeatureModel | undefined;
  @tracked isLoading?: boolean;

  get sortedMapFloors(): Array<MapFloorModel | string> {
    return this.model.areaMap.mapFloors.sortBy('ordinality');
  }

  get additionalDetails(): object {
    const { neighborhoods, desks } = this.model;

    return {
      amenities: [],
      assignedDesks: desks.filter((desk) => desk.assignedTo),
      desks,
      employees: this.employees,
      neighborhoods,
      selectedNeighborhoods: this.selectedNeighborhoods,
    };
  }

  loadEmployeesTask = task({ restartable: true }, async () => {
    const employeesPromises = [];
    let employeeResponses = [];
    const emailLimit = 50;
    const assignedToEmails = this.model.desks.filter((desk) => desk.assignedTo).map((desk) => desk.assignedTo);

    let offset = 0;
    let page = 0;

    while (offset < assignedToEmails.length) {
      const emailsPaginated = assignedToEmails.slice(offset, offset + emailLimit);

      const fetchedEmployeesPromise = this.store.query('employee', {
        filter: {
          locations: this.state.currentLocation?.id,
          'email-in': emailsPaginated.join(','),
          deleted: false,
        },
        include: 'user',
        page: { limit: 100, offset: 0 },
      });

      page++;
      offset = page * emailLimit;

      employeesPromises.push(fetchedEmployeesPromise);
    }

    employeeResponses = await all(employeesPromises);

    const employees: EmployeeModel[] = [];

    employeeResponses.forEach((response) => response.forEach((employee) => employees.push(employee)));

    this.employees = employees;
  });

  @action
  onMapFloorChange(mapFloor: MapFloorModel): void {
    void this.router.transitionTo('spaces.maps.versions.show', this.model.mapVersion.id, {
      queryParams: { floor: mapFloor.id },
    });
  }

  @action
  searchNeighborhoods(searchTerm: string): NeighborhoodModel[] {
    return this.model.neighborhoods.filter((neighborhood) =>
      neighborhood.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }
}
