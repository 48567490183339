/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { computed, get, set } from '@ember/object';
import { not, filterBy, equal, reads } from '@ember/object/computed';
import { task, dropTaskGroup, dropTask, waitForProperty } from 'ember-concurrency';
import { service } from '@ember/service';

export default class OnboardingStepperComponent extends Component {
  tagName = '';

  @service ajax;
  @service store;

  @not('allDone') inProgress;
  @reads('currentStep.id') activeStepId;
  @filterBy('steps', 'done', true) stepsDone;
  @filterBy('steps', 'done', false) stepsRemaining;
  @reads('steps.lastObject.id') lastStepId;
  @reads('stepsRemaining.firstObject') currentStep;

  @computed('steps.[]', 'currentStep')
  get percentageComplete() {
    if (!this.currentStep) {
      return undefined;
    }
    const activeIdx = this.steps.indexOf(this.currentStep);
    const done = activeIdx + 1; // include current step in result
    const percentage = Math.floor((done / this.steps.length) * 100);

    return percentage === 100 ? '99%' : `${percentage}%`;
  }

  @equal('stepsRemaining.length', 1) isLastStep;

  trackSkip(_title, _percentageComplete) {}

  trackStart(_title, _percentageComplete) {}

  trackNext(_title, _percentageComplete) {}

  trackComplete(_title, _percentageComplete) {}

  @computed('steps.@each.skipped', 'allDone')
  get competeWithSkipped() {
    const skipped = this.steps.filterBy('skipped', true);
    return this.allDone && skipped.length > Math.round(this.steps.length / 2);
  }

  @computed('steps.@each.done')
  get allDone() {
    return this.steps.length && this.steps.every(({ done }) => done);
  }

  percentToInt() {
    const result = this.percentageComplete || '0%';
    return parseInt(result.replace(/%/, ''), 10);
  }

  @dropTaskGroup stepUpdateTask;

  @task({ group: 'stepUpdateTask' })
  *startTask(step) {
    yield waitForProperty(this, 'setFirstViewedAtTask.isIdle');
    this.trackStart(get(step, 'title'), this.percentToInt());
    set(step, 'started', true);
    return yield this.reviewStep(step);
  }

  @task({ group: 'stepUpdateTask' })
  *skipTask(step) {
    yield waitForProperty(this, 'setFirstViewedAtTask.isIdle');
    this.trackSkip(get(step, 'title'), this.percentToInt());
    set(step, 'skipped', true);
    return yield this.updateStep(step);
  }

  @task({ group: 'stepUpdateTask' })
  *nextTask(step) {
    yield waitForProperty(this, 'setFirstViewedAtTask.isIdle');
    if (this.stepsRemaining.length == 1) {
      this.trackComplete(get(step, 'title'), this.percentToInt());
    } else {
      this.trackNext(get(step, 'title'), this.percentToInt());
    }
    set(step, 'reviewed', true);
    return yield this.updateStep(step);
  }

  @dropTask
  *setFirstViewedAtTask(step) {
    if (!get(step, 'id') || get(step, 'firstViewedAt')) return;

    const adapter = this.store.adapterFor('locations-setup-guide-step');
    const updateStepURL = adapter.urlForUpdateRecord(get(step, 'id'), 'locations-setup-guide-step');
    const serializer = this.store.serializerFor('locations-setup-guide-step');

    try {
      const payload = yield this.ajax.request(`${updateStepURL}/view`, {
        accept: 'application/vnd.api+json',
        contentType: false,
        type: 'POST',
      });

      const normalized = serializer.normalizeUpdateRecordResponse(
        this.store,
        'locations-setup-guide-step',
        payload,
        get(step, 'id'),
      );

      this.store.push(normalized);
    } catch (error) {
      /* eslint-disable no-console */
      console.log({ error });
    }
  }
}
