import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import type CompanyBillingAddressModel from 'garaje/models/company-billing-address';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

export default class BillingAddressConfirmationModal extends Component {
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: Store;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare statsig: StatsigService;
  @service declare router: RouterService;
  @service declare abilities: AbilitiesService;

  @tracked showModal: boolean = false;
  @tracked address: CompanyBillingAddressModel | null = null;
  @tracked addressString: string = '';

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    if (this.featureFlags.isEnabled('growth_confirm_billing_address_modal') && this.abilities.can('visit billing')) {
      this.showModal = true;
      this.statsig.logEvent('billing-address-modal-viewed');
      void this.loadBillingAddress();
    }
  }

  @action
  async loadBillingAddress(): Promise<void> {
    try {
      this.address = await this.store.findRecord('company-billing-address', this.state.currentCompany.id);
      this.updateAddressString();
    } catch (_e) {
      // eslint-disable-next-line no-console
      console.error(_e);
    }
  }

  @action updateAddressString(): void {
    if (this.address) {
      this.addressString =
        `${this.address.line1 ? this.address.line1 + ', ' : ''}` +
        `${this.address.city ? this.address.city + ', ' : ''}` +
        `${this.address.state ? this.address.state + ' ' : ''}` +
        `${this.address.zip ? this.address.zip + ', ' : ''}` +
        `${this.address.country ? this.address.country : ''}`;
    }
  }

  @action closeModal(): void {
    this.statsig.logEvent('billing-address-modal-close-button-clicked');
    this.showModal = false;
  }

  @action redirectToBilling(): void {
    this.statsig.logEvent('billing-address-modal-edit-redirect-clicked');
    if (this.noBillingAccess) {
      const companyName = encodeURIComponent(this.state.currentCompany.name);
      const companyId = this.state.currentCompany.id;
      window.open(
        `mailto:salestax@envoy.com?subject=Request%20to%20update%20shipping%20address%20for%20${companyName}%20(ID:${companyId})`,
      );
    } else {
      void this.router.transitionTo('billing.index', { queryParams: { editAddress: true } });
    }
  }

  get redirectButtonText(): string {
    if (this.noBillingAccess) {
      return 'Contact us';
    }
    return this.addressString.length ? 'Edit shipping address' : 'Add shipping address';
  }

  get noBillingAccess(): boolean {
    return (
      this.state.billingCompany.resellerPartner && !this.featureFlags.isEnabled('growth_billing_page_access_override')
    );
  }

  @action handleConfirm(): void {
    try {
      this.statsig.logEvent('billing-address-modal-confirm-clicked');
      if (this.address) {
        void this.address.confirm();
        this.flashMessages.showAndHideFlash('success', 'Address successfully confirmed!');
        this.showModal = false;
      }
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
