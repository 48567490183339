import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type InfinityService from 'ember-infinity/services/infinity';
import type { PaginatedRecordArray } from 'garaje/infinity-models/v3-offset';
import ExtendedInfinityModel from 'garaje/infinity-models/v3-offset';
import type InfinityPubsubService from 'garaje/services/infinity-pubsub';

interface InfinityLoaderWrapperComponentSignature {
  Args: {
    queryModelName: string;
    queryParams: Record<string, unknown>;
  };
}

/**
 * A Wrapper for ember-infinity results that additionally yields loading state of the queryTask.
 *
 * @param {String} queryModelName Required. What you would pass as store.query('model-name')
 * @param {Object} queryParams Required. Example follows ....
 *
 *   {
 *     include: 'relationship1,relationship2',
 *     sort: 'relationship1.name,relationship2.name',
 *     perPage: 20,
 *     startingPage: 0,
 *     perPageParam: 'page[limit]',
 *     pageParam: 'page[offset]',
 *     countParam: 'meta.total',
 *   }
 *
 * EXAMPLE USAGE:
 *
  <InfinityLoaderWrapper @queryModelName={{this.queryModelName}} @queryParams={{this.queryParams}} as |loader|>
    {{#if loader.isRunning}}
      <p>We yield `isRunning` to the otter compoment - this refers to initial loading state only.</p>
    {{else}}
      {{#each loader.infinityModel as |model|}}
        <p>Show the world your model ...</p>
      {{/each}}
      {{#unless loader.infinityModel.reachedInfinity}}
        {{#infinity-loader
          infinityModel=loader.infinityModel
          hideOnInfinity=true
        }}
          <p>We can show a loading state here for each request to infinitely paginate.</p>
        {{/infinity-loader}}
      {{/unless}}
    {{/if}}
  </InfinityLoaderWrapper>
 */
export default class InfinityLoaderWrapperComponent extends Component<InfinityLoaderWrapperComponentSignature> {
  @service declare infinity: InfinityService;
  @service declare infinityPubsub: InfinityPubsubService;
  @tracked declare infinityModel?: PaginatedRecordArray<unknown>;

  @action
  refreshQueryTask(): void {
    void this.queryTask.perform(this.args.queryModelName, this.args.queryParams);
  }

  @action
  subscribe(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.infinityPubsub.on(`${this.args.queryModelName}-refresh`, this.refreshQueryTask);
  }

  @action
  unsubscribe(): void {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.infinityPubsub.off(`${this.args.queryModelName}-refresh`, this.refreshQueryTask);
  }

  queryTask = dropTask(async (queryModelName: string, queryParams: Record<string, unknown>) => {
    this.infinityModel = await this.infinity.model(queryModelName, queryParams, ExtendedInfinityModel);
  });
}
