import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';

export default class MapsLiveIndexRoute extends Route {
  @service store;
  @service router;

  async beforeModel() {
    const { areaMap, currentUserReservationMapFloor } = this.modelFor('spaces.maps');
    const firstMapFloor = await get(areaMap, 'mapFloors').sortBy('ordinality').firstObject;
    if (currentUserReservationMapFloor?.rasterImageUrl) {
      this.router.transitionTo('spaces.maps.live.show', currentUserReservationMapFloor.id);
    } else if (firstMapFloor) {
      this.router.transitionTo('spaces.maps.live.show', firstMapFloor.id);
    }
  }

  model() {
    const { areaMap } = this.modelFor('spaces.maps');
    return { areaMap };
  }
}
