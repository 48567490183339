import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

import type GlobalAnalyticsRoomsIndexController from './controller';

export default class GlobalAnalyticsRoomsIndexRoute extends Route {
  title = 'Rooms · Global Analytics · Envoy';

  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare router: RouterService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  setupController(controller: GlobalAnalyticsRoomsIndexController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);

    if (
      this.featureFlags.isEnabled('sigma-embedded-analytics') ||
      this.userFeatureFlags.isEnabled('sigma-embedded-analytics-by-user')
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.fetchSigmaUrlTask.perform();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
      void controller.logSigmaUrlTask.perform();
    }
  }

  redirect(): void {
    if (!this.globalOverviewAccess.canVisitRoomsAnalytics) {
      this.router.transitionTo('unauthorized');
    }
  }
}
