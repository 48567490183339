import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { formatInTimeZone } from 'date-fns-tz';
import { dropTask } from 'ember-concurrency';
import type AreaMapModel from 'garaje/models/area-map';
import type MapFloorModel from 'garaje/models/map-floor';
import type MapVersionModel from 'garaje/models/map-version';
import type CurrentLocationService from 'garaje/services/current-location';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';
import { reads } from 'macro-decorators';

interface VersionsSidebarSignature {
  Args: {
    mapVersion: MapVersionModel;
    mapFloor: MapFloorModel;
    areaMap: AreaMapModel;
  };
}

export interface MapVersionGroup {
  title?: string;
  date: string;
  mapVersions: MapVersionModel[];
}

const DEFAULT_TIMEZONE = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'America/Los_Angeles';

export default class VersionsSidebar extends Component<VersionsSidebarSignature> {
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;

  @reads('currentLocation.timezone', DEFAULT_TIMEZONE) timezone!: string;

  @tracked mapVersionGroups: MapVersionGroup[] = [];

  getFirstVersionInGroup(mapVersionGroup: MapVersionGroup): MapVersionModel | undefined {
    return mapVersionGroup.mapVersions[0];
  }

  constructor(owner: unknown, args: VersionsSidebarSignature['Args']) {
    super(owner, args);
    void this.loadMapVersionsTask.perform();
  }

  loadMapVersionsTask = dropTask(async () => {
    try {
      const mapVersions = await this.store.query('map-version', {
        filter: { 'area-map': this.args.areaMap.id, 'exclude-source': 'internal-update' },
      });

      const employeeIds = uniq(flatten(mapVersions.map((mv) => mv.hasMany('employees').ids())));

      await this.store.query('employee', {
        filter: {
          id: employeeIds.join(','),
          deleted: false,
        },
      });

      const mapVersionGroups = mapVersions.reduce((groups, mapVersion) => {
        const date = formatInTimeZone(mapVersion.createdAt * 1000, this.timezone, 'LLL d');
        const existingGroup = groups.find((group) => group.date === date);

        if (existingGroup) {
          existingGroup.mapVersions.push(mapVersion);
          return groups;
        }

        groups.push({
          date: date,
          mapVersions: [mapVersion],
        });

        return groups;
      }, [] as MapVersionGroup[]);

      this.mapVersionGroups = mapVersionGroups;
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  });
}
