import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { later } from '@ember/runloop';
import { task, timeout, all } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { A } from '@ember/array';

import config from 'garaje/config/environment';
import zft from 'garaje/utils/zero-for-tests';

/**
 * @param {String}            forType
 */
export default class PropagableSettingsChanged extends Component {
  @service flashMessages;
  @service store;
  @service('global-setting-batch') globalSettingBatchService;

  @localCopy('args.forType', 'locations') forType;

  @tracked wantsToUpdateSettings = false;
  @tracked showUndo = true;
  @tracked selectedChildren = A();

  get globalSettingBatch() {
    return this.globalSettingBatchService.currentGlobalSettingBatch;
  }

  get typeSingular() {
    return this.forType.slice(0, -1);
  }

  get totalChildren() {
    return this.globalSettingBatch.hasMany('children').ids().length;
  }

  @action
  stateNameChanged() {
    const globalSettingBatch = this.globalSettingBatch;
    const stateName = get(globalSettingBatch, 'currentState.stateName');
    const globalSettingBatchService = this.globalSettingBatchService;
    if (stateName === 'root.loaded.saved' && config.environment !== 'test') {
      // Hide undo message after 10 seconds
      later(
        this,
        () => {
          if (!this.isDestroyed) {
            this.showUndo = false;
          }
          globalSettingBatchService.switchWithExitingCopy();
        },
        10000,
      );
    } else {
      this.showUndo = true;
    }
  }

  @task
  *undoTask() {
    const globalSettingBatch = this.globalSettingBatch;
    const globalSettingBatchService = this.globalSettingBatchService;
    try {
      yield timeout(zft(500));
      yield globalSettingBatch.undo();
      globalSettingBatchService.switchWithExitingCopy();
      this.flashMessages.showAndHideFlash('success', 'Undone!');
    } catch (_e) {
      this.showUndo = false;
      this.flashMessages.showAndHideFlash('error', 'There was an error.');
    }
  }

  @task
  *overwriteTask() {
    const { globalSettingBatch, selectedChildren } = this;
    get(globalSettingBatch, 'children').pushObjects(selectedChildren);

    try {
      yield this.globalSettingBatchService.makeACopy();
      yield globalSettingBatch.save({ adapterOptions: { restoreAttributes: true } });
      yield all(selectedChildren.map((child) => child.propagableReload()));
      this.flashMessages.showAndHideFlash('success', 'Overwritten!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
      globalSettingBatch.unloadRecord();
    }

    this.close();
  }

  @action
  close() {
    this.wantsToUpdateSettings = false;
    this.selectedChildren = A();
  }

  @action
  toggleWantsToUpdate() {
    this.wantsToUpdateSettings = !this.wantsToUpdateSettings;
  }
}
