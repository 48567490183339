import Route from '@ember/routing/route';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';

export default class EmployeesRoute extends Route {
  @service abilities;
  @service skinnyLocations;
  @service visitorsOnboarding;
  @service router;

  titleToken = 'Employees';

  beforeModel() {
    if (this.abilities.cannot('visit employees')) {
      return this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const locations = this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
    await this.visitorsOnboarding.loadSteps();
    await locations;
  }

  setupController() {
    super.setupController(...arguments);

    this.visitorsOnboarding.loadImpressionsTask.perform();
  }
}
