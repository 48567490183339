import Component from '@glimmer/component';

/**
 * @param {Boolean}               isOpen
 * @param {Boolean}               showCancel
 * @param {Function}              onCancel
 * @param {String}                iconUri
 * @param {String}                iconClassList
 * @param {HTMLElement}           iconElement
 * @param {String}                title
 * @param {String}                pillText
 * @param {String}                pillFillColour (optional)
 * @param {String}                description
 */
export default class SpHeader extends Component {
  get showCancel() {
    return this.args.showCancel ?? true;
  }

  get pillFillColour() {
    return this.args.pillFillColour ?? 'grayDark';
  }
}
