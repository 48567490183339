import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { assert } from '@ember/debug';

export default class FlowsScreeningScreeningSkippableVaccinatedComponent extends Component {
  @service flashMessages;

  get isDisabled() {
    return this.args.isDisabled || this.toggle.isRunning;
  }

  @task
  *toggle() {
    const { model } = this.args;

    assert('Flows::Screening::ScreeningSkippableVaccinated - Must provide a model', typeof model?.save === 'function');

    model.toggleProperty('skipEmployeeScreeningVaccinated');

    try {
      yield model.save();
    } catch {
      model.toggleProperty('skipEmployeeScreeningVaccinated');
      this.flashMessages.showAndHideFlash('error', 'Error saving changes.');
    }
  }
}
