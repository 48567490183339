/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { filterBy } from '@ember/object/computed';
import { action, set } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import pairablePrinters from 'garaje/utils/pairable-printers';

/**
 *
 * @param {Array<ipad>}           ipads
 * @param {Array<printer>}        printers
 */

export default class VisitorEntranceDevicesComponent extends Component {
  @service flashMessages;
  @service state;

  pairablePrinters = pairablePrinters;
  editPrinterId = null;

  @filterBy('ipads', 'isVisible') connectedIpads;

  /**
   * @task `deleteDeviceTask`
   */
  @dropTask
  *deleteDeviceTask(device) {
    try {
      yield device.destroyRecord();
      const deviceName = device.isIpad ? 'iPad' : 'printer';
      this.flashMessages.showAndHideFlash('success', `${deviceName} deleted successfully`);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  /**
   * @task `savePrinterTask`
   */
  @dropTask
  *savePrinterTask(printerChangeset) {
    try {
      yield printerChangeset.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      set(this, 'editPrinterId', null);
    } catch (error) {
      const errorText = parseErrorForDisplay(error);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  cancelChanges(printerChangeset) {
    printerChangeset.rollback();
    set(this, 'editPrinterId', null);
  }
}
