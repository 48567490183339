import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';
import { scheduleOnce } from '@ember/runloop';
import { routeEvent } from 'garaje/utils/decorators/route';

class IntegrationsEnabledRoute extends Route {
  @service currentLocation;
  @service integrationsManager;
  @service router;

  titleToken = 'Enabled Integrations';

  model() {
    return hash({
      plugins: this.modelFor('integrations').plugins,
      pluginInstalls: this.modelFor('integrations').pluginInstalls,
    });
  }

  afterModel(model) {
    const hasPlatform =
      model.pluginInstalls.filter(function (install) {
        return get(install, 'status') !== 'uninstalled';
      }).length > 0;
    if (!this.integrations().length && !hasPlatform) {
      this.router.transitionTo('integrations.index');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    const scrollTo = controller.scrollTo;
    if (scrollTo) {
      controller.scrollTo = null; // clear out the query param so it only scrolls once
      scheduleOnce('afterRender', this, this._scrollIntoView, scrollTo);
    }
  }

  deactivate() {
    super.deactivate(...arguments);
    this.controller.integration = '';
  }

  _scrollIntoView(scrollTo) {
    const el = document.getElementById(scrollTo);
    el && el.scrollIntoView();
  }

  confirmAction() {
    return window.confirm('You will lose any unsaved changes. Are you sure you want to continue?');
  }

  integrations() {
    return this.integrationsManager.getIntegrations(this.currentLocation);
  }

  @routeEvent
  routeWillChange(transition) {
    const currentLocation = this.currentLocation;
    const hasDirtyIntegrations = this.integrationsManager.hasDirtyIntegrations(currentLocation);

    if (hasDirtyIntegrations) {
      if (this.confirmAction()) {
        this.integrationsManager.cleanIntegrations(currentLocation);

        return true;
      } else {
        transition.abort();

        return false;
      }
    } else {
      return true;
    }
  }
}

export default IntegrationsEnabledRoute;
