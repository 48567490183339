import { get } from '@ember/object';
import type { AsyncBelongsTo } from '@ember-data/model';
import Model, { attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';
import type SetupGuideStepModel from 'garaje/models/setup-guide-step';
import setupGuidePagesMap from 'garaje/utils/setup-guide-pages-map';

class LocationsSetupGuideStepModel extends Model {
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
  @belongsTo('setup-guide-step', { async: true }) declare setupGuideStep: AsyncBelongsTo<SetupGuideStepModel>;
  @attr('boolean') declare completed: boolean;
  @attr('string') declare pageResourceId: string;
  @attr('boolean') declare prerequisiteComplete: boolean;
  @attr('boolean') declare reviewed: boolean;
  @attr('boolean') declare skipped: boolean;
  @attr('date') declare firstViewedAt: Date;

  get isCompletedOrSkipped(): boolean {
    return this.completed || this.skipped;
  }

  get routeName(): string {
    // eslint-disable-next-line ember/no-get
    const page = <keyof typeof setupGuidePagesMap>get(this, 'setupGuideStep.page');
    return page && setupGuidePagesMap[page];
  }
}

export default LocationsSetupGuideStepModel;
