import { type AsyncBelongsTo, belongsTo } from '@ember-data/model';
import AbstractGdprConfigurationModel from 'garaje/models/abstract/abstract-gdpr-configuration';
import type ZoneModel from 'garaje/models/zone';

class PropertyGdprConfigurationModel extends AbstractGdprConfigurationModel {
  @belongsTo('zone', { async: true }) declare property: AsyncBelongsTo<ZoneModel>;
}

export default PropertyGdprConfigurationModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'property-gdpr-configuration': PropertyGdprConfigurationModel;
  }
}
