import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { waitForProperty, dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { startingSubscriptionPlanId } from 'garaje/utils/starting-subscription-plan-id';
import { APP, PLAN_LEVEL } from 'garaje/utils/enums';
import { alias } from 'macro-decorators';

export default class ProtectLandingPageController extends Controller {
  @service router;
  @service currentAdmin;
  @service featureFlags;
  @service flashMessages;
  @service locationBilling;
  @service metrics;
  @service state;
  @service store;

  @tracked transitionDone;

  /**
   * @type {boolean} checks for Visitors subscription and any enabled Protect features
   */
  @alias('state.currentLocation.isProtectSetup') isProtectSetup;

  /**
   * @type {object} SubscriptionModel: the current Visitors subscription
   */
  @alias('state.visitorsSubscription') visitorsSubscription;

  /**
   * @type {boolean}
   */
  @alias('currentAdmin.isGlobalAdmin') canStartTrial;

  /**
   * Creates VR Subsription and reloads the global `state` service
   *
   * @param {Event} evt the DOM event
   */
  @dropTask
  *startTrialTask(evt) {
    if (evt && evt.preventDefault) {
      evt.preventDefault();
    }
    const { currentCompany } = this.state;

    try {
      if (this.featureFlags.isEnabled('locationBilling')) {
        // Create the VR Subscription Batch
        const subscriptionBatch = this.store.createRecord('subscription-batch', {
          company: currentCompany,
          products: [
            {
              'subscription-plan-id': startingSubscriptionPlanId({ app: APP.VISITORS, state: this.state }),
              trial: true,
            },
          ],
          status: 'pending',
        });
        yield subscriptionBatch.save();
        yield this.locationBilling.pollForSubscriptionBatchTask.perform(subscriptionBatch);
      } else {
        // Create VR Subscription
        const subscription = this.store.createRecord('subscription', {
          app: APP.VISITORS,
          plan: PLAN_LEVEL.PREMIUM,
          company: currentCompany,
        });
        yield subscription.save();
        // Reload state service with the new subscription added
        yield this.state.initSubscriptionStateTask.perform();
      }
      this.flashMessages.showAndHideFlash('success', 'Trial started!');
      const transitionObj = this.router.transitionTo('protect.settings.employees.registration');

      this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: 'protect' });

      transitionObj.then(() => {
        if (!this.isDestroyed && !this.isDestroying) {
          this.transitionDone = true;
        }
      });
      yield waitForProperty(this, 'transitionDone');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
