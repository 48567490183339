import Component from '@glimmer/component';
import { hash, task } from 'ember-concurrency';
import { service } from '@ember/service';
import { get } from '@ember/object';
import { localCopy } from 'tracked-toolbox';

/**
 * @param {Model<User>}                 user
 * @param {Model<Location>}             location
 * @param {String}                      notificationType
 */
export default class NotificationPreferencesLoader extends Component {
  @service store;

  @localCopy('args.notificationType', 'host_notification') notificationType;

  constructor() {
    super(...arguments);
    this.query.perform();
  }

  get hasSomeEnabled() {
    const preferenceValues = this.query.lastSuccessful.value ?? [];
    return preferenceValues.some((p) => p.preference.preferenceValue);
  }

  get modelType() {
    return this.args.location ? 'location' : 'user';
  }

  get model() {
    return this.args.location ?? this.args.user;
  }

  query = task(async () => {
    const { installedPlugins, notificationPreferences } = await hash({
      notificationPreferences: this.store.query('notification-preference', {
        filter: {
          relation_type: this.modelType,
          relation_id: get(this.model, 'id'),
          notification_type: this.notificationType,
        },
      }),
      installedPlugins: this.store.query('plugin', {
        include: 'plugin',
        filter: {
          notification_channels: true,
        },
        page: {
          limit: 100,
        },
      }),
    });

    return installedPlugins.sortBy('name').map((plugin, index) => {
      return {
        id: index,
        plugin: plugin,
        // There is no real relationship between plugin, model and
        // notification-preferences which force us to do the
        // binding manually.
        preference: this.findOrCreateNotificationPreference(plugin, notificationPreferences),
      };
    });
  });

  findOrCreateNotificationPreference(plugin, notificationPreferences) {
    const preference = notificationPreferences.findBy('notificationPlatformKey', get(plugin, 'key'));

    if (preference) return preference;

    return this.store.createRecord('notification-preference', {
      notificationPlatformKey: get(plugin, 'key'),
      notificationType: this.notificationType,
      preferenceValue: false,
      relationId: get(this.model, 'id'),
      relationType: this.modelType,
    });
  }
}
