import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import type { Task } from 'ember-concurrency';
import type PrinterModel from 'garaje/models/printer';
import brotherIpAddressValidator from 'garaje/validators/brother-ip-address';

const PRINTER_VALIDATIONS = {
  ip: brotherIpAddressValidator(),
};

// Printers with one of these as its `connectionType` can be edited.
// Other printers are configured via an external source, not garaje.
const EDITABLE_PRINTER_CONNECTION_TYPES = ['bluetooth', 'ip'];

interface PrinterDetailsComponentSignature {
  Args: {
    /**
     * Action to exit edit mode
     */
    cancelChanges: () => void;
    /**
     * Set to true if it's in edit mode
     */
    editMode: boolean;
    /**
     * Function called to transition to ipad route (if not provided, transitions to visitors.devices.ipads route)
     */
    goToIPadPage?: () => void;
    /**
     * Printer to display
     */
    printer: PrinterModel;
    /**
     * Task to save printer
     */
    savePrinterTask: Task<void, [PrinterModel]>;
    /**
     * Task to delete printer
     */
    deletePrinterTask: Task<void, [PrinterModel]>;
    /**
     * Action to set edit mode
     */
    setEditMode: () => void;
  };
}

export default class PrinterDetailsComponent extends Component<PrinterDetailsComponentSignature> {
  @service declare abilities: AbilitiesService;

  printerValidations = PRINTER_VALIDATIONS;
  @tracked displayDeleteConfirm = false;

  get showDeleteOption(): boolean {
    return this.abilities.can('delete printer', this.args.printer);
  }

  get showEditOption(): boolean {
    const printer = this.args.printer;
    return (
      this.abilities.can('update printer', printer) &&
      EDITABLE_PRINTER_CONNECTION_TYPES.includes(printer.connectionType)
    );
  }

  get showOptionsMenu(): boolean {
    return this.showDeleteOption || this.showEditOption;
  }

  get printerChangeset(): DetailedChangeset<PrinterModel> {
    const printer = this.args.printer;
    const validator = lookupValidator(this.printerValidations);

    return Changeset(printer, validator, this.printerValidations);
  }
}
