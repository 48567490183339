import Model, { type AsyncBelongsTo, attr, belongsTo } from '@ember-data/model';
import type LocationModel from 'garaje/models/location';

export default class ConnectLocationConfigurationModel extends Model {
  // kiosk rebadging credentials for employees
  @attr('boolean') declare canEnableEmployeeRebadging: boolean;
  @attr('boolean') declare employeePropertyRebadgingEnabled: boolean;

  // mobile rebadging credentials for employees
  @attr('boolean') declare canEnableEmployeePropertyMobileCredentialing: boolean;
  @attr('boolean') declare employeePropertyMobileCredentialingEnabled: boolean;

  // email digital pass for visitors
  @attr('boolean') declare canEnableVisitorPropertyDigitalPass: boolean;
  @attr('boolean') declare visitorPropertyDigitalPassEnabled: boolean;

  @attr('boolean') declare automaticEntryCreationOnSignIn: boolean;
  @attr('boolean') declare connectWalkInApprovalContactsEnabled: boolean;
  @attr('boolean') declare hostNotificationsEnabled: boolean;

  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'connect-location-configuration': ConnectLocationConfigurationModel;
  }
}
