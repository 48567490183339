import Helper from '@ember/component/helper';
import { task } from 'ember-concurrency';

/*
 * This helper wraps a function in an ember-concurrency task
 * Useful when you are passing a fn to a component that expects an ember-concurrency task
 *
 * @example
 *   <ChildComponent @handleClick={{ec-task this.myFunction type="drop"}} />
 */

/**
 * @param {Function} fn   Required. The function to wrap
 * @param {String} type   Optional. The type of task. Defaults to `task`
 * @returns {Task}        Returns an ember concurrency task
 */
export default class EcTask extends Helper {
  task = task(async (...args) => {
    return await this.handler(...args);
  });

  dropTask = task({ drop: true }, async (...args) => {
    return await this.handler(...args);
  });

  restartableTask = task({ restartable: true }, async (...args) => {
    return await this.handler(...args);
  });

  enqueueTask = task({ enqueue: true }, async (...args) => {
    return await this.handler(...args);
  });

  keepLatestTask = task({ keepLatest: true }, async (...args) => {
    return await this.handler(...args);
  });

  compute([handler], { type }) {
    this.handler = handler;

    switch (type) {
      case 'drop':
        return this.dropTask;
      case 'enqueue':
        return this.enqueueTask;
      case 'keepLatest':
        return this.keepLatestTask;
      case 'restartable':
        return this.restartableTask;
      default:
        return this.task;
    }
  }
}
