import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import fetch from 'fetch';
import config from 'garaje/config/environment';
import type { GetRoomsQuery } from 'garaje/graphql/generated/roomba-types';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type RoombaGraphqlService from 'garaje/services/roomba-graphql';
import type RoombaMetricsService from 'garaje/services/roomba-metrics';
import type RoomsPollingService from 'garaje/services/rooms-polling';

interface ReplaceableImageArgs {
  room: GetRoomsQuery['rooms'][number];
}

export default class ReplaceableImage extends Component<ReplaceableImageArgs> {
  @service declare roombaMetrics: RoombaMetricsService;
  @service declare flashMessages: FlashMessagesService;
  @service declare roomsPolling: RoomsPollingService;
  @service declare roombaGraphql: RoombaGraphqlService;

  @tracked showRemoveImageDialog = false;

  @action
  openRemoveImageDialog(event: Event): void {
    event.preventDefault();
    this.showRemoveImageDialog = true;
  }

  @action
  closeRemoveImageDialog(): void {
    this.showRemoveImageDialog = false;
  }

  uploadImage = task({ drop: true }, async (event: Event) => {
    this.roombaMetrics.trackEvent('room_image_uploaded', {
      room_id: this.args.room.id,
    });

    const target = <HTMLInputElement | null>event.target;
    const files = target && 'files' in target ? target['files'] : null;
    if (!files || !files[0]) {
      return;
    }
    const formData = new FormData();

    formData.append('image', files[0]);
    formData.append('roomId', this.args.room.id);

    try {
      await fetch(`${config.roomsHost}/a/roomba/rooms/upload-image`, {
        credentials: 'include',
        method: 'PUT',
        body: formData,
      });
    } catch (_error) {
      this.flashMessages.showAndHideFlash('error', 'Error on uploading the image');
    }
    await this.roomsPolling.fetchRooms.perform();
  });

  removeImage = task({ drop: true }, async () => {
    this.roombaMetrics.trackEvent('room_image_remove', {
      room_id: this.args.room.id,
    });
    try {
      await this.roombaGraphql.deleteRoomBackgroundImage(this.args.room.id);
    } catch (_error) {
      this.flashMessages.showAndHideFlash('error', 'Error on deleting the image');
    }
    await this.roomsPolling.fetchRooms.perform();
  });
}
