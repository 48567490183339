import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class DeliveriesSettingsDeliveryAreasDeliveryAreaDevicesIndexRoute extends Route {
  @service router;
  @service store;

  model() {
    const { deliveryArea } = this.modelFor('deliveries.settings.delivery-areas.delivery-area');
    const devices = this.store.query('delivery-device', {
      filter: {
        'delivery-area': deliveryArea.id,
      },
    });

    return hash({
      deliveryArea,
      devices,
    });
  }

  afterModel(model) {
    if (!get(model.devices, 'length')) {
      this.router.transitionTo('deliveries.settings.delivery-areas.delivery-area.devices.new');
    }
  }
}
