import Component from '@glimmer/component';
import { type Task } from 'ember-concurrency';
import type VfdContactMethod from 'garaje/models/vfd-contact-method';
import { LOAD_MORE } from 'garaje/pods/components/virtual-front-desk/notification-contact-dropdown/component';
import { type NotificationContactDropdownOption } from 'garaje/pods/components/virtual-front-desk/notification-contact-dropdown/component';

interface VirtualFrontDeskNotificationContactDropdownContactMethodSignature {
  Args: {
    /*
      This component is invoked in two ways:
      * directly, in the body of the <PowerSelect/> (which is used for rendering each search result)
      * as the @selectedItemComponent of the <PowerSelect/> (which is used for rendering the selected item only)
      When used to render the selected item, we don't want to show email addresses for users. When ember-power-select
      renders `@selectedItemComponent`, it passes the item as `@option`; because we also want to support a more
      semantically-useful `@contactMethod` name when invoked directly, we give preference to `@contactMethod` but use
      `@option` in its place if `@contactMethod` was not provided.
    */
    contactMethod?: VfdContactMethod;
    option?: VfdContactMethod;

    /**
     * @isSelectedItem is passed a truthy value when this component is being used as the `@selectedItemComponent`for the dropdown.
     * Its main purpose is to determine whether to add the `ember-power-select-selected-item` CSS class.
     */
    isSelectedItem: boolean;

    /**
     * ember-concurrency task to be invoked when the "load more" behavior is triggered.
     */
    loadNextUserPage?: Task<unknown, []>;

    /**
     * Used to indicate whether user results should display full names AND email addresses, or only full names.
     * (When used as the selected item, users should be shown as names only, but when shown as search results,
     * email addresses should be shown as well.)
     */
    showUserEmails: boolean;
  };
}

export default class VirtualFrontDeskNotificationContactDropdownContactMethod extends Component<VirtualFrontDeskNotificationContactDropdownContactMethodSignature> {
  isLoadMore(option: NotificationContactDropdownOption | typeof LOAD_MORE): boolean {
    return option === LOAD_MORE;
  }
}
