import { service } from '@ember/service';
import Component from '@glimmer/component';
import type GlobalOverviewAccessService from 'garaje/services/global-overview-access';
import type UserFeatureFlagsService from 'garaje/services/user-feature-flags';

export default class GlobalAnalyticComponent extends Component {
  @service declare globalOverviewAccess: GlobalOverviewAccessService;
  @service declare userFeatureFlags: UserFeatureFlagsService;

  get analyticsRoute(): string | null {
    return this.globalOverviewAccess.firstAccessibleRouteForGlobalAnalytics();
  }
}
