import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import adapter from 'garaje/utils/decorators/adapter';

import type TenantModel from './tenant';

@adapter('multi-tenancy')
export default class TenantPermissionsConfigurationModel extends Model {
  /**
   * whether the tenant is sharing their employee directory with the property
   */
  @attr('boolean') declare employeesRead: boolean;

  @belongsTo('tenant', { async: true }) declare tenant: AsyncBelongsTo<TenantModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'tenant-permissions-configuration': TenantPermissionsConfigurationModel;
  }
}
