import Route from '@ember/routing/route';
import { hash } from 'rsvp';
import { service } from '@ember/service';

export default class DeliveriesSettingsNicknamesRoute extends Route {
  @service abilities;
  @service state;
  @service router;
  @service store;

  titleToken = 'Deliveries Nicknames';

  beforeModel() {
    if (this.abilities.cannot('edit nicknames for deliveries')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    const { deliveriesSubscription } = this.state;
    const aliases = this.store.findAll('recipient-alias');

    return hash({ aliases, deliveriesSubscription });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.buildEmployeeRecipients.perform(model);
    controller.refreshModel = this.refresh.bind(this);
  }
}
