// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type { ModelRegistry } from 'ember-data/model';
import V3CoalesceAdapter from 'garaje/adapters/v3-coalesce';

export default class FlowAdapter extends V3CoalesceAdapter {
  urlForFindMany<K extends keyof ModelRegistry>(ids: string[], modelName: K, snapshots: unknown[]): string {
    let url = super.urlForFindMany(ids, modelName, snapshots);

    // The `filter[property_owned]` parameter is necessary because the V2 Location API includes property flows
    // in the `flows` relationship for a location, but the default filters for the V3 Flows API exclude them.
    // This led to a situation where a Location model in ember-data would have associated flow IDs of, for example,
    // "1", "2", "3", and "4", but when ember-data tried to make a request to `/api/v3/flows?filter[id]=1,2,3,4`
    // the backend would only respond with flows "1", "2", and "3", which would cause an ember-data error.
    // By passing this filter, the V3 Flows API operates on the same set of flows that the V2 Locations API uses,
    // and the problem is avoided.
    url += '?filter[property_owned]=false,true';
    return url;
  }
}
