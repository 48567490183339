import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type DeviceConfigStaticPageAttributeModel from 'garaje/models/device-config-static-page-attribute';

export default class DeviceConfigStaticPageAttributeTranslationModel extends Model {
  @attr('string') declare fromLanguage: string;
  @attr('string') declare toLanguage: string;
  @attr('string') declare translationStatus: string;
  @attr('string') declare value: string;

  @belongsTo('device-config-static-page-attribute', { async: true })
  declare deviceConfigStaticPageAttribute: AsyncBelongsTo<DeviceConfigStaticPageAttributeModel>;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'device-config-static-page-attribute-translation': DeviceConfigStaticPageAttributeTranslationModel;
  }
}
