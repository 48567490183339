import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type ReservationModel from 'garaje/models/reservation';
import type CurrentAdminService from 'garaje/services/current-admin';
import type LiveMapService from 'garaje/services/live-map';
import { AvailabilityStatus, type ResourceState, type ResourceType } from 'garaje/services/live-map';
import type StateService from 'garaje/services/state';

interface ResourcePopupComponentArgs {
  selectedDate: Date;
  resourceType: ResourceType;
  feature: MapFeatureModel;
  mapFloor: MapFloorModel;
  onCancel: () => void;
}

export default class ResourcePopupComponent extends Component<ResourcePopupComponentArgs> {
  @service declare state: StateService;
  @service declare store: StoreService;
  @service declare liveMap: LiveMapService;
  @service declare currentAdmin: CurrentAdminService;

  get resourceStateFromFeature(): ResourceState | undefined {
    // change this to get the generic resource model from the store once it's implemented
    const resourceId = this.args.feature.externalId;
    return this.liveMap.getResourceStateByIdAndType(resourceId, this.args.resourceType);
  }

  get reservationForResource(): ReservationModel | undefined {
    return undefined;
  }

  get isAvailable(): boolean {
    return this.resourceStateFromFeature?.status.availabilityStatus === AvailabilityStatus.AVAILABLE;
  }

  get isNotAvailable(): boolean {
    return this.resourceStateFromFeature?.status.availabilityStatus === AvailabilityStatus.OCCUPIED;
  }

  get isAssignedToCurrentUser(): boolean {
    // TODO
    // peek generic resource model from the store and check the assignedTo field against the current user email
    return false;
  }

  get isReservedByCurrentUser(): boolean {
    // TODO
    // not sure how we'll be able to check this for non-admins if we're anonymizing employee information within reservations
    return false;
  }

  reserveResource(): void {
    // TODO: implement once backend is ready
    return;
  }

  releaseResource(): void {
    // TODO: implement once backend is ready
    return;
  }
}
