import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import type DeskModel from 'garaje/models/desk';
import type EmployeeModel from 'garaje/models/employee';
import adapter from 'garaje/utils/decorators/adapter';

@adapter('rms')
class AssignmentModel extends Model {
  // Attributes
  @attr('date') declare startTime: string;
  @attr('boolean') declare shouldSendNotification: boolean;
  @attr('number') declare scheduledBy: number;

  // Relationships
  @belongsTo('desk', { async: true }) declare desk: AsyncBelongsTo<DeskModel>;
  @belongsTo('employee', { async: false }) declare employee: EmployeeModel;
}

export default AssignmentModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    assignment: AssignmentModel;
  }
}
