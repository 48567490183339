import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type VfdContactMethod from 'garaje/models/vfd-contact-method';

const INITAL_NUMBER_OF_CONTACTS_TO_SHOW = 3;

interface VirtualFrontDeskBackupContactsSummaryComponentSignature {
  Args: {
    backupContacts: VfdContactMethod[];
  };
}

export default class VirtualFrontDeskBackupContactsSummaryComponent extends Component<VirtualFrontDeskBackupContactsSummaryComponentSignature> {
  @tracked isShowingAllContacts = false;

  get contactsToShow(): VfdContactMethod[] {
    if (this.isShowingAllContacts) {
      return this.args.backupContacts;
    }

    return this.args.backupContacts.slice(0, INITAL_NUMBER_OF_CONTACTS_TO_SHOW);
  }

  get hasMoreContacts(): boolean {
    if (this.isShowingAllContacts) return false;

    return this.args.backupContacts.length > INITAL_NUMBER_OF_CONTACTS_TO_SHOW;
  }

  get notShownContactsCount(): number {
    return this.args.backupContacts.length - INITAL_NUMBER_OF_CONTACTS_TO_SHOW;
  }

  @action
  showAllContacts(): void {
    this.isShowingAllContacts = true;
  }
}
