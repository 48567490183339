// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { action, set, setProperties } from '@ember/object';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { next } from '@ember/runloop';

/**
 * Group row to edit a group
 * @param {Array<Group>}         groups                     List of groups to check duplicated names
 * @param {Group}                group                      Group to edit
 * @param {Function}             confirmGroupDeletion       Action to show the confirmation modal
 */

export default class GroupsTableRowComponent extends Component {
  tagName = 'tr';
  /**
   * Test selectors
   */
  attributeBindings = ['data-test-group-row'];
  @service flashMessages;
  @service metrics;
  /**
   * @type {Boolean}
   */
  isEditing = false;
  /**
   * @type {String}
   */
  updatedName = '';

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);

    setProperties(this, {
      updatedName: this.group.name,
      'data-test-group-row': this.group.id,
    });
  }

  /**
   * @task `saveGroupTask`
   */
  @dropTask
  *saveGroupTask() {
    const duplicatedName = this.groups.any((group) => group.name === this.updatedName.trim());
    if (duplicatedName) {
      this.flashMessages.showAndHideFlash('error', 'Group name taken', 'Choose a group name not already in use');
      return;
    }
    try {
      const originalGroupName = this.group.name;
      set(this.group, 'name', this.updatedName.trim());
      yield this.group.save();

      this.metrics.trackEvent('Group Action Taken', {
        action: 'group_name_edited',
        original_group_name: originalGroupName,
        new_group_name: this.group.name,
      });

      set(this, 'isEditing', false);
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }

  @action
  focusInput() {
    next(this, function () {
      this.element.getElementsByTagName('input')[0].focus();
    });
  }
}
