/* eslint-disable ember/no-computed-properties-in-native-classes */
import type NativeArray from '@ember/array/-private/native-array';
import EmberObject, { action, get, set, computed } from '@ember/object';
import { alias, readOnly, and, sort, map, filter } from '@ember/object/computed';
import { service } from '@ember/service';
import { buildWaiter } from '@ember/test-waiters';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Changeset } from 'ember-changeset';
import type { DetailedChangeset } from 'ember-changeset/types';
import lookupValidator from 'ember-changeset-validations';
import { task, restartableTask, dropTask, all } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type EmployeeModel from 'garaje/models/employee';
import type EntryModel from 'garaje/models/entry';
import type FlowModel from 'garaje/models/flow';
import type LocationModel from 'garaje/models/location';
import type PrinterModel from 'garaje/models/printer';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SignInFieldActionModel from 'garaje/models/sign-in-field-action';
import type SubscriptionModel from 'garaje/models/subscription';
import type UserDatum from 'garaje/models/user-datum';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateAttachmentModel from 'garaje/models/user-document-template-attachment';
import type UserDocumentTemplateConfigurationModel from 'garaje/models/user-document-template-configuration';
import type VisitorDocumentModel from 'garaje/models/visitor-document';
import type ActiveStorageService from 'garaje/services/active-storage-extension';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LoggerService from 'garaje/services/logger';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { NON_ASSIGNABLE_FLOWS } from 'garaje/utils/enums';
import addOrUpdateUserData from 'garaje/utils/user-data';
import buildFlowFieldValidations from 'garaje/validations/flow-field';
import moment from 'moment';
// Without this import the in-repo addon fails
// Doesn't even have to be for this component, but
// must be imported at least once
import 'rome';

const testWaiter = buildWaiter('new-visitor-form-component-waiter');
const isTesting = config.environment === 'test';

interface SaveOptions {
  redirectToIndex?: number;
}

interface PrinterOption {
  disabled: boolean;
  printer: PrinterModel;
}

interface NewVisitorFormComponentArgs {
  Args: {
    currentLocation: LocationModel;
    dateChanged: (date: Date) => void;
    locationIsConnectedToProperty: boolean;
    model: EntryModel;
    modelChangeset: DetailedChangeset<EntryModel>;
    modelDidPartialSave: (entry: EntryModel, error: unknown) => void;
    modelDidSave: (options: SaveOptions) => void;
    printers: PrinterModel[];
    signedInAt: string;
    vrSubscription: SubscriptionModel;
  };
}

export default class NewVisitorFormComponent extends Component<NewVisitorFormComponentArgs> {
  // ActiveStorage service: https://github.com/algonauti/ember-active-storage
  @service declare activeStorageExtension: ActiveStorageService;
  @service declare logger: LoggerService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare store: StoreService;
  @service declare state: StateService;

  @tracked shouldPrintBadge = false;
  @tracked printerSelected: PrinterOption | null = null;
  @tracked didOmitRequiredPrinter?: boolean;
  @tracked printers;
  @tracked date?: Date;
  @tracked atCapacityForSignedInAt?: boolean;
  @tracked hasAttachments = false;

  constructor(owner: unknown, args: NewVisitorFormComponentArgs['Args']) {
    super(owner, args);
    this.printers = this.args.printers ?? [];
    this.date = this.args.modelChangeset.signedInAt;
    void this.checkCapacityTask.perform();
  }

  @map('sortedSignInFields', function (this: NewVisitorFormComponent, field) {
    const signInField = <SignInFieldModel>field;
    return get(this.userDataChangeset, `field-${signInField.id}`) || this.buildFieldChangeset(signInField);
  })
  fieldChangesets!: DetailedChangeset<SignInFieldModel>[];

  @sort('signInFields', (a: SignInFieldModel, b: SignInFieldModel) => a.position - b.position)
  sortedSignInFields!: SignInFieldModel[];
  @and('args.currentLocation.printer.enabled', 'flow.badge.enabled') canPrintBadges!: boolean;
  @alias('args.currentLocation.hostNotificationsEnabled') hostNotificationsEnabled!: boolean;
  @readOnly('flow.signInFieldPage.signInFields') signInFields?: SignInFieldModel[];

  @filter('args.currentLocation.flows.[]', function (flow) {
    const { type, employeeCentric } = <FlowModel>flow;
    return !NON_ASSIGNABLE_FLOWS.includes(type) && !employeeCentric;
  })
  flowOptions!: FlowModel[];

  @readOnly('flow.activeUserDocumentTemplateConfigurations')
  activeUserDocumentTemplateConfigurations!: NativeArray<UserDocumentTemplateConfigurationModel>;

  @computed('fieldChangesets.@each.value')
  get renderableFieldChangesets(): DetailedChangeset<SignInFieldModel>[] {
    return this.fieldChangesets.filter((fieldChangeset) => {
      if (fieldChangeset.isTopLevel) {
        return true;
      }

      // eslint-disable-next-line ember/no-get
      const action = <SignInFieldActionModel>get(fieldChangeset, 'actionableSignInFieldActions.firstObject');

      // Do not attempt to automatically async fetch related signInField
      // If signInField record isn't already fulfilled, it will probably not load
      // eslint-disable-next-line ember/no-get
      const isApplicableAction = action && action.belongsTo('signInField').value() && get(action, 'signInField.id');

      if (!isApplicableAction) {
        return false;
      }

      // eslint-disable-next-line ember/no-get
      const changesetParent = this.fieldChangesets.find((changeset) => changeset.id === get(action, 'signInField.id'));

      // eslint-disable-next-line ember/no-get
      return changesetParent && changesetParent.value === get(action, 'dropdownOption.value');
    });
  }

  @computed('signInFields.@each.identifier')
  get hostSignInField(): SignInFieldModel | undefined {
    return this.signInFields?.find((field) => field.identifier === 'host');
  }

  @computed('args.modelChangeset.userData.@each.field')
  get hasHostNotificationField(): boolean {
    return !!this.args.modelChangeset.userData.findBy('field', 'Host');
  }

  get canSetPropertyNotes(): boolean {
    return this.args.locationIsConnectedToProperty;
  }

  @action
  buildFieldChangeset(field: SignInFieldModel): DetailedChangeset<SignInFieldModel> {
    const validations = buildFlowFieldValidations(field);
    const validator = lookupValidator(validations);
    return Changeset(field, validator, validations);
  }

  @action
  setDate(date: Date): void {
    this.args.modelChangeset.signedInAt = date;
    this.args.dateChanged(date);
    void this.checkCapacityTask.perform();
  }

  isVisitorDocumentPersistable(visitorDocument: VisitorDocumentModel): boolean {
    const activeIdentifiers = this.activeUserDocumentTemplateConfigurations.mapBy('userDocumentTemplate.identifier');
    const isAssociatedToActiveTemplate = activeIdentifiers.includes(visitorDocument.identifier);
    const hasAttachmentsWithPendingUploads = visitorDocument.userDocumentAttachmentsPendingUpload.length > 0;

    return isAssociatedToActiveTemplate && hasAttachmentsWithPendingUploads;
  }

  checkCapacityTask = restartableTask(async () => {
    const locationId = this.args.currentLocation.id;
    const signedInAt = this.args.modelChangeset.signedInAt;

    if (this.args.currentLocation.capacityLimitEnabled) {
      const locationsCapacity = await this.store
        .query('locations-capacity', {
          filter: {
            location: locationId,
            capacity_date: moment(signedInAt).format('YYYY-MM-DDTHH:mm'),
          },
        })
        .then((models) => models.firstObject);

      if (locationsCapacity) {
        const { onSiteCount, expectedCount, capacityLimit } = locationsCapacity;
        const capacityUsed = onSiteCount + expectedCount;
        const capacityRatio = capacityLimit >= 0 ? capacityUsed / capacityLimit : 0;
        this.atCapacityForSignedInAt = capacityRatio >= 1;
        return;
      }
    }
    this.atCapacityForSignedInAt = false;
    return;
  });

  saveTask = dropTask(async (options: SaveOptions = {}) => {
    this.args.modelChangeset.currentLocationId = Number(this.args.currentLocation.id);

    this.userDataChangeset.changes.forEach(({ value }) => {
      const fieldChangeset = <DetailedChangeset<SignInFieldModel>>value;
      addOrUpdateUserData(this.args.modelChangeset, fieldChangeset.name, fieldChangeset.value);
    });

    addOrUpdateUserData(this.args.modelChangeset, 'Your Full Name', this.args.modelChangeset.fullName);

    this.args.modelChangeset.flowName = this.flow!.name;

    // if a printer wasn't selected, then we set the printBadge flag on the entry and let the backend print the badge
    if (!this.printerSelected) {
      set(this.args.modelChangeset, 'printBadge', this.shouldPrintBadge);
    }

    const token = isTesting ? testWaiter.beginAsync() : false;

    try {
      const entry = await this.args.modelChangeset.save();
      const visitorDocuments = entry?.visitorDocuments ?? [];
      let printMessage = this.shouldPrintBadge ? 'Printing badge' : '';

      if (this.printerSelected) {
        // if a printer was selected, then here we manually print the badge on that printer
        const data = [{ type: 'entries', id: entry.id }];
        void this.printerSelected.printer.reprintBadge({ data });
        printMessage = `Badge printing on ${this.printerSelected.printer.name}`;
      }

      try {
        await all(
          visitorDocuments.map((visitorDocument) => this.saveVisitorDocumentTask.perform(entry, visitorDocument)),
        );
      } catch (error) {
        this.flashMessages.showFlash('error', 'Visitor saved! But one or more document uploads failed');

        // Handle entry successfully persisted but associated visitor document upload/save failed
        return this.args.modelDidPartialSave?.(entry, error);
      }

      this.args.modelDidSave(options);
      this.flashMessages.showAndHideFlash('success', 'New visitor entry created!', { details: printMessage });
    } catch (e) {
      // In order for this to work, we modified how we are creating tokens
      // https://github.com/envoy/garaje/pull/2678
      // So, all users which have old tokens will still be effected by this
      // When we have no more old tokens, we should be able to remove this code
      const msg = 'Assertion Failed: Your entry record was saved';
      // Throwing error for entry creation. Not always actually an error
      this.logger.error(<Error>e);
      if ((<Error>e).message.indexOf(msg) === 0) {
        this.flashMessages.showAndHideFlash('success', 'New visitor entry created!');
        this.args.modelDidSave(options);
      }
    } finally {
      if (token) testWaiter.endAsync(token);
    }
  });

  save = dropTask(async (redirectToIndex: number, e: Event) => {
    e.preventDefault();

    const entryChangeset = this.args.modelChangeset;
    await entryChangeset.validate();

    // Only validate RENDERED / VISIBLE field changesets
    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      await changeset?.validate();
    }

    const isMissingPrinter = this.printerSelectionRequired && !this.printerSelected;
    this.didOmitRequiredPrinter = isMissingPrinter;

    if (entryChangeset.isInvalid || isMissingPrinter) {
      // eslint-disable-next-line ember/no-get
      const specificError = <string>get(entryChangeset, 'errors.firstObject.validation');
      this.flashMessages.showFlash('error', 'Please fill in the required fields', { details: specificError });
      return;
    }

    for (const index of this.renderableFieldChangesets.keys()) {
      const changeset = this.renderableFieldChangesets[index];
      if (changeset?.isInvalid) {
        // eslint-disable-next-line ember/no-get
        const specificError = <string>get(changeset, 'errors.firstObject.validation.firstObject');
        this.flashMessages.showFlash('error', 'Please fill in the required fields', { details: specificError });
        return;
      }
    }

    if (!this.isValidVisitorDocuments) {
      this.flashMessages.showFlash('error', 'Please enter all required information');
      return;
    }

    void this.saveTask.perform({ redirectToIndex });
  });

  @(employeesSearcherTask({
    filter: { deleted: false },
    prefix: true,
  }).restartable())
  searchEmployeesTask!: EmployeeSearcherTask;

  setHost(employee: EmployeeModel | null): void {
    // employee will be null when select is cleared
    const employeeName = employee && employee.name;

    this.args.modelChangeset.host = employeeName;
    this.args.modelChangeset.employee = employee;
  }

  /* MVT related
   * Keeping all the code related with MVT below
   */
  // we only care if the changeset changes since the POV field can't be deleted
  @computed('args.modelChangeset.userData')
  get purposeOfVisit(): UserDatum | undefined {
    return this.args.modelChangeset.userData.findBy('field', 'Purpose of visit');
  }

  @computed('flowOptions.[]', 'args.modelChangeset.flow')
  get flow(): FlowModel | undefined {
    // eslint-disable-next-line ember/use-ember-get-and-set
    const flowId = <string>this.args.modelChangeset.get('flow.id');
    return this.flowOptions.find((flow) => flow.id === flowId);
  }

  @computed('args.{currentLocation.id,modelChangeset.userData}', 'flow.name')
  get userDataChangeset(): DetailedChangeset<Record<string, DetailedChangeset<SignInFieldModel>>> {
    const initialState = EmberObject.create({});
    const flow = this.flow;
    const flowName = this.flow?.name;
    // eslint-disable-next-line ember/no-get
    const signInFields = <SignInFieldModel[]>get(flow, 'signInFieldPage.signInFields');

    // bootstrap the initial state for the changeset so it uses whatever is in the invite.userData
    // this is required to show pre-existing data
    this.args.modelChangeset.userData.forEach((option, index) => {
      const fieldName = option.field;
      const value = option.value;

      // ignore purpose of visit here
      if (value !== flowName) {
        let field = signInFields.find((field) => field.name === fieldName);

        if (!field) {
          field = <SignInFieldModel>(<unknown>{
            id: `${index}-${this.args.currentLocation.id}`,
            name: fieldName,
            isLoaded: true,
          });
        }

        field = this.buildFieldChangeset(field);
        field.value = value; // set the initial value
        initialState[this.pseudoIdForField(field)] = field;
      }
    });

    return Changeset(initialState);
  }

  @action
  checkForAttachments(): void {
    this.hasAttachments = Boolean(this.visitorDocuments?.find((document) => document.hasAttachedFile));
  }

  get visitorDocuments(): VisitorDocumentModel[] | undefined {
    const {
      activeUserDocumentTemplateConfigurations,
      args: { model },
    } = this;

    return activeUserDocumentTemplateConfigurations?.sortBy('userDocumentTemplate.position').map((config) => {
      const { userDocumentTemplate } = config;

      return (
        model.visitorDocumentForTemplate(userDocumentTemplate) ||
        this.store.createRecord('visitor-document', { userDocumentTemplate })
      );
    });
  }

  get isValidVisitorDocuments(): boolean {
    const documentsToCheck = this.visitorDocuments?.filter((document) => document.hasAttachedFile);
    return (documentsToCheck ?? []).every((document) => document.isValidDocument);
  }

  pseudoIdForField(field: SignInFieldModel): string {
    // The key provided to set must be a string.
    return `field-${field.id}`;
  }

  @action
  updateUserDataField(changeset: DetailedChangeset<SignInFieldModel>): void {
    if (changeset.isEmail) this.args.modelChangeset.email = changeset.value!;
    this.userDataChangeset[this.pseudoIdForField(changeset)] = changeset;
  }

  @action
  setHostAndUserData(changeset: DetailedChangeset<SignInFieldModel>, employee: EmployeeModel | null): void {
    this.setHost(employee);

    if (!employee) {
      this.args.modelChangeset.sendHostNotification = false;
    }

    // employee will be null when select is cleared
    const employeeName = employee && employee.name;
    changeset.value = employeeName;

    this.updateUserDataField(changeset);
  }

  get printerOptions(): PrinterOption[] {
    return this.printers.map((printer) => {
      return {
        disabled: printer.status !== 'connected',
        printer,
      };
    });
  }

  get printerSelectionRequired(): boolean {
    return this.shouldPrintBadge && this.printers.length > 1;
  }

  @action
  selectPrinter(printer: PrinterOption): void {
    this.printerSelected = printer;
    this.didOmitRequiredPrinter = false;
  }

  @action
  attachFileToDocument(
    visitorDocument: VisitorDocumentModel,
    userDocumentTemplateAttachment: UserDocumentTemplateAttachmentModel,
    update: File | string,
  ): void {
    visitorDocument.entries.addObject(this.args.model);

    let userDocumentAttachment = visitorDocument.getAttachment(userDocumentTemplateAttachment.id);

    if (!userDocumentAttachment) {
      userDocumentAttachment = this.store.createRecord('user-document-attachment');
      userDocumentAttachment.userDocumentTemplateAttachment = userDocumentTemplateAttachment;
      userDocumentAttachment.visitorDocument = visitorDocument;
    }

    if (update instanceof File) {
      userDocumentAttachment.file = update;
    }

    if (typeof update === 'string') {
      userDocumentAttachment.fileUrl = update;
    }
  }

  @action
  resetVisitorDocument(visitorDocument: VisitorDocumentModel): void {
    const { model } = this.args;
    const { userDocumentTemplate } = visitorDocument;

    if (<boolean>(<unknown>visitorDocument.isNew)) {
      model.visitorDocuments.removeObject(visitorDocument);
      visitorDocument.unloadRecord();
      model.visitorDocuments.addObject(this.store.createRecord('visitor-document', { userDocumentTemplate }));
    }
  }

  @action
  updateFlow(flow: FlowModel): void {
    set(this.args.modelChangeset, 'flow', flow);
    this.purposeOfVisit!.value = flow.name;
  }

  saveVisitorDocumentTask = task(async (entry: EntryModel, visitorDocument: VisitorDocumentModel) => {
    if (!this.isVisitorDocumentPersistable(visitorDocument)) return visitorDocument;

    // Associate the entry's location to the visitor document (fallback to current location)
    // eslint-disable-next-line ember/no-get
    const location = this.store.peekRecord('location', <string>get(entry, 'location.id')) || this.state.currentLocation;

    visitorDocument.locations = [location];

    await this.uploadVisitorDocumentAttachmentsTask.perform(entry, visitorDocument);
    await visitorDocument.save();

    // Cleanup attachments
    visitorDocument.userDocumentAttachments.filterBy('isNew').invoke('unloadRecord');

    return visitorDocument;
  });

  uploadVisitorDocumentAttachmentsTask = task(async (entry: EntryModel, visitorDocument: VisitorDocumentModel) => {
    const { activeStorageExtension } = this;
    const directUploadURL = '/a/visitors/api/direct-uploads';
    // eslint-disable-next-line ember/no-get
    const userDocumentTemplateId = <string>get(visitorDocument, 'userDocumentTemplate.id');

    if (!(entry?.id && userDocumentTemplateId)) return;

    const prefix = `user-documents/${userDocumentTemplateId}/entries/${entry.id}`;
    const endpoint = `${directUploadURL}?prefix=${prefix}`;

    return await all(
      visitorDocument.userDocumentAttachmentsPendingUpload.map((userDocumentAttachment) =>
        this.uploadDocumentAttachmentTask.perform(userDocumentAttachment, endpoint, activeStorageExtension),
      ),
    );
  });

  uploadDocumentAttachmentTask = task(
    async (
      userDocumentAttachment: UserDocumentAttachmentModel,
      endpoint: string,
      activeStorageExtension: ActiveStorageService,
    ) => {
      const { file } = userDocumentAttachment;

      if (!(file instanceof File)) {
        throw new Error('Upload halted: no file specified');
      }

      if (!endpoint) {
        throw new Error('Upload halted: no direct upload endpoint specified');
      }

      if (typeof activeStorageExtension.upload !== 'function') {
        throw new Error('Upload halted: invalid Active Storage service specified');
      }

      const { signedId } = await activeStorageExtension.upload(file, endpoint, {
        onProgress: (progress) => {
          if (!userDocumentAttachment.isDestroyed && !userDocumentAttachment.isDestroying) {
            set(userDocumentAttachment, 'uploadProgress', progress);
          }
        },
      });

      userDocumentAttachment.file = signedId;

      return signedId;
    },
  );
}
