import Component from '@glimmer/component';

/**
 * @param {Boolean}             isOpen
 * @param {Boolean}             isEditing
 * @param {Boolean}             isPreviewing
 * @param {Function}            toPreviewing
 * @param {Function}            toEditing
 * @param {String}              editLabel
 * @param {String}              previewLabel
 */
export default class SpBodyStates extends Component {
  get editLabel() {
    return this.args.editLabel ?? 'Edit text';
  }

  get previewLabel() {
    return this.args.previewLabel ?? 'Preview';
  }
}
