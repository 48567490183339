import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment-timezone';

class Meeting extends Model {
  @belongsTo('location', { async: true }) location;
  @belongsTo('employee', { async: true }) employee;
  @hasMany('attendees', { async: true }) attendees;

  @attr('date') expectedArrivalTime;
  @attr('string') name;
  @attr('string') flowName;

  get expectedArrivalTimeDisplay() {
    return moment(this.expectedArrivalTime).format('dddd, MMM D, YYYY [at] h:mm a');
  }
}

export default Meeting;
