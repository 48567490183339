import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
/**
 * @param {Class<Subscription>}           subscription
 * @param {Function}                      boss
 * @param {Function}                      editProfile
 * @param {Function}                      logout
 */
export default class UserMenu extends Component {
  @service currentLocation;
  @service currentAdmin;

  @tracked showShareFeedbackModal = false;

  @action handleShareFeedback() {
    this.showShareFeedbackModal = true;
  }

  @action handleCloseShareFeedbackModal() {
    this.showShareFeedbackModal = false;
  }

  @action
  onInsert() {
    this.currentLocation.setupHeadway();
  }
}
