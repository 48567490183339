import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import config from 'garaje/config/environment';
import type { UploadStatusPayload } from 'garaje/models/csv-upload';
import type LocationModel from 'garaje/models/location';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type BlocklistFilterService from 'garaje/services/blocklist-filter';
import type BlocklistFilterImporterService from 'garaje/services/blocklist-filter-importer';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type UploaderFactoryService from 'garaje/services/uploader-factory';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

interface BlocklistCsvImportLocationComponentSignature {
  Args: {
    /**
     * the location for which to configure the blocklist
     */
    location: LocationModel;
    /**
     * action triggered when upload process completes successfully. Receives no arguments.
     */
    uploadCompleted?: () => void;
  };
}

export default class BlocklistCsvImportLocationComponent extends Component<BlocklistCsvImportLocationComponentSignature> {
  @service declare ajaxFetch: AjaxFetchService;
  @service('blocklistFilter') declare blocklistFilterService: BlocklistFilterService;
  @service declare flashMessages: FlashMessagesService;
  @service('blocklistFilterImporter') declare importer: BlocklistFilterImporterService;
  @service declare uploaderFactory: UploaderFactoryService;

  @tracked importedCount = 0;

  saveBlocklistFiltersTask = task(async (csvFile: File) => {
    const locationId = this.args.location.id;
    const url = urlBuilder.v3.blocklistFilter.uploadCsv(locationId, true);
    try {
      const uploader = this.uploaderFactory.createUploader({
        url,
        headers: { Accept: 'application/vncsvDatad.api+json' },
      });
      await uploader.upload(csvFile);
      await this.waitOnStatusTask.perform();
    } catch (e) {
      void this.blocklistFilterService.queryByCompanyTask.perform(locationId);
      // eslint-disable-next-line no-console
      console.error(e);
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
      throw e; // re-throw the error so the base component can clear the upload
    }
  });

  waitOnStatusTask = task(async () => {
    const locationId = this.args.location.id;
    const url = urlBuilder.v3.blocklistFilter.uploadCsvStatus();
    const settings = {
      url,
      type: 'GET',
      contentType: false,
      headers: { Accept: 'application/vnd.api+json' },
      data: { filter: { location: locationId } },
    };
    while (config.environment !== 'test') {
      const {
        data: { attributes },
      } = await this.ajaxFetch.request<UploadStatusPayload>(url, settings);
      if (attributes['upload-status'] === 'in_progress') {
        if (attributes['imported-count']) {
          this.importedCount = attributes['imported-count'];
        }
        await timeout(1250);
        continue;
      }
      if (attributes['upload-status'] === 'done') {
        await this.blocklistFilterService.queryByCompanyTask.perform(locationId);
        this.args.uploadCompleted?.();
      } else {
        await this.blocklistFilterService.queryByCompanyTask.perform(locationId);
        this.flashMessages.showFlash('error', attributes['message']!);
      }
      break;
    }
  });
}
