import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { addMinutes, endOfDay, fromUnixTime, isToday, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import type { TaskForAsyncTaskFunction } from 'ember-concurrency';
import { task } from 'ember-concurrency';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StateService from 'garaje/services/state';
import urlBuilder from 'garaje/utils/url-builder';

const POLLING_INTERVAL_MINUTES = 1;
// This will be used to calculate the endTime from the current time when making a request for resource states
const CURRENT_STATE_INTERVAL_MINUTES = 5;

export enum ResourceType {
  DESK = 'desk',
  ROOM = 'room',
  POINT_OF_INTEREST = 'poi',
}

export enum CheckInStatusEnum {
  PENDING,
  CHECKED_IN,
}

interface CheckInStatus {
  status: CheckInStatusEnum;
}

export enum AvailabilityStatus {
  AVAILABLE = 'AVAILABLE',
  OCCUPIED = 'OCCUPIED',
  UNAVAILABLE = 'UNAVAILABLE',
}

interface ResourceStatus {
  reservationId: string;
  availabilityStatus: AvailabilityStatus;
  checkInStatus: CheckInStatus;
  reservedFor: string; // urn:envoy:employee:XXXXX
}

export interface ResourceState {
  resourceId: string;
  resourceType: ResourceType;
  assignedTo: string; // urn:envoy:employee:XXXXX
  status: ResourceStatus;
}

export interface LiveMapResponse {
  data: {
    live: ResourceState[];
    static: unknown; // right now, this is an array of different resources in jsonapi format
  };
}

// time fields are of RFC3339 format which is basically the same as ISO8601
export type LiveMapQueryFilters =
  | { locationId?: string; areaMapId?: never; selectedTime?: number }
  | { locationId?: never; areaMapId?: string; selectedTime?: number };

// This service is responsible for communicating with the live map endpoint to get the current state of resources
// And fetching the static data used in the live map view.
// It fetches and stores live map data in the form of resource states and polls the endpoint every 5 minutes.
// It also fetches and loads the static map data into the ember store
export default class LiveMapService extends Service {
  @service declare ajax: AjaxService;
  @service declare state: StateService;
  @service declare flashMessages: FlashMessagesService;
  @service declare store: Store;
  @service declare router: RouterService;

  @tracked resourceStates: ResourceState[] = [];
  @tracked didLiveUpdateFail: boolean = false;
  @tracked currentPollingLocationId: string | null = null;
  @tracked selectedStartTime: string = '';

  // Config for polling
  timeoutId: ReturnType<typeof setTimeout> | null = null; // TS normally expect a Timeout object, but in ember this is a number so this just tells TS to check the environment

  _toOfficeLocationTime(date: Date): Date {
    return this.state.getOfficeLocationTime(date);
  }

  _getStartEndTime(filters?: LiveMapQueryFilters): { startTime: string; endTime: string } {
    const selectedTime = filters?.selectedTime || this.router.currentRoute?.queryParams['selectedTime'];

    if (selectedTime && !isToday(fromUnixTime(Number(selectedTime)))) {
      return {
        startTime: this._toOfficeLocationTime(startOfDay(fromUnixTime(Number(selectedTime)))).toISOString(),
        endTime: this._toOfficeLocationTime(endOfDay(fromUnixTime(Number(selectedTime)))).toISOString(),
      };
    }

    const startTime = toZonedTime(new Date(), this.state.currentLocation.timezone);
    const endTime = addMinutes(startTime, CURRENT_STATE_INTERVAL_MINUTES);

    return {
      startTime: this._toOfficeLocationTime(startTime).toISOString(),
      endTime: this._toOfficeLocationTime(endTime).toISOString(),
    };
  }

  // Get all the resource states for current time
  fetchAndSaveLiveMapData = task({ drop: true }, async (filters?: LiveMapQueryFilters) => {
    const { startTime, endTime } = this._getStartEndTime(filters);

    const queryFilters = {
      locationId: filters?.locationId || this.state.currentLocation?.id,
      startTime: encodeURIComponent(startTime),
      endTime: encodeURIComponent(endTime),
    };

    this.currentPollingLocationId = queryFilters.locationId || null;

    const liveMapDataEndpoint = urlBuilder.maps.getLiveMapUrl(queryFilters);
    const payload: LiveMapResponse = await this.ajax.request(liveMapDataEndpoint, {
      type: 'GET',
      headers: { Accept: 'application/vnd.api+json' },
    });

    // Save live data locally in this service.
    // Push the static data into the ember store.
    this.resourceStates = payload.data.live;
    this.store.pushPayload({ data: payload.data.static });
  });

  getCurrentResourceStates(): ResourceState[] {
    return this.resourceStates;
  }

  getResourceStatesByType(resourceType: ResourceType): ResourceState[] {
    const resourceStates: ResourceState[] = this.getCurrentResourceStates();
    return resourceStates.filter((resource) => resource.resourceType === resourceType);
  }

  getResourceStateByIdAndType(resourceId: string, resourceType: ResourceType): ResourceState | undefined {
    const resourceStates: ResourceState[] = this.getCurrentResourceStates();
    return resourceStates.find(
      (resource) => resource.resourceId === resourceId && resource.resourceType === resourceType,
    );
  }

  getResourceAvailabilityByIdAndType(resourceId: string, resourceType: ResourceType): AvailabilityStatus | undefined {
    return this.getResourceStateByIdAndType(resourceId, resourceType)?.status?.availabilityStatus;
  }

  async getUpdatedLiveMapData(filters?: LiveMapQueryFilters): Promise<void> {
    this.stopPolling();

    // Fetches the latest live map and starts polling management
    await this.startPollingLiveMap(this.fetchAndSaveLiveMapData, filters);
  }

  stopPolling(): void {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
  // In case a consumer needs to restart the polling cycle
  async restartPollingLiveMap(): Promise<void> {
    this.stopPolling();

    const currentFilters: LiveMapQueryFilters | undefined = this.currentPollingLocationId
      ? this.currentPollingLocationId === this.state.currentLocation?.id
        ? { locationId: this.currentPollingLocationId }
        : { areaMapId: this.currentPollingLocationId }
      : undefined;

    this.didLiveUpdateFail = false;
    await this.startPollingLiveMap(this.fetchAndSaveLiveMapData, currentFilters);
  }

  willDestroy(): void {
    this.stopPolling();
    super.willDestroy();
  }

  private async startPollingLiveMap(
    taskCallback: TaskForAsyncTaskFunction<
      {
        drop: boolean;
      },
      (filters?: LiveMapQueryFilters) => Promise<void>
    >,
    filters?: LiveMapQueryFilters,
  ): Promise<void> {
    try {
      await taskCallback.perform(filters);

      if (this.didLiveUpdateFail) {
        this.didLiveUpdateFail = false;
      }
    } catch (error) {
      if (!this.didLiveUpdateFail) {
        this.didLiveUpdateFail = true;
      }
      this.flashMessages.showFlash('error', 'Live map data could not be fetched');
      // eslint-disable-next-line no-console
      console.error('Failed to fetch resource states', error);
    } finally {
      // Clear existing timeout before setting an ew one
      this.stopPolling();

      this.timeoutId = setTimeout(
        () => void this.startPollingLiveMap(taskCallback, filters),
        POLLING_INTERVAL_MINUTES * 60 * 1000,
      );
    }
  }
}
