// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { get } from '@ember/object';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';

/**
 * List of locations with group assignment capabilities
 * @param {Array<Group>}        groups          List of available groups to be assigned
 * @param {SkinnyLocation}      location        Skinny location
 */

export default class LocationsTableGroupSelectionComponent extends Component {
  @service store;
  @service metrics;

  @dropTask
  *toggleGroupTask(newGroup) {
    // Clean existing one
    const existingGroup = get(this.location, 'group');
    if (existingGroup) {
      get(existingGroup, 'locations').removeObject(this.location);
      yield existingGroup.save();
      this.metrics.trackEvent('Group Action Taken', {
        action: 'group_members_removed',
        target_group: existingGroup.name,
        target_group_members: [this.location.name],
      });
    }
    // Add new group if passed
    if (newGroup) {
      get(newGroup, 'locations').pushObject(this.location);
      yield newGroup.save();
      this.metrics.trackEvent('Group Action Taken', {
        action: 'group_members_added',
        target_group: newGroup.name,
        target_group_members: [this.location.name],
      });
    }
    // If the real location is already loaded, reload it.
    const realLocation = this.store.peekRecord('location', this.location.id);
    if (realLocation) {
      yield realLocation.reload();
    }
  }
}
