import axios, { Axios, AxiosError } from 'axios';

import { TokenResolver } from './token-resolver';

export class HttpResolver {
  private readonly tokenResolver: TokenResolver;
  protected readonly axiosInstance: Axios;

  private refreshTried = false;

  constructor(tokenResolver: TokenResolver, baseUrl: string) {
    this.tokenResolver = tokenResolver;

    this.axiosInstance = axios.create({
      timeout: 10000,
      baseURL: baseUrl,
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      config.headers.Authorization = `Bearer ${await this.tokenResolver.getAccessToken()}`;

      return config;
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        this.refreshTried = false;

        return response;
      },
      async (error: AxiosError) => {
        if (error.config && error.response?.status === 401 && !this.refreshTried) {
          const refreshedToken = await this.tokenResolver.refreshAccessToken();

          if (refreshedToken) {
            error.config.headers.Authorization = `Bearer ${refreshedToken}`;
            this.refreshTried = true;

            return axios.request(error.config);
          }
        }

        throw error;
      },
    );
  }

  resolve() {
    return this.axiosInstance;
  }
}
