import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { alias } from 'macro-decorators';

/**
 * @param {Class}     subscription
 * @param {Boolean}   pill
 */
export default class TrialDaysLeftComponent extends Component {
  @service state;
  @service featureFlags;

  @tracked pill;

  constructor() {
    super(...arguments);
    this.pill = this.args.pill ?? true;
  }

  @alias('args.subscription.isOnTrial') isOnTrial;

  get isDesksClosedBeta() {
    const app = this.args.subscription.app;
    return app === 'desks' && get(this.featureFlags, 'desksClosedBeta');
  }

  get trialDaysLeft() {
    const trialDaysLeft = this.args.subscription.trialDaysLeft;
    return parseInt(trialDaysLeft, 10);
  }

  get isTrialExpired() {
    return !!(
      this.args.subscription.onExpiredTrial ||
      this.args.subscription.isExpiredDeliveriesTrial ||
      !this.args.subscription.trialDaysLeft
    );
  }

  get show() {
    return this.isOnTrial && !this.isTrialExpired && !this.isDesksClosedBeta;
  }
}
