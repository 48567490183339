import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { get, action } from '@ember/object';
import { all } from 'rsvp';

export default class DesksIndexPageRoute extends Route {
  @service state;
  @service router;
  @service store;
  @service featureConfig;
  @service abilities;
  @service featureFlags;

  beforeModel() {
    if (this.abilities.cannot('visit desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const desksModel = this.modelFor('desks');
    const currentLocationId = this.state.currentLocation.id;
    // Cannot use floors from the desks model because the floors/desks could have changed since the model was loaded
    // Must fetch the floors again to ensure the floors/desks are up to date
    const floors = await this.store.query('floor', { filter: { 'location-id': currentLocationId }, include: 'desks' });
    const floorDesks = floors.map((floor) => floor.desks);
    await all(floorDesks);
    return { ...desksModel, floors };
  }

  redirect({ floors }) {
    const { currentLocation, currentLocationEnabledDesks } = this.state;
    const locationFloors = floors.filter((floor) => get(floor.location, 'id') === currentLocation.id);
    if (
      !this.featureConfig.isEnabled('desks') ||
      !currentLocationEnabledDesks ||
      !this.featureConfig.isEnabled('desks.accessDesksTab')
    ) {
      return this.router.transitionTo('desks.landing-page');
    } else if (!locationFloors || !locationFloors.length) {
      return this.router.transitionTo('desks.get-started');
    } else if (this.abilities.cannot('visit-all-desks desk')) {
      if (this.abilities.can('visit-reservations desks')) {
        return this.router.transitionTo('desks.reservations');
      } else if (this.abilities.can('visit-settings desks')) {
        return this.router.transitionTo('desks.settings');
      }
      return this.router.transitionTo('unauthorized');
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.fetchAmenitiesTask.perform();
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
