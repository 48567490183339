import Component from '@glimmer/component';
import groupBy from 'garaje/utils/decorators/group-by';
import { A } from '@ember/array';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { all, restartableTask } from 'ember-concurrency';
import { notEmpty } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import { dependentKeyCompat } from '@ember/object/compat';

/**
 * @param {Array}                               selectedChildren
 * @param {Model<GlobalSettingBatch>}           globalSettingBatch
 * @param {Function}                            close
 * @param {Function}                            nextToConfirm
 * @param {Function}                            updateChildren
 */
export default class PropagableSettingsChangedModalFlowsSearcher extends Component {
  @service store;

  @tracked searchQuery = null;
  @tracked flows = A();

  @notEmpty('args.selectedChildren') atLeastOneSelected;

  // TODO: @heroiceric Flows should not be grouped by `location.name` because
  // the group-by addon is not capable of handling async relationships. This
  // only works if the location relationship has already been loaded
  @groupBy('filteredFlows', 'location.name') flowsByLocation;

  constructor() {
    super(...arguments);
    this.fetchFlows.perform();
  }

  @dependentKeyCompat
  get filteredFlows() {
    if (!this.searchQuery) {
      return this.flows;
    }

    return this.flows.filter((flow) => flow.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
  }

  get allFilteredSelected() {
    return get(this.filteredFlows, 'length') <= get(this.args.selectedChildren, 'length');
  }

  get selectedMessage() {
    const totalFlows = get(this.flows, 'length');
    const totalSelected = get(this.args.selectedChildren, 'length');

    if (totalSelected === 0) {
      return '';
    } else if (totalSelected === totalFlows) {
      return 'All flows selected';
    } else {
      return `${totalSelected} of ${totalFlows} flows selected`;
    }
  }

  @restartableTask
  *fetchFlows() {
    const flowParentId = get(this.args.globalSettingBatch, 'parent.id');
    let flows = yield this.store.findAll('flow');

    // Remove current flow from the list
    flows = flows.filter((flow) => {
      return get(flow, 'id') !== flowParentId;
    });

    // Flows cannot be grouped by `location.name` unless we ensure that the
    // location for each flow has been loaded
    yield all(flows.map((flow) => get(flow, 'location')));

    this.flows = flows;
  }

  @action
  clearAll() {
    this.args.updateChildren(A());
  }

  @action
  toggleAllSelected() {
    const filteredFlows = this.filteredFlows;
    const selectedChildren = this.args.selectedChildren;

    if (this.allFilteredSelected) {
      filteredFlows.forEach((flow) => selectedChildren.removeObject(flow));
    } else {
      filteredFlows.forEach((flow) => selectedChildren.addObject(flow));
    }
  }
}
