import { service } from '@ember/service';
import { Ability } from 'ember-can';
import type AuthzService from 'garaje/services/authz';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureConfigService from 'garaje/services/feature-config';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type StateService from 'garaje/services/state';
import { Permission } from 'garaje/utils/ui-permissions';
import _intersection from 'lodash/intersection';

export default class ResourceAbility extends Ability {
  @service declare authz: AuthzService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureConfig: FeatureConfigService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare state: StateService;

  get canVisit(): boolean {
    const permissions = [Permission.MAPS_LIVE_CREATE];
    const featureFlagEnabled = this.featureFlags.isEnabled('web-parking-resource');

    return featureFlagEnabled && this.authz.hasAllPermissionsAtCurrentLocation(permissions);
  }
}
