import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action, get } from '@ember/object';
import ObjectProxy from '@ember/object/proxy';
import { service } from '@ember/service';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { dropTask } from 'ember-concurrency';

export default class EmployeesSyncSettingsController extends Controller {
  @service currentLocation;
  @service flashMessages;
  @service('locations') locationsService;
  @service router;

  queryParams = ['integration', 'from'];
  @tracked integration = '';
  @tracked from = 'employees.integrations';

  get activeInstalls() {
    const syncPlugins = get(this.model, 'plugins').filterBy('category', 'sync');
    const pluginInstalls = get(this.model, 'pluginInstalls');

    // find active installs that belong to any plugin in the sync category
    const syncPluginInstalls = pluginInstalls.filter((install) => {
      return syncPlugins.findBy('id', get(install, 'plugin.id')) !== undefined;
    });
    return syncPluginInstalls.filter(function (install) {
      return get(install, 'status') !== 'uninstalled';
    });
  }

  get locations() {
    return get(this.locationsService, 'currentCompanyLocations')?.map((location) =>
      ObjectProxy.create({ content: location, disabled: false }),
    );
  }

  @action
  integrationDisconnected() {
    this.router.transitionTo('employees.integrations');
  }

  @dropTask
  *saveDirectoryProvider(model) {
    try {
      yield model.save();
      this.flashMessages.showAndHideFlash('success', 'Saved!');
      this.router.transitionTo('employees.sync-settings');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @action
  setIntegration(integration) {
    this.integration = integration;
  }

  @action
  clearIntegration() {
    this.integration = '';
  }
}
