import Component from '@glimmer/component';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { DISCARDED_RESPONSE_STRINGS } from 'garaje/utils/enums';

/**
 * @param {String}    name
 * @param {Class}     content
 */
export default class CustomColumn extends Component {
  @service currentLocation;

  get data() {
    const userData = get(this.args.content, 'userData');

    if (userData) {
      const matchingField = userData.findBy('field', this.args.name);
      if (matchingField) {
        let val = get(matchingField, 'value');
        if (val === DISCARDED_RESPONSE_STRINGS.PLACEHOLDER) {
          val = DISCARDED_RESPONSE_STRINGS.DISPLAY_TEXT;
        }

        return val;
      }
    }
    return null;
  }
}
