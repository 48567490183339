import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class DesksLandingPageRoute extends Route {
  @service state;
  @service router;
  @service featureConfig;
  @service abilities;

  beforeModel() {
    if (this.abilities.cannot('visit-landing desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  model() {
    return this.modelFor('desks');
  }

  redirect({ floors }) {
    const { currentLocationEnabledDesks } = this.state;
    if (
      this.featureConfig.isEnabled('desks') &&
      currentLocationEnabledDesks &&
      !floors.length &&
      this.featureConfig.isEnabled('desks.accessDesksTab')
    ) {
      return this.router.transitionTo('desks.get-started');
    } else if (
      this.featureConfig.isEnabled('desks') &&
      currentLocationEnabledDesks &&
      floors.length &&
      this.featureConfig.isEnabled('desks.accessDesksTab')
    ) {
      return this.router.transitionTo('desks.index');
    }
  }
}
