import Component from '@glimmer/component';
import type { VfdContactMethodSlackMetadata } from 'garaje/models/vfd-contact-method';

type VirtualFrontDeskNotificationContactDropdownContactMethodSlackChannelComponentSignature = {
  Args: {
    channel: VfdContactMethodSlackMetadata | null;
  };
};

export default class VirtualFrontDeskNotificationContactDropdownContactMethodSlackChannelComponent extends Component<VirtualFrontDeskNotificationContactDropdownContactMethodSlackChannelComponentSignature> {
  get channelName(): string | null {
    if (this.args.channel) {
      return '#' + this.args.channel['channel-name'];
    }
    return null;
  }
}
