/* eslint-disable ember/no-computed-properties-in-native-classes */
// eslint-disable-next-line ember/no-classic-components
import Component from '@ember/component';
import { alias, or } from '@ember/object/computed';
import { computed, get, set, setProperties } from '@ember/object';
import { fadeOut } from 'ember-animated/motions/opacity';
import { service } from '@ember/service';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';

import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import normalizeCouponCode from 'garaje/utils/normalize-coupon-code';
import zft from 'garaje/utils/zero-for-tests';

export default class ConfirmPurchaseRooms extends Component {
  @service flashMessages;
  @service metrics;
  @service store;

  couponCode = null;
  estimate = null;
  lastValidCouponCode = null;
  period = null;
  quantity = 0;
  quantityTooSmall = false;

  @alias('estimate.nextInvoiceEstimate.lineItems.firstObject.unitAmount') unitCost;
  @or('updateQuantityTask.isRunning', 'fetchEstimateTask.isRunning') isLoading;

  @computed('estimate.nextInvoiceEstimate.discounts.[]')
  get discounts() {
    return get(this, 'estimate.nextInvoiceEstimate.discounts') ?? [];
  }

  @computed('unitCost', 'quantity')
  get newCost() {
    if (!this.unitCost) {
      return 0;
    }
    return this.unitCost * this.quantity;
  }

  @computed('plan.{yearlyPrice,monthlyPrice}', 'activeUnits')
  get yearlySavingsInDollars() {
    return (this.plan.monthlyPrice - this.plan.yearlyPrice) * 12 * Math.max(this.activeUnits, 1);
  }

  /* eslint-disable require-yield */
  *transition({ removedSprites }) {
    removedSprites.forEach(fadeOut);
  }
  /* eslint-enable require-yield */

  // eslint-disable-next-line ember/classic-decorator-hooks
  init() {
    super.init(...arguments);

    const period = ['monthly', 'yearly'].includes(this.subscription.period) ? this.subscription.period : 'yearly';

    setProperties(this, { durationAnimation: zft(250), period, quantity: Math.max(this.activeUnits, 1) });

    this.fetchEstimateTask.perform();
  }

  @dropTask
  *applyCouponCodeTask(couponCode) {
    set(this, 'couponCode', normalizeCouponCode(couponCode));
    yield this.fetchEstimateTask.perform();
    set(this, 'couponCode', null);
  }

  @restartableTask
  *fetchEstimateTask() {
    const couponCode = this.couponCode !== null ? this.couponCode : this.lastValidCouponCode;
    const previousEstimate = this.estimate;

    try {
      const newEstimate = yield this.store
        .createRecord('subscription-estimate', {
          app: 'rooms',
          quantity: this.quantity,
          plan: 'standard',
          period: this.period,
          couponCode,
          subscription: this.subscription,
        })
        .save();

      setProperties(this, {
        couponCode: null,
        estimate: newEstimate,
        lastValidCouponCode: couponCode,
      });
    } catch (e) {
      setProperties(this, {
        couponCode: this.lastValidCouponCode,
        estimate: previousEstimate,
        period: previousEstimate.period,
        quantity: previousEstimate.quantity,
      });

      this.flashMessages.showFlash('error', 'Error', parseErrorForDisplay(e));
      this.metrics.trackEvent('Viewed flash message', {
        product: 'rooms',
        type: 'error',
        message_title: parseErrorForDisplay(e),
        message_code: 'rooms_upgrade_error',
        message_code_type: 'rooms_upgrade',
      });
    }
  }

  @dropTask
  *handleSubmitTask(event) {
    event.preventDefault();

    if (this.fetchEstimateTask.isRunning) {
      return;
    }

    try {
      if (this.purchasingFromTrial) {
        yield this.subscription.purchase({
          coupon: this.lastValidCouponCode,
          period: this.period,
          quantity: this.quantity,
        });

        yield this.subscription.reload();
        this.flashMessages.showAndHideFlash('success', 'Thanks!');
        this.metrics.trackEvent('Viewed flash message', {
          product: 'rooms',
          type: 'success',
          message_title: 'Thanks!',
          message_code: 'rooms_purchase_success',
          message_code_type: 'rooms_purchase_success',
        });
        this.transitionToBilling();
      } else {
        const oldQuantity = this.subscription.quantity;
        const newQuantity = this.quantity;
        const oldPlan = this.subscription.normalizedPlanName;
        const oldPeriod = this.subscription.period;

        setProperties(this.subscription, {
          coupon: this.lastValidCouponCode,
          period: this.period,
          plan: this.planName,
          quantity: this.quantity,
        });

        yield this.subscription.save();
        this.flashMessages.showAndHideFlash('success', 'Upgrade successful');
        this.metrics.trackEvent('Viewed flash message', {
          product: 'rooms',
          type: 'success',
          message_title: 'Upgrade successful',
          message_code: 'rooms_upgrade_success',
          message_code_type: 'rooms_upgrade',
        });
        this.transitionToBilling();
        this.metrics.startJob();
        this.metrics.trackJobEvent('Account modified', {
          product: this.subscription.app,
          account_change_type: 'subscription',
          account_change_object: 'plan',
          account_change_action: 'upgrade',
          object_change_from: oldPlan,
          object_change_to: this.planName,
        });
        if (oldPeriod !== this.period) {
          this.metrics.trackJobEvent('Account modified', {
            product: this.subscription.app,
            account_change_type: 'subscription',
            account_change_object: 'period',
            account_change_action: 'updated',
            object_change_from: oldPeriod,
            object_change_to: this.period,
          });
        }
        if (oldQuantity < newQuantity) {
          this.metrics.trackJobEvent('Account modified', {
            product: this.subscription.app,
            account_change_type: 'subscription',
            account_change_object: 'quantity',
            account_change_action: 'expansion',
            object_change_from: oldQuantity,
            object_change_to: newQuantity,
          });
        }
      }
    } catch (e) {
      this.subscription.rollbackAttributes();
      this.flashMessages.showFlash('error', parseErrorForDisplay(e));
      this.metrics.trackEvent('Viewed flash message', {
        product: 'rooms',
        type: 'error',
        message_title: parseErrorForDisplay(e),
        message_code: this.purchasingFromTrial ? 'rooms_purchase_error' : 'rooms_upgrade_error',
        message_code_type: this.purchasingFromTrial ? 'rooms_purchase' : 'rooms_upgrade',
      });
    }
  }

  @restartableTask
  *showQuantityTooSmallError() {
    set(this, 'quantityTooSmall', true);

    yield timeout(zft(5000));

    set(this, 'quantityTooSmall', false);
  }

  @restartableTask
  *updateQuantityTask({ target }) {
    let { value: newQuantity } = target;
    if (!newQuantity) {
      return;
    }
    newQuantity = parseInt(newQuantity, 10);
    if (isNaN(newQuantity) || newQuantity < Math.max(this.activeUnits, 1) || newQuantity > 999) {
      if (newQuantity < Math.max(this.activeUnits, 1)) {
        this.showQuantityTooSmallError.perform();
      }

      yield timeout(zft(1000));

      if (newQuantity < Math.max(this.activeUnits, 1)) {
        set(this, 'quantity', Math.max(this.activeUnits, 1));
      }

      // The user has typed an invalid value into the input
      target.value = this.quantity;
    } else {
      setProperties(this, {
        quantity: newQuantity,
        quantityTooSmall: false,
      });
    }
    yield timeout(zft(500));
    yield this.fetchEstimateTask.perform(this.quantity);
  }
}
