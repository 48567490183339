import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { routeEvent } from 'garaje/utils/decorators/route';

export default class DesksGetStartedRoute extends Route {
  @service state;
  @service store;
  @service router;
  @service transitionConfirm;
  @service featureFlags;
  @service featureConfig;
  @service abilities;

  beforeModel() {
    if (this.abilities.cannot('visit-settings desks')) {
      this.router.transitionTo('unauthorized');
    }
  }

  async model() {
    const desksModel = this.modelFor('desks');
    await this.store.findRecord('desk-location', this.state.currentLocationEnabledDesks.id, {
      reload: true,
      include: 'workplace-days',
    });
    const workplaceDays = this.store
      .peekAll('workplaceDay')
      .filter((day) => {
        return day.belongsTo('deskLocation').id() === this.state.currentLocationEnabledDesks.id;
      })
      .sort((a, b) => a.id - b.id);
    return { ...desksModel, workplaceDays };
  }

  redirect() {
    const { currentLocationEnabledDesks } = this.state;
    if (!this.featureConfig.isEnabled('desks') || !currentLocationEnabledDesks.active) {
      this.router.transitionTo('desks.landing-page');
    }
  }

  @routeEvent
  routeWillChange(transition) {
    const settingsController = this.controller;
    const dirtyDays = settingsController.dirtyWorkplaceDays;

    if (dirtyDays.length) {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          dirtyDays.forEach((day) => day.rollbackAttributes());
        },
      });
    }
    return;
  }
}
