import { hash } from 'rsvp';
import normalizePlanId from 'garaje/utils/normalize-plan-id';
import planRank from 'garaje/utils/plan-rank';
import Route from '@ember/routing/route';
import { APP } from 'garaje/utils/enums';
import { service } from '@ember/service';

export default class BillingProductPlansUpgradeRoute extends Route {
  @service router;
  @service store;

  normalizePlanName = (planName, app) => {
    if (planName === 'standard' && app === APP.VISITORS) {
      return 'standard-1';
    } else if (planName === 'premium' && app === APP.DELIVERIES) {
      return 'standard';
    }

    return planName;
  };

  model({ plan: planName }) {
    const { activeUnits, paymentSource, subscription } = this.modelFor('billing.product-plans');
    const { deliveryLimit } = this.modelFor('billing');
    const normalizedPlanId = normalizePlanId(planName, subscription.app); // ID of the plan serialized from envoy-web
    const normalizedPlanName = this.normalizePlanName(planName, subscription.app); // Name of the plan tier used when updating subscriptions in billing
    const plan = this.store.findRecord('plan', normalizedPlanId);

    return hash({
      activeUnits,
      deliveryLimit,
      normalizedPlanName,
      paymentSource,
      plan,
      planName,
      subscription,
    });
  }

  afterModel({ paymentSource, plan, planName, subscription }) {
    if (!subscription || subscription.isEnterprisePlan) {
      return this.router.transitionTo('billing');
    }

    const planId = normalizePlanId(planName, subscription.app);
    const isUpgrading =
      planRank(planId) > planRank(subscription.plan) || subscription.onTrial || subscription.onExpiredTrial;

    if (!isUpgrading || !paymentSource.exists || !plan) {
      return this.router.transitionTo('billing.product-plans', subscription.app);
    }
  }
}
