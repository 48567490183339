import Helper from '@ember/component/helper';
import { service } from '@ember/service';
import { action } from '@ember/object';

/**
 * DoubleConfirmation helper wraps a settings panel onCancel with undo / rollback capabilities
 * @param {function} close Required. method to close the panel
 * @param {function} rollback Required. method to rollback the model
 * @param {boolean} dirty Required. signifies if dirty
 */
export default class DoubleConfirmation extends Helper {
  @service transitionConfirm;

  compute(_, { dirty, rollback, close }) {
    this.dirty = dirty;
    this.rollback = rollback;
    this.close = close;

    return this.handleCancel;
  }

  @action
  handleCancel() {
    if (this.dirty) {
      return this.transitionConfirm.displayConfirmTask.perform(null, {
        header: 'You have unsaved changes',
        continue: () => {
          this.rollback();
          this.close();
        },
      });
    }

    this.close();
  }
}
