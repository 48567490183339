import type RouterService from '@ember/routing/router-service';
import Service, { service } from '@ember/service';

export type TransitionSource = 'location' | 'global-overview';

const SUPPORTED_NESTED_ROUTES = ['visitors.invites.show', 'visitors.entry'];
const SUPPORTED_GLOBAL_ROUTES = ['global-overview'];

export default class GlobalRoutingService extends Service {
  @service declare router: RouterService;

  /**
   * Checks whether a route normally nested within a location can be considered having been temporarily nested within the global overview
   * @returns whether the route is a nested global route
   */
  isNestedGlobalRoute(): boolean {
    const currentRouteName = this.router.currentRouteName;
    const queryParams = this.router.currentRoute.queryParams ?? {};

    return (
      SUPPORTED_GLOBAL_ROUTES.some((route) => queryParams['transitionedFrom'] === route) &&
      SUPPORTED_NESTED_ROUTES.some((route) => currentRouteName.startsWith(route))
    );
  }

  /**
   * Returns the back link based on the transitionedFrom source
   * @param transitionedFrom the source of the transition
   * @param locationRoute the default location route
   * @returns
   */
  getBackLink(transitionedFrom: TransitionSource, locationRoute = 'visitors.invites'): string {
    switch (transitionedFrom) {
      case 'global-overview':
        return 'location-overview.invite-log';
      case 'location':
      default:
        return locationRoute;
    }
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  interface Registry {
    'global-routing': GlobalRoutingService;
  }
}
