import Controller from '@ember/controller';
import { get } from '@ember/object';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { defer } from 'rsvp';
import { filterBy } from 'macro-decorators';

export default class DesksNeighborhoodsController extends Controller {
  @service store;
  @service state;

  @filterBy('model.neighborhoods', 'hasDirtyAttributes') dirtyNeighborhoods;

  get neighborhoods() {
    return this.model.neighborhoods.filter((neighborhood) => {
      return !neighborhood.isDeleted && get(neighborhood.location, 'id') === this.state.currentLocation.id;
    });
  }

  @dropTask
  showCreateNeighborhoodModalTask = {
    *perform() {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  get neighborhoodDesksTotal() {
    const { desks } = this.model;
    let desksCount = 0;
    for (const neighborhood in desks) {
      desksCount += desks[neighborhood].length;
    }
    return desksCount;
  }
}
