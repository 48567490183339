import Route from '@ember/routing/route';
import { get } from '@ember/object';
import { service } from '@ember/service';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import { routeEvent } from 'garaje/utils/decorators/route';
import { hash } from 'rsvp';

export default class EmployeeRegistrationIndexRoute extends Route {
  title = 'Protect · Employee registration manager · Envoy';

  @service abilities;
  @service flow;
  @service router;
  @service skinnyLocations;
  @service state;
  @service transitionConfirm;
  @service store;

  async beforeModel() {
    if (this.abilities.can('visit workplace')) {
      return this.router.transitionTo('workplace.settings.employees.registration');
    }
    if (this.abilities.cannot('visit screening for protect')) {
      return this.router.transitionTo('protect');
    }
    return await this.skinnyLocations.loadAllTask.perform().catch(throwUnlessTaskDidCancel);
  }

  async model() {
    const userDocumentTemplates = await this.store.findAll('user-document-template');
    const covid19VaccineCardDocumentTemplate = userDocumentTemplates.findBy('identifier', 'covid-19-vaccine-card');
    const covid19TestResultDocumentTemplate = userDocumentTemplates.findBy('identifier', 'covid-19-test-result');
    const locationId = this.state.currentLocation.id;

    // TODO: Move this configuration fetching / computation logic to a shared user document
    //       service with identifiable conventions [VIS-3953]
    // TODO: Support polymorphic association between configuration record and Location / Company
    //       to enable fetching all relevant configurations in a single request instead of
    //       one-by-one [VIS-3954]
    const { covidTestConfigurations, vaccineConfigurations } = await hash({
      covidTestConfigurations: covid19TestResultDocumentTemplate?.id
        ? this.store.query('user-document-template-configuration', {
            filter: {
              'user-document-template-id': covid19TestResultDocumentTemplate.id,
              'location-id': locationId,
            },
          })
        : [],
      vaccineConfigurations: covid19VaccineCardDocumentTemplate?.id
        ? this.store.query('user-document-template-configuration', {
            filter: {
              'user-document-template-id': covid19VaccineCardDocumentTemplate.id,
              'location-id': locationId,
            },
          })
        : [],
    });

    return {
      covidTestDocumentTrackingConfiguration: get(covidTestConfigurations, 'firstObject'),
      vaccineDocumentTrackingConfiguration: get(vaccineConfigurations, 'firstObject'),
    };
  }

  setupController(controller) {
    this.flow.setSelectedIfNoneAndOnlyOneChoice();
    controller.setMetaInStorage();
    this.controller.showWelcomeBanner = controller.shouldShowWelcomeBanner();
  }

  @routeEvent
  routeWillChange(transition) {
    const { canSave, isOpen, rollbackAll } = this.controller;

    if (canSave && isOpen && transition.targetName !== 'protect.settings.employees.registration.edit') {
      this.transitionConfirm.displayConfirmTask.perform(transition, {
        continue() {
          rollbackAll();
        },
      });
    }
    return true;
  }
}
