import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import type UserDocumentAttachmentModel from 'garaje/models/user-document-attachment';
import type UserDocumentTemplateModel from 'garaje/models/user-document-template';

export default class UserDocumentTemplateAttachmentModel extends Model {
  @belongsTo('user-document-template', { async: true })
  declare userDocumentTemplate: AsyncBelongsTo<UserDocumentTemplateModel>;
  @hasMany('user-document-attachment', { async: true })
  declare userDocumentAttachments: AsyncHasMany<UserDocumentAttachmentModel>;

  @attr('boolean') declare required: boolean;
  @attr('date') declare createdAt: Date;
  @attr('date') declare updatedAt: Date;
  @attr('number') declare position: number;
  @attr('string') declare description: string;
  @attr('string') declare identifier: string;
  @attr('string') declare title: string;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'user-document-template-attachment': UserDocumentTemplateAttachmentModel;
  }
}
