import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { filterBy } from 'macro-decorators';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { or } from 'macro-decorators';
import { IMPRESSION_NAMES } from 'garaje/utils/enums';
import { APP } from 'garaje/utils/enums';

export default class SetupGuideSteps extends Component {
  @service store;
  @service router;
  @service statsig;
  @service currentAdmin;
  @service featureFlags;
  @service impressions;
  @service chameleon;
  @service('setupGuideStepper') setupGuideStepperService;

  @tracked hasSteps = false;
  @tracked showModal = false;
  @tracked totalSteps = null;
  @tracked currentStepNumber = null;
  @tracked currentStep = null;
  @tracked setupGuideStepperContinued = null;
  @tracked shouldShowIpadDemo = false;
  @tracked stepperClosed = false;

  @or('currentAdmin.isGlobalAdmin', 'currentAdmin.isLocationAdmin') isAdmin;
  @filterBy('setupGuideItems', 'completed', true) completedSteps;
  @filterBy('setupGuideItems', 'completed', false) remainingSteps;

  constructor() {
    super(...arguments);
    this.showStepper = this.setupGuideStepperService.showStepper;
    this.setupGuideStepperContinued = localStorage.getItem('setupGuideStepperContinued');
    if (this.showStepper) {
      this.fetchSetupGuideItems().then(() => this.updateCurrentStep());
    }
  }

  get setupGuideItems() {
    return this.setupGuideStepperService.setupGuideItems;
  }

  get showCompletedMessage() {
    return this.setupGuideStepperService.allStepsComplete && !this.setupGuideStepperContinued;
  }

  async fetchSetupGuideItems() {
    await this.setupGuideStepperService.loadSetupStepsTask.perform(APP.VISITORS);
    if (this.setupGuideItems.length) {
      this.hasSteps = true;
    }
    this.totalSteps = this.setupGuideItems.length;
  }

  @action logStepperViewed() {
    this.statsig.logEvent('setup-guide-stepper-viewed');
  }

  @action
  updateCurrentStep(newStepNumber, fromArrowClick) {
    if (newStepNumber != null) {
      if (newStepNumber >= 0 && newStepNumber < this.totalSteps) {
        this.currentStepNumber = newStepNumber;
      } else if (newStepNumber < 0) {
        this.currentStepNumber = this.totalSteps - 1;
      } else if (newStepNumber >= this.totalSteps) {
        this.currentStepNumber = 0;
      }
    } else {
      const localCurrentStep = localStorage.getItem('setupGuideStep');
      if (localCurrentStep) {
        this.currentStepNumber = parseInt(localCurrentStep);
        if (
          isNaN(this.currentStepNumber) ||
          this.currentStepNumber < 0 ||
          this.currentStepNumber >= this.setupGuideItems.length
        ) {
          this.currentStepNumber = 0;
        }
      } else {
        this.currentStepNumber = 0;
      }
    }
    localStorage.setItem('setupGuideStep', this.currentStepNumber);
    this.currentStep = this.setupGuideItems.find((_, idx) => idx === this.currentStepNumber);
    if (fromArrowClick) {
      const item = this.currentStep;
      this.statsig.logEvent('setup_guide_stepper_arrow_clicked', item.identifier, { app: item.app });
    }
  }

  @action
  handleArrowClicked(newStepNumber) {
    if (this.showCompletedMessage) {
      localStorage.setItem('setupGuideStepperContinued', 'true');
      this.setupGuideStepperContinued = 'true';
      this.updateCurrentStep(0, true);
    } else {
      this.updateCurrentStep(newStepNumber, true);
    }
  }

  @action
  goToNextStep() {
    let newStepNumber = (this.currentStepNumber + 1) % this.totalSteps;
    while (this.setupGuideItems[newStepNumber].completed && newStepNumber !== this.currentStepNumber) {
      newStepNumber = (newStepNumber + 1) % this.totalSteps;
    }
    this.updateCurrentStep(newStepNumber);
    this.handleActionClicked();
  }

  @action handleActionClicked(isSecondary) {
    const item = this.currentStep;
    const tourId = isSecondary ? item.metadata.secondary_button_chmln_tour_id : item.metadata.chmln_tour_id;
    if (tourId && this.featureFlags.isEnabled('growth_show_chmln_tour')) {
      this.chameleon.showTour(tourId);
    }

    this.statsig.logEvent('setup_guide_stepper_action_clicked', item.identifier, {
      app: item.app,
      secondary: isSecondary,
    });
    if (isSecondary) {
      this.router.transitionTo(item.metadata.secondary_button_route);
    } else if (item.route) {
      this.router.transitionTo(item.route);
    } else if (this.currentStep.metadata.external_route) {
      if (this.featureFlags.isEnabled('growth_ipad_demo')) {
        this.shouldShowIpadDemo = true;
      } else {
        this.showModal = true;
        this.impressions.postImpression.perform(IMPRESSION_NAMES.NAVATTIC_IPAD_DEMO_VIEWED);
        if (!this.currentStep.completed) {
          this.toggleCompletionTask.perform(true);
        }
      }
    }
  }

  @action
  handleTextLinkClick(event) {
    const target = event.target;
    if (target.tagName === 'A') {
      const item = this.currentStep;
      this.statsig.logEvent('setup_guide_stepper_text_link_clicked', item.identifier, { app: item.app });

      if (!target.getAttribute('target')) {
        event.preventDefault();
        const href = target.getAttribute('href');
        this.router.transitionTo(href);
      }
    }
  }

  @action closeModal() {
    this.showModal = false;
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      this.shouldShowIpadDemo = false;
    }
  }

  @action closeStepper() {
    this.stepperClosed = true;
    this.statsig.logEvent('setup_guide_stepper_closed', null, { app: this.currentStep.app });
    this.setupGuideStepperService.toggleSetupGuideStepsEnabledTask.perform();
  }

  get markButtonLabel() {
    if (this.currentStep.completed) {
      return this.currentStep.metadata.dashboard_completed_step_label;
    }

    return this.currentStep.metadata.dashboard_incomplete_step_label || 'Mark as complete';
  }

  get allStepsComplete() {
    return !this.setupGuideItems.any((item) => !item.completed);
  }

  get shouldShowActions() {
    return this.isAdmin && this.currentStep;
  }

  get showIpadDemo() {
    if (this.featureFlags.isEnabled('growth_ipad_demo')) {
      return !this.showModal && this.shouldShowIpadDemo;
    }
    return false;
  }

  toggleCompletionTask = task({ drop: true }, async (isLogged = false) => {
    const setupGuideItem = this.currentStep;
    const completed = !setupGuideItem.completed;
    const statsigEventLabel = setupGuideItem.completed ? 'incomplete' : 'complete';

    setupGuideItem.completed = completed;
    this.isOpen = !completed;

    if (!isLogged) {
      this.statsig.logEvent(
        `setup_guide_stepper_item_mark_as_${statsigEventLabel}_clicked`,
        setupGuideItem.identifier,
        {
          app: setupGuideItem.app,
        },
      );
    }

    try {
      await setupGuideItem.save();
      this.setupGuideStepperService.checkAllStepsComplete();
    } catch (e) {
      setupGuideItem.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  });
}
